/* eslint-disable react/jsx-no-bind */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableHead = require('@common/Table2/TableHead.react');
const ColumnSelectorCell = require('@common/Table2/ColumnSelectorTableCell.react');

const ItemsHeader = ({
  onSort,
  sortBy,
  changeColumnConfiguration,
  editableColumns,
  type,
  ...rest
}) => {
  const getSort = (name) => {
    if (sortBy.col === name) {
      return sortBy.order;
    }
    return null;
  };

  return (
    <TableHead {...rest}>
      <TableRow>
        <TableCell className="pipeline-item__joc-badge" name="joc-badge" />
        <TableCell className="pipeline-item__name"
          id="item-name"
          onSort={onSort}
          sort={getSort('name')}
          name="name">Name
        </TableCell>
        <TableCell className="pipeline-item__client"
          onSort={onSort}
          sort={getSort('client')}
          name="client">Client
        </TableCell>
        <TableCell className="pipeline-item__customer"
          name="customer">Client contact
        </TableCell>
        <TableCell className="pipeline-item__pm"
          onSort={onSort}
          sort={getSort('pm')}
          name="pm">PM
        </TableCell>
        <TableCell className="pipeline-item__account"
          onSort={onSort}
          sort={getSort('account')}
          name="account">Account
        </TableCell>
        <TableCell className="pipeline-item__joc"
          onSort={onSort}
          sort={getSort('job-order-category')}
          name="job-order-category">Job order category
        </TableCell>
        <TableCell className="pipeline-item__project-type"
          onSort={onSort}
          sort={getSort('project-type')}
          name="project-type">Project type
        </TableCell>
        <TableCell className="pipeline-item__job-order"
          onSort={onSort}
          sort={getSort('job-order')}
          name="job-order">Job order
        </TableCell>
        <TableCell className="pipeline-item__end"
          onSort={onSort}
          sort={getSort('contract_date')}
          name="contract_date">Signed on
        </TableCell>
        <TableCell className="pipeline-item__start"
          onSort={onSort}
          sort={getSort('date_start')}
          name="date_start">Start
        </TableCell>
        <TableCell className="pipeline-item__months" name="duration">Months</TableCell>
        <TableCell className="pipeline-item__end"
          onSort={onSort}
          sort={getSort('due_date')}
          name="due_date">Due date
        </TableCell>
        <TableCell className="pipeline-item__client-po"
          onSort={onSort}
          sort={getSort('po')}
          name="po">
          Client PO
        </TableCell>
        <TableCell className="pipeline-item__budget"
          name="budget-status"
          onSort={onSort}
          sort={getSort('budget-status')}>
          Budget
        </TableCell>
        <TableCell className="pipeline-item__value"
          name="estimate"
          onSort={onSort}
          sort={getSort('estimate')}>
          Value
        </TableCell>
        <TableCell className="pipeline-item__margin"
          name="margin"
          onSort={onSort}
          sort={getSort('margin')}>
          Margin
        </TableCell>
        <TableCell className="pipeline-item__costs-percentage"
          name="costs"
          onSort={onSort}
          sort={getSort('costs')}>
          Ext. costs %
        </TableCell>
        <TableCell className="pipeline-item__costs"
          name="costs-value"
          onSort={onSort}
          sort={getSort('costs-value')}>
          Ext. costs
        </TableCell>
        <TableCell className="pipeline-item__probability"
          onSort={onSort}
          sort={getSort('probability')}
          name="probability">Prob.
        </TableCell>
        <TableCell className="pipeline-item__budget-consumption"
          name="budget-consumption"
          onSort={onSort}
          sort={getSort('budget-consumption')}>
          Budget consumption
        </TableCell>
        <TableCell className="pipeline-item__progress"
          name="progress"
          onSort={onSort}
          sort={getSort('progress')}>
          Progress
        </TableCell>
        <TableCell className="pipeline-item__risk" name="risk">
          Risk
        </TableCell>
        <TableCell className="pipeline-item__stage"
          onSort={onSort}
          sort={getSort('stage')}
          name="stage">
          Stage
        </TableCell>
        <TableCell className="pipeline-item__whitelist"
          onSort={onSort}
          sort={getSort('timesheet_whitelist')}
          name="timesheet_whitelist">
          Whitelist
        </TableCell>
        <TableCell className="pipeline-item__whitelist"
          onSort={onSort}
          sort={getSort('archived-date')}
          name="archived-date">
          Archived on
        </TableCell>
        <ColumnSelectorCell className="pipeline-item__actions"
          name="actions"
          columns={editableColumns}
          onColumnsChange={(columns) => changeColumnConfiguration(type, columns)} />
      </TableRow>
    </TableHead>
  );
};

module.exports = ItemsHeader;
