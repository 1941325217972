/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations,default-case,no-shadow */
const React = require('react');
const TextArea = require('@common/material-input/SidebarTextArea.react');
const TextInput = require('@common/material-input/SidebarTextInput.react');
const NumericInput = require('@common/material-input/SidebarNumericInput.react');
const DateRange = require('@common/material-input/SidebarDateRangeSelection.react');
const InputWrapper = require('@common/material-input/SidebarWrapperEditable.react');
const StatusSelect = require('./StatusSelect.react');
const MilestoneSelect = require('../../../containers/Boards/Sidebar/MilestoneSelect');
const SidebarMembers = require('./SidebarMembers.react');

module.exports = class ItemEditBody extends React.Component {
  getField(attribute, value) {
    switch (attribute.type) {
      case 'numeric':
        return (
          <NumericInput
            key={attribute.id}
            name={attribute.id}
            placeholder={attribute.name}
            value={value}
            onChange={(name, value) => {
              const number = value ? parseFloat(value) : null;
              this.props.onChange(name, number, attribute.type);
            }}>
            <span />
          </NumericInput>
        );
      case 'text':
        return (
          <TextArea
            key={attribute.id}
            name={attribute.id}
            placeholder={attribute.name}
            value={value}
            onChange={(name, value) => {
              this.props.onChange(name, value, attribute.type);
            }}>
            <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
          </TextArea>
        );
      case 'status':
        const statusStructure = this.props.structure
          .find((attributeStructure) => attributeStructure.id === attribute.id);
        return (
          <div className="material-input__frame project-canvas-kanban-sidebar-status"
            key={attribute.id}>
            <InputWrapper placeholder={attribute.name} icon={<span />}>
              <StatusSelect
                name={attribute.id}
                value={value}
                structure={statusStructure}
                onChange={(name, value) => {
                  this.props.onChange(name, value, attribute.type);
                }} />
            </InputWrapper>
          </div>
        );
      case 'date':
        return (
          <DateRange
            key={attribute.id}
            name={attribute.id}
            placeholder={attribute.name}
            value={value}
            onChange={(name, value) => {
              this.props.onChange(name, value, attribute.type);
            }}>
            <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
          </DateRange>
        );
      case 'milestone':
        const icon = value
          ? `wethod-icon wethod-icon-${value.type.toLowerCase()} wethod-icon-${value.type.toLowerCase()}--black`
          : '';
        return (
          <div className="material-input__frame" key={attribute.id}>
            <InputWrapper placeholder={attribute.name} icon={<span className={icon} />}>
              <MilestoneSelect
                name={attribute.id}
                value={value}
                onChange={(name, value) => {
                  this.props.onChange(name, value, attribute.type);
                }} />
            </InputWrapper>
          </div>
        );
      case 'member':
        return (
          <div className="project-canvas-kanban-sidebar-member" key={attribute.id}>
            <SidebarMembers
              label={attribute.name}
              placeholder="Add members"
              name={attribute.id}
              value={value}
              onChange={(name, value) => {
                this.props.onChange(name, value, attribute.type);
              }}>
              <span className="wethod-icon wethod-icon-pm" />
            </SidebarMembers>
          </div>
        );
    }
  }

  render() {
    return (
      <div>
        <div className="project-canvas-kanban-sidebar-name">
          <TextInput
            name="item-name"
            placeholder=""
            value={this.props.item.name}
            onChange={(name, value) => {
              this.props.onChange(name, value, 'item-name');
            }} />
        </div>
        {this.props.structure
          .map((attribute, index) => this.getField(attribute,
            this.props.item.attributes[index].value))}
      </div>
    );
  }
};
