const React = require('react');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const ColorReadOnlyField = require('@common/material-input/SidebarReadOnlyColorDotField.react');
const Tag = require('@common/Tag/Tag.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

module.exports = class SupplierReadOnlyDetails extends React.Component {
  static isItaly(country) {
    const name = country ? country.name : '';
    return name.toLowerCase() === 'italy';
  }

  getTagList() {
    if (this.props.supplier.tags && this.props.supplier.tags.length > 0) {
      return this.props.supplier.tags.map((tag) => (
        <Tag key={tag.id}>{tag.name}</Tag>
      ));
    }
    return '';
  }

  getStatus() {
    if (this.props.statusEnabled) {
      const status = this.props.supplier.status ? this.props.supplier.status : {};
      return (
        <ColorReadOnlyField
          placeholder="Status"
          value={status.name}
          color={status.color}>
          <span />
        </ColorReadOnlyField>
      );
    }

    return null;
  }

  getPaymentTerms() {
    return this.props.supplier.payment_term ? this.props.supplier.payment_term.name : '';
  }

  getCountry() {
    return this.props.supplier.country ? this.props.supplier.country.name : '';
  }

  getProvince() {
    return this.props.supplier.province ? this.props.supplier.province.name : '';
  }

  render() {
    return (
      <div className="supplier__sidebar-body">
        {this.getStatus()}
        <ReadOnlyField placeholder="Name"
          value={this.props.supplier.name}>
          <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Email"
          value={this.props.supplier.email}>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Telephone"
          value={this.props.supplier.telephone}>
          <span className="wethod-icon wethod-icon-phone wethod-icon-phone--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Tag"
          value={this.getTagList()}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Notes"
          value={this.props.supplier.notes}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Address"
          value={this.props.supplier.address}>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Town"
          value={this.props.supplier.town}>
          <span />
        </ReadOnlyField>
        <div className="suppliers__sidebar--multiple-input-row">
          <div className="suppliers__sidebar--multiple-input-element">
            <ReadOnlyField placeholder="ZIP"
              value={this.props.supplier.zip}>
              <span />
            </ReadOnlyField>
          </div>
          <div className="suppliers__sidebar--multiple-input-element">
            <ReadOnlyField placeholder="Country"
              value={this.getCountry()} />
          </div>
        </div>
        <ShowIf condition={SupplierReadOnlyDetails.isItaly(this.props.supplier.country)}>
          <div className="suppliers__province">
            <ReadOnlyField placeholder="Province" value={this.getProvince()}>
              <span />
            </ReadOnlyField>
          </div>
        </ShowIf>
        <ReadOnlyField placeholder="Website"
          value={this.props.supplier.website}>
          <span className="wethod-icon wethod-icon-internet wethod-icon-internet--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Linkedin"
          value={this.props.supplier.linkedin}>
          <span className="wethod-icon wethod-icon-linkedin wethod-icon-linkedin--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="VAT"
          value={this.props.supplier.vat}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Payment conditions"
          value={this.props.supplier.payment_conditions}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Payment Terms"
          value={this.getPaymentTerms()}>
          <span />
        </ReadOnlyField>
      </div>
    );
  }
};
