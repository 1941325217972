const React = require('react');
const _ = require('underscore');
const TableCell = require('@common/Table2/TableCell.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const SelectItem = require('@common/Menu/ListMenu/MenuItemColorful.react');
const RoundedSelect = require('@common/inputs/Select/ColorfulSelect/RoundedColorfulSelect/RoundedColorfulSelect.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');

const RiskCell = ({
  updateErrors, availableValues, readOnly,
  value, label, color, onChange, error, disabled, style, metadataService,
}) => {
  const options = availableValues.map((risk) => (
    <SelectItem key={risk.id}
      value={risk.id}
      color={risk.color}>
      {_.unescape(risk.name)}
    </SelectItem>
  ));

  function handleChange(e) {
    const selected = availableValues.filter((risk) => risk.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  return (
    <TableCell className="pipeline-item__risk" style={style}>
      <InputValidator updateErrors={updateErrors} constraints={[]}>
        <InputMetadataManager metadataService={metadataService}
          name="risk-select"
          canEdit={!readOnly && !disabled}>
          <RoundedSelect
            label={label}
            value={value}
            placeholder=""
            errorText={error}
            color={color}
            onChange={handleChange}>
            {options}
          </RoundedSelect>
        </InputMetadataManager>
      </InputValidator>
    </TableCell>
  );
};

module.exports = RiskCell;
