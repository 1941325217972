/* eslint-disable consistent-return,default-case,react/jsx-no-bind,react/no-access-state-in-setstate */
const React = require('react');
const TextInput = require('@common/TextInput.react');

module.exports = class CollapsibleCategory extends React.Component {
  // category, id, name, expanded, hasErrors, editable, onDelete, onAdd, onRename, onToggle, children
  constructor(props) {
    super(props);

    this.state = {
      inputFocused: false,
    };
  }

  getIcon() {
    switch (this.props.category) {
      case 'train':
        return 'wethod-icon wethod-icon-train wethod-icon-train--black';
      case 'flight':
        return 'wethod-icon wethod-icon-travel wethod-icon-travel--black';
      case 'car':
        return 'wethod-icon wethod-icon-car wethod-icon-car--black';
      case 'accommodation':
        return 'wethod-icon wethod-icon-bed wethod-icon-bed--black';
      case 'other':
        return 'wethod-icon wethod-icon-asterisk wethod-icon-asterisk--black';
    }
  }

  getDropdownIcon() {
    let style = 'wethod-icon wethod-icon-dropdown wethod-icon-dropdown--black travel__editable-icon';
    if (this.props.expanded) {
      style += ' wethod-icon-dropdown--exapanded';
    }
    if (!this.props.editable) {
      style += ' travel__dropdown--readonly';
    }
    return style;
  }

  getExpandedBody() {
    if (this.props.expanded) {
      return this.props.children;
    }
  }

  getName() {
    if (this.props.editable && this.props.onRename) {
      return (
        <TextInput name={this.props.id}
          value={this.props.name}
          onChange={this.props.onRename}
          onFocus={this.toggleFocus.bind(this)}
          onBlur={this.toggleFocus.bind(this)} />
      );
    }
    return <span>{this.props.name}</span>;
  }

  getErrorDot() {
    if (this.props.hasErrors) {
      return <div className="travel__category-dot travel__category-dot--error" />;
    }
  }

  getDelete() {
    if (this.props.editable && this.props.onDelete) {
      return (
        <span className="wethod-icon wethod-icon-delete wethod-icon-delete--black travel__editable-icon travel__carrier--category-icons"
          onClick={() => this.props.onDelete(this.props.id)} />
      );
    }
  }

  getAdd() {
    if (this.props.editable && this.props.onAdd) {
      return (
        <span className="wethod-icon wethod-icon-plus wethod-icon-plus--black travel__editable-icon travel__carrier--category-icons"
          onClick={this.props.onAdd} />
      );
    }
  }

  getInputClass() {
    let style = 'material-input__input travel__carrier--title';
    if (this.props.editable) {
      style += ' travel__carrier--title-edit';

      if (this.state.inputFocused) {
        style += ' travel__carrier--title-edit-rename';
      }
    }
    return style;
  }

  toggleFocus() {
    this.setState({ inputFocused: !this.state.inputFocused });
  }

  render() {
    return (
      <div className="travel__category-elements">
        <div className="travel__carrier material-input">
          <div className="material-input__icon">
            <span className={this.getIcon()} />
          </div>
          <div className={this.getInputClass()}>
            {this.getName()}
          </div>
          {this.getErrorDot()}
          {this.getDelete()}
          {this.getAdd()}
          <span className={this.getDropdownIcon()}
            onClick={() => this.props.onToggle(this.props.id)} />
        </div>
        <div className={this.props.expanded ? 'travel__carrier--body' : ''}>
          {this.getExpandedBody()}
        </div>
      </div>
    );
  }
};
