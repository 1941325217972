/* eslint-disable react/sort-comp,react/no-access-state-in-setstate,no-param-reassign */
const React = require('react');
const TagsInput = require('@common/Tag/TagsInput/TagsInput.react');
const Card = require('./CollapsibleCard.react');

module.exports = class Tags extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  getTagUses(name) {
    for (let i = 0; i < this.props.knownTags.length; i++) {
      if (this.props.knownTags[i].name === name) return this.props.knownTags[i].use;
    }
    return 0;
  }

  handleAddTag(name) {
    const tag = {
      name,
      count: this.getTagUses(name) + 1,
    };
    this.props.addTag(this.props.person.id, tag);
  }

  handleDeleteTag(tag) {
    tag.count = this.getTagUses(tag.name) - 1;
    this.props.deleteTag(this.props.person.id, tag);
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    return (
      <Card
        title="Tags"
        collapsed={this.state.collapsed}
        toggleCollapsed={this.toggleCollapsed.bind(this)}>
        <TagsInput
          tags={this.props.person.tags}
          editable
          knownTags={this.props.knownTags}
          handleAdd={this.handleAddTag.bind(this)}
          handleDelete={this.handleDeleteTag.bind(this)} />
      </Card>
    );
  }
};
