const React = require('react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');

const ProgressField = ({ value }) => {
  const formattedValue = value ? `${value} %` : '';

  return (
    <TextField
      readOnly
      id="sidebar-progress"
      label="Progress"
      value={formattedValue} />
  );
};

module.exports = ProgressField;
