/* eslint-disable react/jsx-no-bind */
const React = require('react');
const List = require('@common/Menu/ListMenu/ListMenu.react');
const Item = require('@common/Menu/ListMenu/MenuItem.react');

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.selectButtonEl = null;
  }

  onClick() {
    this.setState({ open: true });
  }

  onClose() {
    this.setState({ open: false });
  }

  getItems() {
    return this.props.items.map((item) => (
      <Item
        key={item.value}
        onClick={() => this.props.onChange(this.props.name, item.value)}>
        {item.label}
      </Item>
    ));
  }

  getCurrent() {
    return this.props.items.filter((item) => item.value === this.props.value)[0];
  }

  render() {
    return (
      <div>
        <button type="button"
          ref={(el) => this.selectButtonEl = el}
          className="planning-people-repeat-select material-input-button"
          onClick={this.onClick.bind(this)}>
          {this.getCurrent().label}
        </button>
        <List
          open={this.state.open}
          onClose={this.onClose.bind(this)}
          anchorEl={this.selectButtonEl}
          centered>
          {this.getItems()}
        </List>
      </div>
    );
  }
}

module.exports = Select;
