const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const FavouriteIcon = require('../../common/FavouriteIcon.react');

module.exports = class ProjectInfoCell extends React.Component {
  /**
   * Check if favourites can be edited
   * (you cannot edit other users favourite)
   * @returns {boolean}
   */
  canEditFavourite() {
    return !this.props.userId;
  }

  render() {
    return (
      <TableCell className="timesheet-weekly__cell-options">
        <span className="timesheet-weekly__color-bar"
          style={{ backgroundColor: this.props.project.project.project_type.color }} />
        <FavouriteIcon projectId={this.props.project.id_project}
          canEdit={this.canEditFavourite()}
          favorite={this.props.project.favorite}
          archived={this.props.project.project.archived}
          addFavourite={this.props.addFavourite}
          removeFavourite={this.props.removeFavourite} />
      </TableCell>
    );
  }
};
