const React = require('react');
const { CSSTransition } = require('react-transition-group');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const Actions = require('../containers/Actions');
const OrderList = require('../containers/OrderList');
const OrderSidebar = require('../containers/sidebar/OrderSidebar');
const Modal = require('../containers/Modal');

const Orders = ({
  showSidebar,
}) => (
  <div className="wethod-section-body orders">
    <Actions />
    <OrderList />
    <Modal />
    <CSSTransition
      in={showSidebar}
      classNames="wethod-sidebar--transition"
      timeout={400}
      mountOnEnter
      unmountOnExit>
      <FormValidator>
        <OrderSidebar />
      </FormValidator>
    </CSSTransition>
  </div>
);

module.exports = Orders;
