/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Body = require('../containers/ShareModalBody');

module.exports = class ShareModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shareTo: null,
      notes: null,
    };
  }

  getOkActionClassName() {
    let name = 'wethod-button wethod-button--confirm';
    if (!this.isPersonSelected()) {
      name += ' disabled';
    }
    return name;
  }

  getActions() {
    return (
      <div className="basket-modal__actions">
        <button type="button"
          className="wethod-button wethod-button--warning"
          onClick={this.handleClose.bind(this)}>
          CANCEL
        </button>
        <button type="button"
          className={this.getOkActionClassName()}
          onClick={this.handleShare.bind(this)}>
          SHARE
        </button>
      </div>
    );
  }

  /**
   * Returns true if user has selected the colleague to share the opportunity.
   * @returns {boolean}
   */
  isPersonSelected() {
    return this.state.shareTo !== null;
  }

  handleShare(e) {
    e.preventDefault();
    if (this.isPersonSelected()) {
      this.props.share(this.state.notes, this.state.shareTo, this.props.opportunity.id);
    }
  }

  handleClose(e) {
    e.preventDefault();
    this.props.closeModal();
  }

  getTitle() {
    return 'Share opportunity';
  }

  updatePerson(name, person) {
    this.setState({
      shareTo: person ? person.id : null,
    });
  }

  updateNote(name, value) {
    this.setState({ notes: value });
  }

  getFeedback() {
    if (this.props.isSaving) {
      return <div className="wethod-modal2__feedback">Sharing...</div>;
    }
    return '';
  }

  render() {
    return (
      <Modal
        title={this.getTitle()}
        onCancelClick={this.handleClose.bind(this)}>
        <Body
          opportunity={this.props.opportunity}
          updatePerson={this.updatePerson.bind(this)}
          actions={this.getActions()}
          onNotesChange={this.updateNote.bind(this)} />
        {this.getActions()}
        {this.getFeedback()}
      </Modal>
    );
  }
};
