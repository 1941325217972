const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const CreationRow = require('../../containers/location/CreationRow');
const EditRow = require('../../containers/location/EditRow');

const TableBodyRow = ({ item, defaultLocation, updateErrors, errors, isValid }) => {
  const isNew = item.id == null || item.id === 'new-location';
  return (
    <ShowIf condition={isNew}
      showElse={(
        <EditRow
          item={item}
          defaultLocation={defaultLocation}
          updateErrors={updateErrors}
          errors={errors}
          isValid={isValid} />
      )}>
      <CreationRow item={item} updateErrors={updateErrors} errors={errors} isValid={isValid} />
    </ShowIf>
  );
};

module.exports = TableBodyRow;
