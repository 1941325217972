const React = require('react');
const FileManagerExternalContent = require('@common/widgets/Files/components/FileManagerExternalContent.react');
const File = require('@common/widgets/Files/models/File');
const ExternalDrive = require('@common/widgets/Files/models/ExternalDrive');
const Path = require('@common/widgets/Files/models/Path');
const EmptyListMessage = require('@common/widgets/Files/components/EmptyList.react');
const ExternalDriveIntegration = require('../../../../../../models/CanvasGoogleDriveIntegration');
const CannotSyncModal = require('./modals/CannotSyncModal.react');
const CannotUploadModal = require('./modals/CannotUploadModal.react');

module.exports = class FileManagerGoogleContent extends React.Component {
  static getFormattedFiles(files) {
    return files.map((file) => new File(file.id, file.name, file.mimeType, file.webViewLink,
      file.size, null, null, file.modifiedTime));
  }

  constructor(props) {
    super(props);

    this.state = {
      searchKeyword: '',
      isLoadingFiles: false,
      hasMore: true,
      isUploading: false,
      deletingFiles: [],
      files: [],
      isSyncing: false,
      isCreatingFolder: false,
      externalDrive: new ExternalDrive(props.externalDriveConfig),
      showModal: null,
      uploadErrors: [],
    };

    this.projectFolderPath = '';

    this.path = new Path();
    this.path = this.path.add(this.state.externalDrive.projectFolder);

    this.loadMore = this.loadMore.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.onSync = this.onSync.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.createProjectFolder = this.createProjectFolder.bind(this);
  }

  onSearch(keyword, parentId) {
    this.setState({ searchKeyword: keyword }, () => {
      this.loadMore(1, parentId);
    });
  }

  onUpload(file, parentId) {
    this.setState({ isUploading: true }, () => {
      const request = ExternalDriveIntegration.uploadFile(file, this.props.projectId, parentId);
      $.when(request)
        .done((uploadedFile) => {
          this.setState({
            files: this.addFiles([uploadedFile]),
            isUploading: false,
          });
        })
        .fail((error) => this.setState({
          showModal: 'cannot-upload',
          uploadErrors: [error.reason],
          isUploading: false,
        }));
    });
  }

  onDeleteFile(file) {
    const { id } = file;
    this.setState({ deletingFiles: this.addToDeletingFiles(id) }, () => {
      const request = ExternalDriveIntegration.deleteFile(this.props.projectId, id);
      $.when(request)
        .done(() => {
          this.setState({
            files: this.removeFile(id),
            deletingFiles: this.removeFromDeletingFiles(id),
          });
        });
    });
  }

  onSync() {
    this.setState({ isSyncing: true }, () => {
      const request = ExternalDriveIntegration.syncProjectFolder(this.props.projectId);
      $.when(request)
        .done((storage) => {
          const externalDrive = new ExternalDrive({
            ...this.props.externalDriveConfig,
            storage,
          });
          this.setState({
            externalDrive,
            isSyncing: false,
          });
        })
        .fail((data) => {
          this.projectFolderPath = data.canonical_path;

          this.setState({
            isSyncing: false,
            showModal: 'cannot-sync',
          });
        });
    });
  }

  getModal() {
    switch (this.state.showModal) {
      case 'cannot-sync':
        return (
          <CannotSyncModal close={this.closeModal}
            isWaiting={this.state.isCreatingFolder}
            createFolder={this.createProjectFolder}
            missingPath={this.projectFolderPath} />
        );
      case 'cannot-upload':
        return <CannotUploadModal close={this.closeModal} errors={this.state.uploadErrors} />;
      default:
        return null;
    }
  }

  createProjectFolder() {
    this.setState({ isCreatingFolder: true }, () => {
      const request = ExternalDriveIntegration.createProjectFolder(this.props.projectId);

      $.when(request)
        .done((storage) => {
          const externalDrive = new ExternalDrive({
            ...this.props.externalDriveConfig,
            storage,
          });
          this.setState({
            externalDrive,
            showModal: false,
            isCreatingFolder: false,
          });
          this.loadMore(null, storage.external_id);
        });
    });
  }

  loadMore(size, parentId) {
    this.setState({ isLoadingFiles: true }, () => {
      const request = ExternalDriveIntegration
        .getFiles(this.props.projectId, this.state.searchKeyword, null, parentId);
      $.when(request)
        .done((response) => {
          this.setState({
            files: FileManagerGoogleContent.getFormattedFiles(response.files),
            hasMore: false,
            isLoadingFiles: false,
          });
        }).fail(() => {
          this.setState({ isLoadingFiles: false });
          this.onSync();
        });
    });
  }

  closeModal() {
    this.setState({ showModal: null });
  }

  addFiles(files) {
    const formattedFiles = FileManagerGoogleContent.getFormattedFiles(files);
    return this.state.files.concat(formattedFiles);
  }

  addToDeletingFiles(id) {
    return this.state.deletingFiles.concat(id);
  }

  removeFromDeletingFiles(id) {
    return this.state.deletingFiles.filter((file) => file.id !== id);
  }

  removeFile(id) {
    return this.state.files.filter((file) => file.id !== id);
  }

  render() {
    return (
      <FileManagerExternalContent loadMore={this.loadMore}
        onUpload={this.onUpload}
        onSearch={this.onSearch}
        emptyFilesMessage={(
          <EmptyListMessage
            title="Deliverables & docs storage"
            description="Share documents and key deliverables" />
        )}
        externalDrive={this.state.externalDrive}
        onSync={this.onSync}
        path={this.path}
        hasMore={this.state.hasMore}
        isUploading={this.state.isUploading}
        deletingFiles={this.state.deletingFiles}
        searchKeyword={this.state.searchKeyword}
        canEdit={this.props.canEdit}
        files={this.state.files}
        setHeaderAction={this.props.setHeaderAction}
        isLoadingFiles={this.state.isLoadingFiles}
        isSyncing={this.state.isSyncing}
        isCreatingFolder={this.state.isCreatingFolder}
        modal={this.getModal()}
        onDeleteFile={this.onDeleteFile} />
    );
  }
};
