const React = require('react');
const Tooltip = require('@common/tooltip/Tooltip.react');

const RefreshButton = ({ onClick }) => (
  <button type="button"
    className="wethod-icon-button briefing-bottom-actions__button"
    onClick={onClick}>
    <Tooltip
      content="Refresh data"
      id="tooltip-refresh" />
    <div className="wethod-icon wethod-icon-ps-correction wethod-icon-ps-correction--black" />
  </button>
);

module.exports = RefreshButton;
