/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const About = require('../containers/About');
const HistoryActions = require('./HistoryActions.react');
const EventsFuture = require('../containers/EventsFuture/EventsFuture');
const EventsPast = require('../containers/EventsPast/EventsPast');
const EventCreator = require('../containers/EventCreator/EventCreator');
const Modal = require('../containers/Modal');

module.exports = class Timeline extends React.Component {
  render() {
    return (
      <div className="timeline wethod-section-body">
        <SectionHeader
          current_section="Timeline"
          preview_anchor_id="timeline"
          helper_url="pipeline/index/#timeline" />
        <div className="timeline-project__info">
          <About />
        </div>
        <div className="timeline__history">
          <HistoryActions />
          <div className="timeline__events">
            <EventsFuture />
            <EventCreator />
            <EventsPast />
          </div>
        </div>
        <Modal />
      </div>
    );
  }
};
