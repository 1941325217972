const React = require('react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');
const Task = require('../Task.react');
const PrimaryInfo = require('../TaskPrimaryInfo.react');
const Name = require('../TaskName.react');
const Levels = require('../TaskLevels.react');
const CurrencyManager = require('../../../../containers/CurrencyManager');
const ExternalCosts = require('../../Cell/ExternalCostCell.react');
const Markup = require('../TaskMarkup.react');
const DaysCell = require('../../Cell/ManDaysCell.react');
const Cost = require('../../Cell/CostCell.react');
const Price = require('../../Cell/PriceCell.react');
const IconCell = require('../../Cell/IconCell.react');
const TaskNotes = require('../TaskNotes.react');
const MoreOptions = require('../../MoreOptionsButton/MoreOptionsButton.react');
const MoreOptionsMenuItem = require('../../MoreOptionsButton/MoreOptionsMenuItem.react');
const Quantity = require('./ProductTaskQuantity.react');

/**
 * Special task created from a product.
 *
 * @param {boolean} created - True if task did not exist in previous budget version
 * @param {boolean} deleted  - True if task did exist in previous budget version
 * @param {boolean} editableBudget - True if budget is editable
 * @param {string} name - Task name
 * @param {string | null} currency - Task currency
 * @param {number} markup - Task markup
 * @param {number} days - Task total days
 * @param {number} cost - Task total cost
 * @param {number} price - Task total price
 * @param {number} externalCosts - Task external cost
 * @param levels
 * @param {function} deleteTask - Invoked to delete task
 * @param {function} updateTaskInfo - Invoked to update task info
 * @param {{quantity:number, markup:number, external_cost:number, levels:[]}} previous - Task values in the previous budget version
 * @param {number} quantity - Task quantity
 * @param {number} id - Task ID
 * @param {boolean} priceIsFixed - True if price fixed set from product, false if price calculated from task attributes as usual
 * @param {[]} levelsTotals
 * @param {string} notes
 * @return {JSX.Element}
 * @constructor
 */
const ProductTask = ({
  created, deleted, editableBudget, name, currency, markup, days, cost, price,
  externalCosts, levels, deleteTask, updateTaskInfo, previous, quantity, id, priceIsFixed,
  levelsTotals, column, notes,
}) => {
  function saveQuantity(amount) {
    updateTaskInfo(id, { product_quantity: amount });
  }

  const getPriceTooltip = () => {
    if (priceIsFixed) {
      return 'Price set by the product';
    }
    return '';
  };

  /**
   * Return levels with total values.
   */
  const levelsWithTotals = levels.map((level, index) => ({
    ...level,
    total: levelsTotals[index],
  }));

  const editable = editableBudget && !deleted;

  if (column === 'left') {
    return (
      <Task exists={created} existed={deleted}>
        <PrimaryInfo>
          <Name>{name}</Name>
          <Quantity editable={editable}
            id={`task-${id}-quantity`}
            save={saveQuantity}
            previousValue={previous.quantity}>
            {quantity}
          </Quantity>
        </PrimaryInfo>
      </Task>
    );
  }
  if (column === 'center') {
    return (
      <Task exists={created} existed={deleted}>
        <PrimaryInfo>
          <Levels items={levelsWithTotals}
            previousItems={previous.levels} />
        </PrimaryInfo>
      </Task>
    );
  }
  return (
    <Task exists={created} existed={deleted}>
      <PrimaryInfo>
        <CurrencyManager>
          <ExternalCosts currencyCode={currency}
            previousValue={previous.external_cost}
            value={externalCosts} />
        </CurrencyManager>
        <Markup previousValue={previous.markup}>{markup}</Markup>
        <DaysCell>{days}</DaysCell>
        <Cost>{cost}</Cost>
        <Tooltip label={getPriceTooltip()}>
          <Price label="Task price">{price}</Price>
        </Tooltip>
        <IconCell>
          <TaskNotes editable={editable} notes={notes} id={id} />
        </IconCell>
        <MoreOptions editable={editable}>
          <MoreOptionsMenuItem onClick={deleteTask}>Delete</MoreOptionsMenuItem>
        </MoreOptions>
      </PrimaryInfo>
    </Task>
  );
};

module.exports = ProductTask;
