/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations,no-shadow */
const React = require('react');
const Search = require('@common/SearchInput.react');
const MemberTag = require('@common/Tag/Tag.react');
const Loader = require('@common/Loader/Loader.react');
const MemberResultElement = require('./MemberResultElement.react');

module.exports = class MemberMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredPeople: this.props.people,
      keyword: '',
    };
  }

  componentDidUpdate(prevProps) {
    const prevPeople = prevProps.people ? prevProps.people.length : 0;
    const newPeople = this.props.people ? this.props.people.length : 0;
    if (prevPeople !== newPeople) {
      this.setState({
        filteredPeople: this.props.addedPeople && this.props.addedPeople.length > 0
          ? this.props.people
            .filter((member) => !this.props.addedPeople.some((person) => person.id === member.id))
          : this.props.people,
      });
    }
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Filter the list of people with the given keyword, checking that they're not already added
   * @param keyword
   */
  handleMemberFilter(keyword) {
    this.setState({
      filteredPeople: this.props.people.filter((member) => (this.found(keyword, member.name)
        || this.found(keyword, member.surname)) && (!this.props.addedPeople
        || !this.props.addedPeople.some((person) => person.id === member.id))),
      keyword,
    });
  }

  handleMemberSelect(id) {
    const member = this.props.people.find((member) => member.id === id);
    this.setState({
      filteredPeople: this.state.filteredPeople.filter((member) => member.id !== id),
    });
    this.props.onSelect(member);
  }

  handleMemberDelete(memberTagId) {
    const member = this.props.people.find((member) => member.id === memberTagId);
    if (member) {
      const containsKeyword = this.found(this.state.keyword, member.name)
        || this.found(this.state.keyword, member.surname);

      if (containsKeyword) {
        this.setState({
          filteredPeople: this.state.filteredPeople.concat(member),
        });
      }
    }
    this.props.onDelete(memberTagId);
  }

  getShortName(member) {
    if (member) {
      return `${member.name.slice(0, 1)} ${member.surname}`;
    }
  }

  getCompleteName(member) {
    if (member) {
      return `${member.name} ${member.surname}`;
    }
  }

  getDelete() {
    if (this.props.canEdit && !this.props.isSaving) {
      return this.handleMemberDelete.bind(this);
    }
    return null;
  }

  getMembersTagList() {
    if (this.props.addedPeople && this.props.addedPeople.length > 0) {
      return this.props.addedPeople.map((member) => (
        <MemberTag key={member.id} id={member.id} onDelete={this.getDelete()}>
          {this.getShortName(member)}
        </MemberTag>
      ));
    }
    return <span className="project-canvas-menu-members__result--empty">No members added</span>;
  }

  getMembersResultList() {
    if (this.state.filteredPeople && this.state.filteredPeople.length > 0) {
      return (
        this.state.filteredPeople.map((member) => (
          <MemberResultElement key={member.id}
            onClick={this.handleMemberSelect.bind(this)}
            disabled={this.props.isDeleting}
            {...{
              ...member,
            }} />
        ))
      );
    }
    if (this.state.filteredPeople && this.state.filteredPeople.length === 0) {
      return <span className="project-canvas-menu-members__result--empty">No member found</span>;
    }
  }

  getMembersSearch() {
    if (this.props.isLoading) {
      return <Loader />;
    }
    if (this.props.canEdit) {
      return (
        <div className="project-canvas-menu-members__search">
          <Search placeholder="Add member" filter={this.handleMemberFilter.bind(this)} />
          <div className="members-result-list">
            {this.getMembersResultList()}
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="project-canvas-menu project-canvas-menu-members">
        <div className="project-canvas-menu-members__tag-list">
          {this.getMembersTagList()}
        </div>
        {this.getMembersSearch()}
      </div>
    );
  }
};
