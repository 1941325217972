const { connect } = require('react-redux');
const Component = require('@common/TabSwitcher.react');
const actions = require('../actions');

const items = [
  {
    key: 'mine',
    name: 'Mine',
  }, {
    key: 'incoming',
    name: 'Incoming',
  }, {
    key: 'all',
    name: 'All',
  },
];

const mapStateToProps = (state) => ({
  items,
  current: state.ownerFilter,
});

const mapDispatchToProps = (dispatch) => ({
  handleClick: (key) => dispatch(actions.changeOwnerFilter(key)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
