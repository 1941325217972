const React = require('react');
const NumericField = require('@common/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const DaysCell = require('../Cell/DaysCell.react');
const Delta = require('../NumericDelta.react');
const CollaborativeInput = require('../../../containers/BudgetCollaborativeInput');

/**
 * Level man days input for task.
 * @type {TaskLevel}
 */
module.exports = class TaskLevel extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: this.props.value };

    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const changedValueFromParent = this.props.value !== prevProps.value;

    if (changedValueFromParent) {
      this.updateValue(this.props.value);
    }
  }

  onChange(e) {
    if (!this.props.readOnly) {
      this.setState({ value: e.target.value });

      let numericValue = parseFloat(e.target.value);
      numericValue = !Number.isNaN(numericValue) ? numericValue : 0;

      if (this.props.save) {
        this.props.save(this.props.levelAmountId, this.props.id, numericValue);
      }
    }
  }

  updateValue(value) {
    this.setState({ value });
  }

  render() {
    return (
      <DaysCell>
        <Delta previousValue={this.props.previousValue}
          actualValue={this.props.value}
          decimalPlaces={1} />
        <CollaborativeInput id={`task-${this.props.taskId}-level-${this.props.id}`}>
          <NumericField label={`task-${this.props.taskId}-level-${this.props.id}`}
            onChange={this.onChange}
            value={this.state.value}
            readOnly={this.props.readOnly}
            precision={1}
            hideZero />
        </CollaborativeInput>
      </DaysCell>
    );
  }
};
