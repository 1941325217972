const React = require('react');
const moment = require('moment');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const status = require('../../segmentsStatus');

const TimelineSegment = ({ segment, visibleFrom, visibleTo, selected, selectable, onClick }) => {
  // Count visible months considering visibleFrom and visibleTo
  function getSegmentMonths() {
    const from = segment.from
      ? moment.max([moment(segment.from), moment(visibleFrom).startOf('month')])
      : moment(visibleFrom).startOf('month');
    const to = segment.to
      ? moment.min([moment(segment.to), moment(visibleTo).endOf('month')])
      : moment(visibleTo).endOf('month');
    return Math.round(to.diff(from, 'months', true));
  }

  const segmentMonths = getSegmentMonths();

  function handleClick() {
    if (selectable && !selected) {
      onClick(segment.status);
    }
  }

  const getClass = () => {
    const style = 'segment-timeline__segment';
    if (selected) {
      return `${style} ${style}--selected`;
    }
    if (!selectable) {
      return `${style} ${style}--disabled`;
    }
    return style;
  };

  return (
    <ShowIf condition={segmentMonths > 0}>
      <div className={getClass()}
        aria-label={status.LABEL[segment.status]}
        onClick={handleClick}
        style={{
          flex: segmentMonths,
          '--segment-color': status.COLORS[segment.status],
        }} />
    </ShowIf>
  );
};

module.exports = TimelineSegment;
