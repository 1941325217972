const request = require('./Request');

const PlanningSettings = {
  getAvailableHoursGrouping() {
    return request({
      method: 'get',
      url: '/authorization/settings/planning/available-hours-grouping',
    });
  },
  setAvailableHoursGrouping(data) {
    return request({
      method: 'patch',
      url: '/authorization/settings/planning/available-hours-grouping',
      data,
    });
  },
};

module.exports = PlanningSettings;
