const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const SidebarButton = require('@common/OpenSidebarButton.react');
const { formatDate } = require('../../../../../services/FormatService');

module.exports = class BudgetTemplate extends React.Component {
  handleClick() {
    this.props.openSidebar(this.props.template);
  }

  getDate() {
    return formatDate(this.props.template.created_on);
  }

  getCreator() {
    return this.props.template.created_by
      ? `${this.props.template.created_by.name} ${this.props.template.created_by.surname}`
      : '';
  }

  render() {
    return (
      <TableRow>
        <TableCell className="budget-template-table__column--name">{this.props.template.name}</TableCell>
        <TableCell className="budget-template-table__column--description">{this.props.template.description}</TableCell>
        <TableCell className="budget-template-table__column--info">
          Created by {this.getCreator()} on {this.getDate()}
        </TableCell>
        <TableCell className="budget-template-table__column--actions">
          <SidebarButton
            onClick={this.handleClick.bind(this)} />
        </TableCell>
      </TableRow>
    );
  }
};
