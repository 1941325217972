const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const RequestManager = require('../../../../../containers/RequestManager');
const ModalContent = require('./DraftStatusModalContent.react');

const DraftStatusModal = ({ closeModal }) => (
  <Modal onClose={closeModal} title="Back to draft">
    <RequestManager>
      <ModalContent closeModal={closeModal} />
    </RequestManager>
  </Modal>
);

module.exports = DraftStatusModal;
