const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('./NotesModalContent.react');
const PermissionManager = require('../../../../../containers/PermissionManager');
const RequestManager = require('../../../../../containers/RequestManager');

const NotesModal = ({ closeModal, notes }) => (
  <Modal onClose={closeModal} title="Notes">
    <RequestManager>
      <PermissionManager>
        <ModalContent text={notes} onClose={closeModal} />
      </PermissionManager>
    </RequestManager>
  </Modal>
);

module.exports = NotesModal;
