const { connect } = require('react-redux');
const Component = require('@common/Table2/TableSearch.react');
const actions = require('../actions');

const hasIdFilter = (string) => {
  const regex = new RegExp(/^id:\d+$/);

  return regex.test(string);
};

const mapStateToProps = (state) => ({
  placeholder: 'Search by project, user, or manager',
  className: hasIdFilter(state.searchFilter) ? 'wethod-highlighter--search-keyword' : '',
});

const mapDispatchToProps = (dispatch) => ({
  filter: (key) => dispatch(actions.changeSearchFilter(key)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
