const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const NumericField = require('@common/inputs/NumericField/BasicNumericField/BasicNumericField.react');

const ExternalCostsCell = ({
  readOnly, value, name, label, style, suffix,
}) => (
  <TableCell className="pipeline-item__costs" style={style}>
    <NumericField readOnly={readOnly}
      label={label}
      suffix={suffix}
      value={value}
      name={name} />
  </TableCell>
);

module.exports = ExternalCostsCell;
