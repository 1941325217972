const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const Content = require('@common/Modal/ModalContent.react');
const Body = require('@common/Modal/ModalBody.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const BodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Typography = require('@common/Typography/Typography.react');

const InvalidIntercompanyInvitationModal = ({ onCloseClick }) => (
  <Modal onClose={onCloseClick} title="Connect an intercompany project">
    <Content>
      <Body>
        <BodyBlock>
          Seems like this invitation's link is <Typography weight="bold">not valid</Typography>, please try again or ask for another one.
        </BodyBlock>
      </Body>
      <Footer>
        <FooterAction onClick={onCloseClick}>
          Close
        </FooterAction>
      </Footer>
    </Content>
  </Modal>
);

module.exports = InvalidIntercompanyInvitationModal;
