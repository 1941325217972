/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');

module.exports = class NumericSummaryCellView extends React.Component {
  render() {
    return (
      <TableCell
        className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell--summary project-canvas-spreadsheet__cell-button">
        <span className="project-canvas-spreadsheet__cell-button-content">
          {this.props.value}
        </span>
        <span className="project-canvas-spreadsheet__cell--summary-type">{this.props.function}</span>
      </TableCell>
    );
  }
};
