const React = require('react');
const DurationInput = require('@common/inputs/DurationInput/OutlinedDurationInput/OutlinedDurationInput.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const Button = require('@common/Button/RoundedButton.react');
const Header = require('../SubsectionHeader.react');

class FullTimeEquivalent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      fte: this.props.fte,
    };

    this.updateErrors = this.updateErrors.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSave() {
    this.props.onSaveClick({ fte: this.state.fte });
  }

  updateErrors(name, errors) {
    this.setState((state) => ({ errors: { ...state.errors, [name]: errors } }));
  }

  isDirty() {
    return this.props.fte !== this.state.fte;
  }

  hasErrors() {
    return this.state.errors.fte && this.state.errors.fte.length > 0;
  }

  canSave() {
    return this.isDirty() && !this.hasErrors() && !this.props.isSaving;
  }

  render() {
    return (
      <div>
        <Header subtitle="Full Time Equivalent (FTE)"
          id="full-time-equivalent"
          description="Define the working hours per day which is your company standard. If you do not define one, the default value will be 8 hours a day." />
        <div className="company-settings-margin-top-xl company-settings--spaced">
          <div className="company-settings-fte__amount-form">
            <InputValidator updateErrors={this.updateErrors} constraints={['required', 'maxDuration:480', 'minDuration:1']}>
              <DurationInput onChange={this.handleChange}
                className="company-settings-fte__amount-form-value"
                id="fte"
                name="fte"
                label="Hours : Minutes"
                value={this.state.fte}
                errorText={this.state.errors?.fte?.[0]?.message}
                unit={DurationInput.UNIT_MINUTES}
                format={DurationInput.FORMAT_HM} />
            </InputValidator>
            <Button onClick={this.handleSave}
              disabled={!this.canSave()}
              className="company-settings__button company-settings__button--blue company-settings-fte__amount-form-save-button">
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

module.exports = FullTimeEquivalent;
