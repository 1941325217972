const React = require('react');
const _ = require('underscore');
const Select = require('@common/inputs/Select/ColorfulSelect/OutlinedColorfulSelect/OutlinedColorfulSelect.react');
const SelectItem = require('@common/Menu/ListMenu/MenuItemColorful.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');

const RiskSelect = ({
  updateErrors, value, color, onChange, error, helperText, name, availableValues,
  readOnly, disabled,
}) => {
  function handleChange(e) {
    const selected = availableValues.filter((risk) => risk.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  const getOptions = () => availableValues.map((risk) => (
    <SelectItem key={risk.id}
      value={risk.id}
      color={risk.color}>
      {_.unescape(risk.name)}
    </SelectItem>
  ));

  return (
    <InputValidator updateErrors={updateErrors} constraints={[]}>
      <Select readOnly={readOnly}
        disabled={!readOnly && disabled}
        value={value}
        id="sidebar-risk"
        label="Risk"
        color={color}
        onChange={handleChange}
        name={name}
        helperText={helperText}
        errorText={error}>
        {getOptions()}
      </Select>
    </InputValidator>
  );
};

module.exports = RiskSelect;
