/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const SearchInput = require('./SearchInput.react');

/**
 * This component hold the 'actions' buttons, seach and filters fir the heatmap section
 */
class Actions extends React.Component {
  render() {
    return (
      <div className="actions clearfix">
        <SectionHeader current_section="Heatmap" preview_anchor_id="projects-heatmap" helper_url="planning/index" />
        <SearchInput label="search client or category" searchWith={this.props.searchWith} />
      </div>
    );
  }
}

module.exports = Actions;
