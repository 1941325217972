const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');

const PlanUponApprovalErrorModal = ({ onCloseClick }) => (
  <Modal title="Oops" onCancelClick={onCloseClick}>
    <div>
      Seems like this project no longer needs a request to be planned,
      <br />
      so you can't edit it anymore.
      <br />
      <br />
      You can still approve or reject it. Otherwise, simply delete it.
    </div>
    <div className="wethod-modal2__actions">
      <Button onClick={onCloseClick}>Close</Button>
    </div>
  </Modal>
);

module.exports = PlanUponApprovalErrorModal;
