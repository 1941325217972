const React = require('react');
const Tag = require('@common/Tag/SwitchTag.react');

const TagFilter = ({ tag, onClick }) => {
  function handleTagClick(id, active) {
    onClick({ ...tag, active });
  }
  return (
    <Tag id={tag.id}
      active={tag.active}
      onClick={handleTagClick}
      className="knowledge__tag">
      {tag.name}
    </Tag>
  );
};

module.exports = TagFilter;
