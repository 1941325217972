/* eslint-disable react/sort-comp,consistent-return,react/jsx-no-bind */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const TextInput = require('@common/TextInput.react');

module.exports = class ItemCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focus: false, name: '' };
  }

  onFocus() {
    this.setState({ focus: true });
  }

  onBlur() {
    this.setState({ focus: false });
  }

  onNameChange(name, value) {
    const trimmed = value.trim();
    if (trimmed !== this.state.name) {
      this.setState({ name: trimmed });
    }
  }

  handleKeyUp(e) {
    const { name } = this.state;
    if (e.keyCode === 13 && name !== '') {
      this.props.onAddItem(name);
      this.setState({ name: '', focus: false });
    }
  }

  getInput() {
    if (this.props.waiting) {
      return <span className="project-canvas-spreadsheet__row-creator-feedback">Adding...</span>;
    }
    return (
      <TextInput
        placeholder="+ New row"
        onChange={this.onNameChange.bind(this)}
        onFocus={this.onFocus.bind(this)}
        onKeyUp={this.handleKeyUp.bind(this)}
        onBlur={this.onBlur.bind(this)} />
    );
  }

  getInstructionsClassName() {
    let name = 'project-canvas-spreadsheet__row-creator-instructions';
    const visible = !this.props.waiting && (this.state.focus || this.state.name !== '');
    if (visible) {
      name += ' project-canvas-spreadsheet__row-creator-instructions--visible';
    }
    return name;
  }

  getNameCellClassName() {
    let name = 'project-canvas-spreadsheet__cell--creator-name';
    if (this.state.focus) {
      name += ' project-canvas-spreadsheet__cell--creator-name--focus';
    }
    return name;
  }

  render() {
    return (
      <TableRow className="project-canvas-spreadsheet__row-creator">
        <TableCell className={this.getNameCellClassName()}>
          {this.getInput()}
        </TableCell>
        <TableCell>
          <span className={this.getInstructionsClassName()}>Press ENTER to add</span>
        </TableCell>
      </TableRow>
    );
  }
};
