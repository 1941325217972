/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return */
const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const ModalBody = require('@common/NotesModal/NotesModalBody.react');

module.exports = class NoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notes: this.props.project.notes,
      unsavedChanges: false,
    };
  }

  updateNote(content) {
    const unsavedChanges = this.props.project.notes !== content;

    this.setState({ notes: content, unsavedChanges });
  }

  handleSave() {
    if (this.state.unsavedChanges) {
      const updatedProject = { ...this.props.project, ...this.state };
      this.props.saveNote(updatedProject);
    }
  }

  getSaveStyle() {
    let style = 'wethod-button';
    if (!this.state.unsavedChanges) style += ' disabled';
    return style;
  }

  getBody() {
    if (!this.canEdit()) {
      return (
        <div className="timesheet-note__input">{this.state.notes}</div>
      );
    }
    return (
      <ModalBody note={this.state.notes || ''}
        updateNote={this.updateNote.bind(this)} />
    );
  }

  getActions() {
    if (!this.canEdit()) {
      return (
        <div className="timesheet-weekly__modal-actions">
          <button type="button" onClick={this.props.onCancelClick} className="wethod-button">Cancel</button>
        </div>
      );
    }
    return (
      <div className="timesheet-weekly__modal-actions">
        <button type="button" onClick={this.props.onCancelClick} className="wethod-button">Cancel</button>
        <button type="button" onClick={this.handleSave.bind(this)} className={this.getSaveStyle()}>Save</button>
      </div>
    );
  }

  getFeedback() {
    if (this.props.isSaving) return <div className="profile-contact-info__feedback">Saving...</div>;
    return null;
  }

  canEdit() {
    return this.props.project && this.props.project.can_edit;
  }

  render() {
    return (
      <Modal title="Insert a note" onCancelClick={this.props.onCancelClick} className="timesheet-weekly__modal">
        {this.getBody()}
        {this.getActions()}
        {this.getFeedback()}
      </Modal>
    );
  }
};
