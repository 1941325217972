const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const Button = require('@common/Button/RoundedButton.react');

const DeleteCompanyHolidayConfirmationModal = ({
  waitingFor,
  data,
  onClose,
  onDeleteCompanyHolidayConfirmationConfirm,
  onDeleteCompanyHolidayConfirmationCancel,
}) => {
  function handleCancel() {
    onDeleteCompanyHolidayConfirmationCancel(data);
    onClose();
  }
  function handleConfirm() {
    onDeleteCompanyHolidayConfirmationConfirm(data);
  }
  const isWaiting = waitingFor.some((key) => key.includes(data));

  function getFeedback() {
    return isWaiting ? 'Deleting...' : undefined;
  }

  return (
    <Modal title="Delete holiday" onClose={handleCancel}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            By deleting this company holiday,
            working hours capacity will be restored to the one previously set for each team member.
          </ModalBodyBlock>
          <ModalBodyBlock>
            Are you sure you want to continue?
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={getFeedback()}>
          <Button className="company-settings__button company-settings__button--white" onClick={handleCancel}>Cancel</Button>
          <Button className="company-settings__button company-settings__button--blue"
            onClick={handleConfirm}
            disabled={isWaiting}>
            Yes, delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = DeleteCompanyHolidayConfirmationModal;
