const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const ModalBody = require('@common/NotesModal/NotesModalBody.react');

module.exports = class PersonNoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: this.props.note,
    };
  }

  handleSave() {
    this.props.editNote(this.props.personId, this.state.note);
  }

  updateNote(content) {
    this.setState({ note: content });
  }

  render() {
    return (
      <Modal
        title="Write a short review"
        onCancelClick={this.props.onCancelClick}
        className="timesheet-weekly__modal">
        <ModalBody
          note={this.state.note || ''}
          updateNote={this.updateNote.bind(this)} />
        <div className="project-review__modal-actions">
          <button type="button" onClick={this.props.onCancelClick} className="wethod-button">Cancel</button>
          <button type="button" onClick={this.handleSave.bind(this)} className="wethod-button">Save</button>
        </div>
      </Modal>
    );
  }
};
