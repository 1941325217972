const React = require('react');
const IconButtonTooltip = require('@common/TooltipFixed/IconButtonTooltip.react');

const TravelSidebarActions = ({ onDelete }) => (
  <IconButtonTooltip label="Delete request" onClick={onDelete} borderless>
    <span className="wethod-icon-delete wethod-icon-delete--black" />
  </IconButtonTooltip>
);

module.exports = TravelSidebarActions;
