const React = require('react');
const Modal = require('@common/modal2/Modal.react');

const InfoModal = ({ title, children, onCancelClick }) => (
  <Modal title={title} onCancelClick={onCancelClick} className="project-canvas-alert">
    {children}
    <div className="profile-contact-info__actions">
      <button type="button" onClick={onCancelClick} className="wethod-button">OK</button>
    </div>
  </Modal>
);

module.exports = InfoModal;
