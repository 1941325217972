const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const IconButtonTooltip = require('@common/TooltipFixed/IconButtonTooltip.react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

module.exports = class MoreOptionsButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };

    this.closeMenu = this.closeMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
  }

  setMenuAnchorRef(ref) {
    this.button = ref;
  }

  closeMenu() {
    this.setState({ showMenu: false });
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  hasOptions() {
    return React.Children.count(this.props.children) > 0;
  }

  render() {
    return (
      <ShowIf condition={this.hasOptions()} showElse={null}>
        <div style={{ display: 'inline-block' }}>
          <IconButtonTooltip label="Show options"
            borderless
            onClick={this.showMenu}
            rootRef={this.setMenuAnchorRef}>
            <Icon icon="more" />
          </IconButtonTooltip>
          <Menu
            open={this.state.showMenu}
            onClose={this.closeMenu}
            anchorEl={this.button}>
            {this.props.children}
          </Menu>
        </div>
      </ShowIf>
    );
  }
};
