const React = require('react');
const PropTypes = require('prop-types');
const _ = require('underscore');
const Accordion = require('@common/AdvancedSearch/FilterAccordion/FilterAccordion.react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const Tag = require('@common/Tag/Tag.react');

class CustomizableOptionsAutocompleteFilter extends React.Component {
  static getInputValue(item) {
    return _.unescape(item.value);
  }

  constructor(props) {
    super(props);

    this.state = {
      inputValue: null,
    };

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const addedValue = prevState.inputValue === null && this.state.inputValue !== null;
    if (addedValue) {
      this.resetInput();
    }
  }

  onChange(e, option) {
    const { value } = e.target;
    if (value !== '') {
      const newValue = this.getValues().concat(value);
      this.props.onChange(this.props.name, newValue);
      this.setState({ inputValue: option });
    }
  }

  onDelete(id) {
    const newValue = this.getValues().filter((value) => value !== id);
    this.props.onChange(this.props.name, newValue);
  }

  getValues() {
    return this.props.value;
  }

  getTags() {
    return this.getValues()
      .map((value) => <Tag key={value} id={value} onDelete={this.onDelete}>{value}</Tag>);
  }

  resetInput() {
    this.setState({ inputValue: null });
  }

  render() {
    return (
      <Accordion expanded={this.props.expanded}
        label={this.props.label}
        value={this.getValues()}
        className={this.props.className}>
        <div>
          <Autocomplete name={this.props.name}
            value={this.state.inputValue}
            loading={this.props.isLoading}
            onFilter={this.props.onFilter}
            getInputValue={CustomizableOptionsAutocompleteFilter.getInputValue}
            placeholder={this.props.placeholder}
            onChange={this.onChange}>
            {this.props.getOptions()}
          </Autocomplete>
          <div>{this.getTags()}</div>
        </div>
      </Accordion>
    );
  }
}

CustomizableOptionsAutocompleteFilter.defaultProps = {
  expanded: false,
  label: '',
  placeholder: '',
  className: '',
  value: [],
  onChange: null,
  isLoading: false,
};

CustomizableOptionsAutocompleteFilter.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  /**
   * Function to call when value changes.
   * @param name {string}
   * @param value {string}
   */
  onChange: PropTypes.func,
  expanded: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  /**
   * Function to call when input value changes.
   * @param name {string}
   * @param value {string}
   */
  onFilter: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

module.exports = CustomizableOptionsAutocompleteFilter;
