const React = require('react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');
const Cell = require('./Cell.react');

class NameCell extends React.Component {
  getTooltip() {
    return this.props.tooltip || '';
  }

  render() {
    return (
      <Tooltip label={this.getTooltip()}>
        <Cell className={`wethod-budget-calculator__cell-name ${this.props.className}`}>
          {this.props.children}
        </Cell>
      </Tooltip>
    );
  }
}

module.exports = NameCell;
