/* eslint-disable react/no-access-state-in-setstate,react/no-did-update-set-state */
const React = require('react');
const $ = require('jquery');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const Info = require('./ProjectInfo.react');
const Month = require('./ProjectMonth/ProjectMonthController.react');
const Total = require('./ProjectTotal.react');
const Actions = require('./ProjectActions.react');
const DateService = require('../../../../../../services/DateService');
const { isMe } = require('../../../../../../services/UserService');
const ProductionPlanModel = require('../../../../../../models/ProductionPlan');

module.exports = class Project extends React.Component {
  static isTotalProductionAccettable(value) {
    return Math.abs(value - 100) < 1;
  }

  /**
   * Return an array of month between start and end, formatted as 'YYYY-MM-DD'.
   *
   * @param start Moment
   * @param end Moment
   * @returns {Array}
   */
  static getMonthsInPeriod(start, end) {
    const length = end.diff(start, 'months') + 1;
    const months = [];
    for (let i = 0; i < length; i++) {
      const month = start.clone();
      months.push(month.add(i, 'months').format('YYYY-MM-DD'));
    }
    return months;
  }

  /**
   * Strips months with a 0 plan from the begin and from the end of the given months, returns the remaining.
   * @param months
   */
  static trimMonths(months) {
    const productiveMonths = months.filter((month) => month.amount !== 0);
    if (productiveMonths.length) {
      return Project.getMonthsInPeriod(moment(productiveMonths[0].month),
        moment(productiveMonths[productiveMonths.length - 1].month));
    }
    return [];
  }

  static compareDate(a, b) {
    const dateA = moment(a.month);
    const dateB = moment(b.month);
    if (dateA.isBefore(dateB)) {
      return -1;
    }
    if (dateA.isAfter(dateB)) {
      return 1;
    }
    return 0;
  }

  constructor(props) {
    super(props);

    this.state = {
      months: this.props.months,
      mode: this.getMode(), // view | edit | approve | saving
    };

    this.monthsInPeriod = Project
      .getMonthsInPeriod(moment(props.date_start), moment(props.date_end))
      .toString();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isSaving !== this.props.isSaving) {
      this.setState({
        mode: this.getMode(),
      });
    }
    const trimmedMonths = Project.trimMonths(this.state.months);
    const trimmedMonthsString = trimmedMonths.join();
    const prevTrimmedMonths = Project.trimMonths(prevState.months);
    const prevTrimmedMonthsString = prevTrimmedMonths.join();
    if (trimmedMonthsString !== prevTrimmedMonthsString) {
      const start = trimmedMonths[0];
      const end = trimmedMonths[trimmedMonths.length - 1];
      this.monthsInPeriod = Project.getMonthsInPeriod(moment(start), moment(end)).toString();
      this.props.updateProjectDuration(this.props.id, start, end);
    }
  }

  onDragStart(e) {
    const data = {
      months: this.state.months,
      project_id: this.props.id,
      date_end: this.props.date_end,
    };
    const monthsInDuration = $(e.target)
      .closest('.production-plan-project')
      .children('.production-plan-project__month--in-duration');

    const projectMirror = document.createElement('div');
    projectMirror.classList.add('production-plan-project--mirror');

    monthsInDuration.each((index, child) => {
      const month = document.createElement('span');
      month.classList.add('wethod-table__cell', 'production-plan-project__month', 'production-plan-project__month--editable');
      month.appendChild(child.cloneNode(true).children.item(0));
      projectMirror.appendChild(month);
    });
    document.body.appendChild(projectMirror);
    const dragImage = document.querySelector('.production-plan-project--mirror');
    e.dataTransfer.setDragImage(dragImage, dragImage.offsetWidth - 10, dragImage.offsetHeight - 4);
    e.dataTransfer.setData('wethod/production-plan-project', JSON.stringify(data));
    e.dataTransfer.dropEffect = 'move';
  }

  getMode() {
    if (this.props.isSaving) {
      return 'saving';
    }
    if (this.needApproval()) {
      return 'approve';
    }
    return 'view';
  }

  /**
   * Returns the planning for the given month, if any.
   *
   * @param month
   */
  getPlanned(month) {
    const { months } = this.state; // months compiled for the project
    for (let i = 0; i < months.length; i++) {
      if (months[i].month === month) {
        return months[i];
      }
    }
    return null;
  }

  getTotalProductionValue() {
    return (this.props.estimate * this.props.probability) / 100;
  }

  getTotalProduction() {
    const total = this.state.months.reduce((sum, month) => {
      if (month.amount) {
        return sum + month.amount;
      }
      return sum;
    }, 0);

    return numeral(Math.round(total)).format('0');
  }

  getClassName() {
    let name = 'production-plan-project';
    if (this.props.firstOfProbability) {
      name += ' production-plan-project--first-of-probability';
    }
    return name;
  }

  getTotalPastProduction() {
    return this.state.months
      .filter((month) => DateService.isPastMonth(month.month))
      .reduce((sum, month) => {
        if (month.amount) {
          return sum + month.amount;
        }
        return sum;
      }, 0);
  }

  getMonths() {
    return this.props.visibleMonths.map((month, index) => (
      <Month key={month}
        date={month}
        mode={this.state.mode}
        index={index}
        dateStart={this.props.date_start}
        dateEnd={this.props.date_end}
        editMonth={this.editMonth.bind(this)}
        isProjectMonth={this.isProjectMonth(month)}
        totalProductionValue={this.getTotalProductionValue()}
        move={this.move.bind(this)}
        moveProject={this.moveProject.bind(this)}
        projectId={this.props.id}
        onDragProjectStart={this.onDragStart.bind(this)}
        planned={this.getPlanned(month)} />
    ));
  }

  getContent() {
    return []
      .concat(<Info key="info"
        id={this.props.id}
        name={this.props.name}
        probability={this.props.firstOfProbability ? this.props.probability : null}
        jobOrder={this.props.job_order}
        isTimeBased={this.props.is_time_based_progress}
        client={this.props.client.name}
        finalNet={this.props.estimate}
        totalProductionValue={this.getTotalProductionValue()}
        pm={this.props.pm} />)
      .concat(this.getMonths())
      .concat(
        <TableCell key="actions" className="production-plan-project__actions">
          <Total value={this.getTotalProduction()}
            totalProductionValue={this.getTotalProductionValue()}
            isTotalProductionAccettable={Project.isTotalProductionAccettable} />
          <Actions mode={this.state.mode}
            projectId={this.props.id}
            canEdit={this.canEditProject()}
            saveProject={this.save.bind(this)}
            showOptions={this.props.visibleActionsProjectId === this.props.id}
            showActionsDropdown={this.showActionsDropdown.bind(this)}
            hideActionsDropdown={this.props.closeActionsDropdown}
            autoDistribute={this.autoDistribute.bind(this)}
            changeMode={this.changeMode.bind(this)} />
        </TableCell>,
      );
  }

  /**
   * Returns true if the given month belongs to the project's duration.
   *
   * @param month
   * @returns {boolean}
   */
  isProjectMonth(month) {
    return this.monthsInPeriod.indexOf(month) !== -1;
  }

  editMonth(month) {
    const hasPlanning = this.getPlanned(month.month);
    let months = [];

    if (hasPlanning === null) {
      months = this.state.months.concat(month).sort(Project.compareDate);
    } else {
      months = this.state.months.map((monthItem) => {
        if (monthItem.month === month.month) {
          return month;
        }
        return monthItem;
      });
    }

    this.setState({ months });
  }

  /**
   * Move plan's amount from month 'from' to month 'to'.
   * @param from {string} a month with format YYYY-MM-DD
   * @param to {string} a month with format YYYY-MM-DD
   */
  move(from, to) {
    const fromMonth = this.getPlanned(from);
    const toMonth = this.getPlanned(to);
    if (fromMonth) {
      let { months } = this.state;
      if (toMonth) {
        months = this.state.months
          .map((month) => {
            if (month.month === to) {
              return {
                ...month,
                amount: month.amount + fromMonth.amount,
                delta: 0,
              };
            }
            if (month.month === from) {
              return {
                ...month,
                amount: 0,
                delta: 0,
              };
            }
            return month;
          });
      } else {
        months = this.state.months
          .map((month) => {
            if (month.month === from) {
              return {
                ...month,
                amount: 0,
                delta: 0,
              };
            }
            return month;
          })
          .concat({
            ...fromMonth,
            month: to,
            delta: 0,
          })
          .sort(Project.compareDate);
      }

      this.setState({ months });
    }
  }

  /**
   * Last month moved from "from" to "to".
   * @param from
   * @param to
   */
  moveProject(from, to) {
    const difference = moment(to).diff(moment(from), 'months');
    const months = this.state.months.map((month) => { // if month can be edited
      if (!DateService.isPastMonth(month.month)) {
        const nextMonth = moment(month.month)
          .clone()
          .add(difference, 'months')
          .format('YYYY-MM-DD');
        return {
          ...month,
          month: nextMonth,
        };
      }
      return month;
    });
    const start = difference > 0 ? moment(this.state.months[0].month)
      : moment(this.state.months[0].month)
        .clone()
        .add(difference, 'months');
    const end = difference > 0 ? moment(this.state.months[this.state.months.length - 1].month)
      .clone()
      .add(difference, 'months') : moment(this.state.months[this.state.months.length - 1].month);
    const durationMonths = Project.getMonthsInPeriod(start, end);
    const filledMonths = durationMonths.map((monthString) => {
      const monthPlan = months.filter((month) => month.month === monthString)[0];
      if (!monthPlan) {
        return {
          amount: 0,
          delta: 0,
          month: monthString,
        };
      }
      return monthPlan;
    }).sort(Project.compareDate);

    this.setState({ months: filledMonths });
  }

  needApproval() {
    for (let i = 0; i < this.props.months.length; i++) {
      if (this.props.months[i].delta) {
        const delta = Math.round(this.props.delta * 10) / 10;
        return delta !== 0;
      }
    }
    return false;
  }

  changeMode(mode) {
    this.setState({ mode });
  }

  save() {
    const total = this.getTotalProduction();
    if (Project.isTotalProductionAccettable(total)) {
      this.props.saveProject({
        id: this.props.id,
        name: this.props.name,
        client: this.props.client.id,
        date_start: this.props.date_start,
        date_end: this.props.date_end,
        estimate: this.props.estimate,
        probability: this.props.probability,
        months: this.state.months.map((month) => ({
          ...month,
          delta: 0,
        })),
      });
    } else {
      this.props.showContactModal();
    }
  }

  showActionsDropdown() {
    this.props.showActionsDropdown(this.props.id);
  }

  /**
   * Distribute remaining production on the remaining project duration.
   */
  autoDistribute() {
    if (DateService.isPastMonth(this.props.date_end)) {
      this.props.showCannotAutoDistributePastModal();
    } else {
      $.when(ProductionPlanModel.getAutoDistribution(this.props.id))
        .done((response) => {
          const { months } = this.state;
          const updatedMonths = months
            .filter((month) => DateService.isPastMonth(month.month))
            .concat(response);
          this.setState({ months: updatedMonths });
          this.changeMode('edit');
          this.props.closeActionsDropdown();
        });
    }
  }

  isProjectMine() {
    const pmId = this.props.pm.id;
    const accountId = this.props.account ? this.props.account.id : null;

    return isMe(pmId) || isMe(accountId);
  }

  canEditProject() {
    const canEdit = this.isProjectMine()
      ? Wethod.PlanningApp.getPermission('modify')
      : Wethod.PlanningApp.getPermission('modify_other');

    return canEdit && !this.props.is_time_based_progress;
  }

  render() {
    return (
      <TableRow className={this.getClassName()} data-project-id={this.props.id}>
        {this.getContent()}
      </TableRow>
    );
  }
};
