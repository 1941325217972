const React = require('react');
const DateSlider = require('@common/SingleValueSlider.react');
const HeaderMonth = require('./HeaderMonth.react');

const TimelineHeader = ({ visibleMonths, setRef, onPrevClick, onNextClick, onTodayClick }) => (
  <div className="segment-timeline__header">
    <div>
      <DateSlider className="segment-timeline__header-date-selector"
        value="Today"
        onPrevClicked={onPrevClick}
        onNextClicked={onNextClick}
        onTodayClicked={onTodayClick} />
    </div>
    <div ref={setRef} className="segment-timeline__header-months">
      {visibleMonths.map((month) => (
        <HeaderMonth key={month} date={month} />
      ))}
    </div>
  </div>
);

module.exports = TimelineHeader;
