const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const IconLink = require('@common/TooltipFixed/IconLinkTooltip.react');
const LevelChart = require('./LanguageLevelChart.react');
const EmployeeInfo = require('./EmployeeInfo');

const EmployeeRow = ({ employee, selectedLanguage }) => {
  const selectedLanguageLevel = selectedLanguage
    ? employee.languages
      .filter((language) => language.name.toLowerCase() === selectedLanguage.toLowerCase())[0].level
    : null;

  return (
    <TableRow className="knowledge__employee-row">
      <TableCell name="name" className="knowledge__column-employee knowledge__column-employee-lang">
        <EmployeeInfo employee={employee} />
      </TableCell>
      <TableCell name="level" className="knowledge__column-level">
        <LevelChart data={selectedLanguageLevel} />
      </TableCell>
      <TableCell name="link" className="knowledge__column-link">
        <IconLink label="Go to profile"
          className="chargeability__button"
          borderless
          href={`#settings/profile/${employee.id}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
        </IconLink>
      </TableCell>
    </TableRow>
  );
};

module.exports = EmployeeRow;
