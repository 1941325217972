const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const Button = require('@common/Button/RoundedButton.react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');
const status = require('../segmentsStatus').default;
const { getTimelineLabel, TIMELINE_TIMESHEET } = require('../timelines');

module.exports = class CreateSegmentButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }

  onClose() {
    this.setState({ open: false });
  }

  onOpen() {
    this.setState({ open: true });
  }

  onMenuItemClick(timeline) {
    this.props.createSegmentModal(timeline);
    this.onClose();
  }

  setMenuAnchorRef(ref) {
    this.menuAnchorRef = ref;
  }

  getOptions() {
    return this.props.timelines?.map((timeline) => (
      <TimelineOption key={timeline.name}
        timeline={timeline}
        permissions={this.props.permissions}
        onClick={this.onMenuItemClick} />
    )) || [];
  }

  render() {
    return (
      <div>
        <Button onClick={this.onOpen}
          className="data-freezing-engine__actions-create-button"
          inputRef={this.setMenuAnchorRef}>
          Create segment
          <Icon icon="dropdown" />
        </Button>
        <ShowIf condition={this.menuAnchorRef != null}>
          <Menu open={this.state.open}
            bottom
            onClose={this.onClose}
            anchorEl={this.menuAnchorRef}
            className="wethod-select-menu">
            {this.getOptions()}
          </Menu>
        </ShowIf>
      </div>
    );
  }
};

const TimelineOption = ({
  timeline,
  permissions,
  onClick,
}) => {
  function hasSegment(segmentStatus) {
    return timeline.segments.some((segment) => segment.status === segmentStatus);
  }

  function hasPermissions(permission) {
    return permissions[permission];
  }

  function isTimelineDisabled() {
    switch (timeline.name.toLowerCase()) {
      case TIMELINE_TIMESHEET:
        return hasSegment(status.PAUSED, timeline) || !hasPermissions('pause');
      default:
        return hasSegment(status.IN_REVIEW, timeline);
    }
  }

  const isDisabled = isTimelineDisabled();

  function handleClick() {
    if (!isDisabled) {
      onClick(timeline.name);
    }
  }

  function getTooltipMessage() {
    if (!isDisabled) {
      return '';
    }
    switch (timeline.name.toLowerCase()) {
      case TIMELINE_TIMESHEET:
        return hasPermissions('pause')
          ? 'There is a paused segment in this timeline'
          : "You don't have permission to change paused period";
      default:
        return 'There is a segment in review in this timeline';
    }
  }

  return (
    <MenuItem
      value={timeline.name}
      className={isDisabled ? 'disabled' : null}
      onClick={handleClick}>
      <Tooltip label={getTooltipMessage()}>
        {getTimelineLabel(timeline.name)}
      </Tooltip>
    </MenuItem>
  );
};
