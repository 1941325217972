const { connect } = require('react-redux');
const Component = require('@common/AdvancedSearch/filters/RadioFilter/RadioFilter.react');

const mapStateToProps = () => ({
  availableOptions: [{
    label: 'Enabled',
    value: 'enabled',
  }, {
    label: 'Disabled',
    value: 'disabled',
  }, {
    label: 'All',
    value: 'all',
  }],
});

module.exports = connect(mapStateToProps)(Component);
