const React = require('react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');

const DeleteJobTitleModalContent = ({
  closeModal, isPending, deleteJobTitle, jobTitleId,
  taskId, dayId,
}) => {
  const isSaving = () => isPending('delete-job-title');

  const feedback = isSaving() ? 'Deleting...' : '';

  function handleDelete() {
    deleteJobTitle(dayId, taskId, jobTitleId);
  }

  return (
    <ModalContent>
      <ModalBody>
        Do you really want to remove this job title?
      </ModalBody>
      <Footer feedback={feedback}>
        <FooterAction onClick={closeModal} disabled={isSaving()}>Cancel</FooterAction>
        <FooterAction onClick={handleDelete} disabled={isSaving()}>Yes</FooterAction>
      </Footer>
    </ModalContent>
  );
};

module.exports = DeleteJobTitleModalContent;
