const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const Button = require('@common/Button/RoundedButton.react');

const UpdateFteConfirmationModal = ({
  data,
  isSaving,
  onClose,
  save,
}) => {
  function handleConfirm() {
    save(data.fte);
  }

  function getFeedback() {
    return isSaving ? 'Saving...' : '';
  }

  return (
    <Modal title="Change FTE" onClose={onClose}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            You are changing the Full Time Equivalent for your company, this will influence both
            planning and timesheet.
          </ModalBodyBlock>
          <ModalBodyBlock>
            Make sure to check Capacity Group and align accordingly.
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={getFeedback()}>
          <Button className="company-settings__button company-settings__button--white"
            onClick={onClose}>Cancel
          </Button>
          <Button className="company-settings__button company-settings__button--blue"
            onClick={handleConfirm}
            disabled={!data || isSaving}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = UpdateFteConfirmationModal;
