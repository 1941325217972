const React = require('react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const RadialSelect = require('@common/inputs/RadialSelect/RadialSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItem.react');

const ProbabilityInput = ({
  updateErrors, readOnly, value, color, onChange, error, disabled, name,
}) => {
  const availableValues = [0, 10, 25, 50, 75, 90, 100];

  const getOptions = () => availableValues.map((percentage) => (
    <SelectItem key={percentage} value={percentage} label={percentage}>{percentage} %</SelectItem>
  ));

  return (
    <InputValidator updateErrors={updateErrors} constraints={['required']}>
      <RadialSelect value={value}
        label="Probability"
        color={color}
        onChange={onChange}
        name={name}
        errorText={error}
        disabled={!readOnly && disabled}
        readOnly={readOnly}>
        {getOptions()}
      </RadialSelect>
    </InputValidator>
  );
};

module.exports = ProbabilityInput;
