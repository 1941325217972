const React = require('react');
const Checkbox = require('@common/inputs/Checkbox/Checkbox.react');
const Row = require('./SettingsRow.react');

const CheckboxRow = ({
  id, name,
  label, description, feedback,
  value, checked, disabled, border,
  className, rowClassName,
  onChange,
}) => {
  const style = 'company-settings__checkbox company-settings__row-checkbox';
  const labelClassName = label ? 'company-settings__row-label' : '';

  return (
    <Row feedback={feedback} border={border} className={rowClassName}>
      <div
        className={`${className} ${style} ${labelClassName}`}>
        <Checkbox id={id}
          name={name}
          label={label}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled} />
      </div>
      <div className="company-settings__description">{description}</div>
    </Row>
  );
};

module.exports = CheckboxRow;
