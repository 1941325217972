/* eslint-disable react/sort-comp,consistent-return,react/jsx-no-bind */
const React = require('react');
const dragula = require('react-dragula');
const Table = require('@common/Table2/Table2.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const ItemCreator = require('./ItemCreator.react');
const Item = require('./Item.react');
const HeadContent = require('../../../containers/Boards/Spreadsheet/HeadContent');
const arrayMover = require('../../../services/arrayMover');
const ItemSummary = require('./ItemSummary.react');

module.exports = class Spreadsheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = { nameAttributeWidth: null };
  }

  onAddItem(name) {
    this.props.addItem(name);
  }

  hasHorizontalScroll() {
    return this.getContentWidth() > 1024;
  }

  componentDidMount() {
    if (this.props.focusedBoardId === this.props.boardId) {
      this.props.scrollToBoard();
    }
    this.drake = this.dragulaDecorator(this.bodyEl);
  }

  saveColumnSummary(func, attributeId) {
    this.props.saveColumnSummary(this.props.boardId, attributeId, func);
  }

  /**
   * Returns true if at least one column needs to display a summary.
   * @return {boolean}
   */
  summaryNeeded() {
    return this.props.structure.filter((attribute) => attribute.summary !== null).length > 0;
  }

  getItems() {
    let items = this.props.items
      .map((item, index) => (
        <Item key={item.id}
          canEdit={this.props.canEdit}
          onEditName={this.props.editItemName}
          isDeleting={this.props.isDeletingItem(item.id)}
          editAttributeValue={this.props.editAttributeValue}
          addAttributeValueMember={this.props.addAttributeValueMember}
          deleteItem={this.props.deleteItem}
          boardId={this.props.boardId}
          deleteAvailableStatus={this.props.deleteAvailableStatus}
          createAvailableStatus={this.props.createAvailableStatus}
          updateAvailableStatus={this.props.updateAvailableStatus}
          deleteMember={this.props.deleteMember}
          deleteMilestone={this.props.deleteMilestone}
          isWaitingForCellSaving={this.props.isWaitingForCellSaving}
          isWaitingForMemberDeleting={this.props.isWaitingForMemberDeleting}
          structure={this.props.structure}
          dataSort={index}
          {...item} />
      ));

    if (this.props.canEdit) {
      items = items.concat(<ItemCreator key="creator"
        waiting={this.props.waitingForAddingItem}
        onAddItem={this.onAddItem.bind(this)} />);
    }
    if (this.summaryNeeded()) {
      items = items.concat(<ItemSummary key="summary"
        changeSummary={this.saveColumnSummary.bind(this)}
        canEdit={this.props.canEdit}
        items={this.props.items}
        attributes={this.props.structure} />);
    }
    return items;
  }

  updateNameAttributeWidth(width) {
    this.setState({ nameAttributeWidth: width });
  }

  /**
   * Get spreadsheet's content width, based on columns' width.
   * @return {number}
   */
  getContentWidth() {
    if (this.state.nameAttributeWidth !== null) {
      const actionsAttributeWidth = this.props.canEdit ? 56 : 0;
      const cellAttributeWidth = 150;
      const nextWidth = this.state.nameAttributeWidth + actionsAttributeWidth + cellAttributeWidth
        * this.props.structure.length;
      return Math.max(nextWidth, 1024);
    }
    return 1024;
  }

  getStyle() {
    let width = 1024;
    if (this.hasHorizontalScroll()) {
      width = this.getContentWidth();
    }

    return {
      width: `${width}px`,
    };
  }

  getClassName() {
    let name = 'project-canvas-spreadsheet';
    if (this.hasHorizontalScroll()) {
      name += ' project-canvas-spreadsheet--horizontal-scroll';
    }
    return name;
  }

  onDrop(el, target, source, sibling) {
    const from = parseInt(el.getAttribute('data-sort'));
    const toNextSiblingSort = sibling.getAttribute('data-sort') ? parseInt(sibling.getAttribute('data-sort')) : this.props.items.length;
    const to = toNextSiblingSort > from ? toNextSiblingSort - 1 : toNextSiblingSort;

    const sortedItems = arrayMover(from, to, this.props.items)
      .map((item, index) => ({
        ...item,
        sort: index,
      })); // Set the right sort, based on index

    this.props.sortRows(this.props.boardId, sortedItems);

    clearTimeout(this.sortItemTimer);
    this.sortItemTimer = setTimeout(() => {
      const map = sortedItems.map((item) => ({
        id: item.id,
        sort: item.sort,
      }));
      this.props.saveRowsSort(this.props.boardId, map);
    }, 2000);
  }

  /**
   * Init Dragula on the element componentBackingInstance.
   * @param componentBackingInstance
   */
  dragulaDecorator(componentBackingInstance) {
    if (componentBackingInstance && this.props.canEdit) {
      const options = {
        // move only if using handle
        moves: (el, container, handle) => handle.classList
          .contains('project-canvas-spreadsheet__row-drag-handle'),
        accepts: (el, target, source, sibling) => { // accept only elements before ItemCreator coming from the same board
          const sameBoard = parseInt(el.getAttribute('data-board-id')) === this.props.boardId;
          const beforeItemCreator = sibling && !sibling.classList.contains('project-canvas-spreadsheet__row-summary');
          return sameBoard && beforeItemCreator;
        },
      };

      return dragula([componentBackingInstance], options).on('drop', this.onDrop.bind(this));
    }
  }

  sortColumns(from, to) {
    const sortedStructure = arrayMover(from, to, this.props.structure)
      .map((attribute, index) => ({
        ...attribute,
        sort: index,
      })); // Set the right sort, based on index

    const sortedItems = this.props.items.map((item) => ({
      ...item,
      attributes: arrayMover(from, to, item.attributes),
    }));

    this.props.sortColumns(this.props.boardId, sortedStructure, sortedItems);

    clearTimeout(this.sortColumnTimer);
    this.sortColumnTimer = setTimeout(() => {
      const map = sortedStructure.map((attribute) => ({
        id: attribute.id,
        sort: attribute.sort,
      }));
      this.props.saveColumnsSort(this.props.boardId, map);
    }, 3000);
  }

  render() {
    return (
      <div className={this.getClassName()} style={this.getStyle()}>
        <Table columns={this.props.structure.length + 1}>
          <TableHead>
            <HeadContent waiting={this.props.waitingForAddingAttribute}
              boardId={this.props.boardId}
              sortColumns={this.sortColumns.bind(this)}
              isDeleting={this.props.isDeletingAttribute}
              onAddColumnClick={this.props.addAttribute}
              onDeleteClick={this.props.deleteAttribute}
              updateNameAttributeWidth={this.updateNameAttributeWidth.bind(this)}
              updateActivityAttribute={this.props.updateActivityAttribute}
              attributes={this.props.structure}
              options={this.props.options} />
          </TableHead>
          <TableBody hasMore={this.props.waiting}
            loadMore={() => ({})}
            rootRef={(el) => this.bodyEl = el}>
            {this.getItems()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
