/* eslint-disable react/sort-comp,consistent-return,class-methods-use-this,
 react/no-did-update-set-state,react/no-access-state-in-setstate,no-param-reassign,
 no-nested-ternary */
const React = require('react');
const Sidebar = require('@common/sidebar/Sidebar.react');
const EditableBody = require('./SupplierEditableDetails.react');
const ReadOnlyBody = require('./SupplierReadOnlyDetails.react');
const Actions = require('./SupplierSidebarActions.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * supplier: object corresponding the focused supplier to show in the sidebar
 * isSaving: boolean, check for pending saving
 * canEdit: boolean, permission to edit
 *
 * updateSupplier
 * closeSidebar
 * deleteSupplier
 *
 * @type {module.SupplierSidebar}
 */
module.exports = class SupplierSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      hasUnsavedChanges: false, // is true if any input has unsaved changes, false otherwise
      errors: [], // array containing the name of the attributes that have errors; if the list is
      // empty there are no errors
      supplier: this.props.supplier || {},
      editMode: !this.props.supplier.id,
    };
  }

  componentDidUpdate(prevProps) {
    const prevId = prevProps.supplier ? prevProps.supplier.id : null;
    const currentId = this.props.supplier ? this.props.supplier.id : null;
    if (currentId && prevId !== currentId) this.setState({ supplier: this.props.supplier });
  }

  /**
   * Checks if it's safe to save a supplier: you cannot save changes if there's another saving
   * pending or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.state.errors.length === 0;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return !this.state.editMode && this.props.canEdit;
  }

  /**
   * Check if every tag is contained in prevTags. Every tag must have a unique id
   *
   * @param {array} prevTags
   * @param {array} tags
   */
  hasSameTags(prevTags, tags) {
    return !tags.some((tag) => !prevTags.some((prev) => prev.id === tag.id));
  }

  isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldSupplier = this.props.supplier || {};
    let oldVal = null;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'status':
        oldVal = oldSupplier.status ? oldSupplier.status.id : null;
        value = value ? value.id : null;
        break;
      case 'tags':
        // When old tags and new tags are actually equals, the value to be compared is the length;
        // otherwise the new value is set to -1 to be surely different from the old length
        oldVal = oldSupplier && oldSupplier.tags ? oldSupplier.tags.length : null;
        value = value
          ? this.hasSameTags(oldSupplier.tags || [], value) ? value.length : -1
          : null;
        break;
      case 'payment_term':
        oldVal = oldSupplier.payment_term ? oldSupplier.payment_term.id : null;
        value = value ? value.id : null;
        break;
      case 'country':
        oldVal = oldSupplier.country ? oldSupplier.country.name : null;
        value = value ? value.name : null;
        break;
      case 'province':
        oldVal = oldSupplier.province ? oldSupplier.province.name : null;
        value = value ? value.name : null;
        break;
      default:
        oldVal = oldSupplier[name];
        break;
    }

    let totalChanges = this.state.unsavedChanges.length;

    // When both values are 'empty', there's no need to compare them
    if ((this.isDirty(oldVal) || this.isDirty(value)) && (oldVal !== value)) {
      // When the value has changed, we insert the name in the changes array, if not already present
      const newChanges = this.state.unsavedChanges;
      if (!newChanges.includes(name)) newChanges.push(name);
      this.setState({ unsavedChanges: newChanges });
      totalChanges += 1;
    } else {
      // When the value is equal to the props, we need to remove it from the changes array if
      // present
      let newChanges = this.state.unsavedChanges;
      newChanges = newChanges.filter((attr) => attr !== name);
      this.setState({ unsavedChanges: newChanges });
      totalChanges = newChanges.length;
    }

    return totalChanges !== 0;
  }

  // Save in the local state the modified supplier and check if there are unsaved changes
  handleInputChanges(name, value) {
    value = value === '' ? null : value;
    const hasUnsavedChanges = this.hasChanged(name, value);
    const newSupplier = {
      ...this.state.supplier,
      [name]: value,
    };

    this.setState({
      hasUnsavedChanges,
      supplier: newSupplier,
    });
  }

  handleDelete() {
    if (this.props.deleteSupplier) this.props.deleteSupplier(this.state.supplier);
  }

  /**
   * Check for input errors and keep the state list of errors updated:
   * to know if there are errors in the sidebar we can count on the number of errors in the list
   */
  handleErrors(name, errors) {
    let updatedErrors = this.state.errors;
    if (errors.length === 0) {
      updatedErrors = updatedErrors.filter((error) => error !== name);
    } else if (!updatedErrors.includes(name)) {
      updatedErrors.push(name);
    }

    this.setState({ errors: updatedErrors });
  }

  handleSave() {
    this.setState({
      editMode: false,
      hasUnsavedChanges: false,
      unsavedChanges: [],
    });

    if (this.state.supplier.id) {
      if (this.props.updateSupplier) {
        this.props.updateSupplier(this.state.supplier);
      }
    } else if (this.props.addSupplier) {
      this.props.addSupplier(this.state.supplier);
    }
  }

  handleEditMode() {
    if (this.canEdit()) {
      this.setState({ editMode: true });
    }
  }

  getBody() {
    if (this.state.editMode) {
      return (
        <EditableBody supplier={this.state.supplier}
          statusEnabled={this.props.statusEnabled}
          statuses={this.props.statuses}
          paymentTerms={this.props.paymentTerms}
          countries={this.props.countries}
          provinces={this.props.provinces}
          onChange={this.handleInputChanges.bind(this)}
          onValidate={this.handleErrors.bind(this)}
          errors={this.state.errors} />
      );
    }
    return (
      <ReadOnlyBody supplier={this.state.supplier}
        statusEnabled={this.props.statusEnabled} />
    );
  }

  getActions() {
    if (this.state.supplier && this.state.supplier.id && this.canEdit()) {
      return (
        <Actions onDelete={this.handleDelete.bind(this)} />
      );
    }

    return null;
  }

  render() {
    return (
      <Sidebar title="Supplier Details"
        hasUnsavedChanges={this.state.hasUnsavedChanges}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.closeSidebar}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.closeSidebar}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
