/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations,jsx-a11y/control-has-associated-label */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const Menu = require('@common/Menu/Menu.react');
const DatePicker = require('@common/DateRangePicker.react');

module.exports = class DateItemCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      updatedRange: this.props.value,
    };
  }

  hasValue(range) {
    return range && range.start_date && range.end_date;
  }

  formatInterval(range) {
    if (this.hasValue(range)) {
      const from = moment(range.start_date);
      const to = moment(range.end_date);
      return !from.isSame(to) ? `${from.format('DD.MM.YY')} - ${to.format('DD.MM.YY')}` : from.format('DD.MM.YYYY');
    }
  }

  hasChangedRange() {
    if (!this.hasValue(this.props.value) && !this.state.updatedRange) {
      return false;
    }
    if (!this.hasValue(this.props.value) && this.state.updatedRange) {
      return true;
    }
    return !moment(this.state.updatedRange.start_date).isSame(this.props.value.start_date)
      || !moment(this.state.updatedRange.end_date).isSame(this.props.value.end_date);
  }

  onCloseMenu() {
    if (this.hasChangedRange()) {
      this.props.editValue(this.props.item, this.props.attribute, this.state.updatedRange);
    }

    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  handleIntervalUpdate(name, startDate, endDate) {
    this.setState({
      updatedRange: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }

  handleIntervalDelete() {
    this.props.editValue(this.props.item, this.props.attribute,
      {
        start_date: null,
        end_date: null,
      });

    this.setState({
      showMenu: false,
      updatedRange: null,
    });
  }

  getCell() {
    if (this.props.value && this.props.value.start_date && this.props.value.end_date) {
      return (
        <TableCell className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button">
          <div className="project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--sliding project-canvas-spreadsheet__cell-button-content--sliding-enabled">
            <button type="button"
              ref={(el) => this.moreButtonEl = el}
              className="project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--date"
              onClick={this.onMenuButtonClick.bind(this)}>
              {this.formatInterval(this.props.value)}
            </button>
            <div className="project-canvas-spreadsheet__cell-button-content--delete">
              <button type="button"
                className="wethod-icon-button"
                onClick={this.handleIntervalDelete.bind(this)}>
                <div className="wethod-icon wethod-icon-medium-discard wethod-icon-medium-discard--white" />
              </button>
            </div>
          </div>
          <Menu
            open={this.state.showMenu}
            onClose={this.onCloseMenu.bind(this)}
            anchorEl={this.moreButtonEl}>
            <DatePicker
              name={this.props.item}
              from={this.props.value ? this.props.value.start_date : null}
              to={this.props.value ? this.props.value.end_date : null}
              onChange={this.handleIntervalUpdate.bind(this)} />
          </Menu>
        </TableCell>
      );
    }
    return (
      <TableCell className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button">
        <div className="project-canvas-spreadsheet__cell-button">
          <button type="button"
            ref={(el) => this.moreButtonEl = el}
            className="project-canvas-spreadsheet__cell-button-content"
            onClick={this.onMenuButtonClick.bind(this)} />
        </div>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          <DatePicker
            name={this.props.item}
            from={null}
            to={null}
            onChange={this.handleIntervalUpdate.bind(this)} />
        </Menu>
      </TableCell>
    );
  }

  render() {
    return (
      this.getCell()
    );
  }
};
