const React = require('react');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const Modal = require('@common/Modal/Modal.react');
const RequestManager = require('../../../../containers/RequestManager');
const ModalContent = require('../../../../containers/Calculator/Task/SelectIntercompanySupplierModal/SelectIntercompanySupplierModalContent');

const SelectIntercompanySupplierModal = ({ closeModal, task }) => (
  <Modal title="Select supplier company" onClose={closeModal}>
    <FormValidator>
      <RequestManager>
        <ModalContent
          task={task}
          closeModal={closeModal} />
      </RequestManager>
    </FormValidator>
  </Modal>
);

module.exports = SelectIntercompanySupplierModal;
