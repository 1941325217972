const React = require('react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const Modal = require('@common/Modal/Modal.react');
const RadioGroup = require('@common/inputs/RadioGroup/RadioGroup.react');
const Radio = require('@common/inputs/RadioGroup/Radio.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');

module.exports = class BaselineModal extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.state = { value: this.props.value };
  }

  onChange(e) {
    this.setState({ value: e.target.value });
  }

  onSave() {
    this.props.onSave(this.state.value);
  }

  getFeedback() {
    return this.isSaving() ? 'Saving...' : '';
  }

  isSaving() {
    return this.props.isPending;
  }

  render() {
    return (
      <Modal title="Set the baseline" onClose={this.props.closeModal}>
        <ModalContent>
          <ModalBody>
            <div className="wethod-budget-modal-baseline">
              <RadioGroup
                name="baseline"
                value={this.state.value.toString()}
                onChange={this.onChange}>
                {this.props.baselines.map((baseline) => (
                  <Radio
                    key={baseline.version}
                    label={(
                      <span>{baseline.version} {baseline.baseline
                        ? <span className="wethod-budget-baseline__label">(Baseline)</span> : null}
                      </span>
                    )}
                    value={baseline.budgetId.toString()}
                    className="wethod-budget-baseline__row" />
                ))}
              </RadioGroup>
            </div>
          </ModalBody>
          <ModalFooter feedback={this.getFeedback()}>
            <ModalFooterAction onClick={this.props.closeModal}>
              Close
            </ModalFooterAction>
            <ModalFooterAction onClick={this.onSave}>
              Save
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
};
