/* eslint-disable class-methods-use-this,react/sort-comp,no-case-declarations,react/no-access-state-in-setstate,react/jsx-no-bind,no-underscore-dangle */
const React = require('react');
const FormMetadataManager = require('@common/FormMetadataManager/FormMetadataManager.react');
const CollapsibleCategory = require('./CollapsibleCategory.react');
const Details = require('./categories/CategoryDetailsController.react');

module.exports = class CategoryBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
    };
  }

  getUuid() {
    return new Date().getTime();
  }

  initItem() {
    const newId = `new_${this.props.category}_${this.getUuid()}`;
    const item = {
      id: newId,
      type: this.props.category,
      name: this.props.defaultLabel,
      employees: [{
        name: Wethod.userInfo.get('name'),
        surname: Wethod.userInfo.get('surname'),
      }],
      guests: [],
      date: moment().format('YYYY-MM-DDThh:mm:ssZ'),
      return_date: moment().format('YYYY-MM-DDThh:mm:ssZ'),
      to: this.props.mainDestination,
      _fields: this.props.travelCarrierMetadata,
    };

    return item;
  }

  // Update list with empty item if necessary and expand the first item
  componentDidMount() {
    let list = this.props.travels;
    if (!list || list.length === 0) {
      const item = this.initItem();
      list = [item];
      this.props.onAdd(item);
    }

    this.setState({ expanded: list[0].id });
  }

  hasErrors(id) {
    if (this.props.errors) {
      return this.props.errors[id];
    }
    return false;
  }

  isExpanded(id) {
    return this.state.expanded === id;
  }

  toggleItem(id) {
    if (this.isExpanded(id)) {
      this.setState({ expanded: null });
    } else {
      this.setState({ expanded: id });
    }
  }

  onItemChange(item, name, value) {
    if (item) {
      let changes = {};
      switch (name) {
        case 'date':
          const date = value ? moment(value).format('YYYY-MM-DDThh:mm:ssZ') : null;
          changes = {
            date,
            return_date: null,
          };
          break;
        case 'range_date':
          const startDate = value ? moment(value.start_date).format('YYYY-MM-DDThh:mm:ssZ') : null;
          const endDate = value ? moment(value.end_date).format('YYYY-MM-DDThh:mm:ssZ') : null;
          changes = {
            date: startDate,
            return_date: endDate,
          };
          break;
        case 'from':
        case 'to':
          const destination = value ? value.city : null;
          changes = { [name]: destination };
          break;
        case 'value':
          let formattedValue = parseFloat(value);
          formattedValue = !Number.isNaN(formattedValue) ? formattedValue : null;
          changes = { [name]: formattedValue };
          break;
        case 'people_list': // value = {employees: [...], guests: [...]}
          changes = { ...value };
          break;
        default:
          changes = { [name]: value };
          break;
      }
      const updatedItem = { ...item, ...changes };
      this.props.onUpdate(updatedItem);
    }
  }

  onItemAdd() {
    const item = this.initItem();

    this.setState({ expanded: item.id });
    this.props.onAdd(item);
  }

  onItemRename(item, value) {
    if (item) {
      const updatedItem = {
        ...item,
        name: value,
      };
      this.props.onUpdate(updatedItem);
    }
  }

  onItemDelete(id) {
    const expanded = this.state.expanded === id ? null : this.state.expanded;
    this.setState({ expanded });
    if (this.props.travels && this.props.travels.length === 1) { // last item has been deleted
      this.props.changePage('main');
    }
    this.props.onDelete(id);
  }

  getList() {
    return this.props.travels.map((item) => (
      <CollapsibleCategory key={item.id}
        id={item.id}
        name={item.name}
        category={this.props.category}
        expanded={this.isExpanded(item.id)}
        editable={this.props.editable}
        onRename={(name, value) => this.onItemRename(item, value)}
        onDelete={this.onItemDelete.bind(this)}
        hasErrors={this.hasErrors(item.id)}
        onAdd={this.onItemAdd.bind(this)}
        onToggle={this.toggleItem.bind(this)}>
        <FormMetadataManager metadata={item._fields}>
          <Details travel={item}
            editable={this.props.editable}
            category={this.props.category}
            onValidate={(name, errors) => this.props.onValidate(`${item.type}-${item.id}-${name}`, errors)}
            onChange={(name, value) => this.onItemChange(item, name, value)} />
        </FormMetadataManager>
      </CollapsibleCategory>
    ));
  }

  render() {
    return (
      <div>
        {this.getList()}
      </div>
    );
  }
};
