/* eslint-disable no-shadow,react/jsx-no-bind */
const React = require('react');
const Slider = require('@common/Slider.react');

module.exports = ({ name, vote, id, allowMissingCriteria, onChange }) => {
  const onVoteChange = (name, value) => {
    onChange(name, value, id);
  };

  const getProgressValue = () => {
    if (parseInt(vote) === 0) {
      return allowMissingCriteria ? 'No basis' : null;
    }
    return vote;
  };

  return (
    <div className="project-review-criterion">
      <div className="project-review-criterion__name">{name}</div>
      <div className="project-review-criterion__progress">
        <div className="project-review-criterion__progress-input">
          <Slider max={10} name="progress" onChange={onVoteChange} value={vote} />
        </div>
        <div className="project-review-criterion__progress-value">{getProgressValue()}</div>
      </div>
    </div>
  );
};
