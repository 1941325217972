const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Loading = require('@common/LoadingSmall.react');
const Body = require('./IntercompanyDetailsBody.react');

module.exports = class IntercompanyDetailsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      details: null,
      loading: true,
    };
  }

  componentDidMount() {
    const options = { projectId: this.props.data };

    $.when(Wethod.request('pipeline:intercompany:supplier:details', options))
      .done((response) => this.setState({
        details: response.data,
        loading: false,
      }));
  }

  getBody() {
    if (this.state.loading) {
      return <Loading />;
    }
    return <Body details={this.state.details} />;
  }

  render() {
    return (
      <Modal title="Intercompany details" onCancelClick={this.props.onCloseClick}>
        {this.getBody()}
      </Modal>
    );
  }
};
