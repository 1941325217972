const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const BudgetFooterLink = require('./BudgetFooterLink.react');

const PlanningLink = ({ projectId }) => (
  <BudgetFooterLink noBorder label="Planning" href={`#planning/people?project_id=${projectId}`}>
    <Icon icon="planning" />
  </BudgetFooterLink>
);

module.exports = PlanningLink;
