const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const formatNumber = require('../../../../../../services/FormatService').formatDecimalNumber;
const formatName = require('../../../../../../services/FormatService').formatOneLetterPersonName;

const BudgetChangelogRow = ({ log, showNoteModal }) => {
  function showMessage() {
    if (log.notes) {
      showNoteModal(log.notes);
    }
  }

  const getNoteButton = () => {
    let iconStyle = 'wethod-icon-notes wethod-icon-notes--black';
    iconStyle += log.notes ? ' wethod-icon-notes--done' : ' disabled';
    let buttonStyle = 'wethod-icon-button budget-changelog__button';
    buttonStyle += log.notes ? '' : ' budget-changelog__button--disabled';
    return (
      <button type="button"
        className={buttonStyle}
        onClick={showMessage}>
        <span className={iconStyle} />
      </button>
    );
  };

  const getLinkButton = () => {
    const budgetLink = `#pipeline/budget/${log.project_id}/version/${log.version}`;
    return (
      <a className="wethod-icon-button budget-changelog__button" href={budgetLink}>
        <span className="wethod-icon-arrow-right wethod-icon-arrow-right--black" />
      </a>
    );
  };

  const getDeltaStyle = (value) => {
    let style = 'budget-changelog__column-margin budget-changelog__column--center';
    if (value && value > 0) {
      style += ' budget-changelog__delta--positive';
    } else if (value && value < 0) {
      style += ' budget-changelog__delta--negative';
    }

    return style;
  };

  const getUnescapedValue = (value) => _.unescape(value);

  const formatDate = (date) => moment(date).format('DD/MM/YYYY');

  const formatHour = (date) => moment(date).format('HH:MM');

  const formatK = (value, isDelta) => {
    if (value == null) {
      return '-';
    }
    const valueK = value / 1000;
    return formatNumber(valueK, isDelta, 1);
  };

  const formatDeltaPercentage = (value) => {
    if (value == null) {
      return '-';
    }
    return formatNumber(value, true);
  };

  return (
    <TableRow>
      <TableCell name="joc" className="budget-changelog__column-joc">
        <div className="budget-changelog__color-bar" style={{ backgroundColor: log.job_order_category_color }} />
      </TableCell>
      <TableCell name="project_name"
        className="budget-changelog__column-project budget-changelog__project">
        <div className="budget-changelog__multirow-info">
          <span>{getUnescapedValue(log.project_name)}</span>
          <span>{getUnescapedValue(log.client_name)}</span>
        </div>
      </TableCell>
      <TableCell name="project_probability" className="budget-changelog__column-probability budget-changelog__column--center">
        {log.project_probability}
      </TableCell>
      <TableCell name="pm_name" className="budget-changelog__column-person">{formatName(getUnescapedValue(log.pm_name))}</TableCell>
      <TableCell name="account_name" className="budget-changelog__column-person">{formatName(getUnescapedValue(log.account_name))}</TableCell>
      <TableCell name="to_value" className="budget-changelog__column-value budget-changelog__column--center">{formatK(log.to_value)}</TableCell>
      <TableCell name="fnp_margin" className="budget-changelog__column-margin budget-changelog__column--center">{formatK(log.fnp_margin)}</TableCell>
      <TableCell name="margin_delta" className={getDeltaStyle(log.margin_delta / 1000)}>{formatK(log.margin_delta, true)}</TableCell>
      <TableCell name="to_margin" className="budget-changelog__column-margin budget-changelog__column--center">{formatNumber(log.to_margin)}</TableCell>
      <TableCell name="to_margin" className={getDeltaStyle(log.percentage_delta)}>{formatDeltaPercentage(log.percentage_delta)}</TableCell>
      <TableCell name="version" className="budget-changelog__column-version budget-changelog__column--center">{log.version}</TableCell>
      <TableCell name="prev_fpn_margin" className={getDeltaStyle(log.prev_fpn_margin)}>{formatK(log.prev_fpn_margin, true)}</TableCell>
      <TableCell name="approved_by" className="budget-changelog__column-person">{formatName(getUnescapedValue(log.approved_by))}</TableCell>
      <TableCell name="approved_on" className="budget-changelog__column-date">
        <div className="budget-changelog__multirow-info">
          <span>{formatDate(log.approved_on)}</span>
          <span>{formatHour(log.approved_on)}</span>
        </div>
      </TableCell>
      <TableCell name="notes" className="budget-changelog__column-notes">{getNoteButton()}</TableCell>
      <TableCell name="link" className="budget-changelog__column-link">{getLinkButton()}</TableCell>
    </TableRow>
  );
};

module.exports = BudgetChangelogRow;
