const React = require('react');
const moment = require('moment');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const Button = require('@common/Button/RoundedButton.react');

const FiscalYearChangeConfirmationModal = ({
  data,
  waitingForChange,
  onClose,
  onFiscalYearChangeCancel,
  previousFiscalYear,
  onFiscalYearChangeConfirm,
}) => {
  function handleCancel() {
    onFiscalYearChangeCancel(previousFiscalYear);
    onClose();
  }
  function handleConfirm() {
    onFiscalYearChangeConfirm(data);
  }
  const isWaiting = waitingForChange.some((key) => key.includes(data.id));

  function getFeedback() {
    return isWaiting ? 'Deleting...' : undefined;
  }

  return (
    <Modal title="Change fiscal year" onClose={handleCancel}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            You are about to change the fiscal year of your
            company to {moment.months(data - 1)}.
          </ModalBodyBlock>
          <ModalBodyBlock>
            Are you sure you want to continue?
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={getFeedback()}>
          <Button className="company-settings__button company-settings__button--white" onClick={handleCancel}>Cancel</Button>
          <Button className="company-settings__button company-settings__button--blue"
            onClick={handleConfirm}
            disabled={!data || isWaiting}>
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = FiscalYearChangeConfirmationModal;
