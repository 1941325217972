const React = require('react');
const UploadErrorModal = require('@common/modals/CannotUploadModal.react');
const ExportModal = require('../containers/modals/ExportModal');
const ImportModal = require('../containers/modals/ImportModal');
const ErrorModal = require('./modals/ErrorModal.react');
const ImportSuccessModal = require('../containers/modals/ImportSuccessModal');

module.exports = ({ toShow, modalData, closeModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'export':
        return <ExportModal data={modalData} onClose={closeModal} />;
      case 'import':
        return <ImportModal data={modalData} onClose={closeModal} />;
      case 'upload-error':
        return <UploadErrorModal data={modalData} onCancelClick={closeModal} />;
      case 'import-error':
        return <ErrorModal data={modalData} onClose={closeModal} />;
      case 'import-success':
        return <ImportSuccessModal data={modalData} onClose={closeModal} />;
      default:
        return null;
    }
  };

  return getModal();
};
