/* eslint-disable class-methods-use-this,react/sort-comp */
const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const Loader = require('@common/Loader/Loader.react');
const Search = require('../containers/SearchBox');
const Person = require('../containers/Person');

module.exports = class People extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortBy: {
        col: 'name',
        order: 'asc',
      },
    };
  }

  getPeople() {
    if (this.props.isLoading) return <Loader />;
    if (this.props.loadedItems.length === 0) return [];
    return this.props.loadedItems.map((item) => <Person key={item.id} {...item} />);
  }

  hasMore() {
    return this.props.loadedItems.length < this.props.totalItems;
  }

  loadMore(size, page) {
    this.props.loadClients(size, page);
  }

  getSearch() {
    return <Search />;
  }

  handleSort(col, order) {
    this.props.orderClients(col, order);

    this.setState({
      sortBy: {
        col,
        order,
      },
    });
  }

  getSort(col) {
    const currentSort = this.state.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  render() {
    return (
      <div className="clients-list">
        <Table columns={8}
          maxHeight={this.props.tableHeight}
          search={this.getSearch()}>
          <TableHead>
            <TableRow>
              <TableCell className="clients-people__column--profile-pic" />
              <TableCell
                className="clients-people__column--name"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('name')}
                name="name">
                Name
              </TableCell>
              <TableCell
                className="clients-people__column--surname"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('surname')}
                name="surname">
                Surname
              </TableCell>
              <TableCell
                className="clients-people__column--company"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('company')}
                name="company">
                Company
              </TableCell>
              <TableCell
                className="clients-people__column--role"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('role')}
                name="role">
                Role
              </TableCell>
              <TableCell className="clients-people__column--email">Email</TableCell>
              <TableCell className="clients-people__column--actions" />
            </TableRow>
          </TableHead>
          <TableBody
            hasMore={this.hasMore()}
            loadMore={this.loadMore.bind(this)}
            empty="There's nothing here! What about adding a contact?">
            {this.getPeople()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
