const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const Button = require('@common/Button/RoundedButton.react');
const Typography = require('@common/Typography/Typography.react');

const DeleteLocationConfirmationModal = ({ data, waitingForDelete, onClose, onDelete }) => {
  function handleDelete() {
    onDelete(data.deleteLocation.id);
  }

  const isWaiting = waitingForDelete.some((key) => key.includes(`delete-locations-${data.deleteLocation.id}`));

  function getFeedback() {
    return isWaiting ? 'Deleting...' : undefined;
  }

  return (
    <Modal title="Delete location" onClose={onClose}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            <Typography>Employees linked to </Typography>
            <Typography weight="bold">{data.deleteLocation.name}</Typography>
            <Typography> will be assigned to </Typography>
            <Typography weight="bold">{data.defaultLocation.name}</Typography>
            <Typography>
              . Therefore company closures and company holidays will depend on the default location.
            </Typography>
          </ModalBodyBlock>
          <ModalBodyBlock>
            <Typography>Are you sure you want to continue?</Typography>
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={getFeedback()}>
          <Button className="company-settings__button company-settings__button--white" onClick={onClose}>Cancel</Button>
          <Button className="company-settings__button company-settings__button--blue"
            onClick={handleDelete}
            disabled={!data || !data.deleteLocation.id || isWaiting}>
            Delete location
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = DeleteLocationConfirmationModal;
