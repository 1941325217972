const request = require('./Request');

const FullTimeEquivalent = {
  /**
   * @param {number} amount
   * @return {*}
   */
  updateDefault(amount) {
    return request({
      method: 'put',
      url: '/fte',
      data: { fte: amount },
    });
  },
  getDefault() {
    return request({
      method: 'get',
      url: '/fte',
    });
  },
};

module.exports = FullTimeEquivalent;
