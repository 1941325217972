const React = require('react');
const AdvancedSearchController = require('@common/AdvancedSearch/AdvancedFiltersController.react');
const DayFilter = require('@common/AdvancedSearch/filters/RangeFilter/DayRangeFilter/DayRangeFilter.react');
const EmployeeCategoryFilter = require('../../containers/advanced-search/filters/EmployeeCategory');
const TimesheetMode = require('../../containers/advanced-search/filters/TimesheetMode');
const ClientFilter = require('./filters/ClientFilter.react');
const ProjectFilter = require('./filters/ProjectFilter.react');
const EmployeeFilter = require('./filters/EmployeeFilter.react');

const TimesheetLogAdvancedFilters = ({
  type, errors, updateErrors, ...rest
}) => (
  <AdvancedSearchController errors={errors} updateErrors={updateErrors} {...rest}>
    <EmployeeCategoryFilter name="category" label="Employee category" />
    <TimesheetMode name="mode" label="Timesheet Mode" />
    <DayFilter name="date" label="Date" />
    <DayFilter name="updated" label="Update Date" />
    <ProjectFilter name="project" label="Project" placeholder="Search project name or job order" />
    <EmployeeFilter name="employee" label="Employee" placeholder="Search employee name" />
    <ClientFilter name="client" label="Client" />
    <EmployeeFilter name="updated-by" label="Updated By" placeholder="Search employee name" />
  </AdvancedSearchController>
);

module.exports = TimesheetLogAdvancedFilters;
