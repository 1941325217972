const { connect } = require('react-redux');
const TableSearch = require('@common/Table2/TableSearch.react');
const actions = require('../actions');

const mapStateToProps = () => ({
  placeholder: 'Search by name',
});

const mapDispatchToProps = (dispatch) => ({
  filter: (keyword) => dispatch(actions.filterPeople(keyword)),
  updateHeight: () => null,
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(TableSearch);
