const React = require('react');
const TextField = require('@common/inputs/TextField/BasicTextField/BasicTextField.react');
const NameCell = require('../Cell/NameCell.react');
const CollaborativeInput = require('../../../containers/BudgetCollaborativeInput');

const TaskName = ({ children, editable, save, id }) => (
  <NameCell className="wethod-budget-task__name" editable={editable} tooltip={children}>
    <CollaborativeInput id={id}>
      <TextField readOnly={!editable} value={children} onChange={save} />
    </CollaborativeInput>
  </NameCell>
);

module.exports = TaskName;
