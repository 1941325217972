const React = require('react');
const TableSearch = require('@common/Table2/TableSearch.react');

const ItemsSearch = ({ keyword, onFilter, placeholder, ...rest }) => (
  <TableSearch
    className="pipeline__search"
    placeholder={placeholder}
    keyword={keyword}
    filter={onFilter}
    {...rest} />
);

module.exports = ItemsSearch;
