'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var BuFilter = require('@common/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');
var BusinessUnitService = require('../../../services/BusinessUnitService');
var FiscalYearService = require('../../../services/FiscalYearService');

Wethod.module('ReportApp.ProductionValue', function (ProductionValue, Wethod, Backbone, Marionette, $, _, Header) {
  this.FilterItemView = Marionette.ItemView.extend({
    template: '#filterSelectItemTemplate',
    className: 'wethod-filter-select__item',
    tagName: 'li',
    triggers: {
      click: 'choose:item',
    },
  });

  this.FilterListView = Marionette.CollectionView.extend({
    tagName: 'ul',
    childView: ProductionValue.FilterItemView,
    triggers: {
      'choose:item': 'choose:item',
    },
  });

  this.ProjectDetailChartHeaderItemView = Marionette.ItemView.extend({
    template: '#productionValueProjectDetailChartHeaderLayout',
  });

  this.ClientDetailChartHeaderItemView = Marionette.ItemView.extend({
    template: '#productionValueClientDetailChartHeaderLayout',
  });

  /**
   * Production Value Layout
   */
  this.ProductionValueLayout = Marionette.LayoutView.extend({
    template: '#productionValueLayoutTemplate',
    className: 'productionValue',
    _currYear: null,
    _currBusinessUnit: '',
    _model: null,
    _availableBusinessUnits: [],
    regions: {
      header: '[data-region="productionValueHead"]',
      sectionHeader: '[data-region="sectionHeader"]',
      totalChart: '[data-region="productionValueTotalChart"]',
      clientDetailChartHeader: '[data-region="productionValueClientDetailChartHeader"]',
      projectDetailChartHeader: '[data-region="productionValueProjectDetailChartHeader"]',
      clientDetailChartList: '[data-region="productionValueClientDetailChartList"]',
      projectDetailChartList: '[data-region="productionValueProjectDetailChartList"]',
      projectWeeklyDetailChartList: '[data-region="productionValueProjectWeeklyDetailChartList"]',
      weeklyDetailLayout: '[data-region="weeklyDetailLayout"]',
      businessUnitSelect: '[data-region="businessUnitSelect"]',
      modal: '[data-region="modal"]',
    },
    ui: {
      clientDetailChart: '[data-region="productionValueClientDetailChart"]',
      projectDetailChart: '[data-region="productionValueProjectDetailChart"]',
      projectWeeklyDetailChart: '[data-region="productionValueProjectWeeklyDetailChart"]',
      detailDate: '[data-region="selectedMonth"]',
      prevYear: '[data-region="yearPrev"]',
      yearSelectionLabel: '[data-region="yearLabel"]',
      nextYear: '[data-region="yearNext"]',
      exportModule: '[data-region="exportModule"]',
    },
    events: {
      'click @ui.prevYear': 'selectPrevYear',
      'click @ui.nextYear': 'selectNextYear',
      'click @ui.exportModule': 'exportModule',
    },
    initialize: function (options) {
      this._businessUnitService = new BusinessUnitService();
      this._currYear = options.year;
      this._currBusinessUnit = this._businessUnitService.getLastSelected();
      this._fiscalYearService = new FiscalYearService();
      // Set default class to show weeks info on projects and clients details
      this.model = new Backbone.Model({ is_monthly: false });

      // Show details for the selected month
      dispatcher.on('report:ProductionValue:showDetail', function (date) {
        var byClient = this._model.get('by_client');
        var byProject = this._model.get('by_project');
        var byProjectWeekly = this._model.get('by_project_weekly');
        var totalByClient = this._model.get('total_by_client');
        var totalByProject = this._model.get('total_by_project');

        var year = this._currYear;
        var displayDate;
        var monthByProject = [];
        var monthByClient = [];

        var prodProjModel = null;
        var prodProjColl = null;
        var prodWeekModel = null;
        var prodWeekColl = null;

        if (date !== year) {
          // If the selected month is the current month then
          // add class to show weeks info on projects and clients details
          this.$el.addClass('is_monthly');
        } else {
          // Remove class to show weeks info on projects and clients details
          this.$el.removeClass('is_monthly');
        }

        if (date === year) {
          monthByProject = totalByProject;
          monthByClient = totalByClient;
          displayDate = date;
        } else {
          // get project data for the selected month
          _.each(byProject, function (list, projectsDate) {
            if (projectsDate === date) {
              monthByProject = _.map(list);
            }
          });

          // get client data for the selected month
          _.each(byClient, function (list, clientsDate) {
            if (clientsDate === date) {
              monthByClient = _.map(list);
            }
          });

          // build weekly explosion
          _.each(byProjectWeekly, function (weeks, month) {
            if (month === date) {
              prodWeekColl = new ProductionValue.ProdWeekColl();
              _.each(weeks, function (projs, week) {
                prodProjColl = new ProductionValue.ProdProjColl();
                _.each(projs, function (proj) {
                  prodProjModel = new ProductionValue.ProdProjModel(proj);
                  prodProjColl.add(prodProjModel);
                });
                prodWeekModel = new ProductionValue.ProdWeekModel({
                  projs: prodProjColl,
                  week: week,
                });
                prodWeekColl.add(prodWeekModel);
              });
            }
          });

          displayDate = moment(date).format('MMMM');
        }

        var clientCollection = new ProductionValue.ClientsCollection(monthByClient);
        var projectCollection = new ProductionValue.ProjectsCollection(monthByProject);

        var clientCollectionView = new ProductionValue.ChartDetailsCollectionView({
          collection: clientCollection,
        });
        var projectCollectionView = new ProductionValue.ChartDetailsCollectionView({
          collection: projectCollection,
        });

        // update month label
        this.ui.detailDate.text(displayDate);

        this.clientDetailChartList.show(clientCollectionView);
        this.projectDetailChartList.show(projectCollectionView);

        // Calc selected month weeks
        var weekCount = {};
        var isCurrentMonthSelected = date === moment().day('Thursday').format('Y-MM-01');
        if (isCurrentMonthSelected) {
          weekCount = {
            current_week: this._model.get('current_week'),
            total_weeks: this._model.get('total_weeks'),
          };
        } else {
          var thisMonth = moment(date);
          var nextMonth = moment(date).add(1, 'month');
          var thisMonthMonday = this.getFirstMonday(thisMonth);
          var nextMonthMonday = this.getFirstMonday(nextMonth);
          var count = nextMonthMonday.diff(thisMonthMonday, 'days') / 7;

          weekCount = {
            current_week: count,
            total_weeks: count,
          };
        }

        // project details header
        var projectDetailsHeaderModel = new Backbone.Model(weekCount);
        var projectDetailsHeaderView = new ProductionValue
          .ProjectDetailChartHeaderItemView({ model: projectDetailsHeaderModel });
        this.projectDetailChartHeader.show(projectDetailsHeaderView);

        // client details header
        var clientDetailsHeaderModel = new Backbone.Model(weekCount);
        var clientDetailsHeaderView = new ProductionValue
          .ClientDetailChartHeaderItemView({ model: clientDetailsHeaderModel });
        this.clientDetailChartHeader.show(clientDetailsHeaderView);

        // show details box
        this.ui.clientDetailChart.show();
        this.ui.projectDetailChart.show();

        // show weekly explosion
        if (date !== year) {
          var weeklyDetailLayout = new ProductionValue.WeeklyDetailLayout({
            weekColl: prodWeekColl,
            month: date,
          });
          this.weeklyDetailLayout.show(weeklyDetailLayout);
        } else {
          this.weeklyDetailLayout.empty();
          this.weeklyDetailLayout.destroy();
        }

        $('html, body').animate({
          scrollTop: 400,
        }, 500);
      }.bind(this));
    },
    getFirstMonday: function (momentMonth) {
      var firstDayPos = momentMonth.startOf('month').day();
      var firstDayDelta;
      if (firstDayPos <= 4) {
        firstDayDelta = 4 - firstDayPos;
      } else {
        firstDayDelta = 7 - (firstDayPos - 4);
      }
      momentMonth.add(firstDayDelta, 'days').day(1);
      return momentMonth;
    },
    onShow: function () {
      this.showYear(this._currYear, this._currBusinessUnit);
      this.showBusinessUnitFilter();
    },
    onRender: function () {
      var sectionHeaderModel = new Header.SectionHeaderModel({
        current_section: 'Production Value',
        preview_anchor_id: 'production-value',
        helper_url: 'reports/index/#production-value',
      });
      var sectionHeaderView = new Header.SectionHeaderView({ model: sectionHeaderModel });
      this.sectionHeader.show(sectionHeaderView);
    },
    showBusinessUnitFilter: function () {
      if (this._businessUnitService.isEnabled()) {
        var businessUnitSelect = React.createElement(BuFilter, {
          selectedValues: this._businessUnitService.getLastSelected(),
          onApply: this.onBusinessUnitChanged.bind(this),
        });
        var container = document.querySelector(this.businessUnitSelect.el);
        ReactDOM.render(businessUnitSelect, container);
      }
    },
    onBusinessUnitChanged: function (name, selectedValues) {
      this._currBusinessUnit = selectedValues;
      this.showYear(this._currYear, this._currBusinessUnit);
    },
    showYear: function (year, bu) {
      if (this.clientDetailChartList.hasView()) {
        this.clientDetailChartList.reset();
        this.ui.clientDetailChart.hide();
      }
      if (this.projectDetailChartList.hasView()) {
        this.projectDetailChartList.reset();
        this.ui.projectDetailChart.hide();
      }

      this.weeklyDetailLayout.reset();
      this.totalChart.reset();

      var loadingView = new Wethod.View.Loading.SmallView();
      this.totalChart.show(loadingView);

      var productionValueRequest = Wethod.request('get:report:productionValue', year, bu);
      $.when(productionValueRequest).done(function (productionValueModel) {
        this._model = productionValueModel;

        var prodByMonth = this._model.get('by_month');
        var roadrunnerByMonth = this._model.get('roadrunner_by_month');
        var total = this._model.get('total');

        // Total by month chart
        var totalChartData = ProductionValue
          .totalChartData(prodByMonth, roadrunnerByMonth, total, year);
        var totalChartView = new ProductionValue.TotalChartItemView({
          chartData: totalChartData,
          target: this._model.get('target'),
          total: this._model.get('total'),
          total_weighted: this._model.get('total_weighted'),
        });
        this.totalChart.show(totalChartView);

        this.enableYearSelection();
      }.bind(this));
    },
    selectPrevYear: function (e) {
      e.preventDefault();
      if (this.ui.prevYear.hasClass('disabled')) {
        return;
      }
      this.disableYearSelection();
      this._currYear = parseInt(this._currYear) - 1;
      this.showYear(this._currYear, this._currBusinessUnit);
      this.updateYearLabel(this._currYear);
    },
    selectNextYear: function (e) {
      e.preventDefault();
      if (this.ui.nextYear.hasClass('disabled')) {
        return;
      }
      this.disableYearSelection();
      this._currYear = parseInt(this._currYear) + 1;
      this.showYear(this._currYear, this._currBusinessUnit);
      this.updateYearLabel(this._currYear);
    },
    exportModule: function () {
      var modalExport = new ProductionValue.ModalExportItemView();
      this.modal.show(modalExport);
    },
    disableYearSelection: function () {
      this.ui.prevYear.addClass('disabled');
      this.ui.nextYear.addClass('disabled');
    },
    enableYearSelection: function () {
      this.ui.prevYear.removeClass('disabled');
      this.ui.nextYear.removeClass('disabled');
    },
    updateYearLabel: function (year) {
      this.ui.yearSelectionLabel.text(this._fiscalYearService.getYearDynamically(year));
    },
  });

  // EXPORT
  var modalW = 350;
  var
    modalH = 500;
  this.ModalExportItemView = Marionette.LayoutView.extend({
    template: '#exportModalWithMonthTemplate',
    className: 'modalWrapper',
    _selectedMonth: moment(),
    ui: {
      modalEL: '.modalStructure',
      actionButton: '[data-action="modalDoAction"]',
      cancelButton: '[data-action="modalCancel"]',
      selectedMonth: '[data-action="selectedMonth"]',
      monthPicker: '[data-region="monthPicker"]',
    },
    events: {
      'click @ui.actionButton': 'doOkAction',
      'click @ui.cancelButton': 'cancelAction',
      'click @ui.selectedMonth': 'openMonthPicker',
    },
    regions: {
      monthPicker: '[data-region="monthPicker"]',
    },
    onRender: function () {
      this.selectDate(this._selectedMonth);
      this.placeModal();
    },
    placeModal: function () {
      var contextW = $(window).width();
      var contextH = $(window).height();
      var posLeft = (contextW - modalW) / 2;
      var posTop = (contextH - modalH) / 2;

      this.ui.modalEL.css({
        left: posLeft,
        top: posTop,
      }).show();
    },
    openMonthPicker: function () {
      var yearStart = this._selectedMonth.format('YYYY');
      var monthSelector = new Wethod.View.MonthPicker.YearsView({ start: yearStart });
      this.getRegion('monthPicker').show(monthSelector);
      this.ui.monthPicker.addClass('isVisible');

      var monthStart = this._selectedMonth.format('MM');
      this.getRegion('monthPicker').currentView.scrollYears(30 * (13 * 10 + (monthStart - 0.5)));

      this.getRegion('monthPicker').currentView.on('monthPicker:month:selected', function (value) {
        this.selectDate(value);
        this.getRegion('monthPicker').empty();
        this.ui.monthPicker.removeClass('isVisible');
      }.bind(this));
    },
    // Set the selected date and show the label
    selectDate: function (date) {
      this._selectedMonth = moment(date);
      this.ui.selectedMonth.val(this._selectedMonth.format('MMMM YYYY'));
    },
    doOkAction: function (e) {
      e.preventDefault();
      Wethod.request('export:productionValue', this._selectedMonth.format('YYYY-MM-DD'));
      this.destroy();
    },
    cancelAction: function (e) {
      e.preventDefault();
      this.destroy();
    },
  });

  this.ProductionValueClock = Marionette.ItemView.extend({
    template: '#productionValueClock',
    className: 'production-value-report__resume-right',
    ui: {
      targetClockChart: '[data-action="target-clock-chart"]',
    },
    getTargetClockChartConfig: function (year, yearProgress, opportunitiesValue, projectsValue) {
      return {
        chart: {
          backgroundColor: 'transparent',
          type: 'solidgauge',
        },
        title: {
          text: '',
        },
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          borderWidth: 0,
          backgroundColor: 'none',
          shadow: false,
          positioner: function () {
            return {
              x: 150,
              y: 100,
            };
          },
        },

        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [{
            backgroundColor: '#FFFFFF',
            borderWidth: 0,
          }],
        },

        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },

        plotOptions: {
          solidgauge: {
            borderWidth: '8px',
            borderRadius: 0,
            dataLabels: {
              enabled: false,
            },
            linecap: 'square',
            stickyTracking: true,
            allowPointSelect: true,
            events: {
              mouseOver: function (event) {
                $('*[data-action="' + event.target.name + '"]').addClass('zoom');
              },
              mouseOut: function (event) {
                $('*[data-action="' + event.target.name + '"]').removeClass('zoom');
              },
              click: function (event) {
                if (event.point.series.name === 'projects') dispatcher.trigger('report:ProductionValue:showDetail', year);
              },
            },
          },
        },
        series: [
          {
            name: 'time',
            borderColor: '#F3F3F3',
            tooltip: {
              pointFormat: '',
            },
            data: [{
              color: '#F3F3F3',
              radius: '100%',
              innerRadius: '0%',
              y: yearProgress,
            }],
          }, {
            name: 'opportunities',
            tooltip: {
              pointFormat: '',
            },
            data: [{
              color: 'rgba(78,216,141,0.4)',
              radius: '95%',
              innerRadius: '85%',
              y: opportunitiesValue,
            }],
          }, {
            name: 'projects',
            cursor: 'pointer',
            tooltip: {
              pointFormat: '',
            },
            data: [{
              color: 'rgba(78,216,141,1)',
              radius: '95%',
              innerRadius: '85%',
              y: projectsValue,
            }],
          },
        ],
      };
    },
    onShow: function () {
      this.ui.targetClockChart.highcharts(
        this.getTargetClockChartConfig(
          this.model.get('year'),
          this.model.getYearProgress(),
          this.model.getProjectValueProgress(),
          this.model.getWeightedProgress()
        )
      );
    },
  });

  /**
   * Total product by month Item View
   */
  this.TotalChartItemView = Marionette.LayoutView.extend({
    template: '#productionValueTotalChartTemplate',
    className: 'production-value-report__resume',
    ui: {
      rriChart: '[data-action="rri-chart"]',
      productionChart: '[data-action="production-chart"]',
    },
    regions: {
      right: '[data-region="right"]',
    },
    chartColors: {
      production: '#4ed88d',
      rri: '#db4d69',
    },
    commonChartConfig: {
      title: {
        align: 'left',
        margin: 10,
        x: 0,
      },
      subtitle: {
        align: 'left',
        margin: 10,
        x: 0,
      },
      yAxis: {
        endOnTick: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        title: {
          enabled: false,
        },
        type: 'datetime',
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false,
          },
        },
      },
    },
    minRri: 0,
    getNormalizedRri: function (series) {
      var ascSortedSerie = JSON.parse(JSON.stringify(series)).sort(function (a, b) {
        if (a.y === undefined) return 1;
        if (b.y === undefined) return -1;
        if (a.y > b.y) return 1;
        if (a.y < b.y) return -1;
        return 0;
      });
      this.minRri = ascSortedSerie[0].y;
      return series.map(function (value) {
        var clone = JSON.parse(JSON.stringify(value));
        if (clone.y) {
          clone.y += Math.abs(this.minRri) + 0.1;
        }
        return clone;
      }, this);
    },
    getRriChartConfig: function (series, normalizedSeries, months, minRri) {
      var config = JSON.parse(JSON.stringify(this.commonChartConfig));
      config.title.x = -35;
      config.subtitle.x = -35;
      config.subtitle.useHTML = true;
      config.chart = { spacingLeft: 45 };
      config.chart.type = 'spline';
      config.title.useHTML = true;
      config.title.text = 'Roadrunner Index';
      config.subtitle.text = 'Monthly ratio between reported progress and timesheet';
      config.yAxis.title = { text: null };
      config.yAxis.plotLines = [{
        value: 1 + Math.abs(minRri) + 0.1,
        color: '#D8D8D8',
        width: 1,
        label: {
          text: '1',
          x: -25,
          y: 3,
          style: {
            color: '#606060',
            fontWeight: 100,
          },
        },
      }];
      config.yAxis.labels = {
        enabled: false,
      };
      config.yAxis.title.x = -35;
      config.yAxis.gridLineWidth = 0;
      config.yAxis.type = 'logarithmic';
      config.xAxis.categories = months;
      config.xAxis.labels = {
        formatter: function () {
          return this.value.name;
        },
      };
      config.plotOptions.series.color = this.chartColors.rri;
      config.series = [{
        name: 'Roadrunner Index',
        data: normalizedSeries,
        marker: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            var value = series[this.point.index];
            return value.y === 0 ? '' : numeral(value.y).format('0.[0]');
          },
        },
      }];

      return config;
    },
    getProductionChartConfig: function (deltaSeries, weightedSeries, months) {
      var config = JSON.parse(JSON.stringify(this.commonChartConfig));
      config.chart = { spacingLeft: 45 };
      config.title.x = -35;
      config.subtitle.x = -35;
      config.title.useHTML = true;
      config.subtitle.useHTML = true;
      config.chart.type = 'column';
      config.chart.events = {
        load: function () {
          var xAxis = this.xAxis[0];
          var yAxis = this.yAxis[0];
          var xTicks = xAxis.ticks;
          var yStacksColumns = yAxis.stacks['column,,,'];
          var points = this.series[0].points;
          points.forEach(function (point, i) {
            if (point.y > 0) {
              if (xTicks[i]) {
                var xLabel = xTicks[i].label.element;
                xLabel.style.cursor = 'pointer';
                xLabel.onclick = function () {
                  dispatcher.trigger('report:ProductionValue:showDetail', point.date);
                };
              }
              if (yStacksColumns[i]) {
                var yLabel = yStacksColumns[i].label.element;
                yLabel.style.cursor = 'pointer';
                yLabel.onclick = function () {
                  dispatcher.trigger('report:ProductionValue:showDetail', point.date);
                };
              }
            }
          });
        },
      };
      config.title.text = 'Production Value';
      config.subtitle.text = 'Monthly value generated by projects (in K)';
      config.yAxis.title = { text: null };
      config.yAxis.min = 0;
      config.yAxis.labels = {
        enabled: false,
      };
      config.yAxis.endOnTick = true;
      config.yAxis.stackLabels = {
        enabled: true,
        formatter: function () {
          return this.total === 0 ? '' : numeral(this.total / 1000).format('0,0');
        },
      };
      config.yAxis.gridLineWidth = 0;
      config.xAxis.categories = months;
      config.xAxis.labels = {
        formatter: function () {
          return this.value.name;
        },
      };
      config.plotOptions.series.stacking = 'normal';
      config.plotOptions.series.fillColor = {
        linearGradient: [0, 0, 1, 200],
        stops: [
          [0, '#4ed88d'],
          [1, 'rgba(78,216,141,0)'],
        ],
      };
      config.series = [{
        name: 'Production',
        data: weightedSeries,
        cursor: 'pointer',
        color: this.chartColors.production,
        point: {
          events: {
            // trigger event to display production details
            click: function () {
              dispatcher.trigger('report:ProductionValue:showDetail', this.date);
            },
          },
        },
      }];

      return config;
    },

    initialize: function (options) {
      this.months = options.chartData.categories;
      this.rriSeries = options.chartData.roadrunnerSeries;
      this.rriNormalizedSeries = this.getNormalizedRri(this.rriSeries);
      this.productionSeries = options.chartData.prodSeries;
      this.productionWeightedSeries = options.chartData.prodWeightedSeries;
      this.yearlyProd = options.chartData.yearlyProd;
      this.year = options.chartData.year;
      this.target = options.target;
      this.total = options.total;
      this.totalWeighted = options.total_weighted;
    },

    onDomRefresh: function () {
      this.ui.rriChart.highcharts(
        this.getRriChartConfig(this.rriSeries, this.rriNormalizedSeries, this.months, this.minRri)
      );
      this.ui.productionChart.highcharts(
        this.getProductionChartConfig(this.productionWeightedSeries, this.productionSeries,
          this.months)
      );
      var targetClockModel = new ProductionValue.TargetClockModel({
        months: this.months,
        year: this.year,
        target: this.target,
        total: this.total,
        totalWeighted: this.totalWeighted,
      });
      var targetClockView = new ProductionValue.ProductionValueClock({ model: targetClockModel });
      this.right.show(targetClockView);
    },
  });

  /**
   * Project/Client detail Item View
   */
  this.ChartDetailItemView = Marionette.ItemView.extend({
    template: '#productionValueChartClientDetailTemplate',
    className: '',
    tagName: 'li',
    initialize: function (options) {
      this.options = options;
    },
  });

  /**
   * Project/Client detail Collection View
   */
  this.ChartDetailsCollectionView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'productionValue__detailChart__list',
    childView: ProductionValue.ChartDetailItemView,
  });

  /**
   * Represents the Weekly Detail table header.
   */
  this.WeeklyDetailHeaderView = Marionette.ItemView.extend({
    template: '#weeklyDetailHeaderTemplate',
    className: 'recordsHeader',
    _prevClicked: null,
    ui: {
      changeOrderButtonEl: '[data-action="changeOrder"]',
    },
    events: {
      'click @ui.changeOrderButtonEl': 'onChangeOrderClicked',
    },
    /**
     * Returns the target jQuery object of the given event.
     *
     * @param event
     * @returns {*|jQuery|HTMLElement}
     */
    getTarget: function (event) {
      return $(event.target);
    },
    /**
     * Returns the direction which must be use to sort the collection.
     *
     * @param orderButtonEl
     * @returns {*}
     */
    getOrderDirection: function (orderButtonEl) {
      var directionIcon = orderButtonEl.find('.orderDirectionIcon');
      if (directionIcon.hasClass('asc')) {
        return 'desc';
      }
      return 'asc';
    },
    /**
     * Removes all order icons.
     */
    cleanOrderIcons: function () {
      $('.weekly-header__order-icon').removeClass('asc desc');
    },
    /**
     * Remove the CSS class "active" from all the table's headers. The CSS class "active" is used
     * to highlight the column by which the table is actually sorted.
     */
    cleanActiveHeaders: function () {
      $('.header--clickable.active').removeClass('active');
    },
    /**
     * Add the CSS class "active" to the given table's header and show the sort arrow accordingly
     * with the given direction. The CSS class "active" is used to highlight the column by which
     * the table is actually sorted.
     * @param headerEl the header to set active
     * @param direction the direction of the sorting
     */
    setActive: function (headerEl, direction) {
      this.cleanOrderIcons();
      headerEl.addClass('active');
      headerEl.find('.weekly-header__order-icon').addClass(direction);
    },
    /**
     * Called on changeOrderButton clicked. Triggers the event 'production-value:weekly:order',
     * passing the data useful for the sort.
     *
     * @param event
     */
    onChangeOrderClicked: function (event) {
      var currentClicked = this.getTarget(event).closest('.header--clickable');
      var type = currentClicked.data('order');
      var direction = this.getOrderDirection(currentClicked);
      this.cleanActiveHeaders();
      this.setActive(currentClicked, direction);
      this.trigger('production-value:weekly:order', {
        type: type,
        direction: direction,
      });
    },
  });

  /**
   * Weekly details Layout
   */
  this.WeeklyDetailLayout = Marionette.LayoutView.extend({
    template: '#weeklyDetailLayoutTemplate',
    className: 'prodValue__detailProjWeekly',
    _weekColl: null,
    _prodProjColl: null,
    _weekLabelColl: null,
    _month: null,
    regions: {
      weekHeader: '[data-region="weeklyDetailHeaderRegion"]',
      weekSelection: '[data-region="weekSelection"]',
      weekLabel: '[data-region="weeklyDetailLabelRegion"]',
      weekColl: '[data-region="weeklyDetailCollRegion"]',
    },
    ui: {
      monthLabel: '[data-region="selectedMonth"]',
      weekTotal: '[data-region="weeklyDetailTotal"]',
    },
    initialize: function (options) {
      this._weekColl = options.weekColl;
      this._month = options.month;

      var labelColl = new ProductionValue.ProdWeekLabelColl();
      this._weekColl.each(function (weekModel) {
        var labelModel = new ProductionValue.ProdWeekLabelModel({ week: weekModel.get('week') });
        labelColl.add(labelModel);
      });

      this._weekLabelColl = labelColl;
    },

    onShow: function () {
      dispatcher.on('report:prod:weekly:selectWeek', function (week) {
        this.selectWeek(week);
      }.bind(this));

      var labelCollView = new ProductionValue.WeeklyDetailLabelCollView({
        collection: this._weekLabelColl,
      });
      this.weekLabel.show(labelCollView);

      this._prodProjColl = this._weekColl.first().get('projs');

      var weekCollView = new ProductionValue.WeeklyDetailCollView({
        collection: this._prodProjColl,
      });
      this.weekColl.show(weekCollView);
      this.ui.monthLabel.text(moment(this._month).format('MMMM'));
      this.showTotal(this._prodProjColl);

      var weekHeaderView = new ProductionValue.WeeklyDetailHeaderView();
      this.weekHeader.show(weekHeaderView);
      this.weekHeader.currentView.on('production-value:weekly:order', function (data) {
        this.orderWeeklyCollectionBy(data.type, data.direction);
      }.bind(this));
    },
    /**
     * Sorts the collection rendered inside weekColl region.
     * @param type
     * @param direction
     */
    orderWeeklyCollectionBy: function (type, direction) {
      var sorted = this._prodProjColl.universalSortBy(type, direction);

      this.weekColl.currentView.collection.reset(sorted, { sort: false });
    },

    /**
     * Renders the projects active in the given week.
     * @param week
     */
    selectWeek: function (week) {
      this.weekHeader.currentView.cleanOrderIcons();
      this.weekHeader.currentView.cleanActiveHeaders();

      this._prodProjColl = this._weekColl.getProjectsByWeek(week);
      var weekCollView = new ProductionValue.WeeklyDetailCollView({
        collection: this._prodProjColl,
      });
      this.weekColl.show(weekCollView);
      this.showTotal(this._prodProjColl);
    },

    showTotal: function (prodProjColl) {
      var total = 0;
      prodProjColl.each(function (prodProjModel) {
        total += prodProjModel.get('product');
      });

      this.ui.weekTotal.text('WEEKLY TOTAL PRODUCTION VALUE: ' + numeral(total / 1000)
        .format('0,0.0') + ' K');
    },

    onDestroy: function () {
      dispatcher.off('report:prod:weekly:selectWeek');
    },
  });

  /**
   * Weekly product Item View
   */
  this.WeeklyDetailItemView = Marionette.ItemView.extend({
    template: '#weeklyDetailCollTemplate',
    tagName: 'li',
    className: 'recordList__record',
    ui: {
      prodHour: '[data-region="weeklyDetailProdHour"]',
      projectName: '.prodValue__detailProjWeekly__projectName',
      goToReportEl: '[data-action="goToReport"]',
    },

    onShow: function () {
      this.model.get('timetracking_days');
      var prodHour = this.model.get('prod_daily');
      var twentyPercent = (this.model.get('estimated_prod_daily') * 20) / 100;

      if (prodHour > (this.model.get('estimated_prod_daily') + twentyPercent)) {
        this.ui.prodHour.addClass('higher');
        this.ui.prodHour.prop('title', 'Higher than estimated');
      } else if (prodHour < (this.model.get('estimated_prod_daily') - twentyPercent)) {
        this.ui.prodHour.addClass('lower');
        this.ui.prodHour.prop('title', 'Lower than estimated');
      }

      this.ui.projectName.prop('title', this.model.get('name'));
      this.ui.goToReportEl.prop('href', this.getReportUrl());
    },

    /**
     * Get the URL to the report for the current project.
     *
     * @returns {string} : i.e. #project/1069/report.
     */
    getReportUrl: function () {
      var projectId = this.model.get('id');
      return '#project/' + projectId + '/report';
    },
  });

  /**
   * Weekly product Collection View
   */
  this.WeeklyDetailCollView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'recordList prodValue__detailProjWeekly__list',
    childView: ProductionValue.WeeklyDetailItemView,
  });

  /**
   * Weekly production label view (button to choose the week)
   */
  this.WeeklyDetailLabelItemView = Marionette.ItemView.extend({
    template: '#weeklyDetailLabelTemplate',
    tagName: 'li',
    ui: {
      label: '[data-region="weekLabel"]',
    },
    events: {
      'click @ui.label': 'selected',
    },
    selected: function (e) {
      e.preventDefault();
      dispatcher.trigger('report:prod:weekly:selectWeek', this.model.get('week'));
      this.ui.label.addClass('sel');
    },
  });

  /**
   * Weekly production label Collection View
   */
  this.WeeklyDetailLabelCollView = Marionette.CollectionView.extend({
    tagName: 'ul',
    className: 'prodValue__detailProjWeekly__switchButton',
    childView: ProductionValue.WeeklyDetailLabelItemView,
    onShow: function () {
      this.children.findByIndex(0).ui.label.addClass('sel');

      dispatcher.on('report:prod:weekly:selectWeek', function () {
        this.children.each(function (view) {
          view.ui.label.removeClass('sel');
        });
      }.bind(this));
    },

    onDestroy: function () {
      dispatcher.off('report:prod:weekly:selectWeek');
    },
  });
}, Wethod.module('HeaderApp.Header'));
