const React = require('react');
const numeral = require('numeral');
const isEqual = require('react-fast-compare');
const NumericInput = require('@common/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');

module.exports = class BudgetArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      internal: this.props.hours.internal,
      remote: this.props.hours.remote,
      travel: this.props.hours.travel,
      overtime: this.props.hours.overtime,
      night_shift: this.props.hours.night_shift,
    };

    this.handleInputBlur = this.handleInputBlur.bind(this);
  }

  handleInputBlur() {
    if (this.areHoursDirty()) {
      this.props.updateHours(this.props.projectId, this.props.date, this.props.id, this.state,
        this.props.notes, this.props.employeeId);
    }
  }

  onChange(e) {
    const { name, value } = e.target;
    const state = {};
    const newAmount = value === '' ? null : parseFloat(value);
    const oldAmount = this.getHours(name);
    if (oldAmount !== newAmount) {
      state[name] = newAmount;
      this.setState(state);
    }
  }

  onNoteClick(e) {
    e.stopPropagation();
    const data = {
      areaId: this.props.id,
      projectId: this.props.projectId,
      types: this.props.hours,
      notes: this.props.notes,
      canEdit: this.props.canEdit,
    };
    this.props.openNote(data);
  }

  setRef(ref) {
    this.tooltipRef = ref;
  }

  getNotesIconClassName() {
    let name = 'wethod-icon-notes';
    if (this.props.notes) {
      name += ' wethod-icon-notes--done';
    }
    return name;
  }

  getNameAreaClass() {
    let name = 'timesheet__budget-area-name';
    if (!this.props.id) {
      name += ' timesheet__budget-area-name--generic';
    }
    return name;
  }

  /**
   * Returns the amount of hours for the given type.
   * @param type
   * @return {*}
   */
  getHours(type) {
    return this.state[type];
  }

  /**
   * Returns total hours for area.
   * If no type is compiled returns null.
   * If a type has an amount of 0 hours it's considered compiled.
   *
   * @return {string|*}
   */
  getTotalHours() {
    const compiledHours = this.getCompiledHours();
    if (compiledHours.length === 0) {
      return '';
    }
    const hours = compiledHours.reduce((sum, type) => sum + type.value, 0);

    return numeral((Math.round(hours * 100) / 100)).format('0,0.00');
  }

  /**
   * Returns a list where each item represents the amount of an hours type.
   * @return {{name: (string), value: *}[]}
   */
  getHoursArray() {
    const hourTypes = ['internal', 'remote', 'travel', 'overtime', 'night_shift'];

    return hourTypes.map((type) => ({
      name: type,
      value: this.getHours(type),
    }));
  }

  /**
   * Returns a list of hours type which value is different from null.
   * @return {{name: string, value: *}[]}
   */
  getCompiledHours() {
    return this.getHoursArray().filter((type) => type.value !== null);
  }

  getFeedback() {
    if (this.props.isSaving) {
      return 'Saving';
    }
    if (!this.props.on && this.getTotalHours()) {
      return 'Area Off';
    }
    return <span>&#160;</span>;
  }

  getTooltipMessage() {
    return !this.props.isInWhitelist ? 'You need to be a whitelisted team member to update this timesheet.' : '';
  }

  /**
   * Returns true if user has updated hours.
   * @returns {boolean}
   */
  areHoursDirty() {
    return !isEqual(this.state, this.props.hours);
  }

  render() {
    return (
      <li className="timesheet__budget-area">
        <div className={this.getNameAreaClass()}>{this.props.name}</div>
        <div className="timesheet__budget-area-feedback">{this.getFeedback()}</div>
        <div className="timesheet__budget-area-hours">
          <NumericInput
            label="internal"
            disabled={!this.props.canEdit}
            name="internal"
            precision={2}
            value={this.getHours('internal')}
            onBlur={this.handleInputBlur.bind(this)}
            onChange={this.onChange.bind(this)} />
        </div>
        <div className="timesheet__budget-area-hours">
          <NumericInput
            label="remote"
            disabled={!this.props.canEdit}
            name="remote"
            precision={2}
            value={this.getHours('remote')}
            onBlur={this.handleInputBlur.bind(this)}
            onChange={this.onChange.bind(this)} />
        </div>
        <div className="timesheet__budget-area-hours">
          <NumericInput
            label="travel"
            disabled={!this.props.canEdit}
            name="travel"
            precision={2}
            value={this.getHours('travel')}
            onBlur={this.handleInputBlur.bind(this)}
            onChange={this.onChange.bind(this)} />
        </div>
        <div className="timesheet__budget-area-hours">
          <NumericInput
            label="night shift"
            disabled={!this.props.canEdit}
            name="night_shift"
            precision={2}
            value={this.getHours('night_shift')}
            onBlur={this.handleInputBlur.bind(this)}
            onChange={this.onChange.bind(this)} />
        </div>
        <div className="timesheet__budget-area-hours">
          <NumericInput
            label="overtime"
            disabled={!this.props.canEdit}
            name="overtime"
            precision={2}
            value={this.getHours('overtime')}
            onBlur={this.handleInputBlur.bind(this)}
            onChange={this.onChange.bind(this)} />
        </div>
        <div className="timesheet__budget-area-notes">
          <div className={this.getNotesIconClassName()}
            aria-label="Open notes"
            onClick={this.onNoteClick.bind(this)} />
        </div>
        <div className="timesheet__budget-area-total">
          <Tooltip label={this.getTooltipMessage()}>
            {this.getTotalHours()}
          </Tooltip>
        </div>
      </li>
    );
  }
};
