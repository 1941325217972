const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/inputs/Select/SelectItem.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const AutocompleteFilter = require('./CustomizableOptionsAutocompleteFilter.react');
const AutocompleteModel = require('../../../../../../../../models/Autocomplete');

module.exports = class EmployeeFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getEmployeesAutocomplete(keyword)
      .done((list) => this.setState({
        availableItems: list.map((project) => project),
        isLoading: false,
      }));
  }

  getOptions() {
    return this.state.availableItems.map((employee) => (
      <SelectItem key={employee.id}
        value={employee.fullName}>
        <span>{_.unescape(employee.fullName)}</span>
        <ShowIf condition={employee.archived}>
          <span className="timesheet-changelog__secondary-info">
            <br />
            ARCHIVED
          </span>
        </ShowIf>
      </SelectItem>
    ));
  }

  render() {
    return (
      <AutocompleteFilter name={this.props.name}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.props.onChange}
        expanded={this.props.expanded}
        isLoading={this.state.isLoading}
        onFilter={this.onFilter}
        getOptions={this.getOptions.bind(this)} />
    );
  }
};
