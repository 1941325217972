const React = require('react');
const $ = require('jquery');
const Table2 = require('@common/Table2/Table2.react');
const TableBody = require('@common/Table2/TableBody.react');
const TableHead = require('@common/Table2/TableHead.react');
const BodyRow = require('./TableBodyRow.react');
const ItemManager = require('./ItemManager.react');
const PermissionManager = require('../containers/PermissionManager');
const HeadRow = require('./TableHeadRow.react');
const TableSearch = require('../containers/TableSearch');

module.exports = class Table extends React.Component {
  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions')
      .outerHeight(true) + $('.section-header').outerHeight(true) + 24);
  }

  constructor(props) {
    super(props);

    this.state = { tableHeight: null };

    this.loadMore = this.loadMore.bind(this);
    this.onSort = this.onSort.bind(this);
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  componentDidUpdate(prevProps) {
    const changedOwnerFilter = prevProps.ownerFilter !== this.props.ownerFilter;
    const changedSearchFilter = prevProps.searchFilter !== this.props.searchFilter;

    if (changedOwnerFilter || changedSearchFilter) {
      this.props.getItems(0, this.props.pageLimit, this.props.sortBy.col, this.props.sortBy.order,
        this.props.ownerFilter, this.props.searchFilter);
    }
  }

  onSort(col, order) {
    this.props.getItems(0, this.props.pageLimit, col, order, this.props.ownerFilter,
      this.props.searchFilter);
  }

  getSort(name) {
    if (this.props.sortBy.col === name) {
      return this.props.sortBy.order;
    }
    return null;
  }

  getRows() {
    return this.props.items.map((item) => (
      <PermissionManager key={item.id}>
        <ItemManager item={item}>
          <BodyRow showSidebar={this.props.showSidebar} />
        </ItemManager>
      </PermissionManager>
    ));
  }

  static getEmptyMessage() {
    return <span>No requests yet. Hit "Add request" to insert one</span>;
  }

  updateTableHeight() {
    this.setState({ tableHeight: Table.getTableMaxHeight() });
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const offset = (page - 1) * size;
      this.props.getItems(offset, size, this.props.sortBy.col, this.props.sortBy.order,
        this.props.ownerFilter, this.props.searchFilter);
    }
  }

  render() {
    return (
      <div ref={(ref) => this.containerRef = ref}>
        <Table2 maxHeight={this.state.tableHeight}
          search={<TableSearch keyword={this.props.searchFilter} />}>
          <TableHead>
            <HeadRow onSort={this.onSort}
              sortBy={this.props.sortBy} />
          </TableHead>
          <TableBody hasMore={this.props.hasMore}
            loadMore={this.loadMore}
            empty={Table.getEmptyMessage()}>
            {this.getRows()}
          </TableBody>
        </Table2>
      </div>
    );
  }
};
