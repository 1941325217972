const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Loader = require('@common/Loader/Loader.react');
const Info = require('../containers/ProjectInfo');
const Actions = require('../containers/Actions');
const Plan = require('../containers/Plan');
const Modal = require('../containers/Modal');
const LinkedPlan = require('../containers/LinkedPlan');

module.exports = class InvoicePlan extends React.Component {
  componentDidUpdate(prevProps) {
    // Save invoice plan if it canBeSaved now
    if (prevProps && this.props
        && !prevProps.canBeSaved && this.props.canBeSaved && !this.props.isWaiting) {
      this.props.saveInvoicePlan(this.props.plan, this.props.projectId);
    }
  }

  getActions() {
    if (!this.props.isLinked && !this.props.isLoading) {
      return <Actions />;
    }

    return null;
  }

  getPlan() {
    if (this.props.isLoading) {
      return (
        <div className="invoice-plan__loader">
          <Loader />
        </div>
      );
    }

    if (this.props.isLinked) {
      return <LinkedPlan />;
    }

    return <Plan />;
  }

  render() {
    return (
      <div className="wethod-section-body invoice-plan">
        <div className="wethod-section-actions">
          <SectionHeader
            current_section="Project invoice plan"
            helper_url="pipeline/index/#invoice-plan"
            big />
          <Info />
          {this.getActions()}
        </div>
        {this.getPlan()}
        <Modal />
      </div>
    );
  }
};
