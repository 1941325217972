const React = require('react');
const Button = require('@common/Button/RoundedButton.react');
const Ellipser = require('@common/Ellipser/Ellipser.react');

const PricelistSelect = ({ name, onClick }) => (
  <Button onClick={onClick}
    border><Ellipser>{name}</Ellipser>
  </Button>
);

module.exports = PricelistSelect;
