const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Modal = require('../containers/Modal');
const Widgets = require('../containers/Widgets');
const Filter = require('../containers/TimeFilter');

module.exports = class Demographics extends React.Component {
  componentDidMount() {
    this.props.getTrends(this.props.timeFilter);
  }

  render() {
    return (
      <div className="wethod-section-body demographics">
        <div className="wethod-section-actions">
          <SectionHeader current_section="Demographics"
            preview_anchor_id="demographics"
            helper_url="reports/index/#demographics"
            big />
          <Filter />
        </div>
        <Widgets />
        <Modal />
      </div>
    );
  }
};
