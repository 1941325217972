const React = require('react');
const numeral = require('numeral');
const Escaper = require('@common/formatters/Escaper.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Infotip = require('@common/Infotip/Infotip.react');
const FavouriteIcon = require('../../common/FavouriteIcon.react');

const ProjectInfo = ({
  addFavorite, removeFavorite, handleClick, userId, ...project
}) => {
  /**
   * Check if favourites can be edited
   * (you cannot edit other users favourite)
   * @returns {boolean}
   */
  const canEditFavourite = !userId;
  const getJobOrderCategoryColorStyle = () => ({
    backgroundColor: project.project.project_type.color,
  });

  /**
   * Returns a list where each item represents the amount of an hours type inside the given area.
   * @param area
   * @return {{name: (string), value: *}[]}
   */
  const getAreaHoursArray = (area) => {
    const hourTypes = ['internal', 'remote', 'travel', 'overtime', 'night_shift'];

    return hourTypes.map((type) => ({
      name: type,
      value: area.hours[type],
    }));
  };

  /**
   * Returns a list of hours type which value is different from null for the given area.
   * @param area
   * @return {{name: *, value: *}[]}
   */
  const getCompiledAreaHours = (area) => getAreaHoursArray(area)
    .filter((type) => type.value !== null);

  /**
   * Returns a list of areas which total amount of hours is different from null.
   * @return {*}
   */
  const getCompiledAreas = () => project.areas
    .filter((area) => getCompiledAreaHours(area).length > 0);

  /**
   * Returns the total amount of hours compiled for the given area.
   * @param area
   * @return {{name: *, value: *}}
   */
  const getAreaTotalHours = (area) => getCompiledAreaHours(area)
    .reduce((sum, type) => sum + type.value, 0);

  /**
   * Returns the sum of the timesheet's hours for the project.
   */
  const getHours = () => {
    // mostra 0 solo se almeno 1 area progetto ha ore compilate
    const compiledAreas = getCompiledAreas();
    if (compiledAreas.length === 0) {
      return '';
    }
    const hours = compiledAreas.reduce((sum, area) => sum + getAreaTotalHours(area), 0);

    return numeral((Math.round(hours * 100) / 100)).format('0,0.00');
  };

  return (
    <div className="timesheet-project__info-list timesheet-project__info-list--detailed"
      onClick={handleClick}>
      <div className="wethod-list-column timesheet__column--favourite">
        <FavouriteIcon projectId={project.id}
          canEdit={canEditFavourite}
          archived={project.project.archived}
          favorite={project.favorite}
          removeFavourite={removeFavorite}
          addFavourite={addFavorite} />
      </div>
      <div className="timesheet-project-info wethod-list-column timesheet__column--project-name">
        <Escaper>{project.project.name}</Escaper>
        <span>{project.project.pm.pm_name} {project.project.pm.pm_surname}</span>
      </div>
      <div className="timesheet-project-info wethod-list-column timesheet__column--automatic">
        <ShowIf condition={project.project.project_type.is_timesheet_automatic}>
          <Infotip label="auto" />
        </ShowIf>
      </div>
      <div className="timesheet-project-info wethod-list-column timesheet__column--client">
        <Escaper>{project.client}</Escaper>
      </div>
      <div className="timesheet-project-info wethod-list-column timesheet__column--job-order">
        <Escaper>{project.project.job_order}</Escaper>
      </div>
      <div className="timesheet-project-info wethod-list-column timesheet__column--job-order-category">
        <span className="timesheet__job-order-category-color"
          style={getJobOrderCategoryColorStyle()} />
        <span className="timesheet__job-order-category-name">{project.project.project_type.name}</span>
      </div>
      <div className="timesheet-project-info wethod-list-column timesheet__column--hours">
        {getHours()}
      </div>
    </div>
  );
};

module.exports = ProjectInfo;
