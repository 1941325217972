/* eslint-disable class-methods-use-this */
const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const OpenSidebarButton = require('@common/OpenSidebarButton.react');

module.exports = class Contact extends React.Component {
  handleSidebarOpen() {
    this.props.openSidebar(this.props.id);
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  render() {
    return (
      <TableRow>
        <TableCell className="client-contacts__column--name">{this.getUnescapedValue(this.props.name)}</TableCell>
        <TableCell className="client-contacts__column--surname">{this.getUnescapedValue(this.props.surname)}</TableCell>
        <TableCell className="client-contacts__column--role">{this.props.role}</TableCell>
        <TableCell className="client-contacts__column--email">
          <a
            href={`mailto:${this.props.email}`}>
            {this.props.email}
          </a>
        </TableCell>
        <TableCell className="client-contacts__column--actions">
          <OpenSidebarButton
            onClick={this.handleSidebarOpen.bind(this)} />
        </TableCell>
      </TableRow>
    );
  }
};
