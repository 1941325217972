const React = require('react');
const Modal = require('@common/modal2/Modal.react');

module.exports = class ConfirmDeleteRatingModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    this.props.onDelete(this.props.supplierId, this.props.data);
  }

  getFeedBack() {
    if (this.props.isDeleting) {
      return <div className="profile-contact-info__feedback">Deleting</div>;
    }
    return null;
  }

  getClassName() {
    let name = 'suppliers__modal';
    if (this.props.isDeleting) {
      name += ' wethod-modal2--disabled';
    }
    return name;
  }

  render() {
    return (
      <Modal title="Delete review"
        onCancelClick={this.props.onClose}
        className={this.getClassName()}>
        <p>
          You are going to permanently <b>delete</b> this review.
        </p>
        <p>
          Are you sure you want to continue?
        </p>
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.props.onClose}
            className="wethod-button">
            Cancel
          </button>
          <button type="button"
            onClick={this.handleDelete}
            className="wethod-button">
            Yes
          </button>
        </div>
        {this.getFeedBack()}
      </Modal>
    );
  }
};
