const React = require('react');
const { connect } = require('react-redux');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Typography = require('@common/Typography/Typography.react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const actions = require('../../actions');

class ToggleArchiveModal extends React.Component {
  static onClose() {
    modalService.closeModal();
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
    };
    this.onOk = this.onOk.bind(this);
  }

  onOk() {
    this.setState({ isLoading: true });
    const promise = this.props.user.employee.type === 0
      ? this.props.archiveEmployee
      : this.props.unarchiveEmployee;
    promise(this.props.user.employee.id)
      .always(() => {
        this.setState({ isLoading: false });
      })
      .done(() => {
        this.props.onSuccess?.();
        this.props.getItems();
        modalService.closeModal();
      })
      .fail((response) => {
        if (typeof response === 'string') {
          this.setState({ error: response });
        }
      });
  }

  render() {
    return (
      <Modal
        onClose={ToggleArchiveModal.onClose}
        title={this.props.user.employee.type === 0 ? 'Archive' : 'Unarchive'}>
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              Are you sure you want to
              { this.props.user.employee.type === 0 ? ' archive' : ' unarchive' }
              <Typography weight={Typography.WEIGHTS.BOLD}>
                { `${this.props.user.person.name} ${this.props.user.person.surname}` }
              </Typography>?
            </ModalBodyBlock>
          </ModalBody>
          <ModalFooter
            error={this.state.error}
            feedback={this.state.isLoading ? 'Saving...' : null}>
            <ModalFooterAction
              onClick={ToggleArchiveModal.onClose}
              disabled={this.state.isLoading}>
              Cancel
            </ModalFooterAction>
            <ModalFooterAction
              onClick={this.onOk}
              disabled={this.state.isLoading}>
              { this.props.user.employee.type === 0 ? 'Archive' : 'Unarchive' }
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

module.exports = connect(null, {
  getItems: actions.getItems,
  archiveEmployee: actions.archiveEmployee,
  unarchiveEmployee: actions.unarchiveEmployee,
})(ToggleArchiveModal);
