const React = require('react');
const Button = require('@common/Button/Button.react');
const PrimaryInfo = require('../HeaderBox/HeaderBoxPrimaryInfo.react');
const Subtitle = require('./ProjectInfoPrimarySectionSubtitle.react');
const OpenSidebarIcon = require('./OpenSidebarIcon.react');

const ProjectInfoPrimarySection = ({
  name,
  client,
  jobOrder,
  onClick,
}) => (
  <PrimaryInfo>
    <Button className="wethod-budget-header__project-primary-section"
      onClick={onClick}
      label="Open project details">
      <div className="wethod-budget-header__project-primary-section-info">
        <h1 className="wethod-budget-header__project-title">{name}</h1>
        <Subtitle>{client}</Subtitle>
        <Subtitle>{jobOrder || '-'}</Subtitle>
      </div>
      <span className="wethod-budget-header__project-primary-section-action">
        <OpenSidebarIcon />
      </span>
    </Button>
  </PrimaryInfo>
);

module.exports = ProjectInfoPrimarySection;
