const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const NumericField = require('@common/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');

const MonthsCell = ({
  updateErrors, readOnly, value, onBlur,
  onFocus, error, name, label, style, metadataService,
}) => {
  const customErrorMessages = { min: (param) => `${param} or more` };
  const minValue = metadataService.getConstraintValue('duration', 'greater_than') || 1;

  return (
    <TableCell className="pipeline-item__months" style={style}>
      <InputValidator updateErrors={updateErrors}
        constraints={['required', `min:${minValue}`]}
        customMessages={customErrorMessages}>
        <InputMetadataManager metadataService={metadataService}
          name={name}
          canEdit={!readOnly}>
          <NumericField
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            errorText={error}
            label={label} />
        </InputMetadataManager>
      </InputValidator>
    </TableCell>
  );
};

module.exports = MonthsCell;
