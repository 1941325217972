/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const InputWrapper = require('@common/material-input/SidebarWrapperEditable.react');
const MemberElement = require('./MemberElement.react');
const MemberSelect = require('../../../containers/Boards/Sidebar/MemberSelect');

module.exports = class SidebarMembers extends React.Component {
  // Init default members value to empty array
  componentDidMount() {
    if (!this.props.value) this.props.onChange(this.props.name, []);
  }

  handleDelete(id) {
    this.props.onChange(this.props.name, this.props.value.filter((member) => member.id !== id));
  }

  getMembers() {
    if (this.props.value) {
      return this.props.value.map((member) => (
        <MemberElement key={member.id} onDelete={this.handleDelete.bind(this)} {...member} />
      ));
    }
  }

  render() {
    return (
      <div>
        <InputWrapper placeholder={this.props.label} icon={this.props.children}>
          <MemberSelect
            name={this.props.name}
            value={this.props.placeholder}
            members={this.props.value}
            onSelect={this.props.onChange} />
        </InputWrapper>
        {this.getMembers()}
      </div>
    );
  }
};
