const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');

const OutOfSyncModal = ({ onCloseClick }) => (
  <Modal title="Outdated information" onCancelClick={onCloseClick}>
    <div>
      Seems like your allocation requests are out of sync.
      <br />
      <br />
      Please <b>reload the page</b> to avoid unexpected results.
    </div>
    <div className="wethod-modal2__actions">
      <Button onClick={onCloseClick}>Close</Button>
    </div>
  </Modal>
);

module.exports = OutOfSyncModal;
