const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Loading = require('@common/LoadingSmall.react');
const Escaper = require('@common/formatters/Escaper.react');
const Button = require('@common/Button/RoundedButton.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Select = require('./WonLostFeedbackSelect.react');

module.exports = class WonLostFeedbackModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReason: null,
    };
  }

  componentDidMount() {
    this.props.getReasonWhy();
  }

  componentDidUpdate(prevProps) {
    const prevReasonWhyList = prevProps && prevProps.data.reasonWhyList
      ? prevProps.data.reasonWhyList.length : null;
    const currentReasonWhyList = this.props && this.props.data.reasonWhyList
      ? this.props.data.reasonWhyList.length : null;

    if (prevReasonWhyList == null && currentReasonWhyList != null) { // reason-why list given
      const selectedReason = this.getSelectedReason();

      this.handleFeedbackChange(null, selectedReason); // Set the initially selected reason
    }
  }

  handleSave() {
    if (!this.state.selectedReason) {
      this.props.onCloseClick();
    }
    const reasonWhy = this.state.selectedReason?.id;

    this.props.saveReasonWhy(
      this.props.data.project.id,
      reasonWhy,
      this.props.data.handleFeedbackSave,
    );
  }

  handleFeedbackChange(name, item) {
    this.setState({ selectedReason: item });
  }

  getWonReasonWhyList() {
    return this.props.data.reasonWhyList ? this.props.data.reasonWhyList.filter((reason) => reason.type === 'won') : [];
  }

  getLostReasonWhyList() {
    return this.props.data.reasonWhyList ? this.props.data.reasonWhyList.filter((reason) => reason.type === 'lost') : [];
  }

  getCurrentTypeReasonWhyList() {
    return this.props.isWon ? this.getWonReasonWhyList() : this.getLostReasonWhyList();
  }

  getCurrentType() {
    return this.props.isWon ? 'won' : 'lost';
  }

  getSelectedReason() {
    const selectedReasonId = this.props.data.project.reason_why; // project reason-why previously selected
    let selectedReason = this.props.data.reasonWhyList
      .filter((reason) => reason.id === selectedReasonId); // Find the object corresponding the previously selected reason-why
    // Selected reason is the one corresponding the previously selected reason-why
    selectedReason = selectedReason && selectedReason.length > 0 ? selectedReason[0] : null;

    if (!selectedReason || !this.isReasonOfCorrectType(selectedReason)) {
      // Previous value cannot be used, the selected reason is by default the first available of the current type (won/lost)
      [selectedReason] = this.getCurrentTypeReasonWhyList();
    }
    return selectedReason;
  }

  getWonLostBody() {
    const projectName = this.props.data.project.name;
    const items = this.getCurrentTypeReasonWhyList();
    const label = this.getCurrentType();

    return (
      <div>
        The project <b><Escaper>{projectName}</Escaper></b> is <b>{label}</b>,
        {' '}
        what's the main reason that led to this?
        <br /><br />
        <Select value={this.state.selectedReason}
          items={items}
          onChange={this.handleFeedbackChange.bind(this)} />
      </div>
    );
  }

  getBody() {
    if (this.props.isWaiting) {
      return <Loading />;
    }

    return this.getWonLostBody();
  }

  isReasonOfCorrectType(reasonWhy) {
    if (!reasonWhy) return false;

    const reasonType = reasonWhy.type;
    const currentType = this.getCurrentType();

    return reasonType === currentType;
  }

  render() {
    return (
      <Modal title="Won/lost feedback"
        className="pipeline__modal-won-lost-feedback"
        onCancelClick={this.props.onCloseClick}>
        {this.getBody()}
        <ShowIf condition={!this.props.isWaiting}>
          <div className="wethod-modal2__actions">
            <Button onClick={this.props.onCloseClick}>Cancel</Button>
            <Button onClick={this.handleSave.bind(this)}
              disabled={this.props.isSaving}>
              Save
            </Button>
          </div>
        </ShowIf>
        <div className="wethod-modal2__feedback">
          <ShowIf condition={this.props.isSaving}>
            <span>Saving...</span>
          </ShowIf>
        </div>
      </Modal>
    );
  }
};
