const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');

const TableHeaderRow = ({ ...rest }) => {
  const WEEKDAYS = [
    'MON',
    'TUE',
    'WED',
    'THU',
    'FRI',
    'SAT',
    'SUN',
  ];
  function getWeekDays() {
    return WEEKDAYS.map((weekday) => (
      <span key={weekday}
        className="company-settings-work-hour-capacity__table-cell-weekday">
        {`${weekday} (h)`}
      </span>
    ));
  }

  return (
    <TableRow className="company-settings-work-hour-capacity__table-row" {...rest}>
      <TableCell className="company-settings-work-hour-capacity__table-cell-name">
        CAPACITY GROUP NAME
      </TableCell>
      <TableCell className="company-settings-work-hour-capacity__table-cell-weekday company-settings-work-hour-capacity__table-cell-weekdays">
        {getWeekDays()}
      </TableCell>
      <TableCell className="company-settings-work-hour-capacity__table-cell-action" />
    </TableRow>
  );
};

module.exports = TableHeaderRow;
