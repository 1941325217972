/* eslint-disable react/no-did-update-set-state,no-param-reassign,no-fallthrough,no-case-declarations,jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label */
/* eslint-disable react/sort-comp,class-methods-use-this,consistent-return,
 react/no-access-state-in-setstate */
const React = require('react');
const Sidebar = require('@common/sidebar/Sidebar.react');
const EditableBody = require('./SidebarEditableBody.react');
const ReadOnlyBody = require('./SidebarReadOnlyBody.react');
const Actions = require('./BasketSidebarActions.react');
const OpportunityManager = require('../containers/OpportunityManager');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * opportunity: object corresponding the focused opportunity to show in the sidebar
 * isSaving: boolean, check for pending saving
 * canEdit: boolean, permission to edit
 *
 * onSave
 * onClose
 * onDelete
 *
 * @type {module.BasketSidebar}
 */
module.exports = class BasketSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      hasUnsavedChanges: false, // is true if any input has unsaved changes, false otherwise
      errors: [], // array containing the name of the attributes that have errors; if the list is empty there are no errors
      opportunity: this.props.opportunity || {},
      editMode: !this.props.opportunity.id, // by default is true if opportunity has no id (when we want to add a new one)
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.opportunity && prevProps && prevProps.opportunity) {
      if (!prevState.opportunity.id && prevProps.opportunity.id) {
        const updatedOpportunity = {
          ...prevState.opportunity,
          id: prevProps.opportunity.id,
        };
        this.setState({
          opportunity: updatedOpportunity,
        });
      }
    }
  }

  /**
   * Checks if it's safe to save an opportunity: you cannot save changes if there's another saving
   * pending or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.state.errors.length === 0;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    const hasLinkedProject = this.props.opportunity ? this.props.opportunity.project_id : false;
    return this.props.canEdit && !this.state.editMode && !hasLinkedProject && !this.props.isLoading;
  }

  isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldOpportunity = this.props.opportunity || {};
    let oldVal = null;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'client_name':
        oldVal = oldOpportunity.client ? oldOpportunity.client_id : null;
        value = value ? value.id : null;
        break;
      case 'account':
        oldVal = oldOpportunity.account ? oldOpportunity.account_id : null;
        value = value ? value.id : null;
        break;
      case 'value':
        oldVal = oldOpportunity.value ? oldOpportunity.value.toString() : '';
        value = value ? value.toString() : '';
        break;
      case 'due_date':
        oldVal = oldOpportunity.due_date;
        value = value && value !== '' ? value : null;
        break;
      default:
        oldVal = oldOpportunity[name];
        break;
    }

    let totalChanges = this.state.unsavedChanges.length;

    // When both values are 'empty', there's no need to compare them
    if ((this.isDirty(oldVal) || this.isDirty(value)) && (oldVal !== value)) {
      // When the value has changed, we insert the name in the changes array, if not already present
      const newChanges = this.state.unsavedChanges;
      if (!newChanges.includes(name)) {
        newChanges.push(name);
      }
      this.setState({ unsavedChanges: newChanges });
      totalChanges += 1;
    } else {
      // When the value is equal to the props, we need to remove it from the changes array if
      // present
      let newChanges = this.state.unsavedChanges;
      newChanges = newChanges.filter((attr) => attr !== name);
      this.setState({ unsavedChanges: newChanges });
      totalChanges = newChanges.length;
    }

    return totalChanges !== 0;
  }

  // Save in the local state the modified opportunity and check if there are unsaved changes
  handleInputChanges(name, value) {
    let changes = {};
    switch (name) {
      case 'client_name':
        const newClient = value || {
          id: null,
          name: null,
        };
        changes = {
          client_id: newClient.id,
          client: newClient,
        };
        break;
      case 'account':
        const newAccount = value ? {
          id: value.id,
          name: value.name,
          surname: '',
        } : null;
        changes = {
          account_id: value ? value.id : null,
          account: newAccount,
        };
        break;
      case 'due_date':
        const date = value && value !== '' ? value : null;
        changes = { due_date: date };
        break;
      default:
        changes = { [name]: value };
        break;
    }

    const newOpportunity = { ...this.state.opportunity, ...changes };

    const hasUnsavedChanges = this.hasChanged(name, value);
    this.setState({
      hasUnsavedChanges,
      opportunity: newOpportunity,
    });
  }

  handleDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.state.opportunity);
    }
  }

  /**
   * Check for input errors and keep the state list of errors updated:
   * to know if there are errors in the sidebar we can count on the number of errors in the list
   */
  handleErrors(name, errors) {
    let updatedErrors = this.state.errors;
    if (errors.length === 0) {
      updatedErrors = updatedErrors.filter((error) => error !== name);
    } else if (!updatedErrors.includes(name)) {
      updatedErrors.push(name);
    }

    this.setState({ errors: updatedErrors });
  }

  handleSave() {
    this.setState({
      editMode: false,
      hasUnsavedChanges: false,
    });

    if (this.props.onSave) {
      this.props.onSave(this.state.opportunity);
    }
  }

  handleEditMode() {
    if (this.canEdit()) {
      this.setState({ editMode: true });
    }
  }

  getBody() {
    if (this.state.editMode) {
      return (
        <OpportunityManager item={this.state.opportunity}>
          <EditableBody opportunity={this.state.opportunity}
            onChange={this.handleInputChanges.bind(this)}
            onValidate={this.handleErrors.bind(this)} />
        </OpportunityManager>
      );
    }
    return (
      <OpportunityManager item={this.state.opportunity}>
        <ReadOnlyBody opportunity={this.state.opportunity} />
      </OpportunityManager>
    );
  }

  getActions() {
    if (this.state.opportunity.id && !this.state.editMode) {
      return (
        <Actions share={this.props.share}
          canEdit={this.canEdit()}
          canDelete={this.props.canDelete}
          opportunity={this.state.opportunity}
          onDelete={this.handleDelete.bind(this)} />
      );
    }

    return null;
  }

  render() {
    return (
      <Sidebar title="Opportunity Details"
        hasUnsavedChanges={this.state.hasUnsavedChanges}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.onClose}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
