const React = require('react');
const Modal = require('@common/modal2/Modal.react');

module.exports = class StartReviewModal extends React.Component {
  getModal() {
    if (!this.props.data || !this.props.data.id) {
      return (
        <div>
          Request has been sent.
          <div className="profile-contact-info__actions">
            <button type="button" onClick={this.props.onCancelClick} className="wethod-button">Ok</button>
          </div>
        </div>
      );
    }
    return (
      <div>
        Request has been sent.
        <br />
        <br />
        Do you want to
        {' '}
        <b>review the project and its team </b>
        now?
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.props.onCancelClick} className="wethod-button">I'll do it later</button>
          <a
            className="wethod-button wethod-button--confirm"
            href={`/#project/${this.props.data.request.project}/review/${this.props.data.id}`}>
            Yes
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal title="Project & team review" onCancelClick={this.props.onCancelClick} className="project-canvas-alert">
        {this.getModal()}
      </Modal>
    );
  }
};
