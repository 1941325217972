const React = require('react');
const moment = require('moment');
const Modal = require('@common/Modal/Modal.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const MonthPicker = require('@common/inputs/MonthPicker/OutlinedMonthPicker/OutlinedMonthPicker.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Icon = require('@common/Icon/Icon.react');
const Select = require('@common/inputs/Select/ColorfulSelect/OutlinedColorfulSelect/OutlinedColorfulSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItemColorful.react');
const InlineFields = require('../InlineFields.react');
const { LABELS } = require('./EditStatusModal.react');
const status = require('../../segmentsStatus');
const timelines = require('../../timelines');

module.exports = class CreateSegmentModal extends React.Component {
  static getFormattedMonth(date) {
    return date ? moment(date).format('MMM YYYY') : '';
  }

  constructor(props) {
    super(props);

    this.state = {
      freeSegment: this.getFreeSegment(),
      startDate: this.getFreeSegment()?.from,
      endDate: null,
      selectedStatus: this.getTimelineStatus(),
    };
  }

  handleDateChange(e) {
    const formattedDate = e.target.value
      ? moment(e.target.value).endOf('month').format('YYYY-MM-DD')
      : null;
    this.setState({
      endDate: formattedDate,
    });
  }

  handleSave() {
    const updatedSegment = {
      status: this.state.selectedStatus,
      from: this.state.startDate,
      to: this.state.endDate,
    };
    this.props.save(this.getUpdatedTimelineSegments(updatedSegment), this.props.timeline);
  }

  /**
   * Append the updated segment to the timeline, remove the free segment
   * @param updatedSegment
   */
  getUpdatedTimelineSegments(updatedSegment) {
    const timeline = this.getSelectedTimeline();
    return timeline.segments.map((segment) => {
      if (segment.status === status.STATUS.FREE) {
        return updatedSegment;
      }
      return segment;
    });
  }

  getSelectedTimeline() {
    return this.props.timelines
      .find((timeline) => timeline.name.toLowerCase() === this.props.timeline?.toLowerCase());
  }

  getFreeSegment() {
    return this.props.timelines
      .find((timeline) => timeline.name.toLowerCase() === this.props.timeline?.toLowerCase())
      ?.segments
      ?.find((segment) => segment.status === status.STATUS.FREE);
  }

  getTitle() {
    return `Create segment - ${timelines.getTimelineLabel(this.props.timeline)}`;
  }

  getDescription() {
    switch (this.props.timeline?.toLowerCase()) {
      case timelines.TIMELINE_TIMESHEET:
        return 'Choose the time range to apply the data editing pause:';
      default:
        return 'Choose a time range to analyze before applying data freezing preferences:';
    }
  }

  getFeedback() {
    return this.props.isSaving ? 'Saving...' : null;
  }

  getTimelineStatus() {
    let defaultStatus;
    switch (this.props.timeline?.toLowerCase()) {
      case timelines.TIMELINE_TIMESHEET:
        defaultStatus = status.STATUS.PAUSED;
        break;
      default:
        defaultStatus = status.STATUS.IN_REVIEW;
        break;
    }
    return defaultStatus;
  }

  getOptions() {
    return [
      <SelectItem disabled
        key={this.state.selectedStatus}
        value={this.state.selectedStatus}
        color={status.COLORS[this.state.selectedStatus]}>
        {LABELS[this.state.selectedStatus]}
      </SelectItem>,
    ];
  }

  render() {
    return (
      <Modal title={this.getTitle()} onClose={this.props.closeModal}>
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              {this.getDescription()}
            </ModalBodyBlock>
            <ModalBodyBlock>
              <InlineFields>
                <TextField readOnly
                  name="start_date"
                  label="From"
                  value={CreateSegmentModal.getFormattedMonth(this.state.startDate)}
                  onChange={this.handleDateChange.bind(this)} />
                <MonthPicker name="end_date"
                  label="To"
                  id="segment-end-date"
                  value={this.state.endDate}
                  onChange={this.handleDateChange.bind(this)}
                  disableBefore={this.state.startDate}
                  disableAfterEqual={this.state.freeSegment?.to}
                  suffix={<Icon icon="calendar" label="end-date-icon" />} />
              </InlineFields>
              <br />
              <Select readOnly
                value={this.state.selectedStatus}
                color={status.COLORS[this.state.selectedStatus]}
                label="Segment status"
                id="segment-status"
                name="segment-status">
                {this.getOptions()}
              </Select>
            </ModalBodyBlock>
          </ModalBody>
          <Footer feedback={this.getFeedback()}>
            <FooterAction onClick={this.props.closeModal} disabled={this.props.isSaving}>
              Dismiss
            </FooterAction>
            <FooterAction onClick={this.handleSave.bind(this)}
              disabled={this.props.isSaving || !this.state.endDate}>
              Confirm
            </FooterAction>
          </Footer>
        </ModalContent>
      </Modal>
    );
  }
};
