const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const BudgetFooterButton = require('../BudgetFooterButton.react');

const MulticurrencyActionButton = ({ label, onClick, disabled }) => (
  <BudgetFooterButton label={label}
    onClick={onClick}
    disabled={disabled}
    noBorder>
    <Icon icon="multicurrency" />
  </BudgetFooterButton>
);

module.exports = MulticurrencyActionButton;
