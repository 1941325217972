const React = require('react');
const RadioGroup = require('@common/inputs/RadioGroup/RadioGroup.react');
const RadioButtonRow = require('../RadioButtonRow.react');
const SelectItem = require('../SettingsSelectItem.react');
const Select = require('../SettingsSelect.react');
const constants = require('../../constants');

const TimesheetStrategy = ({
  strategy, dailyModeToggle,
  isSaving, isSavingDailyModeToggle,
  onStrategyChange, onDailyModeChange,
}) => {
  function handleStrategyChange(e) {
    const { value } = e.target;
    onStrategyChange(value);
  }

  const feedback = isSaving ? 'Saving...' : null;
  const feedbackDailyModeToggle = isSavingDailyModeToggle ? 'Saving...' : null;

  return (
    <RadioGroup name="strategy" value={strategy} onChange={handleStrategyChange}>
      <RadioButtonRow
        label="Use weekly timesheet"
        value="weekly"
        feedback={feedback || feedbackDailyModeToggle}
        description={(
          <TimesheetWeeklyToggle
            label="Edit timesheet for the whole week and allow the user to edit timesheet daily:"
            strategy={strategy}
            dailyModeToggle={dailyModeToggle}
            isSaving={isSaving || isSavingDailyModeToggle}
            onChange={onDailyModeChange} />
        )} />
      <RadioButtonRow
        border={false}
        feedback={feedback}
        rowClassName="company-settings--border-top"
        label="Use daily detailed timesheet"
        value="daily"
        description="Edit timesheet daily and express the type of working hours (i.e. overtime or travel) for each project's area." />
    </RadioGroup>
  );
};

module.exports = TimesheetStrategy;

const TimesheetWeeklyToggle = ({
  label,
  strategy,
  dailyModeToggle,
  isSaving,
  onChange,
}) => {
  function handleDailyToggleChange(e) {
    const { value } = e.target;
    onChange(value);
  }

  const isWeekly = () => strategy === 'weekly';

  return (
    <div className="company-settings-flex company-settings-flex--vertical-center">
      <div className="company-settings-margin-right-s">{label}</div>
      <Select className="company-settings__text company-settings--line-height-one"
        name="timesheet-daily-toggle"
        label="timesheet-daily-toggle"
        value={dailyModeToggle}
        onChange={handleDailyToggleChange}
        disabled={isSaving || !isWeekly()}>
        <SelectItem value={constants.DAILY_MODE_USER_CHOICE}>By user’s choice</SelectItem>
        <SelectItem value={constants.DAILY_MODE_ALWAYS_ON}>Always</SelectItem>
      </Select>
    </div>
  );
};
