const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const Button = require('@common/Button/RoundedButton.react');

const DeleteJOTemplateConfirmModal = ({ data, waitingForDelete, onClose, onDelete }) => {
  function handleDelete() {
    onDelete(data.id);
  }

  const isWaiting = waitingForDelete.some((key) => key.includes(data.id));

  function getFeedback() {
    return isWaiting ? 'Deleting...' : undefined;
  }

  return (
    <Modal title="Delete job order template" onClose={onClose}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            You are about to <b>delete</b> a job order template.
          </ModalBodyBlock>
          <ModalBodyBlock>
            Are you sure you want to continue?
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={getFeedback()}>
          <Button className="company-settings__button company-settings__button--white" onClick={onClose}>Cancel</Button>
          <Button className="company-settings__button company-settings__button--blue"
            onClick={handleDelete}
            disabled={!data || !data.id || isWaiting}>
            Delete template
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = DeleteJOTemplateConfirmModal;
