const { connect } = require('react-redux');
const Component = require('../../components/ProjectInfo/ProjectInfo.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  project: state.selected_project,
  loading: state.waiting_for.filter((request) => request === 'project-availability').length > 0,
  planningSettings: state.planningSettings,
  selectedPricelist: state.selectedPricelist,
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(actions.closeProjectInfo()),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
