const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const Tooltip = require('@common/tooltip/Tooltip.react');
const Loader = require('@common/Loader/Loader.react');
const Search = require('../containers/SearchBox');
const Row = require('./BudgetChangelogRow.react');

module.exports = class BudgetChangelogList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  handleSort(col, order) {
    if (!this.props.isWaiting) {
      this.props.sort(col, order);
    }
  }

  getSort(col) {
    const currentSort = this.props.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  getChangelogs() {
    return this.props.logs.map((item) => (
      <Row key={item.id} log={item} showNoteModal={this.props.showNoteModal} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) return <Loader />;
    return 'There’s nothing here.';
  }

  getSearch() {
    return <Search filter={this.filter.bind(this)} />;
  }

  updateTableHeight() {
    this.setState({ tableHeight: BudgetChangelogList.getTableMaxHeight() });
  }

  hasMore() {
    return this.props.hasMore;
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      this.props.loadMore(size, page);
    }
  }

  filter(keyword) {
    this.props.filter(keyword);
  }

  render() {
    return (
      <div>
        <Table columns={15} maxHeight={this.state.tableHeight} search={this.getSearch()}>
          <TableHead>
            <TableRow>
              <TableCell name="joc"
                className="budget-changelog__column-joc" />
              <TableCell name="project_name"
                className="budget-changelog__column-project"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('project_name')}>
                Project
              </TableCell>
              <TableCell name="project_probability"
                className="budget-changelog__column-probability budget-changelog__column--small budget-changelog__column--center"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('project_probability')}>
                Prob (%)
              </TableCell>
              <TableCell name="pm_name"
                className="budget-changelog__column-person"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('pm_name')}>
                PM
              </TableCell>
              <TableCell name="account_name"
                className="budget-changelog__column-person"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('account_name')}>
                Account
              </TableCell>
              <TableCell name="to_value"
                className="budget-changelog__column-value budget-changelog__column--center budget-changelog__column--tooltip"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('to_value')}>
                Value (K)
                <Tooltip
                  content="Final Net Price value (K)"
                  id="fnp" />
              </TableCell>
              <TableCell name="fnp_margin"
                className="budget-changelog__column-margin budget-changelog__column--small budget-changelog__column--center budget-changelog__column--tooltip"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('fnp_margin')}>
                <span className="budget-changelog__column-margin--header">
                  <span>K</span>
                  <span>(Margin)</span>
                </span>
                <Tooltip
                  content="Final Net Price's margin value (K)"
                  id="margin-K" />
              </TableCell>
              <TableCell name="margin_delta"
                className="budget-changelog__column-margin budget-changelog__column--small budget-changelog__column--center budget-changelog__column--tooltip"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('margin_delta')}>
                <span className="budget-changelog__column-margin--header">
                  <span>Δ K</span>
                  <span>(Margin)</span>
                </span>
                <Tooltip
                  content="Delta value (K) between Final Net Price's margin and Suggested Price's margin"
                  id="delta-margin-K" />
              </TableCell>
              <TableCell name="to_margin"
                className="budget-changelog__column-margin budget-changelog__column--small budget-changelog__column--center budget-changelog__column--tooltip"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('to_margin')}>
                <span className="budget-changelog__column-margin--header">
                  <span>%</span>
                  <span>(Margin)</span>
                </span>
                <Tooltip
                  content="Final Net Price's margin value (%)"
                  id="margin-%" />
              </TableCell>
              <TableCell name="percentage_delta"
                className="budget-changelog__column-margin budget-changelog__column--small budget-changelog__column--center budget-changelog__column--tooltip"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('percentage_delta')}>
                <span className="budget-changelog__column-margin--header">
                  <span>Δ %</span>
                  <span>(Margin)</span>
                </span>
                <Tooltip
                  content="Delta value (%) between Final Net Price's margin and Suggested Price's margin"
                  id="delta-margin-%" />
              </TableCell>
              <TableCell name="version"
                className="budget-changelog__column-version budget-changelog__column--small budget-changelog__column--center"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('version')}>
                Version
              </TableCell>
              <TableCell name="prev_fpn_margin"
                className="budget-changelog__column-version-margin budget-changelog__column--center budget-changelog__column--tooltip"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('prev_fpn_margin')}>
                <span className="budget-changelog__column-margin--header">
                  <span>Δ K</span>
                  <span>(V. Margin)</span>
                </span>
                <Tooltip
                  content="Delta value (K) between Final Net Price's margin and previous version's margin"
                  id="delta-version-margin-K" />
              </TableCell>
              <TableCell name="approved_by"
                className="budget-changelog__column-person"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('approved_by')}>
                Approved by
              </TableCell>
              <TableCell name="approved_on"
                className="budget-changelog__column-date"
                onSort={this.handleSort.bind(this)}
                sort={this.getSort('approved_on')}>
                Approved on
              </TableCell>
              <TableCell name="notes" className="budget-changelog__column-notes" />
              <TableCell name="link" className="budget-changelog__column-link" />
            </TableRow>
          </TableHead>
          <TableBody hasMore={this.hasMore()}
            loadMore={this.loadMore.bind(this)}
            empty={this.getEmptyContent()}>
            {this.getChangelogs()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
