const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const Select = require('@common/inputs/Select/ColorfulSelect/OutlinedColorfulSelect/OutlinedColorfulSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItemColorful.react');
const Typography = require('@common/Typography/Typography.react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');
const status = require('../../segmentsStatus');
const timelines = require('../../timelines');

class EditStatusModal extends React.Component {
  static getWarningsTooltipLabel(optionStatus) {
    if (optionStatus !== status.STATUS.PAUSED && optionStatus !== status.STATUS.FROZEN) {
      return null;
    }
    const title = optionStatus === status.STATUS.PAUSED ? 'Can’t pause data edit' : 'Can’t freeze data';
    return (
      <span>
        <Typography size={Typography.SIZES.PX11} weight={Typography.WEIGHTS.SEMIBOLD}>
          {title}
        </Typography>
        <br />
        Review and resolve mandatory warnings below<br />
        before applying changes to data edit status.
      </span>
    );
  }

  static getMergedDateSegment(oldSegment, newSegment) {
    return {
      ...oldSegment,
      status: newSegment.status,
      // from is the minimum date between the two segments
      from: oldSegment.from < newSegment.from ? oldSegment.from : newSegment.from,
      // to is the maximum date between the two segments
      to: oldSegment.to > newSegment.to ? oldSegment.to : newSegment.to,
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedStatus: this.props.segment.status,
    };
  }

  handleSave() {
    const isStatusEqual = this.state.selectedStatus === this.props.segment.status;
    if (isStatusEqual) {
      this.props.closeModal();
      return;
    }
    if (this.isSaveDisabled()) {
      return;
    }

    const updatedTimelineSegments = this
      .getUpdatedTimelineSegments(this.getSelectedTimelineSegments());

    switch (this.state.selectedStatus) {
      case status.STATUS.PAUSED:
        this.handlePauseSave(updatedTimelineSegments);
        break;
      case status.STATUS.FROZEN:
        this.handleFreezeSave(updatedTimelineSegments);
        break;
      case status.STATUS.IN_REVIEW:
      case status.STATUS.FREE:
        this.handleStatusSave(updatedTimelineSegments);
        break;
      default:
        break;
    }
  }

  handlePauseSave(updatedTimelineSegments) {
    if (this.hasAlerts()) {
      this.props.showIgnoreAlertsModal(updatedTimelineSegments, this.props.segment.timeline);
    } else {
      this.props.showConfirmPauseModal(updatedTimelineSegments, this.props.segment.timeline);
    }
  }

  handleFreezeSave(updatedTimelineSegments) {
    this.props.showConfirmFreezeModal(updatedTimelineSegments, this.props.segment.timeline);
  }

  /**
   * Remove segment with old status
   * Segment with new status will automatically be added
   */
  handleStatusSave(updatedTimelineSegments) {
    this.props.save(updatedTimelineSegments, this.props.segment.timeline);
  }

  handleChange(e) {
    this.setState({
      selectedStatus: e.target.value,
    });
  }

  /**
   * Get the selected timeline
   * @returns {*}
   */
  getSelectedTimelineSegments() {
    const { segments } = this.props.timelines
      .find((timeline) => timeline.name.toLowerCase()
        === this.props.segment.timeline.toLowerCase());
    return segments;
  }

  /**
   * Get the timeline ready to be saved, with the updated segments replacing the old ones
   * @param segments
   */
  getUpdatedTimelineSegments(segments) {
    return this.getTimelineWithMergedSegments(
      this.getTimelineWithoutReplacedSegment(segments),
    );
  }

  /**
   * Get the timeline with the updated segments replacing the old ones
   *
   * @param segments
   * @returns {(*|T)[]} - updated segments
   */
  getTimelineWithMergedSegments(segments) {
    const newSegment = {
      from: this.props.segment.from,
      to: this.props.segment.to,
      status: this.state.selectedStatus,
    };

    const existingSegment = segments.find((segment) => segment.status === newSegment.status);

    if (existingSegment) {
      return segments.map((segment) => (
        segment.status === newSegment.status
          ? EditStatusModal.getMergedDateSegment(segment, newSegment)
          : segment));
    }
    // If there is no segment with the new status, add the new segment
    return [...segments, newSegment];
  }

  /**
   * Get the timeline without the segment that is going to be replaced
   *
   * @param segments
   * @returns {T[]}
   */
  getTimelineWithoutReplacedSegment(segments) {
    return segments
      .filter((segment) => segment.status !== this.props.segment.status);
  }

  getTitle() {
    return `Data editing preferences - ${timelines.getTimelineLabel(this.props.segment.timeline)}`;
  }

  getPermissionTooltipLabel(optionStatus, segmentStatus) {
    if (optionStatus !== status.STATUS.PAUSED && optionStatus !== status.STATUS.FROZEN
      && segmentStatus !== status.STATUS.PAUSED) {
      return null;
    }
    const title = optionStatus === status.STATUS.FROZEN
      ? 'Can’t freeze data edit'
      : 'Can’t edit data pause';
    const blockedStatus = this.props.permissions[status.PERMISSIONS[optionStatus]]
      ? segmentStatus
      : optionStatus;
    return (
      <span>
        <Typography size={Typography.SIZES.PX11} weight={Typography.WEIGHTS.SEMIBOLD}>
          {title}
        </Typography>
        <br />
        You don’t have permission to change {blockedStatus} period.
      </span>
    );
  }

  getFreeSegmentTooltipLabel(optionsStatus) {
    if (optionsStatus !== status.STATUS.FREE) {
      return null;
    }

    const isPausedInAllData = this.hasPausedSegmentInAllData();
    const isPausedInTimesheet = this.props.segment.status === status.STATUS.PAUSED;
    const isTimesheetTimeline = this.props.segment.timeline
      .toLowerCase() === timelines.TIMELINE_TIMESHEET;

    if (isPausedInTimesheet && isTimesheetTimeline && isPausedInAllData) {
      return (
        <span>
          <Typography size={Typography.SIZES.PX11} weight={Typography.WEIGHTS.SEMIBOLD}>
            Can’t set status to Free
          </Typography>
          <br />
          Cannot set status to Free when there is a paused segment in the main timeline.
        </span>
      );
    }

    return null;
  }

  getFeedback() {
    return this.props.isSaving ? 'Saving...' : null;
  }

  getAvailableStatuses() {
    const segments = this.props.timelines
      .find((timeline) => timeline.name.toLowerCase() === this.props.segment.timeline.toLowerCase())
      ?.segments;

    return EditStatusModal.AVAILABLE_STATUSES(segments)[this.props.segment.status];
  }

  /**
   * Return options for select based on current segment status
   * If segment has warnings, disable option and show tooltip for PAUSED and FROZEN statuses
   * @return {Array}
   */
  getOptions() {
    const hasWarnings = this.hasWarnings(this.props.segment.timeline);
    return this.getAvailableStatuses().map((key) => {
      const isEqual = key === this.props.segment.status;
      const canEdit = this.canEditStatus(key);
      if (!canEdit) {
        return (
          <Tooltip key={key}
            label={this.getPermissionTooltipLabel(key, this.props.segment.status)}>
            <SelectItem value={key} color={status.COLORS[key]} disabled>
              {EditStatusModal.LABELS[key]}
            </SelectItem>
          </Tooltip>
        );
      }
      const tooltipLabel = EditStatusModal.getWarningsTooltipLabel(key);
      const needsTooltip = !isEqual && hasWarnings && tooltipLabel;
      if (needsTooltip) {
        return (
          <Tooltip label={tooltipLabel} key={key}>
            <SelectItem value={key} color={status.COLORS[key]} disabled>
              {EditStatusModal.LABELS[key]}
            </SelectItem>
          </Tooltip>
        );
      }
      const freeSegmentTooltipLabel = this.getFreeSegmentTooltipLabel(key);
      if (freeSegmentTooltipLabel) {
        return (
          <Tooltip label={freeSegmentTooltipLabel} key={key}>
            <SelectItem value={key} color={status.COLORS[key]} disabled>
              {EditStatusModal.LABELS[key]}
            </SelectItem>
          </Tooltip>
        );
      }
      return (
        <SelectItem key={key} value={key} color={status.COLORS[key]}>
          {EditStatusModal.LABELS[key]}
        </SelectItem>
      );
    });
  }

  hasPausedSegmentInAllData() {
    const allDataTimeline = this.props.timelines
      .find((timeline) => timeline.name.toLowerCase() === timelines.TIMELINE_ALL);
    return allDataTimeline && allDataTimeline.segments
      .some((segment) => segment.status === status.STATUS.PAUSED);
  }

  /**
   * Check if user has permissions to edit status based on current segment status and selected option
   * @param option - status option to check
   * @returns {*|false}
   */
  canEditStatus(option) {
    return option === this.props.segment.status
      || (this.props.permissions[status.PERMISSIONS[option]]
        && this.props.permissions[status.PERMISSIONS[this.props.segment.status]]);
  }

  /**
   * Check if segment has warnings based on totals
   * Alerts are not considered mandatory warnings
   * @returns {*}
   */
  hasWarnings(timeline) {
    if (timelines.isMainTimeline(timeline)) {
      return this.props.totals
        ? Object.entries(this.props.totals).some(([key, value]) => key !== 'alerts' && value > 0)
        : false;
    }
    return false;
  }

  /**
   * Check if segment has alerts based on totals
   * @returns {*|boolean}
   */
  hasAlerts() {
    return this.props.totals && this.props.totals.alerts > 0;
  }

  isSaveDisabled() {
    return !this.state.selectedStatus || this.props.isSaving;
  }

  render() {
    return (
      <Modal title={this.getTitle()} onClose={this.props.closeModal}>
        <ModalContent isLoading={this.props.isWaitingForWarnings}>
          <ModalBody>
            <ModalBodyBlock>
              Apply data editing preferences on segment.
            </ModalBodyBlock>
            <ModalBodyBlock>
              <Select value={this.state.selectedStatus}
                color={status.COLORS[this.state.selectedStatus]}
                label="Segment status"
                name="segment-status"
                onChange={this.handleChange.bind(this)}>
                {this.getOptions()}
              </Select>
            </ModalBodyBlock>
          </ModalBody>
          <Footer feedback={this.getFeedback()}>
            <FooterAction onClick={this.props.closeModal}>
              Dismiss
            </FooterAction>
            <FooterAction onClick={this.handleSave.bind(this)}
              disabled={this.isSaveDisabled()}>
              Confirm
            </FooterAction>
          </Footer>
        </ModalContent>
      </Modal>
    );
  }
}

EditStatusModal.LABELS = {
  [status.STATUS.FREE]: 'Free',
  [status.STATUS.IN_REVIEW]: 'Data review',
  [status.STATUS.PAUSED]: 'Pause data edit',
  [status.STATUS.FROZEN]: 'Permanently freeze data',
};

EditStatusModal.AVAILABLE_STATUSES = (segments) => ({
  [status.STATUS.FREE]: [
    status.STATUS.FREE,
    status.STATUS.IN_REVIEW,
  ],
  [status.STATUS.IN_REVIEW]: [
    status.STATUS.FREE,
    status.STATUS.IN_REVIEW,
    status.STATUS.PAUSED,
  ],
  [status.STATUS.PAUSED]: [
    segments && segments.some((segment) => segment.status === status.STATUS.IN_REVIEW)
      ? status.STATUS.IN_REVIEW
      : status.STATUS.FREE,
    status.STATUS.PAUSED,
    status.STATUS.FROZEN,
  ],
  [status.STATUS.FROZEN]: [
    status.STATUS.FROZEN,
  ],
});

module.exports = EditStatusModal;
