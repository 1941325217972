const React = require('react');
const IconButtonTooltip = require('@common/TooltipFixed/IconButtonTooltip.react');

const BudgetTemplateSidebarActions = ({ onDelete }) => (
  <div>
    <IconButtonTooltip label="Delete template" onClick={onDelete} borderless>
      <span className="wethod-icon-delete wethod-icon-delete--black" />
    </IconButtonTooltip>
  </div>
);

module.exports = BudgetTemplateSidebarActions;
