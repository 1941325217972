const React = require('react');
const isEqual = require('react-fast-compare');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const TableSearch = require('./ProjectSearch.react');
const Project = require('./Project.react');

module.exports = class ProjectList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      hasMore: true,
      isLoading: false,
      search: '',
      size: 0,
      page: 0,
      sortBy: {
        col: 'project',
        order: 'asc',
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const loading = !prevState.isLoading && this.state.isLoading;
    const buChanged = !isEqual(prevProps.buFilter, this.props.buFilter);

    if (loading) {
      this.getProjects();
    } else if (buChanged) {
      this.filterBu();
    }
  }

  onSort(col, order) {
    if (!this.state.isLoading) {
      this.setState({
        sortBy: {
          col,
          order,
        },
        page: 0,
        projects: [],
        hasMore: true,
        isLoading: true,
      });
    }
  }

  getRows() {
    return this.state.projects.map((project) => (
      <Project key={project.id}
        id={project.id}
        name={project.name}
        ordered={project.ordered}
        periodOfSupply={project.period_of_supply}
        projectType={project.project_type}
        bu={project.bu}
        jobOrder={project.job_order}
        pm={project.pm} />
    ));
  }

  getSort(col) {
    const currentSort = this.state.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  getProjects() {
    const options = {
      supplierId: this.props.supplierId,
      offset: this.state.page * this.state.size,
      limit: this.state.size,
      search: this.state.search,
      orderBy: this.state.sortBy.col,
      sort: this.state.sortBy.order,
      bu: this.props.buFilter.join(),
    };
    const request = Wethod.request('get:finance:supplier:projects', options);
    $.when(request)
      .done((projects) => this.setState((prevState) => ({
        projects: prevState.projects.concat(projects.toJSON()),
        isLoading: false,
        hasMore: projects.length === prevState.size,
      })));
  }

  filterBu() {
    this.setState({
      hasMore: true,
      isLoading: true,
      projects: [],
    });
  }

  loadMore(size, page) {
    if (!this.state.isLoading) {
      this.setState({
        size,
        page: page - 1,
        isLoading: true,
      });
    }
  }

  filter(keyword) {
    if (!this.state.isLoading) {
      this.setState({
        search: keyword,
        page: 0,
        hasMore: true,
        isLoading: true,
        projects: [],
      });
    }
  }

  render() {
    return (
      <Table columns={5}
        maxHeight={this.props.tableHeight}
        search={(
          <TableSearch keyword={this.state.search}
            filter={this.filter.bind(this)} />
        )}>
        <TableHead>
          <TableRow>
            <TableCell key="project"
              sort={this.getSort('project')}
              onSort={this.onSort.bind(this)}
              name="project"
              className="supplier-project__column--name">
              Name
            </TableCell>
            <TableCell key="pm"
              sort={this.getSort('pm')}
              onSort={this.onSort.bind(this)}
              name="pm"
              className="supplier-project__column--pm">
              PM
            </TableCell>
            <TableCell key="project_type"
              sort={this.getSort('project_type')}
              onSort={this.onSort.bind(this)}
              name="project_type"
              className="supplier-project__column--type">
              Project type
            </TableCell>
            <TableCell key="period_of_supply"
              sort={this.getSort('period_of_supply')}
              onSort={this.onSort.bind(this)}
              name="period_of_supply"
              className="supplier-project__column--period">
              Period of supply
            </TableCell>
            <TableCell key="ordered"
              sort={this.getSort('ordered')}
              onSort={this.onSort.bind(this)}
              name="ordered"
              className="supplier-project__column--ordered">
              Ordered
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.state.hasMore}
          loadMore={this.loadMore.bind(this)}
          empty="There are no projects for this supplier yet!">
          {this.getRows()}
        </TableBody>
      </Table>
    );
  }
};
