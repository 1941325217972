const { connect } = require('react-redux');
const SearchInput = require('@common/SearchInput.react');
const { filterAlerts } = require('../actions');

const mapStateToProps = () => ({ placeholder: 'Search' });

const mapDispatchToProps = (dispatch) => ({
  filter: (keyword) => dispatch(filterAlerts(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(SearchInput);
