const { connect } = require('react-redux');
const Component = require('@common/Table2/TableSearch.react');

const getKeyword = (filters) => filters.filter((filter) => filter.type === 'keyword')[0].target;

const mapStateToProps = (state) => ({
  placeholder: 'Search users, projects or clients',
  keyword: getKeyword(state.people_filters),
});

const mapDispatchToProps = (dispatch) => ({
  updateHeight: () => dispatch(() => {
  }),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
