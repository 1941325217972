const React = require('react');
const Select = require('@common/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItem.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const ForOpportunities = require('../../containers/ForOpportunities');
const ForProject = require('../../containers/ForProjects');

const ProbabilitySelect = ({
  updateErrors, value, creation, onChange, errorText, name, label, id, readOnly, disabled,
}) => {
  const getAvailableValues = (filter) => {
    if (filter) {
      if (filter === 'project') {
        return [90, 100];
      }
      if (filter === 'opportunity') {
        return [0, 10, 25, 50, 75];
      }
    }
    return [0, 10, 25, 50, 75, 90, 100];
  };

  const getOptions = (filter) => getAvailableValues(filter).map((percentage) => (
    <SelectItem key={percentage} value={percentage}>{percentage} %</SelectItem>
  ));

  const getSelect = (filter) => (
    <InputValidator updateErrors={updateErrors} constraints={['required']}>
      <Select readOnly={readOnly}
        disabled={disabled}
        value={value}
        label={label}
        id={id}
        name={name}
        onChange={onChange}
        errorText={errorText}>
        {getOptions(filter)}
      </Select>
    </InputValidator>
  );

  if (creation) {
    return (
      <div>
        <ForProject>
          {getSelect('project')}
        </ForProject>
        <ForOpportunities>
          {getSelect('opportunity')}
        </ForOpportunities>
      </div>
    );
  }

  return getSelect();
};

module.exports = ProbabilitySelect;
