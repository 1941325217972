const { connect } = require('react-redux');
const Component = require('@common/TabSwitcher.react');
const actions = require('../../actions');

const items = [
  {
    key: false,
    name: 'Current',
  }, {
    key: true,
    name: 'Archived',
  }, {
    key: 'all',
    name: 'All',
  },
];

const mapStateToProps = (state) => ({
  items,
  current: state.jobTitleSettings.archivedFilter,
});

const mapDispatchToProps = (dispatch) => ({
  handleClick: (key) => dispatch(actions.getJobTitles(key)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
