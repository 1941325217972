const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const Table = require('../containers/TimesheetLogTable');
const FilterTransformer = require('./advanced-search/FiltersTransformer.react');
const AdvancedSearch = require('../containers/advanced-search/TimesheetLogAdvancedSearch');

const TimesheetLog = () => (
  <div className="wethod-section-body timesheet-changelog">
    <SectionHeader
      current_section="Timesheet Changelog"
      helper_url="/settings/index/#timesheet-log"
      big />
    <Table />
    <FormValidator>
      <FilterTransformer>
        <AdvancedSearch />
      </FilterTransformer>
    </FormValidator>
  </div>
);

module.exports = TimesheetLog;
