const $ = require('jquery');
const _ = require('underscore');
const TimesheetSettings = require('@models/TimesheetSettings');
const JobOrderTemplateModel = require('@models/JobOrderTemplate');
const OrderPlanSettings = require('@models/OrderPlanSettings');
const JobTitleModel = require('@models/JobTitle');
const FiscalYearModel = require('@models/FiscalYear');
const WorkHourCapacityModel = require('@models/WorkHourCapacity');
const EmployeeTagsModel = require('@models/EmployeeTags');
const LocationModel = require('@models/Location');
const CompanyHolidayModel = require('@models/CompanyHoliday');
const WinLossEmailAddress = require('@models/WinLossEmailAddress');
const FullTimeEquivalent = require('@models/FullTimeEquivalent');
const constants = require('./constants');

const showModal = (name, data) => ({
  type: constants.SHOW_MODAL,
  name,
  data,
});

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});
module.exports.closeModal = closeModal;

/*
 Timesheet settings
 */

const setTimesheetStrategyRequest = (strategy) => ({
  type: constants.SET_TIMESHEET_STRATEGY_REQUEST,
  strategy,
});

const setTimesheetStrategySuccess = () => ({
  type: constants.SET_TIMESHEET_STRATEGY_SUCCESS,
});

module.exports.setTimesheetStrategy = (strategy) => (dispatch) => {
  dispatch(setTimesheetStrategyRequest(strategy));

  $.when(TimesheetSettings.setStrategy(strategy)).done(() => {
    dispatch(setTimesheetStrategySuccess());
  });
};

const setTimesheetFrequencyRequest = (frequency) => ({
  type: constants.SET_TIMESHEET_FREQUENCY_REQUEST,
  frequency,
});

const setTimesheetFrequencySuccess = () => ({
  type: constants.SET_TIMESHEET_FREQUENCY_SUCCESS,
});

module.exports.setTimesheetFrequency = (frequency) => (dispatch) => {
  dispatch(setTimesheetFrequencyRequest(frequency));

  $.when(TimesheetSettings.setFrequency(frequency)).done(() => {
    dispatch(setTimesheetFrequencySuccess());
  });
};

const getTimesheetTimeLimitRequest = () => ({
  type: constants.GET_TIMESHEET_TIME_LIMIT_REQUEST,
});

const getTimesheetTimeLimitSuccess = (data) => ({
  type: constants.GET_TIMESHEET_TIME_LIMIT_SUCCESS,
  data,
});

module.exports.getTimesheetTimeLimit = () => (dispatch) => {
  dispatch(getTimesheetTimeLimitRequest());

  $.when(TimesheetSettings.getTimeLimit()).done((data) => {
    dispatch(getTimesheetTimeLimitSuccess(data.timeLimit));
  });
};

const setTimesheetTimeLimitRequest = () => ({
  type: constants.SET_TIMESHEET_TIME_LIMIT_REQUEST,
});

const setTimesheetTimeLimitSuccess = (data) => ({
  type: constants.SET_TIMESHEET_TIME_LIMIT_SUCCESS,
  data,
});

module.exports.setTimesheetTimeLimit = (enabled) => (dispatch) => {
  dispatch(setTimesheetTimeLimitRequest());

  $.when(TimesheetSettings.setTimeLimit(enabled)).done((data) => {
    dispatch(setTimesheetTimeLimitSuccess(data.timeLimit));
  });
};

const updateTimesheetTimeLimitRequest = () => ({
  type: constants.UPDATE_TIMESHEET_TIME_LIMIT_REQUEST,
});

const updateTimesheetTimeLimitSuccess = (data) => ({
  type: constants.UPDATE_TIMESHEET_TIME_LIMIT_SUCCESS,
  data,
});

module.exports.updateTimesheetTimeLimit = (buffer) => (dispatch) => {
  dispatch(updateTimesheetTimeLimitRequest());

  $.when(TimesheetSettings.updateTimeLimit(buffer)).done((data) => {
    dispatch(updateTimesheetTimeLimitSuccess(data.timeLimit));
  });
};

const setTimesheetDailyModeRequest = () => ({
  type: constants.SET_TIMESHEET_DAILY_MODE_REQUEST,
});

const setTimesheetDailyModeSuccess = (data) => ({
  type: constants.SET_TIMESHEET_DAILY_MODE_SUCCESS,
  data,
});

module.exports.setTimesheetDailyMode = (dailyMode) => (dispatch) => {
  dispatch(setTimesheetDailyModeRequest());

  $.when(TimesheetSettings.setDailyModeToggle(dailyMode)).done((data) => {
    dispatch(setTimesheetDailyModeSuccess(data.daily_mode));
  });
};

/*
 Job order templates settings
 */

module.exports.showDeleteJobOrderTemplateConfirmModal = (data) => (dispatch) => {
  dispatch(showModal('delete-job-order-template-confirmation', data));
};

module.exports.showUpdateFteConfirmModal = (data) => (dispatch) => {
  dispatch(showModal('fte-change-confirmation', data));
};

const showErrorModal = (data) => (dispatch) => {
  dispatch(showModal('error', data));
};

const getTemplatesRequest = () => ({
  type: constants.GET_TEMPLATES_REQUEST,
});

const getTemplatesSuccess = (templates) => ({
  type: constants.GET_TEMPLATES_SUCCESS,
  templates,
});

const getTemplates = () => (dispatch) => {
  dispatch(getTemplatesRequest());

  $.when(JobOrderTemplateModel.getAll()).done((response) => {
    dispatch(getTemplatesSuccess(response));
  }).fail(() => { // No templates found, we show an empty list
    dispatch(getTemplatesSuccess([]));
  });
};

const enableTemplatesRequest = () => ({
  type: constants.ENABLE_TEMPLATES_REQUEST,
});

const enableTemplatesSuccess = (enabled) => ({
  type: constants.ENABLE_TEMPLATES_SUCCESS,
  enabled,
});

module.exports.enableTemplates = (enabled) => (dispatch) => {
  dispatch(enableTemplatesRequest());

  $.when(JobOrderTemplateModel.setEnabled(enabled)).done((response) => {
    dispatch(enableTemplatesSuccess(response.enabled));
    if (response.enabled) {
      dispatch(getTemplates()); // Get the list of available templates after enabling the functionality
    }
  });
};

const deleteTemplateRequest = (id) => ({
  type: constants.DELETE_TEMPLATE_REQUEST,
  id,
});

const deleteTemplateSuccess = (id) => ({
  type: constants.DELETE_TEMPLATE_SUCCESS,
  id,
});

const deleteTemplateFailure = (id) => ({
  type: constants.DELETE_TEMPLATE_FAILURE,
  id,
});

module.exports.deleteTemplate = (id) => (dispatch) => {
  dispatch(deleteTemplateRequest(id));

  $.when(JobOrderTemplateModel.delete(id)).done(() => {
    dispatch(deleteTemplateSuccess(id));
    dispatch(closeModal());
  }).fail((error) => {
    dispatch(deleteTemplateFailure(id));
    dispatch(showErrorModal(error));
  });
};

module.exports.addNewJobOrderTemplate = () => ({
  type: constants.ADD_NEW_TEMPLATE,
});

module.exports.deleteNewJobOrderTemplate = () => ({
  type: constants.DELETE_NEW_TEMPLATE,
});

const saveTemplateRequest = (id) => ({
  type: constants.SAVE_TEMPLATE_REQUEST,
  id,
});

const saveTemplateSuccess = (id, template) => ({
  type: constants.SAVE_TEMPLATE_SUCCESS,
  id,
  template,
});

const saveTemplateFailure = (id) => ({
  type: constants.SAVE_TEMPLATE_FAILURE,
  id,
});

const createJobOrderTemplate = (template) => (dispatch) => {
  dispatch(saveTemplateRequest(template.id));

  const formattedTemplate = {
    ...template,
    id: undefined,
  };

  $.when(JobOrderTemplateModel.create(formattedTemplate)).done((response) => {
    dispatch(saveTemplateSuccess(template.id, response));
  }).fail((error) => {
    dispatch(saveTemplateFailure(template.id));
    dispatch(showErrorModal(error));
  });
};

const updateJobOrderTemplate = (template) => (dispatch) => {
  dispatch(saveTemplateRequest(template.id));

  $.when(JobOrderTemplateModel.update(template.id, template)).done((response) => {
    dispatch(saveTemplateSuccess(template.id, response));
  }).fail((error) => {
    dispatch(saveTemplateFailure(template.id));
    dispatch(showErrorModal(error));
  });
};

module.exports.saveJobOrderTemplate = (template) => (dispatch) => {
  const isNew = !template.id || template.id === 'new-template';

  if (isNew) {
    dispatch(createJobOrderTemplate(template));
  } else {
    dispatch(updateJobOrderTemplate(template));
  }
};

/*
 Order plan settings
 */
const getOrderPlanPreferenceRequest = () => ({
  type: constants.GET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST,
});

const getOrderPlanPreferenceSuccess = (preference) => ({
  type: constants.GET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST,
  preference,
});

module.exports.getOrderPlanPreference = () => (dispatch) => {
  dispatch(getOrderPlanPreferenceRequest());

  $.when(OrderPlanSettings.getPreference()).done((response) => {
    dispatch(getOrderPlanPreferenceSuccess(response));
  }).fail(() => { // No preference found, we show default value
    dispatch(getOrderPlanPreferenceSuccess('period_of_supply'));
  });
};

const setOrderPlanPreferenceRequest = (preference) => ({
  type: constants.SET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST,
  preference,
});

const setOrderPlanPreferenceSuccess = (preference) => ({
  type: constants.SET_ORDER_PLAN_VIEW_PREFERENCE_SUCCESS,
  preference,
});

module.exports.setOrderPlanPreference = (preference) => (dispatch) => {
  dispatch(setOrderPlanPreferenceRequest(preference));
  $.when(OrderPlanSettings.setPreference(preference)).done((response) => {
    dispatch(setOrderPlanPreferenceSuccess(response));
  }).fail(() => { // No preference found, we show default value
    dispatch(setOrderPlanPreferenceSuccess('period_of_supply'));
  });
};

/*
 Job title settings
 */

module.exports.addLevel = (level) => ({
  type: constants.ADD_LEVEL,
  level,
});
module.exports.deleteLevel = (level) => ({
  type: constants.DELETE_LEVEL,
  level,
});
module.exports.updateLevel = (level) => ({
  type: constants.UPDATE_LEVEL,
  level,
});
module.exports.addNewJobTitle = () => ({
  type: constants.ADD_NEW_JOB_TITLE,
});
module.exports.deleteNewJobTitle = () => ({
  type: constants.DELETE_NEW_JOB_TITLE,
});
const saveJobTitleRequest = (id) => ({
  type: constants.SAVE_JOB_TITLE_REQUEST,
  id,
});
const saveJobTitleSuccess = (id, jobTitle) => ({
  type: constants.SAVE_JOB_TITLE_SUCCESS,
  id,
  jobTitle,
});
const saveJobTitleFailure = (id) => ({
  type: constants.SAVE_JOB_TITLE_FAILURE,
  id,
});
module.exports.createJobTitle = (jobTitle) => (dispatch) => {
  dispatch(saveJobTitleRequest(jobTitle.id));

  $.when(JobTitleModel.create(jobTitle.name, jobTitle.level, jobTitle.archived))
    .done((response) => {
      dispatch(saveJobTitleSuccess(jobTitle.id, response));
    }).fail((error) => {
      dispatch(saveJobTitleFailure(jobTitle.id));
      dispatch(showErrorModal(error));
    });
};
module.exports.updateJobTitle = (id, jobTitle) => (dispatch) => {
  dispatch(saveJobTitleRequest(id));

  $.when(JobTitleModel.update(id, jobTitle)).done((response) => {
    dispatch(saveJobTitleSuccess(id, response));
  }).fail((error) => {
    dispatch(saveJobTitleFailure(id));
    dispatch(showErrorModal(error));
  });
};
const getJobTitlesRequest = (filter) => ({
  type: constants.GET_JOB_TITLES_REQUEST,
  filter,
});
const getJobTitlesSuccess = (jobTitles) => ({
  type: constants.GET_JOB_TITLES_SUCCESS,
  jobTitles,
});
module.exports.getJobTitles = (archivedFilter) => (dispatch) => {
  dispatch(getJobTitlesRequest(archivedFilter));

  const filters = { archived: archivedFilter };

  $.when(JobTitleModel.list(filters)).done((response) => {
    dispatch(getJobTitlesSuccess(response));
  });
};

/*
 Fiscal year settings
 */
const getFiscalYearRequest = () => ({
  type: constants.GET_FISCAL_YEAR_REQUEST,
});
const getFiscalYearSuccess = (fiscalYear) => ({
  type: constants.GET_FISCAL_YEAR_SUCCESS,
  fiscalYear,
});
module.exports.getFiscalYear = () => (dispatch) => {
  dispatch(getFiscalYearRequest());

  $.when(FiscalYearModel.get()).done((response) => {
    dispatch(getFiscalYearSuccess(response));
  }).fail(() => { // No preference found, we show default value
    dispatch(getFiscalYearSuccess({ start: 1 }));
  });
};
const setFiscalYearRequest = (fiscalYear) => ({
  type: constants.SET_FISCAL_YEAR_REQUEST,
  fiscalYear,
});
const setFiscalYearSuccess = (fiscalYear) => ({
  type: constants.SET_FISCAL_YEAR_SUCCESS,
  fiscalYear,
});
const setFiscalYearState = (fiscalYear) => ({
  type: constants.SET_FISCAL_YEAR_STATE,
  fiscalYear,
});
const setPreviousFiscalYear = (previousFiscalYear) => ({
  type: constants.SET_PREVIOUS_FISCAL_YEAR_STATE,
  previousFiscalYear,
});
module.exports.setFiscalYear = (fiscalYear) => (dispatch) => {
  dispatch(setFiscalYearRequest(fiscalYear));
  $.when(FiscalYearModel.set(fiscalYear)).done((response) => {
    dispatch(setFiscalYearSuccess(response));
    dispatch(closeModal());
  }).fail(() => { // No preference found, we show default value
    dispatch(setFiscalYearState(fiscalYear));
  });
};
module.exports.setFiscalYearState = (fiscalYear) => (dispatch) => {
  dispatch(setFiscalYearState(fiscalYear));
};
// eslint-disable-next-line max-len
module.exports.showFiscalYearChangeConfirmModal = (previousFiscalYear, newFiscalYear) => (dispatch) => {
  dispatch(setPreviousFiscalYear(previousFiscalYear));
  dispatch(setFiscalYearState(newFiscalYear));
  dispatch(showModal('fiscal-year-change-confirmation', newFiscalYear));
};

/*
 Work hour capacity settings
 */

const saveWorkHourCapacityRequest = (id) => ({
  type: constants.SAVE_WORK_HOUR_CAPACITY_REQUEST,
  id,
});
const saveWorkHourCapacitySuccess = (id, workHourCapacity) => ({
  type: constants.SAVE_WORK_HOUR_CAPACITY_SUCCESS,
  id,
  workHourCapacity,
});
const saveWorkHourCapacityFailure = (id) => ({
  type: constants.SAVE_WORK_HOUR_CAPACITY_FAILURE,
  id,
});

const deleteWorkHourCapacityRequest = (id) => ({
  type: constants.DELETE_WORK_HOUR_CAPACITY_REQUEST,
  id,
});
const deleteWorkHourCapacitySuccess = (id) => ({
  type: constants.DELETE_WORK_HOUR_CAPACITY_SUCCESS,
  id,
});
const deleteWorkHourCapacityFailure = (id) => ({
  type: constants.DELETE_WORK_HOUR_CAPACITY_FAILURE,
  id,
});
module.exports.deleteWorkHourCapacityFailure = deleteWorkHourCapacityFailure;
const getWorkHourCapacitiesRequest = () => ({
  type: constants.GET_WORK_HOUR_CAPACITIES_REQUEST,
});
const getWorkHourCapacitiesSuccess = (workHourCapacities) => ({
  type: constants.GET_WORK_HOUR_CAPACITIES_SUCCESS,
  workHourCapacities,
});

module.exports.addNewWorkHourCapacity = () => ({
  type: constants.ADD_NEW_WORK_HOUR_CAPACITY,
});
module.exports.deleteNewWorkHourCapacity = () => ({
  type: constants.DELETE_NEW_WORK_HOUR_CAPACITY,
});
const setWorkHourCapacityEmployees = (workHourCapacityEmployees) => ({
  type: constants.SET_WORK_HOUR_CAPACITY_EMPLOYEES,
  workHourCapacityEmployees,
});
module.exports.setWorkHourCapacityEmployees = setWorkHourCapacityEmployees;
module.exports.deleteWorkHourCapacity = (id) => (dispatch) => {
  dispatch(deleteWorkHourCapacityRequest(id));

  $.when(WorkHourCapacityModel.delete(id)).done((response) => {
    dispatch(deleteWorkHourCapacitySuccess(id, response));
    dispatch(closeModal());
    dispatch(setWorkHourCapacityEmployees([]));
  }).fail((error) => {
    dispatch(deleteWorkHourCapacityFailure(id));
    dispatch(showErrorModal(error));
  });
};
module.exports.showDeleteModalWorkHourCapacity = (workHourCapacity) => (dispatch) => {
  dispatch(showModal('delete-work-hour-capacity-confirmation', workHourCapacity));
};

module.exports.getWorkHourCapacityEmployees = (id) => (dispatch) => {
  $.when(WorkHourCapacityModel.get(id)).done((response) => {
    dispatch(setWorkHourCapacityEmployees(response.work_hour_capacity_employees));
  }).fail((error) => {
    dispatch(deleteWorkHourCapacityFailure(id));
    dispatch(closeModal());
    dispatch(showErrorModal(error));
  });
};
module.exports.createWorkHourCapacity = (workHourCapacity) => (dispatch) => {
  dispatch(saveWorkHourCapacityRequest(workHourCapacity.id));

  $.when(WorkHourCapacityModel.create(workHourCapacity))
    .done((response) => {
      dispatch(saveWorkHourCapacitySuccess(workHourCapacity.id, response));
    }).fail((error) => {
      dispatch(saveWorkHourCapacityFailure(workHourCapacity.id));
      dispatch(showErrorModal(error));
    });
};
module.exports.updateWorkHourCapacity = (id, workHourCapacity) => (dispatch) => {
  dispatch(saveWorkHourCapacityRequest(id));

  $.when(WorkHourCapacityModel.update(id, workHourCapacity)).done((response) => {
    dispatch(saveWorkHourCapacitySuccess(id, response));
  }).fail((error) => {
    dispatch(saveWorkHourCapacityFailure(id));
    dispatch(showErrorModal(error));
  });
};
module.exports.getWorkHourCapacities = () => (dispatch) => {
  dispatch(getWorkHourCapacitiesRequest());

  $.when(WorkHourCapacityModel.list()).done((response) => {
    dispatch(getWorkHourCapacitiesSuccess(response));
  });
};

/*
 Employee tags settings
 */
const getEmployeeTagsRequest = () => ({
  type: constants.GET_EMPLOYEE_TAGS_REQUEST,
});

const getEmployeeTagsSuccess = () => ({
  type: constants.GET_EMPLOYEE_TAGS_SUCCESS,
});

const setEmployeeTagUpdateSuccess = (tag) => ({
  type: constants.SET_EMPLOYEE_TAGS_UPDATE_SUCCESS,
  tag,
});

const setEmployeeTagCategorySaving = (category, saving) => ({
  type: constants.SET_EMPLOYEE_TAG_CATEGORY_SAVING,
  category,
  saving,
});
module.exports.setEmployeeTagCategorySaving = setEmployeeTagCategorySaving;

const setEmployeeTagItemSaving = (tag, saving) => ({
  type: constants.SET_EMPLOYEE_TAGS_ITEM_SAVING,
  tag,
  saving,
});

const setEmployeeTagsDelete = () => ({
  type: constants.SET_EMPLOYEE_TAGS_DELETE,
});

const setEmployeeTagsDeleteSuccess = (id) => ({
  type: constants.SET_EMPLOYEE_TAGS_DELETE_SUCCESS,
  id,
});

const setEmployeeTagsSuccess = (employeeTags) => ({
  type: constants.SET_EMPLOYEE_TAGS_SUCCESS,
  employeeTags,
});

const setNewEmployeeTags = (employeeTags) => ({
  type: constants.SET_NEW_EMPLOYEE_TAGS,
  employeeTags,
});

const setEmployeeTagsState = (employeeTagsCategory) => ({
  type: constants.SET_EMPLOYEE_TAGS_STATE,
  employeeTagsCategory,
});

const setEmployeeTagNewSuccess = (employeeTag) => ({
  type: constants.SET_EMPLOYEE_TAG_NEW_SUCCESS,
  employeeTag,
});

module.exports.addNewCategory = () => ({
  type: constants.ADD_NEW_CATEGORY,
});

module.exports.deleteCategory = (id) => ({
  type: constants.DELETE_CATEGORY,
  id,
});

const setTagDeleteSuccess = (id) => ({
  type: constants.DELETE_TAG,
  id,
});

module.exports.createEmployeeTag = (tag) => (dispatch) => {
  $.when(EmployeeTagsModel.createItem({
    id_category: tag.id_category,
    name: '',
  })).done((response) => {
    dispatch(setEmployeeTagNewSuccess(response));
  });
};

const setEmployeeTagsItems = (categories, items) => ({
  type: constants.SET_EMPLOYEE_TAGS_ITEMS,
  categories,
  items,
});

module.exports.deleteCategoryRequest = (id) => (dispatch) => {
  dispatch(setEmployeeTagsDelete());
  // eslint-disable-next-line max-len
  $.when(EmployeeTagsModel.delete(id)).done(() => {
    dispatch(setEmployeeTagsDeleteSuccess(id));
  }).fail(() => { // No preference found, we show default value
  });
};

module.exports.createEmployeeTagsCategory = (employeeTagsCategory) => (dispatch) => {
  $.when(EmployeeTagsModel.create(employeeTagsCategory)).done((response) => {
    dispatch(setNewEmployeeTags(response));
  }).fail(() => { // No preference found, we show default value
    dispatch(setEmployeeTagsState(employeeTagsCategory));
  });
};

module.exports.saveEmployeeTagCategory = (employeeTagsCategory) => (dispatch) => {
  dispatch(setEmployeeTagCategorySaving(employeeTagsCategory, true));
  // eslint-disable-next-line max-len
  $.when(EmployeeTagsModel.set(employeeTagsCategory.id, employeeTagsCategory)).done((response) => {
    dispatch(setEmployeeTagsSuccess(response));
    dispatch(setEmployeeTagCategorySaving(employeeTagsCategory, false));
  }).fail(() => { // No preference found, we show default value
    dispatch(setEmployeeTagsState(employeeTagsCategory));
  });
};

const getEmployeeTagsItems = (categories) => (dispatch) => {
  $.when(EmployeeTagsModel.getItems()).done((response) => {
    dispatch(setEmployeeTagsItems(categories, response));
    dispatch(getEmployeeTagsSuccess());
  }).fail(() => { // No preference found, we show default value
    // TODO show error?
    dispatch(setEmployeeTagsItems(categories, []));
    dispatch(getEmployeeTagsSuccess());
  });
};

module.exports.getEmployeeTags = () => (dispatch) => {
  dispatch(getEmployeeTagsRequest());
  $.when(EmployeeTagsModel.get()).done((response) => {
    dispatch(getEmployeeTagsItems(response));
  }).fail(() => { // No preference found, we show default value
    // TODO show error?
    dispatch(getEmployeeTagsSuccess());
  });
};

module.exports.deleteEmployeeTagItem = (id) => (dispatch) => {
  dispatch(setEmployeeTagsDelete());
  $.when(EmployeeTagsModel.deleteItem(id)).done(() => {
    dispatch(setTagDeleteSuccess(id));
    dispatch(setEmployeeTagsDeleteSuccess());
  }).fail(() => { // No preference found, we show default value
    // TODO show error?
  });
};

module.exports.saveEmployeeTagItem = (tag) => (dispatch) => {
  dispatch(setEmployeeTagItemSaving(tag, true));
  $.when(EmployeeTagsModel.updateTag(tag)).done((result) => {
    dispatch(setEmployeeTagUpdateSuccess(result));
  }).fail(() => { // No preference found, we show default value
    // TODO show error?
  });
};

/*
 Company holiday settings
 */

const saveCompanyHolidayRequest = (id) => ({
  type: constants.SAVE_COMPANY_HOLIDAY_REQUEST,
  id,
});
const saveCompanyHolidaySuccess = (id, companyHoliday) => ({
  type: constants.SAVE_COMPANY_HOLIDAY_SUCCESS,
  id,
  companyHoliday,
});
const saveCompanyHolidayFailure = (id) => ({
  type: constants.SAVE_COMPANY_HOLIDAY_FAILURE,
  id,
});

const deleteCompanyHolidayRequest = (id) => ({
  type: constants.DELETE_COMPANY_HOLIDAY_REQUEST,
  id,
});
const deleteCompanyHolidaySuccess = (id) => ({
  type: constants.DELETE_COMPANY_HOLIDAY_SUCCESS,
  id,
});
const deleteCompanyHolidayFailure = (id) => ({
  type: constants.DELETE_COMPANY_HOLIDAY_FAILURE,
  id,
});
module.exports.deleteCompanyHolidayFailure = deleteCompanyHolidayFailure;
const getCompanyHolidaysRequest = () => ({
  type: constants.GET_COMPANY_HOLIDAYS_REQUEST,
});
const getCompanyHolidaysSuccess = (companyHolidays) => ({
  type: constants.GET_COMPANY_HOLIDAYS_SUCCESS,
  companyHolidays,
});

module.exports.addNewCompanyHoliday = () => ({
  type: constants.ADD_NEW_COMPANY_HOLIDAY,
});
module.exports.deleteNewCompanyHoliday = () => ({
  type: constants.DELETE_NEW_COMPANY_HOLIDAY,
});
module.exports.deleteCompanyHoliday = (id) => (dispatch) => {
  dispatch(deleteCompanyHolidayRequest(id));

  $.when(CompanyHolidayModel.delete(id)).done((response) => {
    dispatch(deleteCompanyHolidaySuccess(id, response));
    dispatch(closeModal());
  }).fail((error) => {
    dispatch(deleteCompanyHolidayFailure(id));
    dispatch(closeModal());
    dispatch(showErrorModal(error));
  });
};
module.exports.showDeleteModalCompanyHoliday = (id) => (dispatch) => {
  dispatch(showModal(
    'delete-company-holiday-confirmation',
    id,
  ));
};
module.exports.createCompanyHoliday = (companyHoliday) => (dispatch) => {
  dispatch(saveCompanyHolidayRequest(companyHoliday.id));

  $.when(CompanyHolidayModel.create(companyHoliday))
    .done((response) => {
      dispatch(saveCompanyHolidaySuccess(companyHoliday.id, response));
    }).fail((error) => {
      dispatch(saveCompanyHolidayFailure(companyHoliday.id));
      dispatch(showErrorModal(error));
    });
};
module.exports.updateCompanyHoliday = (id, companyHoliday) => (dispatch) => {
  dispatch(saveCompanyHolidayRequest(id));

  $.when(CompanyHolidayModel.update(id, companyHoliday)).done((response) => {
    dispatch(saveCompanyHolidaySuccess(id, response));
  }).fail((error) => {
    dispatch(saveCompanyHolidayFailure(id));
    dispatch(showErrorModal(error));
  });
};
const getCompanyHolidays = () => (dispatch) => {
  dispatch(getCompanyHolidaysRequest());

  $.when(CompanyHolidayModel.list()).done((response) => {
    dispatch(getCompanyHolidaysSuccess(response));
  });
};
module.exports.getCompanyHolidays = getCompanyHolidays;

/*
 Location settings
 */

module.exports.addNewLocation = () => ({
  type: constants.ADD_NEW_LOCATION,
});

module.exports.deleteNewLocation = () => ({
  type: constants.DELETE_NEW_LOCATION,
});

const saveLocationRequest = (id) => ({
  type: constants.SAVE_LOCATION_REQUEST,
  id,
});

const saveLocationSuccess = (id, location) => ({
  type: constants.SAVE_LOCATION_SUCCESS,
  id,
  location,
});

const saveLocationFailure = (id) => ({
  type: constants.SAVE_LOCATION_FAILURE,
  id,
});

module.exports.createLocation = (location) => (dispatch) => {
  dispatch(saveLocationRequest(location.id));
  const locationBody = _.omit(location, 'id');
  $.when(LocationModel.create(locationBody))
    .done((response) => {
      dispatch(saveLocationSuccess(location.id, response));
      dispatch(getCompanyHolidays());
    }).fail((error) => {
      dispatch(saveLocationFailure(location.id));
      dispatch(showErrorModal(error));
    });
};

module.exports.updateLocation = (id, location) => (dispatch) => {
  dispatch(saveLocationRequest(id));
  const locationBody = _.omit(location, 'id');
  $.when(LocationModel.update(id, locationBody)).done((response) => {
    dispatch(saveLocationSuccess(id, response));
    dispatch(getCompanyHolidays());
  }).fail((error) => {
    dispatch(saveLocationFailure(id));
    dispatch(showErrorModal(error));
  });
};

const deleteLocationRequest = (id) => ({
  type: constants.DELETE_LOCATION_REQUEST,
  id,
});

const deleteLocationSuccess = (id) => ({
  type: constants.DELETE_LOCATION_SUCCESS,
  id,
});

const deleteLocationFailure = (id) => ({
  type: constants.DELETE_LOCATION_FAILURE,
  id,
});

module.exports.showDeleteLocationConfirmModal = (data) => (dispatch) => {
  dispatch(showModal('delete-locations-confirmation', data));
};

module.exports.deleteLocation = (id) => (dispatch) => {
  dispatch(deleteLocationRequest(id));
  $.when(LocationModel.delete(id)).done(() => {
    dispatch(deleteLocationSuccess(id));
    dispatch(closeModal());
    dispatch(getCompanyHolidays());
  }).fail((error) => {
    dispatch(deleteLocationFailure(id));
    dispatch(showErrorModal(error));
  });
};

const getLocationRequest = () => ({
  type: constants.GET_LOCATION_REQUEST,
});

const getLocationSuccess = (location) => ({
  type: constants.GET_LOCATION_SUCCESS,
  location,
});

module.exports.getLocation = () => (dispatch) => {
  dispatch(getLocationRequest());

  $.when(LocationModel.get()).done((response) => {
    dispatch(getLocationSuccess(response));
  });
};

const updateWinEmailAddressRequest = () => ({
  type: constants.SAVE_WIN_EMAIL_ADDRESS_REQUEST,
});

const updateWinEmailAddressSuccess = (response) => ({
  type: constants.SAVE_WIN_EMAIL_ADDRESS_SUCCESS,
  response,
});

const updateWinEmailAddressFailure = () => ({
  type: constants.SAVE_WIN_EMAIL_ADDRESS_FAILURE,
});

module.exports.updateWinEmailAddress = (value) => (dispatch) => {
  dispatch(updateWinEmailAddressRequest());
  $.when(WinLossEmailAddress.updateWinEmail(value)).done((response) => {
    dispatch(updateWinEmailAddressSuccess(response));
  }).fail((error) => {
    dispatch(updateWinEmailAddressFailure());
    dispatch(showErrorModal(error));
  });
};

const updateLossEmailAddressRequest = () => ({
  type: constants.SAVE_LOSS_EMAIL_ADDRESS_REQUEST,
});

const updateLossEmailAddressSuccess = (response) => ({
  type: constants.SAVE_LOSS_EMAIL_ADDRESS_SUCCESS,
  response,
});

const updateLossEmailAddressFailure = () => ({
  type: constants.SAVE_LOSS_EMAIL_ADDRESS_FAILURE,
});

module.exports.updateLossEmailAddress = (value) => (dispatch) => {
  dispatch(updateLossEmailAddressRequest());
  $.when(WinLossEmailAddress.updateLossEmail(value)).done((response) => {
    dispatch(updateLossEmailAddressSuccess(response));
  }).fail((error) => {
    dispatch(updateLossEmailAddressFailure());
    dispatch(showErrorModal(error));
  });
};

const updateDefaultFullTimeEquivalentRequest = () => ({
  type: constants.SAVE_DEFAULT_FTE_REQUEST,
});

const updateDefaultFullTimeEquivalentSuccess = (response) => ({
  type: constants.SAVE_DEFAULT_FTE_SUCCESS,
  response,
});

module.exports.updateDefaultFullTimeEquivalent = (value) => (dispatch) => {
  dispatch(updateDefaultFullTimeEquivalentRequest());
  FullTimeEquivalent.updateDefault(value).done((response) => {
    dispatch(updateDefaultFullTimeEquivalentSuccess(response));
    dispatch(closeModal());
  });
};
