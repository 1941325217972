const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const ExpenseModel = require('../../../../../../models/Expense');

module.exports = class OwnerSelect extends React.Component {
  static getFullName(person) {
    return `${_.unescape(person.name)} ${_.unescape(person.surname)}`;
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    ExpenseModel.getOwnerAutocomplete(keyword)
      .done((collection) => this.setState({
        availableItems: collection,
        isLoading: false,
      }));
  }

  onChange(e, item) {
    let selectedValue = this.state.availableItems.filter((person) => person.id === item.value);

    selectedValue = selectedValue && selectedValue.length > 0 ? selectedValue[0] : null;

    this.props.onChange(e, selectedValue);
  }

  getOptions() {
    return this.state.availableItems.map((person) => (
      <SelectItem key={person.id}
        value={person.id}>
        {OwnerSelect.getFullName(person)}
      </SelectItem>
    ));
  }

  getValue() {
    if (!this.props.value) {
      return null;
    }
    return this.props.value;
  }

  render() {
    return (
      <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
        <Autocomplete readOnly={this.props.readOnly}
          label={this.props.label}
          onChange={this.onChange.bind(this)}
          value={this.getValue()}
          getInputValue={OwnerSelect.getFullName}
          name={this.props.name}
          id={this.props.id}
          onFilter={this.onFilter.bind(this)}
          errorText={this.props.errorText}
          loading={this.state.isLoading}>
          {this.getOptions()}
        </Autocomplete>
      </InputValidator>
    );
  }
};
