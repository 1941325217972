const React = require('react');
const Avatar = require('@common/AvatarBox/Avatar.react');
const { formatDate } = require('../../../../../../../services/FormatService');

module.exports = class SponsorItemPast extends React.Component {
  getTags() {
    const tagsLength = this.props.tags.length;
    return this.props.tags.map((tag, index) => {
      if (index < tagsLength - 1) {
        return (
          <span key={tag.id}>
            {tag.name}
            ,
            {' '}
          </span>
        );
      }
      return <span key={tag.id}>{tag.name}</span>;
    });
  }

  getName() {
    return `${this.props.name} ${this.props.surname}`;
  }

  render() {
    return (
      <a
        href={`#settings/profile/${this.props.sponsor}`}
        className="profile-sponsor__list-item profile-sponsor__list-item--link">
        <div className="profile-sponsor__list-item-pic">
          <div className="profile-sponsor__list-item-pic">
            <Avatar name={this.getName()} pic={this.props.pic} size="medium" />
          </div>
        </div>
        <div className="profile-sponsor__list-item-info">
          <div className="profile-sponsor__list-item-name">{this.getName()}</div>
          <div className="profile-sponsor__list-item-tags">{this.getTags()}</div>
          <div className="profile-sponsor__list-item-level">{this.props.level}</div>
        </div>
        <div className="profile-sponsor__list-item-period">
          From {formatDate(this.props.from_date)} to {formatDate(this.props.to_date)}
        </div>
      </a>
    );
  }
};
