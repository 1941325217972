const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const NumericField = require('@common/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const TextArea = require('@common/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const DateInput = require('@common/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');

const PlaceAutocomplete = require('@common/inputs/PlaceAutocomplete/PlaceAutocomplete.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');
const {
  isValueRequired,
  isRouteRequired,
  getActualReimbursement,
  getValueLabel,
  getReimbursementHelperText,
  isMileage,
} = require('../../services/ExpenseCategoryService');
const CategorySelect = require('../../containers/sidebar/CategorySelect');
const ProjectSelect = require('./ProjectSelect.react');
const CreationInfo = require('./CreationInfo.react');
const FileSection = require('../../containers/sidebar/FileSection');
const OwnerSelect = require('./OwnerSelect.react');

const ExpenseBody = ({
  readOnly,
  canEditOwner,
  item,
  creation,
  errors,
  updateErrors,
  onChange,
  metadataService,
}) => {
  function handleChange(e) {
    const {
      name,
      value,
    } = e.target;
    if (onChange) {
      onChange(name, value);
    }
  }

  function handleSelectChange(e, option) {
    const { name } = e.target;
    if (onChange) {
      onChange(name, option);
    }
  }

  const canEditFiles = !readOnly && !creation;

  const isOwnerEditable = !readOnly && canEditOwner;

  return (
    <div>
      <ShowIf condition={item.id != null && readOnly}>
        <CreationInfo owner={item.owner} creator={item.requested_by} date={item.created_on} />
      </ShowIf>
      <Row>
        <InputWrapper icon={<span className="wethod-icon wethod-icon-pm wethod-icon-pm--black" />}>
          <InputMetadataManager metadataService={metadataService} name="owner" canEdit={isOwnerEditable}>
            <OwnerSelect
              id="sidebar-owner"
              label="Owner"
              value={item.owner}
              onChange={handleSelectChange}
              errorText={errors.owner}
              updateErrors={updateErrors} />
          </InputMetadataManager>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper
          icon={<span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />}>
          <InputMetadataManager metadataService={metadataService} name="project" canEdit={!readOnly}>
            <ProjectSelect readOnly={readOnly}
              id="sidebar-project"
              label="Project name"
              value={item.project}
              onChange={handleSelectChange}
              errorText={errors.project}
              updateErrors={updateErrors} />
          </InputMetadataManager>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <InputMetadataManager metadataService={metadataService} name="category" canEdit={!readOnly}>
            <CategorySelect
              id="sidebar-category"
              label="Expense category"
              value={item.category}
              onChange={handleSelectChange}
              errorText={errors.category}
              updateErrors={updateErrors} />
          </InputMetadataManager>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper
          icon={<span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />}>
          <InputValidator updateErrors={updateErrors} constraints={['required']}>
            <InputMetadataManager metadataService={metadataService} name="date" canEdit={!readOnly}>
              <DateInput
                disableBeforeEqual={metadataService.getConstraintValue('date', 'greater_than')}
                id="sidebar-date"
                label="Date"
                value={item.date}
                onChange={handleChange}
                errorText={errors.date} />
            </InputMetadataManager>
          </InputValidator>
        </InputWrapper>
      </Row>
      <ShowIf condition={isRouteRequired(item.category)}>
        <Row>
          <InputWrapper
            icon={<span className="wethod-icon wethod-icon-location wethod-icon-location--black" />}>
            <InputValidator updateErrors={updateErrors} constraints={['required']}>
              <InputMetadataManager metadataService={metadataService} name="from" canEdit={!readOnly}>
                <PlaceAutocomplete
                  sectionName="expenses"
                  id="sidebar-from"
                  label="From"
                  value={item.from}
                  onChange={handleChange}
                  errorText={errors.from} />
              </InputMetadataManager>
            </InputValidator>
          </InputWrapper>
        </Row>
      </ShowIf>
      <ShowIf condition={isRouteRequired(item.category)}>
        <Row>
          <InputWrapper
            icon={<span className="wethod-icon wethod-icon-location wethod-icon-location--black" />}>
            <InputValidator updateErrors={updateErrors} constraints={['required']}>
              <InputMetadataManager canEdit={!readOnly}
                name="to"
                metadataService={metadataService}>
                <PlaceAutocomplete
                  sectionName="expenses"
                  id="sidebar-to"
                  label="To"
                  value={item.to}
                  onChange={handleChange}
                  errorText={errors.to} />
              </InputMetadataManager>

            </InputValidator>
          </InputWrapper>
        </Row>
      </ShowIf>
      <ShowIf condition={isRouteRequired(item.category) && !isMileage(item.category)}>
        <Row>
          <InputWrapper>
            <InputMetadataManager canEdit={!readOnly}
              name="distance"
              metadataService={metadataService}>
              <NumericField
                id="sidebar-distance"
                label="Total miles/km"
                value={item.distance}
                precision={1}
                onChange={handleChange} />
            </InputMetadataManager>

          </InputWrapper>
        </Row>
      </ShowIf>
      <ShowIf condition={isValueRequired(item.category)}>
        <Row>
          <InputWrapper>
            <InputValidator updateErrors={updateErrors} constraints={['required']}>
              <InputMetadataManager canEdit={!readOnly}
                name="value"
                metadataService={metadataService}>
                <NumericField
                  id="sidebar-value"
                  label={getValueLabel(item.category)}
                  value={item.value}
                  precision={2}
                  onChange={handleChange}
                  errorText={errors.value} />
              </InputMetadataManager>
            </InputValidator>
          </InputWrapper>
        </Row>
      </ShowIf>
      <Row>
        <InputWrapper>
          <NumericField readOnly
            name="reimbursement"
            id="sidebar-reimbursement"
            label="Reimbursement"
            helperText={getReimbursementHelperText(item.category)}
            value={getActualReimbursement(item.category, item.value)}
            precision={2} />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper icon={(
          <span
            className="wethod-icon wethod-icon-description wethod-icon-description--black" />
)}>
          <InputMetadataManager canEdit={!readOnly}
            name="notes"
            metadataService={metadataService}>
            <TextArea
              id="sidebar-notes"
              label="Notes"
              value={item.notes}
              rows={3}
              onChange={handleChange}
              errorText={errors.notes} />
          </InputMetadataManager>
        </InputWrapper>
      </Row>
      <FileSection item={item} canDownload canDelete={canEditFiles} canUpload={canEditFiles} />
    </div>
  );
};

module.exports = ExpenseBody;
