const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Filter = require('../containers/TimeFilter');
const Table = require('../containers/ChargeabilityTable');

module.exports = class Chargeability extends React.Component {
  componentDidMount() {
    this.props.getChargeability(this.props.timeFilter);
  }

  render() {
    return (
      <div className="wethod-section-body chargeability">
        <div className="wethod-section-actions">
          <SectionHeader current_section="Chargeability"
            preview_anchor_id="chargeability"
            helper_url="reports/index/#chargeability"
            big />
          <Filter />
        </div>
        <Table />
      </div>
    );
  }
};
