const React = require('react');
const { CSSTransition } = require('react-transition-group');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const PricelistSidebar = require('../../containers/sidebar/PricelistSidebar');
const SaveManager = require('../../containers/sidebar/SaveManager');

const Sidebar = ({ showSidebar }) => (
  <CSSTransition in={showSidebar}
    classNames="wethod-sidebar--transition"
    timeout={400}
    mountOnEnter
    unmountOnExit>
    <FormValidator>
      <SaveManager>
        <PricelistSidebar />
      </SaveManager>
    </FormValidator>
  </CSSTransition>
);

module.exports = Sidebar;
