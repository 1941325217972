/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Mobile = require('@common/media-queries/Mobile.react');
const Section = require('../../../../common/components/Section.react');
const Title = require('../../../containers/sections/Production/Title');
const StartedProjects = require('../../../containers/sections/Production/StartedProjects');
const ProfitableProjects = require('../../../containers/sections/Production/ProfitableProjects');
const ClosedProjects = require('../../../containers/sections/Production/ClosedProjects');
const ProductionAgainstLastYear = require('../../../containers/sections/Production/ProductionAgainstLastYear');
const ProductionAgainstTarget = require('../../../containers/sections/Production/ProductionAgainstTarget');
const WeeklyProductionChart = require('./WeeklyProductionChart.react');

module.exports = class Production extends React.Component {
  render() {
    return (
      <Section left={(
        <WeeklyProductionChart
          last_year={Math.round(this.props.prev_year_prod / 1000)}
          three_weeks_ago={Math.round(this.props.two_weeks_ago_production / 1000)}
          two_weeks_ago={Math.round(this.props.prev_week_production / 1000)}
          one_week_ago={Math.round(this.props.week_production / 1000)}
          target={Math.round(this.props.weekly_target / 1000)}
          dynamic_target={Math.round(this.props.weekly_dynamic_target / 1000)} />
)}>
        <Title />
        <ProductionAgainstTarget />
        <ProductionAgainstLastYear />
        <ProfitableProjects />
        <StartedProjects />
        <ClosedProjects />
        <Mobile>
          <WeeklyProductionChart
            last_year={Math.round(this.props.prev_year_prod / 1000)}
            three_weeks_ago={Math.round(this.props.two_weeks_ago_production / 1000)}
            two_weeks_ago={Math.round(this.props.prev_week_production / 1000)}
            one_week_ago={Math.round(this.props.week_production / 1000)}
            target={Math.round(this.props.weekly_target / 1000)}
            dynamic_target={Math.round(this.props.weekly_dynamic_target / 1000)} />
        </Mobile>
      </Section>
    );
  }
};
