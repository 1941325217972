'use strict';

var React = require('react');
var ReactDOM = require('react-dom');
var AppHeader = require('@common/AppHeader/components/Header.react');

Wethod.module('HeaderApp.Header', function (Header) {
  Header.Controller = {
    showHeader: function () {
      var element = React.createElement(AppHeader);
      var container = document.getElementById('appHeader');
      if (container !== null) ReactDOM.render(element, container);
    },
  };
});
