const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const Empty = require('@common/lists/EmptyRow.react');
const Search = require('@common/Table2/TableSearch.react');
const Employee = require('./EmployeeTagRow.react');

module.exports = class EmployeesTagTable extends React.Component {
  static updateHeight() {}

  constructor(props) {
    super(props);

    this.state = {
      expanded: null,
    };
  }

  handleRowClick(id) {
    const isCurrentExpandedRow = id === this.state.expanded;
    if (isCurrentExpandedRow) {
      this.setState({ expanded: null }); // Collapse row
    } else {
      this.setState({ expanded: id }); // Expand row
    }
  }

  getLoadedEmployees() {
    return this.props.employees.slice(0, this.props.page * this.props.size);
  }

  getEmployees() {
    const employees = this.getLoadedEmployees();

    return employees.map((employee) => (
      <Employee key={employee.id}
        expanded={this.state.expanded === employee.id}
        onClick={this.handleRowClick.bind(this)}
        activeTags={this.props.activeTags}
        employee={employee}
        tagType={this.props.name} />
    ));
  }

  hasMore() {
    const loaded = this.props.page * this.props.size;
    return this.props.employees.length > loaded;
  }

  render() {
    return (
      <Table columns={3} maxHeight={this.props.maxHeight}>
        <TableHead>
          <TableRow>
            <TableCell name="name"
              className="knowledge__column-employee knowledge__column-employee-tag capitalize"
              colSpan={3}>
              Team member & {this.props.name}
            </TableCell>
          </TableRow>
          <TableRow className="knowledge__search-row">
            <TableCell name="search" className="knowledge__search-cell">
              <Search placeholder="Search team member"
                updateHeight={EmployeesTagTable.updateHeight}
                filter={this.props.filter} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.hasMore()}
          loadMore={this.props.loadMore}
          empty={<Empty message="No employees found" />}>
          {this.getEmployees()}
        </TableBody>
      </Table>
    );
  }
};
