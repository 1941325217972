const React = require('react');
const Select = require('@common/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItem.react');

const ExternalCostSelect = ({ value, onChange, name, readOnly, disabled }) => {
  /**
   * The selectable options. Disabled external costs value may differ from "canonical" available
   * options that user can select. This is because disabled external costs are obtained from budget.
   * To address this condition we create a fake array of available values containing only a single
   * option equal to the given value.
   * This is needed to keep select behaviour coherent avoiding extra components.
   */
  const availableValues = disabled || readOnly ? [value]
    : [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const getOptions = () => availableValues.map((percentage) => (
    <SelectItem key={percentage} value={percentage}>{percentage} %</SelectItem>
  ));

  return (
    <Select readOnly={readOnly}
      disabled={!readOnly && disabled}
      value={value}
      id="sidebar-costs-percentage"
      label="External costs %"
      onChange={onChange}
      name={name}>
      {getOptions()}
    </Select>
  );
};

module.exports = ExternalCostSelect;
