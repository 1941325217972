const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Header = require('../SubsectionHeader.react');
const DisabledView = require('./DisabledView.react');
const EnabledView = require('./EnabledView.react');
const LoadingView = require('./LoadingView.react');
const Modal = require('../../containers/ModalController');

const JobOrderTemplates = ({ enabled, isWaiting, enable }) => (
  <div>
    <Header subtitle="Job Order"
      id="job-order-template"
      description="Set job orders templates for automatic job order generation for new projects of each job order category." />
    <ShowIf condition={isWaiting}
      showElse={(
        <ShowIf condition={enabled}
          showElse={
            <DisabledView enable={enable} />
          }>
          <EnabledView enable={enable} />
        </ShowIf>
      )}>
      <LoadingView />
    </ShowIf>
    <Modal />
  </div>
);

module.exports = JobOrderTemplates;
