const React = require('react');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const Mobile = require('@common/media-queries/Mobile.react');
const LaptopAndAbove = require('@common/media-queries/LaptopAndAbove.react');
const Modal = require('../containers/ModalController');
const SectionHeader = require('./SectionHeader.react');
const Actions = require('./Actions.react');
const Items = require('../containers/Items');
const Sidebar = require('../containers/SidebarController');
const PermissionManager = require('../containers/PermissionManager');
const AdvancedSearch = require('../containers/advanced-search/PipelineAdvancedSearch');
const Segment = require('../containers/advanced-search/PipelineSegment');
const FiltersTransformer = require('../containers/advanced-search/FiltersTransformer');
const Snackbar = require('../containers/Snackbar');
const ColumnList = require('../models/ColumnList');
const MobileColumnList = require('../models/MobileColumnList');

class Pipeline extends React.Component {
  componentDidMount() {
    if (this.props.basket) {
      this.props.addOpportunity(this.props.basket, this.props.winProbabilityThreshold);
    }
  }

  render() {
    const columnList = new ColumnList(
      this.props.visibleColumns,
      this.props.type,
    );
    const mobileColumnList = new MobileColumnList();

    return (
      <div className="pipeline wethod-section-body">
        <SectionHeader />
        <PermissionManager>
          <Actions />
        </PermissionManager>
        <Mobile>
          <Items visibleColumns={mobileColumnList.getVisible()}
            editableColumns={mobileColumnList.getEditable()} />
        </Mobile>
        <LaptopAndAbove>
          <Items visibleColumns={columnList.getVisible()}
            editableColumns={columnList.getEditable()} />
        </LaptopAndAbove>
        <Modal />
        <Sidebar />
        <FormValidator>
          <FiltersTransformer>
            <AdvancedSearch />
          </FiltersTransformer>
        </FormValidator>
        <FormValidator>
          <FiltersTransformer>
            <Segment />
          </FiltersTransformer>
        </FormValidator>
        <Snackbar />
      </div>
    );
  }
}

module.exports = Pipeline;
