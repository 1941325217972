/* eslint-disable camelcase */
const React = require('react');
const TextArea = require('@common/material-input/SidebarTextArea.react');
const TextInput = require('@common/material-input/SidebarTextInput.react');
const NumericInput = require('@common/material-input/SidebarNumericInput.react');
const FieldFactory = require('../categories/FieldFactory.react');
const ReadOnlyCostsField = require('./ReadOnlyTravelOfficeCostsField.react');

const TravelOfficeEditableSection = ({
  value, notes, status, payment_method, onChange,
  onValidate, metadataService,
}) => {
  const approved = status === 'approved';

  return (
    <div className="travel-office travel-office--highlight">
      <h3 className="travel__sidebar-subtitle">Travel office</h3>
      <FieldFactory name="value"
        readOnlyComponent={(
          <ReadOnlyCostsField placeholder="Total costs"
            value={value} />
)}
        metadataService={metadataService}>
        <NumericInput name="value"
          placeholder="Total costs"
          value={value}
          formatOptions={{ stripLeadingZeroes: false }}
          onChange={onChange}
          onValidate={onValidate}
          required={approved}>
          <span />
        </NumericInput>
      </FieldFactory>
      <TextInput name="payment_method"
        placeholder="Payment method"
        value={payment_method}
        onChange={onChange}
        onValidate={onValidate}>
        <span />
      </TextInput>
      <TextArea name="travel_office_notes"
        placeholder="Notes"
        value={notes}
        onChange={onChange}
        onValidate={onValidate}
        rows={4}>
        <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
      </TextArea>
    </div>
  );
};

module.exports = TravelOfficeEditableSection;
