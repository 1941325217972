const React = require('react');
const Table2 = require('@common/Table2/Table2.react');
const TableBody = require('@common/Table2/TableBody.react');
const TableHead = require('@common/Table2/TableHead.react');
const BodyRow = require('./TableWorkHourCapacityBodyRow.react');
const HeaderRow = require('./TableWorkHourCapacityHeaderRow.react');

module.exports = class TableWorkHourCapacity extends React.Component {
  static loadMore() {
    return null;
  }

  static getEmptyMessage() {
    return <span>No employee capacity group yet. Click "New capacity group" to create one</span>;
  }

  constructor(props) {
    super(props);

    this.state = { tableHeight: 375 };
  }

  getRows() {
    // first workHourCapacity item is default one
    return this.props.items
      .sort((a, b) => {
        if (a.default === b.default) {
          return 0;
        }
        if (a.default) {
          return -1;
        }
        return 1;
      })
      .map((item) => (
        <BodyRow key={item.id} item={item} />
      ));
  }

  hasMore() {
    return this.props.isWaiting;
  }

  render() {
    return (
      <div ref={(ref) => this.containerRef = ref} className="company-settings__table">
        <Table2 maxHeight={this.state.tableHeight}>
          <TableHead>
            <HeaderRow />
          </TableHead>
          <TableBody hasMore={this.hasMore()}
            editable
            loadMore={TableWorkHourCapacity.loadMore}
            empty={TableWorkHourCapacity.getEmptyMessage()}>
            {this.getRows()}
          </TableBody>
        </Table2>
      </div>
    );
  }
};
