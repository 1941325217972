const { connect } = require('react-redux');
const Component = require('../../components/full-time-equivalent/FullTimeEquivalent.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  fte: state.defaultFullTimeEquivalent,
});

const mapDispatchToProps = (dispatch) => ({
  onSaveClick: (data) => dispatch(actions.showUpdateFteConfirmModal(data)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
