const { connect } = require('react-redux');
const Component = require('../components/CreateSegmentButton.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  timelines: state.timelines,
  permissions: state.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  createSegmentModal: (timeline) => dispatch(actions.showCreateSegmentModal(timeline)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
