/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const SidebarInput = require('@common/material-input/SidebarWrapperEditable.react');
const ColorDotButton = require('../../containers/sidebar/StatusSelectButton');

/**
 * An outlined button select component, material styled, with color dot on the side and colored
 * select.
 *
 * PROPS
 * placeholder: string that will be shown as placeholder and, when placeholder is not shown, as
 * label helperText: string shown at the bottom of the input to give info; it will otherwise be
 * replaced by an error text when necessary children: icon shown on the left side of the input
 *
 * name: string
 * value: string
 * items: list of items to be shown in the picklist, required a 'name' attribute that will be shown
 * and a 'color' required: boolean
 *
 * onChange
 * onValidate
 *
 * @type {module.StatusSelect}
 */
module.exports = class StatusSelect extends React.Component {
  render() {
    return (
      <SidebarInput
        placeholder={this.props.placeholder}
        helperText={this.props.helperText}
        icon={this.props.children}
        required={this.props.required}>
        <ColorDotButton
          name={this.props.name}
          value={this.props.value}
          items={this.props.items}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required={this.props.required} />
      </SidebarInput>
    );
  }
};
