const React = require('react');
const _ = require('underscore');
const Select = require('@common/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItem.react');
const SelectItemDisabled = require('@common/inputs/Select/SelectItemDisabled.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');

const CategorySelect = ({
  value, availableValues, onChange, updateErrors,
  id, name, label, errorText, readOnly, required,
}) => {
  function handleChange(e) {
    const selected = availableValues
      .filter((category) => category.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  const getOptions = () => availableValues.map((category) => {
    if (category.is_archived) {
      return (
        <SelectItemDisabled key={category.id} value={category.id} hidden>
          {_.unescape(category.name)}
        </SelectItemDisabled>
      );
    }
    return (
      <SelectItem key={category.id} value={category.id}>
        {_.unescape(category.name)}
      </SelectItem>
    );
  });

  const currentValue = value ? value.id : null;

  return (
    <InputValidator updateErrors={updateErrors} constraints={['required']}>
      <Select readOnly={readOnly}
        value={currentValue}
        id={id}
        label={label}
        onChange={handleChange}
        name={name}
        errorText={errorText}
        required={required}
        empty="No available categories yet.">
        {getOptions()}
      </Select>
    </InputValidator>
  );
};

module.exports = CategorySelect;
