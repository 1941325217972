const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const ClientCreator = require('@common/inputs/Autocomplete/creators/ClientCreator.react');
const AutocompleteModel = require('../../../../../../models/PipelineAutocomplete');

module.exports = class ClientSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(item.corporate_name);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getClients(keyword)
      .done((collection) => this.setState({
        availableItems: collection.toJSON(),
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const { value } = e.target;
    const client = item ? {
      id: value,
      corporate_name: item.corporate_name,
    } : null;

    this.props.onChange(e, client);
  }

  onCreate(item) {
    const event = {
      target: {
        value: item.id,
        name: this.props.name,
      },
    };
    this.onChange(event, item);
  }

  getOptions() {
    return this.state.availableItems.map((client) => (
      <SelectItem key={client.id}
        value={client.id}
        corporate_name={client.hint}>
        {_.unescape(client.hint)}
      </SelectItem>
    ));
  }

  getValue() {
    const { id } = this.props.value;
    if (id === null) {
      return null;
    }
    return this.props.value;
  }

  render() {
    return (
      <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
        <Autocomplete readOnly={this.props.readOnly}
          label="Client"
          onChange={this.onChange.bind(this)}
          value={this.getValue()}
          getInputValue={ClientSelect.getInputValue}
          name={this.props.name}
          id="sidebar-client"
          onFilter={this.onFilter.bind(this)}
          errorText={this.props.error}
          creator={ClientCreator}
          onCreate={this.onCreate.bind(this)}
          loading={this.state.isLoading}>
          {this.getOptions()}
        </Autocomplete>
      </InputValidator>
    );
  }
};
