const { connect } = require('react-redux');
const Component = require('../../components/timeline/TimelineBody.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  timelines: state.timelines,
  // segments: state.segments,
  selectedSegment: state.selectedSegment,
});

const mapDispatchToProps = (dispatch) => ({
  selectSegment: (segmentStatus) => dispatch(actions.selectSegment(segmentStatus)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
