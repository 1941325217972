/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid */
const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Loader = require('@common/Loader/Loader.react');
const Actions = require('../containers/Actions');
const ProjectInfo = require('../containers/ProjectInfo/ProjectInfo');
const Calendar = require('../containers/Calendar');
const Modal = require('../containers/Modal');
const SearchBar = require('../containers/SearchBar');

module.exports = class Planning extends React.Component {
  /**
   * Returns if user has an external level.
   * @returns {bool}
   */
  isUserExternal() {
    return this.props.userInfo && this.props.userInfo.level.external;
  }

  componentDidMount() {
    this.props.getPeople();
    this.props.getAvailablePeopleFilters();
    this.props.isInternationalCalendar();
    this.props.getFte();
    this.props.getPlanningSettings();
    this.props.getAvailablePricelists();
    socket.emit('join', 'planning-people');
    socket.on('planning-people-changed', (data) => this.props.syncPlan(data.changes));
  }

  componentDidUpdate(prevProps) {
    const justFinishedFirstLoading = !this.props.firstLoading && prevProps.firstLoading;
    const justFinishedPlanLoading = this.props.days.length && !prevProps.days.length;

    if (justFinishedFirstLoading) {
      if (this.isUserExternal()) {
        // Show all visible users if the current one is external
        this.props.selectPeopleFilter('type', 'all');
      }
      const from = moment().isoWeekday(1).subtract(2, 'week'); // Monday of the last current week
      const to = from.clone().subtract(1, 'day').add(25, 'week');
      this.props.getPlan(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));
      dispatcher.trigger('tour:start', this.props.idTour);
    }
    if (justFinishedPlanLoading) {
      if (this.props.selectedProject) {
        this.props.selectProject(this.props.selectedProject);
      }

      this.props.getAvailableProjects();
    }
  }

  /**
   * Returns all skill filters contained in the given string.
   * */
  getSkills(string) {
    const regex = /(skill:)(\w+|\s)*;*/g;
    const match = string.match(regex);
    if (match !== null) {
      return match.map((skill) => skill.replace('skill:', '').replace(';', '').trim().toLowerCase());
    }
    return [];
  }

  /**
   * Returns all language filters contained in the given string.
   * */
  getLanguages(string) {
    const regex = /(language:)(\w+|\s)*;*/g;
    const match = string.match(regex);
    if (match !== null) {
      return match.map((language) => language.replace('language:', '').replace(';', '').trim().toLowerCase());
    }
    return [];
  }

  /**
   * Returns all interest filters contained in the given string.
   * */
  getInterests(string) {
    const regex = /(interest:)(\w+|\s)*;*/g;
    const match = string.match(regex);
    if (match !== null) {
      return match.map((skill) => skill.replace('interest:', '').replace(';', '').trim().toLowerCase());
    }
    return [];
  }

  onSearchKeywordChange(keyword) {
    const skills = this.getSkills(keyword);
    const languages = this.getLanguages(keyword);
    const interests = this.getInterests(keyword);
    if (skills.length) {
      this.props.onSearchKeywordChange(skills, 'skills');
    } else if (languages.length) {
      this.props.onSearchKeywordChange(languages, 'languages');
    } else if (interests.length) {
      this.props.onSearchKeywordChange(interests, 'interests');
    } else {
      this.props.onSearchKeywordChange(keyword, 'keyword');
    }
  }

  isFilteringSkills() {
    return this.props.people_filters.filter((filter) => filter.type === 'skills' && filter.target.length > 0).length > 0;
  }

  isFilteringLanguage() {
    return this.props.people_filters.filter((filter) => filter.type === 'languages' && filter.target.length > 0).length > 0;
  }

  isFilteringInterests() {
    return this.props.people_filters.filter((filter) => filter.type === 'interests' && filter.target.length > 0).length > 0;
  }

  getSearchBarContainerClassName() {
    if (this.isFilteringSkills() || this.isFilteringLanguage() || this.isFilteringInterests()) {
      return 'wethod-highlighter--search-keyword';
    }
    return null;
  }

  onExportClick(e) {
    e.preventDefault();
    Wethod.PlanningApp.Planning.showModalExport();
  }

  getClassName() {
    return [
      'planning-people__content',
      (
        this.props.planningSettings.availableHoursGrouping === 'price_lists'
        && this.props.selected_project
        && !this.props.selectedPricelist
      ) ? 'planning-people__content--pricelist-not-selected-yet' : '',
    ].join(' ').trim();
  }

  getContent() {
    if (this.props.firstLoading || !this.props.days.length) {
      return (
        <div className="planning-people__loader-container">
          <Loader />
        </div>
      );
    }
    return (
      <div className={this.getClassName()}>
        <Actions />
        <ProjectInfo />
        <div className={this.getSearchBarContainerClassName()}>
          <SearchBar filter={this.onSearchKeywordChange.bind(this)} />
        </div>
        <Calendar />
        <Modal />
        <div>
          <a
            href="#"
            style={{ fontSize: '12px' }}
            className="exportLink right"
            title="Export planning"
            onClick={this.onExportClick}>
            export module
          </a>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="planning-people wethod-section-body">
        <SectionHeader
          current_section="People Allocation"
          preview_anchor_id="overview"
          big
          helper_url="planning/index"
          tour_id={51691}
          tour_start_page="planning/people" />
        {this.getContent()}
      </div>
    );
  }
};
