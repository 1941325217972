const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');

const TableHeaderRow = () => (
  <TableRow className="company-settings-company-holidays__table-row">
    <TableCell className="company-settings-company-holidays__table-cell-name">
      HOLIDAY
    </TableCell>
    <TableCell className="company-settings-company-holidays__table-cell-date">
      DATE
    </TableCell>
    <TableCell className="company-settings-company-holidays__table-cell-location">
      LOCATIONS
    </TableCell>
    <TableCell className="company-settings-company-holidays__table-cell-recurring-year-toggle">
      REPEAT EVERY YEAR
    </TableCell>
    <TableCell className="company-settings-company-holidays__table-cell-action" />
  </TableRow>
);

module.exports = TableHeaderRow;
