/* eslint-disable camelcase */
const React = require('react');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const format = require('../../../../../../../services/FormatService').formatDecimalNumber;

const TravelOfficeReadOnlySection = ({ value, notes, payment_method, canEdit }) => {
  const style = canEdit ? 'travel-office travel-office--highlight travel-office--readonly' : 'travel-office travel-office--readonly';

  const formattedValue = value ? format(value, false, 1) : value;

  return (
    <div className={style}>
      <h3 className="travel__sidebar-subtitle">Travel office</h3>
      <ReadOnlyField placeholder="Total costs" value={formattedValue}>
        <span />
      </ReadOnlyField>
      <ReadOnlyField placeholder="Payment method" value={payment_method}>
        <span />
      </ReadOnlyField>
      <ReadOnlyField placeholder="Notes" value={notes}>
        <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
      </ReadOnlyField>
    </div>
  );
};

module.exports = TravelOfficeReadOnlySection;
