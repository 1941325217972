const React = require('react');
const PropTypes = require('prop-types');
const Button = require('./Button.react');

const UnstyledButton = ({ className, children, ...rest }) => {
  const actualClassName = [
    'wethod-unstyled-button',
    className,
  ].join(' ').trim();

  return (
    <Button className={actualClassName} {...rest}>{children}</Button>
  );
};

UnstyledButton.defaultProps = {
  className: '',
};

UnstyledButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

module.exports = UnstyledButton;
