const JobOrderTemplatesTypes = require('@models/JobOrderTemplate').types;
const constants = require('./constants');

class CompanySettingsReducer {
  /**
   * Get a default template with the basic information.
   */
  static getExampleTemplate() {
    return {
      id: 'new-template',
      job_order_categories: [],
      blocks: [{
        sort: 0, type: JobOrderTemplatesTypes.number, format: 3, value: '001',
      }],
    };
  }

  static getExampleEmployeeTagCategory() {
    return {
      name: '',
      isNew: true,
      sort: 999,
      disabled: false,
    };
  }

  /**
   * Return the updated list of templates to be shown after fetch:
   * when no template is fetched, we can show the list of templates previously visible.
   *
   * @param previousList
   * @param updatedList
   * @returns {*}
   */
  static getListOfTemplates(previousList, updatedList) {
    const hasTemplates = updatedList && updatedList.length > 0;

    return hasTemplates ? updatedList : previousList;
  }

  constructor(state) {
    this.state = {
      ...state,
      waitingFor: [], // contains a key for each pending request
      modalToShow: null,
      modalData: null,
      levels: state.levels || [],
      employeeTags: [],
      workHourCapacityEmployees: [],
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  reduxReducer(state = this.state, action) {
    switch (action.type) {
      case constants.SHOW_MODAL:
        return {
          ...state,
          modalToShow: action.name,
          modalData: action.data,
        };
      case constants.CLOSE_MODAL:
        return {
          ...state,
          modalToShow: null,
          modalData: null,
        };
      case constants.SET_TIMESHEET_STRATEGY_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('set-timesheet-strategy'),
          timesheet: {
            ...state.timesheet,
            strategy: action.strategy,
          },
        };
      case constants.SET_TIMESHEET_STRATEGY_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'set-timesheet-strategy'),
        };
      case constants.SET_TIMESHEET_FREQUENCY_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('set-timesheet-frequency'),
          timesheet: {
            ...state.timesheet,
            frequency: action.frequency,
          },
        };
      case constants.SET_TIMESHEET_FREQUENCY_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'set-timesheet-frequency'),
        };
      case constants.ENABLE_TEMPLATES_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('set-enabled'),
        };
      case constants.GET_TIMESHEET_TIME_LIMIT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-time-limit'),
        };
      case constants.GET_TIMESHEET_TIME_LIMIT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-time-limit'),
          timesheet: {
            ...state.timesheet,
            timeLimit: action.data,
          },
        };
      case constants.SET_TIMESHEET_TIME_LIMIT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('set-time-limit'),
        };
      case constants.SET_TIMESHEET_TIME_LIMIT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'set-time-limit'),
          timesheet: {
            ...state.timesheet,
            timeLimit: action.data,
          },
        };
      case constants.UPDATE_TIMESHEET_TIME_LIMIT_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('update-time-limit'),
        };
      case constants.UPDATE_TIMESHEET_TIME_LIMIT_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'update-time-limit'),
          timesheet: {
            ...state.timesheet,
            timeLimit: action.data,
          },
        };
      case constants.SET_TIMESHEET_DAILY_MODE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('set-timesheet-daily-mode'),
        };
      case constants.SET_TIMESHEET_DAILY_MODE_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'set-timesheet-daily-mode'),
          timesheet: {
            ...state.timesheet,
            dailyModeToggle: action.data,
          },
        };
      case constants.ENABLE_TEMPLATES_SUCCESS:
        return {
          ...state,
          jobOrderTemplates: {
            ...state.jobOrderTemplates,
            // Show the available templates, if any, or a new example template
            templates: CompanySettingsReducer
              .getListOfTemplates(
                [CompanySettingsReducer.getExampleTemplate()],
                state.jobOrderTemplates.templates,
              ),
            enabled: action.enabled,
          },
          waitingFor: state.waitingFor.filter((key) => key !== 'set-enabled'),
        };
      case constants.DELETE_TEMPLATE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-template-${action.id}`),
        };
      case constants.DELETE_TEMPLATE_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-template-${action.id}`),
          jobOrderTemplates: {
            ...state.jobOrderTemplates,
            templates: state.jobOrderTemplates.templates
              .filter((template) => template.id !== action.id),
          },
        };
      case constants.DELETE_TEMPLATE_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-template-${action.id}`),
        };
      case constants.ADD_NEW_TEMPLATE:
        // Add an empty template to the start of the list of job order templates
        return {
          ...state,
          jobOrderTemplates: {
            ...state.jobOrderTemplates,
            templates: [
              CompanySettingsReducer.getExampleTemplate(),
            ]
              .concat(state.jobOrderTemplates.templates),
          },
        };
      case constants.DELETE_NEW_TEMPLATE:
        // Remove the empty template (the new one, not yet saved) from the list of job order templates
        return {
          ...state,
          jobOrderTemplates: {
            ...state.jobOrderTemplates,
            templates: state.jobOrderTemplates.templates
              .filter((template) => template.id != null && template.id !== 'new-template'),
          },
        };
      case constants.SAVE_TEMPLATE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-template-${action.id}`),
        };
      case constants.SAVE_TEMPLATE_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-template-${action.id}`),
          jobOrderTemplates: {
            ...state.jobOrderTemplates,
            templates: state.jobOrderTemplates.templates.map((template) => {
              if (template.id === action.id) {
                return {
                  ...template,
                  ...action.template,
                };
              }
              return template;
            }),
          },
        };
      case constants.SAVE_TEMPLATE_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-template-${action.id}`),
        };
      case constants.GET_TEMPLATES_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-templates'),
        };
      case constants.GET_TEMPLATES_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-templates'),
          jobOrderTemplates: {
            ...state.jobOrderTemplates,
            templates: CompanySettingsReducer
              .getListOfTemplates(state.jobOrderTemplates.templates, action.templates),
          },
        };
      case constants.GET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-order-plan-view-preference'),
        };
      case constants.GET_ORDER_PLAN_VIEW_PREFERENCE_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-order-plan-view-preference'),
          orderPlanViewPreference: state.preference,
        };
      case constants.SET_ORDER_PLAN_VIEW_PREFERENCE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('set-order-plan-view-preference'),
        };
      case constants.SET_ORDER_PLAN_VIEW_PREFERENCE_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'set-order-plan-view-preference'),
          orderPlanViewPreference: state.preference,
        };
      case constants.ADD_NEW_JOB_TITLE:
        // Add an empty job title to the start of the list of job titles
        return {
          ...state,
          jobTitleSettings: {
            ...state.jobTitleSettings,
            jobTitles: [{ id: 'new-job-title' }].concat(state.jobTitleSettings.jobTitles),
          },
        };
      case constants.DELETE_NEW_JOB_TITLE:
        // Remove the empty job title (the new one, not yet saved) from the list of job titles
        return {
          ...state,
          jobTitleSettings: {
            ...state.jobTitleSettings,
            jobTitles: state.jobTitleSettings.jobTitles
              .filter((jobTitle) => jobTitle.id != null && jobTitle.id !== 'new-job-title'),
          },
        };
      case constants.ADD_LEVEL:
        return {
          ...state,
          levels: state.levels.concat(action.level),
        };
      case constants.DELETE_LEVEL:
        return {
          ...state,
          levels: state.levels.filter((level) => level.id !== action.level.id),
          jobTitleSettings: {
            ...state.jobTitleSettings,
            jobTitles: state.jobTitleSettings.jobTitles.filter((jobTitle) => (
              jobTitle.level.id !== action.level.id
            )),
          },
        };
      case constants.UPDATE_LEVEL:
        return {
          ...state,
          levels: state.levels.map((level) => {
            if (level.id === action.level.id) {
              return action.level;
            }
            return level;
          }),
        };
      case constants.SAVE_JOB_TITLE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-job-title-${action.id}`),
        };
      case constants.SAVE_JOB_TITLE_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-job-title-${action.id}`),
          jobTitleSettings: {
            ...state.jobTitleSettings,
            jobTitles: state.jobTitleSettings.jobTitles.map((jobTitle) => {
              if (jobTitle.id === action.id) {
                return action.jobTitle;
              }
              return jobTitle;
            }),
          },
        };
      case constants.SAVE_JOB_TITLE_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-job-title-${action.id}`),
        };
      case constants.GET_JOB_TITLES_REQUEST:
        return {
          ...state,
          jobTitleSettings: {
            ...state.jobTitleSettings,
            archivedFilter: action.filter,
            jobTitles: [],
          },
          waitingFor: state.waitingFor.concat('get-job-titles'),
        };
      case constants.GET_JOB_TITLES_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-job-titles'),
          jobTitleSettings: {
            ...state.jobTitleSettings,
            jobTitles: action.jobTitles,
          },
        };
      case constants.GET_FISCAL_YEAR_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-fiscal-year'),
        };
      case constants.GET_FISCAL_YEAR_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-fiscal-year'),
          fiscalYear: {
            start: action.fiscalYear.fiscal_year_start,
          },
        };
      case constants.SET_FISCAL_YEAR_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-fiscal-year'),
        };
      case constants.SET_FISCAL_YEAR_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-fiscal-year'),
          fiscalYear: {
            start: action.fiscalYear.fiscal_year_start,
          },
        };
      case constants.SET_PREVIOUS_FISCAL_YEAR_STATE:
        return {
          ...state,
          previousFiscalYear: action.previousFiscalYear.start,
        };
      case constants.SET_FISCAL_YEAR_STATE:
        return {
          ...state,
          fiscalYear: {
            start: action.fiscalYear,
          },
        };
      case constants.ADD_NEW_WORK_HOUR_CAPACITY:
        // Add an empty work hour capacity to the start of the list of work hour capacity
        return {
          ...state,
          workHourCapacities: [{ id: 'new-work-hour-capacity' }].concat(state.workHourCapacities),
        };
      case constants.DELETE_NEW_WORK_HOUR_CAPACITY:
        // Remove the empty job title (the new one, not yet saved) from the list of job titles
        return {
          ...state,
          workHourCapacities: state.workHourCapacities
            .filter((workHourCapacity) => workHourCapacity.id != null && workHourCapacity.id !== 'new-work-hour-capacity'),
        };
      case constants.SAVE_WORK_HOUR_CAPACITY_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-work-hour-capacity-${action.id}`),
        };
      case constants.SAVE_WORK_HOUR_CAPACITY_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-work-hour-capacity-${action.id}`),
          workHourCapacities: state.workHourCapacities.map((workHourCapacity) => {
            if (workHourCapacity.id === action.id) {
              return action.workHourCapacity;
            }
            return workHourCapacity;
          }),
        };
      case constants.SAVE_WORK_HOUR_CAPACITY_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-work-hour-capacity-${action.id}`),
        };
      case constants.GET_WORK_HOUR_CAPACITIES_REQUEST:
        return {
          ...state,
          workHourCapacities: [],
          waitingFor: state.waitingFor.concat('get-work-hour-capacities'),
        };
      case constants.GET_WORK_HOUR_CAPACITIES_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-work-hour-capacities'),
          workHourCapacities: action.workHourCapacities,
        };
      case constants.DELETE_WORK_HOUR_CAPACITY_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-work-hour-capacity-${action.id}`),
        };
      case constants.DELETE_WORK_HOUR_CAPACITY_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-work-hour-capacity-${action.id}`),
          workHourCapacities: state.workHourCapacities
            .filter((workHourCapacity) => workHourCapacity.id !== action.id),
        };
      case constants.DELETE_WORK_HOUR_CAPACITY_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-work-hour-capacity-${action.id}`),
        };
      case constants.SET_WORK_HOUR_CAPACITY_EMPLOYEES:
        return {
          ...state,
          workHourCapacityEmployees: action.workHourCapacityEmployees,
        };
      case constants.GET_EMPLOYEE_TAGS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-employee-tags'),
        };
      case constants.SET_EMPLOYEE_TAGS_DELETE:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('delete-employee-tags'),
        };
      case constants.SET_EMPLOYEE_TAGS_DELETE_COMPLETE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-employee-tags'),
        };
      case constants.GET_EMPLOYEE_TAGS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-employee-tags'),
        };
      case constants.SET_EMPLOYEE_TAGS_UPDATE_SUCCESS:
        return {
          ...state,
          employeeTags: state.employeeTags.map((el) => {
            if (el.id === action.tag.id_category) {
              return {
                ...el,
                items: el.items.map((item) => {
                  if (item.id === action.tag.id) {
                    return {
                      ...item,
                      name: action.tag.name,
                      disabled: false,
                    };
                  }
                  return item;
                }),
              };
            }
            return el;
          }),
        };
      case constants.SET_EMPLOYEE_TAGS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-employee-tags'),
        };
      case constants.SET_NEW_EMPLOYEE_TAGS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-employee-tags'),
          employeeTags: state.employeeTags.filter((el) => !el.isNew)
            .concat([action.employeeTags])
            .map((el) => ({ ...el, disabled: false })),
        };
      case constants.SET_EMPLOYEE_TAGS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-employee-tags'),
          employeeTags: state.employeeTags.map((el) => {
            if (el.id === action.employeeTags.id) {
              return {
                ...el,
                name: action.employeeTags.name,
                isNew: undefined,
                disabled: undefined,
              };
            }
            return el;
          }),
        };
      case constants.SET_PREVIOUS_EMPLOYEE_TAGS_STATE:
        return {
          ...state,
          previousEmployeeTags: action.previousEmployeeTags.categories,
        };
      case constants.SET_EMPLOYEE_TAGS_STATE:
        return {
          ...state,
          employeeTags: action.categories,
        };
      case constants.ADD_NEW_CATEGORY:
        return {
          ...state,
          // eslint-disable-next-line max-len
          employeeTags: state.employeeTags.concat([
            {
              id: parseInt(Math.max(...state.employeeTags?.map((o) => o.id))) + 1 || 1,
              ...CompanySettingsReducer.getExampleEmployeeTagCategory(),
            },
          ]),
        };
      case constants.DELETE_CATEGORY:
      case constants.SET_EMPLOYEE_TAGS_DELETE_SUCCESS:
        return {
          ...state,
          // eslint-disable-next-line max-len
          employeeTags: state.employeeTags.filter((el) => el.id !== action.id),
          waitingFor: state.waitingFor.filter((key) => key !== 'delete-employee-tags'),
        };
      case constants.SET_EMPLOYEE_TAGS_ITEMS:
        return {
          ...state,
          employeeTags: action.categories?.length > 0 ? action.categories?.map((category) => ({
            ...category,
            items: action.items.filter((item) => item.id_category === category.id),
          })) : [],
        };
      case constants.SET_EMPLOYEE_TAG_NEW_SUCCESS:
        return {
          ...state,
          employeeTags: state.employeeTags.map((category) => {
            if (category.id === action.employeeTag.id_category) {
              return {
                ...category,
                items: category.items?.length > 0
                  ? category.items.concat([action.employeeTag])
                  : [action.employeeTag],
              };
            }
            return category;
          }),
        };
      case constants.DELETE_TAG:
        return {
          ...state,
          employeeTags: state.employeeTags.map((category) => {
            if (category.items?.length > 0) {
              return {
                ...category,
                items: category.items.filter((item) => item.id !== action.id),
              };
            }
            return category;
          }),
        };
      case constants.SET_EMPLOYEE_TAG_CATEGORY_SAVING:
        return {
          ...state,
          // eslint-disable-next-line array-callback-return,consistent-return
          employeeTags: state.employeeTags.map((category) => {
            if (category.id === action.category.id) {
              return {
                ...category,
                name: action.category.name,
                disabled: action.saving,
              };
            }
            return category;
          }),
        };
      case constants.SET_EMPLOYEE_TAGS_ITEM_SAVING:
        return {
          ...state,
          employeeTags: state.employeeTags.map((category) => {
            if (category.items?.length > 0) {
              return {
                ...category,
                items: category.items.map((item) => {
                  if (item.id === action.tag.id) {
                    return {
                      ...item,
                      name: action.tag.name,
                      disabled: action.saving,
                    };
                  }
                  return item;
                }),
              };
            }
            return category;
          }),
        };
      case constants.ADD_NEW_LOCATION:
        // Add an empty location to the start of the list of locations
        return {
          ...state,
          locations: [{ id: 'new-location' }].concat(state.locations),
        };
      case constants.DELETE_NEW_LOCATION:
        // Remove the empty location (the new one, not yet saved) from the list of locations
        return {
          ...state,
          locations: state.locations.filter((location) => location.id != null && location.id !== 'new-location'),
        };
      case constants.SAVE_LOCATION_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-locations-${action.id}`),
        };
      case constants.SAVE_LOCATION_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-locations-${action.id}`),
          locations: state.locations.map((location) => {
            if (location.id === action.id) {
              return action.location;
            }
            return location;
          }).sort((a, b) => a.id - b.id),
        };
      case constants.SAVE_LOCATION_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-locations-${action.id}`),
        };
      case constants.GET_LOCATION_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('get-locations'),
          locations: [],
        };
      case constants.GET_LOCATION_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-locations'),
          locations: [...state.locations],
        };
      case constants.DELETE_LOCATION_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-locations-${action.id}`),
        };
      case constants.DELETE_LOCATION_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-locations-${action.id}`),
          locations: state.locations.filter((location) => location.id !== action.id),
        };
      case constants.DELETE_LOCATION_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-locations-${action.id}`),
        };
      case constants.ADD_NEW_COMPANY_HOLIDAY:
        // Add an empty company holiday to the start of the list of company holiday
        return {
          ...state,
          companyHolidays: [{ id: 'new-company-holiday' }].concat(state.companyHolidays),
        };
      case constants.DELETE_NEW_COMPANY_HOLIDAY:
        // Remove the empty company holiday (the new one, not yet saved) from the list of company holidays
        return {
          ...state,
          companyHolidays: state.companyHolidays
            .filter((companyHoliday) => companyHoliday.id != null && companyHoliday.id !== 'new-company-holiday'),
        };
      case constants.SAVE_COMPANY_HOLIDAY_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`save-company-holiday-${action.id}`),
        };
      case constants.SAVE_COMPANY_HOLIDAY_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-company-holiday-${action.id}`),
          companyHolidays: state.companyHolidays.map((companyHoliday) => {
            if (companyHoliday.id === action.id) {
              return action.companyHoliday;
            }
            return companyHoliday;
          }),
        };
      case constants.SAVE_COMPANY_HOLIDAY_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `save-company-holiday-${action.id}`),
        };
      case constants.GET_COMPANY_HOLIDAYS_REQUEST:
        return {
          ...state,
          companyHolidays: [],
          waitingFor: state.waitingFor.concat('get-company-holidays'),
        };
      case constants.GET_COMPANY_HOLIDAYS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'get-company-holidays'),
          companyHolidays: action.companyHolidays,
        };
      case constants.DELETE_COMPANY_HOLIDAY_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat(`delete-company-holiday-${action.id}`),
        };
      case constants.DELETE_COMPANY_HOLIDAY_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-company-holiday-${action.id}`),
          companyHolidays: state.companyHolidays
            .filter((companyHoliday) => companyHoliday.id !== action.id),
        };
      case constants.DELETE_COMPANY_HOLIDAY_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== `delete-company-holiday-${action.id}`),
        };
      case constants.SAVE_WIN_EMAIL_ADDRESS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-win-email-address'),
        };
      case constants.SAVE_WIN_EMAIL_ADDRESS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-win-email-address'),
        };
      case constants.SAVE_WIN_EMAIL_ADDRESS_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-win-email-address'),
        };
      case constants.SAVE_LOSS_EMAIL_ADDRESS_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-loss-email-address'),
        };
      case constants.SAVE_LOSS_EMAIL_ADDRESS_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-loss-email-address'),
        };
      case constants.SAVE_LOSS_EMAIL_ADDRESS_FAILURE:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-loss-email-address'),
        };
      case constants.SAVE_DEFAULT_FTE_REQUEST:
        return {
          ...state,
          waitingFor: state.waitingFor.concat('save-default-fte'),
        };
      case constants.SAVE_DEFAULT_FTE_SUCCESS:
        return {
          ...state,
          waitingFor: state.waitingFor.filter((key) => key !== 'save-default-fte'),
        };
      default:
        return state;
    }
  }
}

module.exports = CompanySettingsReducer;
