const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');

module.exports = class Actions extends React.Component {
  render() {
    return (
      <div className="supplier__actions wethod-section-actions" ref={(ref) => this.rootRef = ref}>
        <SectionHeader
          current_section="Supplier Details"
          helper_url="finance/index/#supplier-details"
          big />
      </div>
    );
  }
};
