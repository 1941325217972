const React = require('react');
const Select = require('@common/material-input/SidebarSelectAddButton.react');

module.exports = class BankAccountSelect extends React.Component {
  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  static found(key, value) {
    if (value) return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    return false;
  }

  static getEmpty() {
    return (
      <li className="wethod-search-select__item">
        Add one or more bank accounts where you would like to receive payments from your clients in the <a href="#settings/company/bank-accounts">settings page</a>.
      </li>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      filteredItems: this.props.items,
    };
  }

  handleFilter(name, key) {
    const filtered = this.getFilteredItems(key);
    this.setState({
      filteredItems: filtered,
    });
  }

  getFilteredItems(keyword) {
    return this.props.items.filter((item) => BankAccountSelect.found(keyword, item.name));
  }

  getSelectedValue() {
    return (this.props.value && this.props.value.id) ? this.props.value : null;
  }

  render() {
    return (
      <Select
        name="bank_account"
        placeholder="Bank Account"
        helperText="Choose in which bank account you would like to receive payments from this client."
        value={this.getSelectedValue()}
        items={this.state.filteredItems}
        onFilter={this.handleFilter.bind(this)}
        onChange={this.props.onChange}
        onValidate={this.props.onValidate}
        empty={BankAccountSelect.getEmpty()}>
        <span />
      </Select>
    );
  }
};
