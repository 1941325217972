/* eslint-disable no-shadow */
/**
 * Returns true if the given employees satisfy the given filters.
 *
 * @returns {Array} items filtered by key
 * @param {Array} filters
 * @param {Object} employee
 * @param plans
 * @param projects
 */
module.exports = (filters, employee, plans, projects) => {
  const searchFilter = filters.filter((filter) => filter.type === 'keyword')[0];
  const typeFilter = filters.filter((filter) => filter.type === 'type')[0];
  const levelFilters = filters.filter((filter) => filter.type === 'level');
  const tagFilters = filters.filter((filter) => filter.type === 'tag');
  const skillsFilters = filters.filter((filter) => filter.type === 'skills')[0];
  const languagesFilters = filters.filter((filter) => filter.type === 'languages')[0];
  const interestsFilters = filters.filter((filter) => filter.type === 'interests')[0];
  const buFilters = filters.filter((filter) => filter.type === 'bu');
  const jobTitleFilters = filters.filter((filter) => filter.type === 'job_title');
  const employeeProjects = plans[employee.id]
    ? plans[employee.id].map((plan) => projects[plan.project_id]) : [];
  const priceListFilters = filters.filter((filter) => filter.type === 'priceList')[0];

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  const found = (key, value) => {
    if (value) {
      return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    }
    return false;
  };

  /**
   * Return true whether employee's name contains the given keyword.
   * @param employee
   * @return {boolean}
   */
  const isInUserName = (employee) => {
    const key = searchFilter.target;
    const name = `${employee.surname} ${employee.name}`;
    return key === '' || found(key, name);
  };

  /**
   * Return true whether at least one of the employee's project's name contains the given keyword.
   * @return {boolean}
   */
  const isInProjectName = () => {
    const key = searchFilter.target;
    return key === '' || employeeProjects.filter((project) => found(key, project.name)).length > 0;
  };

  /**
   * Return true whether at least one of the employee's project's client contains the given keyword.
   * @return {boolean}
   */
  const isInProjectClient = () => {
    const key = searchFilter.target;
    return key === '' || employeeProjects.filter((project) => found(key, project.client.name)).length > 0;
  };

  /**
   * Return true whether employee has the given type.
   * @param employee
   * @return {boolean|*}
   */
  const isType = (employee) => {
    const key = typeFilter.target;
    const isExternal = employee.level.external;
    return key === 'all' || (isExternal && key === 'external') || (!isExternal && key === 'internal');
  };

  /**
   * Return true whether employee has one of the filtered levels.
   * @param employee
   * @return {boolean}
   */
  const isLevel = (employee) => {
    const keys = levelFilters;
    const levelId = employee.level.id;
    return keys.length === 0 || keys.filter((level) => level.target.id === levelId).length > 0;
  };

  /**
   * Return true whether employee has a specific priceList.
   * @param employee
   * @return {boolean}
   */
  const isPricelist = (employee) => {
    const key = priceListFilters;
    const priceListId = employee?.price_list?.id;
    return key?.target?.id === undefined || key.target.id === priceListId;
  };

  /**
   * Return true whether employee has one of the filtered business unit.
   * @param employee
   * @return {boolean}
   */
  const hasBusinessUnit = (employee) => {
    const keys = buFilters;
    const buId = employee.business_unit;
    return keys.length === 0 || keys.filter((bu) => bu.target.id === String(buId)).length > 0;
  };

  /**
   * Return true whether employee has one of the filtered job titles.
   * @param employee
   * @return {boolean}
   */
  const hasJobTitle = (employee) => {
    const keys = jobTitleFilters;
    const jobTitleId = employee.job_title ? employee.job_title.id : null;
    return keys.length === 0 || keys
      .filter((jobTitle) => jobTitle.target.id === jobTitleId).length > 0;
  };

  /**
   * Returns a map containing all filtered tags grouped by their category.
   * @return {{}}
   */
  const getCategoriesMap = () => {
    const categories = [];
    for (let i = 0; i < tagFilters.length; i++) {
      const tag = { // tag to add
        ...tagFilters[i].target,
        category: {
          id: tagFilters[i].secondaryTarget.id,
          name: tagFilters[i].secondaryTarget.name,
        },
      };
      const categoryAlreadyAdded = categories
        .filter((category) => category.id === tag.category.id).length > 0;
      if (categoryAlreadyAdded) { // this category has been already added to the map
        for (let j = 0; j < categories.length; j++) {
          if (categories[j].id === tag.category.id) {
            categories[j].tags.push(tagFilters[i].target);
          }
        }
      } else {
        categories.push({
          ...tag.category,
          tags: [tagFilters[i].target],
        });
      }
    }
    return categories;
  };

  /**
   * Return true whether employee is visible based on 'tag' filters.
   * Tag across category are in 'AND' but within the same category they'are in 'OR'.
   */
  const hasTags = (employee) => {
    const keys = tagFilters;
    const { tags } = employee;
    if (keys.length === 0) {
      return true;
    }
    const categories = getCategoriesMap();

    for (let i = 0; i < categories.length; i++) {
      // get all employee's tags with categories[i]
      const tagsWithCategory = tags.filter((tag) => tag.category.id === categories[i].id);
      if (tagsWithCategory.length === 0) {
        // employee must have a tag with the given category, if not exclude it
        return false;
      }
      // check if tagsWithCategory (each employee can have at most 1 tag per category) corresponds to at least one
      // of the tags picked by the user for categories[i]
      const tagsWithTarget = categories[i].tags.filter((tag) => tag.id === tagsWithCategory[0].id);
      if (tagsWithTarget.length === 0) {
        return false;
      }
    }
    return true;
  };

  /**
   * Return true whether employee is visible based on 'skills' filters.
   * Employee is visible only if he has ALL the required skills.
   * @param employee
   */
  const hasSkills = (employee) => {
    const keys = skillsFilters.target;
    const { skills } = employee;
    if (keys.length === 0) {
      return true;
    }
    for (let i = 0; i < keys.length; i++) {
      let found = false;
      for (let j = 0; j < skills.length && !found; j++) {
        if (skills[j].name.toLowerCase().indexOf(keys[i]) !== -1) {
          found = true;
        }
      }
      if (!found) {
        return false;
      }
    }
    return true;
  };

  /**
   * Return true whether employee is visible based on 'languages' filters.
   * Employee is visible only if he has ALL the required languages.
   * @param employee
   */
  const hasLanguages = (employee) => {
    const keys = languagesFilters.target;
    const { languages } = employee;
    if (keys.length === 0) {
      return true;
    }
    for (let i = 0; i < keys.length; i++) {
      let found = false;
      for (let j = 0; j < languages.length && !found; j++) {
        if (languages[j].name.toLowerCase().indexOf(keys[i]) !== -1) {
          found = true;
        }
      }
      if (!found) {
        return false;
      }
    }
    return true;
  };

  /**
   * Return true whether employee is visible based on 'interests' filters.
   * Employee is visible only if he has ALL the required interests.
   * @param employee
   */
  const hasInterests = (employee) => {
    const keys = interestsFilters.target;
    const { interests } = employee;
    if (keys.length === 0) {
      return true;
    }
    for (let i = 0; i < keys.length; i++) {
      let found = false;
      for (let j = 0; j < interests.length && !found; j++) {
        if (interests[j].name.toLowerCase().indexOf(keys[i]) !== -1) {
          found = true;
        }
      }
      if (!found) {
        return false;
      }
    }
    return true;
  };

  return (isInUserName(employee) || isInProjectName(employee) || isInProjectClient(employee))
    && hasSkills(employee) && hasLanguages(employee) && hasInterests(employee)
    && isType(employee)
    && isLevel(employee)
    && isPricelist(employee)
    && hasTags(employee)
    && hasBusinessUnit(employee)
    && hasJobTitle(employee);
};
