const React = require('react');
const isEqual = require('react-fast-compare');
const Loader = require('@common/Loader/Loader.react');
const Table = require('@common/Table2/Table2.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableBody = require('@common/Table2/TableBody.react');
const TableSearch = require('../TableSearch.react');
const ItemRow = require('./UnapprovedBudget.react');

module.exports = class UnapprovedBudgets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  /**
   * Get the maximum height of the table, fixed to 500
   * @returns {number}
   */
  static getTableMaxHeight() {
    return 500;
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  componentDidUpdate(prevProps) {
    const filtersChanged = !isEqual(prevProps.filters, this.props.filters);
    if (filtersChanged) {
      this.filter(this.props.filters);
    }
  }

  getList() {
    return this.props.items.map((item) => (
      <ItemRow
        key={item.project.id}
        item={item} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    if (!this.props.selectedSegment) {
      return 'Start data review from the timeline above to check on data';
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    return (
      <TableSearch
        keyword={this.props.search}
        filter={this.search.bind(this)} />
    );
  }

  getDefaultOptions() {
    return {
      offset: this.props.offset,
      limit: this.props.limit,
      search: this.props.search,
      order: this.props.order,
      sort: this.props.sort,
      filters: this.props.filters,
    };
  }

  getItems(options) {
    if (!this.props.isWaiting && this.props.selectedSegment != null) {
      this.props.getItems(
        options.offset,
        options.limit,
        options.search,
        options.order,
        options.sort,
        options.filters,
      );
    }
  }

  getSort(order) {
    return this.props.order === order ? this.props.sort : null;
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = (page - 1) * size;
      options.limit = size;
      options.order = this.props.order;
      options.sort = this.props.sort;

      this.getItems(options);
    }
  }

  hasMorePages() {
    return this.props.hasMorePages;
  }

  updateTableHeight() {
    this.setState({ tableHeight: UnapprovedBudgets.getTableMaxHeight() });
  }

  search(search) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.search = search;

      this.getItems(options);
    }
  }

  filter(filters) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.filters = filters;

      this.getItems(options);
    }
  }

  sort(order, sort) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.order = order;
      options.sort = sort;
      this.getItems(options);
    }
  }

  render() {
    return (
      <Table columns={6} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell name="name"
              className="data-freezing-engine__table-unapproved-budgets-name"
              onSort={this.sort.bind(this)}
              sort={this.getSort('name')}>
              Project
            </TableCell>
            <TableCell name="job-order"
              className="data-freezing-engine__table-unapproved-budgets-column"
              onSort={this.sort.bind(this)}
              sort={this.getSort('job-order')}>
              Job Order
            </TableCell>
            <TableCell name="client"
              className="data-freezing-engine__table-unapproved-budgets-column"
              onSort={this.sort.bind(this)}
              sort={this.getSort('client')}>
              Client
            </TableCell>
            <TableCell name="pm"
              className="data-freezing-engine__table-unapproved-budgets-column"
              onSort={this.sort.bind(this)}
              sort={this.getSort('pm')}>
              PM
            </TableCell>
            <TableCell name="budget-status"
              className="data-freezing-engine__table-unapproved-budgets-budget-status"
              onSort={this.sort.bind(this)}
              sort={this.getSort('budget-status')}>
              Budget
            </TableCell>
            <TableCell name="probability"
              className="data-freezing-engine__table-unapproved-budgets-probability"
              onSort={this.sort.bind(this)}
              sort={this.getSort('probability')}>
              Probability
            </TableCell>
            <TableCell name="actions"
              className="data-freezing-engine__table-unapproved-budgets-actions" />
          </TableRow>
        </TableHead>
        <TableBody hasMore={this.hasMorePages()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
