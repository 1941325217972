const React = require('react');
const { connect } = require('react-redux');
const DropdownMenu = require('@common/Dropdown/DropdownMenu.react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const Typography = require('@common/Typography/Typography.react');
const actions = require('../../actions');

const LevelsAvailabilityPricelistSelection = ({
  availablePricelists,
  selectedPricelist,
  selectPricelist,
}) => {
  const getOptions = () => availablePricelists
    .filter((pricelist) => (pricelist.usedByEmployees !== false))
    .map((pricelist) => (
      <MenuItem key={pricelist.id} onClick={selectPricelist.bind(this, pricelist)}>
        { pricelist.name }
      </MenuItem>
    ));

  return (
    <div style={{
      display: 'flex', alignItems: 'center', padding: '10px 10px 10px 0',
    }}>
      { availablePricelists && (
        <DropdownMenu
          label={selectedPricelist?.name ?? 'Select pricelist'}>
          {getOptions()}
        </DropdownMenu>
      )}
      <div style={{ padding: '0 0 0 10px' }}>
        { !selectedPricelist && (
          <Typography
            color={Typography.COLORS.PLACEHOLDER_GRAY}
            component={Typography.COMPONENTS.P}
            size={Typography.SIZES.PX11}
            weight={Typography.WEIGHTS.BOLD}
            uppercase>
            Select one pricelist to start
          </Typography>
        )}
      </div>
    </div>
  );
};

module.exports = connect((state) => ({
  availablePricelists: state.availablePricelists,
  selectedPricelist: state.selectedPricelist,
}), {
  selectPricelist: actions.selectPricelist,
})(LevelsAvailabilityPricelistSelection);
