/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind */
const React = require('react');
const TextInput = require('@common/TextInput.react');

module.exports = class StatusEditElement extends React.Component {
  componentDidMount() {
    if (this.props.status.focused) this.input.focus();
  }

  isNew() {
    return this.props.status.id < 0;
  }

  handleClick() {
    if (!this.props.status.default && !this.isNew()) {
      this.props.onColorClick(this.props.status.id);
    }
  }

  handleDelete() {
    this.props.onDelete(this.props.status.id);
  }

  handleBlur(value) {
    this.props.onBlur(this.props.status.id, value);
  }

  getActions() {
    if (!this.props.status.default) {
      const checkboxStyle = this.isNew() ? 'disabled' : '';
      const deleteStyle = this.isNew() ? 'wethod-icon-medium-delete wethod-icon-medium-delete--black project-canvas-menu-status-edit__delete disabled'
        : 'wethod-icon-medium-delete wethod-icon-medium-delete--black project-canvas-menu-status-edit__delete';
      return []
        .concat(<input
          type="checkbox"
          id="final-status"
          key="final-status"
          className={checkboxStyle}
          checked={this.props.status.completed}
          onChange={() => this.props.onFinalChange(this.props.status.id)} />)
        .concat(<div
          key="delete"
          className={deleteStyle}
          onClick={this.handleDelete.bind(this)} />);
    }
  }

  getColorStyle() {
    let style = 'project-canvas-menu-status-edit__color';
    if (!this.props.status.default) style += ' project-canvas-menu-status-edit__color--button';
    if (this.props.status.id < 0) style += ' disabled';

    return style;
  }

  render() {
    return (
      <div className="project-canvas-menu-status-edit__element">
        <span
          style={{ backgroundColor: this.props.status.color }}
          className={this.getColorStyle()}
          onClick={this.handleClick.bind(this)} />
        <div className="project-canvas-menu-status-edit__element-label">
          <TextInput
            ref={(input) => this.input = input}
            name={this.props.status.id}
            value={this.props.status.name}
            onChange={this.props.onChange}
            onBlur={this.handleBlur.bind(this)}
            placeholder={this.props.placeholder}
            disabled={this.isNew()} />
        </div>
        {this.getActions()}
      </div>
    );
  }
};
