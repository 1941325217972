const React = require('react');
const _ = require('underscore');
const TableCell = require('@common/Table2/TableCell.react');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const Autocomplete = require('@common/inputs/Autocomplete/BasicAutocomplete/BasicAutocomplete.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');
const AutocompleteModel = require('../../../../../../../models/PipelineAutocomplete');

module.exports = class PmCell extends React.Component {
  static getInputValue(item) {
    return _.unescape(`${item.name} ${item.surname}`);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getManagers(keyword)
      .done((collection) => this.setState({
        availableItems: collection.toJSON(),
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const { value } = e.target;
    let pm = null;

    if (item) {
      pm = {
        id: value,
        name: item.name,
        surname: item.surname,
      };
    }

    this.props.onChange(e, pm);
  }

  getOptions() {
    return this.state.availableItems.map((pm) => {
      const splitted = pm.hint.split(' ');
      const name = splitted[0];
      splitted.splice(0, 1);
      const surname = splitted.join(' ');

      return (
        <SelectItem key={pm.id}
          value={pm.id}
          name={name}
          surname={surname}>
          {_.unescape(`${name} ${surname}`)}
        </SelectItem>
      );
    });
  }

  render() {
    return (
      <TableCell className="pipeline-item__pm" style={this.props.style}>
        <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
          <InputMetadataManager metadataService={this.props.metadataService}
            name={this.props.name}
            canEdit={!this.props.readOnly}>
            <Autocomplete
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              onChange={this.onChange.bind(this)}
              value={this.props.value}
              getInputValue={PmCell.getInputValue}
              label={this.props.label}
              id={`${this.props.projectId}-pm`}
              onFilter={this.onFilter.bind(this)}
              errorText={this.props.error}
              loading={this.state.isLoading}>
              {this.getOptions()}
            </Autocomplete>
          </InputMetadataManager>
        </InputValidator>
      </TableCell>
    );
  }
};
