const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Card = require('../containers/Card');
const BasicInfo = require('../containers/BasicInfo');
const ContactInfo = require('../containers/ContactInfo');
const Skills = require('../containers/Skills');
const Interests = require('../containers/Interests');
const Bio = require('../containers/Bio');
const Languages = require('../containers/Languages/Languages');
const Portfolio = require('../containers/Portfolio/Portfolio');
const Sponsors = require('../containers/Sponsors/SponsorList');
const Sponsorized = require('../containers/Sponsorized/SponsorList');
const Objectives = require('../containers/Objectives/Objectives');
const YearResolution = require('../containers/YearResolution');
const ObjectivesFilters = require('../containers/Objectives/Filters');
const Trends = require('../containers/Trends/Trends');
const TrendsPeriodFilter = require('../containers/Trends/PeriodFilter');
const WorkingConnections = require('../containers/WorkingConnections/WorkingConnections');
const Kudos = require('../containers/Kudos/Kudos');
const KudosFilters = require('../containers/Kudos/Filters');
const Modal = require('../containers/ModalController');

module.exports = class Profile extends React.Component {
  constructor(props) {
    super(props);

    document.title = `${this.props.name} | ${Wethod.userInfo.get('company')} | WETHOD`;
  }

  getKudos() {
    if (this.props.showKudos) {
      return (
        <div className="profile__row">
          <Card title="Kudos" className="profile-card--100 profile-kudos" actions={<KudosFilters />}>
            <Kudos />
          </Card>
        </div>
      );
    }
    return null;
  }

  getOkr() {
    if (this.props.permissions.okr) {
      return (
        <div>
          <div className="profile__row">
            <Card
              title="Year Resolution"
              className="profile-card--100"
              onEditClick={this.props.showYearResolutionModal}>
              <YearResolution />
            </Card>
          </div>
          <div className="profile__row">
            <Card title="Objectives" className="profile-card--100" actions={<ObjectivesFilters />}>
              <Objectives />
            </Card>
          </div>
        </div>
      );
    }
    return null;
  }

  getMetrics() {
    if (this.props.permissions.metrics) {
      return (
        <div>
          <div className="profile__row">
            <Card title="Trends" className="profile-card--100 profile-trends" actions={<TrendsPeriodFilter />}>
              <Trends />
            </Card>
          </div>
          <div className="profile__row">
            <Card title="Working Connections" className="profile-card--100 profile-working-connections">
              <WorkingConnections />
            </Card>
          </div>
          {this.getKudos()}
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="profile wethod-section-body">
        <div className="profile__row profile__row--first">
          <SectionHeader
            current_section="Profile"
            preview_anchor_id="profile"
            helper_url="settings/index/#profile" />
          <BasicInfo />
          <Card className="profile__contact-info" onEditClick={this.props.showEditModal}>
            <ContactInfo />
          </Card>
        </div>
        <div className="profile__row">
          <Card title="Skills" onEditClick={this.props.showSkillsModal}>
            <Skills />
          </Card>
          <Card title="Short Bio" onEditClick={this.props.showBioModal}>
            <Bio />
          </Card>
        </div>
        <div className="profile__row">
          <Card title="Interests" onEditClick={this.props.showInterestsModal}>
            <Interests />
          </Card>
          <Card title="Portfolio" onEditClick={this.props.showPortfolioModal}>
            <Portfolio />
          </Card>
        </div>
        <div className="profile__row">
          <Card title="Languages" onEditClick={this.props.showLanguagesModal}>
            <Languages />
          </Card>
        </div>
        <div className="profile__row">
          <Card title="Sponsored by" className="profile-card--100" onEditClick={this.props.showSponsorsModal}>
            <Sponsors />
          </Card>
        </div>
        <div className="profile__row">
          <Card title="Sponsor of" className="profile-card--100">
            <Sponsorized />
          </Card>
        </div>
        {this.getOkr()}
        {this.getMetrics()}
        <Modal />
      </div>
    );
  }
};
