/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const Table = require('@common/TableLight/TableLight.react');
const TableHead = require('@common/TableLight/TableHead.react');
const TableBody = require('@common/TableLight/TableBody.react');
const TableHeaderRow = require('@common/TableLight/TableHeaderRow.react');
const TableTotalRow = require('@common/TableLight/TableTotalRow.react');
const TableCell = require('@common/TableLight/TableCell.react');
const Area = require('./Area.react');
const { formatByStep } = require('../../../../../../../services/FormatService');

class Areas extends React.Component {
  getProgress(daysLeft, budgetDays) {
    if (daysLeft === null || Number.isNaN(daysLeft) || budgetDays === 0) {
      return null;
    }
    return Math.round(((budgetDays - daysLeft) / budgetDays) * 1000) / 10;
  }

  getRows() {
    return this.props.areas.map((area) => (
      <Area
        key={area.id}
        {...area}
        isWaiting={this.props.isSaving}
        mode={this.props.project.project_status_mode}
        onChange={this.props.onAreaChange} />
    ));
  }

  /**
   * Return true if this project status has been compiled by area.
   * @returns {boolean}
   */
  compiledByArea() {
    return this.props.areas.filter((area) => area.days_left !== null).length > 0;
  }

  getTotals() {
    const totals = this.props.areas.reduce((sum, area) => {
      const daysLeft = area.days_left === null ? 0 : parseFloat(area.days_left);
      return {
        days: sum.days + area.days,
        days_left: sum.days_left + daysLeft,
      };
    }, {
      days: 0,
      days_left: 0,
    });

    // If this PS is not compiled by areas, show total days_left for consistency
    if (!this.compiledByArea()) {
      totals.days_left = this.props.totalDaysLeft;
    }

    return totals;
  }

  render() {
    const totals = this.getTotals();
    const progress = this.getProgress(totals.days_left, totals.days);

    return (
      <Table className="project-status-areas">
        <TableHead>
          <TableHeaderRow>
            <TableCell>Area</TableCell>
            <TableCell>Budget days</TableCell>
            <TableCell>Days left</TableCell>
            <TableCell>Progress</TableCell>
          </TableHeaderRow>
          <TableTotalRow>
            <TableCell>Total</TableCell>
            <TableCell>{formatByStep(totals.days, 1, false)}</TableCell>
            <TableCell>
              {totals.days_left !== null ? formatByStep(totals.days_left, 1, false) : null}
            </TableCell>
            <TableCell>{progress !== null ? `${formatByStep(progress, 1, false)}%` : null}</TableCell>
          </TableTotalRow>
        </TableHead>
        <TableBody>
          {this.getRows()}
        </TableBody>
      </Table>
    );
  }
}

module.exports = Areas;
