const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const Tag = require('@common/Tag/Tag.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const AutocompleteModel = require('../../../../../../models/PipelineAutocomplete');

module.exports = class WhitelistSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(`${item.name} ${item.surname}`);
  }

  static getEmptyState() {
    return (
      <Row>
        <div className="pipeline__sidebar-description pipeline__sidebar-description--bottom-spaced">
          No one is currently allowed to edit this project timesheet.
        </div>
      </Row>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getWhitelist(keyword)
      .done((list) => this.setState({
        availableItems: list.toJSON(),
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const { value } = e.target;
    if (value !== '') {
      const formattedItem = { id: item.value, name: item.name, surname: item.surname };

      // Avoid adding the item to the list of selected ones if it is already selected
      if (!this.isDuplicated(formattedItem)) {
        const newValue = this.getValue().concat(formattedItem);
        this.props.onChange(this.props.name, newValue);
      }

      // Set the item and then remove it to trigger the update of the Autocomplete and clear the input
      this.setState({ inputValue: item }, () => this.resetInput());
    }
  }

  onDelete(id) {
    if (!this.props.readOnly) {
      const newValue = this.getValue().filter((value) => value.id !== id);
      this.props.onChange(this.props.name, newValue);
    }
  }

  getValue() {
    return this.props.value;
  }

  getOptions() {
    return this.state.availableItems.map((person) => {
      const split = person.hint.split(' ');
      const name = split[0];
      split.splice(0, 1);
      const surname = split.join(' ');

      return (
        <SelectItem key={person.id}
          value={person.id}
          name={name}
          surname={surname}>
          {_.unescape(`${name} ${surname}`)}
        </SelectItem>
      );
    });
  }

  getTags() {
    return this.getValue()
      .map((value) => (
        <Tag key={value.id} id={value.id} onDelete={this.onDelete} disabled={this.props.readOnly}>
          {WhitelistSelect.getInputValue(value)}
        </Tag>
      ));
  }

  /**
   * Check whether the given item is already in the list of selected ones
   *
   * @param item
   * @returns {*}
   */
  isDuplicated(item) {
    return this.getValue().some((person) => person.id === item.id);
  }

  resetInput() {
    this.setState({ inputValue: undefined });
  }

  /**
   * Check whether to show the list of tags or an empty state.
   * @returns {boolean}
   */
  shouldShowTags() {
    return this.getTags().length > 0;
  }

  render() {
    return (
      <div>
        <ShowIf condition={!this.props.readOnly}>
          <Row className="wethod-sidebar__multiple-input-row--space-bottom">
            <Autocomplete readOnly={this.props.readOnly}
              onChange={this.onChange.bind(this)}
              value={this.state.inputValue}
              getInputValue={WhitelistSelect.getInputValue}
              label=""
              placeholder="Search team member"
              name={this.props.name}
              id="sidebar-whitelist-autocomplete"
              onFilter={this.onFilter.bind(this)}
              loading={this.state.isLoading}>
              {this.getOptions()}
            </Autocomplete>
          </Row>
        </ShowIf>
        <ShowIf condition={this.shouldShowTags()}
          showElse={WhitelistSelect.getEmptyState()}>
          <Row className="wethod-sidebar__multiple-input-row--space-bottom">
            <div>{this.getTags()}</div>
          </Row>
        </ShowIf>
      </div>
    );
  }
};
