const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Actions = require('../containers/Actions');
const ProductsList = require('../containers/ProductsList');
const Sidebar = require('../containers/SidebarController');
const Modal = require('../containers/modal/Modal');

const Products = () => (
  <div className="wethod-section-body products">
    <SectionHeader
      current_section="Products"
      helper_url="/settings/index/#products"
      big />
    <Actions />
    <ProductsList />
    <Sidebar />
    <Modal />
  </div>
);

module.exports = Products;
