const React = require('react');
const Modal = require('@common/Table2/modals/ShareSearchModal/ShareSearchModal.react');
const AutocompleteModel = require('../../../../../../models/PipelineAutocomplete');

class ShareSearchModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recipients: [],
      isLoading: false,
    };

    this.onSendClick = this.onSendClick.bind(this);
    this.onRecipientFilter = this.onRecipientFilter.bind(this);
  }

  onSendClick(message, recipient) {
    const path = `/#${Backbone.history.fragment}`;

    this.props.shareSearch(message, recipient, path);
  }

  onRecipientFilter(name, keyword) {
    this.setState({ isLoading: true });

    AutocompleteModel.getManagers(keyword)
      .done((collection) => this.setState({
        recipients: collection.toJSON(),
        isLoading: false,
      }));
  }

  render() {
    return (
      <Modal onCloseClick={this.props.onCloseClick}
        onSendClick={this.onSendClick}
        recipients={this.state.recipients}
        onRecipientFilter={this.onRecipientFilter}
        isLoadingRecipients={this.state.isLoading}
        selectParam="hint" />
    );
  }
}

module.exports = ShareSearchModal;
