/* eslint-disable consistent-return */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');

module.exports = class MilestoneItemCellView extends React.Component {
  getIcon() {
    return `wethod-icon wethod-icon-${this.props.value.type.toLowerCase()}`;
  }

  getCell() {
    if (this.props.value) {
      return (
        <div className="project-canvas-spreadsheet__cell-button-content--milestone">
          <div className={this.getIcon()} />
          <span>{moment(this.props.value.date).format('DD.MM.YYYY')}</span>
        </div>
      );
    }
  }

  render() {
    return (
      <TableCell
        key={this.props.id}
        className="project-canvas-spreadsheet__cell">
        {this.getCell()}
      </TableCell>
    );
  }
};
