const { connect } = require('react-redux');
const Component = require('../../components/modals/IgnoreAlertsModal.react');
const actions = require('../../actions');

const mapDispatchToProps = (dispatch) => ({
  showConfirmPauseModal: (segments, timeline) => dispatch(actions
    .showConfirmPauseModal(segments, timeline)),
});

module.exports = connect(null, mapDispatchToProps)(Component);
