const React = require('react');
const TextInput = require('@common/material-input/SidebarTextInput.react');
const TextArea = require('@common/material-input/SidebarTextArea.react');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const BudgetTemplateStructure = require('../BudgetTemplateStructure.react');

const BudgetTemplateEditableDetails = ({ template, onChange, onValidate }) => (
  <div className="budget-template__sidebar-body">
    <TextInput
      name="name"
      placeholder="Template Name"
      value={template.name}
      onChange={onChange}
      onValidate={onValidate}
      required />
    <TextArea
      name="description"
      placeholder="Description"
      value={template.description}
      onChange={onChange}
      onValidate={onValidate}
      rows={4}
      required />
    <ReadOnlyField
      placeholder="Structure"
      value={<BudgetTemplateStructure areas={template.areas} />} />
  </div>
);

module.exports = BudgetTemplateEditableDetails;
