/* eslint-disable react/sort-comp */
const React = require('react');
const Avatar = require('@common/AvatarBox/Avatar.react');
const Criterion = require('./Criterion.react');

module.exports = class PersonRow extends React.Component {
  onCriterionChange(name, value, id) {
    this.props.editVote(this.props.id, id, parseInt(value));
  }

  getNoteClassName() {
    let name = 'wethod-icon-notes wethod-icon-notes--black';
    if (this.props.note) {
      name += ' wethod-icon-notes--done';
    }
    return name;
  }

  getTags() {
    return this.props.tags.map((tag) => (
      <span
        key={tag}
        className="project-review__person-tag">
        {_.unescape(tag)}
      </span>
    ));
  }

  onNoteClick() {
    this.props.showNoteModal(this.props.id, this.props.note);
  }

  getCriteria() {
    return this.props.criteria.map((criterion) => (
      <Criterion
        key={criterion.id}
        {...criterion}
        allowMissingCriteria={this.props.allowMissingCriteria}
        onChange={this.onCriterionChange.bind(this)} />
    ));
  }

  getFeedback() {
    if (!this.props.complete) {
      return (
        <span
          className="project-review__row-feedback project-review__row-feedback--negative">
          incomplete
        </span>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="project-review__step-table-row project-review__step-table-row--person">
        <div className="project-review__step-table-row-header">
          <div className="project-review__step-table-cell project-review__step-table-cell--profile-pic">
            <div className="project-review__profile-pic-container">
              <Avatar pic={this.props.profile_pic} name={this.props.name} size="medium" />
            </div>
          </div>
          <div className="project-review__step-table-cell project-review__step-table-cell--name">
            <div className="project-review__person-name">{_.unescape(this.props.name)}</div>
            <div className="project-review__person-tags">{this.getTags()}</div>
            <div className="project-review__person-level">{_.unescape(this.props.level)}</div>
          </div>
          <div className="project-review__step-table-cell project-review__step-table-cell--feedback">
            {this.getFeedback()}
          </div>
          <div className="project-review__step-table-cell project-review__step-table-cell--note">
            <div className={this.getNoteClassName()} onClick={this.onNoteClick.bind(this)} />
          </div>
        </div>
        <div className="project-review__step-table-row-content">
          {this.getCriteria()}
        </div>
      </div>
    );
  }
};
