const BudgetModel = require('@models/Budget');
const PeopleAllocationModel = require('@models/PeopleAllocation');
const HolidayModel = require('@models/HolidayInstance');
const PlanningSettings = require('@models/PlanningSettings');
const PricelistModel = require('@models/Pricelist');
const FeatureService = require('../../../../../services/FeatureService');
const constants = require('./constants');

const getHolidaysSuccess = (holidays) => ({
  type: constants.GET_HOLIDAYS_SUCCESS,
  holidays,
});

const getHolidaysRequest = () => ({
  type: constants.GET_HOLIDAYS_REQUEST,
});

const getHolidays = (from, to) => (dispatch) => {
  dispatch(getHolidaysRequest());
  HolidayModel.list(from, to)
    .done((holidays) => dispatch(getHolidaysSuccess(holidays)));
};

const isInternationalCalendarSuccess = (enabled, highlightWeekend) => ({
  type: constants.IS_INTERNATIONAL_CALENDAR_SUCCESS,
  enabled,
  highlightWeekend,
});

const isInternationalCalendarRequest = () => ({
  type: constants.IS_INTERNATIONAL_CALENDAR_REQUEST,
});

module.exports.isInternationalCalendar = () => (dispatch) => {
  dispatch(isInternationalCalendarRequest());
  $.when(Wethod.request('get:company:planning:calendar'))
    .done((response) => dispatch(isInternationalCalendarSuccess(response.attributes.enabled,
      response.attributes.highlight_weekend)));
};

const getFteRequest = () => ({
  type: constants.GET_FTE_REQUEST,
});

const getFteSuccess = (fte) => ({
  type: constants.GET_FTE_SUCCESS,
  fte,
});

module.exports.getFte = () => (dispatch) => {
  dispatch(getFteRequest());
  $.when(Wethod.request('get:company:fte'))
    .done((response) => dispatch(getFteSuccess(response.attributes.fte)));
};

const getAvailablePeopleFiltersSuccess = (filters) => ({
  type: constants.GET_AVAILABLE_PEOPLE_FILTERS_SUCCESS,
  filters,
});

const getAvailablePeopleFiltersRequest = () => ({
  type: constants.GET_AVAILABLE_PEOPLE_FILTERS_REQUEST,
});

module.exports.getAvailablePeopleFilters = () => (dispatch) => {
  dispatch(getAvailablePeopleFiltersRequest());
  $.when(Wethod.request('get:planning:filters'))
    .done((response) => dispatch(getAvailablePeopleFiltersSuccess(response.attributes)));
};

module.exports.getPlanningSettings = () => (dispatch) => (
  PlanningSettings.getAvailableHoursGrouping()
    .done(({ available_hours_grouping: availableHoursGrouping }) => {
      dispatch({
        type: constants.GET_PLANNING_SETTINGS_SUCCESS,
        planningSettings: {
          availableHoursGrouping,
        },
      });
    })
);

module.exports.getAvailablePricelists = () => (dispatch) => (
  PricelistModel.list({
    available: true,
  })
    .done((response) => {
      dispatch({
        type: constants.GET_AVAILABLE_PRICELISTS_SUCCESS,
        availablePricelists: response,
      });
    })
);

module.exports.selectPricelist = (pricelist) => (dispatch) => {
  dispatch({
    type: constants.SET_SELECTED_PRICELIST,
    selectedPricelist: pricelist,
  });
  dispatch({ type: constants.FILTER_PROJECT_LEVELS_BY_PRICELIST });
  dispatch({ type: constants.FILTER_PEOPLE_BY_PRICELIST });
};

const getAvailableProjectsSuccess = (projects) => ({
  type: constants.GET_AVAILABLE_PROJECTS_SUCCESS,
  projects,
});

const getAvailableProjectsRequest = () => ({
  type: constants.GET_AVAILABLE_PROJECTS_REQUEST,
});

module.exports.getAvailableProjects = () => (dispatch) => {
  dispatch(getAvailableProjectsRequest());
  $.when(Wethod.request('get:planning:projects-list'))
    .done((response) => dispatch(getAvailableProjectsSuccess(response.toJSON())));
};

const getPeopleSuccess = (people) => ({
  type: constants.GET_PEOPLE_SUCCESS,
  people,
});

const getPeopleRequest = () => ({
  type: constants.GET_PEOPLE_REQUEST,
});

module.exports.getPeople = () => (dispatch) => {
  dispatch(getPeopleRequest());
  $.when(Wethod.request('get:planning:people'))
    .done((response) => dispatch(getPeopleSuccess(response.data)));
};

const getPlanSuccess = (projects, planning, from, to) => {
  const isFuture = moment(from).isSameOrAfter(moment(), 'week');
  return {
    type: constants.GET_PLAN_SUCCESS,
    plan: planning,
    projects,
    from,
    to,
    is_future: isFuture,
  };
};

const getPlanRequest = () => ({
  type: constants.GET_PLAN_REQUEST,
});

module.exports.getPlan = (from, to) => (dispatch) => {
  dispatch(getPlanRequest(from, to));

  $.when(Wethod.request('get:planning', {
    dateStart: from,
    dateEnd: to,
  })).done((planning) => {
    if (Wethod.featureService.isEnabled(FeatureService.JAKALA_FEATURES_NOVEMBER_BLOCK)) {
      dispatch(getHolidays(from, to));
    }
    dispatch(getPlanSuccess(planning.attributes.projects, planning.attributes.plannings, from, to));
  });
};

module.exports.filterPeople = (keyword) => ({
  type: constants.FILTER_PEOPLE,
  keyword,
});

module.exports.closeProjectInfo = () => (dispatch) => {
  dispatch({ type: constants.CLOSE_PROJECT_INFO });
  dispatch({ type: constants.FILTER_PEOPLE_BY_PRICELIST });
};

module.exports.changeZoom = (zoom) => ({
  type: constants.CHANGE_ZOOM,
  zoom,
});

module.exports.closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.showModal = (name, data) => ({
  type: constants.SHOW_MODAL,
  name,
  data,
});

module.exports.selectPeopleFilter = (type, target, secondaryTarget) => ({
  type: constants.SELECT_PEOPLE_FILTER,
  name: type,
  target,
  secondaryTarget,
});

module.exports.clearPeopleFilters = () => (dispatch) => {
  dispatch({ type: constants.CLEAR_PEOPLE_FILTERS });
  dispatch({ type: constants.FILTER_PEOPLE_BY_PRICELIST });
};

module.exports.deselectPeopleFilter = (type, target, secondaryTarget) => ({
  type: constants.DESELECT_PEOPLE_FILTER,
  name: type,
  target,
  secondaryTarget,
});

const getProjectAvailabilityFailure = (reason) => ({
  type: constants.GET_PROJECT_AVAILABILITY_FAILURE,
  reason,
});

const getProjectAvailabilitySuccess = (project) => ({
  type: constants.GET_PROJECT_AVAILABILITY_SUCCESS,
  project,
});

const getProjectAvailabilityRequest = () => ({
  type: constants.GET_PROJECT_AVAILABILITY_REQUEST,
});

module.exports.searchKeywordChange = (keyword, mode) => ({
  type: constants.SEARCH_KEYWORD_CHANGE,
  keyword,
  mode,
});

const getProjectAvailability = (id) => (dispatch) => {
  dispatch(getProjectAvailabilityRequest());
  PeopleAllocationModel.getProgress(id)
    .done((project) => {
      dispatch(getProjectAvailabilitySuccess(project));
    })
    .fail((message, code) => {
      let reason = constants.FAILURE_REASONS.MISSING_PERMISSIONS;
      if (code === 404) {
        reason = constants.FAILURE_REASONS.MISSING_APPROVED_BUDGET_VERSION;
      }
      dispatch(getProjectAvailabilityFailure(reason));
    });
};

const saveBudgetConversionSuccess = () => ({
  type: constants.SAVE_BUDGET_CONVERSION_SUCCESS,
});

const saveBudgetConversionFailure = (message) => ({
  type: constants.SAVE_BUDGET_CONVERSION_FAILURE,
  message,
});

const saveBudgetConversionRequest = () => ({
  type: constants.SAVE_BUDGET_CONVERSION_REQUEST,
});

module.exports.saveBudgetConversion = (projectId, areas) => (dispatch) => {
  dispatch(saveBudgetConversionRequest());
  $.when(BudgetModel.swap(projectId, areas))
    .done(() => {
      dispatch(saveBudgetConversionSuccess());
      dispatch(getProjectAvailability(projectId));
    }).fail((message) => {
      dispatch(saveBudgetConversionFailure(message));
    });
};

const recurringAddFailure = (errors, settings) => ({
  type: constants.RECURRING_ADD_FAILURE,
  errors,
  settings,
});

const recurringRequest = (settings) => ({
  type: constants.RECURRING_REQUEST,
  settings,
});

const recurringSuccess = (changes) => ({
  type: constants.RECURRING_SUCCESS,
  changes,
});

module.exports.recurringAdd = (employeeId, plannings, force) => (dispatch) => {
  const data = {
    employee: employeeId,
    plannings,
  };

  dispatch(recurringRequest(data));

  $.when(Wethod.request('repeat:planning:add', force, data))
    .done((response) => {
      const changes = response.data.map((change) => ({
        ...change,
        person: employeeId,
      }));
      dispatch(recurringSuccess(changes));
      if (changes.length) {
        socket.emit('planning-people-changed', {
          author: Wethod.userInfo.get('employee_id'),
          changes,
        });
      }
    })
    .fail((response) => dispatch(recurringAddFailure(response.data, data)));
};

module.exports.recurringDelete = (employeeId, plannings) => (dispatch) => {
  const data = {
    employee: employeeId,
    plannings,
  };

  dispatch(recurringRequest(data));

  $.when(Wethod.request('repeat:planning:delete', data))
    .done((response) => {
      const changes = response.data.map((change) => ({
        ...change,
        person: employeeId,
      }));
      dispatch(recurringSuccess(changes));
      if (changes.length) {
        socket.emit('planning-people-changed', {
          author: Wethod.userInfo.get('employee_id'),
          changes,
        });
      }
    });
};

const editPlanFailure = () => ({
  type: constants.EDIT_PLAN_FAILURE,
});

const editPlanRequest = (changes) => ({
  type: constants.EDIT_PLAN_REQUEST,
  changes,
});

// changed: [{day,amount, project_id}]
module.exports.editPlan = (changes) => (dispatch) => {
  const data = {
    project: changes[0].project_id,
    employee: changes[0].person,
    plannings: changes.map((change) => ({
      date: change.day,
      amount: change.amount,
    })),
  };

  dispatch(editPlanRequest(changes));
  $.when(Wethod.request('update:planning', data))
    .done((response) => {
      const solvedConflicts = response.data.solved_conflicts;
      dispatch(recurringSuccess(solvedConflicts));
      if (changes.length) {
        socket.emit('planning-people-changed', {
          author: Wethod.userInfo.get('employee_id'),
          changes: changes.concat(solvedConflicts),
        });
      }
    })
    .fail(() => dispatch(editPlanFailure()));
};

module.exports.syncPlan = (changes) => {
  const projects = [];
  for (let i = 0; i < changes.length; i++) {
    const projectId = changes[i].project_id;
    const found = projects.toString().indexOf(projectId).length > 0;
    if (!found) {
      projects.push(projectId);
    }
  }
  return (dispatch) => {
    const multiProject = projects.length > 1;// changes contains more than one project change
    if (multiProject) {
      dispatch(recurringSuccess(changes));
    }
    dispatch(editPlanRequest(changes));
  };
};

module.exports.toggleRecurringMode = () => ({
  type: constants.TOGGLE_RECURRING_MODE,
});

module.exports.confirmWeekendPlanning = () => ({
  type: constants.CONFIRM_WEEKEND_PLANNING,
});

module.exports.confirmHolidayPlanning = () => ({
  type: constants.CONFIRM_HOLIDAY_PLANNING,
});

module.exports.confirmCapacityPlanning = () => ({
  type: constants.CONFIRM_CAPACITY_PLANNING,
});

module.exports.getProjectAvailability = getProjectAvailability;
