const React = require('react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const IconLink = require('@common/TooltipFixed/IconLinkTooltip.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const IconButtonTooltip = require('@common/TooltipFixed/IconButtonTooltip.react');
const IconButton = require('@common/Button/IconButton.react');

module.exports = class ItemSidebarActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  handleArchive() {
    this.props.onArchive(true);
  }

  handleUnarchive() {
    this.props.onArchive(false);
  }

  onShareClick() {
    this.props.shareProject(this.props.project);
  }

  onReviewClick() {
    this.props.requestReview(this.props.project);
  }

  onFeedbackClick() {
    this.props.editFeedback(this.props.project);
  }

  getOptionsMenu(projectId) {
    return (
      <Menu
        open={this.state.showMenu}
        onClose={this.closeMenu.bind(this)}
        anchorEl={this.optionsButton}>
        <MenuItem className="wethod-menu__item--link">
          <a href={`#timeline/${projectId}`}
            target="_blank"
            rel="noreferrer">
            Timeline
          </a>
        </MenuItem>
        <MenuItem className="wethod-menu__item--link">
          <a href={`/#project-status/${projectId}`}
            target="_blank"
            rel="noreferrer">
            Project status
          </a>
        </MenuItem>
        <MenuItem className="hidden-mobile wethod-menu__item--link">
          <a href={`#planning/production-plan?project_id:${projectId}`}
            target="_blank"
            rel="noreferrer">
            Production plan
          </a>
        </MenuItem>
        <MenuItem className="wethod-menu__item--link">
          <a href={`#finance/order/?project=${projectId}`}
            target="_blank"
            rel="noreferrer">
            Orders
          </a>
        </MenuItem>
        <MenuItem onClick={this.onShareClick.bind(this)}>
          Share project
        </MenuItem>
        <ShowIf condition={this.props.reviewEnabled && this.props.canStartReview}>
          <MenuItem onClick={this.onReviewClick.bind(this)}>
            Request review
          </MenuItem>
        </ShowIf>
        <ShowIf condition={this.props.canEditFeedback()}>
          <MenuItem onClick={this.onFeedbackClick.bind(this)}>
            Edit won/lost feedback
          </MenuItem>
        </ShowIf>
        <ShowIf condition={this.props.canArchive && !this.props.isArchived}>
          <MenuItem onClick={this.handleArchive.bind(this)}>
            Archive
          </MenuItem>
        </ShowIf>
        <ShowIf condition={this.props.canArchive && this.props.isArchived}>
          <MenuItem onClick={this.handleUnarchive.bind(this)}>
            Unarchive
          </MenuItem>
        </ShowIf>
      </Menu>
    );
  }

  setMenuAnchorRef(ref) {
    this.optionsButton = ref;
  }

  showMenu() {
    if (!this.state.showMenu) this.setState({ showMenu: true });
  }

  closeMenu() {
    if (this.state.showMenu) this.setState({ showMenu: false });
  }

  hasBudget() {
    return this.props.budget !== null && this.props.budget.id !== null;
  }

  render() {
    const projectId = this.props.project.id;
    return (
      <div>
        <ShowIf condition={this.hasBudget()}>
          <IconLink label="Planning"
            borderless
            href={`#planning/people?project_id=${projectId}`}
            target="_blank">
            <span className="wethod-icon wethod-icon-planning wethod-icon-planning--black" />
          </IconLink>
        </ShowIf>
        <IconLink label="Budget"
          borderless
          href={`#pipeline/budget/${projectId}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
        </IconLink>
        <IconLink label="Canvas"
          borderless
          href={`#project/${projectId}/canvas`}
          target="_blank">
          <span className="wethod-icon wethod-icon-canvas wethod-icon-canvas--black" />
        </IconLink>
        <IconLink label="Report"
          borderless
          href={`#project/${projectId}/report`}
          target="_blank">
          <span className="wethod-icon wethod-icon-project-report wethod-icon-project-report--black" />
        </IconLink>
        <IconLink label="Invoice plan"
          className="hidden-mobile"
          borderless
          href={`#project/${projectId}/invoice-plan`}
          target="_blank">
          <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        </IconLink>
        <ShowIf condition={this.props.canDelete}>
          <div className="wethod-vertical-line" />
        </ShowIf>
        <ShowIf condition={this.props.canDelete}>
          <IconButtonTooltip label="Delete" onClick={this.props.onDelete} borderless>
            <span className="wethod-icon-delete wethod-icon-delete--black" />
          </IconButtonTooltip>
        </ShowIf>
        <ShowIf condition={this.props.canDelete}>
          <div className="wethod-vertical-line" />
        </ShowIf>
        <IconButton icon="more"
          color="black"
          label="More options"
          border={false}
          onClick={this.showMenu.bind(this)}
          inputRef={this.setMenuAnchorRef.bind(this)} />
        {this.getOptionsMenu(projectId)}
      </div>
    );
  }
};
