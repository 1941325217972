/* eslint-disable react/no-unused-state,react/sort-comp */
const React = require('react');
const Select = require('@common/SearchSelect/SearchSelect.react');
const Item = require('../../../containers/modals/Sponsors/SponsorItem');

module.exports = class SponsorsBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showSelect: false };
  }

  getSponsorItems() {
    return this.props.sponsors.map((sponsor) => <Item key={sponsor.id} {...sponsor} />);
  }

  getFeedback() {
    if (this.props.waiting) return <div className="wethod-modal2__feedback">Saving...</div>;
    return null;
  }

  onDoneClick() {
    this.props.hideModal();
  }

  addSponsor(name, value) {
    if (value) {
      this.props.addSponsor({
        sponsor: value.id,
        from_date: moment().format('YYYY-MM-DD'),
        to_date: null,
      }, this.props.employee);
    }
  }

  render() {
    return (
      <div className="profile-sponsor--editable">
        <div className="profile-sponsor__add">
          <Select
            name="sponsor"
            items={this.props.people}
            placeholder="Choose a sponsor to add"
            onChange={this.addSponsor.bind(this)} />
        </div>
        <div className="profile-sponsor__collection">
          {this.getSponsorItems()}
        </div>
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.onDoneClick.bind(this)} className="wethod-button">Done</button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
