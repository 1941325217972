const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const BacklogTrend = require('../containers/BacklogTrend');
const ProductionTrend = require('../containers/ProductionTrend');
const AvgProductionTrend = require('../containers/AvgProductionTrend');
const WeeksBacklogTrend = require('../containers/WeeksBacklogTrend');
const Tips = require('./Tips.react');

module.exports = class Backlog extends React.Component {
  componentDidMount() {
    this.props.getBacklogs();
    this.props.getProductions();
    this.props.getAvgRevenues();
  }

  render() {
    return (
      <div className="backlog wethod-section-body">
        <SectionHeader current_section="Backlog"
          preview_anchor_id="backlog"
          helper_url="reports/index/#backlog" />
        <ProductionTrend />
        <div className="backlog__one-year-trends">
          <AvgProductionTrend />
          <BacklogTrend />
          <WeeksBacklogTrend />
        </div>
        <Tips />
      </div>
    );
  }
};
