const React = require('react');
const Overview = require('@common/widgets/RatingOveview/components/RatingOverview.react');
const OverviewItem = require('@common/widgets/RatingOveview/components/Item/Item.react');
const OverviewRating = require('@common/widgets/RatingOveview/components/Item/Rating.react');
const OverviewHeader = require('@common/widgets/RatingOveview/components/Item/Header.react');
const OverviewFooter = require('@common/widgets/RatingOveview/components/Item/Footer.react');
const OverviewComment = require('@common/widgets/RatingOveview/components/Item/Comment.react');
const HeaderActions = require('@common/widgets/RatingOveview/components/Item/HeaderActions.react');
const Escaper = require('@common/formatters/Escaper.react');

module.exports = class RatingWidget extends React.Component {
  componentDidMount() {
    this.props.getRatings(this.props.supplierId);
  }

  getHeaderActions(rating) {
    const items = [];
    const canEditRating = this.props.canEdit && rating.employee.id === this.props.employeeId;

    if (canEditRating) {
      items.push({
        label: 'Edit',
        onClick: () => this.props.showRatingModal(rating),
      });
    }
    if (canEditRating || (rating.employee.id !== this.props.employeeId
      && this.props.canDeleteOthers)) {
      items.push({
        label: 'Delete',
        onClick: () => this.props.showDeleteRatingModal(rating),
      });
    }

    if (items.length) {
      return (
        <HeaderActions items={items} />
      );
    }
    return null;
  }

  getValues() {
    return this.props.ratings.map((rating) => rating.value);
  }

  getItems() {
    return this.props.ratings
      .sort((a, b) => b.value - a.value)
      .map((rating) => {
        const tags = rating.employee.tags.join(' | ');
        const timestamp = moment(rating.created_at).format('DD/MM/YYYY - HH:mm');
        return (
          <OverviewItem key={rating.id}>
            <OverviewHeader actions={this.getHeaderActions(rating)}
              author={rating.employee.name}
              authorInfo={tags}
              authorInfoSecond={rating.employee.level}
              pic={rating.employee.pic} />
            <OverviewRating value={rating.value} max={5} />
            <div className="supplier-widget-rating-item__project">
              <div className="supplier-widget-rating-item__project-name">
                <Escaper>{rating.project.name}</Escaper>
              </div>
              <div className="supplier-widget-rating-item__project-client">
                <Escaper>{rating.project.client.name}</Escaper>
              </div>
            </div>
            <OverviewComment text={rating.comment} />
            <OverviewFooter timestamp={timestamp} />
          </OverviewItem>
        );
      });
  }

  getAddHandler() {
    return this.props.canEdit ? this.showNewRatingModal.bind(this) : null;
  }

  showNewRatingModal() {
    this.props.showRatingModal();
  }

  render() {
    return (
      <Overview ratings={this.getValues()}
        maxRating={5}
        loading={this.props.isWaiting}
        onAddClick={this.getAddHandler()}>{this.getItems()}
      </Overview>
    );
  }
};
