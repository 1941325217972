const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const RadioButtonIconFull = require('@common/inputs/RadioGroup/icons/RadioFullIcon.react');
const RadioButtonIconEmpty = require('@common/inputs/RadioGroup/icons/RadioEmptyIcon.react');
const MoreOptions = require('./MoreOptions.react');
const NameCell = require('./NameCell.react');

const EditRow = ({ item, levels, waitingForSave, onChange }) => {
  function isSaving() {
    return waitingForSave.some((key) => key === `save-job-title-${item.id}`);
  }

  function getIcon(level) {
    if (item.level && item.level.id === level.id) {
      return (
        <span key={level.id}
          className="company-settings-job-title__table-cell-level disabled">
          <RadioButtonIconFull />
        </span>
      );
    }
    return (
      <span key={level.id}
        className="company-settings-job-title__table-cell-level company-settings-job-title__table-cell-level--hidden">
        <RadioButtonIconEmpty />
      </span>
    );
  }

  function getLevels() {
    return levels ? levels.map((level) => (
      getIcon(level)
    )) : [];
  }

  /**
   * Handle change of the job title.
   * @param name
   * @param value
   */
  function handleChange(name, value) {
    const updatedItem = { [name]: value };
    if (onChange && !isSaving()) {
      onChange(item.id, updatedItem);
    }
  }

  /**
   * Toggle archived state of the job title.
   */
  function handleArchive() {
    const archived = !item.archived;
    handleChange('archived', archived);
  }

  /**
   * Handle name change triggering the job title update.
   * @param e
   */
  function handleNameChange(e) {
    const { name } = e.target;
    const { value } = e.target;
    handleChange(name, value);
  }

  return (
    <TableRow feedback={isSaving() ? 'Saving' : ''}
      className="company-settings-job-title__table-row company-settings--with-transparent-input">
      <NameCell name={item.name}
        isArchived={item.archived}
        handleNameChange={handleNameChange} />
      <TableCell className="company-settings-job-title__table-cell-level">
        <div className="company-settings-job-title__table-cell-levels">
          {getLevels()}
        </div>
      </TableCell>
      <TableCell className="company-settings-job-title__table-cell-action">
        <MoreOptions archived={item.archived} onArchive={handleArchive} />
      </TableCell>
    </TableRow>
  );
};

module.exports = EditRow;
