const React = require('react');
const ErrorModal = require('@common/modals/ErrorModal.react');
const IntercompanyProjectModal = require('./modals/IntercompanyProject/IntercompanyProjectModal.react');
const IntercompanyInvalidModal = require('./modals/InvalidIntercompanyInvitation/InvalidIntercompanyInvitationModal.react');
const SaveSearchModal = require('../containers/modals/SaveSearchModal');
const ExportSearchModal = require('../containers/modals/ExportSearchModal');
const ShareSearchModal = require('../containers/modals/ShareSearchModal');
const ConfirmDeleteModal = require('../containers/modals/ConfirmDeleteModal');
const DeleteFailureModal = require('../containers/modals/DeleteFailureModal');
const ConfirmArchiveModal = require('../containers/modals/ConfirmArchiveModal');
const ArchiveFailureModal = require('./modals/ArchiveFailureModal.react');
const ProjectShareModal = require('../containers/modals/ShareProjectModal');
const ReviewRequestModal = require('../containers/modals/RequestReviewModal');
const StartReviewModal = require('./modals/StartReviewModal.react');
const EditFeedbackModal = require('../containers/modals/EditFeedbackModal');
const SegmentDeleteModal = require('../containers/modals/SegmentDeleteModal');
const IntercompanyDetailsModal = require('./modals/IntercompanyDetailsModal/IntercompanyDetailsModal.react');

module.exports = ({ toShow, modalData, hideModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'intercompany-link':
        return <IntercompanyProjectModal onCloseClick={hideModal} />;
      case 'intercompany-invalid':
        return <IntercompanyInvalidModal onCloseClick={hideModal} />;
      case 'search-save':
        return <SaveSearchModal onCloseClick={hideModal} />;
      case 'search-export':
        return <ExportSearchModal onCloseClick={hideModal} />;
      case 'search-share':
        return <ShareSearchModal onCloseClick={hideModal} />;
      case 'confirm-delete':
        return <ConfirmDeleteModal onCloseClick={hideModal} data={modalData} />;
      case 'segment-confirm-delete':
        return <SegmentDeleteModal onCloseClick={hideModal} segment={modalData} />;
      case 'delete-failure':
        return <DeleteFailureModal onCloseClick={hideModal} data={modalData} />;
      case 'confirm-archive':
        return <ConfirmArchiveModal onCloseClick={hideModal} data={modalData} />;
      case 'archive-failure':
        return <ArchiveFailureModal onCloseClick={hideModal} data={modalData} />;
      case 'project-share':
        return <ProjectShareModal onCloseClick={hideModal} data={modalData} />;
      case 'review-request':
        return <ReviewRequestModal onCloseClick={hideModal} data={modalData} />;
      case 'review-start':
        return <StartReviewModal onCloseClick={hideModal} data={modalData} />;
      case 'edit-feedback':
        return <EditFeedbackModal onCloseClick={hideModal} data={modalData} />;
      case 'save-error':
        return <ErrorModal onCancelClick={hideModal} data={modalData} />;
      case 'intercompany-details':
        return <IntercompanyDetailsModal onCloseClick={hideModal} data={modalData} />;
      default:
        return null;
    }
  };

  return getModal();
};
