const React = require('react');
const Loader = require('@common/Loader/Loader.react');

const Widget = ({ title, subtitle, actions, children, isWaiting }) => {
  const getBody = () => {
    if (isWaiting) {
      return (
        <div className="wethod-widget__loading">
          <Loader />
        </div>
      );
    }
    return children;
  };

  return (
    <div className="wethod-widget">
      <div className="wethod-widget__header">
        <span>
          <span className="wethod-widget__title">{title}</span>
          <span className="wethod-widget__subtitle">{subtitle}</span>
        </span>
        <span>{actions}</span>
      </div>
      <div className="wethod-widget__body">{getBody()}</div>
    </div>
  );
};

module.exports = Widget;
