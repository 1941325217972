const { connect } = require('react-redux');
const Component = require('@common/AdvancedSearch/SegmentSelect/SegmentSelect.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  availableOptions: state.availableSegments,
  value: state.currentSegment,
  filters: state.filters,
  businessUnit: state.buFilter,
  pageLimit: state.pageLimit,
  sortBy: state.sortBy,
  search: state.search,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateClick: () => dispatch(actions.showSegmentCreate()),
  onEditSegmentClick: (segment) => dispatch(actions.showSegmentEdit(segment)),
  onDeleteSegmentClick: (segment) => dispatch(actions.showConfirmSegmentDeleteModal(segment)),
  onChange: (segment) => dispatch(actions.applySegment(segment)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
