const React = require('react');
const Skills = require('@common/Tag/TagsInput/TagsInput.react');

module.exports = class SkillsBody extends React.Component {
  onAdd(name) {
    this.props.addSkill({ name }, this.props.employee);
  }

  onDelete(skill) {
    this.props.deleteSkill({ id: skill.id, name: skill.name }, this.props.employee);
  }

  onDoneClick() {
    this.props.hideModal();
  }

  render() {
    return (
      <div className="profile__skills profile__skills--editable">
        <Skills
          editable
          tags={this.props.tags}
          knownTags={this.props.knownTags}
          handleAdd={this.onAdd.bind(this)}
          placeholder="Add skill"
          handleDelete={this.onDelete.bind(this)} />
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.onDoneClick.bind(this)} className="wethod-button">Done</button>
        </div>
      </div>
    );
  }
};
