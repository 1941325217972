const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const BudgetFooterButton = require('../BudgetFooterButton.react');

const ConsumptionReportAction = ({ onClick }) => (
  <BudgetFooterButton label="Consumption vs Status" onClick={onClick} noBorder>
    <Icon icon="chart" size="medium" />
  </BudgetFooterButton>
);

module.exports = ConsumptionReportAction;
