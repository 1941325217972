const React = require('react');
const NumericField = require('@common/inputs/NumericField/BasicNumericField/BasicNumericField.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const PrimarySection = require('../Price/PricePrimarySection.react');
const PrimarySectionAmount = require('../Price/PricePrimarySectionAmount.react');
const Delta = require('../../Calculator/NumericDelta.react');
const CurrencySelect = require('../../CurrencySelect.react');
const CurrencyManager = require('../../../containers/CurrencyManager');
const FormatService = require('../../../../../../../services/FormatService');
const CollaborativeInput = require('../../../containers/BudgetCollaborativeInput');

const FinalPricePrimarySection = ({
  isCurrencyEnabled, label, editable, value, onValueChange, onCurrencyChange, previousValue = null,
  currencyCode = null, exchangeRateDate = '', currencyEditable = true, hideLabel = false,
  invoiceDrivenExchangeRate = false,
}) => {
  function handleValueChange(e) {
    let numericValue = parseFloat(e.target.value);
    numericValue = !Number.isNaN(numericValue) ? numericValue : 0;

    onValueChange(numericValue);
  }

  function handleCurrencyChange(e) {
    onCurrencyChange(e.target.value);
  }

  const getExchangeRateMessage = () => {
    if (invoiceDrivenExchangeRate) {
      return 'Final net\'s rate is driven by invoices';
    }
    if (exchangeRateDate !== '') {
      return `Exchange rates updated on ${FormatService.formatDate(exchangeRateDate)}`;
    }
    return '';
  };

  return (
    <PrimarySection label={hideLabel ? '' : label}>
      <PrimarySectionAmount className="wethod-budget-header__price-primary-section-value--final-price">
        <div className="wethod-budget-header__price-primary-section-value-input">
          <Delta actualValue={value} previousValue={previousValue} label={`${label} delta`} />
          <CollaborativeInput id="final-net-price">
            <NumericField
              delay={2000}
              onChange={handleValueChange}
              value={value}
              precision={2}
              minPrecision={2}
              readOnly={!editable}
              label={label} />
          </CollaborativeInput>
        </div>
        <ShowIf condition={isCurrencyEnabled}>
          <CurrencyManager>
            <CurrencySelect onChange={handleCurrencyChange}
              label={`${label} currency select`}
              code={currencyCode}
              editable={currencyEditable} />
          </CurrencyManager>
        </ShowIf>
      </PrimarySectionAmount>
      <ShowIf condition={exchangeRateDate !== ''}>
        <div data-testid="exchange-rate-message" className="wethod-budget-header__price-primary-section-subtext">
          {getExchangeRateMessage()}
        </div>
      </ShowIf>
    </PrimarySection>
  );
};

module.exports = FinalPricePrimarySection;
