/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,jsx-a11y/control-has-associated-label */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const Menu = require('@common/Menu/Menu.react');
const MilestoneDateMenu = require('./MilestoneMenu/MilestoneDateMenu.react');
const MilestoneListMenu = require('./MilestoneMenu/MilestoneListMenu.react');

module.exports = class MilestoneItemCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      showMilestoneList: false,
      updatedMilestone: this.props.value,
    };
  }

  needsUpdate() {
    if (!this.props.value) { // Save changes if the milestone info are completed
      return Boolean(this.state.updatedMilestone && this.state.updatedMilestone.type
        && this.state.updatedMilestone.date);
    } // Save changes if some info of the milestone has actually changed
    return this.state.updatedMilestone.type !== this.props.value.type
      || !moment(this.state.updatedMilestone.date).isSame(this.props.value.date);
  }

  onCloseMenu() {
    if (this.needsUpdate()) {
      this.props.editValue(this.props.item, this.props.attribute, this.state.updatedMilestone);
      this.setState({ showMenu: false });
    } else {
      this.setState({
        showMenu: false,
        updatedMilestone: this.props.value,
      });
    }
  }

  onMenuButtonClick() {
    if (!this.props.eventTypes && !this.props.isLoading) {
      this.props.getEventTypes();
    }

    this.setState({ showMenu: true });
  }

  onMilestoneClick() {
    this.setState({ showMilestoneList: true });
  }

  handleMilestoneDelete() {
    this.props.deleteMilestone(this.props.item, this.props.attribute);

    this.setState({ updatedMilestone: null });
  }

  handleMilestoneChange(type) {
    this.setState(
      {
        updatedMilestone: {
          ...this.state.updatedMilestone,
          type,
        },
        showMilestoneList: false,
      },
      () => {
        if (this.state.updatedMilestone && this.state.updatedMilestone.date) {
          this.onCloseMenu();
        }
      },
    );
  }

  handleDateChange(day) {
    this.setState(
      {
        updatedMilestone: {
          ...this.state.updatedMilestone,
          date: day,
        },
        showMilestoneList: false,
      },
      () => {
        this.onCloseMenu();
      },
    );
  }

  getMenuBody() {
    if (!this.state.updatedMilestone || this.state.showMilestoneList) {
      return (
        <MilestoneListMenu isLoading={this.props.isLoading}
          eventTypes={this.props.eventTypes}
          value={this.state.updatedMilestone}
          onChange={this.handleMilestoneChange.bind(this)} />
      );
    }
    return (
      <MilestoneDateMenu isLoading={this.props.isLoading}
        value={this.state.updatedMilestone}
        onMilestoneClick={this.onMilestoneClick.bind(this)}
        onChange={this.handleDateChange.bind(this)} />
    );
  }

  getIcon() {
    return `wethod-icon wethod-icon-${this.state.updatedMilestone.type.toLowerCase()}`;
  }

  getMilestoneCell() {
    if (this.state.updatedMilestone) {
      return (
        <div className="project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--sliding project-canvas-spreadsheet__cell-button-content--sliding-enabled">
          <button type="button"
            ref={(el) => this.moreButtonEl = el}
            className="project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--date"
            onClick={this.onMenuButtonClick.bind(this)}>
            <div className={this.getIcon()} />
            <span>{moment(this.state.updatedMilestone.date).format('DD.MM.YYYY')}</span>
          </button>
          <div className="project-canvas-spreadsheet__cell-button-content--delete">
            <button type="button"
              className="wethod-icon-button"
              onClick={this.handleMilestoneDelete.bind(this)}>
              <div className="wethod-icon wethod-icon-medium-discard wethod-icon-medium-discard--white" />
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="project-canvas-spreadsheet__cell-button">
        <button type="button"
          ref={(el) => this.moreButtonEl = el}
          className="project-canvas-spreadsheet__cell-button-content"
          onClick={this.onMenuButtonClick.bind(this)} />
      </div>
    );
  }

  render() {
    return (
      <TableCell key={this.props.id}
        className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button">
        {this.getMilestoneCell()}
        <Menu open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          {this.getMenuBody()}
        </Menu>
      </TableCell>
    );
  }
};
