/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Body = require('../../../containers/modals/Sponsors/SponsorsBody');

module.exports = class SponsorsModal extends React.Component {
  render() {
    return (
      <Modal title="Edit Sponsors" onCancelClick={this.props.onCancelClick}>
        <Body />
      </Modal>
    );
  }
};
