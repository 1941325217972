const $ = require('jquery');
const AllocationRequestModel = require('@models/AllocationRequest');
const constants = require('./constants');

const applySolvedConflicts = (items) => ({
  type: constants.SOLVED_CONFLICTS_APPLY,
  items, // List of items passed from conflict to pending
});

const hideModal = () => ({
  type: constants.HIDE_MODAL,
});

module.exports.showApproveAllocationModal = (item) => ({
  type: constants.SHOW_MODAL_APPROVE_ALLOCATION,
  item,
});

module.exports.showRejectAllocationModal = (item) => ({
  type: constants.SHOW_MODAL_REJECT_ALLOCATION,
  item,
});

module.exports.showConfirmDeleteAllocationModal = (item) => ({
  type: constants.SHOW_MODAL_CONFIRM_DELETE_ALLOCATION,
  item,
});

const showOutOfSyncModal = () => ({
  type: constants.SHOW_MODAL_OUT_OF_SYNC,
});

const showPlanUponApprovalErrorModal = () => ({
  type: constants.SHOW_MODAL_PLAN_UPON_APPROVAL_ERROR,
});

module.exports.showSidebar = (item) => ({
  type: constants.SHOW_SIDEBAR,
  item,
});

const closeSidebar = () => ({
  type: constants.CLOSE_SIDEBAR,
});

const getItemsSuccess = (items) => ({
  type: constants.GET_ITEMS_SUCCESS,
  items,
});

const getItemsRequest = (offset, limit, col, order) => ({
  type: constants.GET_ITEMS_REQUEST,
  offset,
  limit,
  sortBy: {
    col,
    order,
  },
});

module.exports.getItems = (offset, limit, orderBy, sort, ownerFilter,
  searchFilter) => (dispatch) => {
  dispatch(getItemsRequest(offset, limit, orderBy, sort));

  const options = {
    offset,
    limit,
    orderBy,
    sort,
    ownerFilter,
    searchFilter,
  };

  $.when(AllocationRequestModel.getAll(options))
    .done((items) => dispatch(getItemsSuccess(items)));
};

module.exports.changeOwnerFilter = (key) => ({
  type: constants.OWNER_FILTER_CHANGE,
  key,
});

module.exports.changeSearchFilter = (key) => ({
  type: constants.SEARCH_FILTER_CHANGE,
  key,
});

const approveAllocationSuccess = (id) => ({
  type: constants.ALLOCATION_APPROVE_SUCCESS,
  id,
});

const approveAllocationRequest = (id) => ({
  type: constants.ALLOCATION_APPROVE_REQUEST,
  id,
});

module.exports.approveAllocation = (id) => (dispatch) => {
  dispatch(approveAllocationRequest(id));

  $.when(AllocationRequestModel.approve(id))
    .done(() => {
      dispatch(approveAllocationSuccess(id));
      dispatch(hideModal());
    })
    .fail(() => {
      dispatch(showOutOfSyncModal());
    });
};

const rejectAllocationSuccess = (id) => ({
  type: constants.ALLOCATION_REJECT_SUCCESS,
  id,
});

const rejectAllocationRequest = (id) => ({
  type: constants.ALLOCATION_REJECT_REQUEST,
  id,
});

module.exports.rejectAllocation = (id) => (dispatch) => {
  dispatch(rejectAllocationRequest(id));

  $.when(AllocationRequestModel.reject(id))
    .done((response) => {
      dispatch(rejectAllocationSuccess(id));
      dispatch(applySolvedConflicts(response));
      dispatch(hideModal());
    })
    .fail(() => {
      dispatch(showOutOfSyncModal());
    });
};

const updateAllocationSuccess = (item) => ({
  type: constants.ALLOCATION_UPDATE_SUCCESS,
  item,
});

const updateAllocationRequest = (id) => ({
  type: constants.ALLOCATION_UPDATE_REQUEST,
  id,
});

module.exports.updateAllocation = (id, changes) => (dispatch) => {
  dispatch(updateAllocationRequest(id));

  $.when(AllocationRequestModel.update(id, changes))
    .done((response) => {
      dispatch(updateAllocationSuccess(response.item));
      dispatch(applySolvedConflicts(response.solved_conflicts));
      dispatch(closeSidebar());
    })
    .fail((error) => {
      dispatch(closeSidebar());
      if (error && error.error_key === 'not-plan-upon-approval') {
        dispatch(showPlanUponApprovalErrorModal());
      } else {
        dispatch(showOutOfSyncModal());
      }
    });
};

const createAllocationSuccess = (item) => ({
  type: constants.ALLOCATION_CREATE_SUCCESS,
  item,
});

const createAllocationRequest = () => ({
  type: constants.ALLOCATION_CREATE_REQUEST,
});

module.exports.createAllocation = (item) => (dispatch) => {
  dispatch(createAllocationRequest());

  $.when(AllocationRequestModel.create(item))
    .done((response) => {
      dispatch(createAllocationSuccess(response));
      dispatch(closeSidebar());
    });
};

const deleteAllocationSuccess = (id) => ({
  type: constants.ALLOCATION_DELETE_SUCCESS,
  id,
});

const deleteAllocationRequest = () => ({
  type: constants.ALLOCATION_DELETE_REQUEST,
});

module.exports.deleteAllocation = (id) => (dispatch) => {
  dispatch(deleteAllocationRequest());

  $.when(AllocationRequestModel.delete(id))
    .done((response) => {
      dispatch(deleteAllocationSuccess(id));
      dispatch(applySolvedConflicts(response));
      dispatch(hideModal());
      dispatch(closeSidebar());
    })
    .fail(() => {
      dispatch(closeSidebar());
      dispatch(showOutOfSyncModal());
    });
};

module.exports.hideModal = hideModal;
module.exports.closeSidebar = closeSidebar;
