const moment = require('moment');
const PeriodicityModel = require('@models/PeriodicityModel');
const updateUrl = require('../services/updateUrl');
const DateService = require('../../../../../../services/DateService');
const constants = require('./constants');

module.exports.updateHours = (projectId, areaId, hours) => ({
  type: constants.HOURS_UPDATE,
  project_id: projectId,
  area_id: areaId,
  hours,
});

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

module.exports.openNoteModal = (project) => ({
  type: constants.NOTE_OPEN_MODAL,
  project,
});

const openConfirmDeleteModal = (project) => ({
  type: constants.CONFIRM_DELETE_OPEN_MODAL,
  project,
});

const saveNoteWeeklySuccess = (projectId, note) => ({
  type: constants.NOTE_SAVE_SUCCESS,
  project_id: projectId,
  note,
});

const saveNoteRequest = () => ({
  type: constants.NOTE_SAVE_REQUEST,
});

module.exports.saveNoteWeekly = (project) => (dispatch) => {
  dispatch(saveNoteRequest());
  Wethod.request('save:friday:timesheet', project)
    .then(() => {
      dispatch(saveNoteWeeklySuccess(project.project.id, project.notes));
    });
};

const saveHoursWeeklySuccess = (project, responseId) => ({
  type: constants.HOURS_SAVE_SUCCESS,
  project,
  id: responseId,
});

const saveHoursWeeklyRequest = (project) => ({
  type: constants.HOURS_SAVE_REQUEST,
  project,
});

module.exports.saveHoursWeekly = (project) => (dispatch) => {
  dispatch(saveHoursWeeklyRequest(project));
  Wethod.request('save:friday:timesheet', project)
    .then((response) => {
      dispatch(saveHoursWeeklySuccess(project, response.id));
    });
};

const deleteHoursWeeklySuccess = (project) => ({
  type: constants.HOURS_DELETE_SUCCESS,
  project,
});

module.exports.deleteHoursWeekly = (project, force) => {
  if (!force && project.notes && project.notes.trim() !== '') {
    return (dispatch) => {
      dispatch(openConfirmDeleteModal(project));
    };
  }
  return (dispatch) => {
    dispatch(saveHoursWeeklyRequest(project));
    Wethod.request('delete:friday:timesheet', project)
      .then(() => {
        dispatch(deleteHoursWeeklySuccess(project));
        dispatch(closeModal());
      });
  };
};

const changeDateSuccess = (date, projects, employee, employeeHolidays) => ({
  type: constants.DATE_CHANGE_SUCCESS,
  date,
  projects,
  employee,
  employeeHolidays,
});

const changeDateRequest = () => ({
  type: constants.DATE_CHANGE_REQUEST,
});

module.exports.changeDateWeekly = (date, userId) => {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  updateUrl(formattedDate, userId);
  return (dispatch) => {
    dispatch(changeDateRequest());
    const currentEmployeeId = userId || Wethod.userInfo.attributes.employee_id;
    $.when(Wethod.request('get:friday:timesheet:coll', formattedDate, userId), DateService.getHolidayForWeekRequest(formattedDate, currentEmployeeId))
      .then((response, employeeHolidays) => {
        const projects = response.get('projects');
        const employee = response.get('employee');
        dispatch(changeDateSuccess(date, projects, employee, employeeHolidays));
      });
  };
};

module.exports.changeDay = (date) => {
  updateUrl(date);
  return {
    type: constants.DAY_CHANGE,
    date,
  };
};

const addFavoriteRequest = (projectId) => ({
  type: constants.FAVORITE_ADD_REQUEST,
  project_id: projectId,
});

const addFavoriteSuccess = (response) => ({
  type: constants.FAVORITE_ADD_SUCCESS,
  project_id: response.id_project,
  favorite_id: response.id,
});

module.exports.addFavorite = (projectId) => (dispatch) => {
  dispatch(addFavoriteRequest(projectId));
  Wethod.request('save:friday:timesheet:favorite', {
    id_employee: Wethod.userInfo.get('employee_id'),
    id_project: projectId,
  })
    .then((response) => {
      dispatch(addFavoriteSuccess(response.toJSON()));
    });
};

module.exports.removeFavorite = (projectId, favoriteId) => {
  Wethod.request('delete:friday:timesheet:favorite', { id: favoriteId });
  return {
    type: constants.FAVORITE_REMOVE,
    project_id: projectId,
  };
};

module.exports.filterProjects = (keyword) => ({
  type: constants.FILTER_PROJECTS,
  keyword,
});

const getPeriodicitySuccess = (periodicity) => ({
  type: constants.GET_PERIODICITY_SUCCESS,
  periodicity,
});

const getPeriodicityRequest = () => ({
  type: constants.GET_PERIODICITY_REQUEST,
});

module.exports.getPeriodicity = (userId) => (dispatch) => {
  dispatch(getPeriodicityRequest());
  const model = new PeriodicityModel({ employeeId: userId });

  $.when(model.getModel())
    .done((periodicity) => dispatch(getPeriodicitySuccess(periodicity.value)));
};

const updatePeriodicitySuccess = (periodicity) => ({
  type: constants.GET_PERIODICITY_SUCCESS,
  periodicity,
});

const updatePeriodicityRequest = () => ({
  type: constants.GET_PERIODICITY_REQUEST,
});

module.exports.updatePeriodicity = (periodicity, userId) => (dispatch) => {
  dispatch(updatePeriodicityRequest());
  const model = new PeriodicityModel({
    value: periodicity,
    employeeId: userId,
  });

  $.when(model.updateModel())
    .done(() => dispatch(updatePeriodicitySuccess(periodicity)));
};

module.exports.changeBuFilter = (units) => ({
  type: constants.CHANGE_BU_FILTER,
  bu: units,
});

module.exports.closeModal = closeModal;
