const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const MonthSelect = require('@common/inputs/MonthPicker/BasicMonthPicker/BasicMonthPicker.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');

const StartCell = ({
  updateErrors, readOnly, value, onChange, onFocus, projectId, error, name, label, style,
  metadataService,
}) => (
  <TableCell className="pipeline-item__start" style={style}>
    <InputValidator updateErrors={updateErrors} constraints={['required']}>
      <InputMetadataManager metadataService={metadataService}
        name={name}
        canEdit={!readOnly}>
        <MonthSelect
          label={label}
          onFocus={onFocus}
          onChange={onChange}
          disableBeforeEqual={metadataService.getConstraintValue(name, 'greater_than')}
          value={value}
          id={`${projectId}-start`}
          errorText={error} />
      </InputMetadataManager>
    </InputValidator>
  </TableCell>
);

module.exports = StartCell;
