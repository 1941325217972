/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign */
const React = require('react');
const NumericInput = require('@common/NumericInput.react');
const TableCell = require('@common/Table2/TableCell.react');

module.exports = class ProjectMonthEditable extends React.Component {
  getBorderStyle() {
    return this.props.isBoundary ? 'dashed' : 'solid';
  }

  getStyle() {
    if (this.props.isProjectMonth) {
      return {
        borderBottomStyle: this.getBorderStyle(),
        borderBottomColor: 'rgba(72,165,249,1)',
      };
    }
  }

  getDelta() {
    if (this.props.delta) {
      const delta = Math.round(this.props.delta * 10) / 10;
      if (delta !== 0) {
        const formattedDelta = numeral(delta).format('+0,0.[0]');
        return <span className="production-plan-project__month-delta"><span>{formattedDelta}</span></span>;
      }
    }

    return null;
  }

  onChange(name, value) {
    const newAmount = parseFloat(value);
    const oldAmount = this.getAmount();
    if (oldAmount !== newAmount) {
      this.props.editMonth({
        amount: Number.isNaN(newAmount) ? 0 : newAmount,
        delta: this.props.delta,
        month: this.props.month,
      });
    }
  }

  getAmount() {
    return Math.round(this.props.amount * 10) / 10;
  }

  getProductionValue() {
    const amount = this.getAmount();
    return (this.props.totalProductionValue / 100) * amount;
  }

  getValue() {
    const value = this.getProductionValue();
    if (value) return numeral(value).format('0,0');
    return '';
  }

  onDragStart(e) {
    const data = {
      amount: this.props.amount,
      month: this.props.month,
      project_id: this.props.projectId,
    };
    const dragImage = e.target.cloneNode(true);
    dragImage.classList.add('production-plan-project__month--mirror');
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, dragImage.offsetWidth / 2, 0);
    e.dataTransfer.setData('wethod/production-plan-month', JSON.stringify(data));
    e.dataTransfer.dropEffect = 'move';
  }

  onDragOver(e) {
    if ($(e.target).hasClass('production-plan-project__month--editable')) {
      e.preventDefault();
    }
  }

  onDrop(e) {
    const isProject = e.dataTransfer.getData('wethod/production-plan-project') !== '';
    if (!isProject) {
      const data = JSON.parse(e.dataTransfer.getData('wethod/production-plan-month'));
      const projectId = parseInt(e.target.parentElement.getAttribute('data-project-id'));
      const dragImage = document.querySelector('.production-plan-project__month--mirror');

      dragImage.remove();
      if (data.project_id === projectId) {
        e.preventDefault();
        e.target.classList.remove('production-plan-project__month--target-over');
        const from = data.month;
        const to = this.props.month;
        if (from !== to) {
          this.props.move(from, to);
        }
      }
    } else {
      const data = JSON.parse(e.dataTransfer.getData('wethod/production-plan-project'));
      const projectId = parseInt(e.target.parentElement.getAttribute('data-project-id'));
      const dragImage = document.querySelector('.production-plan-project--mirror');

      dragImage.remove();
      if (data.project_id === projectId) {
        e.preventDefault();
        e.target.classList.remove('production-plan-project__month--target-over');
        const from = data.date_end;
        const to = this.props.month;
        if (from !== to) {
          this.props.moveProject(from, to);
        }
      }
    }
  }

  onDragEnter(e) {
    if ($(e.target).hasClass('production-plan-project__month--editable')) {
      e.target.classList.add('production-plan-project__month--target-over');
    }
  }

  onDragLeave(e) {
    e.target.classList.remove('production-plan-project__month--target-over');
  }

  onDragProjectStart(e) {
    e.stopPropagation();
    this.props.onDragProjectStart(e);
  }

  getProjectHandle() {
    if (this.props.isLastProjectMonth) {
      return (
        <span
          className="production-plan-project__handle"
          draggable="true"
          onDragStart={this.onDragProjectStart.bind(this)}>
          <span className="wethod-icon-draggable" />
        </span>
      );
    }
    return null;
  }

  getClassName() {
    let name = 'production-plan-project__month production-plan-project__month--editable';
    if (this.props.isProjectMonth) {
      name += ' production-plan-project__month--in-duration';
    }
    return name;
  }

  render() {
    return (
      <TableCell
        className={this.getClassName()}
        style={this.getStyle()}
        draggable="true"
        onDragOver={this.onDragOver}
        onDragStart={this.onDragStart.bind(this)}
        onDragEnter={this.onDragEnter.bind(this)}
        onDragLeave={this.onDragLeave.bind(this)}
        onDrop={this.onDrop.bind(this)}>
        <span className="production-plan-project__month-content" data-month={this.props.date}>
          <span
            className="production-plan-project__month-handle wethod-icon-draggable wethod-icon-draggable--black" />
          {this.getDelta()}
          <span className="production-plan-project__month-value">
            <NumericInput
              name="amount"
              value={this.getAmount()}
              onChange={this.onChange.bind(this)} />
            <span
              className="production-plan-project__month-value production-plan-project__month-value--production">
              {this.getValue()}
            </span>
          </span>
          {this.getProjectHandle()}
        </span>
      </TableCell>
    );
  }
};
