const React = require('react');
const isEqual = require('react-fast-compare');
const MultipleSelect = require('@common/MultipleSelectFilter/MultipleSelectFilter.react');

module.exports = class JobOrderCategoriesSelect extends React.Component {
  /**
   * Remove all the references to the given template from the list of job order categories.
   * @param jocList
   * @param templateId
   * @returns {array}
   */
  static removeRefToTemplate(jocList, templateId) {
    return jocList.map((joc) => {
      const isAssociatedToTemplate = joc.job_order_template
        && joc.job_order_template.id === templateId;

      const jobOrderTemplate = isAssociatedToTemplate ? null : joc.job_order_template;

      return {
        ...joc,
        job_order_template: jobOrderTemplate,
      };
    });
  }

  /**
   * Add the reference to the given template to the list of job order categories,
   * removing the ref to the old template.
   * @param jocList
   * @param templateId
   * @param templateJocList
   * @param oldTemplateId
   * @returns {array}
   */
  static updateRefToTemplate(jocList, templateId, templateJocList, oldTemplateId) {
    if (!templateId || !templateJocList) {
      return jocList;
    }

    let updatedJocList = jocList;

    // Reset the old ref from the list of job order categories
    if (oldTemplateId) {
      updatedJocList = this.removeRefToTemplate(jocList, oldTemplateId);
    }

    // Add the ref to the given template
    return updatedJocList.map((joc) => {
      const isUsedByTemplate = templateJocList
        .some((templateJoc) => templateJoc.id === joc.id);

      const jobOrderTemplate = isUsedByTemplate ? { id: templateId } : joc.job_order_template;

      return {
        ...joc,
        job_order_template: jobOrderTemplate,
      };
    });
  }

  static isJocListChanged(jocList, oldJocList) {
    return !isEqual(jocList, oldJocList);
  }

  /**
   * Check for errors in the list of job order categories.
   * A list is not valid if it is empty.
   * @param jocList
   * @returns {string|null}
   */
  static validateJobOrderCategoriesList(jocList) {
    const isEmpty = !jocList || jocList.length === 0;

    return isEmpty ? 'No job order category selected' : null;
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    // Update joc list to set the ref to the template
    const updatedJocList = JobOrderCategoriesSelect.updateRefToTemplate(
      this.props.jobOrderCategories,
      this.props.template.id,
      this.props.template.job_order_categories,
    );
    this.updateJobOrderCategoriesList(updatedJocList);

    // Check for errors
    this.handleErrors(this.props.template.job_order_categories);
  }

  componentWillUnmount() {
    // Remove ref to template from joc list
    const updatedJocList = JobOrderCategoriesSelect
      .removeRefToTemplate(this.props.jobOrderCategories, this.props.template.id);
    this.updateJobOrderCategoriesList(updatedJocList);
  }

  /**
   * Validate the given list and updates the errors given to the parent.
   * @param jocList
   */
  handleErrors(jocList) {
    const error = JobOrderCategoriesSelect.validateJobOrderCategoriesList(jocList);

    if (error) {
      const formattedError = [{
        message: error,
      }];
      // Update errors list
      this.props.onError('job_order_categories', formattedError);
    } else {
      // Reset errors list
      this.props.onError('job_order_categories', []);
    }
  }

  onJocListChange(name, values) {
    const jocList = this.getJocListFromIds(values);
    // Update the list of job order categories with the ref to the template: first we remove
    // every ref we previously set, in order to have the updated list of jocs
    const updatedJocList = JobOrderCategoriesSelect.updateRefToTemplate(
      this.props.jobOrderCategories, this.props.template.id, jocList, this.props.template.id,
    );
    this.updateJobOrderCategoriesList(updatedJocList);

    this.props.handleTemplateChange('job_order_categories', jocList);

    // Check for errors
    this.handleErrors(jocList);
  }

  getJocListFromIds(idList) {
    return idList.map((id) => {
      const jobOrderCategory = this.props.jobOrderCategories.find((joc) => joc.id === id);
      return { id: jobOrderCategory.id, name: jobOrderCategory.name };
    });
  }

  getSelectedJocList() {
    return this.props.template.job_order_categories.map((joc) => joc.id);
  }

  /**
   * Return the list of job order categories that can be selected for this template.
   * The jocs already associated to another template cannot be used again.
   * @returns {array}
   */
  getAvailableJocList() {
    return this.props.jobOrderCategories.filter((joc) => !this.isJocUsedByOtherTemplates(joc));
  }

  getFormattedJocList() {
    return ({
      label: 'Select all',
      value: 'all',
      children: this.getAvailableJocList()
        .map((joc) => ({ label: joc.name, value: joc.id, children: [] })),
    });
  }

  getAcceptedJocList() {
    return this.props.jobOrderCategories.map((joc) => joc.id);
  }

  updateJobOrderCategoriesList(jocList) {
    if (JobOrderCategoriesSelect.isJocListChanged(jocList, this.props.jobOrderCategories)) {
      this.props.onJobOrderCategoryChange(jocList);
    }
  }

  /**
   * Check whether the given job order category is associated to another job order template.
   * @param joc
   * @returns {boolean}
   */
  isJocUsedByOtherTemplates(joc) {
    return joc && joc.job_order_template != null
      && joc.job_order_template.id !== this.props.template.id;
  }

  render() {
    return (
      <MultipleSelect
        label="Job order categories"
        values={this.getSelectedJocList()}
        availableOptions={this.getFormattedJocList()}
        acceptedValues={this.getAcceptedJocList()}
        onApply={this.onJocListChange.bind(this)}
        name={`jo-template-joc-${this.props.template.id}`} />
    );
  }
};
