const React = require('react');
const { connect } = require('react-redux');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const FileSection = require('@common/FileSection/FileSection.react');
const Empty = require('@common/FileSection/Empty.react');
const actions = require('../../actions');

const accepted = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.google-apps.spreadsheet',
  'application/vnd.apple.numbers',
];

class ImportMetadataModal extends React.Component {
  static onClose() {
    modalService.closeModal();
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      file: [],
      error: null,
    };
    this.onOk = this.onOk.bind(this);
  }

  handleUpload(file) {
    this.setState({
      error: null,
      file: [file],
    });
  }

  handleUploadError() {
    this.setState({ error: 'File not accepted, please try again' });
  }

  handleFileDelete() {
    this.setState({ file: [] });
  }

  onOk() {
    this.setState({ isLoading: true });
    this.props.importMetadata(this.state.file[0])
      .always(() => {
        this.setState({ isLoading: false });
      })
      .done(() => {
        this.props.getItems();
        this.props.onSuccess?.();
        ImportMetadataModal.onClose();
      })
      .fail((response) => {
        if (typeof response === 'string') {
          this.setState({ error: response });
        } else
        if (typeof response?.[0].error === 'string') {
          let { error } = response[0];
          if (Array.isArray(response[0]?.rows)) {
            error += response[0].rows.reduce((acc, row) => `${acc}${row} `, '. Rows: ');
          }
          this.setState({ error });
        }
      });
  }

  render() {
    return (
      <Modal onClose={ImportMetadataModal.onClose} title="Import Metadata">
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              <FileSection
                className="team-import-metadata-modal__file-section"
                title={null}
                canDownload={false}
                canUpload={!this.state.file.length}
                canDelete
                accept={accepted}
                empty={<Empty>Upload .xls or .xlxs files only.</Empty>}
                onDelete={this.handleFileDelete.bind(this)}
                files={this.state.file}
                onUpload={this.handleUpload.bind(this)}
                onUploadError={this.handleUploadError.bind(this)} />
            </ModalBodyBlock>
          </ModalBody>
          <ModalFooter
            error={this.state.error}
            feedback={this.state.isLoading ? 'Saving...' : null}>
            <ModalFooterAction
              onClick={ImportMetadataModal.onClose}
              disabled={this.state.isLoading}>
              Cancel
            </ModalFooterAction>
            <ModalFooterAction
              onClick={this.onOk}
              disabled={this.state.isLoading}>
              Import
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

module.exports = connect(null, {
  getItems: actions.getItems,
  importMetadata: actions.importMetadata,
})(ImportMetadataModal);
