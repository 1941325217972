const React = require('react');
const BuFilter = require('@common/BusinessUnitSelectFilter/BusinessUnitSelectFilter.react');
const List = require('../containers/ProjectList');
const BusinessUnitService = require('../../../../../../../services/BusinessUnitService');

module.exports = class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.businessUnitService = new BusinessUnitService();

    this.state = {
      buEnabled: this.businessUnitService.isEnabled(),
      buFilter: this.businessUnitService.getLastSelected(),
    };

    this.headerHeight = 30;
  }

  getBuFilter() {
    if (this.state.buEnabled) {
      return (
        <div className="supplier-projects__bu-select">
          <BuFilter onApply={this.changeBusinessUnit.bind(this)}
            selectedValues={this.state.buFilter} />
        </div>
      );
    }
    return null;
  }

  changeBusinessUnit(name, units) {
    this.setState({ buFilter: units });
  }

  render() {
    return (
      <div className="supplier-projects">
        <div className="supplier-projects__header">
          <h2 className="supplier-projects__header-title">Projects</h2>
          {this.getBuFilter()}
        </div>
        <List tableHeight={this.props.tableHeight - this.headerHeight}
          buFilter={this.state.buFilter}
          buEnabled={this.state.buEnabled} />
      </div>
    );
  }
};
