const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const { getLanguageLevels } = require('../../../../services/LanguageService');
const Employee = require('../containers/EmployeeLangRow');

module.exports = class EmployeesLangTable extends React.Component {
  static getLevels() {
    return getLanguageLevels()
      .map((lvl) => <span className="knowledge__column-level-label" key={lvl.id}>{lvl.name}</span>);
  }

  getLoadedEmployees() {
    return this.props.employees.slice(0, this.props.page * this.props.size);
  }

  getEmployees() {
    const employees = this.getLoadedEmployees();

    return employees.map((employee) => (
      <Employee key={employee.id}
        employee={employee} />
    ));
  }

  hasMore() {
    const loaded = this.props.page * this.props.size;
    return this.props.employees.length > loaded;
  }

  render() {
    return (
      <Table columns={3} maxHeight={this.props.maxHeight}>
        <TableHead>
          <TableRow>
            <TableCell name="name" className="knowledge__column-employee knowledge__column-employee-lang">Team member</TableCell>
            <TableCell name="level" className="knowledge__column-level">
              {EmployeesLangTable.getLevels()}
            </TableCell>
            <TableCell name="link" className="knowledge__column-link" />
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.hasMore()}
          loadMore={this.props.loadMore}
          empty="No employees found">
          {this.getEmployees()}
        </TableBody>
      </Table>
    );
  }
};
