const React = require('react');
const T = require('@common/Typography/Typography.react');
const Header = require('../../../company/components/SubsectionHeader.react');
const Actions = require('../../containers/PersonalApiToken/Actions');
const List = require('../../containers/PersonalApiToken/TokenList');

const PersonalApiToken = () => (
  <div>
    <Header subtitle="Personal API Token"
      id="personal-api-token"
      description={(
        <span>
          Here you can create and revoke personal API tokens.
          <T weight={T.WEIGHTS.BOLD} size={T.SIZES.INHERIT}>
            Bear in mind: keep your API access secure, as it can be used to access your data in
            wethod.
          </T>
        </span>
)} />
    <Actions />
    <List />
  </div>
);

module.exports = PersonalApiToken;
