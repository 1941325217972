const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

const ConfirmDeleteModal = ({ onClose, data, isSaving, onDelete }) => {
  function handleDelete() {
    if (!isSaving) {
      onDelete(data);
    }
  }

  const getBody = () => (
    <div>
      <p>
        You are about to delete this expense.
      </p>
      <p>
        Are you sure you want to continue?
      </p>
    </div>
  );

  return (
    <Modal title="Delete expense" onCancelClick={onClose} className="expenses__modal">
      {getBody()}
      <div className="wethod-modal2__actions">
        <Button onClick={onClose}>Not yet</Button>
        <Button onClick={handleDelete}
          disabled={isSaving}>
          Yes, delete
        </Button>
      </div>
      <ShowIf condition={isSaving}>
        <div className="wethod-modal2__feedback">Saving...</div>
      </ShowIf>
    </Modal>
  );
};

module.exports = ConfirmDeleteModal;
