const React = require('react');
const Typography = require('@common/Typography/Typography.react');

const DashboardEmptyBody = ({ src, alt, title, description }) => (
  <div className="data-freezing-engine__dashboard-empty-body">
    <img src={src} alt={alt} className="data-freezing-engine-mb--m" />
    <Typography weight={Typography.WEIGHTS.SEMIBOLD} className="data-freezing-engine-mb--xxs">
      {title}
    </Typography>
    <Typography color={Typography.COLORS.NOBEL_GREY}>{description}</Typography>
  </div>
);

module.exports = DashboardEmptyBody;
