const React = require('react');
const { CSSTransition } = require('react-transition-group');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const TemplatesTable = require('../containers/BudgetTemplateList');
const Sidebar = require('../containers/sidebar/BudgetTemplateSidebar');

module.exports = class BudgetTemplates extends React.Component {
  componentDidMount() {
    this.props.getTemplates();
  }

  render() {
    return (
      <div className="budget-template wethod-section-body">
        <SectionHeader current_section="Budget Templates" helper_url="budget/index/#create-a-template" />
        <TemplatesTable />
        <CSSTransition
          in={this.props.showSidebar}
          classNames="wethod-sidebar--transition"
          timeout={400}
          mountOnEnter
          unmountOnExit>
          <Sidebar />
        </CSSTransition>
      </div>
    );
  }
};
