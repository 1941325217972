const React = require('react');
const Typography = require('@common/Typography/Typography.react');
const FilledTag = require('@common/Tag/FilledTag.react');
const { LABEL, STATUS } = require('../../segmentsStatus');

const TimelineLegend = () => {
  const labels = () => Object.values(STATUS).map((s) => (
    <div key={s} className="segment-timeline__legend-item">
      <FilledTag backgroundColor={TimelineLegend.statusColor[s]}
        borderRadius={FilledTag.BORDER_RADIUS.ROUNDED}>
        {' '}
      </FilledTag>
      <Typography size={Typography.SIZES.PX11} color={Typography.COLORS.DUSTY_GRAY}>
        {LABEL[s]}
      </Typography>
    </div>
  ));

  return (
    <div className="segment-timeline__legend">
      {labels()}
    </div>
  );
};

TimelineLegend.statusColor = {
  [STATUS.FREE]: FilledTag.COLORS.GARDEN_GREEN,
  [STATUS.IN_REVIEW]: FilledTag.COLORS.BRIGHT_SKY_BLUE,
  [STATUS.PAUSED]: FilledTag.COLORS.SUNSET_ORANGE,
  [STATUS.FROZEN]: FilledTag.COLORS.DUSTY_GRAY,
};

module.exports = TimelineLegend;
