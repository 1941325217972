/* eslint-disable react/sort-comp,class-methods-use-this,react/no-access-state-in-setstate */

const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Body = require('./ProjectAreasBody.react');

module.exports = class ProjectAreasModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      areas: this.props.data.areas,
      risk: this.props.data.risk,
    };
  }

  formatDaysLeft(value) {
    const daysLeft = parseFloat(value);
    return Number.isNaN(daysLeft) ? null : daysLeft;
  }

  onSaveClick() {
    if (!this.isWaitingForProject()) {
      const areas = this.state.areas.map((area) => ({
        id: area.id,
        uid: area.uid,
        days_left: this.formatDaysLeft(area.days_left),
      }));

      const data = {
        date: moment(this.props.data.date).format('YYYY-MM-DD'),
        areas,
        project: this.props.data.project.id,
      };
      this.props.save(data);
    }
  }

  onCancelClick() {
    if (!this.isWaitingForProject()) {
      this.props.onCancelClick();
    }
  }

  onAreaChange(id, daysLeft) {
    this.setState({
      areas: this.state.areas.map((area) => {
        if (area.id === id) {
          return {
            ...area,
            days_left: daysLeft,
          };
        }
        return area;
      }),
    });
  }

  onRiskChange(risk) {
    this.setState({ risk });
  }

  getBodyData() {
    return {
      ...this.props.data,
      areas: this.state.areas,
      risk: this.state.risk,
    };
  }

  isWaitingForProject() {
    return this.props.isSaving.filter((key) => key === this.props.data.project.id).length > 0;
  }

  getActionsStyle(isSaving) {
    let style = 'wethod-button';
    if (isSaving) {
      style += ' disabled';
    }
    return style;
  }

  getFeedback() {
    if (this.isWaitingForProject()) {
      return <div className="profile-contact-info__feedback">Saving...</div>;
    }
    return null;
  }

  render() {
    const isSaving = this.isWaitingForProject();

    return (
      <Modal title="Project status"
        onCancelClick={this.onCancelClick.bind(this)}
        className="project-status-alert">
        <Body {...this.getBodyData()}
          onAreaChange={this.onAreaChange.bind(this)}
          onRiskChange={this.onRiskChange.bind(this)}
          isSaving={isSaving} />
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.onCancelClick.bind(this)}
            className={this.getActionsStyle(isSaving)}>Cancel
          </button>
          <button type="button"
            onClick={this.onSaveClick.bind(this)}
            className={this.getActionsStyle(isSaving)}>Save
          </button>
        </div>
        {this.getFeedback()}
      </Modal>
    );
  }
};
