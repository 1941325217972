/* eslint-disable react/jsx-no-bind */
const React = require('react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const Item = require('@common/Menu/ListMenu/MenuItemColorful.react');

module.exports = ({
  anchorEl, onClose, items, saveRisk, data,
}) => {
  const onClick = (id) => {
    const prevRisk = data.risk;
    if (prevRisk.id !== id) {
      const options = {
        date: moment(data.date).format('YYYY-MM-DD'),
        risk: id,
        project: data.project.id,
      };
      saveRisk(options);
    }
  };

  const show = anchorEl !== null;

  const getItems = () => items.map((item) => (
    <Item
      key={item.id}
      onClick={() => onClick(item.id)}
      color={item.color}>
      {item.name}
    </Item>
  ));

  return (
    <Menu open={show} anchorEl={anchorEl} onClose={onClose} centered>
      {getItems()}
    </Menu>
  );
};
