const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const FiscalYearService = require('../../../../../../services/FiscalYearService');

const ExportModal = ({ data, isWaiting, onClose, exportModule }) => {
  const fiscalYearService = new FiscalYearService();

  function handleConfirm() {
    exportModule(data.year);
  }

  return (
    <Modal title="Export" onCancelClick={onClose} className="time-off__modal">
      <p>
        {/* eslint-disable-next-line max-len */}
        The year you selected for the export is: <b>{fiscalYearService.getYearDynamically(data.year)}</b>.<br /><br />
        You will receive the export in your mailbox.
      </p>
      <div className="wethod-modal2__actions">
        <Button onClick={onClose} disabled={isWaiting}>Cancel</Button>
        <Button onClick={handleConfirm} disabled={isWaiting}>
          Email to me
        </Button>
      </div>
      <ShowIf condition={isWaiting}>
        <div className="wethod-modal2__feedback">Sending...</div>
      </ShowIf>
    </Modal>
  );
};

module.exports = ExportModal;
