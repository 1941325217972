/* eslint-disable react/sort-comp,class-methods-use-this,consistent-return */
const React = require('react');
const moment = require('moment');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const OpenSidebarButton = require('@common/OpenSidebarButton.react');

module.exports = class Opportunity extends React.Component {
  getStatus() {
    if (this.props.opportunity.project_id) {
      return (
        <div className="basket-table__checked">
          <div className="wethod-icon-medium-checked" />
        </div>
      );
    }
    return (
      <button type="button"
        className="wethod-button"
        onClick={this.handleProjectAdd.bind(this)}>
        + Project
      </button>
    );
  }

  getCompany() {
    if (this.props.opportunity.client) {
      return (
        <a className="basket-table__column--name-company"
          href={`#finance/client/company/${this.props.opportunity.client.id}`}>
          {this.props.opportunity.client.name}
        </a>
      );
    }
  }

  getNameCell() {
    return (
      <div>
        <div>{this.props.opportunity.name}</div>
        {this.getCompany()}
      </div>
    );
  }

  getAccountName() {
    return this.props.opportunity.account && this.props.opportunity.account.name
      ? `${this.props.opportunity.account.name.slice(0, 1)}. ${this.props.opportunity.account.surname}`
      : '';
  }

  getDotClassName() {
    let style = 'basket__due-date-priority-dot';

    const dueDate = moment(this.props.opportunity.due_date);
    const period = moment(dueDate).diff(moment(), 'days');

    if (period < 0) {
      style += ' basket__due-date-priority-dot--high';
    } else if (period < 3) {
      style += ' basket__due-date-priority-dot--low';
    }
    return style;
  }

  getDate() {
    if (this.props.opportunity.due_date && !this.props.opportunity.project_id) {
      return (
        <div>
          <span className={this.getDotClassName()} />
          <span className="basket__due-date">
            {moment(this.props.opportunity.due_date).format('DD/MM/YYYY')}
          </span>
        </div>
      );
    }
    return '';
  }

  getValue() {
    const formattedValue = this.props.getFormattedValue();
    return formattedValue || 0;
  }

  getValueStyle() {
    let style = 'basket-table__column--target';

    // Add suffix 'K'
    if (!this.props.isValueAsUnit) {
      style += ' basket-table__column--target-value';
    }
    return style;
  }

  handleProjectAdd() {
    const opportunity = {
      project: {
        id: null,
        name: this.props.opportunity.name,
        estimate: this.props.opportunity.value || 0,
        probability: 10,
        external_cost: 0,
      },
      account: {
        id: this.props.opportunity.account.id,
        name: this.props.opportunity.account.name,
        surname: this.props.opportunity.account.surname,
      },
      client: {
        id: this.props.opportunity.client.id,
        corporate_name: this.props.opportunity.client.name,
      },
      basket_id: this.props.opportunity.id,
    };

    this.props.addProject(opportunity);
  }

  handleSidebarOpen() {
    this.props.showSidebar(this.props.opportunity);
  }

  render() {
    return (
      <TableRow>
        <TableCell className="basket-table__column--name">{this.getNameCell()}</TableCell>
        <TableCell className="basket-table__column--account">{this.getAccountName()}</TableCell>
        <TableCell className={this.getValueStyle()}>{this.getValue()}</TableCell>
        {/* <TableCell className="basket-table__column--days">{this.props.day}</TableCell> */}
        {/* <TableCell className="basket-table__column--costs">{this.props.costs}</TableCell> */}
        <TableCell className="basket-table__column--follow-up">{this.getDate()}</TableCell>
        <TableCell className="basket-table__column--state">{this.getStatus()}</TableCell>
        <TableCell className="basket-table__column--actions"><OpenSidebarButton onClick={this.handleSidebarOpen.bind(this)} /></TableCell>
      </TableRow>
    );
  }
};
