const React = require('react');
const InfoHeader = require('@common/InfoHeader/InfoHeader.react');
const InfoHeaderDescription = require('@common/InfoHeader/Description.react');
const StatusSelect = require('../containers/StatusSelect');

const Info = ({ info, showSidebar, statusEnabled }) => {
  const description = (
    <InfoHeaderDescription title={info.name}
      showPic />
  );

  const statusSelect = statusEnabled ? <StatusSelect key="status" /> : null;

  return (
    <InfoHeader description={description}
      onOpenSidebarClick={showSidebar}
      actions={[statusSelect]} />
  );
};

module.exports = Info;
