const React = require('react');
const PropTypes = require('prop-types');
const Cleave = require('cleave.js/react');
const FormatService = require('../../../../services/FormatService');

const patternMap = {
  'HH:mm:ss': ['h', 'm', 's'],
  'HH:mm': ['h', 'm'],
  'mm:ss': ['m', 's'],
};

class TimeInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleInit = this.handleInit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.props.value !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  handleChange(e) {
    e.target.value = this.getPaddedValue(e.target.value);
    if (e.target.value !== this.props.value) {
      this.props.onChange(e);
    }
  }

  handleInit(cleave) {
    this.cleave = cleave;
    this.cleave.setRawValue(this.getPaddedValue(this.props.value));
  }

  getFormatOptions() {
    return {
      time: true,
      timePattern: this.getTimePattern(),
    };
  }

  setRef(ref) {
    this.input = ref;
  }

  getTimePattern() {
    return patternMap[this.props.timePattern];
  }

  /**
   * @param {string} value
   * @return {string}
   */
  getPaddedValue(value) {
    const timePattern = this.getTimePattern();
    let paddedValue = '';
    const valueParts = value.split(':');
    timePattern.forEach((unit, index) => {
      const separator = index > 0 ? ':' : '';
      const part = valueParts[index] ? valueParts[index].padEnd(2, '0') : '00';// Fill remaining spaces with '0'
      paddedValue = `${paddedValue}${separator}${part}`;
    });

    return paddedValue;
  }

  syncWithParent() {
    this.cleave.setRawValue(this.getPaddedValue(this.props.value));
  }

  render() {
    return (
      <Cleave htmlRef={this.setRef}
        className="wethod-input-root"
        options={this.getFormatOptions()}
        onBlur={this.props.onBlur}
        onInit={this.handleInit}
        onChange={this.handleChange}
        id={this.props.id}
        readOnly={this.props.readOnly}
        required={this.props.required}
        disabled={this.props.disabled}
        name={this.props.name} />
    );
  }
}

TimeInput.TIME_PATTERN_HMS = FormatService.DURATION_PATTERN_HMS;
TimeInput.TIME_PATTERN_MS = FormatService.DURATION_PATTERN_MS;
TimeInput.TIME_PATTERN_HM = FormatService.DURATION_PATTERN_HM;

TimeInput.defaultProps = {
  value: null,
  timePattern: TimeInput.TIME_PATTERN_HMS,
  id: undefined,
  readOnly: false,
  required: false,
  disabled: false,
  name: undefined,
  onBlur: undefined,
};

TimeInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  timePattern: PropTypes.oneOf([
    TimeInput.TIME_PATTERN_HMS,
    TimeInput.TIME_PATTERN_HM,
    TimeInput.TIME_PATTERN_MS,
  ]),
  name: PropTypes.string,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

module.exports = TimeInput;
