const React = require('react');
const moment = require('moment');
const { CSSTransition } = require('react-transition-group');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const AllocationRequestSidebar = require('../containers/sidebar/AllocationRequestSidebar');
const PermissionManager = require('../containers/PermissionManager');
const ItemManager = require('./ItemManager.react');
const SaveManager = require('../containers/SaveManager');

const Sidebar = ({ showSidebar, item }) => {
  /**
   * Return the default item with the employee information
   * @returns {{days: *[], employee: {surname, name, id}}}
   */
  function getDefaultItem() {
    return {
      employee: {
        id: Wethod.userInfo.get('employee_id'),
        name: Wethod.userInfo.get('name'),
        surname: Wethod.userInfo.get('surname'),
      },
      notes: '',
      days: [{
        date: moment().format('YYYY-MM-DD'),
        hours: 8,
      }],
    };
  }

  const nonEmptyItem = item || getDefaultItem();

  return (
    <CSSTransition in={showSidebar}
      classNames="wethod-sidebar--transition"
      timeout={400}
      mountOnEnter
      unmountOnExit>
      <FormValidator>
        <PermissionManager>
          <SaveManager>
            <ItemManager item={nonEmptyItem}>
              <AllocationRequestSidebar />
            </ItemManager>
          </SaveManager>
        </PermissionManager>
      </FormValidator>
    </CSSTransition>
  );
};

module.exports = Sidebar;
