/* eslint-disable react/no-array-index-key */
const React = require('react');
const Trend = require('@common/trend/TrendItem.react');
const Widget = require('./Widget.react');
const Chart = require('./Chart.react');
const Empty = require('./TrendEmpty.react');

module.exports = class TrendWidget extends React.Component {
  getCharts() {
    return this.props.data.criteria.map((criterion, index) => (
      <Trend
        key={index}
        title={criterion.name}
        total={criterion.avg}
        className="profile-kudos__chart"
        chart={<Chart year={this.props.data.year} scores={criterion.quarters} />} />
    ));
  }

  getContent() {
    const { data } = this.props;
    if (data) {
      return (
        <Widget title={this.props.title}>
          <div className="profile-kudos__subheader">average based on NPS</div>
          {this.getCharts()}
          <div className="profile-kudos__legend">
            <div>
              <span>Nr. of projects</span>
              <span>{data.projects_count}</span>
            </div>
            <div>
              <span>Nr. of reviews received</span>
              <span>{data.reviews_count}</span>
            </div>
          </div>
        </Widget>
      );
    }
    return (
      <Widget title={this.props.title}>
        <Empty />
      </Widget>
    );
  }

  render() {
    return (
      this.getContent()
    );
  }
};
