const React = require('react');
const Header = require('../SubsectionHeader.react');
const Actions = require('../../containers/work-hour-capacity/WorkHourCapacityActions');
const List = require('../../containers/work-hour-capacity/TableWorkHourCapacity');
const FeatureService = require('../../../../../../../services/FeatureService');

const descriptionOld = 'Define a capacity group for team members, specifying the maximum number of working hours for each day of the week.\n'
  + '8 hours of capacity correspond to 1 Full-Time Equivalent (FTE). '
  + 'Currently, this is the value used by wethod for calculations in both budget and project report.';

const description = 'Define a capacity group for team members that has a specific maximum amount of working hours per day of a week.\n\n'
  + 'You can set maximum 8 hours of capacity.';

const WorkHourCapacity = () => (
  <div className="bottomSpace">
    <Header subtitle="Employee Capacity Groups"
      id="work-hour-capacity"
      description={Wethod.featureService.isEnabled(FeatureService.FTE)
        ? description : descriptionOld} />
    <Actions />
    <List />
  </div>
);

module.exports = WorkHourCapacity;
