const React = require('react');
const Typography = require('@common/Typography/Typography.react');
const Segment = require('./TimelineSegment.react');
const status = require('../../segmentsStatus').default;
const { isMainTimeline, getTimelineLabel } = require('../../timelines');

const Timeline = ({ visibleMonths, timeline, selectedSegment, selectSegment }) => {
  const segments = timeline?.segments || [];

  const isMain = isMainTimeline(timeline?.name);

  function isSelected(segment) {
    return timeline.name === selectedSegment?.timeline
    && segment.status === selectedSegment?.status;
  }

  /**
   * Check if segment is selectable, free segments are not selectable
   * @param segment
   * @returns {boolean}
   */
  function isSelectable(segment) {
    return segment.status !== status.FREE;
  }

  function selectTimelineSegment(segmentStatus) {
    selectSegment(segmentStatus, timeline.name);
  }

  /**
   * Render timeline segments
   * The first and last segments are rendered as infinite segments
   * @returns {*}
   */
  function timelineSegments() {
    return segments.map((segment, index) => (
      <Segment key={segment.status}
        segment={{
          ...segment,
          from: index === 0 ? null : segment.from,
          to: index === segments.length - 1 ? null : segment.to,
        }}
        visibleFrom={visibleMonths[0]}
        visibleTo={visibleMonths[visibleMonths.length - 1]}
        selected={isSelected(segment)}
        selectable={isSelectable(segment)}
        onClick={selectTimelineSegment} />
    ));
  }

  function getStyle() {
    let className = 'segment-timeline__row';
    if (!isMain) {
      className += ` ${className}--secondary`;
    }
    return className;
  }

  return (
    <div className={getStyle()}>
      <Typography className="segment-timeline__label"
        weight={isMain ? Typography.WEIGHTS.BOLD : Typography.WEIGHTS.NORMAL}
        size={Typography.SIZES.PX12}>
        {getTimelineLabel(timeline?.name)}
      </Typography>
      <div className="segment-timeline__timeline">
        {timelineSegments()}
      </div>
    </div>
  );
};

module.exports = Timeline;
