const React = require('react');
const $ = require('jquery');
const Table = require('@common/Table2/Table2.react');
const TableBody = require('@common/Table2/TableBody.react');
const Loader = require('@common/Loader/Loader.react');
const { isMe } = require('../../../../../services/UserService');
const Header = require('./TimeOffListHeader.react');
const Search = require('../containers/TimeOffSearch');
const Row = require('../containers/SaveManager');

module.exports = class TimeOffList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getList() {
    return this.props.list.map((item) => (
      <Row key={item.employee.id}
        year={this.props.year}
        item={item}
        isSaving={this.isItemSaving(item.employee.id)}
        readOnly={!this.canEditItem(item.employee.id)} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    return (
      <Search filter={this.filter.bind(this)} />
    );
  }

  getDefaultOptions() {
    return {
      offset: this.props.options,
      limit: this.props.limit,
      keyword: this.props.keyword,
      year: this.props.year,
    };
  }

  getItems(options) {
    if (!this.props.isWaiting) {
      this.props.getItems(options.offset, options.limit, options.keyword, options.year);
    }
  }

  updateTableHeight() {
    this.setState({ tableHeight: TimeOffList.getTableMaxHeight() });
  }

  isItemSaving(itemId) {
    return this.props.itemsInSaving
      .filter((item) => item === itemId)
      .length > 0;
  }

  hasMore() {
    return this.props.hasMore;
  }

  loadMore(size, page) {
    const options = this.getDefaultOptions();
    options.offset = (page - 1) * size;
    options.limit = size;

    this.getItems(options);
  }

  filter(keyword) {
    const options = this.getDefaultOptions();
    options.offset = 0;
    options.keyword = keyword;

    this.getItems(options);
  }

  canEditItem(itemEmployeeId) {
    return this.props.permissions.edit
      && (isMe(itemEmployeeId) || this.props.permissions.edit_other);
  }

  render() {
    return (
      <Table columns={16} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <Header />
        <TableBody hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}
          editable={this.props.canEdit}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
