const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const ExportButton = require('@common/ExportButton/ExportButton.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Button = require('@common/Button/RoundedButton.react');
const TypeSwitcher = require('../containers/TypeSwitcher');

const Actions = ({
  offset, limit, keyword,
  showSidebar, showExportModal, getItems,
  canViewOther, canEdit,
}) => {
  function onExportClick() {
    showExportModal();
  }

  function onAddClick() {
    showSidebar();
  }

  function onTypeFilterClick(type) {
    getItems(offset, limit, keyword, type);
  }

  return (
    <div className="wethod-section-actions">
      <SectionHeader
        current_section="Expenses"
        helper_url="finance/index/#expenses"
        big />
      <div className="expenses__actions">
        <ShowIf condition={canViewOther}>
          <TypeSwitcher handleClick={onTypeFilterClick} />
        </ShowIf>
        <div className="expenses__actions--right">
          <ExportButton onClick={onExportClick} />
          <ShowIf condition={canEdit}>
            <Button onClick={onAddClick}>New expense</Button>
          </ShowIf>
        </div>
      </div>
    </div>
  );
};

module.exports = Actions;
