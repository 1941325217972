const React = require('react');
const BaseSelect = require('@common/inputs/Select/BasicSelect/BasicSelect.react');

const Select = ({ className, children, ...rest }) => {
  const customClassName = className || 'company-settings__select';

  return (
    <div className={customClassName}>
      <BaseSelect {...rest}>
        {children}
      </BaseSelect>
    </div>
  );
};

module.exports = Select;
