const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const AutocompleteModel = require('../../../../../../../../models/Autocomplete');

module.exports = class ProjectSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(item.name);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getProjectsByNameOrJobOrderAutocomplete(keyword)
      .done((projects) => this.setState({
        availableItems: projects,
        isLoading: false,
      }));
  }

  onChange(e, item) {
    let selectedValue = item
      ? this.state.availableItems.filter((project) => project.id === item.value)
      : null;

    selectedValue = selectedValue && selectedValue.length > 0 ? selectedValue[0] : null;

    this.props.onChange(e, selectedValue);
  }

  getOptions() {
    return this.state.availableItems.map((project) => (
      <SelectItem key={project.id}
        value={project.id}>
        <span>{_.unescape(project.name)}</span>
        <br />
        <span className="timesheets__secondary_info">{project.jobOrder}</span>
        <br />
        <span className="timesheets__secondary_info">{project.archived ? 'ARCHIVED' : ''}</span>
      </SelectItem>
    ));
  }

  getValue() {
    if (!this.props.value) {
      return null;
    }
    return this.props.value;
  }

  render() {
    return (
      <Autocomplete
        label={this.props.label}
        placeholder="Search project name or job order"
        onChange={this.onChange.bind(this)}
        value={this.getValue()}
        getInputValue={ProjectSelect.getInputValue}
        name={this.props.name}
        id={this.props.id}
        onFilter={this.onFilter.bind(this)}
        errorText={this.props.errorText}
        loading={this.state.isLoading}>
        {this.getOptions()}
      </Autocomplete>
    );
  }
};
