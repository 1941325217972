const React = require('react');
const IconLink = require('@common/TooltipFixed/IconLinkTooltip.react');
const BudgetFooterAction = require('./BudgetFooterAction.react');

const BudgetFooterLink = ({ noBorder, label, href, children }) => (
  <BudgetFooterAction noBorder={noBorder}>
    <IconLink label={label}
      borderless
      href={href}
      target="_blank">
      {children}
    </IconLink>
  </BudgetFooterAction>
);

module.exports = BudgetFooterLink;
