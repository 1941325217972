const React = require('react');
const Escaper = require('@common/formatters/Escaper.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');
const PropertyMetadataService = require('../../../../../services/PropertyMetadataService/PropertyMetadataService');

const LinkedPlan = ({ masterProject, project, billingGroupId, onRevoke }) => {
  // eslint-disable-next-line no-underscore-dangle
  const metadataService = new PropertyMetadataService(masterProject._fields || []);
  const canEdit = metadataService.getCanEdit('invoices');
  const disabledClass = !canEdit ? 'disabled' : '';

  function handleRevoke() {
    onRevoke(project, masterProject, billingGroupId);
  }

  return (
    <div className="invoice-plan__linked">
      <div className="invoice-plan__linked-description">
        Invoice plan delegated to
        {' '}
        <a href={`/#project/${masterProject.id}/invoice-plan`}>
          <Escaper>{masterProject.name}</Escaper>
        </a>
      </div>
      <InputMetadataManager name="invoices" canEdit metadataService={metadataService}>
        <button type="button" onClick={handleRevoke} className={`wethod-button ${disabledClass}`}>Revoke delegation</button>
      </InputMetadataManager>
    </div>
  );
};

module.exports = LinkedPlan;
