/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return */
const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const Escaper = require('@common/formatters/Escaper.react');
const OptionsCell = require('../containers/ProjectOptionsCell');
const NoteCell = require('../containers/ProjectNoteCell');
const HoursCell = require('./ProjectTotalCell.react');
const InfoCell = require('./ProjectInfoCell.react');
const TimesheetAutomaticTagCell = require('./TimesheetAutomaticTag.react');
const { isInWhitelist } = require('../../services/projectService');

module.exports = class WeeklyProject extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: false,
      project: this.props.project,
      showTooltip: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.project.hours !== prevState.project.hours) {
      this.setState({ project: this.props.project });
    }
  }

  handleHoursUpdate(value) {
    const hours = this.props.project.hours || 0;
    if (value !== hours) {
      const detail = {};
      Object.keys(this.props.project.detail).forEach((day) => detail[day] = 0);
      const updatedProject = {
        ...this.props.project,
        hours: value,
        detail,
      };
      if (value) {
        this.props.updateHours(updatedProject);
      } else {
        this.props.deleteHours(updatedProject);
      }
      this.setState({
        unsavedChanges: false,
        project: updatedProject,
      });
    }
  }

  handleMouseEnter() {
    const isTimesheetEditable = this.props.project.can_edit;
    const whitelistEditable = isInWhitelist(this.props.project.project);
    const archiveEditable = !this.props.project.project.archived;
    if (!isTimesheetEditable || !whitelistEditable || !archiveEditable) {
      this.setState({ showTooltip: true });
    }
  }

  handleMouseLeave() {
    this.setState({ showTooltip: false });
  }

  isWaiting() {
    return this.props.isWaitingProject(this.props.project.project.id);
  }

  handleHoursChange(name, value) {
    const hours = this.props.project.hours || 0;
    if (value !== hours) {
      this.setState({
        unsavedChanges: true,
      });
    } else {
      this.setState({
        unsavedChanges: false,
      });
    }
  }

  getFeedback() {
    if (this.isWaiting()) return 'Saving';
    if (this.state.unsavedChanges) {
      return 'Unsaved Changes';
    }
  }

  /**
   * Check whether the logged user can edit the hours of the timesheet.
   * The permissions consider the automatic thimesheet, the whitelist and the archived status.
   * @returns {*|boolean}
   */
  canEditHours() {
    const isTimesheetEditable = this.props.project.can_edit;
    const whitelistEditable = isInWhitelist(this.props.project.project);
    const archiveEditable = !this.props.project.project.archived;
    return isTimesheetEditable && whitelistEditable && archiveEditable;
  }

  render() {
    return (
      <TableRow className="timesheet-weekly__row timesheet-weekly__row--body"
        onMouseEnter={this.handleMouseEnter.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}>
        <OptionsCell project={this.props.project} />
        <InfoCell project={this.props.project} />
        <TimesheetAutomaticTagCell key="automatic_timesheet"
          automatic={this.props.project.project.project_type.is_timesheet_automatic} />
        <TableCell className="timesheet-weekly__cell-job-order hidden-mobile">
          <Escaper>{this.props.project.project.job_order}</Escaper>
        </TableCell>
        <NoteCell project={this.props.project}
          isWaiting={this.isWaiting()} />
        <HoursCell className="timesheet-weekly__cell-total"
          name="total"
          value={this.state.project.hours}
          totalHours={this.props.project.hours}
          project={this.props.project.project}
          canEdit={this.props.project.can_edit}
          isInWhitelist={isInWhitelist(this.props.project.project)}
          editable={this.canEditHours()}
          isWaiting={this.isWaiting()}
          feedback={this.getFeedback()}
          onChange={this.handleHoursChange.bind(this)}
          updateHours={this.handleHoursUpdate.bind(this)}
          showTooltip={this.state.showTooltip}
          reasonCannotEdit={this.props
            .reasonWhyCannotEditHour(this.props.project.reason_cannot_edit)} />
        {this.state.showTooltip
          && (
          <TableCell className="timesheet__tooltip tooltipReact">
            <span style={{ position: 'relative', bottom: '50%' }}>
              {this.props.reasonWhyCannotEditHour(this.props.project.reason_cannot_edit).message}
            </span>
          </TableCell>
          )}
      </TableRow>
    );
  }
};
