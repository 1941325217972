const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');

const TableHeaderRow = ({ levels, ...rest }) => {
  function getLevels() {
    return levels ? levels.map((level) => (
      <span key={level.id}
        className="company-settings-job-title__table-cell-level">
        {level.short_text}
      </span>
    )) : [];
  }

  return (
    <TableRow className="company-settings-job-title__table-row" {...rest}>
      <TableCell className="company-settings-job-title__table-cell-name">
        Name
      </TableCell>
      <TableCell className="company-settings-job-title__table-cell-level company-settings-job-title__table-cell-levels">
        {getLevels()}
      </TableCell>
      <TableCell className="company-settings-job-title__table-cell-action" />
    </TableRow>
  );
};

module.exports = TableHeaderRow;
