/* eslint-disable consistent-return,class-methods-use-this,no-shadow,no-param-reassign,max-len */
const constants = require('./constants');
const searchFilter = require('./services/searchFilter');
const BusinessUnitService = require('../../../../../services/BusinessUnitService');

class PlanningReducer {
  constructor(state) {
    this.businessUnitService = new BusinessUnitService();

    this.state = {
      ...state,
      user_id: Wethod.userInfo.get('employee_id'),
      people: [],
      available_people_filters: null,
      planningSettings: {},
      selectedPricelist: null,
      availablePricelists: null,
      selected_project: null,
      available_projects: [], // projects' select
      international_calendar: false,
      highlight_weekend: false,
      planning_weekend_confirmed: false, // user accepted ConfirmWeekendPlanModal
      planning_holiday_confirmed: false, // user accepted ConfirmHolidayPlanModal
      filtered_people: [], // people after applying pagination an filters
      zoom: 'day', // hour | day | week
      planned_projects: {}, // projects with at least one hour in the loaded plan
      people_filters: this.getDefaultPeopleFilters({ keyword: state.searchFilter }),
      days: [],
      modal: null,
      modal_data: null,
      waiting_for: [],
      plans: [],
      bu_enabled: this.businessUnitService.isEnabled(), // are business units enabled for the company
      recurring_mode_enabled: false,
      holidays: [],
    };

    this.reduxReducer = this.reduxReducer.bind(this);
  }

  comparePeople(a, b) {
    if (a.is_teammate && !b.is_teammate) {
      return -1;
    }
    if (!a.is_teammate && b.is_teammate) {
      return 1;
    }
    if ((a.is_teammate && b.is_teammate) || (!a.is_teammate && !b.is_teammate)) {
      if (a.surname < b.surname) {
        return -1;
      }
      if (a.surname > b.surname) {
        return 1;
      }
      return 0;
    }
  }

  getSortedPeople(people) {
    let loggedUserIndex = null;
    for (let i = 0; i < people.length; i++) {
      if (people[i].id === Wethod.userInfo.attributes.employee_id) {
        loggedUserIndex = i;
      }
    }
    if (loggedUserIndex !== null) {
      const loggedUser = people.splice(loggedUserIndex, 1);
      people = people.sort(this.comparePeople);
      return loggedUser.concat(people);
    }
    return people.sort(this.comparePeople);
  }

  getDefaultPeopleFilters(defaults = {}) {
    let filters = [{
      type: 'type',
      target: 'internal',
      secondaryTarget: undefined,
    }, {
      type: 'keyword',
      target: defaults.keyword ? defaults.keyword : '',
    }, {
      type: 'skills',
      target: [],
    }, {
      type: 'languages',
      target: [],
    }, {
      type: 'interests',
      target: [],
    }];

    if (this.businessUnitService.isEnabled()) {
      const units = this.businessUnitService.getLastSelected();
      const unitFilters = units.map((unit) => ({
        type: 'bu',
        target: this.businessUnitService.findById(unit),
      }));
      filters = filters.concat(unitFilters);
    }

    return filters;
  }

  /**
   * Activate the selected filter
   * @param activeFilters
   * @param {*} type
   * @param {*} target
   * @param {*} secondaryTarget
   */
  onFilterSelection(activeFilters, type, target, secondaryTarget) {
    // Remove all other filters on 'level type'
    if (type === 'type') {
      activeFilters.forEach((filter, index) => {
        if (filter.type === 'type') {
          activeFilters.splice(index, 1);
        }
      });
    }

    activeFilters.push({
      type,
      target,
      secondaryTarget,
    });
    window.scrollTo(0, 0);
    return activeFilters;
  }

  /**
   * Remove the deactiveted filter from the active filters
   * @param {*} type
   * @param {*} target
   * @param {*} secondaryTarget
   */
  onFilterDeselection(activeFilters, type, target, secondaryTarget) {
    const index = activeFilters.findIndex((filter) => type === filter.type
      && target === filter.target && filter.secondaryTarget === secondaryTarget);
    if (index !== -1) {
      activeFilters.splice(index, 1);
    }

    return activeFilters;
  }

  /**
   * Remove all active people's filters. Keep search filter instead.
   */
  clearFilters(activeFilters) {
    const defaultFilters = this.getDefaultPeopleFilters().map((filter) => {
      if (filter.type === 'keyword') {
        return activeFilters.filter((filter) => filter.type === 'keyword')[0];
      }
      return filter;
    }).filter((filter) => filter.type !== 'bu');

    window.scrollTo(0, 0);

    return defaultFilters;
  }

  reduxReducer(state = this.state, action) {
    /**
     * Return an array of strings representing days between from and to.
     * Remove weekend if international calendar not enabled.
     * @param from {moment.Moment}
     * @param to {moment.Moment}
     * @returns {[]}
     */
    const getDays = (from, to) => {
      const length = to.diff(from, 'days') + 1;
      let days = [];
      for (let i = 0; i < length; i++) {
        days.push(from.clone().add(i, 'days').format('YYYY-MM-DD'));
      }
      if (!state.international_calendar) {
        days = days.filter((day) => {
          const name = moment(day).format('dddd');
          return name !== 'Saturday' && name !== 'Sunday';
        });
      }
      return days;
    };

    const getFilteredPeople = (people, activeFilters) => people
      .filter((person) => searchFilter(activeFilters, person, state.plans, state.planned_projects));

    switch (action.type) {
      case constants.IS_INTERNATIONAL_CALENDAR_REQUEST: {
        return {
          ...state,
          waiting_for: state.waiting_for.concat('international-calendar'),
        };
      }
      case constants.IS_INTERNATIONAL_CALENDAR_SUCCESS: {
        return {
          ...state,
          international_calendar: action.enabled,
          highlight_weekend: action.highlightWeekend,
          waiting_for: state.waiting_for.filter((request) => request !== 'international-calendar'),
        };
      }
      case constants.GET_FTE_REQUEST: {
        return {
          ...state,
          fte: state.waiting_for.concat('fte'),
        };
      }
      case constants.GET_FTE_SUCCESS: {
        return {
          ...state,
          fte: action.fte,
          waiting_for: state.waiting_for.filter((request) => request !== 'fte'),
        };
      }
      case constants.GET_AVAILABLE_PEOPLE_FILTERS_REQUEST: {
        return {
          ...state,
          waiting_for: state.waiting_for.concat('available-people-filters'),
        };
      }
      case constants.GET_AVAILABLE_PEOPLE_FILTERS_SUCCESS: {
        return {
          ...state,
          available_people_filters: action.filters,
          waiting_for: state.waiting_for.filter((request) => request !== 'available-people-filters'),
        };
      }
      case constants.GET_PLANNING_SETTINGS_SUCCESS: {
        return {
          ...state,
          planningSettings: action.planningSettings,
        };
      }
      case constants.GET_AVAILABLE_PRICELISTS_SUCCESS: {
        return {
          ...state,
          availablePricelists: action.availablePricelists,
        };
      }
      case constants.SET_SELECTED_PRICELIST: {
        return {
          ...state,
          selectedPricelist: action.selectedPricelist,
        };
      }
      case constants.GET_AVAILABLE_PROJECTS_REQUEST: {
        return {
          ...state,
          waiting_for: state.waiting_for.concat('available-projects'),
        };
      }
      case constants.GET_AVAILABLE_PROJECTS_SUCCESS: {
        const plannedProjects = state.planned_projects;
        const availableProjects = action.projects;
        for (let i = 0; i < availableProjects.length; i++) {
          const { id } = availableProjects[i];
          plannedProjects[id] = availableProjects[i];
        }

        return {
          ...state,
          available_projects: action.projects,
          planned_projects: plannedProjects,
          waiting_for: state.waiting_for.filter((request) => request !== 'available-projects'),
        };
      }
      case constants.GET_PEOPLE_REQUEST: {
        return {
          ...state,
          waiting_for: state.waiting_for.concat('people'),
        };
      }
      case constants.GET_PEOPLE_SUCCESS: {
        const people = this.getSortedPeople(action.people);
        return {
          ...state,
          people,
          filtered_people: getFilteredPeople(people, state.people_filters),
          waiting_for: state.waiting_for.filter((request) => request !== 'people'),
        };
      }
      case constants.GET_PLAN_REQUEST: {
        return {
          ...state,
          waiting_for: state.waiting_for.concat('plan'),
        };
      }
      case constants.GET_PLAN_SUCCESS: {
        const daysToAdd = getDays(moment(action.from), moment(action.to));
        const { plans } = state;
        for (let i = 0; i < state.people.length; i++) {
          const personId = state.people[i].id;
          const actualPlan = plans[personId];
          const planToAdd = action.plan[personId];
          if (planToAdd) {
            if (!actualPlan) {
              plans[personId] = [];
            }
            // Add only plans that are not in the person's actual plan yet
            for (let i = 0; i < planToAdd.length; i++) {
              const isPlanAlreadyLoaded = plans[personId]
                .some((plan) => plan.id === planToAdd[i].id);
              if (!isPlanAlreadyLoaded) {
                plans[personId] = plans[personId].concat(planToAdd[i]);
              }
            }
          }
        }
        return {
          ...state,
          days: action.is_future ? state.days.concat(daysToAdd) : daysToAdd.concat(state.days),
          planned_projects: { ...state.planned_projects, ...action.projects },
          plans: { ...plans },
          waiting_for: state.waiting_for.filter((request) => request !== 'plan'),
        };
      }
      case constants.CHANGE_ZOOM: {
        return {
          ...state,
          filtered_people: getFilteredPeople(state.people, state.people_filters),
          zoom: action.zoom,
        };
      }
      case constants.GET_PROJECT_AVAILABILITY_FAILURE: {
        const sentences = {
          [constants.FAILURE_REASONS.MISSING_APPROVED_BUDGET_VERSION]: 'Please create a budget for this project before start planning.',
          [constants.FAILURE_REASONS.MISSING_PERMISSIONS]: 'You don\'t have permission to edit this planning.',
        };

        return {
          ...state,
          waiting_for: state.waiting_for.filter((request) => request !== 'project-availability'),
          modal: 'error',
          modal_data: { sentence: sentences[action.reason] },
        };
      }
      case constants.GET_PROJECT_AVAILABILITY_REQUEST: {
        return {
          ...state,
          planning_weekend_confirmed: false,
          waiting_for: state.waiting_for.concat('project-availability'),
        };
      }
      case constants.GET_PROJECT_AVAILABILITY_SUCCESS: {
        const people = state.people.map((person) => {
          const teammate = action.project.planned_employees
            .filter((plannedPerson) => plannedPerson === person.id).length > 0;
          return {
            ...person,
            is_teammate: teammate,
          };
        });
        const sortedPeople = this.getSortedPeople(people);
        return {
          ...state,
          selectedPricelist: null,
          selected_project: action.project,
          waiting_for: state.waiting_for.filter((request) => request !== 'project-availability'),
          people: sortedPeople,
          filtered_people: getFilteredPeople(sortedPeople, state.people_filters),
        };
      }
      case constants.FILTER_PROJECT_LEVELS_BY_PRICELIST: {
        if (
          state.planningSettings.availableHoursGrouping === 'user_levels'
          || !state.selectedPricelist
        ) {
          return state;
        }
        // Explanation of what's going on here: if using 'price_lists' mode, the "levels" prop inside "selected_project"
        // is replaced by "priceListLevels". This is done to keep compatibility with the old code without changing any of its logic.
        const updatedPriceListLevels = state.selected_project.priceListLevels.map((priceListLevel) => (
          // this is needed because if the allocation gets modified by the user,
          // the data inside "levels" is mutatated, therefore we gotta update
          // the priceListLevels array with such changes.
          state.selected_project.levels.find((level) => (
            priceListLevel.level_id === level.level_id && priceListLevel.price_list_id === level.price_list_id
          )) ?? priceListLevel
        ));
        const levels = updatedPriceListLevels
          .filter((pricelistLevel) => ( // this is the actual filtering by the selected pricelist
            pricelistLevel.price_list_id === state.selectedPricelist.id
          ))
          .map((pricelistLevel) => ({
            ...pricelistLevel,
            id: pricelistLevel.level_id, // this is the hack to make the priceListLevels "compatible" with the old logic (which is based on "levels")
          }));
        return {
          ...state,
          selected_project: {
            ...state.selected_project,
            levels,
            priceListLevels: updatedPriceListLevels,
          },
        };
      }
      case constants.FILTER_PEOPLE_BY_PRICELIST: {
        if (
          state.planningSettings.availableHoursGrouping === 'user_levels'
        ) {
          return state;
        }
        const peopleFilters = [
          ...state.people_filters.filter((filter) => filter.type !== 'priceList'),
          {
            type: 'priceList',
            target: state.selectedPricelist,
          },
        ];
        const filteredPeople = getFilteredPeople(
          state.people,
          peopleFilters,
        );
        return {
          ...state,
          people_filters: peopleFilters,
          filtered_people: filteredPeople,
        };
      }
      case constants.CLOSE_PROJECT_INFO: {
        const people = state.people.map((person) => ({
          ...person,
          is_teammate: false,
        }));
        const sortedPeople = this.getSortedPeople(people);
        return {
          ...state,
          selectedPricelist: null,
          selected_project: null,
          people: sortedPeople,
          filtered_people: getFilteredPeople(sortedPeople, state.people_filters),
        };
      }
      case constants.CLOSE_MODAL: {
        return {
          ...state,
          modal: null,
          modal_data: null,
        };
      }
      case constants.SHOW_MODAL: {
        return {
          ...state,
          modal: action.name,
          modal_data: action.data,
        };
      }
      case constants.EDIT_PLAN_FAILURE: {
        return {
          ...state,
          modal: 'conflictual-plan',
        };
      }
      case constants.EDIT_PLAN_REQUEST: {
        const projectChanged = action.changes[0].project_id;
        const personId = action.changes[0].person;
        const person = state.people.filter((person) => person.id === personId)[0];
        const personLevel = person.level.id;
        const changedPlanDates = action.changes.map((change) => change.day).toString(); // concatenation of changes' dates
        let personPlans = state.plans[personId] ? state.plans[personId] : []; // a person could have no plans
        const changedPlanAmount = action.changes.reduce((sum, change) => sum + change.amount, 0); // new total amount for changed plan
        const beforeChangePlanAmount = personPlans
          .filter((plan) => plan.project_id === projectChanged
            && changedPlanDates.indexOf(plan.day) !== -1)
          .reduce((sum, change) => sum + change.amount, 0); // total amount for changed plan before changes

        personPlans = personPlans
          .filter((plan) => !(plan.project_id === projectChanged
            && changedPlanDates.indexOf(plan.day) !== -1)) // remove all changed plans
          .concat(action.changes.filter((change) => change.amount > 0)); // add new changes (those with amount = 0 are not needed)

        const { plans } = state;
        plans[personId] = personPlans;

        const selectedProjectChanged = state.selected_project
          && state.selected_project.id === projectChanged;
        const nextSelectedProject = !selectedProjectChanged ? state.selected_project : {
          ...state.selected_project,
          levels: state.selected_project.levels.map((level) => {
            if (level.id === personLevel) {
              const daysDelta = (changedPlanAmount - beforeChangePlanAmount) / (state.fte / 60);
              return {
                ...level,
                planned_days: level.planned_days + daysDelta,
              };
            }
            return level;
          }),
        };

        return {
          ...state,
          plans: { ...plans },
          selected_project: nextSelectedProject,
          planned_projects: {
            ...state.planned_projects,
            // Avoid issue WETHOD-APP-40E
            [projectChanged]: action.changes[0].project_data ? action.changes[0].project_data
              : state.planned_projects[projectChanged],
          },
        };
      }
      case constants.SELECT_PEOPLE_FILTER: {
        const activeFilters = [...this.onFilterSelection(state.people_filters, action.name,
          action.target, action.secondaryTarget)];
        return {
          ...state,
          people_filters: activeFilters,
          filtered_people: getFilteredPeople(state.people, activeFilters),
        };
      }
      case constants.DESELECT_PEOPLE_FILTER: {
        const activeFilters = [...this.onFilterDeselection(state.people_filters, action.name,
          action.target, action.secondaryTarget)];
        return {
          ...state,
          people_filters: activeFilters,
          filtered_people: getFilteredPeople(state.people, activeFilters),
        };
      }
      case constants.CLEAR_PEOPLE_FILTERS: {
        const activeFilters = [...this.clearFilters(state.people_filters)];
        return {
          ...state,
          people_filters: activeFilters,
          filtered_people: getFilteredPeople(state.people, activeFilters),
        };
      }
      case constants.SEARCH_KEYWORD_CHANGE: {
        const key = action.keyword;
        const activeFilters = state.people_filters.map((filter) => {
          if (filter.type === action.mode) { // Set the right filter with keyword
            return {
              ...filter,
              target: key,
            };
          } // Reset the unused filters
          if (filter.type === 'skills' || filter.type === 'languages' || filter.type === 'interests') { // Array-like filters
            return {
              ...filter,
              target: [],
            };
          }
          if (filter.type === 'keyword') { // String-like filters
            return {
              ...filter,
              target: '',
            };
          }
          return filter;
        });
        window.scrollTo(0, 0);
        return {
          ...state,
          people_filters: activeFilters,
          filtered_people: getFilteredPeople(state.people, activeFilters),
        };
      }
      case constants.SAVE_BUDGET_CONVERSION_REQUEST: {
        return {
          ...state,
          waiting_for: state.waiting_for.concat('save-conversion'),
        };
      }
      case constants.SAVE_BUDGET_CONVERSION_SUCCESS: {
        return {
          ...state,
          waiting_for: state.waiting_for.filter((request) => request !== 'save-conversion'),
        };
      }
      case constants.SAVE_BUDGET_CONVERSION_FAILURE: {
        const messages = {
          'Budget must be approved': 'The budget is not approved yet, you can edit it directly.',
          'Submitted costs exceed budget allowed costs': 'The cost of the chosen days exceeds the budget costs, probably the budget has been modified by someone else. Start over to use the updated data.',
        };
        const message = messages?.[action.message] || 'The changes you have made cannot be saved. Please try again.';
        return {
          ...state,
          waiting_for: state.waiting_for.filter((request) => request !== 'save-conversion'),
          modal: 'error',
          modal_data: { sentence: message, title: 'Oops! Changes cannot be saved' },
        };
      }
      case constants.RECURRING_REQUEST: {
        return {
          ...state,
          waiting_for: state.waiting_for.concat('recurring-plan'),
        };
      }
      case constants.RECURRING_SUCCESS: {
        const { plans } = state;
        const plannedProjects = { ...state.planned_projects };

        if (action.changes.length) {
          const projectChangedIds = [];

          // Avoid issue WETHOD-APP-40E
          for (let i = 0; i < action.changes.length; i++) {
            const changedProject = action.changes[i].project_data;
            const changedProjectId = changedProject ? changedProject.id
              : action.changes[i].project_id;
            // Update project data only if data are available
            if (changedProject) {
              plannedProjects[changedProjectId] = changedProject;
            }
            projectChangedIds.push(changedProjectId);
          }

          const projectsChanged = projectChangedIds.toString(); // concatenation of changes' project id
          const personId = action.changes[0].person;
          const changedPlanDates = action.changes.map((change) => change.day).toString(); // concatenation of changes' dates
          let personPlans = state.plans[personId] ? state.plans[personId] : []; // a person could have no plans

          personPlans = personPlans
            .filter((plan) => !(projectsChanged.indexOf(plan.project_id) !== -1
              && changedPlanDates.indexOf(plan.day) !== -1)) // remove all changed plans
            .concat(action.changes.filter((change) => change.amount > 0)); // add new changes (those with amount = 0 are not needed)

          plans[personId] = personPlans;
        }

        return {
          ...state,
          plans: { ...plans },
          modal: null,
          modal_data: null,
          waiting_for: state.waiting_for.filter((request) => request !== 'recurring-plan'),
          planned_projects: plannedProjects,
        };
      }
      case constants.RECURRING_ADD_FAILURE: {
        return {
          ...state,
          modal: 'recurring-failure',
          modal_data: {
            errors: action.errors,
            settings: action.settings,
          },
          waiting_for: state.waiting_for.filter((request) => request !== 'recurring-plan'),
        };
      }
      case constants.TOGGLE_RECURRING_MODE: {
        return {
          ...state,
          recurring_mode_enabled: !state.recurring_mode_enabled,
        };
      }
      case constants.CONFIRM_WEEKEND_PLANNING: {
        return {
          ...state,
          planning_weekend_confirmed: true,
        };
      }
      case constants.CONFIRM_HOLIDAY_PLANNING: {
        return {
          ...state,
          planning_holiday_confirmed: true,
        };
      }
      case constants.CONFIRM_CAPACITY_PLANNING: {
        return {
          ...state,
          planning_capacity_confirmed: true,
        };
      }
      case constants.GET_HOLIDAYS_REQUEST: {
        return {
          ...state,
          waiting_for: state.waiting_for.filter((request) => request !== 'holidays'),
        };
      }
      case constants.GET_HOLIDAYS_SUCCESS: {
        return {
          ...state,
          holidays: state.holidays.concat(action.holidays),
          waiting_for: state.waiting_for.concat('holidays'),
        };
      }
      default:
        return state;
    }
  }
}

module.exports = PlanningReducer;
