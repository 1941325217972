/* eslint-disable react/sort-comp,consistent-return,react/no-access-state-in-setstate */
const React = require('react');
const TextInput = require('@common/TextInput.react');

module.exports = class ObjectiveTitle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: this.props.title,
      errors: [],
    };

    this.timer = 0;
  }

  save() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (!this.state.errors.length) {
        this.props.onEditTitle(this.state.title);
      }
    }, 500);
  }

  onChange(name, value) {
    const nextState = { ...this.state };
    nextState[name] = value;
    this.setState({ ...nextState }, () => {
      this.save();
    });
  }

  addError(name) {
    // Passing a function to setState lets you avoid async state update problems.
    if (this.state.errors.indexOf(name) === -1) {
      this.setState((previousState) => ({
        ...previousState,
        errors: previousState.errors.concat(name),
      }), () => {
        this.props.updateFormErrors(this.props.id, 'add');
      });
    }
  }

  removeError(name) {
    this.setState({ errors: this.state.errors.filter((error) => error !== name) }, () => {
      this.props.updateFormErrors(this.props.id, 'remove');
    });
  }

  onValidate(name, errors) {
    if (errors.length > 0) {
      this.addError(name);
    } else {
      this.removeError(name);
    }
  }

  getClassName(name) {
    let className = `profile-objectives__item-${name}`;
    if (this.state.errors.indexOf(name) !== -1) {
      className = `${className} wethod-modal2-input--error`;
    }
    return className;
  }

  render() {
    return (
      <span className={this.getClassName('title')}>
        <TextInput name="title"
          value={this.state.title}
          constraints="required"
          onValidate={this.onValidate.bind(this)}
          onChange={this.onChange.bind(this)} />
      </span>
    );
  }
};
