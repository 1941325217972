const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const DraggableItem = require('./DraggableList/DraggableItem.react');

const TaskSortModalDraggableItem = ({ id, name }) => (
  <DraggableItem
    id={id}>
    <div className="wethod-budget-task-sort-modal__draggable-item">
      <Icon icon="draggable" className="wethod-budget-task-sort-modal__draggable-item-icon" />
      <span className="wethod-budget-task-sort-modal__draggable-item-label">{name}</span>
    </div>
  </DraggableItem>
);

module.exports = TaskSortModalDraggableItem;
