const React = require('react');
const moment = require('moment');
const Modal = require('@common/Modal/Modal.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const MonthPicker = require('@common/inputs/MonthPicker/OutlinedMonthPicker/OutlinedMonthPicker.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Icon = require('@common/Icon/Icon.react');
const InlineFields = require('../InlineFields.react');
const {
  ORDER,
  STATUS,
} = require('../../segmentsStatus');
const timelines = require('../../timelines');

module.exports = class EditPeriodModal extends React.Component {
  static getFormattedMonth(date) {
    return date ? moment(date).format('MMM YYYY') : '';
  }

  constructor(props) {
    super(props);

    this.state = {
      from: this.props.segment.from,
      to: this.props.segment.to,
    };
  }

  /**
   * Set the end date of the segment as the last day of the selected month.
   * @param e
   */
  handleDateChange(e) {
    const formattedDate = moment(e.target.value)
      .endOf('month')
      .format('YYYY-MM-DD');
    this.setState({
      to: formattedDate,
    });
  }

  handleSave() {
    const selectedTimelineSegments = this.getSelectedTimelineSegments();
    this.props.save(
      this.getTimelineWithAdjustedDates(selectedTimelineSegments),
      this.props.segment.timeline,
    );
  }

  getSelectedTimelineSegments() {
    return this.props.timelines
      .find((timeline) => timeline.name === this.props.segment.timeline)
      .segments;
  }

  /**
   * Adjust dates for all segments in the timeline.
   * When the date of a segment is edited, the starting date of the next segment should be adjusted.
   * @param segments
   */
  getTimelineWithAdjustedDates(segments) {
    const sortedSegments = segments.sort((a, b) => ORDER[a.status] - ORDER[b.status]);

    const newSegment = {
      status: this.props.segment.status,
      from: this.props.segment.from,
      to: this.state.to,
    };

    // Find the index of the segment to be updated and replace it with the new segment
    const index = sortedSegments.findIndex((segment) => segment.status === newSegment.status);
    if (index !== -1) {
      sortedSegments[index] = newSegment;
    }

    // Find the next segment and adjust its starting date
    const currentOrder = ORDER[newSegment.status];
    const nextSegment = sortedSegments.find((segment) => ORDER[segment.status] > currentOrder);
    if (nextSegment) {
      nextSegment.from = moment(newSegment.to)
        .add(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');
    }

    return sortedSegments;
  }

  getFeedback() {
    return this.props.isSaving ? 'Saving...' : null;
  }

  getText() {
    if (timelines.isMainTimeline(this.props.segment.timeline)) {
      return this.props.segment.status === STATUS.IN_REVIEW
        ? 'Choose a time range to analyse before applying data freezing preferences: '
        : 'You can only reduce the segment. In order to extend the data set period start a new data review.';
    }
    return 'Choose a time range to apply data freezing preferences: ';
  }

  getMaxDate() {
    return this.canDateBeExtended()
      // Select max date as last month
      ? moment().subtract(1, 'month').format('YYYY-MM-DD')
      // Select max date as segment end date
      : this.props.segment.to;
  }

  /**
   * Get the minimum date for the segment based on the timeline and segment statuses.
   * - If the timeline is the main timeline, return the segment's start date.
   * - Otherwise, check the main timeline segments:
   *   - If there is a segment with STATUS.PAUSED, return its end date.
   *   - If not, return the segment's start date.
   * @returns {string} The minimum date in 'YYYY-MM-DD' format.
   */
  getMinDate() {
    if (timelines.isMainTimeline(this.props.segment.timeline)) {
      return this.props.segment.from;
    }

    const mainTimeline = this.props.timelines
      .find((timeline) => timelines.isMainTimeline(timeline.name));

    const mainTimelinePausedSegment = mainTimeline?.segments
      .find((segment) => segment.status === STATUS.PAUSED);
    return mainTimelinePausedSegment ? mainTimelinePausedSegment.to : this.props.segment.from;
  }

  getTitle() {
    return `Edit segment - ${timelines.getTimelineLabel(this.props.segment.timeline)}`;
  }

  /**
   * Check if the date can be extended for the segment.
   * @returns {boolean}
   */
  canDateBeExtended() {
    return this.props.segment.status === STATUS.IN_REVIEW
      || (
        !timelines.isMainTimeline(this.props.segment.timeline)
        && this.props.segment.status === STATUS.PAUSED
      );
  }

  render() {
    return (
      <Modal title={this.getTitle()} onClose={this.props.closeModal}>
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              {this.getText()}
            </ModalBodyBlock>
            <ModalBodyBlock>
              <InlineFields>
                <TextField readOnly
                  name="start_date"
                  label="From"
                  value={EditPeriodModal.getFormattedMonth(this.state.from)}
                  onChange={this.handleDateChange.bind(this)} />
                <MonthPicker name="end_date"
                  label="To"
                  value={this.state.to}
                  onChange={this.handleDateChange.bind(this)}
                  disableBefore={this.getMinDate()}
                  disableAfter={this.getMaxDate()}
                  suffix={<Icon icon="calendar" label="end-date-icon" />} />
              </InlineFields>
            </ModalBodyBlock>
          </ModalBody>
          <Footer feedback={this.getFeedback()}>
            <FooterAction onClick={this.props.closeModal} disabled={this.props.isSaving}>
              Dismiss
            </FooterAction>
            <FooterAction onClick={this.handleSave.bind(this)}
              disabled={this.props.isSaving || !this.state.to}>
              Confirm
            </FooterAction>
          </Footer>
        </ModalContent>
      </Modal>
    );
  }
};
