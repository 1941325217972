const React = require('react');
const Modal = require('@common/modal2/Modal.react');

const ExportSuccessModal = ({ onCancelClick }) => {
  const getBody = () => (
    <p>
      You will receive it shortly in your mailbox.
    </p>
  );

  const getActions = () => (
    <div className="orders__export-success-modal__actions">
      <button type="button" onClick={onCancelClick} className="wethod-button">Ok, got it</button>
    </div>
  );

  return (
    <Modal title="Export data" onCancelClick={onCancelClick} className="orders__export-success-modal">
      {getBody()}
      {getActions()}
    </Modal>
  );
};

module.exports = ExportSuccessModal;
