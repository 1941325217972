/* eslint-disable max-len */
const React = require('react');
const Mobile = require('@common/media-queries/Mobile.react');
const Section = require('../../../../common/components/Section.react');
const Title = require('../../../containers/sections/Sales/Title');
const NewClients = require('../../../containers/sections/Sales/NewClients');
const ValuableProjects = require('../../../containers/sections/Sales/ValuableProjects');
const MissedOpportunities = require('../../../containers/sections/Sales/MissedOpportunities');
const WonProjects = require('../../../containers/sections/Sales/WonProjects');
const NewOpportunities = require('../../../containers/sections/Sales/NewOpportunities');
const PipelineValue = require('../../../containers/sections/Sales/PipelineValue');
const NewOpportunityList = require('../../../containers/sections/Sales/NewOpportunityList');
const WeeklySalesChart = require('./WeeklySalesChart.react');
const WeeklyPipelineChart = require('./WeeklyPipelineChart.react');

module.exports = class Sales extends React.Component {
  getCharts() {
    const pipelineMin = Math.min(this.props.pipeline_value, this.props.prev_week_pipeline_value,
      this.props.two_weeks_ago_pipeline_value, this.props.prev_year_pipeline_value,
      this.props.prev_year_prev_week_pipeline_value,
      this.props.prev_year_two_weeks_before_pipeline_value);
    const pipelineChartMin = pipelineMin * 0.8; // -20% of pipeline min

    return (
      <div className="briefing-aside-chart__wrapper">
        <WeeklySalesChart
          last_year={Math.round(this.props.prev_year_won_projects_value / 1000)}
          three_weeks_ago={Math.round(this.props.two_weeks_ago_won_projects_value / 1000)}
          two_weeks_ago={Math.round(this.props.prev_week_won_projects_value / 1000)}
          one_week_ago={Math.round(this.props.won_projects_value / 1000)}
          target={Math.round(this.props.weekly_target / 1000)}
          dynamic_target={Math.round(this.props.weekly_dynamic_target / 1000)} />
        <WeeklyPipelineChart
          last_week={Math.round(this.props.pipeline_value / 1000)}
          min={Math.round(pipelineChartMin / 1000)}
          two_weeks_ago={Math.round(this.props.prev_week_pipeline_value / 1000)}
          three_weeks_ago={Math.round(this.props.two_weeks_ago_pipeline_value / 1000)}
          last_year_last_week={Math.round(this.props.prev_year_pipeline_value / 1000)}
          last_year_two_weeks_ago={Math.round(this.props.prev_year_prev_week_pipeline_value / 1000)}
          last_year_three_weeks_ago={Math.round(this.props.prev_year_two_weeks_before_pipeline_value / 1000)} />
      </div>
    );
  }

  render() {
    return (
      <Section left={this.getCharts()}>
        <Title />
        <WonProjects />
        <NewClients />
        <ValuableProjects />
        <PipelineValue />
        <NewOpportunities />
        <NewOpportunityList />
        <MissedOpportunities />
        <Mobile>{this.getCharts()}</Mobile>
      </Section>
    );
  }
};
