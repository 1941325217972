const React = require('react');
const VirtualList = require('@common/VirtualList/DynamicVirtualList.react');
const Mobile = require('@common/media-queries/Mobile.react');
const Laptop = require('@common/media-queries/Laptop.react');
const Desktop = require('@common/media-queries/Desktop.react');
const Year = require('./YearlyPlan.react');

module.exports = class CompactPlan extends React.Component {
  static getPlanMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  /**
   * @param cardRows // Number of rows per year
   * @returns {number}
   */
  static getItemHeight(cardRows) {
    const yearHeight = 56; // Height of year
    const margin = 48; // Tot margin
    const cardHeight = 340; // Height of a singleCard
    return yearHeight + margin + cardHeight * cardRows;
  }

  static isEmpty(plan) {
    return Object.keys(plan).length === 0;
  }

  static addMonth(year, month) {
    let updatedYear = year;
    if (!updatedYear) {
      updatedYear = [];
    }
    if (updatedYear.indexOf(month) === -1) {
      updatedYear.push(month);
    }

    return updatedYear;
  }

  static addDate(date, plan) {
    const dateSplit = date.split('-'); // date="YYYY-MM"
    const year = dateSplit[0];
    const month = dateSplit[1];

    return { ...plan, [year]: CompactPlan.addMonth(plan[year], month) };
  }

  constructor(props) {
    super(props);

    this.state = {
      height: null,
    };
  }

  componentDidMount() {
    this.updatePlanHeight();
  }

  getVisiblePlans() {
    let visiblePlans = {};

    if (this.props.plans) {
      Object.keys(this.props.plans).forEach((date) => {
        visiblePlans = CompactPlan.addDate(date, visiblePlans);
      });
    }

    if (this.props.invoices) {
      Object.keys(this.props.invoices).forEach((date) => {
        visiblePlans = CompactPlan.addDate(date, visiblePlans);
      });
    }

    return visiblePlans;
  }

  getItems(visiblePlans) {
    const items = [];
    Object.keys(visiblePlans).sort().forEach((year) => {
      items.push(
        <Year key={year}
          plans={this.props.plans}
          invoices={this.props.invoices}
          year={year}
          months={visiblePlans[year].sort()} />,
      );
    });
    return items;
  }

  updatePlanHeight() {
    this.setState({ height: CompactPlan.getPlanMaxHeight() });
  }

  render() {
    const visiblePlans = this.getVisiblePlans();
    return CompactPlan.isEmpty(visiblePlans) ? (
      <div>
        <div className="invoice-plan__empty">There are no invoices planned yet.</div>
      </div>
    ) : (
      <div>
        <Mobile>
          <VirtualList items={this.getItems(visiblePlans)}
            overscan={1}
            defaultItemHeight={CompactPlan.getItemHeight(12)}
            height={this.state.height} />
        </Mobile>
        <Laptop>
          <VirtualList items={this.getItems(visiblePlans)}
            overscan={1}
            defaultItemHeight={CompactPlan.getItemHeight(3)}
            height={this.state.height} />
        </Laptop>
        <Desktop>
          <VirtualList items={this.getItems(visiblePlans)}
            overscan={1}
            defaultItemHeight={CompactPlan.getItemHeight(4)}
            height={this.state.height} />
        </Desktop>
      </div>
    );
  }
};
