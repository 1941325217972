const numeral = require('numeral');
const italian = require('numeral/languages/it');
const moment = require('moment');
const MathService = require('./MathService');

numeral.language('it', italian); // load language

numeral.language('it'); // set language

const FormatService = {
  /**
   * Returns the given number properly formatted in K.
   *
   * @param {number} number
   * @param {boolean} isCost
   * @param {boolean} hideZero
   * @param {number} decimalPlaces
   * @param {boolean} explicitSign
   * @returns {string}
   */
  formatNumberAsK: (number, isCost, hideZero, decimalPlaces, explicitSign) => {
    const cost = isCost || false;
    hideZero = hideZero || false;
    decimalPlaces = decimalPlaces || 0;
    // Add decimal places to the format, if needed
    let format = decimalPlaces ? `0,0.[${Array(decimalPlaces + 1).join('0')}]` : '0,0';

    if (explicitSign) {
      format = `+${format}`;
    }

    let formattedNumber;

    number /= 1000;

    if (hideZero && number === 0) {
      formattedNumber = null;
    } else if (cost) {
      if (number > 0) {
        formattedNumber = `(${numeral(number).format(format)})`;
      } else if (number < 0) { // if 0 no parenthesis
        formattedNumber = numeral(-1 * number).format(format);
      } else {
        formattedNumber = 0;
        if (hideZero) {
          formattedNumber = '-';
        }
      }
    } else if (number >= 0) {
      formattedNumber = numeral(number).format(format);
    } else {
      formattedNumber = `(${numeral(-1 * number).format(format)})`;
    }
    return formattedNumber;
  },
  formatNumberAsUnit(number) {
    return numeral(number).format('0,0');
  },
  /**
   * Format number with . to separate thousands and , to separate decimals (if any) with the given
   * precision (default zero decimal places)
   * @param {number} number
   * @param {boolean} explicitSign //always show the sign for both positive and negative numbers
   * @param {number} precision // maximum number of decimal to show
   * @param {number} minPrecision // minimum number of decimal to show
   * @returns {*}
   */
  formatDecimalNumber(number, explicitSign = false, precision = 0, minPrecision = 0) {
    let format = precision ? `0,0.[${Array(precision + 1).join('0')}]` : '0,0';
    format = minPrecision ? `0,0.${Array(minPrecision + 1).join('0')}` : format;
    const value = numeral(number);
    let formattedNumber = value.format(format);
    if (explicitSign && formattedNumber !== '0') {
      format = `+${format}`;
      formattedNumber = value.format(format);
    }
    return formattedNumber;
  },
  formatDeltaInK(number, hideZero, explicitSign) {
    number /= 1000;
    hideZero = hideZero || false;

    const value = numeral(number);
    let formattedNumber = value.format('0,0');

    if (hideZero && formattedNumber === '0') {
      return null;
    }
    if (explicitSign && formattedNumber !== '0') {
      formattedNumber = value.format('+0,0');
    }
    return formattedNumber;
  },
  /**
   * Format a valid date as 28.06.1992.
   * @param date
   */
  formatDate: (date) => moment(date).format('DD.MM.YYYY'),
  /**
   * Return duration formatted as hours, minutes, seconds following the given format.
   *
   * @param {numeral} value duration
   * @param {string} unit duration unit
   * @param {string} format
   * @return {string}
   */
  formatDuration: (value, unit, format) => {
    const acceptedUnits = [
      FormatService.DURATION_UNIT_MINUTES,
      FormatService.DURATION_UNIT_SECONDS,
    ];
    const acceptedFormats = [
      FormatService.DURATION_PATTERN_HMS,
      FormatService.DURATION_PATTERN_HM,
      FormatService.DURATION_PATTERN_MS,
    ];

    if (!acceptedUnits.includes(unit)) {
      throw new Error(`Invalid duration unit '${unit}'`);
    }

    if (!acceptedFormats.includes(format)) {
      throw new Error(`Invalid duration format '${format}'`);
    }

    const duration = moment.duration(value, unit);
    let durationString = '';
    const hours = duration.hours().toString().padStart(2, '0');
    const minutes = duration.minutes().toString().padStart(2, '0');
    const seconds = duration.seconds().toString().padStart(2, '0');

    if (format === FormatService.DURATION_PATTERN_HMS) {
      durationString = `${hours}:${minutes}:${seconds}`;
    }
    if (format === FormatService.DURATION_PATTERN_HM) {
      durationString = `${hours}:${minutes}`;
    }
    if (format === FormatService.DURATION_PATTERN_MS) {
      durationString = `${minutes}:${seconds}`;
    }

    return durationString;
  },
  /**
   * Format a space separated name (i.e. a person's name like Andrea Della Libera) as "A Della
   * Libera".
   * @param nameString
   * @returns {string}
   */
  formatOneLetterPersonName: (nameString) => {
    const splitted = nameString.split(' ');
    const name = splitted[0].charAt(0);
    splitted.splice(0, 1);
    const surname = splitted.join(' ');
    return `${name} ${surname}`;
  },
  /**
   * Format the given value with the given precision in M or K.
   * Examples:
   * - f(1200) -> 1 K
   * - f(1200,1) -> 1,2 K
   * - f(1200000) -> 1 M
   *
   * @param value
   * @param precision digits to keep after comma.
   * @param keepDecimalZero wheater to show or not decimals if they're zeroes only
   * @return {string}
   */
  formatByStep: (value, precision, keepDecimalZero) => {
    precision = precision || 0;
    const steps = [Math.pow(10, 6), Math.pow(10, 3)]; // Millions, Thousands
    let formatted = value;
    let sign = '';

    if (value >= steps[0]) {
      formatted = value / steps[0];
      sign = 'M';
    } else if (value >= steps[1]) {
      formatted = value / steps[1];
      sign = 'K';
    }

    const rounded = MathService.round(formatted, precision);
    let format = '0,0.';
    if (precision > 0) {
      const decimalFormat = `${new Array(precision + 1).join(0)}`;
      if (keepDecimalZero) {
        format += `${decimalFormat}`;
      } else {
        format += `[${decimalFormat}]`;
      }
    }

    return `${numeral(rounded).format(format)} ${sign}`;
  },

  /**
   * Format the given value, representing a number, removing all the leading zeroes.
   * This does not remove any other zero contained in the number.
   * The number is treated as a string.
   * @param {string} value
   * @returns {string}
   */
  formatNumberWithoutLeadingZeroes: (value) => value.replace(/^0+/, ''),

  /**
   * Format the given value, representing a number,
   * adding leading zeroes in order to reach the length given as format.
   * The given number can exceed the format length, without being changed.
   * The number is treated as a string.
   * @param {string} value
   * @param {int} format
   * @returns {string}
   */
  formatNumberWithLeadingZeroes: (value, format) => {
    const numberWithNoLeadingZeroes = value.replace(/^0+/, ''); // Remove all the leading zeroes

    return numberWithNoLeadingZeroes.padStart(format, 0);
  },
  /**
   * Format a valid URL to be usable whether it has a prefix (like 'http://) or not.
   * @param url
   * @returns {string}
   */
  formatURL: (url) => ((url.includes('://')) ? url : `//${url}`),
};

FormatService.DURATION_PATTERN_HMS = 'HH:mm:ss';
FormatService.DURATION_PATTERN_HM = 'HH:mm';
FormatService.DURATION_PATTERN_MS = 'mm:ss';

FormatService.DURATION_UNIT_SECONDS = 'seconds';
FormatService.DURATION_UNIT_MINUTES = 'minutes';

module.exports = FormatService;
