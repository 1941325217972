const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Escaper = require('@common/formatters/Escaper.react');

const RevokeDelegationModal = ({ data, isWaiting, onRevoke, onClose }) => {
  function handleRevoke() {
    onRevoke(data.project, data.billingGroupId);
  }

  function getFeedback() {
    if (isWaiting) return <span className="wethod-modal2__feedback">Saving...</span>;
    return '';
  }

  return (
    <Modal title="Revoke delegation" onCancelClick={onClose} className="invoice-plan__modal">
      <div>
        <p>
          <b><Escaper>{data.project.name}</Escaper></b>
          {' '}
          will no longer be invoiced through
          {' '}
          <b><Escaper>{data.masterProject.name}</Escaper></b>.
          <br />
          Are you sure you want to continue?
        </p>
      </div>
      <div className="invoice-plan__modal-actions">
        <button type="button" onClick={onClose} className="wethod-button">Cancel</button>
        <button type="button" onClick={handleRevoke} className="wethod-button">Yes</button>
      </div>
      {getFeedback()}
    </Modal>
  );
};

module.exports = RevokeDelegationModal;
