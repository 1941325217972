const React = require('react');
const Mobile = require('@common/media-queries/Mobile.react');
const Title = require('../../../containers/sections/Production/Title');
const TotalProduction = require('../../../containers/sections/Production/TotalProduction');
const WorkedProjects = require('../../../containers/sections/Production/WorkedProjects');
const DayValue = require('../../../containers/sections/Production/DayValue');
const RoadrunnerIndex = require('../../../containers/sections/Production/RoadrunnerIndex');
const WorkedHours = require('../../../containers/sections/Production/WorkedHours');
const ProductionChart = require('../../../containers/sections/Production/ProductionChart');
const ChargeabilityChart = require('../../../containers/sections/Production/ChargeabilityChart');
const Section = require('../../../../common/components/Section.react');

module.exports = () => {
  const getCharts = () => (
    <div className="briefing-aside-chart__wrapper">
      <ProductionChart />
      <ChargeabilityChart />
    </div>
  );
  return (
    <Section left={getCharts()}>
      <Title />
      <TotalProduction />
      <WorkedProjects />
      <DayValue />
      <RoadrunnerIndex />
      <WorkedHours />
      <Mobile>{getCharts()}</Mobile>
    </Section>
  );
};
