const React = require('react');
const Modal = require('@common/Modal/SimpleModal/SimpleModal.react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const Typography = require('@common/Typography/Typography.react');

const InvitationSentModal = ({ email }) => {
  function onClose() {
    modalService.closeModal();
  }

  return (
    <Modal onClose={onClose} title="Invitation sent">
      Invitation sent to <Typography weight={Typography.WEIGHTS.BOLD}>{ `${email}` }</Typography>.
    </Modal>
  );
};

module.exports = InvitationSentModal;
