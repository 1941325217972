const React = require('react');
const TimesheetModel = require('@models/TimesheetDetailed');

const PermissionManager = ({ children, ...rest }) => {
  const reasonWhyCannotEditHour = (reasonCannotEdit) => {
    const reason = {
      type: 'input',
      message: '',
    };
    switch (reasonCannotEdit) {
      case TimesheetModel.CANNOT_EDIT_BECAUSE_ARCHIVED:
        reason.input = 'number';
        reason.message = 'This project is being archived. Unarchive it to edit timesheet.';
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_WHITELIST:
        reason.type = 'number';
        reason.message = 'You need to be a whitelisted team member to update this timesheet.';
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_AUTOMATIC:
        reason.type = 'lines';
        reason.message = 'Only working hours of previous weeks can be edited on automatic timesheets.';
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_PROJECT_SCOPE:
        reason.type = 'lines';
        reason.message = 'It is not possible to record working hours out of project scope.';
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_TIME_BUFFER:
        reason.type = 'numbers';
        reason.message = 'The time allowed to record or edit working hours on this project has ended.\nAsk someone from your team with a permission to edit.';
        break;
      case TimesheetModel.CANNOT_EDIT_BECAUSE_DATA_FROZEN:
        reason.type = 'numbers';
        reason.message = 'This information has been frozen.\nYou won\'t be able to edit it again.';
        break;
      default:
        reason.type = 'input';
        reason.message = '';
    }
    return reason;
  };

  return React.cloneElement(children, {
    ...rest,
    reasonWhyCannotEditHour,
  });
};

module.exports = PermissionManager;
