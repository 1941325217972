const React = require('react');
const CommonSectionHeader = require('@common/SectionHeader/components/SectionHeader.react');

const SectionHeader = () => (
  <CommonSectionHeader current_section="Pipeline Opportunities"
    helper_url="pipeline/index/#projects-and-opportunities"
    tour_id={286053}
    tour_start_page="pipeline/opportunities"
    big />
);

module.exports = SectionHeader;
