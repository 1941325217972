/* eslint-disable react/sort-comp,class-methods-use-this,react/no-access-state-in-setstate */
const React = require('react');
const Sidebar = require('@common/sidebar/Sidebar.react');
const EditableBody = require('./BudgetTemplateEditableDetails.react');
const ReadOnlyBody = require('./BudgetTemplateReadOnlyDetails.react');
const Actions = require('./BudgetTemplateSidebarActions.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * template: object corresponding the focused budget template to show in the sidebar
 * isSaving: boolean, check for pending saving
 * canEdit: boolean, permission to edit
 *
 * updateTemplate
 * closeSidebar
 * deleteTemplate
 *
 * @type {module.BudgetTemplateSidebar}
 */
module.exports = class BudgetTemplateSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      hasUnsavedChanges: false, // is true if any input has unsaved changes, false otherwise
      errors: [], // array containing the name of the attributes that have errors; if the list is
      // empty there are no errors
      template: this.props.template || {},
      editMode: false,
    };
  }

  /**
   * Checks if it's safe to save a template: you cannot save changes if there's another saving
   * pending or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.state.errors.length === 0;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return !this.state.editMode;
  }

  isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldTemplate = this.props.template || {};
    const oldVal = oldTemplate[name];

    let totalChanges = this.state.unsavedChanges.length;

    // When both values are 'empty', there's no need to compare them
    if ((this.isDirty(oldVal) || this.isDirty(value)) && (oldVal !== value)) {
      // When the value has changed, we insert the name in the changes array, if not already present
      const newChanges = this.state.unsavedChanges;
      if (!newChanges.includes(name)) newChanges.push(name);
      this.setState({ unsavedChanges: newChanges });
      totalChanges += 1;
    } else {
      // When the value is equal to the props, we need to remove it from the changes array if
      // present
      let newChanges = this.state.unsavedChanges;
      newChanges = newChanges.filter((attr) => attr !== name);
      this.setState({ unsavedChanges: newChanges });
      totalChanges = newChanges.length;
    }

    return totalChanges !== 0;
  }

  // Save in the local state the modified template and check if there are unsaved changes
  handleInputChanges(name, value) {
    const newTemplate = {
      ...this.state.template,
      [name]: value,
    };

    const hasUnsavedChanges = this.hasChanged(name, value);
    this.setState({
      hasUnsavedChanges,
      template: newTemplate,
    });
  }

  handleDelete() {
    if (this.props.deleteTemplate) this.props.deleteTemplate(this.state.template);
  }

  /**
   * Check for input errors and keep the state list of errors updated:
   * to know if there are errors in the sidebar we can count on the number of errors in the list
   */
  handleErrors(name, errors) {
    let updatedErrors = this.state.errors;
    if (errors.length === 0) {
      updatedErrors = updatedErrors.filter((error) => error !== name);
    } else if (!updatedErrors.includes(name)) {
      updatedErrors.push(name);
    }

    this.setState({ errors: updatedErrors });
  }

  handleSave() {
    this.setState({
      editMode: false,
      hasUnsavedChanges: false,
    });

    if (this.props.updateTemplate) {
      this.props.updateTemplate(this.state.template);
    }
  }

  handleEditMode() {
    if (this.canEdit()) {
      this.setState({ editMode: true });
    }
  }

  getBody() {
    if (this.state.editMode) {
      return (
        <EditableBody template={this.state.template}
          onChange={this.handleInputChanges.bind(this)}
          onValidate={this.handleErrors.bind(this)} />
      );
    }
    return (<ReadOnlyBody template={this.state.template} />);
  }

  getActions() {
    if (this.state.template.id && this.canEdit()) {
      return (
        <Actions onDelete={this.handleDelete.bind(this)} />
      );
    }

    return null;
  }

  render() {
    return (
      <Sidebar title="Budget template"
        hasUnsavedChanges={this.state.hasUnsavedChanges}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.closeSidebar}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.closeSidebar}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
