const React = require('react');
const moment = require('moment');
const TableCell = require('@common/Table2/TableCell.react');
const TextField = require('@common/inputs/TextField/BasicTextField/BasicTextField.react');
const IconButton = require('@common/Button/IconButton.react');
const TableRow = require('@common/Table2/TableRow.react');

module.exports = class Token extends React.Component {
  constructor(props) {
    super(props);

    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  onDeleteClick() {
    this.props.showDeleteModal(this.props.id);
  }

  getFormattedLastUse() {
    const date = this.props.last_use;

    return date ? moment(date).format('DD.MM.YYYY, HH:mm') : '';
  }

  render() {
    return (
      <TableRow className="wethod-personal-api-token-row">
        <TableCell className="wethod-personal-api-token-cell wethod-personal-api-token-cell--name">
          <TextField name="name"
            value={this.props.name}
            readOnly />
        </TableCell>
        <TableCell className="wethod-personal-api-token-cell wethod-personal-api-token-cell--last-use">
          <TextField name="last used"
            value={this.getFormattedLastUse()}
            readOnly />
        </TableCell>
        <TableCell className="wethod-personal-api-token-cell wethod-personal-api-token-cell--description">
          <TextField name="name"
            value={this.props.description}
            readOnly />
        </TableCell>
        <TableCell className="wethod-personal-api-token-cell wethod-personal-api-token-cell--action">
          <IconButton icon="delete" color="blue" size="medium" onClick={this.onDeleteClick} className="company-settings__icon-button" label="Delete" />
        </TableCell>
      </TableRow>
    );
  }
};
