const React = require('react');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const Modal = require('@common/Modal/Modal.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const RequestManager = require('../../containers/RequestManager');

const ApiErrorModal = ({ closeModal, message, title = 'Something went wrong' }) => (
  <Modal onClose={closeModal} title={title}>
    <FormValidator>
      <RequestManager>
        <ModalContent>
          <ModalBody>
            {message}
          </ModalBody>
          <ModalFooter>
            <ModalFooterAction onClick={closeModal}>
              Ok
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </RequestManager>
    </FormValidator>
  </Modal>
);

module.exports = ApiErrorModal;
