/* eslint-disable consistent-return,class-methods-use-this,react/sort-comp,
 react/no-access-state-in-setstate */
const React = require('react');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Pic = require('../../../../settings/profile/components/ProfilePicPicker.react');

module.exports = class CompanyReadOnlyDetails extends React.Component {
  getGroup() {
    return this.props.client.group ? this.props.client.group.name : '';
  }

  getBank() {
    return this.props.client.bank_account ? this.props.client.bank_account.name : '';
  }

  getVatRate() {
    return this.props.client.vat_rate ? this.props.client.vat_rate.label : '';
  }

  getCountry() {
    return this.props.client.country ? this.props.client.country.name : '';
  }

  getProvince() {
    return this.props.client.province ? this.props.client.province.name : '';
  }

  getPaymentTerms() {
    return this.props.client.payment_term ? this.props.client.payment_term.name : '';
  }

  getName() {
    return this.props.client.corporate_name ? this.props.client.corporate_name : '';
  }

  getUrl(link) {
    if (link) {
      if (link.indexOf('http') !== -1) return link;
      return `http://${link}`;
    }
    return '';
  }

  getIntercompanyId() {
    if (this.props.hasIntercompanyGroup) {
      const value = this.props.client.group_company !== null
        ? this.props.client.group_company.name : null;

      return (
        <ReadOnlyField placeholder="Intercompany ID"
          value={value}>
          <span />
        </ReadOnlyField>
      );
    }
    return null;
  }

  getExternalId() {
    if (this.hasExternalIds()) {
      return this.props.client.external_ids[0].external_id;
    }
  }

  /**
   * Check whether the client has external ids
   * @returns {boolean}
   */
  hasExternalIds() {
    return this.props.client.external_ids != null && this.props.client.external_ids.length > 0;
  }

  isItaly() {
    const country = this.getCountry();
    return country.toLowerCase() === 'italy';
  }

  formatDate(date) {
    return date ? moment(date).format('DD/MM/YYYY') : null;
  }

  // TODO: change PicPicker!!
  render() {
    return (
      <div>
        <div className="wethod-sidebar__picture">
          <Pic name={this.getName()}
            editable={false} />
        </div>
        <div className="clients__sidebar--multiple-input-row">
          <div className="clients__sidebar--name clients__sidebar--multiple-input-element">
            <ReadOnlyField placeholder="Name"
              value={this.props.client.corporate_name}>
              <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
            </ReadOnlyField>
          </div>
          <div className="clients__sidebar--acronym clients__sidebar--multiple-input-element">
            <ReadOnlyField placeholder="Acronym"
              value={this.props.client.acronym} />
          </div>
        </div>
        <ReadOnlyField placeholder="Legal name"
          value={this.props.client.legal_name}>
          <span />
        </ReadOnlyField>
        {this.getIntercompanyId()}
        <ReadOnlyField placeholder="Group"
          value={this.getGroup()}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Address"
          value={this.props.client.street}>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Town"
          value={this.props.client.town}>
          <span />
        </ReadOnlyField>
        <div className="clients__sidebar--multiple-input-row">
          <div className="clients__sidebar--multiple-input-element">
            <ReadOnlyField placeholder="ZIP"
              value={this.props.client.zip_code}>
              <span />
            </ReadOnlyField>
          </div>
          <div className="clients__sidebar--multiple-input-element">
            <ReadOnlyField placeholder="Country"
              value={this.getCountry()} />
          </div>
        </div>
        <ShowIf condition={this.isItaly()}>
          <div className="clients__sidebar-province">
            <ReadOnlyField placeholder="Province" value={this.getProvince()}>
              <span />
            </ReadOnlyField>
          </div>
        </ShowIf>
        <ReadOnlyField placeholder="Website"
          value={this.props.client.website}
          link={this.getUrl(this.props.client.website)}>
          <span className="wethod-icon wethod-icon-internet wethod-icon-internet--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="LinkedIn"
          value={this.props.client.linkedin}
          link={this.getUrl(this.props.client.linkedin)}>
          <span className="wethod-icon wethod-icon-linkedin wethod-icon-linkedin--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="VAT Number"
          value={this.props.client.vat}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Tax Code"
          value={this.props.client.tax_reference}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Co. Reg. No."
          value={this.props.client.company_registration_number}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="SDI code"
          value={this.props.client.sdi_code}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Payment Conditions"
          value={this.props.client.payment_condition}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Payment Terms"
          value={this.getPaymentTerms()}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Bank Account"
          value={this.getBank()}
          helperText="Bank account in which you would like to receive payments from this client.">
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="VAT Rate"
          value={this.getVatRate()}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Email"
          value={this.props.client.email}
          link={`mailto:${this.props.client.email}`}>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="PEC address"
          value={this.props.client.pec}
          link={`mailto:${this.props.client.pec}`}>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Email 2"
          value={this.props.client.administration_email}
          link={`mailto:${this.props.client.administration_email}`}>
          <span className="wethod-icon wethod-icon-mail wethod-icon-mail--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Phone"
          value={this.props.client.phone}
          link={`tel:${this.props.client.phone}`}>
          <span className="wethod-icon wethod-icon-phone wethod-icon-phone--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Notes"
          value={this.props.client.notes}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </ReadOnlyField>
        <ShowIf condition={this.hasExternalIds()}>
          <ReadOnlyField placeholder="External code" value={this.getExternalId()}>
            <span />
          </ReadOnlyField>
        </ShowIf>
        <h3 className="wethod-sidebar__subtitle">Declaration of intent</h3>
        <ReadOnlyField placeholder="Date" value={this.formatDate(this.props.client.intent_date)}>
          <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Protocol" value={this.props.client.intent_code}>
          <span />
        </ReadOnlyField>
      </div>
    );
  }
};
