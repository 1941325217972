const React = require('react');
const _ = require('underscore');
const TableCell = require('@common/Table2/TableCell.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const TextField = require('@common/inputs/TextField/BasicTextField/BasicTextField.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');
const IntercompanyLabel = require('../../../../containers/Item/cells/NameCell/IntercompanyLabel');

const NameCell = ({
  updateErrors, readOnly, value, onBlur, onFocus, error, name, style, isIntercompany, projectId,
  label, metadataService,
}) => {
  const intercompanyLabel = isIntercompany ? <IntercompanyLabel projectId={projectId} /> : null;

  return (
    <TableCell className="pipeline-item__name" style={style}>
      {intercompanyLabel}
      <InputValidator updateErrors={updateErrors} constraints={['required', 'maxLength:500']}>
        <InputMetadataManager metadataService={metadataService} name={name} canEdit={!readOnly}>
          <TextField
            label={label}
            value={_.unescape(value)}
            onBlur={onBlur}
            onFocus={onFocus}
            errorText={error} />
        </InputMetadataManager>
      </InputValidator>
    </TableCell>
  );
};

module.exports = NameCell;
