const React = require('react');
const TagsInput = require('@common/Tag/TagsInput/TagsInput.react');

const Interests = ({ tags, knownTags, placeholder }) => {
  const getContent = () => {
    if (tags.length > 0) {
      return <TagsInput tags={tags} knownTags={knownTags} />;
    }
    return <span className="profile-card__placeholder">{placeholder}</span>;
  };

  return (
    <div className="profile__interests">{getContent()}</div>
  );
};

module.exports = Interests;
