/* eslint-disable react/sort-comp */
const React = require('react');
const Loading = require('@common/LoadingSmall.react');
const Quarter = require('../../containers/Objectives/Quarter');

module.exports = class Objectives extends React.Component {
  componentDidMount() {
    this.props.getObjectives(this.props.year, this.props.employee);
  }

  /**
   * Filter empty quarters when profile not editable and no quartersFilters are selected.
   *
   * @param quarter
   * @returns {boolean}
   */
  filterQuarters(quarter) {
    if (!this.props.editable && this.props.quartersFilter === null) {
      return quarter.objectives.length > 0;
    }
    return true;
  }

  getQuarters() {
    if (!this.props.waiting) {
      return this.props.quarters
        .filter(this.filterQuarters.bind(this)).map((quarter) => (
          <Quarter key={quarter.quarter}
            title={`Quarter ${quarter.quarter}`}
            {...quarter} />
        ));
    }

    return <Loading />;
  }

  render() {
    return (
      <div className="profile-objectives">
        <span className="profile-card__placeholder">
          Set challenges for each quarter. Add small key results required to achieve your goals.
          Remember to update your achievements periodically
        </span>
        <div className="profile-objectives__quarter-collections">
          {this.getQuarters()}
        </div>
      </div>
    );
  }
};
