const React = require('react');
const VirtualList = require('@common/VirtualList/DynamicVirtualList.react');
const Mobile = require('@common/media-queries/Mobile.react');
const Laptop = require('@common/media-queries/Laptop.react');
const Desktop = require('@common/media-queries/Desktop.react');
const Year = require('./YearlyPlan.react');

module.exports = class FullPlan extends React.Component {
  constructor(props) {
    super(props);

    this.yearOffset = 50;
    this.pastYearLimit = moment().year() - this.yearOffset;
    this.futureYearLimit = moment().year() + this.yearOffset;

    this.firstYear = this.getFirstVisibleIndex();

    this.months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

    this.state = {
      height: null,
    };
  }

  static getPlanMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  /**
   * @param cardRows // Number of rows per year
   * @returns {number}
   */
  static getItemHeight(cardRows) {
    const yearHeight = 56; // Height of year
    const margin = 48; // Tot margin
    const cardHeight = 340; // Height of a singleCard
    return yearHeight + margin + cardHeight * cardRows;
  }

  componentDidMount() {
    this.updatePlanHeight();
  }

  getItems() {
    const items = [];
    for (let year = this.pastYearLimit; year < this.futureYearLimit; year++) {
      items.push(
        <Year key={year}
          plans={this.props.plans}
          invoices={this.props.invoices}
          year={year}
          months={this.months} />,
      );
    }
    return items;
  }

  getFirstVisibleIndex() {
    const firstYear = moment(this.props.start).format('YYYY');

    const difference = firstYear - moment().format('YYYY');

    return this.yearOffset + difference;
  }

  updatePlanHeight() {
    this.setState({ height: FullPlan.getPlanMaxHeight() });
  }

  render() {
    return (
      <div>
        <Mobile>
          <VirtualList items={this.getItems()}
            index={this.firstYear}
            overscan={1}
            defaultItemHeight={FullPlan.getItemHeight(12)}
            height={this.state.height} />
        </Mobile>
        <Laptop>
          <VirtualList items={this.getItems()}
            index={this.firstYear}
            overscan={1}
            defaultItemHeight={FullPlan.getItemHeight(3)}
            height={this.state.height} />
        </Laptop>
        <Desktop>
          <VirtualList items={this.getItems()}
            index={this.yearOffset}
            overscan={1}
            defaultItemHeight={FullPlan.getItemHeight(4)}
            height={this.state.height} />
        </Desktop>
      </div>
    );
  }
};
