/* eslint-disable consistent-return,class-methods-use-this,react/sort-comp,
 react/no-access-state-in-setstate */
const React = require('react');
const SearchSelect = require('@common/material-input/SidebarSearchSelectAddButton.react');

module.exports = class GroupSearchSelectCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: this.props.value,
    };
  }

  getNewItem(oldItems) {
    return oldItems.find((item) => item.name === this.state.selectedItem.name);
  }

  hasNewItem(oldItems, newItems) {
    return (oldItems.length !== newItems.length && !this.state.selectedItem.id);
  }

  componentWillReceiveProps(nextProp) {
    if (this.hasNewItem(this.props.items, nextProp.items)) {
      const newItem = this.getNewItem(nextProp.items);
      this.setState({ selectedItem: newItem });
      if (this.props.onChange) this.props.onChange(this.props.name, newItem);
    }
    return true;
  }

  handleNewItemCreation(value) {
    this.setState({ selectedItem: { name: value } });
    if (this.props.onSave) this.props.onSave({ name: value });
    if (this.props.onChange) this.props.onChange(this.props.name, value);
  }

  render() {
    return (
      <SearchSelect
        name={this.props.name}
        value={this.state.selectedItem}
        placeholder={this.props.placeholder}
        items={this.props.items}
        onChange={this.props.onChange}
        onValidate={this.props.onValidate}
        onCreateClick={this.handleNewItemCreation.bind(this)}
        required={this.props.required}>
        {this.props.children}
      </SearchSelect>
    );
  }
};
