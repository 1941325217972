const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const ProbabilitySelect = require('./ProbabilitySelect.react');
const ProbabilityInput = require('./ProbabilityInput.react');

const ProbabilityField = ({
  showAsSelect, readOnly, disabled,
  value, name, label, onChange, creation,
  errorText, updateErrors,
}) => (
  <ShowIf condition={showAsSelect}
    showElse={(
      <ProbabilityInput readOnly={readOnly}
        disabled={disabled}
        value={value}
        name={name}
        label={label}
        id="sidebar-probability-input"
        errorText={errorText}
        updateErrors={updateErrors}
        onChange={onChange} />
    )}>
    <ProbabilitySelect readOnly={readOnly}
      disabled={disabled}
      value={value}
      name={name}
      label={label}
      id="sidebar-probability"
      errorText={errorText}
      updateErrors={updateErrors}
      onChange={onChange}
      creation={creation} />
  </ShowIf>
);

module.exports = ProbabilityField;
