/* eslint-disable react/sort-comp,react/no-access-state-in-setstate */
const React = require('react');
const Card = require('@common/CollapsibleCard.react');

module.exports = class ContactDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  getContactName() {
    return `${this.props.contact.name} ${this.props.contact.surname}`;
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  getBusinessRoleComponent() {
    const value = this.props.contact.role;
    if (value !== null) {
      return (
        <div className="client-info-row">
          <div className="client-info-row__title">Business Role</div>
          <div className="client-info-row__value">{value}</div>
        </div>
      );
    }
    return null;
  }

  getBusinessUnitComponent() {
    const value = this.props.contact.business_unit;
    if (value !== null) {
      return (
        <div className="client-info-row">
          <div className="client-info-row__title">Business Unit</div>
          <div className="client-info-row__value">{value}</div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <Card
        title="Client contact"
        collapsed={this.state.collapsed}
        toggleCollapsed={this.toggleCollapsed.bind(this)}>
        <div>
          <div className="client-info-row">
            <div className="client-info-row__title">Name</div>
            <div className="client-info-row__value">
              {this.getContactName()}
            </div>
          </div>
          <div className="client-info-row">
            <div className="client-info-row__title">Email</div>
            <div className="client-info-row__value">
              <a href={`mailto:${this.props.contact.email}`}>{this.props.contact.email}</a>
            </div>
          </div>
          {this.getBusinessRoleComponent()}
          {this.getBusinessUnitComponent()}
        </div>
      </Card>
    );
  }
};
