const React = require('react');
const IconButton = require('@common/TooltipFixed/IconButtonTooltip.react');
const CopyIcon = require('./CopyIcon.react');

const CopyButton = ({ onClick }) => (
  <IconButton label="Copy" borderless onClick={onClick}>
    <CopyIcon />
  </IconButton>
);

module.exports = CopyButton;
