const React = require('react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');

module.exports = class InvoiceCreationButton extends React.Component {
  handleClick(e) {
    if (!this.isDisabled()) {
      this.props.onClick(e);
    }
  }

  getStyle() {
    let style = 'wethod-button wethod-button--selected';
    if (this.isDisabled()) {
      style += ' disabled';
    }
    return style;
  }

  getTooltipMessage() {
    return this.needTooltip() ? 'Create budget or approve current budget version first' : '';
  }

  isDisabled() {
    return !this.props.delta || !this.props.budget || this.props.budget.status !== 'APPROVED';
  }

  needTooltip() {
    return !this.props.budget || this.props.budget.status !== 'APPROVED';
  }

  render() {
    return (
      <Tooltip label={this.getTooltipMessage()}>
        <button type="button"
          className={this.getStyle()}
          onClick={this.handleClick.bind(this)}>
          New invoice
        </button>
      </Tooltip>
    );
  }
};
