/* eslint-disable react/sort-comp,react/jsx-no-bind */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const TextInput = require('@common/TextInput.react');
const Menu = require('@common/Menu/Menu.react');
const TextMenu = require('./TextMenu/TextMenu.react');
const { isValidURL } = require('../../../../../../../../../services/ValidationService');
const { formatURL } = require('../../../../../../../../../services/FormatService');

module.exports = class TextItemCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focusOnTitle: false, showMenu: false };
  }

  onBlur(value) {
    this.setState({ focusOnTitle: false, showMenu: false });
    const nextValue = value.trim();
    if (nextValue !== this.props.value) {
      this.props.editValue(this.props.item, this.props.attribute, value);
    }
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  openMenu() {
    this.setState({ showMenu: true });
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.input.blur();
    }
  }

  getCellClassName() {
    let name = 'project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button';
    if (this.state.focusOnTitle) {
      name += ' project-canvas-spreadsheet__cell--focus';
    }
    return name;
  }

  getSlidingClassName() {
    let style = 'project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--sliding';
    if (this.props.value && !this.state.focusOnTitle) style += ' project-canvas-spreadsheet__cell-button-content--sliding-enabled';

    return style;
  }

  onFocus() {
    this.setState({ focusOnTitle: true });
  }

  getButton() {
    if (isValidURL(this.props.value)) {
      const formattedURL = formatURL(this.props.value);
      return (
        <div className="project-canvas-spreadsheet__cell-button-content--delete">
          <a className="wethod-icon-button" href={formattedURL} target="_blank" rel="noreferrer">
            <div className="wethod-icon wethod-icon-medium-program wethod-icon-medium-program--white" />
          </a>
        </div>
      );
    }
    return (
      <div className="project-canvas-spreadsheet__cell-button-content--delete">
        <button type="button"
          className="wethod-icon-button"
          onClick={this.openMenu.bind(this)}
          onFocus={(e) => { e.stopPropagation(); }}>
          <div className="wethod-icon wethod-icon-medium-expand wethod-icon-medium-expand--white" />
        </button>
      </div>
    );
  }

  render() {
    return (
      <TableCell className={this.getCellClassName()}>
        <div className={this.getSlidingClassName()}>
          <div style={{ backgroundColor: 'white', padding: '0 8px' }} ref={(text) => { this.text = text; }}>
            <TextInput
              value={this.props.value}
              ref={(input) => { this.input = input; }}
              onKeyUp={this.handleKeyUp.bind(this)}
              onBlur={this.onBlur.bind(this)}
              onFocus={this.onFocus.bind(this)} />
          </div>
          {this.getButton()}
        </div>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.text}>
          <TextMenu value={this.props.value} canEdit onSave={this.onBlur.bind(this)} />
        </Menu>
      </TableCell>
    );
  }
};
