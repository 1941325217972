const React = require('react');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const modals = require('../../modals');
const TransferHoursModal = require('../../containers/modal/TransferHours/TransferHoursModal');
const ErrorModal = require('./ErrorModal.react');

const availableModals = {
  [modals.TRANSFER_HOURS]: TransferHoursModal,
  [modals.ERROR]: ErrorModal,
};

module.exports = ({
  toShow,
  closeModal,
  modalData,
}) => {
  const Component = availableModals[toShow];
  if (Component) {
    return (
      <FormValidator>
        <Component closeModal={closeModal} {...modalData} />
      </FormValidator>
    );
  }

  return null;
};
