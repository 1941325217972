const { connect } = require('react-redux');
const Component = require('@common/AdvancedSearch/SegmentSelect/SegmentDeleteModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isWaiting: state.waitingFor.filter((key) => key === 'delete-segment').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteClick: (segment) => dispatch(actions.deleteSegment(segment)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
