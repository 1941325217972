const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const IconButtonTooltip = require('@common/TooltipFixed/IconButtonTooltip.react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const TaskNotesModal = require('./TaskNotesModal.react');

const TaskNotes = ({ id, notes, editable }) => {
  function editNote() {
    modalService.openModal(
      <TaskNotesModal
        id={id}
        notes={notes}
        editable={editable} />,
    );
  }

  return (
    <IconButtonTooltip
      label="Notes"
      borderless
      onClick={editNote}>
      <Icon icon="notes" color="black" variant={notes ? 'done' : ''} />
    </IconButtonTooltip>
  );
};

module.exports = TaskNotes;
