const React = require('react');
const PropTypes = require('prop-types');
const DurationInputBase = require('../DurationInput.react');
const Decorator = require('../../../InputDecorator/OutlinedInputDecorator/OutlinedInputDecorator.react');

const OutlinedDurationInput = ({
  label, id, value, required, helperText,
  errorText, disabled, readOnly, ...rest
}) => (
  <Decorator label={label}
    required={required}
    disabled={disabled}
    readOnly={readOnly}
    helperText={helperText}
    errorText={errorText}
    id={id}
    value={value}>
    <DurationInputBase required={required}
      disabled={disabled}
      readOnly={readOnly}
      label={label}
      value={value}
      {...rest} />
  </Decorator>
);

OutlinedDurationInput.UNIT_SECONDS = DurationInputBase.UNIT_SECONDS;
OutlinedDurationInput.UNIT_MINUTES = DurationInputBase.UNIT_MINUTES;

OutlinedDurationInput.FORMAT_HMS = DurationInputBase.FORMAT_HMS;
OutlinedDurationInput.FORMAT_MS = DurationInputBase.FORMAT_MS;
OutlinedDurationInput.FORMAT_HM = DurationInputBase.FORMAT_HM;

OutlinedDurationInput.defaultProps = {
  id: undefined,
  readOnly: undefined,
  disabled: undefined,
  name: undefined,
  helperText: undefined,
  errorText: undefined,
  validate: undefined,
  label: undefined,
  required: undefined,
  format: OutlinedDurationInput.FORMAT_HM,
};

OutlinedDurationInput.propTypes = {
  errorText: PropTypes.string,
  required: PropTypes.bool,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  helperText: PropTypes.string,
  /**
   * Function to call when value changes.
   * @param e {HTMLEvent}
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.number.isRequired,
  unit: PropTypes.oneOf([
    OutlinedDurationInput.UNIT_SECONDS,
    OutlinedDurationInput.UNIT_MINUTES,
  ]).isRequired,
  format: PropTypes.oneOf([
    OutlinedDurationInput.FORMAT_HMS,
    OutlinedDurationInput.FORMAT_HM,
    OutlinedDurationInput.FORMAT_MS,
  ]),
};

module.exports = OutlinedDurationInput;
