const React = require('react');
const PropTypes = require('prop-types');
const Typography = require('@common/Typography/Typography.react');
const Ellipser = require('@common/Ellipser/Ellipser.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

/**
 * Label for SearchAndSelectModalOption. This label requires a name and can have description and an icon.
 * @param name
 * @param description
 * @param icon
 * @return {JSX.Element}
 * @constructor
 */
const SearchAndSelectModalOptionLabel = ({ name, description, icon }) => (
  <div className="wethod-modal-search-and-select__option">
    <div className="wethod-modal-search-and-select__option-left">
      <Typography component="h5">
        <Ellipser>{name}</Ellipser>
      </Typography>
      <Typography component="h6" color="dusty-gray" size={11}>
        <Ellipser>{description}</Ellipser>
      </Typography>
    </div>
    <ShowIf condition={icon !== null}>
      <div className="wethod-modal-search-and-select__option-right">{icon}</div>
    </ShowIf>
  </div>
);

SearchAndSelectModalOptionLabel.defaultProps = {
  description: '',
  icon: null,
};

SearchAndSelectModalOptionLabel.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.node,
};

module.exports = SearchAndSelectModalOptionLabel;
