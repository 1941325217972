/* eslint-disable class-methods-use-this */
const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const Avatar = require('@common/AvatarBox/Avatar.react');

module.exports = class Person extends React.Component {
  handleDelete() {
    this.props.showDeleteModal(this.props);
  }

  getCompanyName() {
    return this.props.client ? this.props.client.corporate_name : '';
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  getFullName() {
    return `${this.props.name} ${this.props.surname}`;
  }

  render() {
    return (
      <TableRow>
        <TableCell className="clients-people__column--profile-pic"><Avatar name={this.getFullName()} /></TableCell>
        <TableCell className="clients-people__column--name">{this.getUnescapedValue(this.props.name)}</TableCell>
        <TableCell className="clients-people__column--surname">{this.getUnescapedValue(this.props.surname)}</TableCell>
        <TableCell className="clients-people__column--company">
          <a
            href={`#finance/client/company/${this.props.client.id}`}>
            {this.getCompanyName()}
          </a>
        </TableCell>
        <TableCell className="clients-people__column--role">{this.props.role}</TableCell>
        <TableCell className="clients-people__column--email">
          <a
            href={`mailto:${this.props.email}`}>
            {this.props.email}
          </a>
        </TableCell>
        <TableCell className="clients-people__column--actions">
          <button type="button"
            className="clients__table-button clients__table-button--hidden wethod-icon-button"
            onClick={this.handleDelete.bind(this)}>
            <div className="wethod-icon wethod-icon-delete wethod-icon-delete--black" />
          </button>
          <a className="clients__table-button"
            href={`#finance/client/person/${this.props.id}`}>
            <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
          </a>
        </TableCell>
      </TableRow>
    );
  }
};
