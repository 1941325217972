/* eslint-disable react/jsx-no-bind */
const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const PropertyMetadataService = require('../../../../../../../services/PropertyMetadataService/PropertyMetadataService');
const PropertyMetadata = require('../../../../../../../services/PropertyMetadataService/PropertyMetadata');

const NoHoursModal = ({
  onCancelClick, showModal, changeZoom, selectedProject,
}) => {
  const account = selectedProject.account ? selectedProject.account.id : null;

  /**
   * Return true if budget is frozen.
   * @return {boolean}
   */
  const budgetIsFrozen = () => {
    // eslint-disable-next-line no-underscore-dangle
    const metadataService = new PropertyMetadataService(selectedProject._fields);
    const canEdit = metadataService.getCanEdit('budgets');
    const frozenDataReason = metadataService.getReason('budgets') === PropertyMetadata.CANNOT_EDIT_DATA_FROZEN;

    return !canEdit && frozenDataReason;
  };

  const canEditBudget = () => {
    const amIPmOrAccount = Wethod.Authorization.amIPmOrAccount(selectedProject.pm.id, account);
    return !budgetIsFrozen() && Wethod.PlanningApp.canEditBudget(amIPmOrAccount);
  };

  const openConversionModal = (e) => {
    e.preventDefault();
    showModal('conversion');
  };

  const onPlanByHourClick = () => {
    changeZoom('hour');
    onCancelClick();
  };

  const getBody = () => {
    const options = [];
    if (canEditBudget()) {
      options.push(
        <a
          key="budget"
          className="planning-people__modal-multi-choice-item"
          href={`${URL}/#pipeline/budget/${selectedProject.id}`}>
          Review your budget
          <div className="planning-people__allocation-details-footer-icon wethod-icon-arrow-right" />
        </a>,
      );
    }
    options.push(
      <div key="hour-plan"
        className="planning-people__modal-multi-choice-item"
        onClick={onPlanByHourClick}>
        Plan by hour
        <div className="planning-people__allocation-details-footer-icon wethod-icon-arrow-right" />
      </div>,
    );
    if (canEditBudget()) {
      options.push(
        <div key="conversion"
          className="planning-people__modal-multi-choice-item"
          onClick={openConversionModal}>
          Convert budget days from another level
          <div className="planning-people__allocation-details-footer-icon wethod-icon-arrow-right" />
        </div>,
      );
    }

    return options;
  };

  return (
    <Modal
      title="Oops! You ran out of budget"
      onCancelClick={onCancelClick}
      className="planning-people__modal-multi-choice-modal">
      <div className="planning-people__modal-multi-choice">
        {getBody()}
      </div>
    </Modal>
  );
};

module.exports = NoHoursModal;
