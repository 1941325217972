const React = require('react');
const ModalBody = require('@common/Modal/ModalBody.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const Select = require('@common/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItem.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');

module.exports = class SelectIntercompanySupplierModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { supplierId: '' };

    this.onChange = this.onChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  onChange(e) {
    const { value } = e.target;
    this.setState({ supplierId: value });
  }

  onSaveClick() {
    this.props.makeTaskIntercompany(this.props.task, this.state.supplierId);
  }

  getAvailableSuppliers() {
    return this.props.suppliers
      .map((supplier) => (
        <SelectItem key={supplier.id} value={supplier.id}>
          {supplier.name}
        </SelectItem>
      ));
  }

  getFeedback() {
    return this.isSaving() ? 'Saving...' : '';
  }

  isSaving() {
    return this.props.isPending('create-intercompany-task');
  }

  render() {
    return (
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            Please select one of the companies belonging to your group as supplier for this task:
          </ModalBodyBlock>
          <ModalBodyBlock>
            <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
              <Select name="supplier"
                disabled={this.isSaving()}
                value={this.state.supplierId}
                id="task-intercompany-supplier"
                errorText={this.props.errors.supplier}
                onChange={this.onChange}>
                {this.getAvailableSuppliers()}
              </Select>
            </InputValidator>
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={this.getFeedback()}>
          <ModalFooterAction onClick={this.props.closeModal} disabled={this.isSaving()}>
            Cancel
          </ModalFooterAction>
          <ModalFooterAction onClick={this.onSaveClick}
            disabled={this.isSaving() || !this.props.isValid}>
            Save
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    );
  }
};
