const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const ModalBody = require('@common/NotesModal/NotesModalBody.react');

module.exports = class NotesModal extends React.Component {
  static isDirty(value) {
    return value != null && value.toString().trim() !== '';
  }

  constructor(props) {
    super(props);

    this.state = {
      notes: this.props.data.plan ? this.props.data.plan.notes : null,
      unsavedChanges: false,
    };
  }

  handleClose() {
    if (this.state.unsavedChanges) {
      const updatedPlan = { ...this.props.data.plan, notes: this.state.notes };
      this.props.updatePlan(updatedPlan, this.props.data.date);
      this.setState({ unsavedChanges: false });
    }
    this.props.onClose();
  }

  getBody() {
    return (
      <ModalBody note={this.state.notes || ''}
        updateNote={this.updateNotes.bind(this)} />
    );
  }

  getActions() {
    return (
      <div className="invoice-plan__modal-actions">
        <button type="button" onClick={this.handleClose.bind(this)} className="wethod-button">Close</button>
      </div>
    );
  }

  updateNotes(content) {
    const prevNotes = this.props.data.plan ? this.props.data.plan.notes : null;
    const unsavedChanges = (NotesModal.isDirty(prevNotes) || NotesModal.isDirty(content))
      && (prevNotes !== content);

    this.setState({ notes: content, unsavedChanges });
  }

  render() {
    return (
      <Modal title="Notes" onCancelClick={this.handleClose.bind(this)} className="invoice-plan__modal">
        {this.getBody()}
        {this.getActions()}
      </Modal>
    );
  }
};
