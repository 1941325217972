const React = require('react');
const Loading = require('@common/LoadingSmall.react');
const Title = require('./sections/Title/Title.react');
const Intro = require('./sections/Intro/Intro.react');
const Economics = require('./sections/Economics/Economics.react');
const Production = require('./sections/Production/Production.react');
const Sales = require('./sections/Sales/Sales.react');
const EmptyFuture = require('./EmptyFuture.react');
const EmptyPast = require('../../common/components/EmptyPast.react');

module.exports = class Content extends React.Component {
  componentDidUpdate(prevProps) {
    const contentEl = $('.briefing-section');
    if (!this.props.date.isSame(prevProps.date)) {
      this.props.updateTimeToRead(null);
    }
    if (this.props.timeToRead === null && contentEl.length) {
      this.props.updateTimeToRead(Content.getTimeToRead(contentEl.text()));
    }
  }

  /**
   * @see https://marketingland.com/estimated-reading-times-increase-engagement-79830
   *
   * @param text
   */
  static getTimeToRead(text) {
    const time = Math.round(text.split(' ').length / 250);

    return Math.max(1, time);
  }

  getContent() {
    if (this.props.waiting) {
      return <Loading />;
    }
    if (this.props.error === 'future-not-available') {
      return <EmptyFuture />;
    }
    if (this.props.error === 'past-not-available') {
      return <EmptyPast />;
    }
    return (
      <div className="briefing-content">
        <Title date={this.props.date} timeToRead={this.props.timeToRead} />
        <Intro
          timeToRead={this.props.timeToRead}
          date={this.props.date}
          cachedOn={this.props.cachedOn} />
        <Economics />
        <Production />
        <Sales actions={this.props.actions} />
      </div>
    );
  }

  render() {
    return <div className="briefing-container">{this.getContent()}</div>;
  }
};
