/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const Menu = require('@common/Menu/Menu.react');
const StatusListMenu = require('../Spreadsheet/ItemCell/StatusItemCell/StatusMenu/StatusListMenu.react');

module.exports = class StatusSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  handleStatusChange(status) {
    this.setState({ showMenu: false });
    this.props.onChange(this.props.name, status);
  }

  /**
   * Returns the updated value of the status linked to the cell
   * (The status could have changed name/color since it was selected for the cell)
   */
  getStatus() {
    const selectedStatus = this.props.structure.available_values
      .find((status) => status.id === this.props.value.id);

    if (!selectedStatus) { // status has been deleted
      return this.props.structure.available_values.find((status) => status.default === true);
    }

    return selectedStatus;
  }

  render() {
    const selectedStatus = this.getStatus();

    return (
      <div>
        <button type="button"
          ref={(el) => this.statusButton = el}
          className="material-input-button project-canvas-kanban-sidebar__button-input project-canvas-kanban-sidebar__button-input--deletable"
          onClick={this.onMenuButtonClick.bind(this)}>
          <span>{selectedStatus.name}</span>
          <span style={{ backgroundColor: selectedStatus.color }}
            className="project-canvas-kanban-sidebar-status__dot" />
        </button>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.statusButton}>
          <StatusListMenu
            statusList={this.props.structure.available_values}
            onStatusChange={this.handleStatusChange.bind(this)} />
        </Menu>
      </div>
    );
  }
};
