const React = require('react');
const TextInput = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Block = require('./BlockInput.react');

const TextBlock = ({ templateId, block, deletable, onChange, onBlockDelete }) => {
  function handleChange(e) {
    const { name, value } = e.target;
    onChange(name, value, block.sort);
  }

  return (
    <Block block={block} deletable={deletable} onBlockDelete={onBlockDelete}>
      <TextInput
        id={`${templateId}-text-${block.sort}`}
        label="Text"
        name="value"
        placeholder="i.e. WT_"
        data-sort={block.sort}
        value={block.value}
        onBlur={handleChange} />
    </Block>
  );
};

module.exports = TextBlock;
