const React = require('react');
const ErrorBody = require('@common/SectionError/SectionError.react');
const RequestManager = require('../containers/RequestManager');
const Content = require('../containers/BudgetContentBody');
const Loader = require('./SectionLoader.react');
const CreationBody = require('../containers/BudgetCreationBody');

module.exports = class Budget extends React.Component {
  constructor(props) {
    super(props);
    this.state = { firstRender: true };
  }

  componentDidMount() {
    this.setState({ firstRender: false });
    this.props.getVersion(this.props.targetVersion);
  }

  render() {
    if (!this.props.project) {
      return <ErrorBody message="The project you are looking for does not exists" />;
    }
    if (!this.props.userHasBudgetViewPermissions) {
      return <ErrorBody message="You are not allowed to see this budget" />;
    }
    if (!this.props.budgetInfo) {
      if (this.state.firstRender || this.props.isLoading) {
        return <Loader />;
      }
      if (this.props.targetVersion !== null) {
        return <ErrorBody message="The budget version you are looking for does not exists" />;
      }
      if (!this.props.userHasBudgetCreatePermissions) {
        return <ErrorBody message="You are not allowed to create the budget for this project" />;
      }
      if (this.props.projectIsFrozen) {
        return <ErrorBody message="You can't create the budget for this project because the project has been frozen" />;
      }
      return <CreationBody />;
    }
    return (
      <RequestManager>
        <Content />
      </RequestManager>
    );
  }
};
