/* eslint-disable react/sort-comp,class-methods-use-this */
const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const Loader = require('@common/Loader/Loader.react');
const Search = require('../containers/SearchBox');
const Review = require('./ReviewRequest.react');

module.exports = class ReviewRequests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  updateTableHeight() {
    this.setState({ tableHeight: this.getTableMaxHeight() });
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  componentDidUpdate(prevProps) {
    if (this.props.year !== prevProps.year && !this.props.isWaiting) {
      this.props.loadMore(1, this.state.size, this.props.keyword, 0, this.props.year);
    }
  }

  getReviews() {
    return this.props.reviews.map((item) => <Review key={item.id} review={item} />);
  }

  getEmptyContent() {
    if (this.props.isWaiting) return <Loader />;
    return 'There’s nothing here. Request a new project review today.';
  }

  hasMore() {
    return this.props.hasMore;
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      const deletedElements = this.props.deletedElements - this.props.createdElements;
      const adjustedPage = deletedElements < 0 ? page - 1 : page;
      this.props.loadMore(adjustedPage, size, this.props.keyword,
        (this.props.deletedElements - this.props.createdElements),
        this.props.year);
      this.setState({
        size,
      });
    }
  }

  filter(keyword) {
    this.props.filter(keyword, this.state.size, this.props.year);
  }

  getSearch() {
    return <Search filter={this.filter.bind(this)} />;
  }

  getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  render() {
    return (
      <div>
        <Table columns={5} maxHeight={this.state.tableHeight} search={this.getSearch()}>
          <TableHead>
            <TableRow>
              <TableCell name="project" className="review-changelog__column-project">Project</TableCell>
              <TableCell name="requested_by" className="review-changelog__column-employee">Requested by</TableCell>
              <TableCell name="requested_on" className="review-changelog__column-date">Requested on</TableCell>
              <TableCell name="totals" className="review-changelog__column-total">Received reviews</TableCell>
              <TableCell className="review-changelog__column-options" />
            </TableRow>
          </TableHead>
          <TableBody hasMore={this.hasMore()}
            loadMore={this.loadMore.bind(this)}
            empty={this.getEmptyContent()}>
            {this.getReviews()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
