/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');

module.exports = class NoSummaryCell extends React.Component {
  render() {
    return (
      <TableCell className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell--summary-empty" />
    );
  }
};
