const React = require('react');
const ServerErrorModal = require('@common/Modal/SimpleModal/SimpleModal.react');
const CannotUploadFileModal = require('./modal/CannotUploadModal.react');
const CannotDownloadFileModal = require('./modal/CannotDownloadModal.react');
const TravelOfficeModal = require('../containers/modal/TravelOfficeModal');
const ExportModal = require('../containers/modal/ExportModal');
const CategoryDownloadModal = require('../containers/modal/CategoryFilesDownloadModal');

const Modal = ({ toShow, canEdit, closeModal, data }) => {
  const getModal = () => {
    switch (toShow) {
      case 'cannot-upload-file':
        return <CannotUploadFileModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'cannot-download-file':
        return <CannotDownloadFileModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'travel-office':
        return <TravelOfficeModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'export':
        return <ExportModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'category-files-download':
        return <CategoryDownloadModal canEdit={canEdit} onCancelClick={closeModal} data={data} />;
      case 'server-error':
        return (
          <ServerErrorModal onClose={closeModal} title={data.title}>
            {data.message}
          </ServerErrorModal>
        );
      default:
        return null;
    }
  };

  return getModal();
};

module.exports = Modal;
