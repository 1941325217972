const { connect } = require('react-redux');
const Component = require('../../components/modals/EditStatusModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.includes('update-segments'),
  totals: state.warningsTotals,
  timelines: state.timelines,
  isWaitingForWarnings: state.waitingFor.includes('warnings-total'),
  permissions: state.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  save: (segments, timelineName) => dispatch(actions.updateSegments(segments, timelineName)),
  showIgnoreAlertsModal: (segments, timeline) => dispatch(actions
    .showIgnoreAlertsModal(segments, timeline)),
  showConfirmPauseModal: (segments, timeline) => dispatch(actions
    .showConfirmPauseModal(segments, timeline)),
  showConfirmFreezeModal: (segments, timeline) => dispatch(actions
    .showConfirmFreezeModal(segments, timeline)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
