const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');

const EmployeeTagsCategoryDeleteModal = ({ onClose, onOk, onDeleteCategory, feedback }) => (
  <Modal onClose={onClose} title="Delete Category Confirm">
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          Delete the category <b>{onDeleteCategory.name}</b>? All its tags will be deleted.
        </ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={(feedback) ? 'deleting...' : null}>
        <ModalFooterAction onClick={onClose}>Close</ModalFooterAction>
        <ModalFooterAction onClick={onOk}>Ok, Delete</ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

module.exports = EmployeeTagsCategoryDeleteModal;
