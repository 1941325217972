const React = require('react');
const moment = require('moment');
const Typography = require('@common/Typography/Typography.react');
const Header = require('../SubsectionHeader.react');
const Modal = require('../../containers/ModalController');
const Select = require('../SettingsSelect.react');
const SelectItem = require('../SettingsSelectItem.react');
const Row = require('../SettingsRow.react');

const FiscalYear = ({ fiscalYear, isSaving, showFiscalYearChangeModal }) => {
  const feedback = isSaving ? 'Saving...' : null;

  function getMonths() {
    return moment.months();
  }

  function handleFiscalYearChange(e) {
    const { value } = e.target;
    const months = getMonths();
    const monthStart = months.indexOf(value) + 1;
    showFiscalYearChangeModal(fiscalYear, monthStart);
  }

  return (
    <div className="bottomSpace">
      <Header subtitle="Fiscal Year"
        id="fiscal-year"
        description="Set the starting month of the accounting period of your business if it does not align with the calendar year (Jan - Dec)." />
      <Row feedback={feedback}>
        <Typography>Fiscal year starts on:</Typography>
        <Select
          name="fiscal-year-preference"
          label="fiscal-year-preference"
          value={moment.months(fiscalYear.start - 1)}
          onChange={handleFiscalYearChange}
          disabled={isSaving}>
          {
            getMonths().map(
              (month) => <SelectItem key={month} value={month}>{month}</SelectItem>,
            )
          }
        </Select>
        <Modal />
      </Row>
    </div>
  );
};

module.exports = FiscalYear;
