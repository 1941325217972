const React = require('react');
const _ = require('underscore');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const Button = require('@common/Button/RoundedButton.react');
const IconButton = require('@common/Button/IconButton.react');
const TextField = require('@common/inputs/TextField/BasicTextField/BasicTextField.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');

module.exports = class CreationRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.item,
    };

    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleItemChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  handleNameChange(e) {
    const { name } = e.target;
    const { value } = e.target;

    this.handleItemChange(name, value);
  }

  onSave() {
    this.props.onSave(this.state);
  }

  /**
   * Check whether the save button should be disabled.
   * All the fields must be filled in, and the location must not be saving.
   * @returns {boolean|*}
   */
  isSaveDisabled() {
    return !this.props.isValid || this.isSaving();
  }

  isSaving() {
    return this.props.waitingForSave.some((key) => key === `save-locations-${this.props.item.id}`);
  }

  render() {
    return (
      <TableRow feedback={this.isSaving() ? 'Saving' : ''}
        className="company-settings-location__table-row company-settings--with-transparent-input">
        <TableCell className="company-settings-location__table-cell-name">
          <InputValidator constraints={['required', 'maxLength: 255', 'minLength: 2']} updateErrors={this.props.updateErrors}>
            <TextField name="name"
              label="Name"
              placeholder="Enter location name"
              value={_.unescape(this.state.name)}
              onChange={this.handleNameChange}
              errorText={this.props.errors.name} />
          </InputValidator>
        </TableCell>
        <TableCell className="company-settings-location__table-cell-action">
          <Button onClick={this.onSave}
            disabled={this.isSaveDisabled()}
            className="company-settings__button company-settings__button--blue company-settings-margin-right-l">
            Save
          </Button>
          <IconButton icon="delete" color="blue" size="medium" onClick={this.props.onDelete} className="company-settings__icon-button" />
        </TableCell>
      </TableRow>
    );
  }
};
