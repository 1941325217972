/* eslint-disable global-require,import/no-dynamic-require */
const React = require('react');
const PropTypes = require('prop-types');

const icons = [
  'warning',
  'keyboard_arrow_down',
];
const iconSvgs = icons.reduce((acc, icon) => {
  acc[icon] = require(`./icons/${icon}.svg`).default;
  return acc;
}, {});

const sizeModifiers = {
  small: '-small',
  medium: '-medium',
  big: '',
};

const colorModifiers = {
  blue: '',
  black: '--black',
  white: '--white',
  green: '--green',
  red: '--red',
};

const Icon = ({ icon, size, color, className, label, variant, ...rest }) => {
  const Svg = icons.includes(icon)
    ? iconSvgs[icon]
    : null;

  const getSizeSvg = () => (
    // Note: "big" is the default value for the legacy implementation
    size === 'big' ? '1em' : size // TODO: to remove once all icons are migrated to the new version
  );

  const getClassNameSvg = () => ([
    'wethod-svg-icon',
    className,
  ].join(' ').trim());

  /**
   * Construct the class that defines an icon.
   * An icon should have:
   * - base class
   * - icon class
   * - color class
   * - custom given classname
   *
   * @returns {string}
   */
  const getClassNameSpan = () => {
    const sizeModifier = sizeModifiers[size];
    const colorModifier = colorModifiers[color];

    const defaultClass = `wethod-icon${sizeModifier} wethod-icon--inline`;
    const baseIconClass = `wethod-icon${sizeModifier}-${icon}`;
    const iconClass = variant !== '' ? `${baseIconClass}--${variant}` : baseIconClass;
    const colorClass = `${baseIconClass}${colorModifier}`;

    let styleClass = `${defaultClass} ${iconClass} ${colorClass}`;
    styleClass = className ? `${styleClass} ${className}` : styleClass;

    return styleClass;
  };

  return Svg
    ? (
      <Svg
        className={getClassNameSvg()}
        width={getSizeSvg()}
        height={getSizeSvg()}
        aria-hidden={rest['aria-label'] ? null : 'true'}
        role="img"
        {...rest} />
    ) : (
      <span
        className={getClassNameSpan()}
        aria-label={label} />
    );
};

Icon.propTypes = {
  /**
   * Name of the icon. Make sure to give an existing name in order to render the icon correctly.
   */
  icon: PropTypes.string.isRequired,
  /**
   * Size of the icon, from a list of available ones.
   */
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['small', 'medium', 'big']), // legacy
    PropTypes.string, // svg
  ]),
  /**
   * Color of the icon, from a list of available ones.
   */
  color: PropTypes.oneOf(['black', 'white', 'blue', 'green', 'red']),
  className: PropTypes.string,
  /**
   * Text used as aria-label when the icon needs to be accessible.
   */
  label: PropTypes.string,
  /**
   * Some icons has variants. I.e. in "notes--done", "done" is a variant of the base icon "notes".
   */
  variant: PropTypes.string,
  style: PropTypes.shape({}),
};

Icon.defaultProps = {
  size: 'big',
  color: 'black',
  className: '',
  label: undefined,
  variant: '',
  style: {},
};

module.exports = Icon;
