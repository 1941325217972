const React = require('react');
const Button = require('@common/Button/RoundedButton.react');

const IntercompanyTaskInviteButton = ({
  isTaskConnected, isInviteSent, editable, onClick,
}) => {
  if (isTaskConnected) {
    return null;
  }
  const label = isInviteSent ? 'Re-send invitation' : 'Send invitation';

  return (
    <Button onClick={onClick} disabled={!editable} border>{label}</Button>
  );
};

module.exports = IntercompanyTaskInviteButton;
