const React = require('react');
const MultipleSelectFilter = require('@common/MultipleSelectFilter/MultipleSelectFilter.react');
const Order = require('../../../../../../models/Order');

const StatusFilter = ({
  selectedValues,
  onApply,
  availableStatuses,
  isWaiting,
  size,
  keyword,
  orderBy,
  sort,
}) => {
  function handleApply(name, statuses) {
    if (!isWaiting) {
      onApply(size, keyword, orderBy, sort, statuses);
    }
  }

  /**
   * Statuses formatted to be used in MultipleSelectFilter.
   * @type {{children: [], label: *, value: *}[]}
   */
  const formattedAvailableStatuses = availableStatuses
    .map((status) => ({
      label: Order.getStatusLabel(status.name),
      value: status.id,
      children: [],
    }));

  const acceptedValues = formattedAvailableStatuses.map((unit) => unit.value);

  const availableOptions = {
    label: 'Select all',
    value: 'all',
    children: formattedAvailableStatuses,
  };

  return (
    <MultipleSelectFilter className="orders-status-filter"
      name="status"
      label="Filter status"
      menuTitle="Status"
      acceptedValues={acceptedValues}
      availableOptions={availableOptions}
      values={selectedValues}
      onApply={handleApply} />
  );
};

module.exports = StatusFilter;
