const TIMELINE_ALL = 'all_data';
const TIMELINE_TIMESHEET = 'timesheet';

const TIMELINE_LABELS = {
  [TIMELINE_ALL]: 'All data',
  [TIMELINE_TIMESHEET]: 'Timesheet',
};

const getTimelineLabel = (timelineName) => {
  const name = timelineName?.toLowerCase();
  return TIMELINE_LABELS[name] || name;
};

module.exports = {
  isMainTimeline(timelineName) {
    return timelineName.toLowerCase() === TIMELINE_ALL;
  },
  getTimelineLabel,
  TIMELINE_ALL,
  TIMELINE_TIMESHEET,
};
