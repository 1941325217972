/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const Menu = require('@common/Menu/Menu.react');
const Search = require('@common/SearchInput.react');
const Loader = require('@common/Loader/Loader.react');
const MemberResultElement = require('../Spreadsheet/ItemCell/MemberItemCell/MemberMenu/MemberResultElement.react');

module.exports = class MemberSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      filteredPeople: this.props.people,
      keyword: '',
    };
  }

  componentDidUpdate(prevProps) {
    const prevPeople = prevProps.people ? prevProps.people.length : 0;
    const newPeople = this.props.people ? this.props.people.length : 0;
    if (prevPeople !== newPeople) {
      this.setState({
        filteredPeople: this.props.members && this.props.members.length > 0
          ? this.props.people.filter((member) => !this.props.members
            .some((person) => person.id === member.id))
          : this.props.people,
      });
    }
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Filter the list of people with the given keyword, checking that they're not already added
   * @param keyword
   */
  handleMemberFilter(keyword) {
    this.setState({
      filteredPeople: this.props.people.filter((member) => (this.found(keyword, member.name)
        || this.found(keyword, member.surname))
        && !this.props.members.some((person) => person.id === member.id)),
      keyword,
    });
  }

  handleMemberSelect(id) {
    this.setState({
      filteredPeople: this.state.filteredPeople.filter((member) => member.id !== id),
    });
    this.props.onSelect(this.props.name);
  }

  onCloseMenu() {
    this.setState({
      showMenu: false,
      keyword: '',
    });
  }

  onMenuButtonClick() {
    if (!this.props.people && !this.props.isLoading) {
      this.props.getPeople();
    } else {
      this.handleMemberFilter('');
    }

    this.setState({
      showMenu: true,
    });
  }

  getCompleteName(member) {
    if (member) {
      return `${member.name} ${member.surname}`;
    }
  }

  getMembersResultList() {
    if (this.state.filteredPeople && this.state.filteredPeople.length > 0) {
      return (
        this.state.filteredPeople.map((member) => (
          <MemberResultElement key={member.id}
            onClick={this.handleMemberSelect.bind(this)}
            disabled={this.props.isDeleting}
            {...{
              ...member,
            }} />
        ))
      );
    }
    if (this.state.filteredPeople && this.state.filteredPeople.length === 0) {
      return <span className="project-canvas-menu-members__result--empty">No member found</span>;
    }
  }

  getMembersSearch() {
    if (this.props.isLoading) {
      return <Loader />;
    }
    return (
      <div className="project-canvas-menu-members__search">
        <Search placeholder="Add member"
          filter={this.handleMemberFilter.bind(this)}
          keyword={this.state.keyword} />
        <div className="members-result-list">
          {this.getMembersResultList()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <button type="button"
          ref={(el) => this.memberButton = el}
          className="material-input-button"
          onClick={this.onMenuButtonClick.bind(this)}>
          {this.props.value}
        </button>
        <Menu open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.memberButton}>
          <div className="project-canvas-menu project-canvas-menu-members">
            {this.getMembersSearch()}
          </div>
        </Menu>
      </div>
    );
  }
};
