/* eslint-disable react/sort-comp,no-param-reassign */
const React = require('react');
const Trend = require('@common/trend/TrendItem.react');
const TimeFilterFactory = require('../../../models/TimeFilter/TimeFilterFactory');
const FiscalYearService = require('../../../../../../../../../../services/FiscalYearService');

module.exports = class TrendsItem extends React.Component {
  constructor(props) {
    super(props);
    this.fiscalYearService = new FiscalYearService();
    this.colors = {
      positive: '#4ED88D',
      neutral: '#48a5f9',
      negative: '#DB4D69',
    };

    this.chartOptions = {
      props: {
        tooltipFormatter: props.tooltipFormatter,
      },
      chart: {
        type: 'spline',
        backgroundColor: 'none',
        style: {
          fontFamily: 'Rubik',
        },
        marginLeft: 0,
        spacingLeft: 0,
        marginRight: 0,
        spacingRight: 0,
      },
      title: {
        text: '',
      },
      yAxis: {
        visible: false,
      },
      xAxis: {
        gridLineWidth: 0,
        lineWidth: 0,
        tickWidth: 0,
        labels: {
          style: {
            color: '#CCCBCC',
          },
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      plotOptions: {
        series: {
          lineWidth: 1,
          label: {
            enabled: false,
          },
          marker: {
            enabled: false,
          },
          dataLabels: {
            props: {
              tooltipFormatter: props.tooltipFormatter,
            },
            formatter(tooltip) {
              return tooltip.props.tooltipFormatter(this);
            },
          },
        },
      },
      tooltip: {
        formatter(tooltip) {
          return tooltip.chart.userOptions.props.tooltipFormatter(this);
        },
      },
    };
  }

  getChartOptions() {
    return {
      ...this.chartOptions,
      series: this.getSeries(),
      xAxis: {
        ...this.chartOptions.xAxis,
        categories: this.getBreakpoints(),
      },
    };
  }

  renderChart() {
    Highcharts.chart(this.chartContainer, this.getChartOptions());
  }

  componentDidUpdate(prevProps) {
    const timeFilterHasChanged = this.props.timeFilter.period !== prevProps.timeFilter.period
      || this.props.timeFilter.frame !== prevProps.timeFilter.frame;
    if (this.props.months.length !== prevProps.months.length || timeFilterHasChanged) {
      this.renderChart();
    }
  }

  getClassName() {
    return `client-trend ${this.props.className}`;
  }

  getSeriesColor(series) {
    let color = this.colors.neutral;
    const todayBreakpointIndex = TimeFilterFactory(this.props.timeFilter)
      .getBreakpointIndexForDate(moment().format());
    if (todayBreakpointIndex > 0) {
      if (series[series.length - 1].y > series[0].y) {
        color = this.colors.positive;
      }
      if (series[series.length - 1].y < series[0].y) {
        color = this.colors.negative;
      }
    }
    return color;
  }

  /**
   * Add marker if the given frame is the last valuable frame of the series.
   * @param months
   * @param index
   * @param series
   */
  getFrameWithMarker(months, index) {
    const todayBreakpointIndex = TimeFilterFactory(this.props.timeFilter)
      .getBreakpointIndexForDate(moment().format());
    const total = this.props.getTotal(months);

    let frame = { y: total };

    if (index === todayBreakpointIndex) {
      frame = {
        ...frame,
        marker: { enabled: true },
      };
    }

    return frame;
  }

  /**
   * Add dataLabel if the given frame is the last valuable frame of the series.
   * @param frame
   * @param index
   * @param series
   */
  getFrameWithDataLabel(frame, index, series) {
    const todayBreakpointIndex = TimeFilterFactory(this.props.timeFilter)
      .getBreakpointIndexForDate(moment().format());

    if (index > 0 && index === todayBreakpointIndex) {
      frame = {
        ...frame,
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: '400',
            fontSize: '10px',
            textShadow: 'none',
            textOutline: 0,
            color: this.getSeriesColor(series),
          },
        },
      };
    }

    return frame;
  }

  getSeries() {
    const { months } = this.props;
    let data = [];
    switch (months.length) {
      case 3: // quarter
        data = this.getFrames(1);
        break;
      case 12: // 1 year
        data = this.getFrames(3);
        break;
      default: // 3 year or more
        data = this.getFrames(12);
    }
    const frameData = data.map(this.getFrameWithMarker.bind(this))
      .map(this.getFrameWithDataLabel.bind(this));
    return [{
      data: frameData,
      color: this.getSeriesColor(frameData),
    }];
  }

  /**
   * Takes an array of frames (which are months grouped by breakpoints) and empties the future ones,
   * because we don't want to display them in the report.
   *
   * @param {Array} frames
   * @return {Array}
   */
  getFramesWithEmptyFuture(frames) {
    const timeFilter = TimeFilterFactory(this.props.timeFilter);
    const todayBreakpointIndex = timeFilter.getBreakpointIndexForDate(moment().format());
    return frames.map((frame, index) => (index <= todayBreakpointIndex ? frame : []));
  }

  /**
   * Split this.props.months in sub-arrays of the given size and return them.
   * @param size
   * @return {Array}
   */
  getFrames(size) {
    const { months } = this.props;
    const frames = [];
    for (let i = 0; i < months.length; i++) {
      const row = Math.floor(i / size);
      if (!frames[row]) {
        frames[row] = [];
      }
      frames[row][i % size] = months[i];
    }
    return this.getFramesWithEmptyFuture(frames);
  }

  getYears() {
    const monthsByYear = this.getFrames(12);
    if (this.fiscalYearService.isSolarYear()) {
      return monthsByYear.map((months) => moment(`${months[0].month}-01`).year());
    }
    return monthsByYear.map((months) => `${moment(`${months[0].month}-01`).format('YY')}/${moment(`${months[months.length - 1].month}-01`).format('YY')}`);
  }

  getBreakpoints() {
    const { months } = this.props;
    switch (months.length) {
      case 3: // quarter
        return months.map((month) => moment(`${month.month}-01`).format('MMM'));
      case 12: // 1 year
        return ['Q1', 'Q2', 'Q3', 'Q4'];
      default: // 3 year or more
        return this.getYears();
    }
  }

  render() {
    return (
      <Trend title={this.props.title}
        total={this.props.totalInFrame}
        className={this.getClassName()}
        chart={(
          <div className="trend__report-chart"
            ref={(chartContainer) => this.chartContainer = chartContainer} />
        )}
        isWaiting={this.props.isWaiting} />
    );
  }
};
