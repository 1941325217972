/* eslint-disable react/no-unused-state,react/sort-comp */
const React = require('react');
const TextInput = require('@common/TextInput.react');

module.exports = class CardCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: false,
      name: '',
    };
  }

  onFocus() {
    this.setState({ focus: true });
  }

  onBlur() {
    this.setState({ focus: false });
  }

  onNameChange(name, value) {
    const trimmed = value.trim();
    if (trimmed !== this.state.name) {
      this.setState({ name: trimmed });
    }
  }

  handleKeyUp(e) {
    const { name } = this.state;
    if (e.keyCode === 13 && name !== '') {
      this.setState({
        name: '',
        focus: false,
      });
      this.props.onAddCard(name);
    }
  }

  render() {
    return (
      <div className="project-canvas-kanban__list-card-creator">
        <TextInput
          placeholder="+ New card"
          value={this.state.name}
          onChange={this.onNameChange.bind(this)}
          onFocus={this.onFocus.bind(this)}
          onKeyUp={this.handleKeyUp.bind(this)}
          onBlur={this.onBlur.bind(this)} />
      </div>
    );
  }
};
