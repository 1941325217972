/* eslint-disable react/jsx-no-bind */
const React = require('react');
const Modal = require('@common/modal2/Modal.react');

module.exports = ({ save, onCancelClick, saving, id, review }) => {
  const onSaveClick = () => {
    if (!saving) {
      save(id, review);
    }
  };

  const onCancelButtonClick = () => {
    if (!saving) {
      onCancelClick();
    }
  };

  const getFeedback = () => {
    if (saving) {
      return <div className="profile-contact-info__feedback">Saving...</div>;
    }
    return null;
  };

  const getActionButtonClassName = () => (saving ? 'wethod-button disabled' : 'wethod-button');

  return (
    <Modal onCancelClick={onCancelClick}>
      <div className="project-review__modal-content">
        <span className="bold">
          You won’t be able to edit your review once it’s saved.
          <br />
        </span>
        {' '}
        Are you sure you
        want to
        continue?
      </div>
      <div className="project-review__modal-actions">
        <button type="button"
          onClick={onCancelButtonClick}
          className={getActionButtonClassName()}>Cancel
        </button>
        <button type="button" onClick={onSaveClick} className={getActionButtonClassName()}>Yes, save
          it
        </button>
      </div>
      {getFeedback()}
    </Modal>
  );
};
