/* eslint-disable react/sort-comp */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const Menu = require('@common/Menu/Menu.react');
const StatusListMenu = require('./StatusMenu/StatusListMenu.react');
const StatusEditMenu = require('./StatusMenu/StatusEditMenu.react');

module.exports = class StatusItemCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      editStatusMode: false,
    };
  }

  onCloseMenu() {
    this.setState({ showMenu: false, editStatusMode: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  onEditClick() {
    this.setState({ showMenu: true, editStatusMode: true });
  }

  handleStatusChange(status) {
    this.setState({ showMenu: false, editStatusMode: false });
    this.props.editValue(this.props.item, this.props.attribute, status);
  }

  getMenuBody() {
    if (this.state.editStatusMode) {
      return (
        <StatusEditMenu
          statusList={this.props.structure.available_values}
          attribute={this.props.attribute}
          onDelete={this.props.deleteAvailableStatus}
          onUpdate={this.props.updateAvailableStatus}
          onCreate={this.props.createAvailableStatus} />
      );
    }
    return (
      <StatusListMenu
        statusList={this.props.structure.available_values}
        onStatusChange={this.handleStatusChange.bind(this)}
        onEditClick={this.onEditClick.bind(this)} />
    );
  }

  /**
   * Returns the updated value of the status linked to the cell
   * (The status could have changed name/color since it was selected for the cell)
   */
  getStatus() {
    const selectedStatus = this.props.structure.available_values
      .find((status) => status.id === this.props.value.id);

    if (!selectedStatus) { // status has been deleted
      return this.props.structure.available_values.find((status) => status.default === true);
    }

    return selectedStatus;
  }

  render() {
    const selectedStatus = this.getStatus();

    return (
      <TableCell
        key={this.props.id}
        className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button">
        <button type="button"
          ref={(el) => this.moreButtonEl = el}
          className="project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--status"
          onClick={this.onMenuButtonClick.bind(this)}
          style={{ backgroundColor: selectedStatus.color }}>
          {selectedStatus.name}
        </button>
        <Menu className="project-canvas-spreadsheet__cell-menu"
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          {this.getMenuBody()}
        </Menu>
      </TableCell>
    );
  }
};
