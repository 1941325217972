/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind */
const React = require('react');
const InfoTooltip = require('@common/tooltip/InfoIconTooltip.react');
const StatusElement = require('./StatusEditElement.react');

module.exports = class StatusEditMenu extends React.Component {
  constructor(props) {
    super(props);

    this.colorList = [
      'rgb(166, 198, 160)',
      'rgb(194, 208, 118)',
      'rgb(80, 227, 194)',
      'rgb(72, 165, 249)',
      'rgb(201, 151, 250)',
      'rgb(184, 94, 133)',
      'rgb(255, 198, 217)',
      'rgb(237, 152, 163)',
      'rgb(255, 102, 102)',
      'rgb(255, 147, 79)',
      'rgb(224, 179, 0)',
      'rgb(250, 192, 61)',
      'rgb(253, 225, 134)',
      'rgb(235, 210, 172)',
    ];

    this.newItemId = -1;

    this.state = {
      statusList: this.sortStatuses(this.props.statusList),
      statusChangingColor: null,
    };
  }

  sortStatuses(statuses) {
    return statuses.sort((a, b) => {
      // Status with sort attribute comes first, the other are sorted on id (most recent on the bottom)
      if (a.sort && b.sort) {
        return a.sort - b.sort;
      }
      return -a.sort || b.sort || a.id - b.id;
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const savedStatusList = prevState.statusList.filter((status) => status.id >= 0);
    if (savedStatusList.length !== this.props.statusList.length) {
      // Latest added status is added to the already saved ones and receives focus
      const latestId = Math.max(...this.props.statusList.map((status) => status.id));
      const latestStatus = this.props.statusList.filter((status) => status.id === latestId)[0];
      const updatedStatusList = savedStatusList.map((status) => ({
        ...status,
        focused: false,
      }))
        .concat({
          ...latestStatus,
          focused: true,
        });
      this.setState({ statusList: this.sortStatuses(updatedStatusList) });
    }
  }

  handleFinalChange(id) {
    let updatedStatus = null;
    const updatedStatuses = this.state.statusList.map((status) => {
      if (status.id === id) {
        updatedStatus = {
          ...status,
          completed: !status.completed,
        };
        return updatedStatus;
      }
      return status;
    });

    this.setState({
      statusList: updatedStatuses,
      statusChangingColor: null,
    });

    this.props.onUpdate(this.props.attribute, updatedStatus);
  }

  handleColorClick(id) {
    this.setState({ statusChangingColor: id });
  }

  handleColorChange(color) {
    let updatedStatus = null;
    const updatedStatuses = this.state.statusList.map((status) => {
      if (status.id === this.state.statusChangingColor) {
        updatedStatus = {
          ...status,
          color,
        };
        return updatedStatus;
      }
      return status;
    });

    this.setState({
      statusList: updatedStatuses,
      statusChangingColor: null,
    });

    this.props.onUpdate(this.props.attribute, updatedStatus);
  }

  handleStatusChange(id, value) {
    const updatedStatuses = this.state.statusList.map((status) => {
      if (status.id === id) {
        return {
          ...status,
          name: value,
        };
      }
      return status;
    });

    this.setState({
      statusList: updatedStatuses,
      statusChangingColor: null,
    });
  }

  handleStatusBlur(id) {
    const newStatus = this.state.statusList.find((status) => status.id === id);
    const oldStatus = this.props.statusList.find((status) => status.id === id);

    if (this.hasChanged(newStatus, oldStatus)) {
      this.props.onUpdate(this.props.attribute, newStatus);
    }
  }

  handleStatusDelete(id) {
    const updatedStatusList = this.state.statusList.filter((status) => status.id !== id);

    this.setState({
      statusList: updatedStatusList,
      statusChangingColor: false,
    });
    this.props.onDelete(this.props.attribute, id);
  }

  hasChanged(newStatus, oldStatus) {
    if (newStatus && oldStatus) {
      return newStatus.name !== oldStatus.name;
    }

    return !oldStatus;
  }

  getRandomColor() {
    const totalColors = this.colorList.length;
    const randomPosition = Math.floor(Math.random() * totalColors);

    return this.colorList[randomPosition];
  }

  addNewLabel() {
    const newStatus = {
      id: this.newItemId -= 1,
      name: null,
      color: this.getRandomColor(),
      attribute: this.props.attribute,
    };
    const updatedStatuses = this.state.statusList.concat(newStatus);

    this.setState({
      statusList: updatedStatuses,
      statusChangingColor: null,
    });
    this.props.onCreate(this.props.attribute, newStatus);
  }

  getColorList() {
    if (this.state.statusChangingColor) {
      const colors = this.colorList.map((color) => (
        <div key={color}
          style={{ backgroundColor: color }}
          className="project-canvas-menu-status-edit__color project-canvas-menu-status-edit__color--button"
          onClick={() => this.handleColorChange(color)} />
      ));
      return (
        <div className="project-canvas-menu-status-edit__color-list">
          {colors}
        </div>
      );
    }
  }

  getNewStatusButton() {
    if (this.state.statusList.filter((status) => status.id < 0).length === 0) {
      return (
        <div className="project-canvas-menu-status-edit__element">
          <span className="project-canvas-menu-status-edit__color project-canvas-menu-status-edit__color--empty" />
          <div className="project-canvas-menu-status-edit__element-label project-canvas-menu-status-edit__add-new"
            onClick={this.addNewLabel.bind(this)}>
            + New status
          </div>
        </div>
      );
    }
  }

  getStatusList() {
    return (
      this.state.statusList.map((status) => (
        <StatusElement key={status.id}
          status={status}
          placeholder="Add label"
          onColorClick={this.handleColorClick.bind(this)}
          onFinalChange={this.handleFinalChange.bind(this)}
          onChange={this.handleStatusChange.bind(this)}
          onBlur={this.handleStatusBlur.bind(this)}
          onDelete={this.handleStatusDelete.bind(this)} />
      ))
    );
  }

  render() {
    return (
      <div>
        <div className="project-canvas-menu-status-edit__header">
          <span>Manage status</span>
          <span>
            Set as final
            <InfoTooltip className="project-canvas-menu-status-edit__info-tooltip"
              content="Choose more than one final status when the situation applies" />
          </span>
        </div>
        <div className="project-canvas-menu project-canvas-menu-status-edit">
          {this.getStatusList()}
          {this.getNewStatusButton()}
        </div>
        {this.getColorList()}
      </div>
    );
  }
};
