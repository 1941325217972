const React = require('react');
const ReadOnly = require('@common/material-input/SidebarReadOnlyField.react');

const ReadOnlyField = ({ placeholder, value, icon = <span /> }) => (
  <ReadOnly placeholder={placeholder} value={value}>
    {icon}
  </ReadOnly>
);

module.exports = ReadOnlyField;
