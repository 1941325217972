const React = require('react');
const Infotip = require('@common/Infotip/Infotip.react');
const Option = require('../../../SearchAndSelectModal/SearchAndSelectModalOption.react');
const Label = require('../../../SearchAndSelectModal/SearchAndSelectModalOptionLabel.react');

const PricelistOption = ({
  id, value, onClick, checked, name, pricelistName, pricelistDescription, isDefault = false,
  ...rest
}) => {
  const icon = isDefault ? <Infotip label="Default" /> : null;
  return (
    <Option
      name={name}
      type={Option.TYPE_RADIO}
      checked={checked}
      value={value}
      id={id}
      label={<Label name={pricelistName} description={pricelistDescription} icon={icon} />}
      {...rest}
      onChange={onClick} />
  );
};

module.exports = PricelistOption;
