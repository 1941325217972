/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign */

const React = require('react');
const NumericField = require('@common/inputs/NumericField/BasicNumericField/BasicNumericField.react');

module.exports = class ConversionTaskLevel extends React.Component {
  constructor(props) {
    super(props);

    this.formatOptions = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      numeralDecimalScale: 1,
      numeralDecimalMark: ',',
      delimiter: '.',
      stripLeadingZeroes: true,
    };
  }

  amountHasChanged(value) {
    const current = parseFloat(this.props.amount);
    const newValue = parseFloat(value);
    return !(Number.isNaN(current) && Number.isNaN(newValue))
      && !(current === 0 && Number.isNaN(newValue))
      && current !== newValue;
  }

  handleChange(e) {
    let value = parseFloat(e.target.value);
    if (Number.isNaN(value)) {
      value = 0;
    }
    if (this.amountHasChanged(e.target.value)) {
      this.props.onChange({
        id: this.props.id,
        amount: value,
        name: this.props.name,
        cost: this.props.cost,
      }, this.props.idTask, this.props.idArea);
    }
  }

  render() {
    return (
      <div className="budget-table-header__total-level">
        <NumericField label={`task-${this.props.idTask}-level-${this.props.id}`}
          onChange={this.handleChange.bind(this)}
          value={this.props.amount}
          readOnly={this.props.readOnly}
          precision={1}
          hideZero />
      </div>
    );
  }
};
