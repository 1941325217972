const { connect } = require('react-redux');
const Component = require('../../components/modals/EditPeriodModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.includes('update-segments'),
  timelines: state.timelines,
});

const mapDispatchToProps = (dispatch) => ({
  save: (segments, timelineName) => dispatch(actions.updateSegments(segments, timelineName)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
