const React = require('react');
const Select = require('@common/inputs/Select/RoundedSelect/RoundedSelect.react');
const Item = require('@common/inputs/Select/SelectItem.react');

const CurrencySelect = ({ onChange, code = null, editable, availableCurrencies, label }) => {
  const currencies = availableCurrencies.map((currency) => (
    <Item key={currency.code} value={currency.code}>
      {currency.code}
    </Item>
  ));

  const getMasterCurrencyCode = () => {
    const masterCurrency = availableCurrencies.find((currency) => currency.is_master);
    if (masterCurrency) return masterCurrency.code;
    return null;
  };

  /**
   * Show master currency as selected if no code is specified.
   * */
  const selectedValue = code || getMasterCurrencyCode();

  return (
    <Select className="wethod-budget-currency-select"
      label={label}
      value={selectedValue}
      readOnly={!editable}
      onChange={onChange}
      name="currency">
      {currencies}
    </Select>
  );
};

module.exports = CurrencySelect;
