const React = require('react');
const moment = require('moment');
const PropTypes = require('prop-types');
const TimeInput = require('../TimeInput/TimeInput.react');
const FormatService = require('../../../../services/FormatService');
const EventService = require('../../../../services/EventService');

class DurationInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    this.validate(this.state.value);
  }

  componentDidUpdate(prevProps) {
    const changedFromParent = this.props.value !== prevProps.value;
    if (changedFromParent) {
      this.syncWithParent();
    }
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors(this.props.name);
    }
  }

  handleChange(e) {
    const durationString = e.target.value;
    const duration = moment.duration(durationString);
    const value = duration.as(this.props.unit);

    e.persist();

    this.setState({ value }, () => {
      const event = this.formatEvent(e);
      this.validate(value);
      this.onParentChange(event);
    });
  }

  handleBlur(e) {
    const event = this.formatEvent(e);
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  onParentChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  validate(value) {
    if (this.props.validate) {
      const attributes = { unit: this.props.unit, format: this.props.format };
      this.props.validate(this.props.name, value, attributes);
    }
  }

  syncWithParent() {
    this.setState({ value: this.props.value }, () => {
      this.validate(this.state.value);
    });
  }

  formatEvent(e) {
    return EventService.cloneEvent(e, {
      name: this.props.name,
      value: this.state.value,
    });
  }

  render() {
    return (
      <TimeInput onChange={this.handleChange}
        value={FormatService.formatDuration(this.state.value, this.props.unit, this.props.format)}
        timePattern={this.props.format}
        id={this.props.id}
        name={this.props.name}
        required={this.props.required}
        readOnly={this.props.readOnly}
        disabled={this.props.disabled}
        onBlur={this.handleBlur} />
    );
  }
}

DurationInput.UNIT_SECONDS = FormatService.DURATION_UNIT_SECONDS;
DurationInput.UNIT_MINUTES = FormatService.DURATION_UNIT_MINUTES;

DurationInput.FORMAT_HMS = FormatService.DURATION_PATTERN_HMS;
DurationInput.FORMAT_MS = FormatService.DURATION_PATTERN_MS;
DurationInput.FORMAT_HM = FormatService.DURATION_PATTERN_HM;

DurationInput.defaultProps = {
  format: TimeInput.TIME_PATTERN_MS,
  name: undefined,
  validate: undefined,
  resetErrors: undefined,
  id: undefined,
  readOnly: false,
  required: false,
  disabled: false,
  onBlur: undefined,
};

DurationInput.propTypes = {
  value: PropTypes.number.isRequired,
  unit: PropTypes.oneOf([
    DurationInput.UNIT_SECONDS,
    DurationInput.UNIT_MINUTES,
  ]).isRequired,
  format: PropTypes.oneOf([
    DurationInput.FORMAT_HMS,
    DurationInput.FORMAT_HM,
    DurationInput.FORMAT_MS,
  ]),
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  /**
   * Function to call to validate the given value.
   * @param name {string}
   * @errors errors {[]}
   */
  validate: PropTypes.func,
  /**
   * Function to call to reset errors for current input.
   * @param name {string}
   */
  resetErrors: PropTypes.func,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
};

module.exports = DurationInput;
