/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');

module.exports = class StatusItemCellView extends React.Component {
  render() {
    return (
      <TableCell
        key={this.props.id}
        className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button">
        <div
          className="project-canvas-spreadsheet__cell-button-content"
          style={{ backgroundColor: this.props.value.color, cursor: 'initial' }}>
          {this.props.value.name}
        </div>
      </TableCell>
    );
  }
};
