const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const AddItemButton = require('./AddItemButton.react');
const WithBuEnabled = require('../containers/WithBuEnabled');
const BuFilter = require('../containers/BusinessUnitFilter');
const SegmentSelect = require('../containers/SegmentSelect');

const Actions = ({ canCreate }) => (
  <div className="pipeline__actions">
    <div className="pipeline__actions-left">
      <WithBuEnabled>
        <BuFilter />
      </WithBuEnabled>
      <SegmentSelect />
    </div>
    <div className="pipeline__actions-right">
      <ShowIf condition={canCreate}>
        <AddItemButton />
      </ShowIf>
    </div>
  </div>
);

module.exports = Actions;
