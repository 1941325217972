const { connect } = require('react-redux');
const Component = require('../../components/modals/UpdateFteConfirmationModal.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  isSaving: state.waitingFor.some((key) => key.includes('save-default-fte')),
});

const mapDispatchToProps = (dispatch) => ({
  save: (value) => dispatch(actions.updateDefaultFullTimeEquivalent(value)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
