const React = require('react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');

const DisableMulticurrencyModalContent = ({
  closeModal, saveInfo, budgetIsEditable, isPending,
}) => {
  const isSaving = () => isPending('save-budget-info');

  const feedback = isSaving() ? 'Saving...' : '';

  function onSaveClick() {
    saveInfo({ currency_enabled: false });
  }

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          When you disable multicurrency for a budget, final net and external costs <span className="bold">lose their currencies</span>.
        </ModalBodyBlock>
        <ModalBodyBlock>
          If <a href="#finance/invoice">invoices</a> for the budget are present, their value and
          currency is <span className="bold">automatically updated</span> to reflect budget's final
          net.
        </ModalBodyBlock>
        <ModalBodyBlock>
          If <a href="#finance/order">orders</a> for the budget are present, you need to <span className="bold">manually update</span> them to be aligned with budget's external costs.
        </ModalBodyBlock>
        <ModalBodyBlock>Do you want to proceed?</ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={feedback}>
        <ModalFooterAction onClick={closeModal} disabled={!budgetIsEditable}>
          Cancel
        </ModalFooterAction>
        <ModalFooterAction onClick={onSaveClick} disabled={!budgetIsEditable}>
          OK
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  );
};

module.exports = DisableMulticurrencyModalContent;
