const React = require('react');
const _ = require('underscore');
const TableCell = require('@common/Table2/TableCell.react');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const Autocomplete = require('@common/inputs/Autocomplete/BasicAutocomplete/BasicAutocomplete.react');
const ClientCreator = require('@common/inputs/Autocomplete/creators/ClientCreator.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');
const AutocompleteModel = require('../../../../../../../models/PipelineAutocomplete');

module.exports = class ClientCell extends React.Component {
  static getInputValue(item) {
    return _.unescape(item.corporate_name);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getClients(keyword)
      .done((collection) => this.setState({
        availableItems: collection.toJSON(),
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const { value } = e.target;
    const client = item ? {
      id: value,
      corporate_name: item.corporate_name,
    } : null;

    this.props.onChange(e, client);
  }

  onCreate(item) {
    const event = {
      target: {
        value: item.id,
        name: this.props.name,
      },
    };
    this.onChange(event, item);
  }

  getOptions() {
    return this.state.availableItems.map((client) => (
      <SelectItem key={client.id}
        value={client.id}
        corporate_name={client.hint}>
        {_.unescape(client.hint)}
      </SelectItem>
    ));
  }

  getHelperText() {
    if (this.props.rowHasFocus && this.props.isContactRequired) {
      return 'Edit in sidebar';
    }
    return '';
  }

  render() {
    return (
      <TableCell className="pipeline-item__client" style={this.props.style}>
        <InputValidator updateErrors={this.props.updateErrors} constraints={['required']}>
          <InputMetadataManager metadataService={this.props.metadataService}
            name={this.props.name}
            canEdit={!this.props.readOnly && !this.props.isContactRequired}>
            <Autocomplete
              label={this.props.label}
              helperText={this.getHelperText()}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              onChange={this.onChange.bind(this)}
              value={this.props.value}
              getInputValue={ClientCell.getInputValue}
              id={`${this.props.projectId}-client`}
              onFilter={this.onFilter.bind(this)}
              errorText={this.props.error}
              creator={ClientCreator}
              onCreate={this.onCreate.bind(this)}
              loading={this.state.isLoading}>
              {this.getOptions()}
            </Autocomplete>
          </InputMetadataManager>
        </InputValidator>
      </TableCell>
    );
  }
};
