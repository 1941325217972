const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const AddButton = require('../containers/AddButton');
const OwnerFilter = require('../containers/OwnerFilter');

const Actions = ({ canCreateItem }) => (
  <div className="wethod-section-actions">
    <div className="allocation-requests__actions-right">
      <OwnerFilter />
    </div>
    <div className="allocation-requests__actions-right">
      <ShowIf condition={canCreateItem()}>
        <AddButton />
      </ShowIf>
    </div>
  </div>
);

module.exports = Actions;
