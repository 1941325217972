const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const RadialIndicator = require('@common/RadialIndicator/RadialIndicator.react');

const MarginCell = ({ value, color, style }) => (
  <TableCell name="margin" className="pipeline-item__margin" style={style}>
    <RadialIndicator value={value} color={color} />
  </TableCell>
);

module.exports = MarginCell;
