const { connect } = require('react-redux');
const TypeSwitcher = require('@common/TabSwitcher.react');

const mapStateToProps = (state) => ({
  items: [
    {
      key: 'full',
      name: 'Expanded',
    }, {
      key: 'compact',
      name: 'Compact',
    },
  ],
  current: state.view,
});
module.exports = connect(mapStateToProps, null)(TypeSwitcher);
