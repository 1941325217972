const React = require('react');
const Avatar = require('@common/AvatarBox/Avatar.react');

module.exports = class ProfilePicPicker extends React.Component {
  onDeleteClick() {
    this.props.deleteProfilePic(this.props.employee);
  }

  getActions() {
    if (this.props.editable) {
      return (
        <div className="profile-pic-picker__actions">
          <button type="button" className="profile-pic-picker__action--delete" onClick={this.onDeleteClick.bind(this)}>
            <div className="wethod-icon-medium-delete wethod-icon-medium-delete--black" />
          </button>
          <button type="button" className="profile-pic-picker__action--edit" onClick={this.props.showProfilePicModal}>
            <div className="wethod-icon-medium-edit wethod-icon-medium-edit--black" />
          </button>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="profile-pic-picker">
        <Avatar name={this.props.name} pic={this.props.pic} size="big" />
        {this.getActions()}
      </div>
    );
  }
};
