const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const IconLink = require('@common/TooltipFixed/IconLinkTooltip.react');
const Avatar = require('@common/AvatarBox/Avatar.react');
const formatNumber = require('../../../../../../services/FormatService').formatDecimalNumber;
const Trend = require('../containers/ChargeabilityTrend');

const EmployeeRow = ({ employee, levels, selectedLevel }) => {
  const currentLevel = levels.filter((lvl) => lvl.id === employee.current_level)[0];
  const selectedLevelEmp = employee.levels.filter((lvl) => lvl.id === selectedLevel.id)[0];

  const isAbove = (value, target) => target && value && (value - target > 0);

  const isBelow = (value, target) => target && value && (target - value > 0);

  /**
   * Check whether the current level of the employee is equal to the selected level.
   * When the selected level is 'all', the current level is considered equal.
   *
   * @returns {boolean}
   */
  const isCurrentLevelEqualToSelected = () => {
    const isSelectedAll = !selectedLevel || !selectedLevel.id;
    const isLevelEqual = employee.current_level === selectedLevel.id;

    return isSelectedAll || isLevelEqual;
  };

  function getValue(item, attribute) {
    return item ? item[attribute] : null;
  }

  function getData() {
    const data = getValue(selectedLevelEmp, 'data');
    return data || [];
  }

  function getAvgStyle(avg, target) {
    let style = 'chargeability__column-avg chargeability__value';
    if (isAbove(avg, target)) style += ' chargeability__value--positive';
    if (isBelow(avg, target)) style += ' chargeability__value--negative';

    return style;
  }

  function getNonEmptyFormattedValue(value) {
    return (value || value === 0) ? formatNumber(value, false, 1) : '-';
  }

  const getName = () => {
    const nameString = _.unescape(employee.name);
    const surnameString = _.unescape(employee.surname);

    return `${nameString} ${surnameString}`;
  };

  // Get no tags, one ore two tag names
  const tagString = () => {
    let tag = '';
    tag += employee.tags[0] ? employee.tags[0].name : '';
    tag += employee.tags[1] ? `, ${employee.tags[1].name}` : '';
    return tag;
  };

  const getLinkButton = () => {
    if (!employee.archived) {
      const profileLink = `#settings/profile/${employee.id}`;
      return (
        <IconLink label="Go to profile"
          className="chargeability__button"
          borderless
          href={profileLink}
          target="_blank">
          <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
        </IconLink>
      );
    }
    return null;
  };

  const getCurrentLevelLabel = () => {
    if (employee.archived) {
      return (
        <span className="uppercase">Archived</span>
      );
    }
    if (!isCurrentLevelEqualToSelected()) {
      return (
        <span>now <span className="upperCase">{currentLevel.short_name}</span></span>
      );
    }
    return (
      <span className="upperCase">{currentLevel.short_name}</span>
    );
  };

  return (
    <TableRow className="chargeability__employee-row">
      <TableCell name="name" className="chargeability__column-name">
        <div className="chargeability__employee-pic">
          <Avatar name={getName()} pic={employee.pic} size="medium" />
        </div>
        <div className="chargeability__employee-info">
          <div>{getName()}</div>
          <div>{tagString()}</div>
          <div>{getCurrentLevelLabel()}</div>
        </div>
      </TableCell>
      <TableCell name="trend" className="chargeability__column-trend">
        <Trend target={selectedLevel.target} data={getData()} />
      </TableCell>
      <TableCell name="avg" className={getAvgStyle(getValue(selectedLevelEmp, 'avg_chargeability'), selectedLevel.target)}>
        {getNonEmptyFormattedValue(getValue(selectedLevelEmp, 'avg_chargeability'))}
      </TableCell>
      <TableCell name="link" className="chargeability__column-link">{getLinkButton()}</TableCell>
    </TableRow>
  );
};

module.exports = EmployeeRow;
