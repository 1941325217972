const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');

const CannotEditFeedbackModal = ({ onCloseClick, winProbabilityThreshold }) => (
  <Modal title="Won/lost feedback" onCancelClick={onCloseClick}>
    <p>
      You can add a feedback only for <b>won</b> (probability greater or equal to {
      winProbabilityThreshold
    })
      {' '}
      or <b>lost</b> (probability equal to 0) projects.
    </p>
    <div className="wethod-modal2__actions">
      <Button onClick={onCloseClick}>Cancel</Button>
    </div>
  </Modal>
);

module.exports = CannotEditFeedbackModal;
