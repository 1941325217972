/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,array-callback-return,react/jsx-no-bind */
const React = require('react');
const Loader = require('@common/Loader/Loader.react');
const DropDownItem = require('./DropDownItem.react');

/**
 * A dropdown element with a text input and a ul list of DropDownItems
 */
class ProjectDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.sortProjects = this.sortProjects.bind(this);
    this.filterProjects = this.filterProjects.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);

    const projects = this.getItems(this.sortProjects(this.props.projects));
    this.state = {
      projects,
    };
  }

  componentDidUpdate(prevProps) {
    const prevProjects = prevProps.projects ? prevProps.projects.length : 0;
    const currentProjects = this.props.projects ? this.props.projects.length : 0;

    if (prevProjects !== currentProjects) { // Update project list when it changes
      this.setState({ projects: this.getItems(this.sortProjects(this.props.projects)) });
    }

    this.dropDownInput.focus();
  }

  getItems(projects) {
    return projects.map((project) => (
      <DropDownItem key={project.id}
        project={project}
        projectSelected={this.props.projectSelected} />
    ));
  }

  /**
   * Sort projects alphabetically
   * @param projects
   * @returns {*}
   */
  sortProjects(projects) {
    return projects.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Filter projects by an array of keys
   * @param projects
   * @param keys
   * @returns {*}
   */
  filterProjects(projects, keys) {
    return projects.filter((project) => {
      let found = true;
      keys.map((key) => {
        const concat = [project.name, project.client.name, project.pm.name, project.pm.surname, project.job_order].join(' ');
        found = found && concat.toLowerCase().includes(key);
      });
      return found;
    });
  }

  onKeyUp(event) {
    const keys = event.target.value.toLowerCase().split(' ');
    let projects = this.filterProjects(this.props.projects, keys);
    projects = this.sortProjects(projects);

    this.setState({
      projects: this.getItems(projects),
    });
  }

  render() {
    let list = null;
    if (this.props.loading) { // Show loader
      list = (
        <div className="planning-people__loader-container">
          <Loader />
        </div>
      );
    } else if (this.state.projects.length) {
      list = <ul className="dropdown__list">{this.state.projects}</ul>;
    } else {
      list = (
        <p className="dropdown__message">
          Nothing here!&nbsp;
          <a href="http://support.wethod.com/planning/index/#the-structure" target="_blank" rel="noreferrer">
            Can't find a project?
          </a>
        </p>
      );
    }

    return (
      <div
        className="dropdown"
        style={{ display: this.props.visible ? 'block' : 'none' }}
        ref={(div) => {
          this.dropdown = div;
        }}>
        <input
          ref={(input) => {
            this.dropDownInput = input;
          }}
          id="input"
          className="dropdown__input"
          type="text"
          placeholder="Type project/client/PM/job order"
          autoComplete="off"
          disabled={this.props.loading}
          onKeyUp={this.onKeyUp}
          onFocus={(event) => {
            event.target.select();
          }} />
        {list}
      </div>
    );
  }
}

module.exports = ProjectDropDown;
