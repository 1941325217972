const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');
const ConfirmButton = require('@common/Button/PositiveButton.react');
const WarningButton = require('@common/Button/WarningButton.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

const AllocationApproveModal = ({
  item, isSaving, onCloseClick, approveAllocation, rejectAllocation,
}) => {
  function handleApprove() {
    approveAllocation(item.id);
  }

  function handleReject() {
    rejectAllocation(item.id);
  }

  return (
    <Modal title="Manage allocation request" onCancelClick={onCloseClick}>
      <p>
        What do you want to do with this request?
      </p>
      <br />
      <div className="wethod-modal2__actions">
        <Button onClick={onCloseClick}>Back</Button>
        <WarningButton onClick={handleReject} disabled={isSaving}>Reject</WarningButton>
        <ConfirmButton onClick={handleApprove} disabled={isSaving}>Approve</ConfirmButton>
      </div>
      <div className="wethod-modal2__feedback">
        <ShowIf condition={isSaving}>
          <span>Saving...</span>
        </ShowIf>
      </div>
    </Modal>
  );
};

module.exports = AllocationApproveModal;
