/* eslint-disable react/no-did-update-set-state */
const React = require('react');
const TextArea = require('@common/material-input/SidebarTextArea.react');

module.exports = class TextMenuEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      const value = this.props.value ? this.props.value : '';
      this.setState({ value });
    }
  }

  handleChange(name, value) {
    this.setState({ value });
  }

  handleSave() {
    this.props.onSave(this.state.value);
  }

  render() {
    return (
      <div className="project-canvas-menu project-canvas-menu-text" onBlur={this.handleSave.bind(this)}>
        <TextArea name="text" placeholder="" value={this.state.value} rows={5} onChange={this.handleChange.bind(this)} />
      </div>
    );
  }
};
