const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const BudgetFooterButton = require('../BudgetFooterButton.react');

const CompareVersionsAction = ({ versions, onClick }) => {
  // This action makes no sense if budget does not have at least 2 version to compare
  const disabled = versions.length < 2;

  return (
    <BudgetFooterButton label="Compare versions" onClick={onClick} disabled={disabled} noBorder>
      <Icon icon="compare" />
    </BudgetFooterButton>
  );
};

module.exports = CompareVersionsAction;
