/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign */

const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Loading = require('@common/LoadingSmall.react');
const BudgetModel = require('@models/Budget');
const Areas = require('./ConversionAreas.react');
const Totals = require('./ConversionModalTotalsHeader.react');

/**
 * Allow to convert budget days from one level to another.
 * Conversion can happen only between levels belonging to same pricelist so, when user start editing
 * a days input, all inputs belonging to areas related to different pricelists are disabled.
 *
 * @type {ConversionModal}
 */
module.exports = class ConversionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused_pricelist: null, // pricelist related to the current area edited by the user
      areas: null,
      price_list_levels: null,
      totals: null,
      availableAmount: 0,
      isWaiting: true,
      scrollLeft: 0,
      showShadow: false,
    };
    this.levelsHeaderReference = null;
    this.totalsHeaderReference = null;
    this.areasReference = null;
    this.onScroll = this.onScroll.bind(this);
    this.setLevelsHeaderReference = this.setLevelsHeaderReference.bind(this);
    this.setTotalsHeaderReference = this.setTotalsHeaderReference.bind(this);
    this.setAreasReference = this.setAreasReference.bind(this);
  }

  compareLevelsByOrder(levelA, levelB) {
    if (levelA.order < levelB.order) {
      return -1;
    }
    if (levelA.order > levelB.order) {
      return 1;
    }
    return 0;
  }

  getSortedLevels(levels) {
    return levels.sort(this.compareLevelsByOrder);
  }

  componentDidMount() {
    $.when(BudgetModel.getForSwap(this.props.id_project)).done((info) => {
      this.setState({
        areas: info.areas,
        price_list_levels: this.getSortedLevels(info.price_list_levels),
      }, () => {
        const totals = this.getTotals(this.state.areas);
        this.setState({
          totals,
          isWaiting: false,
        });
      });
    }).fail((message) => {
      const sentences = {
        'Budget must be approved': 'The budget is not approved yet, you can edit it directly.',
      };
      const sentence = sentences?.[message] || 'The changes you have made cannot be saved. Please try again.';
      this.props.showModal('error', { sentence });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isSaving && !this.props.isSaving) {
      this.props.onCancelClick();
    }
    if (prevState.isWaiting !== this.state.isWaiting) {
      if (this.levelsHeaderReference.scrollWidth > this.levelsHeaderReference.clientWidth) {
        this.setState({ ...this.state, showShadow: true });
      }
    }
    if (prevState.scrollLeft !== this.state.scrollLeft) {
      if (this.levelsHeaderReference) {
        this.levelsHeaderReference.scrollTo({ left: this.state.scrollLeft });
      }
      if (this.totalsHeaderReference) {
        this.totalsHeaderReference.scrollTo({ left: this.state.scrollLeft });
      }
      if (this.areasReference) {
        this.areasReference.scrollTo({ left: this.state.scrollLeft });
      }
    }
  }

  setLevelsHeaderReference(el) {
    this.levelsHeaderReference = el;
  }

  setTotalsHeaderReference(el) {
    this.totalsHeaderReference = el;
  }

  setAreasReference(el) {
    this.areasReference = el;
  }

  getFeedBack() {
    if (this.props.isSaving) {
      return <div className="planning-conversion-modal__feedback">Saving</div>;
    }
    if (this.state.availableAmount < 0) {
      return (
        <div className="planning-conversion-modal__feedback planning-conversion-modal__feedback--error">
          The number
          of days
          exceeds the limit
        </div>
      );
    }
  }

  getActions() {
    return (
      <div className="profile-contact-info__actions">
        <button
          type="button"
          onClick={this.props.onCancelClick}
          className="wethod-button wethod-button--no-border">
          Dismiss
        </button>
        <button type="button"
          onClick={this.handleConfirmClick.bind(this)}
          className={this.getButtonStyle()}>
          Confirm
        </button>
        {this.getFeedBack()}
      </div>
    );
  }

  canSave() {
    return !this.props.isSaving && this.state.availableAmount >= 0;
  }

  getButtonStyle() {
    let style = 'wethod-button';
    if (!this.canSave()) {
      style += ' disabled';
    }
    return style;
  }

  handleConfirmClick() {
    if (this.canSave()) {
      this.props.saveBudgetConversion(this.props.id_project, this.state.areas);
    }
  }

  /**
   * Return area with given id.
   * @param id
   * @return {*}
   */
  getArea(id) {
    return this.state.areas.find((area) => area.id === id);
  }

  /**
   * Return last touched area, if any.
   *
   * A "touched" area is an area whose days are changed but not yet saved.
   *
   * @param {*} updatedTotals
   * @return {*|null}
   */
  getLastTouchedArea(updatedTotals) {
    const currentTotals = this.state.totals;
    let updatedAreaId = null;

    // An area is considered "touched" if its total is different from the current one
    for (let i = 0; i < currentTotals.areas.length; i++) {
      const prevTotal = currentTotals.areas[i];
      const updatedTotal = updatedTotals[i];

      if (prevTotal.total !== updatedTotal.total) {
        updatedAreaId = updatedTotal.id;
      }
    }

    return updatedAreaId ? this.getArea(updatedAreaId) : null;
  }

  /**
   * Return pricelist related to last touched area, if any.
   *
   * @param updatedTotals
   * @return {*|null}
   */
  getFocusedPricelist(updatedTotals) {
    const updatedArea = this.getLastTouchedArea(updatedTotals);

    return updatedArea ? updatedArea.price_list : null;
  }

  handleChange(updatedLevel, idTask, idArea) {
    let { availableAmount } = this.state;
    let updatedTask = null;
    const updatedAreas = this.state.areas.map((area) => {
      if (area.id === idArea) {
        area.tasks = area.tasks.map((task) => {
          if (task.id === idTask) {
            updatedTask = task;
            task.price_list_levels = task.price_list_levels.map((level) => {
              if (level.id === updatedLevel.id) {
                const availableDays = level.amount - updatedLevel.amount;
                availableAmount += availableDays * updatedLevel.cost;
                level.amount = updatedLevel.amount;
              }
              return level;
            });
          }
          return task;
        });
      }
      return area;
    });

    const updatedTotals = this.getTotals(updatedAreas);
    const updatedTaskPricelistLevels = updatedTask.price_list_levels;
    this.setState({
      areas: updatedAreas,
      totals: updatedTotals,
      availableAmount,
      price_list_levels: this.state.price_list_levels.map((pricelistLevel) => {
        const focusedPricelistCost = updatedTaskPricelistLevels
          .find((level) => level.level.id === pricelistLevel.level.id).cost;
        return {
          ...pricelistLevel,
          cost: focusedPricelistCost,
        };
      }),
      focused_pricelist: this.getFocusedPricelist(updatedTotals.areas),
    });
  }

  getLevelCost(levelId) {
    const level = this.state.price_list_levels.filter((level) => level.level.id === levelId);
    return level ? level[0].cost : null;
  }

  getAreaTotal(area, levelsTotal) {
    let areaTotal = 0;
    let areaLevelsTotal = {};
    const tasks = area.tasks.map((task) => {
      const taskTotal = this.getTaskTotal(task, areaLevelsTotal, levelsTotal, area.enabled);
      areaTotal += taskTotal.total;
      return taskTotal;
    });

    areaLevelsTotal = this.state.price_list_levels.map((level) => ({
      id: level.level.id,
      total: areaLevelsTotal[level.level.id],
    }));

    return {
      id: area.id,
      total: areaTotal,
      tasks,
      price_list_levels: areaLevelsTotal,
    };
  }

  getTaskTotal(task, areaLevelsTotal, levelsTotal, isAreaEnabled) {
    let taskTotal = null;
    taskTotal = task.price_list_levels.reduce((total, level) => {
      if (areaLevelsTotal) {
        areaLevelsTotal[level.level.id] = areaLevelsTotal[level.level.id]
          ? areaLevelsTotal[level.level.id] + level.amount : level.amount;
      }
      if (levelsTotal && isAreaEnabled) {
        levelsTotal[level.level.id] = levelsTotal[level.level.id]
          ? levelsTotal[level.level.id] + level.amount : level.amount;
      }
      return total + level.amount;
    }, 0);

    return {
      id: task.id,
      total: taskTotal,
    };
  }

  getTotals(areas) {
    let total = 0;
    let levelsTotal = {};
    const areaTotals = areas.map((area) => {
      const areaTotal = this.getAreaTotal(area, levelsTotal);
      if (area.enabled) total += areaTotal.total;
      return areaTotal;
    });

    levelsTotal = this.state.price_list_levels.map((level) => ({
      id: level.level.id,
      total: levelsTotal[level.level.id],
    }));

    return {
      areas: areaTotals,
      levels: levelsTotal,
      total,
    };
  }

  onScroll(e) {
    this.setState({ ...this.state, scrollLeft: e.target.scrollLeft });
  }

  getFocusedPricelistId() {
    return this.state.focused_pricelist?.id;
  }

  getBody() {
    if (this.state.isWaiting) {
      return (<Loading />);
    }
    return (
      <span>
        <Totals
          setLevelsHeaderReference={this.setLevelsHeaderReference}
          onScroll={this.onScroll}
          setTotalsHeaderReference={this.setTotalsHeaderReference}
          levels={this.state.price_list_levels}
          totals={this.state.totals}
          availableAmount={this.state.availableAmount} />
        <Areas
          setAreasReference={this.setAreasReference}
          onScroll={this.onScroll}
          areas={this.state.areas}
          levels={this.state.price_list_levels}
          totals={this.state.totals.areas}
          onChange={this.handleChange.bind(this)}
          showShadows={this.state.showShadow}
          focusedPricelistId={this.getFocusedPricelistId()} />
        {this.getActions()}
      </span>
    );
  }

  render() {
    return (
      <Modal title="Convert budget days"
        onCancelClick={this.props.onCancelClick}
        className="planning-conversion-modal">
        {this.getBody()}
      </Modal>
    );
  }
};
