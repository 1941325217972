const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const BudgetFooterButton = require('../BudgetFooterButton.react');

const NotesAction = ({ onClick, isEmpty }) => (
  <BudgetFooterButton label="Notes" onClick={onClick} noBorder>
    <Icon icon="notes" variant={isEmpty ? '' : 'done'} />
  </BudgetFooterButton>
);

module.exports = NotesAction;
