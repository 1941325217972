/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');

module.exports = class ProjectNoteCell extends React.Component {
  /**
   * Notes can not be edited and saved when a project has 0 hours;
   * It's not safe to edit and save notes while a project is saving (e.g. timesheet can be deleted
   * while we edit a note) You can only open notes for archived projects if there was any
   */
  canOpenNote() {
    return this.props.project.project.archived
      ? this.props.project.notes
      : this.props.project.hours && !this.props.isWaiting;
  }

  onNoteClick(e) {
    e.stopPropagation();
    if (this.canOpenNote()) this.props.openNote(this.props.project);
  }

  getNotesIconClassName() {
    let name = 'wethod-icon-notes wethod-icon-notes--black timesheet-weekly__button';
    if (this.props.project.notes) name += ' wethod-icon-notes--done';
    if (!this.canOpenNote()) name += ' disabled';
    return name;
  }

  getCellStyle() {
    let style = 'timesheet-weekly__cell-notes hidden-mobile ';
    if (this.props.className) style += this.props.className;
    return style;
  }

  render() {
    return (
      <TableCell className={this.getCellStyle()}>
        <div className={this.getNotesIconClassName()}
          onClick={this.onNoteClick.bind(this)} />
      </TableCell>
    );
  }
};
