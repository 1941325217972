const React = require('react');
const PropTypes = require('prop-types');
const Modal = require('@common/Modal/Modal.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const Loader = require('@common/Loader/Loader.react');

/**
 * Modal with search bar and list of options
 */
class SearchAndSelectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { keyword: '' };

    this.searchDebounceTimer = null;

    this.onKeywordChange = this.onKeywordChange.bind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    const keywordChanged = this.state.keyword !== prevState.keyword;
    if (keywordChanged) {
      this.loadOptions();
    }
  }

  onKeywordChange(e) {
    const keyword = e.target.value;
    clearTimeout(this.searchDebounceTimer);
    this.searchDebounceTimer = setTimeout(() => {
      this.setState({ keyword });
    }, 500);
  }

  getFeedback() {
    if (this.props.isSaving) {
      return 'Saving...';
    }
    return '';
  }

  getLoader() {
    if (this.props.isLoading) {
      return <Loader />;
    }
    return null;
  }

  loadOptions() {
    this.props.loadOptions(this.state.keyword);
  }

  renderList() {
    if (this.props.errorMessage) {
      return <div className="empty-row">{this.props.errorMessage}</div>;
    }
    if (this.props.hasNoOptions) {
      if (this.props.isLoading) {
        return null;
      }
      return <div className="empty-row">{this.props.emptyMessage}</div>;
    }
    return <div className="wethod-budget-modal-job-title-insert__list">{this.props.children}</div>;
  }

  render() {
    return (
      <Modal title={this.props.title} onClose={this.props.closeModal} className="wethod-modal-search-and-select">
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              <TextField name="search" onChange={this.onKeywordChange} placeholder={this.props.searchPlaceholder} disabled={this.props.isSaving} suffix={this.getLoader()} />
            </ModalBodyBlock>
            <ModalBodyBlock>
              {this.renderList()}
            </ModalBodyBlock>
          </ModalBody>
          <Footer feedback={this.getFeedback()}>
            <FooterAction onClick={this.props.closeModal} disabled={this.props.isSaving}>
              Cancel
            </FooterAction>
            <FooterAction onClick={this.props.onSaveClick}
              disabled={this.props.isSaving || this.props.isEmptySelection}>
              {this.props.saveButtonLabel}
            </FooterAction>
          </Footer>
        </ModalContent>
      </Modal>
    );
  }
}

SearchAndSelectModal.defaultProps = {
  errorMessage: '',
  emptyMessage: 'Nothing found',
  searchPlaceholder: 'Search',
  saveButtonLabel: 'Save',
};

SearchAndSelectModal.propTypes = {
  /**
   * Function called to load options matching passed keyword.
   * @param {string} keyword
   */
  loadOptions: PropTypes.func.isRequired,
  /**
   * True if waiting for insert to complete.
   */
  isSaving: PropTypes.bool.isRequired,
  /**
   * True if waiting for options to be loaded.
   */
  isLoading: PropTypes.bool.isRequired,
  /**
   * Component or list of components to render in item list.
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  /**
   * Error message to display instead of item list.
   */
  errorMessage: PropTypes.string,
  /**
   * Message to show if no item matches the given keyword.
   */
  emptyMessage: PropTypes.string,
  /**
   * Modal title
   */
  title: PropTypes.string.isRequired,
  /**
   * Function called when user click on button to close modal.
   */
  closeModal: PropTypes.func.isRequired,
  /**
   * Placeholder text to show in searchbar.
   */
  searchPlaceholder: PropTypes.string,
  /**
   * True if no item is currently selected.
   */
  isEmptySelection: PropTypes.bool.isRequired,
  /**
   * Function called when user clicks save button.
   */
  onSaveClick: PropTypes.func.isRequired,
  /**
   * Label to use for save button.
   */
  saveButtonLabel: PropTypes.string,
  /**
   * True if there's no options to display.
   */
  hasNoOptions: PropTypes.bool.isRequired,
};

module.exports = SearchAndSelectModal;
