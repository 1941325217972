const React = require('react');
const $ = require('jquery');
const Table = require('@common/Table2/Table2.react');
const TableBody = require('@common/Table2/TableBody.react');
const Loader = require('@common/Loader/Loader.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const ProductRow = require('./Product.react');
const Search = require('../containers/ProductSearch');

module.exports = class ProductsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  componentDidMount() {
    this.updateTableHeight();
    this.getLevels();
  }

  componentDidUpdate(prevProps) {
    const changedAvailabilityFilter = prevProps.availabilityFilter
        !== this.props.availabilityFilter;

    if (changedAvailabilityFilter) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      this.getProducts(options);
    }
  }

  getList() {
    return this.props.products.map((product) => (
      <ProductRow
        key={product.id}
        id={product.id}
        product={product}
        showSidebar={this.props.showSidebar} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return 'There’s nothing here.';
  }

  getSearch() {
    return (
      <Search filter={this.filter.bind(this)} />
    );
  }

  getDefaultOptions() {
    return {
      offset: this.props.pageOffset,
      limit: this.props.pageLimit,
      search: this.props.search,
      order: this.props.order,
      sort: this.props.sort,
      availabilityFilter: this.props.availabilityFilter,
    };
  }

  getProducts(options) {
    if (!this.props.isWaiting) {
      this.props.getProducts(
        options.offset,
        options.limit,
        options.search,
        options.order,
        options.sort,
        options.availabilityFilter,
      );
    }
  }

  getSort(order) {
    return this.props.order === order ? this.props.sort : null;
  }

  getLevels() {
    this.props.getLevels();
  }

  loadMore(size, page) {
    const options = this.getDefaultOptions();
    options.offset = (page - 1) * size;
    options.limit = size;
    options.order = this.props.order;
    options.sort = this.props.sort;

    this.getProducts(options);
  }

  hasMorePages() {
    return this.props.hasMorePages;
  }

  updateTableHeight() {
    this.setState({ tableHeight: ProductsList.getTableMaxHeight() });
  }

  filter(search) {
    const options = this.getDefaultOptions();
    options.offset = 0;
    options.search = search;

    this.getProducts(options);
  }

  sort(order, sort) {
    if (!this.props.isWaiting) {
      const options = this.getDefaultOptions();
      options.offset = 0;
      options.order = order;
      options.sort = sort;
      this.getProducts(options);
    }
  }

  render() {
    return (
      <Table columns={8} maxHeight={this.state.tableHeight} search={this.getSearch()}>
        <TableHead>
          <TableRow>
            <TableCell name="name"
              className="products__column-product"
              onSort={this.sort.bind(this)}
              sort={this.getSort('name')}>
              Name
            </TableCell>
            <TableCell name="description"
              className="products__column-description"
              onSort={this.sort.bind(this)}
              sort={this.getSort('description')}>
              Description
            </TableCell>
            <TableCell name="availableFrom"
              className="products__column-date-from"
              onSort={this.sort.bind(this)}
              sort={this.getSort('availableFrom')}>
              From
            </TableCell>
            <TableCell name="availableTo"
              className="products__column-date-to"
              onSort={this.sort.bind(this)}
              sort={this.getSort('availableTo')}>
              To
            </TableCell>
            <TableCell name="externalCost"
              className="products__column-external-costs"
              onSort={this.sort.bind(this)}
              sort={this.getSort('externalCost')}>
              Ext. costs
            </TableCell>
            <TableCell name="price"
              className="products__column-price"
              onSort={this.sort.bind(this)}
              sort={this.getSort('price')}>
              Price
            </TableCell>
            <TableCell className="products__column-actions" />
          </TableRow>
        </TableHead>
        <TableBody hasMore={this.hasMorePages()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getList()}
        </TableBody>
      </Table>
    );
  }
};
