const React = require('react');
const Button = require('@common/Button/Button.react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');

const StatusButton = ({ onClick, children, isProjectFrozen = false }) => {
  const tooltipMessage = isProjectFrozen ? "This budget has been frozen. You won't be able to edit it again" : '';

  return (
    <Tooltip label={tooltipMessage}>
      <Button className="wethod-budget-status-button" onClick={onClick} disabled={isProjectFrozen}>{children}</Button>
    </Tooltip>
  );
};

module.exports = StatusButton;
