const React = require('react');
const Button = require('@common/Button/RoundedButton.react');

const Version = ({
  label,
  selected,
  disabled,
  isBaseline,
  onClick,
}) => {
  const classNameBaseline = isBaseline ? ' wethod-budget-version--baseline' : '';
  const className = selected ? 'wethod-budget-version wethod-budget-version--selected' : 'wethod-budget-version';

  function handleClick() {
    onClick(label);
  }

  return (
    <Button onClick={handleClick}
      className={className + classNameBaseline}
      border
      disabled={disabled}>{label}
    </Button>
  );
};

module.exports = Version;
