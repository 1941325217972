const React = require('react');
const moment = require('moment');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');

const HolidayErrorBody = ({
  errors,
  onCancelClick,
  onPositiveButtonClick,
  isSaving,
  projectsInfo,
}) => {
  const projects = errors.map((error) => (
    <div data-testid="repeat-error-item"
      key={`${error.project}_${error.date}`}
      className="planning-people-repeat-error__message-list-item">
      <span
        className="bold">
        {projectsInfo[error.project].name}
      </span>
      {' '}
      on
      {' '}
      <span>{moment(error.date).format('ddd,DD MMM YYYY')}</span>
    </div>
  ));

  const getFeedBack = () => {
    if (isSaving) {
      return 'Planning...';
    }
    return '';
  };

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          You are going to repeat an allocation on holidays:
        </ModalBodyBlock>
        <ModalBodyBlock>{projects}</ModalBodyBlock>
        <ModalBodyBlock>
          Would you like to continue?
        </ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={getFeedBack()}>
        <ModalFooterAction onClick={onCancelClick}>
          Cancel
        </ModalFooterAction>
        <ModalFooterAction onClick={onPositiveButtonClick}>
          Yes
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  );
};

module.exports = HolidayErrorBody;
