/* eslint-disable consistent-return */
const React = require('react');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

module.exports = class BasketEditableDetails extends React.Component {
  getAccount() {
    return this.props.opportunity.account_id
      ? `${this.props.opportunity.account.name} ${this.props.opportunity.account.surname}`
      : '';
  }

  getDate() {
    return this.props.opportunity.due_date
      ? moment(this.props.opportunity.due_date).format('DD/MM/YYYY')
      : '';
  }

  getFollowUp() {
    if (!this.props.opportunity.project_id) {
      return (
        <ReadOnlyField placeholder="Follow-up"
          value={this.getDate()}>
          <span className="wethod-icon wethod-icon-event wethod-icon-event--black" />
        </ReadOnlyField>
      );
    }
  }

  getClient() {
    return this.props.opportunity.client.name;
  }

  render() {
    return (
      <div>
        <div style={{ height: '30px' }} />
        <ReadOnlyField placeholder="Opportunity"
          value={this.props.opportunity.name}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Client"
          value={this.getClient()}>
          <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
        </ReadOnlyField>
        <div className="basket__sidebar--target-readonly basket__sidebar--multiple-input-element">
          <ReadOnlyField placeholder="Target"
            value={this.props.getFormattedValue()}>
            <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
          </ReadOnlyField>
          <ShowIf condition={!this.props.isValueAsUnit}>
            <div className="basket__sidebar--target-measure">K</div>
          </ShowIf>
        </div>
        {this.getFollowUp()}
        <ReadOnlyField placeholder="In Charge"
          value={this.getAccount()}>
          <span className="wethod-icon wethod-icon-pm" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Notes"
          value={this.props.opportunity.note}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </ReadOnlyField>
      </div>
    );
  }
};
