const { connect } = require('react-redux');
const SearchInput = require('@common/SearchInput.react');
const actions = require('../actions');

const mapStateToProps = () => ({
  placeholder: 'Search for event',
});

const mapDispatchToProps = (dispatch) => ({
  filter: (keyword) => dispatch(actions.filterEvents(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(SearchInput);
