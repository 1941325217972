const $ = require('jquery');
const TimeOffModel = require('@models/TimeOff');
const constants = require('./constants');

const showModal = (name, data) => ({
  type: constants.SHOW_MODAL,
  name,
  data,
});

module.exports.showExportModal = (year) => (dispatch) => {
  dispatch(showModal('export', { year }));
};

module.exports.showImportModal = (year) => (dispatch) => {
  dispatch(showModal('import', { year }));
};

module.exports.showUploadErrorModal = (error) => (dispatch) => {
  dispatch(showModal('upload-error', error));
};

const showImportErrorModal = (error) => (dispatch) => {
  dispatch(showModal('import-error', error));
};

const showImportSuccessModal = () => (dispatch) => {
  dispatch(showModal('import-success'));
};

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

const getItemsRequest = (offset, limit, search, year) => ({
  type: constants.GET_TIME_OFF_REQUEST,
  offset,
  limit,
  search,
  year,
});

const getItemsSuccess = (items) => ({
  type: constants.GET_TIME_OFF_SUCCESS,
  items,
});

module.exports.getItems = (offset, limit, search, year) => (dispatch) => {
  dispatch(getItemsRequest(offset, limit, search, year));

  const getListRequest = TimeOffModel.getAll(offset, limit, search, year);
  $.when(getListRequest)
    .done((items) => dispatch(getItemsSuccess(items)));
};

const saveItemSuccess = (item) => ({
  type: constants.SAVE_TIME_OFF_SUCCESS,
  item,
});

const saveItemRequest = (id) => ({
  type: constants.SAVE_TIME_OFF_REQUEST,
  id,
});

/**
 * Save the updated item.
 * @param year
 * @param employeeId
 * @param changes
 * @returns {(function(*): void)|*}
 */
module.exports.saveItem = (year, employeeId, changes) => (dispatch) => {
  dispatch(saveItemRequest(employeeId));

  $.when(TimeOffModel.update(year, employeeId, changes))
    .done((response) => {
      dispatch(saveItemSuccess(response));
    });
};

const exportRequest = () => ({
  type: constants.EXPORT_REQUEST,
});

const exportSuccess = () => ({
  type: constants.EXPORT_SUCCESS,
});

module.exports.export = (year) => (dispatch) => {
  dispatch(exportRequest());

  $.when(TimeOffModel.export(year)).done(() => {
    dispatch(exportSuccess());
    dispatch(closeModal());
  });
};

const importRequest = () => ({
  type: constants.IMPORT_REQUEST,
});

const importSuccess = () => ({
  type: constants.IMPORT_SUCCESS,
});

const importError = () => ({
  type: constants.IMPORT_SUCCESS,
});

module.exports.import = (year, file) => (dispatch) => {
  dispatch(importRequest());

  $.when(TimeOffModel.import(year, file)).done(() => {
    dispatch(importSuccess());
    dispatch(showImportSuccessModal());
  }).fail((error, data) => {
    const modalData = { message: error, data };
    dispatch(importError());
    dispatch(showImportErrorModal(modalData));
  });
};

module.exports.closeModal = closeModal;
