const React = require('react');
const _ = require('underscore');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const Button = require('@common/Button/RoundedButton.react');
const IconButton = require('@common/Button/IconButton.react');
const TextField = require('@common/inputs/TextField/BasicTextField/BasicTextField.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const InfoTip = require('@common/Infotip/Infotip.react');

module.exports = class EditRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: {
        ...props.item,
      },
      updatedItem: {},
    };

    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  handleItemChange(name, value) {
    // if the value is the same as in the props, pop the property from the updatedItem
    // otherwise add it to the updatedItem
    this.setState((prevState) => ({
      item: { ...prevState.item, [name]: value },
      updatedItem:
        value === this.props.item[name]
          ? _.omit(prevState.updatedItem, name)
          : { ...prevState.updatedItem, [name]: value },
    }));
  }

  handleNameChange(e) {
    const { name } = e.target;
    const { value } = e.target;

    this.handleItemChange(name, value);
  }

  onSave() {
    this.props.onSave(this.state.item.id, this.state.updatedItem);
    this.setState({ updatedItem: {} });
  }

  onDelete() {
    const data = {
      defaultLocation: this.props.defaultLocation,
      deleteLocation: this.state.item,
    };
    this.props.showDeleteModal(data);
  }

  getFeedback() {
    if (this.isSaving()) {
      return 'Saving...';
    }
    return '';
  }

  isSaving() {
    return this.props.waitingForSave.some((key) => key === `save-locations-${this.props.item.id}`);
  }

  /**
   * Check whether the save button should be disabled.
   * All the fields must be filled in, and the location must not be saving.
   * @returns {boolean|*}
   */
  isSaveDisabled() {
    return !this.props.isValid || this.isSaving();
  }

  /**
   * Check whether the item has unsaved changes
   * @returns {boolean}
   */
  hasUnsavedChanges() {
    return Object.keys(this.state.updatedItem).length !== 0;
  }

  classNameRow() {
    let className = 'company-settings-location__table-row company-settings--with-transparent-input';
    if (this.state.item.default) {
      className += ' company-settings-location__table-row--default';
    }
    return className;
  }

  render() {
    return (
      <TableRow feedback={this.getFeedback()}
        className={this.classNameRow()}>
        <TableCell className="company-settings-location__table-cell-name">
          <InputValidator constraints={['required', 'maxLength: 255', 'minLength: 2']} updateErrors={this.props.updateErrors}>
            <TextField name="name"
              label="Name"
              placeholder="Enter location name"
              value={_.unescape(this.state.item.name)}
              onChange={this.handleNameChange}
              errorText={this.props.errors.name} />
          </InputValidator>
        </TableCell>
        <TableCell className="company-settings-location__table-cell-action">
          <ShowIf condition={this.hasUnsavedChanges()}>
            <Button onClick={this.onSave}
              disabled={this.isSaveDisabled()}
              className="company-settings__button company-settings__button--blue company-settings-margin-right-l">
              Save
            </Button>
          </ShowIf>
          <ShowIf condition={!this.state.item.default}
            showElse={<InfoTip label="default" />}>
            <IconButton icon="delete" color="blue" size="medium" onClick={this.onDelete} className="company-settings__icon-button" />
          </ShowIf>
        </TableCell>
      </TableRow>
    );
  }
};
