const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const Button = require('@common/Button/RoundedButton.react');
const Loader = require('@common/Loader/Loader.react');

module.exports = class DeleteWorkHourCapacityConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.getFeedback = this.getFeedback.bind(this);
    this.isWaiting = this.isWaiting.bind(this);
  }

  componentDidMount() {
    this.props.getWorkHourCapacityEmployees(this.props.workHourCapacity.id);
  }

  handleCancel() {
    this.props.onDeleteWorkHourCapacityConfirmationCancel([]);
  }

  handleConfirm() {
    this.props.onDeleteWorkHourCapacityConfirmationConfirm(this.props.workHourCapacity.id);
  }

  getFeedback() {
    return this.isWaiting() ? 'Deleting...' : undefined;
  }

  getWorkHourCapacityEmployeesListOrLoader() {
    if (this.props.workHourCapacityEmployees.length > 0) {
      return (
        <ul className="company-settings-work-hour-capacity__modal-delete">
          {this.props.workHourCapacityEmployees.map((employee) => (
            <li key={employee.id}>
              {employee.name}
            </li>
          ))}
        </ul>
      );
    }
    return (
      <div className="company-settings-work-hour-capacity__modal-delete-loader">
        <Loader />
      </div>
    );
  }

  isWaiting() {
    return this.props.waitingFor.some(
      (key) => key.includes(this.props.workHourCapacity.id),
    );
  }

  render() {
    return (
      <Modal title="Delete Capacity Group" onClose={this.handleCancel}>
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              You are about to delete <b>{this.props.workHourCapacity.name}</b> that is already
              set for the following team members:
            </ModalBodyBlock>
            <ModalBodyBlock>
              {this.getWorkHourCapacityEmployeesListOrLoader()}
            </ModalBodyBlock>
          </ModalBody>
          <ModalFooter feedback={this.getFeedback()}>
            <Button className="company-settings__button company-settings__button--white"
              onClick={this.handleCancel}>Cancel
            </Button>
            <Button className="company-settings__button company-settings__button--blue"
              onClick={this.handleConfirm}
              disabled={!this.props.workHourCapacity || this.isWaiting()}>
              Do delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
};
