const React = require('react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');

const MarginField = ({ value }) => {
  const formattedValue = value ? `${value} %` : '';

  return (
    <TextField
      readOnly
      id="sidebar-margin"
      label="Margin"
      value={formattedValue} />
  );
};

module.exports = MarginField;
