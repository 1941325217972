const { connect } = require('react-redux');
const Component = require('@common/AdvancedSearch/filters/CheckboxFilter/CheckboxFilter.react');

const mapStateToProps = () => ({
  availableOptions: [{
    label: 'As PM',
    value: 'pm',
  }, {
    label: 'As account',
    value: 'account',
  }, {
    label: 'Others',
    value: 'others',
  }],
});

module.exports = connect(mapStateToProps)(Component);
