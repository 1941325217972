const React = require('react');
const Pricelist = require('./Pricelist/Pricelist.react');
const Title = require('./HeaderTitle.react');
const Levels = require('./HeaderLevels.react');
const DaysCell = require('../Cell/DaysCell.react');
const MoneyCell = require('../Cell/MoneyCell.react');
const PercentageCell = require('../Cell/PercentageCell.react');
const Row = require('../Row.react');
const ExternalCost = require('../../../containers/Calculator/Header/HeaderExternalCost');
const MoreOptions = require('../MoreOptionsButton/MoreOptionsButton.react');
const IconCell = require('../Cell/IconCell.react');
const RowHorizontalScroll = require('../RowHorizontalScroll.react');
const HeaderLevelsSelector = require('./HeaderLevelsSelector.react');

module.exports = class HeaderNew extends React.Component {
  constructor(props) {
    super(props);
    this.headerReference = null;
    this.mutationObserver = null;
    this.onScroll = this.onScroll.bind(this);
    this.setHeaderRef = this.setHeaderRef.bind(this);
  }

  componentDidMount() {
    if (this.props.column === 'center') {
      if (this.headerReference.scrollWidth > this.headerReference.clientWidth) {
        this.props.setShowShadows(true);
      }
      this.mutationObserver = new MutationObserver(() => {
        if (this.headerReference.scrollWidth > this.headerReference.clientWidth) {
          this.props.setShowShadows(true);
        } else {
          this.props.setShowShadows(false);
        }
      });
      this.mutationObserver.observe(this.headerReference, {
        childList: true,
        subtree: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.scrollingComponentAmount !== this.props.scrollingComponentAmount) {
      if (this.props.column === 'center') {
        this.headerReference.scrollTo({ left: this.props.scrollingComponentAmount });
      }
    }
  }

  componentWillUnmount() {
    this.mutationObserver.disconnect();
  }

  onScroll() {
    this.props.setScrollingComponentScrollAmount(this.headerReference.scrollLeft);
  }

  setHeaderRef(el) {
    this.headerReference = el;
  }

  render() {
    if (this.props.column === 'left') {
      return (
        <Row className={`wethod-budget-calculator__header wethod-budget-calculator__header--left-column ${this.props.showShadows ? 'wethod-budget-shadow-left' : ''}`}>
          <Pricelist
            pricelist={this.props.pricelist}
            onClick={this.props.showPricelistModal}
            pricelistIsEditable={this.props.pricelistIsEditable} />
          <PercentageCell>
            <Title>Q.ty</Title>
          </PercentageCell>
        </Row>
      );
    }
    if (this.props.column === 'center') {
      return (
        <RowHorizontalScroll
          myRef={this.setHeaderRef}
          onScroll={this.onScroll}
          className="wethod-budget-calculator__header
           wethod-budget-calculator__header--center-column wethod-budget-calculator__header--horizontal-scroll">
          <Levels
            items={this.props.mainBudgetPriceListLevels} />
        </RowHorizontalScroll>
      );
    }
    return (
      <Row className={`wethod-budget-calculator__header wethod-budget-calculator__header--right-column ${this.props.showShadows ? 'wethod-budget-shadow-right' : ''}`}>
        <ExternalCost>Ext. Costs</ExternalCost>
        <PercentageCell>
          <Title>+%</Title>
        </PercentageCell>
        <DaysCell>
          <Title>Days</Title>
        </DaysCell>
        <MoneyCell>
          <Title>Cost</Title>
        </MoneyCell>
        <MoneyCell>
          <Title>Price</Title>
        </MoneyCell>
        <MoreOptions />
        <IconCell>
          {
            this.props.status === 'DRAFT'
            && (
            <HeaderLevelsSelector
              budgetId={this.props.budgetId}
              mainBudgetPriceListLevels={this.props.mainBudgetPriceListLevels}
              days={this.props.days}
              setVisible={this.props.setVisible} />
            )
          }
        </IconCell>
      </Row>
    );
  }
};
