const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const ContactCreator = require('@common/inputs/Autocomplete/creators/ClientContactCreator.react');
const AutocompleteModel = require('../../../../../../models/PipelineAutocomplete');

module.exports = class CustomerSelect extends React.Component {
  static getInputValue(item) {
    return _.unescape(`${item.name} ${item.surname}`);
  }

  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getClientContacts(keyword, this.props.client.id)
      .done((collection) => this.setState({
        availableItems: collection.toJSON(),
        isLoading: false,
      }));
  }

  onChange(e, item) {
    const { value } = e.target;
    let customer = null;

    if (item) {
      customer = {
        id: value,
        name: item.name,
        surname: item.surname,
      };
    }

    this.props.onChange(e, customer);
  }

  onCreate(item) {
    const event = {
      target: {
        value: item.id,
        name: this.props.name,
      },
    };
    this.onChange(event, item);
  }

  getOptions() {
    return this.state.availableItems.map((customer) => {
      const splitted = customer.hint.split(' ');
      const name = splitted[0];
      splitted.splice(0, 1);
      const surname = splitted.join(' ');

      return (
        <SelectItem key={customer.id}
          value={customer.id}
          name={name}
          surname={surname}>
          {_.unescape(`${name} ${surname}`)}
        </SelectItem>
      );
    });
  }

  getValue() {
    const { id } = this.props.value;
    if (id === null) {
      return null;
    }
    return this.props.value;
  }

  getHelperText() {
    if (!this.clientExists()) {
      return 'Choose client first';
    }
    return '';
  }

  clientExists() {
    return this.props.client.id;
  }

  render() {
    const constraints = this.props.required ? ['required'] : [];

    return (
      <InputValidator updateErrors={this.props.updateErrors} constraints={constraints}>
        <Autocomplete readOnly={this.props.readOnly}
          disabled={!this.clientExists()}
          onChange={this.onChange.bind(this)}
          value={this.getValue()}
          getInputValue={CustomerSelect.getInputValue}
          label="Client contact"
          name={this.props.name}
          id="sidebar-customer"
          onFilter={this.onFilter.bind(this)}
          errorText={this.props.error}
          helperText={this.getHelperText()}
          creator={ContactCreator}
          creatorProps={{
            client: {
              id: this.props.client.id,
              name: this.props.client.corporate_name,
            },
          }}
          onCreate={this.onCreate.bind(this)}
          loading={this.state.isLoading}>
          {this.getOptions()}
        </Autocomplete>
      </InputValidator>
    );
  }
};
