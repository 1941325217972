const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Backdrop = require('@common/Backdrop/Backdrop.react');

const InteractionBlocker = ({ isPending }) => {
  const isChangingVersion = isPending('get-budget-version');
  const isWaitingForVersionChanges = isPending('get-version-comparison');

  return (
    <ShowIf condition={isChangingVersion || isWaitingForVersionChanges}>
      <Backdrop className="wethod-backdrop-pricelist" />
    </ShowIf>
  );
};

module.exports = InteractionBlocker;
