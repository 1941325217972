const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const ModalContent = require('./SubmitStatusButtonModalContent.react');
const RequestManager = require('../../../../../containers/RequestManager');

const SubmitStatusButtonModalContent = ({ closeModal, availableApprovers }) => (
  <Modal onClose={closeModal} title="Send for approval">
    <FormValidator>
      <RequestManager>
        <ModalContent closeModal={closeModal}
          availableApprovers={availableApprovers} />
      </RequestManager>
    </FormValidator>
  </Modal>
);

module.exports = SubmitStatusButtonModalContent;
