const { connect } = require('react-redux');
const SearchSelect = require('@common/SearchSelect/SearchSelect.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  name: 'company',
  items: state.companies,
  constraints: 'required',
  creatorFields: [{
    name: 'acronym',
    constraints: 'required',
    messages: {
      required: 'Acronym is required',
    },
  }],
});

const mapDispatchToProps = (dispatch) => ({
  onCreateClick: (company) => dispatch(actions.saveCompany(company)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(SearchSelect);
