/* eslint-disable guard-for-in,no-restricted-syntax,react/sort-comp,
 react/no-access-state-in-setstate,consistent-return */
const React = require('react');
const TextInput = require('@common/TextInput.react');

module.exports = class ResourceEditable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      errors: {},
      resource: {},
    };

    this.messages = {
      url: {
        required: 'Please add the link URL',
      },
      alias: {
        required: 'Please add an alias for the link',
      },
    };
  }

  getErrorMessages() {
    const errors = { ...this.state.errors };
    for (const error in errors) {
      errors[error] = errors[error].map((constraint) => this.messages[error][constraint]);
    }
    return errors;
  }

  /**
   * Returns true if all inputs are valid.
   * @returns {boolean}
   */
  isValid() {
    const { errors } = this.state;
    for (const error in errors) {
      if (errors[error].length > 0) return false;
    }
    return true;
  }

  onValidate(name, inputErrors) {
    const { errors } = this.state;
    errors[name] = inputErrors;
    this.setState({ errors });
  }

  onChange(name, value) {
    const resource = { ...this.state.resource };
    resource[name] = value;
    this.setState({ resource });
  }

  getFeedback() {
    if (this.props.isSaving) return 'Saving ';
    return '';
  }

  /**
   * Returns the inputs' error message.
   */
  getErrorMessage(name) {
    if (this.state.showErrors) {
      const inputErrors = this.getErrorMessages()[name];
      if (inputErrors) {
        return inputErrors[0];
      }
      return '';
    }
  }

  getActionsClassName() {
    let name = 'client-person__resources-list--actions wethod-list-column';
    if (this.props.isLoading) name += ' disabled';
    return name;
  }

  reset() {
    this.setState({
      resource: {
        alias: '',
        url: '',
      },
      showErrors: false,
    });
  }

  onSaveClick() {
    if (this.isValid()) {
      this.props.save(this.props.person.id, this.state.resource);
      this.reset();
    } else {
      this.setState({ showErrors: true });
    }
  }

  render() {
    return (
      <li className="client-person-resource client-person-resource--new">
        <div className="client-person__resources-list--alias wethod-list-column">
          <TextInput name="alias"
            constraints="required"
            value={this.state.resource.alias}
            placeholder="Alias"
            onValidate={this.onValidate.bind(this)}
            onChange={this.onChange.bind(this)} />
          <div className="client-info-row__error clients-feedback--error">{this.getErrorMessage('alias')}</div>
        </div>
        <div className="client-person__resources-list--url wethod-list-column">
          <TextInput name="url"
            constraints="required"
            value={this.state.resource.url}
            placeholder="Link"
            onValidate={this.onValidate.bind(this)}
            onChange={this.onChange.bind(this)} />
          <div className="client-info-row__error clients-feedback--error">{this.getErrorMessage('url')}</div>
        </div>
        <div className={this.getActionsClassName()}>
          <button type="button"
            className="clients__save-client-button wethod-button"
            onClick={this.onSaveClick.bind(this)}>
            Save
          </button>
        </div>
      </li>
    );
  }
};
