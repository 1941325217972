const { connect } = require('react-redux');
const SearchInput = require('@common/SearchInput.react');
const actions = require('../actions');

const mapStateToProps = (state) => ({
  placeholder: 'Search projects',
  keyword: state.projectsFilter,
});

const mapDispatchToProps = (dispatch) => ({
  filter: (keyword) => dispatch(actions.filterProjects(keyword)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(SearchInput);
