/* eslint-disable react/sort-comp,react/jsx-no-bind */
const React = require('react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const Item = require('@common/Menu/ListMenu/MenuItem.react');

module.exports = class RoundTripSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  hideMenu() {
    this.setState({ showMenu: false });
  }

  handleChange(option) {
    const currentOption = this.props.isRoundTrip ? 'round-trip' : 'one-way';
    if (option !== currentOption) {
      this.props.onChange(option);
    }
  }

  getSelectedOption() {
    return this.props.isRoundTrip ? 'Round trip' : 'One way';
  }

  render() {
    return (
      <div>
        <button type="button"
          className="wethod-button"
          onClick={this.showMenu.bind(this)}
          ref={(el) => this.button = el}>
          {this.getSelectedOption()}
        </button>
        <Menu anchorEl={this.button} open={this.state.showMenu} onClose={this.hideMenu.bind(this)}>
          <Item onClick={() => this.handleChange('one-way')}>One way</Item>
          <Item onClick={() => this.handleChange('round-trip')}>Round trip</Item>
        </Menu>
      </div>
    );
  }
};
