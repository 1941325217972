const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');
const Escaper = require('@common/formatters/Escaper.react');

const ArchiveFailureModal = ({ data, onCloseClick }) => {
  const projectName = data ? data.name : '';

  return (
    <Modal title="Project archive" onCancelClick={onCloseClick}>
      <p>
        Cannot archive <b><Escaper>{projectName}</Escaper></b>.<br />
        Check the 'Rules' section to learn more.
      </p>
      <div className="wethod-modal2__actions">
        <Button onClick={onCloseClick}>Cancel</Button>
      </div>
    </Modal>
  );
};

module.exports = ArchiveFailureModal;
