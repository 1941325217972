/* eslint-disable consistent-return,react/no-array-index-key */
const React = require('react');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const TravelOfficeSection = require('../../../containers/sidebar/TravelOfficeSection/TravelOfficeSection');
const FileSection = require('../../../containers/sidebar/FileSection/FileSection');
const { formatDate } = require('../../../../../../../services/FormatService');

module.exports = class AccommodationReadOnlyDetails extends React.Component {
  getEmployeesInfo() {
    if (this.props.travel.employees) {
      return this.props.travel.employees.map((employee, index) => (
        <div key={index}>
          <div key={index}>
            <ReadOnlyField placeholder="Name" value={employee.name}>
              <span className="wethod-icon wethod-icon-pm" />
            </ReadOnlyField>
            <ReadOnlyField placeholder="Surname" value={employee.surname}>
              <span />
            </ReadOnlyField>
          </div>
        </div>
      ));
    }
  }

  getGuestsInfo() {
    if (this.props.travel.guests) {
      return this.props.travel.guests.map((guest, index) => (
        <div key={index}>
          <ReadOnlyField placeholder="Name" value={guest.name}>
            <span className="wethod-icon wethod-icon-external wethod-icon-external--black" />
          </ReadOnlyField>
          <ReadOnlyField placeholder="Surname" value={guest.surname}>
            <span />
          </ReadOnlyField>
        </div>
      ));
    }
  }

  getPeopleLabel() {
    const employees = this.props.travel.employees ? this.props.travel.employees.length : 0;
    const guests = this.props.travel.guests ? this.props.travel.guests.length : 0;

    const total = employees + guests;
    return total === 1 ? '1 person' : `${total} people`;
  }

  render() {
    return (
      <div>
        <ReadOnlyField placeholder="Location" value={this.props.travel.to}>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </ReadOnlyField>
        <div className="material-input">
          <div className="wethod-button travel__selection-labels travel__no-hover">{this.getPeopleLabel()}</div>
        </div>
        <div className="travel__sidebar-multiple-input-row">
          <div className="travel__sidebar-multiple-input-element">
            <ReadOnlyField placeholder="Check in" value={formatDate(this.props.travel.date)}>
              <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
            </ReadOnlyField>
          </div>
          <div className="travel__sidebar-multiple-input-element">
            <ReadOnlyField placeholder="Check out" value={formatDate(this.props.travel.return_date)} />
          </div>
        </div>
        {this.getEmployeesInfo()}
        {this.getGuestsInfo()}
        <ReadOnlyField placeholder="Additional requests" value={this.props.travel.additional_requests}>
          <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
        </ReadOnlyField>
        <TravelOfficeSection travel={this.props.travel} editMode={false} />
        <FileSection editMode={false} carrier={this.props.travel} />
      </div>
    );
  }
};
