/* eslint-disable react/sort-comp,react/no-did-update-set-state,no-prototype-builtins,react/jsx-no-bind,class-methods-use-this */
const React = require('react');
const _ = require('underscore');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const SidebarButton = require('@common/OpenSidebarButton.react');
const Loader = require('@common/Loader/Loader.react');
const { formatDate } = require('../../../../../services/FormatService');
const formatName = require('../../../../../services/FormatService').formatOneLetterPersonName;
const auth = require('../services/authManager');
const FieldFactory = require('./sidebar/categories/FieldFactory.react');

module.exports = class TravelRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sending: false,
    };
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  handleClick() {
    this.props.openSidebar(this.props.travel);
  }

  handleFilesDownload(hasFiles, category) {
    if (hasFiles) this.props.downloadFiles(this.props.travel, category);
  }

  getDepartureDate() {
    return formatDate(this.props.travel.date);
  }

  getReturnDate() {
    return formatDate(this.props.travel.return_date);
  }

  getCreator() {
    return this.props.travel.employee_id ? `${this.props.travel.employee_id.email}` : '';
  }

  setSending(isSending) {
    this.setState({ sending: isSending });
  }

  sendRequest() {
    if (!this.props.isLoading
      && auth.getPermission('edit', this.props.travel.employee_id, this.props.travel.status)
      && this.props.metadataService.getCanEdit('status')) {
      if (this.props.travelOffices.length > 1) {
        this.props.showTravelOfficeModal({
          travel: this.props.travel,
          setSending: this.setSending.bind(this),
        });
      } else {
        this.props.travel.status = 'sent';
        this.setState({ sending: true });
        this.props.sendTravelRequest((this.props.travel));
      }
    }
  }

  approveTravel() {
    if (!this.props.isLoading
      && auth.getPermission('edit_sent', null, 'sent') && this.props.metadataService.getCanEdit('status')) {
      this.props.travel.status = 'approved';
      this.setState({ sending: true });
      this.props.sendTravelRequest((this.props.travel));
    }
  }

  componentDidUpdate() {
    if (!this.props.isSaving && this.state.sending) {
      this.setState({ sending: false });
    }
  }

  isTravelReadyToBeApproved() {
    const carriersList = this.props.travel.travel_carriers || [];
    if (carriersList.length <= 0) {
      return false;
    }
    for (let i = 0; i < carriersList.length; i++) {
      if (!carriersList[i].value) return false;
    }

    return true;
  }

  getStatus(status) {
    if (this.props.isSaving && this.state.sending) {
      return <Loader />;
    }

    if (status === 'created') {
      let style = 'wethod-button';
      if (this.props.isLoading
        || !auth.getPermission('edit', this.props.travel.employee_id, status)
        || !this.props.metadataService.getCanEdit('status')) {
        style += ' travel-disabled';
      }
      const button = (
        <button type="button" className={style} onClick={this.sendRequest.bind(this)}>
          Send
        </button>
      );
      return (
        <FieldFactory metadataService={this.props.metadataService} name="status" readOnlyComponent={button}>
          {button}
        </FieldFactory>
      );
    } if (status === 'approved') {
      return (
        <span className="travel__status-booked">
          Booked
        </span>
      );
    } if (status === 'sent' && auth.getPermission('edit_sent', null, 'sent') && this.isTravelReadyToBeApproved()) {
      let style = 'wethod-button';
      if (this.props.isLoading || !this.props.metadataService.getCanEdit('status')) {
        style += ' travel-disabled';
      }
      const button = (
        <button type="button" className={style} onClick={this.approveTravel.bind(this)}>
          Submit
        </button>
      );
      return (
        <FieldFactory metadataService={this.props.metadataService} name="status" readOnlyComponent={button}>
          {button}
        </FieldFactory>
      );
    }

    return (
      <span className="travel__status-pending">
        Pending
      </span>
    );
  }

  getIcons() {
    const types = {
      train: {
        cssClass: 'wethod-icon-train wethod-icon-train--black',
        isPresent: false,
        hasFiles: false,
      },
      flight: {
        cssClass: 'wethod-icon-travel wethod-icon-travel--black',
        isPresent: false,
        hasFiles: false,
      },
      car: {
        cssClass: 'wethod-icon-car wethod-icon-car--black',
        isPresent: false,
        hasFiles: false,
      },
      accommodation: {
        cssClass: 'wethod-icon-bed wethod-icon-bed--black',
        isPresent: false,
        hasFiles: false,
      },
      other: {
        cssClass: 'wethod-icon-asterisk wethod-icon-asterisk--black',
        isPresent: false,
        hasFiles: false,
      },
    };

    this.props.travel.travel_carriers.forEach((carrier) => {
      if (carrier && types.hasOwnProperty(carrier.type)) {
        types[carrier.type].isPresent = true;
      }
      if (carrier && carrier.files && carrier.files.length > 0) {
        types[carrier.type].hasFiles = true;
      }
    });

    const circleClass = 'travel__circle-icon';

    return (
      <div className="travel-type-icons">
        <div className={types.train.hasFiles ? circleClass : ''}
          onClick={() => this.handleFilesDownload(types.train.hasFiles, 'train')}>
          <div className={types.train.isPresent ? types.train.cssClass : `${types.train.cssClass} travel__type-not-booked`} />
        </div>
        <div className={types.flight.hasFiles ? circleClass : ''}
          onClick={() => this.handleFilesDownload(types.flight.hasFiles, 'flight')}>
          <div className={types.flight.isPresent ? types.flight.cssClass : `${types.flight.cssClass} travel__type-not-booked`} />
        </div>
        <div className={types.car.hasFiles ? circleClass : ''}
          onClick={() => this.handleFilesDownload(types.car.hasFiles, 'car')}>
          <div className={types.car.isPresent ? types.car.cssClass : `${types.car.cssClass} travel__type-not-booked`} />
        </div>
        <div className={types.accommodation.hasFiles ? circleClass : ''}
          onClick={() => this.handleFilesDownload(types.accommodation.hasFiles, 'accommodation')}>
          <div className={types.accommodation.isPresent ? types.accommodation.cssClass : `${types.accommodation.cssClass} travel__type-not-booked`} />
        </div>
        <div className={types.other.hasFiles ? circleClass : ''}
          onClick={() => this.handleFilesDownload(types.other.hasFiles, 'other')}>
          <div className={types.other.isPresent ? types.other.cssClass : `${types.other.cssClass} travel__type-not-booked`} />
        </div>
      </div>
    );
  }

  getRequestedBy() {
    if (this.props.travel.employee_id) {
      return formatName(`${this.props.travel.employee.name} ${this.props.travel.employee.surname}`);
    }

    return '-';
  }

  render() {
    return (
      <TableRow className="travel-table__row">
        <TableCell
          className="travel-table__column--project travel-table__row--project">
          <span>{this.getUnescapedValue(this.props.travel.project.name)}</span>
          <span className="travel-table__column--project travel-table__row--project-joborder">{this.getUnescapedValue(this.props.travel.project.job_order)}</span>
        </TableCell>
        <TableCell
          className="travel-table__column--joborder travel-table__row--joborder hidden-mobile">{this.getRequestedBy()}
        </TableCell>
        <TableCell className="travel-table__column--date travel-table__row--date hidden-mobile">{this.getDepartureDate()} {'>'} {this.getReturnDate()}</TableCell>
        <TableCell className="travel-table__column--destination travel-table__row--destination">{this.props.travel.destination}</TableCell>
        <TableCell className="travel-table__column--to travel-table__row--to hidden-mobile">{this.props.travel.sent_to ? this.props.travel.sent_to.alias : '-'}</TableCell>
        <TableCell className="travel-table__column--type travel-table__row--type hidden-mobile">{this.getIcons()}</TableCell>
        <TableCell className="travel-table__column--status travel-table__row--status">{this.getStatus(this.props.travel.status)}</TableCell>
        <TableCell className="travel-table__column--actions travel-table__row--actions"><SidebarButton
          onClick={this.handleClick.bind(this)} />
        </TableCell>
      </TableRow>
    );
  }
};
