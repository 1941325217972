const React = require('react');
const Modal = require('@common/modal2/Modal.react');

const NoteModal = ({ data, onCancelClick }) => (
  <Modal title="Approval notes" onCancelClick={onCancelClick} className="budget-changelog__modal">
    <span>{data.message}</span>
  </Modal>
);

module.exports = NoteModal;
