const React = require('react');
const { connect } = require('react-redux');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const TextArea = require('@common/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const actions = require('../../../actions');

class TaskNotesModal extends React.Component {
  static close() {
    modalService.closeModal();
  }

  constructor(props) {
    super(props);
    this.state = {
      text: this.props.notes,
      isLoading: false,
      error: null,
    };

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ text: e.target.value });
  }

  onSave() {
    this.setState({ isLoading: true });
    this.props.updateTaskInfo(this.props.id, { notes: this.state.text })
      .always(() => this.setState({ isLoading: false }))
      .done(() => TaskNotesModal.close())
      .fail((response) => {
        if (typeof response === 'string') {
          this.setState({ error: response });
        }
      });
  }

  render() {
    return (
      <Modal title="Notes" onClose={TaskNotesModal.close}>
        <ModalContent>
          <ModalBody>
            <TextArea value={this.state.text}
              rows={5}
              readOnly={!this.props.editable}
              onChange={this.onChange}
              name="text" />
          </ModalBody>
          <ModalFooter
            error={this.state.error}
            feedback={this.state.isLoading ? 'Saving...' : null}>
            <ModalFooterAction onClick={TaskNotesModal.close}>
              Close
            </ModalFooterAction>
            <ModalFooterAction
              onClick={this.onSave}
              disabled={!this.props.editable || this.state.isLoading}>
              Save
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

module.exports = connect(null, {
  updateTaskInfo: actions.updateTaskInfo,
})(TaskNotesModal);
