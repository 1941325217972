/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const Menu = require('@common/Menu/Menu.react');
const AvatarBox = require('@common/AvatarBox/AvatarBox.react');
const MemberMenu = require('./MemberMenu/MemberMenu.react');

module.exports = class MemberItemCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  isWaitingForCellSaving() {
    return this.props.isWaitingForCellSaving(this.props.item, this.props.attribute);
  }

  isWaitingForMemberDeleting() {
    return this.props.isWaitingForMemberDeleting(this.props.item, this.props.attribute);
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    if (!this.props.people && !this.props.isLoading) {
      this.props.getPeople();
    }
    this.setState({ showMenu: true });
  }

  handleMemberDelete(memberTagId) {
    this.props.deleteMember(this.props.item, this.props.attribute, memberTagId);
  }

  handleMemberSelect(member) {
    this.props.addMember(this.props.item, this.props.attribute, member);
  }

  getAvatars() {
    if (this.props.value !== null) {
      return this.props.value.map((avatar) => ({ ...avatar, name: `${avatar.name} ${avatar.surname}` }));
    }
    return [];
  }

  render() {
    return (
      <TableCell className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button">
        <button type="button"
          ref={(el) => this.moreButtonEl = el}
          className="project-canvas-spreadsheet__cell-button-content"
          onClick={this.onMenuButtonClick.bind(this)}>
          <AvatarBox avatars={this.getAvatars()} />
        </button>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          <MemberMenu
            canEdit
            addedPeople={this.props.value}
            people={this.props.people}
            isLoading={this.props.isLoading}
            isSaving={this.isWaitingForCellSaving()}
            isDeleting={this.isWaitingForMemberDeleting()}
            onSelect={this.handleMemberSelect.bind(this)}
            onDelete={this.handleMemberDelete.bind(this)} />
        </Menu>
      </TableCell>
    );
  }
};
