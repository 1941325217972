/* eslint-disable react/self-closing-comp,class-methods-use-this */
const React = require('react');
const JobOrderTemplatesTypes = require('@models/JobOrderTemplate').types;
const YearBlock = require('../blocks/YearBlock.react');
const NumberBlock = require('../blocks/NumberBlock.react');
const TextBlock = require('../blocks/TextBlock.react');

module.exports = class BlocksArea extends React.Component {
  /**
   * Calc the index of an element which is dropped in some position of a template.
   * The position is calculated thanks to the starting index and the index
   * of the sibling when the element is dropped.
   *
   * @param fromIndex
   * @param siblingIndex
   * @returns {number|*}
   */
  static getIndexAfterDrop(fromIndex, siblingIndex) {
    const isElementDraggedFromComponents = fromIndex == null;

    return !isElementDraggedFromComponents && siblingIndex > fromIndex
      ? siblingIndex - 1
      : siblingIndex;
  }

  /**
   * Order the blocks of a template when an element is added or moved in the list of blocks.
   * Given the element moved, its initial position, and the final position,
   * returns the blocks in the proper order.
   *
   * @param fromIndex // starting position of the element moved
   * @param toIndex // final position of the element moved
   * @param blocks // list of elements of the template
   * @param block // the moved block
   * @returns {*[]} // updated blocks with correct order
   */
  static getReOrderedBlocks(fromIndex, toIndex, blocks, block) {
    let arrayCopy = [...blocks]; // Let's create a copy because we're going to use splice() which modify the given array

    if (fromIndex != null) { // Moving an element existing in the template
      arrayCopy = arrayCopy.filter((el) => el.sort.toString() !== fromIndex.toString()); // Remove item in "from" position
    }
    const blockList = BlocksArea.addBlockToList(toIndex, arrayCopy, block);

    return blockList.map((el, index) => ({
      ...el,
      sort: index,
    }));
  }

  /**
   *
   * @param toIndex // final position of the element moved
   * @param blocks // list of elements of the template
   * @param block // the moved block
   * @returns {*[]} // updated blocks
   */
  static addBlockToList(toIndex, blocks, block) {
    const arrayCopy = [...blocks]; // Let's create a copy because we're going to use splice() which modify the given array
    arrayCopy.splice(toIndex, 0, block); // Add item in the right "to" position

    return arrayCopy;
  }

  /**
   *
   * @param fromIndex // starting position of the element moved
   * @param blocks // list of elements of the template
   * @param element // the moved element
   * @returns {{format: *, type: *, value: string}} // Formatted block object
   */
  static getDraggedBlock(fromIndex, blocks, element) {
    let block;
    const arrayCopy = [...blocks]; // Let's create a copy because we're going to use splice() which modify the given array

    if (fromIndex != null) { // Moving an element existing in the template
      block = arrayCopy[fromIndex]; // Get item in "from" position
    } else { // New item dropped
      block = BlocksArea.getFormattedBlock(element);
    }

    return block;
  }

  /**
   * Return the block object properly formatted, given the corresponding element
   * @param element
   * @returns {{format: number, type: number, value: string}}
   */
  static getFormattedBlock(element) {
    // Adding a new element to the template
    const type = BlocksArea.getIntValue(element.getAttribute('data-id'));
    const format = BlocksArea.getIntValue(element.getAttribute('data-format'));
    return { type, value: '', format };
  }

  /**
   * Return the integer parsing of the value.
   * If the value has no valid numeric interpretation, null is returned.
   * @param value
   * @returns {number|undefined}
   */
  static getIntValue(value) {
    const number = Number.parseInt(value);
    return number != null && !Number.isNaN(number) ? number : null;
  }

  componentDidMount() {
    this.props.addContainer(this.listEl);
  }

  handleBlockChange(name, value, sort) {
    const updatedBlock = { sort, [name]: value };
    const updatedBlocks = this.getUpdatedBlocks(this.props.blocks, updatedBlock);

    this.props.onChange('blocks', updatedBlocks);
  }

  handleBlockDelete(sort) {
    const updatedBlocks = this.removeBlock(this.props.blocks, sort);

    this.props.onChange('blocks', updatedBlocks);
  }

  handleBlockDrop(el, sibling) {
    let blocksList = [...this.props.blocks];
    const fromIndex = BlocksArea.getIntValue(el.getAttribute('data-sort')); // Index of the element dragged

    const draggedBlock = BlocksArea.getDraggedBlock(fromIndex, blocksList, el);

    const error = this.validateDroppedBlock(draggedBlock);
    if (error) {
      const formattedError = [{
        message: error,
      }];
      this.props.onError('blocks', formattedError);
    } else {
      const siblingIndex = sibling ? BlocksArea.getIntValue(sibling.getAttribute('data-sort')) : this.props.blocks.length;
      // Index where the element should be placed after drop
      const toIndex = BlocksArea.getIndexAfterDrop(fromIndex, siblingIndex);

      blocksList = BlocksArea.getReOrderedBlocks(fromIndex, toIndex, blocksList, draggedBlock);

      this.props.onChange('blocks', blocksList);
    }
  }

  setRef(el) {
    this.listEl = el;
  }

  getBlockType(block) {
    return Object
      .keys(JobOrderTemplatesTypes)
      .find((key) => JobOrderTemplatesTypes[key].toString() === block.type.toString());
  }

  getBlocks() {
    return this.props.blocks
      ? this.props.blocks
        .sort((a, b) => a.sort - b.sort)
        .map((block) => {
          const type = this.getBlockType(block);
          return this[type](block);
        })
      : null;
  }

  getUpdatedBlocks(blockList, updatedBlock) {
    return blockList.map((block) => {
      if (block.sort === updatedBlock.sort) {
        return {
          ...block,
          ...updatedBlock,
        };
      }
      return block;
    });
  }

  removeBlock(blockList, blockOrder) {
    return blockList.filter((block) => block.sort !== blockOrder);
  }

  date(block) {
    return (
      <YearBlock data-sort={block.sort}
        key={block.sort}
        templateId={this.props.templateId}
        block={block}
        onBlockDelete={this.handleBlockDelete.bind(this)}
        deletable />
    );
  }

  text(block) {
    return (
      <TextBlock data-sort={block.sort}
        key={block.sort}
        templateId={this.props.templateId}
        block={block}
        onChange={this.handleBlockChange.bind(this)}
        onBlockDelete={this.handleBlockDelete.bind(this)}
        deletable />
    );
  }

  number(block) {
    return (
      <NumberBlock data-sort={block.sort}
        key={block.sort}
        templateId={this.props.templateId}
        block={block}
        onChange={this.handleBlockChange.bind(this)} />
    );
  }

  /**
   * Check whether there are or there will be more than one blocks of type 'year'
   * @param block
   * @returns {*|boolean}
   */
  isYearBlockDuplicated(block) {
    const isYearBlock = block.type === JobOrderTemplatesTypes.date;
    const isBlockNew = block.sort == null;

    if (isYearBlock && isBlockNew) {
      const yearBlocks = this.props.blocks.filter((el) => el.type === JobOrderTemplatesTypes.date);

      return yearBlocks && yearBlocks.length > 0;
    }
    return false;
  }

  /**
   * Check the validity of the given block.
   * Return the errors.
   * @param block
   */
  validateDroppedBlock(block) {
    const hasYearError = this.isYearBlockDuplicated(block);

    if (hasYearError) {
      return 'You already have a year component in this template.';
    }

    return null;
  }

  render() {
    return (
      <div className="company-settings-jo-template__draggable-area"
        data-id={`template-${this.props.templateId}`}
        ref={this.setRef.bind(this)}>
        {this.getBlocks()}
      </div>
    );
  }
};
