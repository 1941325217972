/* eslint-disable react/no-did-update-set-state */
const React = require('react');
const moment = require('moment');
const isEqual = require('react-fast-compare');
const Sidebar = require('@common/sidebar/Sidebar.react');
const AllocationRequestBody = require('./AllocationRequestBody.react');
const Actions = require('./AllocationRequestActions.react');

/**
 * A sidebar concrete component.
 *
 * PROPS
 * item: object corresponding the focused item to show in the sidebar
 * isSaving: boolean, check for pending saving
 *
 * onSave
 * onClose
 * onDelete
 *
 * @type {module.AllocationRequestSidebar}
 */
module.exports = class AllocationRequestSidebar extends React.Component {
  static isDirty(value) {
    return value !== null && value !== undefined && value.toString().trim() !== '';
  }

  static isNew(item) {
    return !item || !item.id;
  }

  /**
   * Remove the given name from the list of changes
   * @param changes
   * @param name
   */
  static resetChanges(changes, name) {
    return changes.filter((attr) => attr !== name);
  }

  /**
   * Add the given name to the list of changes, if not already present
   * @param changes
   * @param name
   */
  static addChanges(changes, name) {
    if (!changes.includes(name)) {
      changes.push(name);
    }
    return changes;
  }

  /**
   * Return the updated item, merging the changes into the oldItem
   * @param oldItem
   * @param changes
   */
  static getUpdatedItem(oldItem, changes) {
    if (oldItem) {
      const updatedItem = { ...oldItem };
      if (changes) {
        Object.keys(changes).forEach((key) => {
          updatedItem[key] = changes[key];
        });
      }
      return updatedItem;
    }
    return null;
  }

  /**
   * Return the day corresponding the given date, if any
   * @param days
   * @param date
   * @returns {*|null}
   */
  static getDayFromDate(days, date) {
    const foundDate = days.filter((day) => moment(day.date).isSame(date, 'day'));
    return foundDate && foundDate.length > 0 ? foundDate[0] : null;
  }

  /**
   * Return the list of days with the updated amount of hours
   * @param name
   * @param value
   * @param days
   * @returns {{days}}
   */
  static getHoursChanges(name, value, days) {
    const { date } = value;

    const changes = { days };

    if (date) {
      changes.days = days.map((day) => {
        if (moment(day.date).isSame(date, 'day')) {
          return {
            ...day,
            hours: value.hours,
            conflicting: false,
          };
        }
        return day;
      });
    }

    return changes;
  }

  /**
   * Check whether the given range is valid.
   * A range is valid when the start date is before or same as the end date.
   * @param from
   * @param to
   * @returns {boolean}
   */
  static isValidRange(from, to) {
    return moment(from).isSameOrBefore(to);
  }

  /**
   * Return a valid end date of the range.
   * A range is valid when the start date is before or same as the end date.
   * @param from
   * @param to
   * @returns {*}
   */
  static getValidTo(from, to) {
    if (AllocationRequestSidebar.isValidRange(from, to)) {
      return to;
    }

    return from;
  }

  /**
   * Return a valid start date of the range.
   * A range is valid when the start date is before or same as the end date.
   * @param from
   * @param to
   * @returns {*}
   */
  static getValidFrom(from, to) {
    if (AllocationRequestSidebar.isValidRange(from, to)) {
      return from;
    }

    return to;
  }

  constructor(props) {
    super(props);

    this.state = {
      unsavedChanges: [], // array containing the name of the attributes that have unsaved changes
      item: {
        ...this.props.item,
        from: this.props.getStartDate(),
        to: this.props.getEndDate(),
      },
      // by default is true if item has no id (when we want to  add a new one)
      editMode: AllocationRequestSidebar.isNew(this.props.item),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState && prevState.item && this.props && this.props.item) {
      const updatedItem = { ...prevState.item };

      // Update the newly created item id once it is first saved
      const hasNewId = !prevState.item.id && this.props.item.id;

      if (hasNewId) {
        updatedItem.id = this.props.item.id;

        this.setState({
          item: updatedItem,
        });
      }
    }
  }

  /**
   * Update the item and unsaved changes
   * @param name
   * @param value
   */
  handleInputChanges(name, value) {
    this.setState((prevState) => {
      const formattedChanges = this.getFormattedChanges(name, value);

      const unsavedChanges = this
        .updateChangedAttributesList(prevState.unsavedChanges, formattedChanges);

      const updatedItem = AllocationRequestSidebar.getUpdatedItem(prevState.item, formattedChanges);

      return {
        item: updatedItem,
        unsavedChanges,
      };
    });
  }

  handleItemDelete() {
    if (this.props.onDelete) {
      this.props.onDelete(this.state.item);
    }
  }

  /**
   * Save the item with the modified attributes.
   */
  handleSave() {
    const formattedChanges = this.getUnsavedChanges();
    const updatedItem = AllocationRequestSidebar.getUpdatedItem(this.props.item, formattedChanges);

    this.props.onSave(formattedChanges, updatedItem);

    this.setState({
      editMode: false,
      unsavedChanges: [],
    });
  }

  handleEditMode() {
    if (this.canEdit()) {
      this.setState({ editMode: true });
    }
  }

  /**
   * Return the changes to be saved
   * @returns {{}}
   */
  getUnsavedChanges() {
    const changes = {};
    this.state.unsavedChanges.forEach((key) => {
      changes[key] = this.state.item[key];
    });
    return changes;
  }

  getBody() {
    return (
      <AllocationRequestBody readOnly={!this.state.editMode}
        internationalCalendar={this.props.internationalCalendar}
        creation={AllocationRequestSidebar.isNew(this.state.item)}
        item={this.state.item}
        errors={this.props.errors}
        updateErrors={this.props.updateErrors}
        onChange={this.handleInputChanges.bind(this)} />
    );
  }

  getActions() {
    if (!this.state.editMode) {
      return (
        <Actions item={this.state.item}
          canDelete={this.canDelete()}
          onDelete={this.handleItemDelete.bind(this)} />
      );
    }

    return null;
  }

  /**
   * Return the new changes, properly formatted
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {*}
   */
  getFormattedChanges(name, value) {
    switch (name) {
      case 'from':
        return this
          .getDateChanges(value,
            AllocationRequestSidebar.getValidTo(value, this.state.item.to),
            this.state.item.days);
      case 'to':
        return this
          .getDateChanges(AllocationRequestSidebar.getValidFrom(this.state.item.from, value),
            value,
            this.state.item.days);
      case 'days':
        return AllocationRequestSidebar.getHoursChanges(name, value, this.state.item.days);
      default:
        return { [name]: value };
    }
  }

  /**
   * Return the list of days between the initial and final date given.
   * Based on international calendar preferences, weekend days may not be included.
   * @param from
   * @param to
   * @returns {*[]}
   */
  getDatesList(from, to) {
    const days = [];
    const start = moment(from);

    while (start.isSameOrBefore(to)) {
      if (this.shouldDayBeIncluded(start)) {
        days.push(start.format('YYYY-MM-DD'));
      }

      start.add(1, 'days');
    }
    return days;
  }

  /**
   * Reset days based on new start date
   * @param from
   * @param to
   * @param days
   * @returns {{from}}
   */
  getDateChanges(from, to, days) {
    const datesList = this.getDatesList(from, to);

    const formattedDays = datesList.map((date) => {
      const oldDay = AllocationRequestSidebar.getDayFromDate(days, date);

      const defaultDay = { date, hours: 8 };

      return oldDay ? { ...oldDay } : defaultDay;
    });

    return { from, to, days: formattedDays };
  }

  /**
   * Checks if it's safe to save a item: you cannot save changes if there's another saving pending
   * or if any input has errors
   * @returns {boolean}
   */
  canSave() {
    return !this.props.isSaving && this.props.isValid;
  }

  /**
   * Check if edit mode can be enabled: you must have permission and not already be in edit mode
   * @returns {boolean}
   */
  canEdit() {
    return this.props.canEditItem(this.props.item.employee.id, this.props.item.status)
      && !this.state.editMode;
  }

  /**
   * Check if the item can be deleted: you must have permissions and item should not be a new one
   * @returns {boolean}
   */
  canDelete() {
    return this.props.canDeleteItem(this.props.item.employee.id)
      && !AllocationRequestSidebar.isNew(this.props.item);
  }

  hasUnsavedChanges() {
    return this.state.unsavedChanges && this.state.unsavedChanges.length > 0;
  }

  /**
   * Check if the new value of some input is actually different from the one received by props
   *
   * @param {string} name - name of the attribute
   * @param {any} value - value of the input
   * @returns {boolean}
   */
  hasChanged(name, value) {
    const oldItem = this.props.item || {};
    let oldVal = null;
    let newVal = value;

    /**
     * Set the new value and the old value to be compared:
     * by default it's the one corresponding the name of the attribute,
     * in case of nested attributes we need to specify the key one
     */
    switch (name) {
      case 'project':
        oldVal = oldItem.project ? oldItem.project.id : null;
        newVal = newVal ? newVal.id : null;
        break;
      case 'days':
        // Compare array of days content
        oldVal = oldItem.days ? { ...oldItem.days } : null;
        newVal = newVal ? { ...newVal } : null;
        return !isEqual(oldVal, newVal);
      // Do not track from and to changes, these attributes should not be saved anyway
      case 'from':
      case 'to':
        return false;
      default:
        oldVal = oldItem[name];
        break;
    }

    return (AllocationRequestSidebar.isDirty(oldVal) || AllocationRequestSidebar.isDirty(newVal))
      && (oldVal !== newVal);
  }

  /**
   * Return the updated list of changed attributes
   * @param oldChangesList
   * @param newChanges
   * @returns {*}
   */
  updateChangedAttributesList(oldChangesList, newChanges) {
    let changesList = oldChangesList;
    Object.keys(newChanges).forEach((key) => {
      const value = newChanges[key];

      if (this.hasChanged(key, value)) {
        changesList = AllocationRequestSidebar.addChanges(changesList, key);
      } else {
        changesList = AllocationRequestSidebar.resetChanges(changesList, key);
      }
    });

    return changesList;
  }

  /**
   * Check whether the given day should be included in the list of days, based on international calendar preferences.
   * When international calendar is enabled, weekend days are included.
   * @param date
   * @returns {boolean}
   */
  shouldDayBeIncluded(date) {
    const weekDay = date.format('dddd').toLowerCase();
    if (!this.props.internationalCalendar) {
      return weekDay !== 'saturday' && weekDay !== 'sunday';
    }
    return true;
  }

  render() {
    return (
      <Sidebar title="Allocation request"
        hasUnsavedChanges={this.hasUnsavedChanges()}
        isSaving={this.props.isSaving}
        canSave={this.canSave()}
        canEdit={this.canEdit()}
        onClose={this.props.onClose}
        onSave={this.handleSave.bind(this)}
        onCancel={this.props.onClose}
        onEdit={this.handleEditMode.bind(this)}
        body={this.getBody()}
        actions={this.getActions()} />
    );
  }
};
