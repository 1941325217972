const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Button = require('@common/Button/Button.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const Loader = require('@common/Loader/Loader.react');
const JobOrderTemplateModel = require('../../../../../../models/JobOrderTemplate');

module.exports = class JobOrderField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  handleGenerateClick() {
    this.setState({
      isLoading: true,
    });

    JobOrderTemplateModel.generate(this.props.joc.id)
      .done((jobOrder) => {
        this.props.onChange(this.props.name, jobOrder['job-order']);

        this.setState({
          isLoading: false,
        });

        // When the job order is generated, it is shown as 'auto'
        this.updateJobOrderManual(false);
      }).fail((error) => {
        this.setState({
          isLoading: false,
        });

        this.props.showErrorModal(error);
      });
  }

  handleChanges(e) {
    const { name, value } = e.target;
    this.props.onChange(name, value);

    // When the job order is manually changed, it is shown as 'manual'
    this.updateJobOrderManual(true);
  }

  /**
   * Shows the loader only when the job order field is loading
   * @returns {JSX.Element|null}
   */
  getLoader() {
    if (this.state.isLoading) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    return null;
  }

  /**
   * Return the appropriate helper text.
   * The 'auto'/'manual' text is shown only when the job order can be generated.
   * Otherwise, no helper text is shown.
   * @returns {null|string}
   */
  getHelperText() {
    if (this.canGenerateJobOrder()) {
      return this.isJobOrderManual() ? 'Manual' : 'Automatically generated';
    }

    return null;
  }

  /**
   * Changes the project flag defining if the job order is manually edited
   * @param isManual
   */
  updateJobOrderManual(isManual) {
    this.props.onChange('job_order_manual', isManual);
  }

  /**
   * Check whether the generate functionality is available.
   * This means that the project must have a job order category selected,
   * and this job order category must have an associated template.
   *
   * @returns {boolean}
   */
  canGenerateJobOrder() {
    const isJocAuto = this.props.joc && this.props.joc.id
      && this.props.joc.job_order_template != null;

    return this.props.isAutoJobOrderEnabled && isJocAuto;
  }

  /**
   * Check whether the generate functionality should be visible.
   *
   * @returns {false|*}
   */
  isGenerateVisible() {
    return !this.props.readOnly && this.canGenerateJobOrder();
  }

  /**
   * Check whether the job order was manually edited
   * @returns {boolean}
   */
  isJobOrderManual() {
    return this.props.project.job_order_manual;
  }

  /**
   * Check whether the job order field should be disabled
   * @returns {boolean}
   */
  isDisabled() {
    return this.props.disabled || this.state.isLoading;
  }

  render() {
    return (
      <div>
        <TextField readOnly={this.props.readOnly}
          name={this.props.name}
          id={this.props.id}
          label={this.props.label}
          disabled={this.isDisabled()}
          value={this.props.value ? this.props.value : ''}
          helperText={this.getHelperText()}
          onChange={this.handleChanges.bind(this)}
          suffix={this.getLoader()} />
        <ShowIf condition={this.isGenerateVisible()}>
          <Button className="pipeline__button-slim pipeline__sidebar-job-order-button"
            label="Generate job order"
            onClick={this.handleGenerateClick.bind(this)}
            disabled={this.isDisabled()}>
            Generate job order
          </Button>
        </ShowIf>
      </div>
    );
  }
};
