const React = require('react');
const $ = require('jquery');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const ModalBody = require('./TemplateListModalBody.react');
const TemplateModel = require('../../../../../../models/BudgetTemplate');

module.exports = class TemplateListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: null,
      templates: [],
      isWaitingForTemplates: true,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    if (!this.state.templates.length) {
      $.when(TemplateModel.getAll())
        .done((templates) => this.setState({ templates, isWaitingForTemplates: false }))
        .fail(() => this.setState({ templates: [], isWaitingForTemplates: false }));
    }
  }

  handleConfirmClick() {
    this.props.createBudget(this.props.projectId, this.state.selectedTemplate);
  }

  handleInputChange(e) {
    this.setState({ selectedTemplate: e.target.value });
  }

  isWaiting() {
    return this.props.isWaitingForCreation || this.state.isWaitingForTemplates;
  }

  render() {
    return (
      <Modal title="Select Template" onClose={this.props.onClose} className="wethod-budget-modal-template-creation">
        <ModalContent isLoading={this.isWaiting()}>
          <ModalBody selectedTemplate={this.state.selectedTemplate}
            templates={this.state.templates}
            handleInputChange={this.handleInputChange} />
          <ModalFooter>
            <ModalFooterAction onClick={this.props.showCreationModeModal}>Back</ModalFooterAction>
            <ShowIf condition={this.state.templates.length}>
              <ModalFooterAction onClick={this.handleConfirmClick.bind(this)}
                disabled={!this.state.selectedTemplate}>Use template
              </ModalFooterAction>
            </ShowIf>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
};
