/* eslint-disable react/sort-comp,class-methods-use-this,consistent-return,
 jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,
 jsx-a11y/interactive-supports-focus */
const React = require('react');
const $ = require('jquery');
const { Portal } = require('react-portal');
const PropTypes = require('prop-types');
const ShowIf = require('../ShowIf/ShowIf.react');

/**
 * A Menu displays any elements on a temporary surface. It appears when the user interacts with a
 * button, or other control.
 * (For list of items, use ListMenu)
 * @type {Menu}
 */
class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listEl: null,
    };

    this.setListEl = this.setListEl.bind(this);
  }

  componentDidUpdate() {
    if (this.props.open) {
      this.disablePageScroll();
    } else {
      this.enablePageScroll();
    }
  }

  componentWillUnmount() {
    this.enablePageScroll();
  }

  enablePageScroll() {
    $('body')
      .css({
        overflow: '',
        paddingRight: '',
      });
  }

  disablePageScroll() {
    $('body')
      .css({
        overflow: 'hidden',
      });
  }

  setListEl(el) {
    this.setState({ listEl: el });
  }

  getListStyle() {
    const { anchorEl } = this.props;
    const { listEl } = this.state;

    if (anchorEl && this.state.listEl) {
      const anchorPosition = anchorEl.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const anchorWidth = anchorEl.offsetWidth;
      const anchorHeight = anchorEl.offsetHeight;
      const listWidth = listEl.offsetWidth > anchorWidth
        ? listEl.offsetWidth : anchorWidth;
      const listHeight = listEl.offsetHeight;
      const outOfWidth = anchorPosition.left + listWidth > windowWidth; // is list going to go
      // out of the viewport's
      // width?
      let outOfHeight = anchorPosition.top + listHeight > windowHeight; // is list going to go
      // out of the
      // viewport's height?
      let listTop = anchorPosition.top;
      if (this.props.bottom) { // The list is placed outside of the anchor height
        outOfHeight = anchorPosition.top + anchorHeight + listHeight > windowHeight;
        if (outOfHeight) { // The list is placed above the anchor, outside its height
          listTop = anchorPosition.top - listHeight;
        } else { // The list is placed below the anchor, outside its height
          listTop = anchorPosition.top + anchorHeight;
        }
      } else if (outOfHeight) { // The list is placed above the anchor
        listTop = anchorPosition.top + anchorHeight - listHeight;
      }

      let listLeft = anchorPosition.left;
      if (outOfWidth) {
        listLeft = anchorPosition.left + anchorWidth - listWidth;
      } else if (this.props.centered) {
        listLeft = listLeft - listWidth / 2 + anchorWidth / 2;
      }

      return {
        left: `${listLeft}px`,
        top: `${listTop}px`,
        minWidth: `${anchorWidth}px`,
      };
    }

    return {};
  }

  getClassName() {
    return `wethod-menu ${this.props.className}`;
  }

  onMenuListClick(e) {
    e.stopPropagation();
  }

  render() {
    return (
      <ShowIf condition={this.props.open}>
        <Portal>
          <div className={this.getClassName()}
            data-testid="wethod-menu"
            onClick={this.props.onClose}>
            <div className="wethod-menu__list"
              ref={this.setListEl}
              onClick={this.onMenuListClick}
              role="menu"
              id={this.props.id}
              style={this.getListStyle()}>
              {this.props.children}
              <ShowIf condition={this.props.footer !== null}>
                <div className="wethod-menu__footer">
                  {this.props.footer}
                </div>
              </ShowIf>
            </div>
          </div>
        </Portal>
      </ShowIf>
    );
  }
}

Menu.defaultProps = {
  footer: null,
  open: false,
  centered: false,
  bottom: false,
  className: '',
  anchorEl: null,
  id: null,
};

Menu.propTypes = {
  /**
   * Menu contents: elements to be displayed in the menu.
   */
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  /**
   * Menu footer.
   */
  footer: PropTypes.node,
  open: PropTypes.bool,
  /**
   * True if you want list to be vertically centered to the anchor.
   * Otherwise, it will be attached to the anchor's top-left corner.
   */
  centered: PropTypes.bool,
  /**
   * True if you want the list to be on the bottom of the anchor, when possible.
   * Otherwise, it will be attached to the anchor's top-left corner.
   */
  bottom: PropTypes.bool,
  /**
   * The function to call when menu is closed.
   */
  onClose: PropTypes.func.isRequired,
  /**
   * The DOM element used to set the position of the menu.
   */
  anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Additional className that must be applied to Menu.
   */
  className: PropTypes.string,
  id: PropTypes.string,
};

module.exports = Menu;
