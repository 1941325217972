/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,
 no-param-reassign,react/no-access-state-in-setstate,consistent-return,jsx-a11y/anchor-has-content,
 jsx-a11y/control-has-associated-label */
const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const Avatar = require('@common/AvatarBox/Avatar.react');

module.exports = class Company extends React.Component {
  handleDelete() {
    this.props.showDeleteModal(this.props);
  }

  getGroupName() {
    return this.props.group ? this.props.group.name : '';
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  render() {
    return (
      <TableRow>
        <TableCell className="clients-companies__column--profile-pic"><Avatar name={this.props.name} /></TableCell>
        <TableCell className="clients-companies__column--name">{this.getUnescapedValue(this.props.name)}</TableCell>
        <TableCell className="clients-companies__column--acronym">{this.props.acronym}</TableCell>
        <TableCell className="clients-companies__column--group">{this.getGroupName()}</TableCell>
        <TableCell className="clients-companies__column--actions">
          <button type="button"
            className="clients__table-button clients__table-button--hidden wethod-icon-button"
            onClick={this.handleDelete.bind(this)}>
            <div className="wethod-icon wethod-icon-delete wethod-icon-delete--black" />
          </button>
          <a className="clients__table-button"
            href={`#finance/client/company/${this.props.id}`}>
            <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
          </a>
        </TableCell>
      </TableRow>
    );
  }
};
