const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/inputs/Select/SelectItem.react');
const AutocompleteModel = require('../../../../../../../../models/Autocomplete');
const AutocompleteFilter = require('../../../../timesheets/components/advanced-search/filters/CustomizableOptionsAutocompleteFilter.react');

module.exports = class ProjectFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getProjectsByNameOrJobOrderAutocomplete(keyword)
      .done((list) => this.setState({
        availableItems: list.map((project) => project),
        isLoading: false,
      }));
  }

  getOptions() {
    return this.state.availableItems.map((project) => (
      <SelectItem key={project.id}
        value={project.name}>
        <span>{_.unescape(project.name)}</span><br />
        <span className="timesheet-changelog__secondary-info">{project.jobOrder}</span>
      </SelectItem>
    ));
  }

  render() {
    return (
      <AutocompleteFilter name={this.props.name}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.props.onChange}
        expanded={this.props.expanded}
        isLoading={this.state.isLoading}
        onFilter={this.onFilter}
        getOptions={this.getOptions.bind(this)} />
    );
  }
};
