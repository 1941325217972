const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const DateService = require('../../../../../../../services/DateService');

const ConfirmWeekendPlanModal = ({
  onCancelClick,
  changes,
  editPlan,
  confirmWeekendPlanning,
}) => {
  function handleOkClick() {
    editPlan(changes);
    onCancelClick();
    confirmWeekendPlanning();
  }

  const getChangesWithoutWeekends = () => changes
    .filter((change) => !DateService.isWeekend(change.day));

  function handleCancelClick() {
    const changesWithoutWeekends = getChangesWithoutWeekends();
    if (changesWithoutWeekends.length) {
      editPlan(getChangesWithoutWeekends());
    }
    onCancelClick();
  }

  return (
    <Modal onClose={onCancelClick} title="Weekend allocation">
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            You are going to change plan over the weekend.
          </ModalBodyBlock>
          <ModalBodyBlock>
            Would you like to continue or do you want to exclude changes on Saturdays and
            Sundays?
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter>
          <ModalFooterAction onClick={handleCancelClick}>
            Exclude them
          </ModalFooterAction>
          <ModalFooterAction onClick={handleOkClick}>
            Continue
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

module.exports = ConfirmWeekendPlanModal;
