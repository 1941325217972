const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const formatNumber = require('../../../../../services/FormatService').formatDecimalNumber;
const InputCell = require('./InputCell.react');

const TimeOffRow = ({ item, readOnly, isSaving, onChange, onBlur }) => {
  function getFormattedValue(value) {
    return formatNumber(value, false, 2);
  }

  function getRemainingStyle(value) {
    let style = 'time-off-item__cell';
    if (value > 0) {
      style += ' time-off-item__cell--error';
    }
    return style;
  }

  function getRemaining(type) {
    return item.time_off_targets[type] - item.used[type]
      - item.planned[type] - item.requested[type];
  }

  function getFeedback() {
    return isSaving ? 'Saving' : '';
  }

  return (
    <TableRow feedback={getFeedback()}>
      <TableCell className="time-off-item__user">{`${item.employee.name} ${item.employee.surname}`}</TableCell>
      <InputCell className="time-off-item__cell"
        name="vacation"
        label="vacation"
        readOnly={readOnly}
        disabled={isSaving}
        value={item.time_off_targets.vacation}
        onChange={onChange}
        onBlur={onBlur} />
      <TableCell className="time-off-item__cell">{getFormattedValue(item.used.vacation)}</TableCell>
      <TableCell className="time-off-item__cell">{getFormattedValue(item.planned.vacation)}</TableCell>
      <TableCell className="time-off-item__cell">{getFormattedValue(item.requested.vacation)}</TableCell>
      <TableCell className="time-off-item__cell">
        {getFormattedValue(getRemaining('vacation'))}
      </TableCell>
      <InputCell className="time-off-item__cell"
        name="leave"
        label="leave"
        readOnly={readOnly}
        disabled={isSaving}
        value={item.time_off_targets.leave}
        onChange={onChange}
        onBlur={onBlur} />
      <TableCell className="time-off-item__cell">{getFormattedValue(item.used.leave)}</TableCell>
      <TableCell className="time-off-item__cell">{getFormattedValue(item.planned.leave)}</TableCell>
      <TableCell className="time-off-item__cell">{getFormattedValue(item.requested.leave)}</TableCell>
      <TableCell className="time-off-item__cell">
        {getFormattedValue(getRemaining('leave'))}
      </TableCell>
      <InputCell className="time-off-item__cell"
        name="target"
        label="target"
        readOnly={readOnly}
        disabled={isSaving}
        value={item.time_off_targets.target}
        onChange={onChange}
        onBlur={onBlur} />
      <TableCell className="time-off-item__cell">{getFormattedValue(item.used.target)}</TableCell>
      <TableCell className="time-off-item__cell">{getFormattedValue(item.planned.target)}</TableCell>
      <TableCell className="time-off-item__cell">{getFormattedValue(item.requested.target)}</TableCell>
      <TableCell className={getRemainingStyle(getRemaining('target'))}>
        {getFormattedValue(getRemaining('target'))}
      </TableCell>
    </TableRow>
  );
};

module.exports = TimeOffRow;
