const React = require('react');
const Typography = require('@common/Typography/Typography.react');
const RadioGroup = require('@common/inputs/RadioGroup/RadioGroup.react');
const Radio = require('@common/inputs/RadioGroup/Radio.react');
const Modal = require('@common/Modal/Modal.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const YearNavigator = require('@common/YearNavigator/YearNavigator.react');
const FiscalYearService = require('../../../../../../../services/FiscalYearService');

module.exports = class ExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.fiscalYearService = new FiscalYearService();
    this.state = {
      year: this.fiscalYearService.getShiftedYear(new Date().getFullYear()),
      export: 'active-only',
    };
  }

  handleConfirm() {
    if (!this.props.isWaiting) {
      if (this.state.export === 'active-only') {
        this.props.exportModule('active-only', this.props.buFilter);
      } else if (this.state.export === 'all') {
        this.props.exportModule('all', this.props.buFilter, this.state.year);
      } else {
        this.props.exportModule(this.props.typeFilter, this.props.buFilter, this.state.year);
      }
    }
  }

  handleYearChange(year) {
    this.setState({ year });
  }

  onRadioChange(e) {
    this.setState({ export: e.target.value });
  }

  getBody() {
    return (
      <div>
        <RadioGroup name="export_radio" onChange={this.onRadioChange.bind(this)} value={this.state.export}>
          <Radio label="Export all active projects" value="active-only" />
          <Radio label="Export all projects" value="all" />
          <Radio label="Export current filtered view" value="filtered" />
        </RadioGroup>
        <ShowIf condition={this.state.export !== 'active-only'}>
          <div style={{ marginTop: '20px' }}>
            <Typography component="span" className="production-plan__modal-description">Choose a year to export.</Typography>
            <YearNavigator year={this.state.year} changeDate={this.handleYearChange.bind(this)} />
          </div>
        </ShowIf>
      </div>
    );
  }

  render() {
    return (
      <Modal title="Export production plan" onClose={this.props.onCancelClick}>
        <ModalContent>
          <ModalBody>{this.getBody()}</ModalBody>
          <ModalFooter feedback={this.props.isWaiting ? 'Sending export...' : ''}>
            <ModalFooterAction onClick={this.props.onCancelClick} disabled={this.props.isWaiting}>
              Cancel
            </ModalFooterAction>
            <ModalFooterAction onClick={this.handleConfirm.bind(this)}
              disabled={this.props.isWaiting}>
              Email to me
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
};
