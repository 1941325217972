/* eslint-disable class-methods-use-this */
const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const FormatService = require('../../../../../../services/FormatService');

module.exports = class Project extends React.Component {
  getFeedbackCell() {
    return (this.props.hasFeedback && this.props.reason_why)
      ? this.props.reason_why.name
      : '';
  }

  getManagers() {
    const { account } = this.props;
    const { pm } = this.props;
    const formattedPm = FormatService.formatOneLetterPersonName(`${pm.person.name} ${pm.person.surname}`);

    if (account && account.id !== pm.id) {
      const formattedAccount = FormatService.formatOneLetterPersonName(`${account.person.name} ${account.person.surname}`);
      return `${_.unescape(formattedPm)} - ${_.unescape(formattedAccount)}`;
    }
    return _.unescape(formattedPm);
  }

  /**
   * Format the project value as unit or as K according to the company settings.
   * @param value
   * @returns {string}
   */
  formatValue(value) {
    if (this.props.isValueAsUnit) {
      return `${numeral(value).format('0,0')}`;
    }
    return `${numeral(value / 1000).format('0,0.0')} K`;
  }

  render() {
    return (
      <TableRow>
        <TableCell className="client-projects__column--name">
          <span className="client-projects__column--name-content">
            <span className="client-projects__column--label">{_.unescape(this.props.name)}</span>
            <span className="client-projects__column--managers">{this.getManagers()}</span>
          </span>
        </TableCell>
        <TableCell className="client-projects__column--feedback">{this.getFeedbackCell()}</TableCell>
        <TableCell
          className="client-projects__column--value">
          {this.formatValue(this.props.estimate)}
        </TableCell>
        <TableCell className="client-projects__column--probability">
          {this.props.probability}
          {' '}
          %
        </TableCell>
        <TableCell className="client-projects__column--actions">
          <a href={`/#pipeline/projects?id=${this.props.id}`} className="wethod-open-sidebar-button">
            <span className="wethod-open-sidebar-button__icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
          </a>
        </TableCell>
      </TableRow>
    );
  }
};
