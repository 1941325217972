const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

const CellDelta = ({
  children,
  className,
  visible,
  label,
}) => (
  <ShowIf condition={visible} showElse={null}>
    <div className={`wethod-budget-calculator__cell-delta ${className}`} aria-label={label}>{children}</div>
  </ShowIf>
);

module.exports = CellDelta;
