const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');
const ArchivedIcon = require('./ArchivedIcon.react');
const ProbabilitySelect = require('./ProbabilitySelect.react');
const ProbabilityInput = require('./ProbabilityInput.react');

const ProbabilityCell = ({
  updateErrors, readOnly, disabled, value, color, onChange, error, name, label, style, archived,
  showAsSelect = true, metadataService,
}) => {
  const getContent = () => {
    if (archived) {
      return <ArchivedIcon />;
    }
    if (showAsSelect) {
      return (
        <ProbabilitySelect updateErrors={updateErrors}
          value={value}
          color={color}
          onChange={onChange}
          label={label}
          errorText={error} />
      );
    }

    return (
      <ProbabilityInput
        value={value}
        label={label}
        errorText={error}
        updateErrors={updateErrors}
        onChange={onChange} />
    );
  };

  return (
    <TableCell className="pipeline-item__probability" style={style}>
      <InputMetadataManager metadataService={metadataService}
        name={name}
        canEdit={!readOnly && !archived && !disabled}>
        {getContent()}
      </InputMetadataManager>
    </TableCell>
  );
};

module.exports = ProbabilityCell;
