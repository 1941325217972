const React = require('react');
const ProjectSelect = require('@common/material-input/SidebarSelectAddButton.react');

module.exports = class ProjectInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
    };
  }

  onFilter(name, value) {
    this.setState({ filter: value });
  }

  getFilteredProjects() {
    return this.props.projects.filter((project) => project.name.toLowerCase()
      .indexOf(this.state.filter) !== -1);
  }

  render() {
    return (
      <div className="supplier__modal-rating-input">
        <h3 className="wethod-sidebar__subtitle">Project supplied</h3>
        <ProjectSelect name="project"
          placeholder="Project name"
          value={this.props.value}
          items={this.getFilteredProjects()}
          onFilter={this.onFilter.bind(this)}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required />
      </div>
    );
  }
};
