const React = require('react');
const Manager = require('@common/widgets/Files/components/FileManager.react');
const GoogleContent = require('./FileManagerGoogleContent.react');
const S3Content = require('./FileManagerS3Content.react');

const FileManager = ({ canEdit, projectId, projectName, clientName, externalDriveConfig }) => {
  if (externalDriveConfig.enabled || externalDriveConfig.error) {
    return (
      <Manager className="project-canvas__widget--files">
        <GoogleContent label="G Drive"
          canEdit={canEdit}
          projectId={projectId}
          projectName={projectName}
          clientName={clientName}
          externalDriveConfig={externalDriveConfig} />
        <S3Content label="wethod"
          canEdit={canEdit}
          externalDriveConfig={externalDriveConfig}
          projectId={projectId} />
      </Manager>
    );
  }
  return (
    <Manager className="project-canvas__widget--files">
      <S3Content label="wethod"
        canEdit={canEdit}
        externalDriveConfig={externalDriveConfig}
        projectId={projectId} />
    </Manager>
  );
};

module.exports = FileManager;
