const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');

const BudgetStatusCell = ({ status, style }) => {
  const getIcon = () => {
    switch (status) {
      case 0:
        return <div className="pipeline__budget-status wethod-icon-medium wethod-icon-medium-draft" />;
      case 1:
        return <div className="pipeline__budget-status wethod-icon-medium wethod-icon-medium-pause" />;
      case 2:
        return <div className="pipeline__budget-status wethod-icon-medium wethod-icon-medium-checked" />;
      default:
        return null;
    }
  };

  return (
    <TableCell name="budget-status"
      className="pipeline-item__budget"
      style={style}>
      {getIcon()}
    </TableCell>
  );
};

module.exports = BudgetStatusCell;
