const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('./DisableMulticurrencyModalContent.react');
const RequestManager = require('../../../../../containers/RequestManager');
const PermissionManager = require('../../../../../containers/PermissionManager');

const DisableMulticurrencyModal = ({ closeModal }) => (
  <Modal title="Disable multicurrency" onClose={closeModal}>
    <PermissionManager>
      <RequestManager>
        <ModalContent closeModal={closeModal} />
      </RequestManager>
    </PermissionManager>
  </Modal>
);

module.exports = DisableMulticurrencyModal;
