/* eslint-disable react/sort-comp */
const React = require('react');
const Modal = require('@common/modal2/Modal.react');

module.exports = class ConfirmAttributeDeleteModal extends React.Component {
  isDeleting() {
    return this.props.waitingFor.filter((key) => key === `delete-board-${this.props.data.boardId}-item-${this.props.data.itemId}`).length > 0;
  }

  getBody() {
    return (
      <div>
        You are about to delete
        {' '}
        <span className="bold">{this.props.data.itemName}</span>
        ,
        are you sure you want to continue?
      </div>
    );
  }

  getFeedback() {
    if (this.isDeleting()) return <div className="profile-contact-info__feedback">Deleting</div>;
    return null;
  }

  handleDelete() {
    if (!this.isDeleting()) this.props.onDelete(this.props.data.boardId, this.props.data.itemId);
  }

  render() {
    return (
      <Modal title="Delete item" onCancelClick={this.props.onCancelClick} className="project-canvas-alert">
        {this.getBody()}
        <div className="profile-contact-info__actions">
          <button type="button" onClick={this.props.onCancelClick} className="wethod-button capitalize">Cancel</button>
          <button
            type="button"
            onClick={this.handleDelete.bind(this)}
            className="wethod-button wethod-button--warning capitalize">
            Yes, delete
          </button>
        </div>
        {this.getFeedback()}
      </Modal>
    );
  }
};
