const React = require('react');
const IconButtonTooltip = require('@common/TooltipFixed/IconButtonTooltip.react');
const IconLinkTooltip = require('@common/TooltipFixed/IconLinkTooltip.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

const ContactSidebarActions = ({ client, canEdit, onDelete }) => (
  <div>
    <IconLinkTooltip href={`#finance/client/person/${client.id}`}
      label="Go to contact page"
      borderless>
      <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
    </IconLinkTooltip>
    <ShowIf condition={canEdit}>
      <div className="wethod-vertical-line" />
    </ShowIf>
    <ShowIf condition={canEdit}>
      <IconButtonTooltip label="Delete contact" onClick={onDelete} borderless>
        <span className="wethod-icon-delete wethod-icon-delete--black" />
      </IconButtonTooltip>
    </ShowIf>
  </div>
);

module.exports = ContactSidebarActions;
