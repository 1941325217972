const React = require('react');
const IconButton = require('@common/TooltipFixed/IconButtonTooltip.react');
const IconLink = require('@common/TooltipFixed/IconLinkTooltip.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const Icon = require('@common/Icon/Icon.react');
const IconButtonTooltip = require('@common/TooltipFixed/IconButtonTooltip.react');

module.exports = class ExpenseSidebarActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  getOptionsMenu(projectId) {
    return (
      <Menu
        open={this.state.showMenu}
        onClose={this.closeMenu.bind(this)}
        anchorEl={this.optionsButton}>
        <MenuItem className="wethod-menu__item--link">
          <a href={`#timeline/${projectId}`}
            target="_blank"
            rel="noreferrer">
            Timeline
          </a>
        </MenuItem>
        <MenuItem className="wethod-menu__item--link">
          <a href={`/#project-status/${projectId}`}
            target="_blank"
            rel="noreferrer">
            Project status
          </a>
        </MenuItem>
        <MenuItem className="hidden-mobile wethod-menu__item--link">
          <a href={`#planning/production-plan?project_id:${projectId}`}
            target="_blank"
            rel="noreferrer">
            Production plan
          </a>
        </MenuItem>
        <MenuItem className="hidden-mobile wethod-menu__item--link">
          <a href={`/#project/${projectId}/invoice-plan`}
            target="_blank"
            rel="noreferrer">
            Invoice plan
          </a>
        </MenuItem>
      </Menu>
    );
  }

  setMenuAnchorRef(ref) {
    this.optionsButton = ref;
  }

  showMenu() {
    if (!this.state.showMenu) this.setState({ showMenu: true });
  }

  closeMenu() {
    if (this.state.showMenu) this.setState({ showMenu: false });
  }

  render() {
    const projectId = this.props.item.project.id;
    return (
      <div>
        <IconLink label="Canvas"
          borderless
          href={`#project/${projectId}/canvas`}
          target="_blank">
          <span className="wethod-icon wethod-icon-canvas wethod-icon-canvas--black" />
        </IconLink>
        <IconLink label="Planning"
          borderless
          href={`#planning/people?project_id=${projectId}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-planning wethod-icon-planning--black" />
        </IconLink>
        <IconLink label="Budget"
          borderless
          href={`#pipeline/budget/${projectId}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
        </IconLink>
        <IconLink label="Report"
          borderless
          href={`#project/${projectId}/report`}
          target="_blank">
          <span className="wethod-icon wethod-icon-project-report wethod-icon-project-report--black" />
        </IconLink>
        <IconLink label="Pipeline"
          borderless
          href={`#pipeline/projects?id=${projectId}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />
        </IconLink>
        <ShowIf condition={this.props.canDelete}>
          <div className="wethod-vertical-line" />
        </ShowIf>
        <ShowIf condition={this.props.canDelete}>
          <IconButton label="Delete expense" onClick={this.props.onDelete} borderless>
            <span className="wethod-icon-delete wethod-icon-delete--black" />
          </IconButton>
        </ShowIf>
        <ShowIf condition={this.props.canDelete}>
          <div className="wethod-vertical-line" />
        </ShowIf>
        <IconButtonTooltip label="More options"
          borderless
          onClick={this.showMenu.bind(this)}
          rootRef={this.setMenuAnchorRef.bind(this)}>
          <Icon icon="more" />
        </IconButtonTooltip>
        {this.getOptionsMenu(projectId)}
      </div>
    );
  }
};
