const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const Empty = require('@common/lists/EmptyRow.react');
const Language = require('./LanguageRow.react');

module.exports = class LanguagesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      size: 0,
    };
  }

  getLoadedLanguages() {
    return this.props.languages.slice(0, this.state.page * this.state.size);
  }

  getLanguages() {
    const languages = this.getLoadedLanguages();

    return languages.map((language) => (
      <Language key={language.name}
        language={language}
        onClick={this.props.changeLanguage}
        selected={this.isLanguageSelected(language.name)} />
    ));
  }

  isLanguageSelected(name) {
    return this.props.selectedLanguage === name;
  }

  hasMore() {
    const loaded = this.state.page * this.state.size;
    return this.props.languages.length > loaded;
  }

  loadMore(size, page) {
    this.setState({
      page,
      size,
    });
  }

  render() {
    return (
      <Table columns={2} maxHeight={this.props.maxHeight}>
        <TableHead>
          <TableRow>
            <TableCell name="language" className="knowledge__column-language">Language</TableCell>
            <TableCell name="distribution" className="knowledge__column-distribution">Distribution</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          empty={<Empty message="No languages found" />}>
          {this.getLanguages()}
        </TableBody>
      </Table>
    );
  }
};
