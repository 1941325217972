const React = require('react');
const Select = require('@common/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItem.react');

const WonLostFeedbackSelect = ({ value, items, onChange }) => {
  const getOptions = () => items.map((feedback) => (
    <SelectItem key={feedback.id} value={feedback.id}>{feedback.name}</SelectItem>
  ));

  function getItemById(itemId) {
    return items.find((item) => item.id === itemId);
  }

  function handleChange(e) {
    const { name } = e.target;
    const itemId = e.target.value;
    onChange(name, getItemById(itemId));
  }

  const errorText = items.length === 0 ? 'No feedback available' : '';

  return (
    <Select name="feedback"
      id="reason-why-list"
      value={value ? value.id : null}
      onChange={handleChange}
      errorText={errorText}>
      {getOptions()}
    </Select>
  );
};

module.exports = WonLostFeedbackSelect;
