const { connect } = require('react-redux');
const Component = require('@common/AdvancedSearch/filters/CheckboxFilter/CheckboxFilter.react');

const mapStateToProps = () => ({
  availableOptions: [{
    label: 'Missing',
    value: 'missing',
  }, {
    label: 'Draft',
    value: 'draft',
  }, {
    label: 'Submitted',
    value: 'submitted',
  }, {
    label: 'Approved',
    value: 'approved',
  }],
});

module.exports = connect(mapStateToProps)(Component);
