const React = require('react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');
const Typography = require('@common/Typography/Typography.react');
const Icon = require('@common/Icon/Icon.react');
const PricelistSelect = require('./PricelistSelect.react');
const PricelistReadOnly = require('./PricelistReadonly.react');
const NameCell = require('../../Cell/NameCell.react');
const PricelistTooltip = require('./PricelistTooltip.react');

const Pricelist = ({ pricelist, onClick, pricelistIsEditable }) => {
  let component = <PricelistReadOnly name={pricelist.name} />;

  if (pricelistIsEditable) {
    component = <PricelistSelect name={pricelist.name} onClick={onClick} />;
  }

  return (
    <NameCell>
      <div className="wethod-budget-calculator__header-price-list" data-testid="pricelist-cell">
        <PricelistTooltip pricelistName={pricelist.name}>
          {component}
        </PricelistTooltip>
        { pricelist.usedByEmployees === false && (
          <Tooltip
            className="wethod-budget-calculator__header-price-list-warning"
            label={(
              <Typography
                size={Typography.SIZES.PX12}
                weight={Typography.WEIGHTS.NORMAL}>
                No team member associated to this pricelist. Select other pricelist
              </Typography>
            )}>
            <Icon icon="warning" size="1.6em" />
          </Tooltip>
        ) }
      </div>
    </NameCell>
  );
};

module.exports = Pricelist;
