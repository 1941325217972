/* eslint-disable react/sort-comp,class-methods-use-this,consistent-return */
const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const Opportunity = require('../containers/Opportunity');
const Search = require('../containers/SearchBox');
const OpportunityManager = require('../containers/OpportunityManager');

module.exports = class Opportunities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  updateTableHeight() {
    this.setState({ tableHeight: this.getTableMaxHeight() });
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getOpportunities() {
    return this.props.filteredItems.map((item) => (
      <OpportunityManager key={item.id} item={item}>
        <Opportunity opportunity={item} />
      </OpportunityManager>
    ));
  }

  hasMore() {
    return this.props.hasMore;
  }

  loadMore(size, page) {
    if (!this.props.isLoading) {
      this.props.loadMore(page, size, this.props.keyword);
      this.setState({
        size,
      });
    }
  }

  filterOpportunities(keyword) {
    this.props.filter(keyword, this.state.size);
  }

  getSearch() {
    return <Search filter={this.filterOpportunities.bind(this)} />;
  }

  getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.basket__actions-wrapper').outerHeight(true) + 30);
  }

  render() {
    return (
      <div>
        <Table columns={8} maxHeight={this.state.tableHeight} search={this.getSearch()}>
          <TableHead>
            <TableRow>
              <TableCell className="basket-table__column--name" name="name">Name</TableCell>
              <TableCell className="basket-table__column--account" name="pm">In Charge</TableCell>
              <TableCell className="basket-table__column--target" name="target">Target Value</TableCell>
              <TableCell className="basket-table__column--follow-up" name="due_date">Follow up</TableCell>
              <TableCell className="basket-table__column--state" />
              <TableCell className="basket-table__column--actions" />
            </TableRow>
          </TableHead>
          <TableBody
            hasMore={this.hasMore()}
            loadMore={this.loadMore.bind(this)}
            empty="There's nothing here! What about adding an opportunity?">
            {this.getOpportunities()}
          </TableBody>
        </Table>
      </div>
    );
  }
};
