const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableBody = require('@common/Table2/TableBody.react');
const Search = require('@common/Table2/TableSearch.react');
const Loader = require('@common/Loader/Loader.react');
const TableHead = require('../containers/TableHead');
const Project = require('../containers/Project');

module.exports = class ProductionPlanList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableHeight: null,
    };
  }

  static getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  componentDidMount() {
    this.updateTableHeight();
  }

  getProjects() {
    return this.props.projects
      .map((project, index, projects) => {
        const firstOfProbability = index === 0 || projects[index - 1].probability
          !== project.probability;
        return <Project key={project.id} {...project} firstOfProbability={firstOfProbability} />;
      });
  }

  getEmptyContent() {
    if (this.props.isWaiting) {
      return <Loader />;
    }
    return 'No results found.';
  }

  updateTableHeight() {
    this.setState({ tableHeight: ProductionPlanList.getTableMaxHeight() });
  }

  hasMore() {
    return this.props.hasMore;
  }

  loadMore(size, page) {
    if (!this.props.isWaiting) {
      this.props
        .loadMore(
          size,
          page,
          this.props.keyword,
          this.props.typeFilter,
          this.props.bu,
        );
    }
  }

  filter(keyword) {
    this.props.filter(this.props.size, keyword, this.props.typeFilter, this.props.bu);
  }

  render() {
    return (
      <Table
        columns={14}
        maxHeight={this.state.tableHeight}
        search={(
          <Search
            keyword={this.props.keyword}
            placeholder={'Search for project\'s name, pm or client'}
            filter={this.filter.bind(this)} />
        )}>
        <TableHead />
        <TableBody hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getProjects()}
        </TableBody>
      </Table>
    );
  }
};
