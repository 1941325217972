const React = require('react');
const ForbiddenError = require('@common/SectionError/ForbiddenSectionError.react');

/**
 * Checks if user has right permissions to access this section, if not shows error message instead
 * of section content.
 * @param children Section content
 * @param canView
 * @return {JSX.Element|*}
 * @constructor
 */
const SectionErrorChecker = ({ children, canView }) => {
  if (!canView) {
    return <ForbiddenError />;
  }
  return children;
};

module.exports = SectionErrorChecker;
