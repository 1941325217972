const React = require('react');
const Mobile = require('@common/media-queries/Mobile.react');
const Title = require('../../../containers/sections/Sales/Title');
const WonProjects = require('../../../containers/sections/Sales/WonProjects');
const NewClients = require('../../../containers/sections/Sales/NewClients');
const ProfitableProjects = require('../../../containers/sections/Sales/ProfitableProjects');
const Reasons = require('../../../containers/sections/Sales/Reasons');
const PipelineValue = require('../../../containers/sections/Sales/PipelineValue');
const Backlog = require('../../../containers/sections/Sales/Backlog');
const Goodbye = require('./Goodbye.react');
const PipelineValueChart = require('../../../containers/sections/Sales/PipelineValueChart');
const Section = require('../../../../common/components/Section.react');

module.exports = ({ actions }) => {
  const getCharts = () => (
    <div>
      <PipelineValueChart />
    </div>
  );
  return (
    <Section left={getCharts()}>
      <Title />
      <WonProjects />
      <NewClients />
      <ProfitableProjects />
      <Reasons />
      <PipelineValue />
      <Backlog />
      <Mobile>{getCharts()}</Mobile>
      <Goodbye />
      {actions}
    </Section>
  );
};
