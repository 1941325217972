const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');

const SupplierSelectItem = ({
  available,
  name,
  onClick,
}) => {
  function isAvailable() {
    return available;
  }

  if (isAvailable()) {
    return (
      <SelectItem
        onClick={onClick}>{_.unescape(name)}
      </SelectItem>
    );
  }

  return (
    <SelectItem className="orders__supplier-item"
      onClick={onClick}>
      <span>{_.unescape(name)}</span> <span>n/a</span>
    </SelectItem>
  );
};

module.exports = SupplierSelectItem;
