const React = require('react');
const ModalBody = require('@common/Modal/ModalBody.react');
const Modal = require('@common/Modal/Modal.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');

/**
 * Modal that ask for confirmation when user is trying to create a product.
 * @param {function} closeModal - Invoked to close this modal
 * @param {boolean} isSaving - Tells if the given product is saving
 * @param {function} saveProduct - Invoked to save the given product
 * @param {{}} product - Product to save
 * @return {JSX.Element}
 * @constructor
 */
const CreationWarningModal = ({ closeModal, isSaving, saveProduct, product }) => {
  function onConfirmClick() {
    saveProduct(product);
    closeModal();
  }
  const feedback = isSaving ? 'Saving...' : '';

  return (
    <Modal title="Create product" onClose={closeModal}>
      <div className="products__modal-content">
        <ModalBody>
          <ModalBodyBlock>Wait! A product can't be changed once you save it.</ModalBodyBlock>
          <ModalBodyBlock>Are you sure you want to continue?</ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={feedback}>
          <ModalFooterAction onClick={closeModal}>Back</ModalFooterAction>
          <ModalFooterAction onClick={onConfirmClick}
            disabled={isSaving}>Yes
          </ModalFooterAction>
        </ModalFooter>
      </div>
    </Modal>
  );
};

module.exports = CreationWarningModal;
