/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Alerts = require('../containers/AlertsList');
const SearchBox = require('../containers/SearchBox');

class AlertsEngine extends React.Component {
  render() {
    return (
      <div className="alerts-engine">
        <SectionHeader
          current_section="Alerts Engine"
          preview_anchor_id="alerts-engine"
          helper_url="settings/index/#alerts-engine" />
        <div className="alerts-engine__header">
          <SearchBox />
        </div>
        <Alerts />
        <div className="alerts-engine__footer" />
        <div className="clear" />
      </div>
    );
  }
}

module.exports = AlertsEngine;
