const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const CreationRow = require('../../containers/work-hour-capacity/WorkHourCapacityCreationRow');
const EditRow = require('../../containers/work-hour-capacity/WorkHourCapacityEditRow');

class TableBodyRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
    };

    this.weekDays = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    this.updateErrors = this.updateErrors.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  getErrorMessage(name) {
    return this.hasErrors(name) ? this.state.errors[name][0].message : '';
  }

  hasErrors(name) {
    return this.state.errors?.[name] && this.state.errors[name].length > 0;
  }

  isNew() {
    return this.props.item.id == null || this.props.item.id === 'new-work-hour-capacity';
  }

  updateErrors(name, errors) {
    this.setState((state) => ({
      errors: {
        ...state.errors,
        [name]: errors,
      },
    }));
  }

  render() {
    return (
      <ShowIf condition={this.isNew()}
        showElse={(
          <EditRow item={this.props.item}
            errors={this.state.errors}
            weekDays={this.weekDays}
            hasErrors={this.hasErrors}
            getErrorMessage={this.getErrorMessage}
            updateErrors={this.updateErrors} />
              )}>
        <CreationRow item={this.props.item}
          weekDays={this.weekDays}
          hasErrors={this.hasErrors}
          getErrorMessage={this.getErrorMessage}
          updateErrors={this.updateErrors} />
      </ShowIf>
    );
  }
}

module.exports = TableBodyRow;
