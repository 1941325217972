const React = require('react');
const { connect } = require('react-redux');
const Modal = require('@common/Modal/SimpleModal/SimpleModal.react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const actions = require('../../actions');

const ExportModal = ({ exportMetadata }) => {
  exportMetadata();

  function closeModal() {
    modalService.closeModal();
  }

  return (
    <Modal onClose={closeModal} title="Export">
      You will receive a document shortly in your mailbox.
    </Modal>
  );
};

module.exports = connect(null, {
  exportMetadata: actions.exportMetadata,
})(ExportModal);
