const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const Typography = require('@common/Typography/Typography.react');
const status = require('../../segmentsStatus').default;
const timelines = require('../../timelines');

const ConfirmFreezeModal = ({ isSaving, updatedSegments, timeline, closeModal, save }) => {
  const segment = updatedSegments.find((seg) => seg.status === status.FROZEN);

  function getFormattedDate(date) {
    return date ? date.format('DD/MM/YYYY') : '';
  }

  function handleSave() {
    save(updatedSegments, timeline);
  }

  function getFeedback() {
    return isSaving ? 'Saving...' : null;
  }

  const startDate = getFormattedDate(moment(segment.from).startOf('month'));
  const endDate = getFormattedDate(moment(segment.to).endOf('month'));

  return (
    <Modal title="Important Information" onClose={closeModal}>
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            The data set of
            <Typography weight={Typography.WEIGHTS.SEMIBOLD}>
              {timelines.getTimelineLabel(timeline)}
            </Typography>
            from {startDate} to {endDate} will be
            <Typography weight={Typography.WEIGHTS.SEMIBOLD}>
              permanently frozen and no user will be able to edit it.
            </Typography>
            The action is irreversible.
            <br /><br />
            Are you sure you want to continue?
          </ModalBodyBlock>
        </ModalBody>
        <Footer feedback={getFeedback()}>
          <FooterAction onClick={closeModal}>
            Dismiss
          </FooterAction>
          <FooterAction onClick={handleSave}>
            Yes, freeze data
          </FooterAction>
        </Footer>
      </ModalContent>
    </Modal>
  );
};

module.exports = ConfirmFreezeModal;
