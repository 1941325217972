const React = require('react');
const Loading = require('@common/LoadingSmall.react');
const Select = require('@common/SearchSelect/SearchSelect.react');

module.exports = class IntercompanyProjectBody extends React.Component {
  constructor(props) {
    super(props);

    this.state = { project: null };
  }

  componentDidMount() {
    this.props.getIntercompanyInfo(this.props.intercompany.token);
  }

  onOkClick() {
    if (this.state.project !== null) {
      this.props.connectProject(this.props.intercompany.token, this.state.project);
    }
  }

  getFeedback() {
    if (this.props.isWaiting) {
      return <div className="wethod-modal2__feedback">Linking...</div>;
    }
    if (this.props.error) {
      return <div className="wethod-modal2__feedback wethod-modal2__feedback--error">{this.props.error}</div>;
    }
    return null;
  }

  getOkClassName() {
    let name = 'wethod-button';
    if (this.state.project === null) {
      name += ' disabled';
    }
    return name;
  }

  chooseProject(name, value) {
    this.setState({ project: value });
  }

  render() {
    if (!this.props.intercompany.client) {
      return <Loading />;
    }
    return (
      <div className="pipeline-projects__intercompany-link">
        <p>
          Link one of your projects to the intercompany task
          {' '}
          <span
            className="bold">
            {this.props.intercompany.client.task.name}
          </span>
          {' '}
          worth
          {' '}
          <span
            className="bold">
            {this.props.intercompany.client.task.value}
          </span>
          {' '}
          from the
          project
          {' '}
          <span className="bold">{this.props.intercompany.client.project.name}</span>
          {' '}
          from company
          {' '}
          <span
            className="bold">
            {this.props.intercompany.client.company.name}
          </span>
          .
        </p>
        <Select
          name="project"
          items={this.props.intercompany.projects}
          placeholder="Select a project to link"
          onChange={this.chooseProject.bind(this)} />
        <p>
          Probability and final net price of the selected project will be overwritten and will
          always reflect the values in the intercompany task:
        </p>
        <ul>
          <li>
            Your project <span className="bold">probability</span> will reflect the project's
            probability of the task
          </li>
          <li>
            Your project <span className="bold">final net price</span> will reflect the task's
            external cost.
          </li>
        </ul>
        <p>
          Do you want to proceed?
        </p>
        <div className="pipeline-projects__intercompany-link-actions">
          <button type="button" className="wethod-button" onClick={this.props.onCancelClick}>Cancel</button>
          <button type="button"
            onClick={this.onOkClick.bind(this)}
            className={this.getOkClassName()}>OK
          </button>
        </div>
        {this.getFeedback()}
      </div>
    );
  }
};
