const React = require('react');
const ErrorModal = require('@common/modals/ErrorModal.react');
const DeleteJobOrderConfirmationModal = require('../containers/modals/DeleteJOTemplateConfirmModal');
const FiscalYearChangeConfirmationModal = require('../containers/modals/FiscalYearChangeConfirmationModal');
const DeleteLocationConfirmationModal = require('../containers/modals/DeleteLocationConfirmationModal');
const DeleteWorkHourCapacityConfirmationModal = require('../containers/modals/DeleteWorkHourCapacityConfirmationModal');
const DeleteCompanyHolidayConfirmationModal = require('../containers/modals/DeleteCompanyHolidayConfirmationModal');
const UpdateFteConfirmationModal = require('../containers/modals/UpdateFteConfirmationModal');

module.exports = ({ toShow, modalData, closeModal }) => {
  const getModal = () => {
    switch (toShow) {
      case 'delete-job-order-template-confirmation':
        return <DeleteJobOrderConfirmationModal onClose={closeModal} data={modalData} />;
      case 'error':
        return <ErrorModal onCancelClick={closeModal} data={modalData} />;
      case 'fiscal-year-change-confirmation':
        return (
          <FiscalYearChangeConfirmationModal
            onClose={closeModal}
            data={modalData} />
        );
      case 'fte-change-confirmation':
        return (
          <UpdateFteConfirmationModal
            onClose={closeModal}
            data={modalData} />
        );
      case 'delete-work-hour-capacity-confirmation':
        return (
          <DeleteWorkHourCapacityConfirmationModal
            onClose={closeModal}
            workHourCapacity={modalData} />
        );
      case 'delete-company-holiday-confirmation':
        return (
          <DeleteCompanyHolidayConfirmationModal
            onClose={closeModal}
            data={modalData} />
        );
      case 'delete-locations-confirmation':
        return (
          <DeleteLocationConfirmationModal onClose={closeModal} data={modalData} />
        );
      default:
        return null;
    }
  };

  return getModal();
};
