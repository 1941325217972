const React = require('react');
const TableSearch = require('@common/Table2/TableSearch.react');

module.exports = ({
  search, placeholder = 'Search', filter, ...rest
}) => (
  <TableSearch placeholder={placeholder}
    keyword={search}
    filter={filter}
    {...rest} />
);
