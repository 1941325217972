const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

const DuplicatesModal = ({ onClose, data, isSaving, onSave }) => {
  const { supplier, duplicates } = data;

  function handleSave() {
    if (!isSaving) {
      const forceSaving = true;
      onSave(supplier, forceSaving);
    }
  }

  function getDuplicatesList() {
    return duplicates.map((duplicate) => <SupplierDuplicate key={duplicate.id} {...duplicate} />);
  }

  const getBody = () => (
    <div>
      <p>
        Similar contacts may already exist in the system.
      </p>
      <p>
        <b>Are you sure you would like to continue?</b>
      </p>
      <div className="suppliers__duplicate-list">
        {getDuplicatesList()}
      </div>
    </div>
  );

  return (
    <Modal title="Duplicates may be found" onCancelClick={onClose}>
      {getBody()}
      <div className="wethod-modal2__actions">
        <Button onClick={onClose}>No</Button>
        <Button onClick={handleSave}
          disabled={isSaving}>
          Yes, create anyway
        </Button>
      </div>
      <ShowIf condition={isSaving}>
        <div className="wethod-modal2__feedback">Saving...</div>
      </ShowIf>
    </Modal>
  );
};

const SupplierDuplicate = ({ name, email }) => (
  <div className="suppliers__duplicate">
    <div className="suppliers__duplicate-name"><b>{name}</b></div>
    <div className="suppliers__duplicate-email">{email}</div>
  </div>
);

module.exports = DuplicatesModal;
