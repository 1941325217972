const React = require('react');
const moment = require('moment');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const SidebarButton = require('@common/OpenSidebarButton.react');
const IconButton = require('@common/TooltipFixed/IconButtonTooltip.react');
const formatNumber = require('../../../../../services/FormatService').formatDecimalNumber;

const Expense = ({ expense, showSidebar, showNotesModal }) => {
  const formattedDate = moment(expense.date).format('DD/MM/YYYY');

  const formattedValue = formatNumber(parseFloat(expense.actual_value), false, 2);

  const formattedPersonName = `${expense.owner.name} ${expense.owner.surname}`;

  function showNotes() {
    showNotesModal(expense);
  }

  function handleSidebarOpen() {
    showSidebar(expense.id);
  }

  const getNotesButton = () => {
    let iconStyle = 'wethod-icon-notes wethod-icon-notes--black';
    iconStyle += expense.notes ? ' wethod-icon-notes--done' : '';
    return (
      <IconButton onClick={showNotes} label="Notes" className="wethod-icon-button--no-margin" borderless>
        <span className={iconStyle} />
      </IconButton>
    );
  };

  return (
    <TableRow>
      <TableCell className="expenses-table__joc-badge">
        <div style={{ background: expense.project.project_type.color }} />
      </TableCell>
      <TableCell className="expenses-table__category">{expense.category.name}</TableCell>
      <TableCell className="expenses-table__person">{formattedPersonName}</TableCell>
      <TableCell className="expenses-table__project">{expense.project.name}</TableCell>
      <TableCell className="expenses-table__date">{formattedDate}</TableCell>
      <TableCell className="expenses-table__notes">{getNotesButton()}</TableCell>
      <TableCell className="expenses-table__value">{formattedValue}</TableCell>
      <TableCell className="expenses-table__actions">
        <SidebarButton onClick={handleSidebarOpen} />
      </TableCell>
    </TableRow>
  );
};

module.exports = Expense;
