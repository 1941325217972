const React = require('react');
const { connect } = require('react-redux');
const moment = require('moment');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const Table = require('@common/Table2/Table2.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const IconButton = require('@common/Button/IconButton.react');
const RoundedButton = require('@common/Button/RoundedButton.react');
const TextField = require('@common/inputs/TextField/BasicTextField/BasicTextField.react');
const BasicDayPicker = require('@common/inputs/DayPicker/BasicDayPicker/BasicDayPicker.react');
const Checkbox = require('@common/inputs/Checkbox/Checkbox.react');
const actions = require('../../actions');

class ExternalIdsModal extends React.Component {
  static onClose() {
    modalService.closeModal();
  }

  static loadMore() {
    return false;
  }

  constructor(props) {
    super(props);
    this.state = {
      codes: this.props.user.employee.external_ids
        ? [...this.props.user.employee.external_ids]
        : [],
      error: null,
    };
    this.newCodesIds = -1; // negative ids are for newly added codes
    this.deletedCodes = [];

    this.onOk = this.onOk.bind(this);
  }

  onOk() {
    this.setState({ isLoading: true });

    const promises = [];
    this.state.codes.filter((code) => code.id < 0).forEach((code) => {
      promises.push(
        this.props.saveUserCode(this.props.user.employee.id, code),
      );
    });
    this.state.codes.filter((code) => code.id >= 0).forEach((code) => {
      const originalCode = this.props.user.employee.external_ids
        .find((item) => item.id === code.id);
      if (JSON.stringify(code) !== JSON.stringify(originalCode)) {
        promises.push(this.props.updateUserCode(code.id, code));
      }
    });
    this.deletedCodes.forEach((code) => {
      promises.push(this.props.deleteUserCode(code.id));
    });

    promises.forEach((promise) => {
      promise.fail((response) => {
        if (typeof response === 'string') {
          this.setState({ error: response });
        }
      });
    });

    $.when(...promises)
      .always(() => {
        this.setState({ isLoading: false });
      })
      .done(() => {
        this.props.onSuccess?.(this.state.codes);
        modalService.closeModal();
      });
  }

  onChange({ what, code }, e) {
    let value;
    if (what === 'include_in_export') {
      value = e.target.checked;
    } else
    if (what === 'start_date' || what === 'end_date') {
      value = moment(e.target.value).format('YYYY-MM-DD');
    } else {
      value = e.target.value;
    }
    this.setState((state) => ({
      ...state,
      codes: state.codes.map((c) => ({
        ...c,
        [what]: c.id === code.id ? value : c[what],
      })),
    }));
  }

  deleteCode(code) {
    this.setState((state) => ({
      codes: state.codes.filter((item) => item.id !== code.id),
    }));
    if (code.id >= 0) {
      this.deletedCodes.push(code);
    }
  }

  addCode() {
    this.setState((state) => ({
      codes: [...state.codes, {
        id: this.newCodesIds - 1,
        type: 'zucchetti',
        external_id: '',
        start_date: null,
        end_date: null,
        include_in_export: false,
      }],
    }));
    this.newCodesIds -= 1;
  }

  canSave() {
    if (this.state.codes.some((code) => (
      !code.external_id
    ))) {
      return false;
    }
    return true;
  }

  render() {
    return (
      <Modal
        onClose={ExternalIdsModal.onClose}
        title="Edit ID Code"
        className="team-external-ids-modal">
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              <div className="team-external-ids-modal__description">
                <p>
                  Edit the codes associeted to the user {`${this.props.user.person.name} ${this.props.user.person.surname}`} in a specific time range
                </p>
                <RoundedButton onClick={this.addCode.bind(this)}>
                  Add ID code
                </RoundedButton>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="">ID Code</TableCell>
                    <TableCell className="">From</TableCell>
                    <TableCell className="">To</TableCell>
                    <TableCell className="">Include in Export</TableCell>
                    <TableCell className="team-table__cell--shrink" />
                  </TableRow>
                </TableHead>
                <TableBody hasMore={false} loadMore={ExternalIdsModal.loadMore}>
                  { this.state.codes.map((code) => (
                    <TableRow key={code.id}>
                      <TableCell className="">
                        <TextField
                          name="code.external_id"
                          value={code.external_id}
                          onChange={this.onChange.bind(this, { what: 'external_id', code })} />
                      </TableCell>
                      <TableCell className="team-external-ids-modal__date">
                        <BasicDayPicker
                          name="code.start_date"
                          value={code.start_date}
                          onChange={this.onChange.bind(this, { what: 'start_date', code })} />
                      </TableCell>
                      <TableCell className="team-external-ids-modal__date">
                        <BasicDayPicker
                          name="code.end_date"
                          value={code.end_date}
                          onChange={this.onChange.bind(this, { what: 'end_date', code })} />
                      </TableCell>
                      <TableCell className="team-external-ids-modal__include-in-export">
                        <Checkbox
                          id={`code.include_in_export_${code.id}`}
                          name="code.include_in_export"
                          value="include_in_export"
                          label="Include in Export"
                          onChange={this.onChange.bind(this, { what: 'include_in_export', code })}
                          checked={code.include_in_export} />
                      </TableCell>
                      <TableCell className="team-table__cell--shrink">
                        <IconButton
                          icon="delete"
                          label="Delete"
                          onClick={this.deleteCode.bind(this, code)}
                          border={false} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ModalBodyBlock>
          </ModalBody>
          <ModalFooter
            error={this.state.error}
            feedback={this.state.isLoading ? 'Saving...' : null}>
            <ModalFooterAction
              onClick={ExternalIdsModal.onClose}
              disabled={this.state.isLoading}>
              Cancel
            </ModalFooterAction>
            <ModalFooterAction
              onClick={this.onOk}
              disabled={!this.canSave() || this.state.isLoading}>
              Save
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

module.exports = connect(null, {
  saveUserCode: actions.saveUserCode,
  updateUserCode: actions.updateUserCode,
  deleteUserCode: actions.deleteUserCode,
})(ExternalIdsModal);
