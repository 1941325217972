const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const IconLinkTooltip = require('@common/TooltipFixed/IconLinkTooltip.react');
const Icon = require('@common/Icon/Icon.react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');

const UnapprovedBudget = ({ item }) => {
  const getStatusIcon = (status) => {
    switch (status) {
      case 0:
        return <div className="data-freezing-engine__table-unapproved-budgets-budget-status-icon wethod-icon-medium wethod-icon-medium-draft" />;
      case 1:
        return <div className="data-freezing-engine__table-unapproved-budgets-budget-status-icon wethod-icon-medium wethod-icon-medium-pause" />;
      case 2:
        return <div className="data-freezing-engine__table-unapproved-budgets-budget-status-icon wethod-icon-medium wethod-icon-medium-checked" />;
      default:
        return null;
    }
  };

  const getPM = (pm) => {
    if (pm) {
      return `${pm.name} ${pm.surname}`;
    }
    return '-';
  };

  function getNonEmpty(value) {
    return value || '-';
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  return (
    <TableRow>
      <TableCell className="data-freezing-engine__table-unapproved-budgets-name">{item.project.name}</TableCell>
      <TableCell className="data-freezing-engine__table-unapproved-budgets-column">{getNonEmpty(item.project.id_job_order)}</TableCell>
      <TableCell className="data-freezing-engine__table-unapproved-budgets-column">{getNonEmpty(item.client?.corporate_name)}</TableCell>
      <TableCell className="data-freezing-engine__table-unapproved-budgets-column">{getPM(item.pm)}</TableCell>
      <TableCell className="data-freezing-engine__table-unapproved-budgets-budget-status">
        <Tooltip label={capitalize(item.budget?.string_status)}>
          {getStatusIcon(item.budget?.status)}
        </Tooltip>
      </TableCell>
      <TableCell className="data-freezing-engine__table-unapproved-budgets-probability">{`${item.project.probability}%`}</TableCell>
      <TableCell className="data-freezing-engine__table-unapproved-budgets-actions">
        <IconLinkTooltip label="Budget"
          borderless
          href={`#pipeline/budget/${item.project.id}`}
          target="_blank">
          <Icon icon="budget" />
        </IconLinkTooltip>
      </TableCell>
    </TableRow>
  );
};

module.exports = UnapprovedBudget;
