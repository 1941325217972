const React = require('react');
const Modal = require('@common/modal2/Modal.react');

const ErrorModal = ({ data, onClose }) => (
  <Modal title="Error" onCancelClick={onClose} className="invoice-plan__modal">
    <p>{data.message}</p><br />
    <div className="invoice-plan__modal-actions">
      <button type="button" onClick={onClose} className="wethod-button">Ok</button>
    </div>
  </Modal>
);

module.exports = ErrorModal;
