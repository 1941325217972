/* eslint-disable class-methods-use-this,react/sort-comp,no-case-declarations,react/jsx-no-bind,
 react/no-access-state-in-setstate */
const React = require('react');
const SearchInput = require('@common/SearchInput.react');
const Loading = require('@common/widgets/Loading.react');
const Activity = require('./Activity.react');
const Empty = require('../Empty.react');

module.exports = class Activities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredActivities: this.props.activities,
      keyword: '',
    };
  }

  componentDidMount() {
    this.props.getActivities();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activities.length !== this.props.activities.length) {
      this.handleFilter(this.state.keyword);
    }
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Filter the list of activities with the given keyword
   * @param keyword
   */
  handleFilter(keyword) {
    this.setState({
      filteredActivities: this.props.activities
        .filter((activity) => this.found(keyword, activity.item.name)
          || this.found(keyword, activity.project.name)
          || this.found(keyword, activity.client.name)),
      keyword,
    });
  }

  handleStatusChange(status, itemId, boardId, attributeId) {
    const activities = this.state.filteredActivities.map((activity) => (activity.item.id === itemId
      ? {
        ...activity,
        selected_status: status,
      }
      : activity));
    this.setState({
      filteredActivities: activities,
    });
    this.props.updateStatus(status, itemId, boardId, attributeId);
  }

  getActivities() {
    if (this.props.isWaiting) {
      return <Loading />;
    }
    if (!this.props.activities.length) {
      return (
        <Empty title="All done. The future is yours!"
          className="desk-widget-empty--activities"
          description="More activities for you will be shown here as they are added, so check back soon."
          image={<div className="desk-widget-empty__activities" />} />
      );
    }
    const activities = !this.state.filteredActivities.length
      ? <div className="desk-widget__body--empty-text">No activity found</div>
      : this.state.filteredActivities.map((activity) => (
        <Activity key={activity.item.id}
          updateStatus={this.handleStatusChange.bind(this)}
          {...activity} />
      ));
    return (
      <div className="wethod-widget__body--content">
        <div className="wethod-widget__search desk-widget__item">
          <SearchInput filter={this.handleFilter.bind(this)}
            keyword={this.state.keyword} />
        </div>
        <div className="desk-widget__item desk-widget-activities__subheader">
          <span className="desk-widget-activities__name">Item</span>
          <span className="desk-widget-activities__project">Project</span>
        </div>
        <div className="desk-widget__body--list">
          {activities}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="wethod-widget">
        <div className="wethod-widget__header">
          <span className="wethod-widget__title">Activities</span>
        </div>
        <div className="wethod-widget__body desk-widget-activities__body">
          {this.getActivities()}
        </div>
      </div>
    );
  }
};
