/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Text = require('@common/material-input/SidebarReadOnlyField.react');

module.exports = class TextMenuView extends React.Component {
  render() {
    return (
      <div className="project-canvas-menu project-canvas-menu-text">
        <Text name="text" value={this.props.value} />
      </div>
    );
  }
};
