const React = require('react');
const moment = require('moment');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const IconButtonTooltip = require('@common/TooltipFixed/IconButtonTooltip.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

const TimesheetLogRow = ({ timesheetLog }) => {
  const formatDate = (date) => (date ? moment(date).format('DD/MM/YYYY') : null);

  const formatName = (employee) => (employee ? `${employee.name} ${employee.surname}` : null);

  const formatNonEmpty = (value) => (value || '-');

  const getDeltaHours = (from, to) => {
    // When from or to are null, they are considered as 0
    const fromHours = from ? parseFloat(from) : 0;
    const toHours = to ? parseFloat(to) : 0;
    return toHours - fromHours;
  };

  return (
    <TableRow>
      <TableCell className="timesheet-changelog__column-employee">
        {formatName(timesheetLog.employee)}
      </TableCell>
      <TableCell className="timesheet-changelog__column-date">
        {formatDate(timesheetLog.date)}
        <ShowIf condition={timesheetLog.mode.toLowerCase() === 'weekly'}>
          <IconButtonTooltip className="timesheet-changelog__date-tooltip"
            label="Week starting on this date">
            <span>i</span>
          </IconButtonTooltip>
        </ShowIf>
      </TableCell>
      <TableCell className="timesheet-changelog__column-mode">{formatNonEmpty(timesheetLog.mode)}</TableCell>
      <TableCell className="timesheet-changelog__column-project">{timesheetLog.project_from.name}</TableCell>
      <TableCell className="timesheet-changelog__column-job-order">{formatNonEmpty(timesheetLog.project_from.job_order)}</TableCell>
      <TableCell className="timesheet-changelog__column-hours">
        {getDeltaHours(timesheetLog.hours_from, timesheetLog.hours_to)}
      </TableCell>
      <TableCell className="timesheet-changelog__column-date">{formatDate(timesheetLog.created)}</TableCell>
      <TableCell className="timesheet-changelog__column-employee">
        {formatNonEmpty(formatName(timesheetLog.updated_by))}
      </TableCell>
      <TableCell className="timesheet-changelog__column-project">
        {formatNonEmpty(timesheetLog.project_to?.name)}
      </TableCell>
    </TableRow>
  );
};

module.exports = TimesheetLogRow;
