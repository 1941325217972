const React = require('react');
const Rating = require('@common/Rating/Rating.react');

const VoteInput = ({ value, onChange }) => (
  <div className="supplier__modal-rating-input">
    <h3 className="wethod-sidebar__subtitle">Overall rating</h3>
    <Rating max={5}
      value={value}
      name="value"
      showNumericValue
      onChange={onChange} />
  </div>
);

module.exports = VoteInput;
