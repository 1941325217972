const React = require('react');
const _ = require('underscore');
const Select = require('@common/inputs/Select/OutlinedSelect/OutlinedSelect.react');
const SelectItem = require('@common/inputs/Select/SelectItem.react');

const ProjectTypeSelect = ({ value, availableValues, onChange, name, error, readOnly }) => {
  function handleChange(e) {
    const selected = availableValues.filter((type) => type.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  const getOptions = () => availableValues.map((type) => (
    <SelectItem key={type.id} value={type.id}>
      {_.unescape(type.name)}
    </SelectItem>
  ));

  return (
    <Select readOnly={readOnly}
      value={value}
      id="sidebar-project-type"
      label="Project type"
      onChange={handleChange}
      name={name}
      errorText={error}>
      {getOptions()}
    </Select>
  );
};

module.exports = ProjectTypeSelect;
