const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');

const BudgetConsumptionCell = ({ value, style }) => {
  const formattedValue = value !== null ? `${value}%` : '';

  return (
    <TableCell name="budget_consumption"
      className="pipeline-item__budget-consumption"
      style={style}>
      {formattedValue}
    </TableCell>
  );
};

module.exports = BudgetConsumptionCell;
