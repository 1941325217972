/* eslint-disable react/sort-comp */
const React = require('react');
const FilterSelect = require('@common/FilterSelect/FilterSelect.react');

module.exports = class MobileTypeSelect extends React.Component {
  getCurrentTypeButton() {
    if (this.props.current) {
      const label = this.props.items.filter((item) => item.key === this.props.current);
      return label[0].name;
    }
    return '';
  }

  handleChange(value) {
    const selectedItem = this.props.items.find((item) => item.name === value.name);
    this.props.onChange(this.props.date, selectedItem.key);
  }

  render() {
    return (
      <FilterSelect
        current={this.getCurrentTypeButton()}
        items={this.props.items}
        onFilterChosen={this.handleChange.bind(this)} />
    );
  }
};
