const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Languages = require('../containers/Languages');
const Skills = require('../containers/Skills');
const Interests = require('../containers/Interests');

module.exports = class Knowledge extends React.Component {
  componentDidMount() {
    this.props.getKnowledge();
  }

  render() {
    return (
      <div className="wethod-section-body knowledge">
        <div className="wethod-section-actions">
          <SectionHeader
            current_section="Knowledge"
            helper_url="reports/index/#knowledge"
            big />
        </div>
        <h2 className="knowledge__title">Languages</h2>
        <Languages />
        <h2 className="knowledge__title">Skills</h2>
        <Skills />
        <h2 className="knowledge__title">Interests</h2>
        <Interests />
      </div>
    );
  }
};
