const React = require('react');
const Switch = require('@common/inputs/Switch/Switch.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const WhitelistBody = require('./WhitelistSelect.react');

const WhitelistField = ({ value, enabled, readOnly, onChange, onEnable, name }) => {
  function getWhitelistEnabledBody() {
    return (
      <WhitelistBody
        name="whitelisted_employee"
        value={value}
        readOnly={readOnly}
        onChange={onChange} />
    );
  }

  return (
    <div>
      <ShowIf condition={!readOnly}>
        <Row className="wethod-sidebar__multiple-input-row--space-bottom">
          <div>
            <div className="pipeline__sidebar-description pipeline__sidebar-description--bottom-spaced">
              Allow <b>only</b> a selection of users to edit this project timesheet.
            </div>
            <Switch id="timesheet_whitelist"
              name={name}
              label="Enable timesheet whitelist"
              onChange={onEnable}
              disabled={readOnly}
              checked={enabled} />
          </div>
        </Row>
      </ShowIf>
      <ShowIf condition={enabled}>
        {getWhitelistEnabledBody()}
      </ShowIf>
    </div>
  );
};

module.exports = WhitelistField;
