const React = require('react');
const Radio = require('@common/inputs/RadioGroup/Radio.react');
const RadioGroup = require('@common/inputs/RadioGroup/RadioGroup.react');

const ModeSelect = ({ mode, onChange }) => (
  <div className="planning-people-repeat-mode">
    <div className="planning-people-repeat-mode__icon">
      <div className="wethod-icon-repeat wethod-icon-repeat--black" />
    </div>
    <div className="planning-people-repeat-mode__form">
      <div className="planning-people-repeat-mode__form-title">What to do</div>
      <RadioGroup name="mode" onChange={onChange} value={mode}>
        <Radio label="Repeat allocation" value="add" />
        <Radio label="Remove future allocation" value="remove" />
      </RadioGroup>
    </div>
  </div>
);

module.exports = ModeSelect;
