/* eslint-disable react/no-did-update-set-state,react/sort-comp */
const React = require('react');
const _ = require('underscore');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const InfoIconTooltip = require('@common/TooltipFixed/InfoIconTooltip.react');
const OpenSidebarButton = require('@common/OpenSidebarButton.react');
const DaysLeftInput = require('./DaysLeftInput.react');
const ProgressInput = require('./ProgressInput.react');
const formatName = require('../../../../../../services/FormatService').formatOneLetterPersonName;
const { formatByStep } = require('../../../../../../services/FormatService');
const formatNumber = require('../../../../../../services/FormatService').formatDecimalNumber;
const FrozenTooltip = require('./FrozenTooltip/FrozenTooltip.react');

module.exports = class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      daysLeft: this.props.days_left,
      showMobileActions: false,
    };
  }

  onTouchStart(e) {
    this.swipeInitialX = e.touches[0].clientX;
  }

  onTouchMove(e) {
    this.swipeFinalX = e.touches[0].clientX;
  }

  onTouchEnd() {
    if (this.swipeFinalX < this.swipeInitialX && !this.state.showMobileActions) {
      this.setState({ showMobileActions: true });
    }
    if (this.swipeFinalX > this.swipeInitialX && this.state.showMobileActions) {
      this.setState({ showMobileActions: false });
    }
  }

  onEditByAreaClick() {
    if (!this.props.missingBudgetDays && !this.props.missingApprovedBudget
      && !this.props.isWaiting) {
      this.props.showProjectAreasModal(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    const userEditedDaysLeft = this.state.daysLeft;
    const outerEditedDaysLeft = this.props.days_left;
    const daysLeftChangedFromTheOutside = outerEditedDaysLeft !== prevProps.days_left;

    if (daysLeftChangedFromTheOutside && (userEditedDaysLeft !== outerEditedDaysLeft)) {
      this.setState({ daysLeft: outerEditedDaysLeft });
    }
  }

  onBlur() {
    if (this.daysAreChanged()) {
      let daysLeft = parseFloat(this.state.daysLeft);
      daysLeft = Number.isNaN(daysLeft) ? null : daysLeft;
      const data = {
        date: moment(this.props.date).format('YYYY-MM-DD'),
        days_left: daysLeft,
        project: this.props.project.id,
      };
      this.props.save(data);
    }
  }

  onRiskIndicatorClick() {
    if (this.canEditRisk()) {
      this.props.showRiskSelect(this.riskMenuAnchor, {
        project: this.props.project,
        days_left: this.props.days_left,
        date: this.props.date,
        risk: this.props.risk,
      });
    }
  }

  onChangeDaysLeft(name, days) {
    if (this.state.daysLeft !== days) {
      this.setState({ daysLeft: (days === '' || Number.isNaN(days)) ? null : days });
    }
  }

  getFeedback() {
    if (this.props.isWaiting) {
      return 'Saving';
    }
    if (this.hasUnsavedChanges()) {
      return 'Unsaved changes';
    }
    return null;
  }

  onChangeProgress(name, progress) {
    const progressParsed = progress;
    let daysLeft = null;
    if (progress !== null) {
      const budgetDays = this.props.budget.days;
      daysLeft = budgetDays - ((progressParsed / 100) * budgetDays);
    }
    this.onChangeDaysLeft('days-left', daysLeft);
  }

  daysAreChanged() {
    const currentDays = parseFloat(this.state.daysLeft);
    const prevDays = parseFloat(this.props.days_left);
    // days_left set to 0 due to area compilation
    const resetByAreasCompilation = this.compiledByArea() && currentDays === null;

    if (Number.isNaN(currentDays) && Number.isNaN(prevDays)) {
      return false;
    }
    // If days_left reset by area compilation, we cannot call this an "unsaved changes" because the
    // reset has been automatically made by backend
    return !resetByAreasCompilation && (prevDays !== currentDays);
  }

  hasUnsavedChanges() {
    return this.daysAreChanged();
  }

  /**
   * Show days and progress for the projects with an approved budget.
   * When there's no budget approved the project status cannot be edited and a warning will be shown
   */
  getDaysAndProgress() {
    if (this.props.missingApprovedBudget) {
      return (
        <TableCell
          data-testid="project-status-cannot-edit-message"
          colSpan={3}
          key="no-budget"
          className="project-status__column--no-budget project-status__column-body">
          Missing
          {' '}
          <a href={`#pipeline/budget/${this.props.project.id}`}>budget</a>
        </TableCell>
      );
    }
    if (this.props.missingBudgetDays) {
      const budgetLink = `#pipeline/budget/${this.props.project.id}`;
      return (
        <TableCell
          data-testid="project-status-cannot-edit-message"
          colSpan={3}
          key="no-budget"
          className="project-status__column--no-budget project-status__column-body">
          Zero days
          {' '}
          <a href={budgetLink}>budget</a>
        </TableCell>
      );
    }
    return []
      .concat(
        <TableCell
          data-testid="project-days-left"
          key="days-left"
          className="project-status__column--days-left project-status__column-body">
          {this.getDaysLeft()}
        </TableCell>,
      )
      .concat(
        <TableCell
          data-testid="project-progress"
          key="progress"
          className="project-status__column--progress project-status__column-body">
          {this.getProgress()}
        </TableCell>,
      )
      .concat(this.getLastProgressCell());
  }

  /**
   * Column showing the last available progress.
   * Only visible on desktop.
   * @returns {JSX.Element}
   */
  getLastProgressCell() {
    let style = 'project-status__column--last-progress project-status__column-body hidden-mobile';

    if (this.hasNegativeProgress()) {
      style += ' project-status__feedback--warning';
    }

    return (
      <TableCell
        data-testid="project-last-days-left"
        key="last-progress"
        className={style}>
        {this.getFormattedLastProgress()}
      </TableCell>
    );
  }

  getRiskStyle() {
    return {
      backgroundColor: this.props.risk.color,
    };
  }

  canEditRisk() {
    return this.props.canEditStatus && (this.props.days_left !== null || this.compiledByArea())
      && !this.props.isWaiting;
  }

  canShowRisk() {
    return (this.props.days_left !== null || this.compiledByArea())
      && !this.props.missingBudgetDays && !this.props.missingApprovedBudget;
  }

  getRiskClassName() {
    let name = 'project-status__column-body project-status__column--risk';
    if (this.canShowRisk()) {
      name += ' project-status__column--risk-visible';
    }
    return name;
  }

  static getUnescapedValue(value) {
    return _.unescape(value);
  }

  getEditByAreaLabel() {
    if (this.compiledByArea()) {
      return (
        <span className="project-status__edit-area-label">Edited by area</span>
      );
    }
    return null;
  }

  getRisk() {
    return (
      <TableCell key="risk"
        className={this.getRiskClassName()}>
        {this.getEditByAreaLabel()}
        <span className="project-status-risk__wrapper">
          <span
            className="project-status-risk__indicator"
            style={this.getRiskStyle()}
            onClick={this.onRiskIndicatorClick.bind(this)}
            ref={(el) => this.riskMenuAnchor = el} />
        </span>
      </TableCell>
    );
  }

  getEditByAreaButtonStyle() {
    let style = 'wethod-button project-status__edit-area-button';
    if (this.props.isWaiting) {
      style += ' disabled';
    }
    return style;
  }

  handleSidebarOpen() {
    this.props.openSidebar(this.props.project.id);
  }

  getProgressValue() {
    const { daysLeft } = this.state;
    const budgetDays = this.props.budget.days;
    if (daysLeft === null || Number.isNaN(daysLeft) || budgetDays === 0) {
      return null;
    }
    return Math.round(((budgetDays - daysLeft) / budgetDays) * 1000) / 10;
  }

  getProgress() {
    const progress = this.getProgressValue();
    const budgetDays = this.props.budget.days;
    if (this.isTypeProgress() && this.props.canEditStatus) {
      return (
        <ProgressInput
          value={progress}
          feedback={this.getFeedback()}
          compiledByArea={this.compiledByArea()}
          lastWeekValue={this.getFormattedLastProgress()}
          negativeProgress={this.hasNegativeProgress()}
          budgetDays={budgetDays}
          onBlur={this.onBlur.bind(this)}
          onChange={this.onChangeProgress.bind(this)}
          disabled={this.props.isWaiting} />
      );
    }
    if (this.props.missingBudgetDaysWithPrevDaysLeft) {
      return (
        <div className="project-status__column--info-tooltip__centered">
          <InfoIconTooltip
            label="Data inconsistency: zero budget days. Delete all project statuses." />
        </div>
      );
    }
    let formattedProgress = '';
    if (progress !== null) {
      const progressAmount = formatNumber((Math.round(progress * 10) / 10), false, 1);
      formattedProgress = `${progressAmount} %`;
    }
    if (this.props.statusFrozen) {
      return <FrozenTooltip>{formattedProgress}</FrozenTooltip>;
    }
    return formattedProgress;
  }

  getEditByAreaButton() {
    if (this.props.userHasStatusEditPermissions() && this.props.canEditStatus) {
      if (this.compiledByArea()) { // Add dot indicator
        return (
          <button type="button"
            className={this.getEditByAreaButtonStyle()}
            onClick={this.onEditByAreaClick.bind(this)}>
            <span className="project-status__edit-area-indicator" />
            Edit by area
          </button>
        );
      }
      return (
        <button type="button"
          className={this.getEditByAreaButtonStyle()}
          onClick={this.onEditByAreaClick.bind(this)}>
          Edit by area
        </button>
      );
    }
    return null;
  }

  getActions() {
    return (
      <TableCell key="edit-area"
        className="project-status__column--actions">
        {this.getEditByAreaButton()}
        <OpenSidebarButton onClick={this.handleSidebarOpen.bind(this)} />
      </TableCell>
    );
  }

  getMonthProduction() {
    const budgetDays = this.props.budget.days;
    if (budgetDays) {
      const plan = formatByStep(this.props.month_production_plan, 1);
      const weekProgress = this.state.daysLeft !== null
        ? ((this.props.last_known_days_left_for_month - this.state.daysLeft) / budgetDays) * 100
        : 0;
      const progress = formatByStep(this.props.month_progress + weekProgress, 1);

      return (
        <span className="project-status__month-production-content">
          <span
            className="project-status-month-production__operator project-status-month-production__ operator--actual">
            {progress}%
          </span>
          <span className="project-status-month-production__separator">
            {' '}/{' '}
          </span>
          <span className="project-status-month-production__operator">
            {plan}%
          </span>
        </span>
      );
    }
    return '-';
  }

  getCells() {
    const pm = `${this.props.pm.name} ${this.props.pm.surname}`;
    return [
      <TableCell key="color"
        className="project-status__column--color project-status__column-body">
        <div
          className="timesheet-weekly__color-bar"
          style={{ backgroundColor: this.props.job_order_category.color }} />
      </TableCell>,
      <TableCell key="project"
        className="project-status__column--project project-status__column-body">
        <div className="project-status__column--project-info project-status__column-body">
          <span>{Project.getUnescapedValue(this.props.project.name)}</span>
          <span>{Project.getUnescapedValue(this.props.client.name)}</span>
          <span>{Project.getUnescapedValue(this.props.project.job_order)}</span>
        </div>
      </TableCell>,
      <TableCell
        key="pm"
        className="project-status__column--pm hidden-mobile project-status__column-body">
        {formatName(pm)}
      </TableCell>,
      <TableCell
        data-testid="project-month-production"
        key="month-production"
        className="project-status__column--month-production hidden-mobile project-status__column-body">
        {this.getMonthProduction()}
      </TableCell>,
    ]
      .concat(this.getDaysAndProgress())
      .concat(this.getRisk())
      .concat(this.getActions());
  }

  getClassName() {
    let name = 'project-status__row';
    if (this.state.showMobileActions) {
      name += ' project-status__row--show-actions';
    }
    return name;
  }

  getDaysLeft() {
    if (this.isTypeDays() && this.props.canEditStatus) {
      return (
        <DaysLeftInput
          value={this.state.daysLeft}
          compiledByArea={this.compiledByArea()}
          feedback={this.getFeedback()}
          lastWeekValue={this.getFormattedLastProgress()}
          negativeProgress={this.hasNegativeProgress()}
          onBlur={this.onBlur.bind(this)}
          onChange={this.onChangeDaysLeft.bind(this)}
          disabled={this.props.isWaiting} />
      );
    }
    let formattedAmount = '';
    if (this.state.daysLeft !== null) {
      formattedAmount = formatNumber(this.state.daysLeft, false, 2);
    }
    if (this.props.statusFrozen) {
      return <FrozenTooltip><span />{formattedAmount}</FrozenTooltip>;
    }
    return null;
  }

  /**
   * Return the last available progress, formatted as percentage
   * @returns {string}
   */
  getLastProgressValue() {
    const daysLeft = this.props.prev_days_left;
    const budgetDays = this.props.budget.days;
    if (daysLeft === null || budgetDays === 0) {
      return '-';
    }
    const progress = ((budgetDays - daysLeft) / budgetDays) * 100;
    return `${formatNumber(progress, false, 1)} %`;
  }

  /**
   * Return last available progress, formatted as days left
   * @returns {string}
   */
  getLastDaysLeft() {
    const value = this.props.prev_days_left;
    if (value != null) {
      const formattedValue = formatNumber(value, false, 2);
      return this.isTypeProgress() ? `${formattedValue} %` : formattedValue;
    }
    return '-';
  }

  /**
   * Return last available progress, formatted
   * @returns {string}
   */
  getFormattedLastProgress() {
    if (this.isTypeProgress()) {
      return this.getLastProgressValue();
    }
    return this.getLastDaysLeft();
  }

  /**
   * Return true if this project status has been compiled by area.
   * @returns {boolean}
   */
  compiledByArea() {
    return this.props.areas.filter((area) => area.days_left !== null).length > 0;
  }

  /**
   * Check if the project has a negative progress with respect to previous project status
   * (This week days left is greater than last known days left)
   * @returns {boolean}
   */
  hasNegativeProgress() {
    const currentDays = parseFloat(this.state.daysLeft);
    const prevDays = this.props.prev_days_left;

    if (Number.isNaN(currentDays) || prevDays == null) {
      return false;
    }

    return currentDays > prevDays;
  }

  isTypeDays() {
    return this.props.project.project_status_mode === 'days-left';
  }

  isTypeProgress() {
    return this.props.project.project_status_mode === 'progress';
  }

  // TODO: project info now contains html entities so we need unescape; remove when not necessary
  render() {
    return (
      <TableRow
        className={this.getClassName()}
        onTouchStart={this.onTouchStart.bind(this)}
        onTouchMove={this.onTouchMove.bind(this)}
        onTouchEnd={this.onTouchEnd.bind(this)}>
        {this.getCells()}
      </TableRow>
    );
  }
};
