/* eslint-disable react/sort-comp */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const Menu = require('@common/Menu/Menu.react');
const TextMenu = require('./TextMenu/TextMenu.react');
const { isValidURL } = require('../../../../../../../../../services/ValidationService');
const { formatURL } = require('../../../../../../../../../services/FormatService');

module.exports = class TextItemCellView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  openMenu() {
    this.setState({ showMenu: true });
  }

  getSlidingClassName() {
    let style = 'project-canvas-spreadsheet__cell-button-content project-canvas-spreadsheet__cell-button-content--sliding';
    if (this.props.value) style += ' project-canvas-spreadsheet__cell-button-content--sliding-enabled';
    return style;
  }

  getButton() {
    if (isValidURL(this.props.value)) {
      const formattedURL = formatURL(this.props.value);
      return (
        <div className="project-canvas-spreadsheet__cell-button-content--delete">
          <a className="wethod-icon-button" href={formattedURL} target="_blank" rel="noreferrer">
            <div className="wethod-icon wethod-icon-medium-program wethod-icon-medium-program--white" />
          </a>
        </div>
      );
    }
    return (
      <div className="project-canvas-spreadsheet__cell-button-content--delete">
        <button type="button" className="wethod-icon-button" onClick={this.openMenu.bind(this)}>
          <div className="wethod-icon wethod-icon-medium-expand wethod-icon-medium-expand--white" />
        </button>
      </div>
    );
  }

  render() {
    return (
      <TableCell className="project-canvas-spreadsheet__cell project-canvas-spreadsheet__cell-button">
        <div
          className={this.getSlidingClassName()}
          ref={(text) => { this.text = text; }}>
          <div
            className="project-canvas-spreadsheet__cell-button-content--text"
            style={{ backgroundColor: 'white' }}>
            {this.props.value}
          </div>
          {this.getButton()}
        </div>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.text}>
          <TextMenu value={this.props.value} canEdit={false} />
        </Menu>
      </TableCell>
    );
  }
};
