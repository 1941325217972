const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const ExportButton = require('@common/ExportButton/ExportButton.react');
const { getPermission } = require('../services/authManager');

module.exports = class Actions extends React.Component {
  handleClick() {
    if (!this.props.isSaving && !this.props.isLoading) {
      this.props.addTravel();
    }
  }

  onExportClick(e) {
    e.preventDefault();
    this.props.showExportModal();
  }

  getAddButtonClassName() {
    let style = 'travel__add-button wethod-button';
    if (this.props.isSaving || this.props.isLoading) {
      style += ' disabled';
    }
    return style;
  }

  getAddButton() {
    if (getPermission('edit', Wethod.userInfo.get('employee_id'))) {
      return (
        <button type="button"
          className={this.getAddButtonClassName()}
          onClick={this.handleClick.bind(this)}>
          New request
        </button>
      );
    }
    return null;
  }

  render() {
    return (
      <div className="travel__actions-wrapper">
        <SectionHeader current_section="Travels"
          preview_anchor_id="travel"
          helper_url="finance/index/#travels"
          big />
        <div className="travel__actions wethod-section-actions">
          <ExportButton onClick={this.onExportClick.bind(this)} />
          {this.getAddButton()}
        </div>
      </div>
    );
  }
};
