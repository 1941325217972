/* eslint-disable react/sort-comp,consistent-return,react/jsx-no-bind */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const Cell = require('./SummaryCell/SummaryCell.react');

module.exports = class ItemSummary extends React.Component {
  getValues(attributeId) {
    return this.props.items.map((item) => item.attributes
      .filter((attribute) => attribute.attribute === attributeId)[0]);
  }

  getAttributesCells() {
    return this.props.attributes.map((attribute) => (
      <Cell
        key={attribute.id}
        attribute={attribute}
        values={this.getValues(attribute.id)}
        changeSummary={this.props.changeSummary}
        canEdit={this.props.canEdit} />
    ));
  }

  getCells() {
    let cells = [<TableCell
      key="name"
      className="project-canvas-spreadsheet__cell--name project-canvas-spreadsheet__cell--summary project-canvas-spreadsheet__cell--summary-name" />]
      .concat(this.getAttributesCells());
    if (this.props.canEdit) {
      cells = cells.concat(<TableCell
        className="project-canvas-spreadsheet__cell--actions project-canvas-spreadsheet__cell--summary project-canvas-spreadsheet__cell--summary-actions"
        key="actions" />);
    }
    return cells;
  }

  render() {
    return (
      <TableRow className="project-canvas-spreadsheet__row project-canvas-spreadsheet__row-summary">
        {this.getCells()}
      </TableRow>
    );
  }
};
