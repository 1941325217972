/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign */

const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const AllocationItem = require('./AllocationItem.react');
const PersonDetails = require('./Person.react');
const Date = require('./Date.react');
const AllocationList = require('./AllocationList.react');
const canPerform = require('../../../services/canPerform');

const PlanDetails = ({
  onCancelClick, data, projectsInfo, selectProject, zoom, changeZoom,
}) => {
  const { person } = data;
  const { from } = data;
  const { to } = data;

  const onItemClick = (projectId) => {
    selectProject(projectId);
    onCancelClick();
  };

  const projects = data.projects.map((project) => {
    const info = projectsInfo[project.id];
    project = { ...project, ...info };
    // We combine project id and allocation status in order to distinguish pending and approved allocation
    const key = `${project.id}${project.is_pending ? '_pending' : ''}`;

    return (
      <AllocationItem key={key}
        onClick={onItemClick}
        project={project}
        canUserEdit={canPerform('modify', project)} />
    );
  });

  const getFooter = () => {
    let label = '';
    let nextZoom = null;
    if (zoom === 'day') {
      label = 'Switch to hourly view';
      nextZoom = 'hour';
    }
    if (zoom === 'week') {
      label = 'Switch to daily view';
      nextZoom = 'day';
    }

    const onClick = () => {
      changeZoom(nextZoom);
      onCancelClick();
    };

    if (nextZoom) {
      return (
        <div className="planning-people__allocation-details-footer" onClick={onClick}>
          <div className="planning-people__allocation-details-footer-label">{label}</div>
          <div
            className="planning-people__allocation-details-footer-icon wethod-icon-arrow-right wethod-icon-arrow-right--white" />
        </div>
      );
    }
    return null;
  };

  const title = zoom === 'week' ? 'Weekly allocation' : 'Daily allocation';

  const className = `project-canvas-alert planning-people-details planning-people-details--${zoom}`;

  return (
    <Modal title={title} onCancelClick={onCancelClick} className={className}>
      <div className="planning-people__allocation-details">
        <PersonDetails person={person} />
        <Date zoom={zoom} from={from} to={to} />
        <AllocationList>
          {projects}
        </AllocationList>
      </div>
      <div className="profile-contact-info__actions">
        {getFooter()}
      </div>
    </Modal>
  );
};

module.exports = PlanDetails;
