const React = require('react');
const PropTypes = require('prop-types');
const TooltipMessage = require('./TooltipMessage.react');

require('./style.scss');

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTooltip: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseEnterTooltipMessage = this.handleMouseEnterTooltipMessage.bind(this);
    this.handleMouseLeaveTooltipMessage = this.handleMouseLeaveTooltipMessage.bind(this);
    this.setRef = this.setRef.bind(this);
    this.timerId = null;
  }

  handleMouseEnter() {
    this.setState({ showTooltip: true });

    if (this.props.hoverable) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
    }
  }

  handleMouseLeave() {
    if (this.props.hoverable) {
      this.timerId = setTimeout(() => {
        this.setState({ showTooltip: false });
      }, 500);
    } else {
      this.setState({ showTooltip: false });
    }
  }

  handleMouseEnterTooltipMessage() {
    if (this.props.hoverable) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.setState({ showTooltip: true });
    }
  }

  handleMouseLeaveTooltipMessage() {
    if (this.props.hoverable) {
      this.setState({ showTooltip: false });
    }
  }

  setRef(el) {
    this.childrenContainerEl = el;
  }

  getClassName() {
    return `wethod-tooltip ${this.props.className}`;
  }

  getClassNameMessage() {
    return [
      'wethod-tooltip__message',
      this.props.hoverable ? 'wethod-tooltip__message--hoverable' : '',
      this.props.hoverable && this.state.showTooltip ? 'wethod-tooltip__message--hoverable-visible' : '',
      `${this.props.className}-message`,
    ].join(' ').trim();
  }

  render() {
    return (
      <div className={this.getClassName()}
        data-testid="tooltip"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}>
        <TooltipMessage show={this.state.showTooltip}
          anchorEl={this.childrenContainerEl}
          className={this.getClassNameMessage()}
          onMouseEnter={this.handleMouseEnterTooltipMessage}
          onMouseLeave={this.handleMouseLeaveTooltipMessage}>
          {this.props.label}
        </TooltipMessage>
        <div ref={this.setRef} className={`${this.props.wrapperClassName} wethod-tooltip__component-wrapper`}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Tooltip.defaultProps = {
  hoverable: false,
  className: '',
  wrapperClassName: '',
};

Tooltip.propTypes = {
  /**
   * Determines if the tooltip can be hovered with mouse
   */
  hoverable: PropTypes.bool,
  /**
   * Label shown as tooltip content
   * @see Tooltip
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /**
   * The class to be added to the component style.
   */
  className: PropTypes.string,
  /**
   * The class to be added to the wrapper style.
   */
  wrapperClassName: PropTypes.string,
  /**
   * Tooltip anchor
   */
  children: PropTypes.node.isRequired,
};

module.exports = Tooltip;
