const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const authManager = require('../../services/authManager');

const DeleteOrderModal = ({ onCancelClick, data, isSaving, deleteOrder }) => {
  const canDeleteOrder = () => authManager.getPermission('delete', data.order);

  function handleDelete() {
    if (!isSaving && canDeleteOrder()) {
      deleteOrder(data.order);
    }
  }

  const getSaveStyle = () => {
    let style = 'orders__confirm-button';
    if (isSaving || !canDeleteOrder()) style += ' orders__confirm-button--disabled';
    return style;
  };

  const getBody = () => (
    <div className="orders__delete-order-modal__body">
      <p>
        You are about to delete this order.
      </p>
      <p>
        Are you sure you want to continue?
      </p>
    </div>
  );

  const getActions = () => (
    <div className="orders__delete-order-modal__actions">
      <button type="button" onClick={onCancelClick} className="orders__cancel-button">Not yet</button>
      <button type="button" onClick={handleDelete} className={getSaveStyle()}>Yes, delete</button>
    </div>
  );

  const getFeedback = () => {
    if (isSaving) return <div className="profile-contact-info__feedback">Saving...</div>;
    return null;
  };

  return (
    <Modal title="Delete order" onCancelClick={onCancelClick} className="orders__delete-order-modal">
      {getBody()}
      {getActions()}
      {getFeedback()}
    </Modal>
  );
};

module.exports = DeleteOrderModal;
