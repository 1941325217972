/* eslint-disable react/no-did-update-set-state */
const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/Menu/ListMenu/MenuItem.react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');

module.exports = class AreaSelect extends React.Component {
  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  static found(key, value) {
    if (value) {
      return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    }
    return false;
  }

  static getInputValue(area) {
    return _.unescape(area.name);
  }

  constructor(props) {
    super(props);

    this.state = {
      filteredAreas: this.props.areas,
    };
  }

  componentDidMount() {
    if (this.props.projectId && (!this.props.areas || this.props.areas.length === 0)) {
      this.props.getAreas(this.props.projectId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.projectId !== prevProps.projectId
      || this.props.areas.length !== prevProps.areas.length) {
      this.setState({
        filteredAreas: this.props.areas,
      });
    }
  }

  handleFilter(name, key) {
    this.setState({
      filteredAreas: this.getFilteredAreas(key),
    });
  }

  handleChange(e, item) {
    const {
      name,
    } = e.target;
    let area = null;

    if (item) {
      area = {
        id: item.value,
        name: item.name,
      };
    }

    this.props.onChange(name, area);
  }

  getFilteredAreas(keyword) {
    return this.props.areas.filter((area) => AreaSelect.found(keyword, area.name));
  }

  getSelectedArea() {
    return (this.props.value && this.props.value.id) ? this.props.value : null;
  }

  getOptions() {
    return this.state.filteredAreas.map((area) => {
      const name = _.unescape(area.name);

      return (
        <SelectItem key={area.id}
          value={area.id}
          name={name}>
          {name}
        </SelectItem>
      );
    });
  }

  render() {
    return (
      <Autocomplete readOnly={this.props.readOnly}
        onChange={this.handleChange.bind(this)}
        value={this.getSelectedArea()}
        getInputValue={AreaSelect.getInputValue}
        name={this.props.name}
        label="Budget area"
        id="sidebar-budget-area"
        onFilter={this.handleFilter.bind(this)}
        loading={this.props.isLoading}>
        {this.getOptions()}
      </Autocomplete>
    );
  }
};
