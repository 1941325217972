const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('./ConsumptionReportModalContent.react');
const RequestManager = require('../../../../../containers/RequestManager');

const ConsumptionReportModal = ({ closeModal, projectReportData }) => (
  <Modal title="Consumption vs Status" onClose={closeModal}>
    <RequestManager>
      <ModalContent data={projectReportData} />
    </RequestManager>
  </Modal>
);

module.exports = ConsumptionReportModal;
