const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const DaySelect = require('@common/inputs/DayPicker/BasicDayPicker/BasicDayPicker.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');

const DateCell = ({
  readOnly, value, onChange, onFocus, error, name, label, style, id, metadataService,
}) => (
  <TableCell className="pipeline-item__due-date" style={style}>
    <InputMetadataManager metadataService={metadataService}
      name={name}
      canEdit={!readOnly}>
      <DaySelect
        resettable
        label={label}
        onFocus={onFocus}
        onChange={onChange}
        value={value}
        id={id}
        errorText={error} />
    </InputMetadataManager>
  </TableCell>
);

module.exports = DateCell;
