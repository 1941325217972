const React = require('react');
const _ = require('underscore');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Loader = require('@common/Loader/Loader.react');
const Actions = require('./ProjectInfoActions.react');
const LevelsAvailability = require('./LevelsAvailability.react');
const LevelsAvailabilityPricelistSelection = require('./LevelsAvailabilityPricelistSelection.react');

module.exports = class ProjectInfo extends React.Component {
  static getUnescapedValue(value) {
    return _.unescape(value);
  }

  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  getProject() {
    return (
      <div className="planning-people__project-info">
        <div className="planning-people__project-info-details">
          <div className="planning-people__project-info-details--left">
            <span className="planning-people__project-info-name">{ProjectInfo.getUnescapedValue(this.props.project.name)}</span>
            <span
              className="planning-people__project-info-client">
              {' '} - {ProjectInfo.getUnescapedValue(this.props.project.client.name)}
            </span>
            {' '}
            <span
              className="planning-people__project-info-pm">
              {`${ProjectInfo.getUnescapedValue(this.props.project.pm.name)} ${ProjectInfo.getUnescapedValue(this.props.project.pm.surname)}`}
            </span>
            <span
              className="planning-people__project-info-jo">
              {`${this.props.project.job_order}`}
            </span>
          </div>
          <Actions showMenu={this.showMenu.bind(this)}
            closeMenu={this.closeMenu.bind(this)}
            isMenuOpen={this.state.showMenu}
            closeInfo={this.props.close}
            project={this.props.project} />
        </div>
        <ShowIf condition={this.shouldShowLevelsAvailability()}>
          <div style={{
            display: 'flex', alignItems: 'center',
          }}>
            <ShowIf condition={this.showPricelistsSelection()}>
              <LevelsAvailabilityPricelistSelection project={this.props.project} />
            </ShowIf>
            <ShowIf condition={this.showLevelsAvailability()}>
              <LevelsAvailability project={this.props.project} />
            </ShowIf>
          </div>
        </ShowIf>
      </div>
    );
  }

  shouldShowLevelsAvailability() {
    const hoursType = this.props.project.type.hours_type;

    return hoursType !== 'leave' && hoursType !== 'vacation';
  }

  showPricelistsSelection() {
    return (
      this.props.planningSettings.availableHoursGrouping === 'price_lists'
    );
  }

  showLevelsAvailability() {
    return (
      this.props.planningSettings.availableHoursGrouping === 'user_levels'
      || !!this.props.selectedPricelist
    );
  }

  showMenu() {
    if (!this.state.showMenu) {
      this.setState({ showMenu: true });
    }
  }

  closeMenu() {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div className="planning-people__project-info">
          <div className="planning-people__loader-container">
            <Loader />
          </div>
        </div>
      );
    }
    if (this.props.project) {
      return this.getProject();
    }
    return null;
  }
};
