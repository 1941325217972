/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,no-bitwise,react/no-did-update-set-state,no-unused-expressions,no-use-before-define,react/jsx-no-bind,jsx-a11y/mouse-events-have-key-events,react/no-access-state-in-setstate,consistent-return,default-case,no-param-reassign,prefer-destructuring */

const React = require('react');
const T = require('@common/Typography/Typography.react');
const TaskLevel = require('./ConversionTaskLevel.react');
const Formatters = require('../../../services/Formatter');
const Column = require('./Column.react');

module.exports = class ConversionAreas extends React.Component {
  compareLevelsByOrder(levelA, levelB) {
    if (levelA.order < levelB.order) return -1;
    if (levelA.order > levelB.order) return 1;
    return 0;
  }

  getUnescapedContent(content) {
    return _.unescape(content);
  }

  getNonZeroAmount(amount) {
    if ((amount !== '0')) return amount;
  }

  handleTaskChange(level, idTask, idArea) {
    this.props.onChange(level, idTask, idArea);
  }

  getAreaLevels(levelsTotal) {
    return levelsTotal.map((level) => (
      <div key={level.id} className="budget-table-header__total-level budget-table-header__total-level--no-shrink">
        {this.getNonZeroAmount(Formatters.getFormattedDaysValue(level.total))}
      </div>
    ));
  }

  getTaskLevels(task, area) {
    if (task.job_titles.length > 0) {
      return (
        <span className="planning-conversion-modal__feedback">
          It's not possible to convert days for this task because it uses job titles.
        </span>
      );
    }
    return task.price_list_levels.sort(this.compareLevelsByOrder).map((level) => (
      <div className="budget-table-header__total-level" key={level.id}>
        <TaskLevel
          {...level}
          idTask={task.id}
          idArea={area.id}
          readOnly={!area.enabled || this.isDisabled(area)}
          onChange={this.handleTaskChange.bind(this)} />
      </div>
    ));
  }

  getTasks(area, areaTotal) {
    return area.tasks.map((task) => {
      let taskTotal = areaTotal.tasks.filter((taskTotal) => task.id === taskTotal.id);
      if (taskTotal) taskTotal = taskTotal[0].total;
      return (
        <div className="budget-task" key={task.id}>
          <div className="budget-task__name">
            <div>
              {this.getUnescapedContent(task.name)}
              <div className="budget-task__name--complete">{this.getUnescapedContent(task.name)}</div>
            </div>
          </div>
          <div className="budget-task__levels">
            {this.getTaskLevels(task, area)}
          </div>
          <div className="budget-task__days">
            {this.getNonZeroAmount(Formatters.getFormattedDaysValue(taskTotal))}
          </div>
        </div>
      );
    });
  }

  getLeftTasks(area) {
    return area.tasks.map((task) => (
      <div className="budget-task" key={task.id}>
        <div className="budget-task__name budget-task__name--left-column">
          <div>
            {this.getUnescapedContent(task.name)}
            <div className="budget-task__name--complete">{this.getUnescapedContent(task.name)}</div>
          </div>
        </div>
      </div>
    ));
  }

  getCenterTasks(area) {
    return area.tasks.map((task) => (
      <div className="budget-task budget-task--center-column" key={task.id}>
        <div className="budget-task__levels-new">
          {this.getTaskLevels(task, area)}
        </div>
      </div>
    ));
  }

  getRightTasks(area, areaTotal) {
    return area.tasks.map((task) => {
      let taskTotal = areaTotal.tasks.filter((taskTotal) => task.id === taskTotal.id);
      if (taskTotal) taskTotal = taskTotal[0].total;
      return (
        <div className="budget-task" key={task.id}>
          <div className="budget-task__days-new">
            {this.getNonZeroAmount(Formatters.getFormattedDaysValue(taskTotal))}
          </div>
        </div>
      );
    });
  }

  getAreas() {
    if (this.props.areas) {
      return this.props.areas.map((area) => {
        let areaTotal = this.props.totals.filter((areaTotals) => area.id === areaTotals.id);
        if (areaTotal) areaTotal = areaTotal[0];
        const levelsTotal = areaTotal.price_list_levels;
        return (
          <div className="budget-area__container" key={area.id}>
            <div className="budget-area">
              <div className="budget-area__info">
                <div className="budget-area__name">{this.getUnescapedContent(area.name)}</div>
                <div className="budget-area__levels">{this.getAreaLevels(levelsTotal)}</div>
                <div className="budget-area__days">
                  {this.getNonZeroAmount(Formatters.getFormattedDaysValue(areaTotal.total))}
                </div>
              </div>
              <div className="budget-area__tasks">
                <div className="container">
                  {this.getTasks(area, areaTotal)}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  /**
   * Return true if given area is disabled.
   * @param area
   * @return {boolean}
   */
  isDisabled(area) {
    return this.props.focusedPricelistId && area.price_list.id !== this.props.focusedPricelistId;
  }

  /**
   * Return given area className.
   *
   * @param area
   * @return {string}
   */
  getAreaInfoClassName(area) {
    let className = 'budget-area__info';
    if (!area.enabled || this.isDisabled(area)) {
      className += ' budget-area__info--disabled';
    }
    return className;
  }

  /**
   * Return given area tasks className.
   *
   * @param area
   * @return {string}
   */
  getAreaTasksClassName(area) {
    let className = 'budget-area__tasks';
    if (!area.enabled) {
      className += ' budget-area__tasks--disabled';
    }
    return className;
  }

  getPricelistName(area) {
    if (area.price_list) {
      return (
        <div className="budget-area__pricelist">
          <T size={T.SIZES.PX11} weight={T.WEIGHTS.NORMAL}>
            Pricelist: {area.price_list.name}
          </T>
        </div>
      );
    }
    return null;
  }

  getLeftAreas() {
    if (this.props.areas) {
      return this.props.areas.map((area) => (
        <div className="budget-area__container" key={area.id}>
          <div className="budget-area">
            <div className={this.getAreaInfoClassName(area)}>
              <div className="budget-area__name budget-area__name--left-column">{this.getUnescapedContent(area.name)}</div>
              {this.getPricelistName(area)}
            </div>
            <div className={this.getAreaTasksClassName(area)}>
              <div className="container">
                {this.getLeftTasks(area)}
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  getCenterAreas() {
    if (this.props.areas) {
      return this.props.areas.map((area) => {
        let areaTotal = this.props.totals.filter((areaTotals) => area.id === areaTotals.id);
        if (areaTotal) areaTotal = areaTotal[0];
        const levelsTotal = areaTotal.price_list_levels;
        return (
          <div className={`budget-area__container ${this.props.showShadows ? 'budget-area__container--fit-content' : ''}`} key={area.id}>
            <div className="budget-area">
              <div className={`${this.getAreaInfoClassName(area)} budget-area__info--center-column`}>
                <div className="budget-area__levels-new">{this.getAreaLevels(levelsTotal)}</div>
              </div>
              <div className={this.getAreaTasksClassName(area)}>
                <div className="container">
                  {this.getCenterTasks(area)}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  getRightAreas() {
    if (this.props.areas) {
      return this.props.areas.map((area) => {
        let areaTotal = this.props.totals.filter((areaTotals) => area.id === areaTotals.id);
        if (areaTotal) areaTotal = areaTotal[0];
        return (
          <div className="budget-area__container" key={area.id}>
            <div className="budget-area">
              <div className={this.getAreaInfoClassName(area)}>
                <div className="budget-area__days-new">
                  {this.getNonZeroAmount(Formatters.getFormattedDaysValue(areaTotal.total))}
                </div>
              </div>
              <div className={this.getAreaTasksClassName(area)}>
                <div className="container">
                  {this.getRightTasks(area, areaTotal)}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }
  }

  render() {
    return (
      <div className="planning-conversion-modal__areas planning-conversion-modal__areas--grid">
        <Column showShadows={this.props.showShadows} column="left">
          {this.getLeftAreas()}
        </Column>
        <Column column="center" className="budget-area--horizontal-scroll" myRef={this.props.setAreasReference} onScroll={this.props.onScroll}>
          {this.getCenterAreas()}
        </Column>
        <Column showShadows={this.props.showShadows} column="right">
          {this.getRightAreas()}
        </Column>
      </div>
    );
  }
};
