const React = require('react');
const _ = require('underscore');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const Button = require('@common/Button/RoundedButton.react');
const IconButton = require('@common/Button/IconButton.react');
const TextField = require('@common/inputs/TextField/BasicTextField/BasicTextField.react');
const NumericField = require('@common/inputs/NumericField/NumericField.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const DurationInput = require('@common/inputs/DurationInput/BasicDurationInput/BasicDurationInput.react');
const FeatureService = require('../../../../../../../services/FeatureService');

module.exports = class CreationRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.item,
    };

    this.handleItemChange = this.handleItemChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleHourChange = this.handleHourChange.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleItemChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  handleNameChange(e) {
    const { name } = e.target;
    const { value } = e.target;

    this.handleItemChange(name, value);
  }

  handleHourChange(e) {
    const { name } = e.target;
    const { value } = e.target;

    if (Wethod.featureService.isEnabled(FeatureService.FTE)) {
      if (!this.props.hasErrors(name)) {
        this.handleItemChange(name, Number(value));
      }
    } else {
      this.handleItemChange(name, Number(value));
    }
  }

  onSave() {
    this.props.weekDays.forEach((day) => {
      if (!this.state[day]) {
        this.setState({
          [day]: 0,
        });
      }
    });
    this.props.onSave(this.state);
  }

  getHours() {
    if (Wethod.featureService.isEnabled(FeatureService.FTE)) {
      return this.props.weekDays.map((day) => {
        const name = `${day}_minutes`;
        return (
          <InputValidator key={day} updateErrors={this.props.updateErrors} constraints={['required', 'maxDuration:480']}>
            <DurationInput
              unit={DurationInput.UNIT_MINUTES}
              className="company-settings-work-hour-capacity__table-cell-weekday company-settings-work-hour-capacity__table-cell-weekday--numeric-input"
              name={name}
              value={this.state[name]}
              errorText={this.props.getErrorMessage(name)}
              onBlur={this.handleHourChange} />
          </InputValidator>
        );
      });
    }
    return this.props.weekDays.map((day) => (
      <NumericField
        key={day}
        className="company-settings-work-hour-capacity__table-cell-weekday company-settings-work-hour-capacity__table-cell-weekday--numeric-input"
        name={day}
        placeholder="0"
        value={this.state[day]}
        positiveOnly
        onBlur={this.handleHourChange} />
    ));
  }

  /**
   * Check whether the save button should be disabled.
   * All the fields must be filled in, and the work hour capacity must not be saving.
   * @returns {boolean|*}
   */
  isSaveDisabled() {
    return !this.state.name || this.isSaving();
  }

  isSaving() {
    return this.props.waitingForSave.some((key) => key === `save-work-hour-capacity-${this.props.item.id}`);
  }

  render() {
    return (
      <TableRow feedback={this.isSaving() ? 'Saving' : ''}
        className="company-settings-work-hour-capacity__table-row company-settings--with-transparent-input">
        <TableCell className="company-settings-work-hour-capacity__table-cell-name">
          <TextField name="name"
            label="Name"
            placeholder="Name"
            value={_.unescape(this.state.name)}
            onBlur={this.handleNameChange} />
        </TableCell>
        <TableCell className="company-settings-work-hour-capacity__table-cell-weekday">
          <div className="company-settings-work-hour-capacity__table-cell-weekdays">
            {this.getHours()}
          </div>
        </TableCell>
        <TableCell className="company-settings-work-hour-capacity__table-cell-action">
          <Button onClick={this.onSave}
            disabled={this.isSaveDisabled()}
            className="company-settings__button company-settings__button--blue company-settings-margin-right-l">
            Save
          </Button>
          <IconButton icon="delete" color="blue" size="medium" onClick={this.props.onDelete} className="company-settings__icon-button" />
        </TableCell>
      </TableRow>
    );
  }
};
