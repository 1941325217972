const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Button = require('@common/Button/RoundedButton.react');

/**
 * Shows confirmation of the import and reloads data of the time off table
 * @type {ImportSuccessModal}
 */
module.exports = class ImportSuccessModal extends React.Component {
  /**
   * Load time off table
   */
  componentDidMount() {
    const offset = 0;
    this.props.reloadData(offset, this.props.limit, this.props.keyword, this.props.year);
  }

  render() {
    return (
      <Modal title="Import" onCancelClick={this.props.onClose} className="time-off__modal">
        <p>
          Data import was <b>successfully completed</b>!
        </p>
        <div className="wethod-modal2__actions">
          <Button onClick={this.props.onClose}>Ok</Button>
        </div>
      </Modal>
    );
  }
};
