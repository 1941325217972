/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-param-reassign */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const NumericInput = require('@common/NumericInput.react');

module.exports = class NumericItemCellEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focusOnTitle: false };
  }

  onBlur(value) {
    this.setState({ focusOnTitle: false });
    value = value === '' ? null : parseFloat(value);
    if (value !== this.props.value) {
      this.props.editValue(this.props.item, this.props.attribute, value);
    }
  }

  handleKeyUp(e) {
    if (e.keyCode === 13) {
      this.input.blur();
    }
  }

  getClassName() {
    let name = 'project-canvas-spreadsheet__cell';
    if (this.state.focusOnTitle) {
      name += ' project-canvas-spreadsheet__cell--focus';
    }
    return name;
  }

  onFocus() {
    this.setState({ focusOnTitle: true });
  }

  render() {
    return (
      <TableCell className={this.getClassName()}>
        <NumericInput
          value={this.props.value}
          ref={(input) => { this.input = input; }}
          formatOptions={{ numeralDecimalScale: 2, stripLeadingZeroes: false }}
          onKeyUp={this.handleKeyUp.bind(this)}
          onBlur={this.onBlur.bind(this)}
          onFocus={this.onFocus.bind(this)} />
      </TableCell>
    );
  }
};
