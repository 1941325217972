/* eslint-disable no-underscore-dangle */
const React = require('react');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const FormMetadataManager = require('@common/FormMetadataManager/FormMetadataManager.react');
const PermissionManager = require('../containers/PermissionManager');
const ItemManager = require('../containers/ItemManager');
const SaveManager = require('../containers/SaveManager');
const Item = require('../containers/Item/Item');

const ItemWrapper = ({ item, showSidebar, visibleColumns, nameOverrides }) => (
  <FormValidator>
    <PermissionManager>
      <SaveManager item={item}>
        <ItemManager item={item}>
          <FormMetadataManager metadata={item._fields} nameOverrides={nameOverrides}>
            <Item item={item} handleOpenSidebar={showSidebar} visibleColumns={visibleColumns} />
          </FormMetadataManager>
        </ItemManager>
      </SaveManager>
    </PermissionManager>
  </FormValidator>
);

module.exports = ItemWrapper;
