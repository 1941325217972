/* eslint-disable react/no-did-update-set-state,react/sort-comp,class-methods-use-this,
 consistent-return */
const React = require('react');
const $ = require('jquery');
const TableSearch = require('@common/Table2/TableSearch.react');
const Loader = require('@common/Loader/Loader.react');
const Modal = require('../containers/Modal');
const Actions = require('../containers/Actions');
const WeeklyTimesheet = require('./WeeklyTimesheet.react');
const DailyTimesheet = require('./DailyTimesheet.react');
const FeatureService = require('../../services/FeatureService');
const CapacityManager = require('../containers/CapacityManager');

module.exports = class Timesheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = { tableHeight: null, page: 0, size: 0 };
  }

  componentDidMount() {
    this.updateTableHeight();
    if (!this.props.periodicity) this.props.getPeriodicity(this.props.userId);
    dispatcher.trigger('tour:start', this.props.idTour);
  }

  getTableMaxHeight() {
    return window.innerHeight - (Wethod.getHeaderHeight() + $('.wethod-section-actions').outerHeight(true) + 24);
  }

  updateTableHeight() {
    this.setState({ tableHeight: this.getTableMaxHeight() });
  }

  updatePeriodicity(periodicity, userId) {
    this.setState({ page: 0, size: 0 });
    this.props.updatePeriodicity(periodicity, userId);
  }

  isWaiting() {
    return this.props.waitingFor.length > 0;
  }

  isWaitingForPeriodicity() {
    return this.props.waitingFor.filter((key) => key === 'periodicity').length > 0;
  }

  isWaitingForProjects() {
    return this.props.waitingFor.filter((key) => key === 'get-projects').length > 0;
  }

  isWaitingProject(id) {
    return this.props.waitingFor.filter((key) => key === `save-project-hours-${id}`).length > 0;
  }

  getLoadedProjects() {
    return this.props.projects.slice(0, this.state.page * this.state.size);
  }

  hasMore() {
    const loaded = this.state.page * this.state.size;
    return this.props.projects.length > loaded;
  }

  loadMore(size, page) {
    this.setState({ page, size });
  }

  filter(keyword) {
    this.setState({ page: 1 });
    this.props.search(keyword);
  }

  getSearch() {
    return (
      <TableSearch
        placeholder={this.props.searchPlaceholder}
        keyword={this.props.searchKeyword}
        filter={this.filter.bind(this)} />
    );
  }

  getBody() {
    if (this.isWaitingForPeriodicity()) {
      return (
        <div className="timesheet-weekly__loader">
          <Loader />
        </div>
      );
    }

    if (this.props.periodicity === 'daily') {
      if (FeatureService.isEmployeeCapacityEnabled()) {
        return (
          <CapacityManager>
            <DailyTimesheet
              tableHeight={this.state.tableHeight}
              date={this.props.date}
              projects={this.getLoadedProjects()}
              allProjects={this.props.allProjects}
              search={this.getSearch()}
              hasMore={this.hasMore()}
              loadMore={this.loadMore.bind(this)}
              isWaiting={this.isWaiting()}
              isWaitingForProjects={this.isWaitingForProjects()}
              isWaitingProject={this.isWaitingProject.bind(this)}
              internationalCalendar={this.props.internationalCalendar}
              updateHours={this.props.updateHours}
              deleteHours={this.props.deleteHours} />
          </CapacityManager>
        );
      }
      return (
        <DailyTimesheet
          tableHeight={this.state.tableHeight}
          date={this.props.date}
          projects={this.getLoadedProjects()}
          allProjects={this.props.allProjects}
          search={this.getSearch()}
          hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          isWaiting={this.isWaiting()}
          isWaitingForProjects={this.isWaitingForProjects()}
          isWaitingProject={this.isWaitingProject.bind(this)}
          internationalCalendar={this.props.internationalCalendar}
          updateHours={this.props.updateHours}
          deleteHours={this.props.deleteHours} />
      );
    }
    if (this.props.periodicity === 'weekly') {
      if (FeatureService.isEmployeeCapacityEnabled()) {
        return (
          <CapacityManager>
            <WeeklyTimesheet
              tableHeight={this.state.tableHeight}
              isWaiting={this.isWaiting()}
              isWaitingForProjects={this.isWaitingForProjects()}
              isWaitingProject={this.isWaitingProject.bind(this)}
              projects={this.getLoadedProjects()}
              allProjects={this.props.allProjects}
              search={this.getSearch()}
              hasMore={this.hasMore()}
              loadMore={this.loadMore.bind(this)}
              updateHours={this.props.updateHours}
              deleteHours={this.props.deleteHours} />
          </CapacityManager>
        );
      }
      return (
        <WeeklyTimesheet
          tableHeight={this.state.tableHeight}
          isWaiting={this.isWaiting()}
          isWaitingForProjects={this.isWaitingForProjects()}
          isWaitingProject={this.isWaitingProject.bind(this)}
          projects={this.getLoadedProjects()}
          allProjects={this.props.allProjects}
          search={this.getSearch()}
          hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          updateHours={this.props.updateHours}
          deleteHours={this.props.deleteHours} />
      );
    }
  }

  render() {
    return (
      <div className="timesheet timesheet-weekly wethod-section-body">
        <Actions
          date={this.props.date}
          changeDate={this.props.changeDate}
          internationalCalendar={this.props.internationalCalendar}
          isWaiting={this.isWaiting()}
          periodicity={this.props.periodicity}
          updatePeriodicity={this.updatePeriodicity.bind(this)} />
        {this.getBody()}
        <Modal />
      </div>
    );
  }
};
