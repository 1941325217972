const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Input = require('@common/NumericInput.react');

module.exports = class HeadcountTargetModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: this.props.data.target,
    };
  }

  handleSave() {
    if (this.props.data.canEditSettings) this.props.saveTarget(this.state.target);
  }

  handleTargetChange(name, value) {
    const target = value ? parseInt(value) : null;
    if (!Number.isNaN(target)) this.setState({ target });
  }

  getActions() {
    return (
      <div className="timesheet-weekly__modal-actions">
        <button type="button" onClick={this.props.onCancelClick} className="wethod-button">Cancel</button>
        <button type="button" onClick={this.handleSave.bind(this)} className="wethod-button">Set target</button>
      </div>
    );
  }

  getBody() {
    return (
      <div>
        <p>
          Set a headcount target.
        </p>
        <p>
          Headcount target:
          <Input
            name="target"
            placeholder="0"
            value={this.state.target}
            onChange={this.handleTargetChange.bind(this)}
            constraints="integer" /> units
        </p>
      </div>
    );
  }

  getFeedback() {
    if (this.props.isWaiting) return <span className="wethod-modal2__feedback">Saving...</span>;
    return '';
  }

  render() {
    return (
      <Modal title="Headcount target" className="demographics__modal" onCancelClick={this.props.onCancelClick}>
        {this.getBody()}
        {this.getActions()}
        {this.getFeedback()}
      </Modal>
    );
  }
};
