/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Infotip = require('@common/Infotip/Infotip.react');

module.exports = class TimesheetAutomaticTag extends React.Component {
  render() {
    return (
      <TableCell className="timesheet-weekly__cell-automatic">
        <ShowIf condition={this.props.automatic}>
          <Infotip label="auto" />
        </ShowIf>
      </TableCell>
    );
  }
};
