const React = require('react');
const { connect } = require('react-redux');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const sidebarService = require('@common/sidebar/SidebarManager/services/SidebarService');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const DropdownMenu = require('@common/Dropdown/DropdownMenu.react');
const actions = require('../actions');
const AddUser = require('./sidebar/AddUser.react');
const ImportMetadataModal = require('./modals/ImportMetadataModal.react');

const NewUserAction = () => {
  function addUser() {
    sidebarService.openSidebar(<AddUser />);
  }

  function importMetadata() {
    modalService.openModal(<ImportMetadataModal />);
  }

  return (
    <DropdownMenu label="New User">
      <MenuItem onClick={addUser}>Add manually</MenuItem>
      <MenuItem onClick={importMetadata}>Import metadata</MenuItem>
    </DropdownMenu>
  );
};

module.exports = connect(null, {
  showSidebar: actions.showSidebar,
})(NewUserAction);
