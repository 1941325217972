/* eslint-disable consistent-return */
const React = require('react');
const TextInput = require('@common/material-input/SidebarTextInput.react');
const NumericInput = require('@common/material-input/SidebarNumericInput.react');
const NoteInput = require('@common/material-input/SidebarTextArea.react');
const DateInput = require('@common/material-input/SidebarDateSelection.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const ClientSearchSelect = require('../containers/ClientSearchSelect');
const AccountSearchSelect = require('../containers/AccountSearchSelect');

module.exports = class BasketEditableDetails extends React.Component {
  handleValueChange(name, value) {
    this.props.onChange(name, this.props.formatValueChanges(value));
  }

  getDate() {
    return this.props.opportunity.due_date
      ? moment(this.props.opportunity.due_date).format('DD/MM/YYYY')
      : '';
  }

  getAccount() {
    if (this.props.opportunity && this.props.opportunity.account_id) {
      const completeName = (`${this.props.opportunity.account.name} ${this.props.opportunity.account.surname}`).trim();
      return {
        id: this.props.opportunity.account_id,
        name: completeName,
      };
    }
    return null;
  }

  getClient() {
    if (this.props.opportunity && this.props.opportunity.client_id) {
      return this.props.opportunity.client;
    }
    return null;
  }

  render() {
    return (
      <div>
        <div className="basket__sidebar--spacer" />
        <TextInput name="name"
          placeholder="Opportunity"
          value={this.props.opportunity.name}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span />
        </TextInput>
        <ClientSearchSelect name="client_name"
          placeholder="Client"
          value={this.getClient()}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-building wethod-icon-building--black" />
        </ClientSearchSelect>
        <div className="basket__sidebar--multiple-input-row">
          <div className="basket__sidebar--target-editable basket__sidebar--multiple-input-element">
            <NumericInput name="value"
              placeholder="Target"
              integer
              value={this.props.getValue()}
              onChange={this.handleValueChange.bind(this)}
              onValidate={this.props.onValidate}>
              <span className="wethod-icon wethod-icon-budget wethod-icon-budget--black" />
            </NumericInput>
            <ShowIf condition={!this.props.isValueAsUnit}>
              <div className="basket__sidebar--target-measure">K</div>
            </ShowIf>
          </div>
        </div>
        <DateInput name="due_date"
          placeholder="Follow-up"
          value={this.props.opportunity.due_date}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}>
          <span className="wethod-icon wethod-icon-event wethod-icon-event--black" />
        </DateInput>
        <AccountSearchSelect name="account"
          placeholder="In Charge"
          value={this.getAccount()}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span className="wethod-icon wethod-icon-pm" />
        </AccountSearchSelect>
        <NoteInput name="note"
          placeholder="Notes"
          value={this.props.opportunity.note}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          rows={4}>
          <span className="wethod-icon wethod-icon-notes wethod-icon-notes--black" />
        </NoteInput>
      </div>
    );
  }
};
