const React = require('react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const NumericField = require('@common/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const TextArea = require('@common/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const TextField = require('@common/inputs/TextField/OutlinedTextField/OutlinedTextField.react');
const DateInput = require('@common/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
// const ProductRow = require('../Product.react');

const ProductBody = ({
  readOnly,
  item,
  levels,
  isNew,
  errors,
  updateErrors,
  onChange,
}) => {
  function getLevelDays(levelId) {
    const level = item.product_levels.find((l) => l.level.id === levelId);
    return level ? level.days : 0;
  }

  function getDaysTotal() {
    return item.product_levels.reduce((total, level) => total + level.days, 0);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (onChange) {
      onChange(name, value);
    }
  }

  function handleValueChange(e) {
    const { name, value } = e.target;
    let parsedValue = parseFloat(value);
    parsedValue = !Number.isNaN(parsedValue) ? parsedValue : null;

    onChange(name, parsedValue);
  }

  function handleLevelChange(e) {
    const { name, value } = e.target;
    let parsedValue = parseFloat(value);
    parsedValue = !Number.isNaN(parsedValue) ? parsedValue : 0;
    const levelId = parseInt(name.split('-')[1], 10);
    const updatedLevelDays = item.product_levels.map((level) => {
      if (level.level.id === levelId) {
        return {
          ...level,
          days: parsedValue,
        };
      }
      return level;
    });

    onChange('product_levels', updatedLevelDays);
  }

  // Most fields are only editable when the item is new
  const isReadOnly = readOnly || !isNew;

  // The date fields are always editable when the sidebar is not in read-only mode
  const isDateReadOnly = readOnly;

  function getLevelsList() {
    return levels?.map((level) => (
      <Row className="products__sidebar-levels-row" key={level.id}>
        <InputWrapper>
          <TextField readOnly
            name={`level-label-${level.id}`}
            value={level.short_text} />
        </InputWrapper>
        <div className="products__sidebar-levels-input">
          <InputWrapper>
            <InputValidator updateErrors={updateErrors} constraints={[]}>
              <NumericField
                readOnly={isReadOnly}
                name={`level-${level.id}`}
                id={`sidebar-level-${level.id}`}
                label="Days"
                precision={1}
                value={getLevelDays(level.id)}
                onChange={handleLevelChange} />
            </InputValidator>
          </InputWrapper>
        </div>
      </Row>
    ));
  }

  function getLevelsTotal() {
    return (
      <Row className="products__sidebar-levels-row products__sidebar-levels-row-total">
        <InputWrapper>
          <TextField readOnly
            name="level-total"
            value="TOTAL" />
        </InputWrapper>
        <div className="products__sidebar-levels-input">
          <InputWrapper>
            <NumericField
              readOnly
              name="daysTotal"
              id="sidebar-days-total"
              label="Days"
              value={getDaysTotal()}
              precision={0} />
          </InputWrapper>
        </div>

      </Row>
    );
  }

  return (
    <div className="products__sidebar-body">
      <Row>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['required']}>
            <TextField readOnly={isReadOnly}
              name="name"
              id="sidebar-name"
              label="Name"
              value={item.name}
              onChange={handleChange}
              errorText={errors.name} />
          </InputValidator>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['maxLength:50']}>
            <TextArea readOnly={isReadOnly}
              name="description"
              id="sidebar-description"
              label="Description"
              value={item.description}
              rows={3}
              onChange={handleChange}
              errorText={errors.description} />
          </InputValidator>
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <DateInput readOnly={isDateReadOnly}
            resettable
            name="available_from"
            id="sidebar-from"
            label="From"
            value={item.available_from}
            onChange={handleChange}
            errorText={errors.available_from} />
        </InputWrapper>
        <InputWrapper>
          <DateInput readOnly={isDateReadOnly}
            resettable
            name="available_to"
            id="sidebar-to"
            label="To"
            value={item.available_to}
            onChange={handleChange}
            errorText={errors.available_to} />
        </InputWrapper>
      </Row>
      <div className="products__sidebar-levels">
        {getLevelsList()}
        {getLevelsTotal()}
      </div>
      <Row>
        <InputWrapper>
          <NumericField readOnly={isReadOnly}
            name="external_cost"
            id="sidebar-external-costs"
            label="Ext. Costs"
            value={item.external_cost}
            precision={2}
            onChange={handleValueChange} />
        </InputWrapper>
        <InputWrapper>
          <NumericField readOnly={isReadOnly}
            name="markup"
            id="sidebar-markup"
            label="Markup %"
            value={item.markup}
            onChange={handleValueChange} />
        </InputWrapper>
      </Row>
      <Row>
        <InputWrapper>
          <NumericField readOnly={isReadOnly}
            name="price"
            id="sidebar-price"
            label="Price"
            value={item.price}
            precision={2}
            onChange={handleValueChange} />
        </InputWrapper>
      </Row>
    </div>
  );
};

module.exports = ProductBody;
