const React = require('react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const CellDelta = require('../../../../containers/Calculator/CellDelta');

/**
 * PriceListDelta
 * @param value current price list
 * @param prevValue previous price list
 * @returns {JSX.Element}
 * @constructor
 */
const PriceListDelta = ({ value, prevValue }) => {
  const hasChanged = prevValue && value.id !== prevValue.id;

  return (
    <ShowIf condition={hasChanged}>
      <CellDelta className="wethod-budget-area__pricelist__delta">
        <span>{prevValue?.name}</span>
        <span>&#8594;</span>
        <span>{value.name}</span>
      </CellDelta>
    </ShowIf>
  );
};
module.exports = PriceListDelta;
