/* eslint-disable react/sort-comp,no-shadow */
const React = require('react');
const UploadButton = require('@common/widgets/Files/components/UploadButton.react');
const Loading = require('@common/widgets/Files/components/SmallLoader.react');
const File = require('./File.react');
const FileModel = require('../../../models/FileModel');

module.exports = class FileSectionEditable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: null,
    };
  }

  isWaiting(fileId) {
    return this.state.status === `deleting-${fileId}`;
  }

  getFileList() {
    if (!this.props.files || !this.props.files.length) {
      return (
        <div className="travel-files__empty">
          Travel bookings and reservations<br />
          will appear here
        </div>
      );
    }
    return this.props.files.map((file) => (
      <File key={file.id}
        file={file}
        onDelete={this.delete.bind(this)}
        onDownload={this.props.onDownload}
        downloadable={this.props.canDownload}
        isWaiting={this.isWaiting(file.id)} />
    ));
  }

  upload(file) {
    if (this.props.carrier && this.props.carrier.id) {
      this.setState({ status: 'uploading' });
      const model = new FileModel();

      $.when(model.upload(file, this.props.carrier.id)).done((file) => {
        this.setState({ status: null });
        this.props.onUploadSuccess(this.props.travel, this.props.carrier, file);
      }).fail(() => {
        this.setState({ status: null });
        this.props.showCannotUploadFileModal(['network']);
      });
    }
  }

  delete(file) {
    if (this.props.carrier) {
      this.setState({ status: `deleting-${file.id}` });
      const model = new FileModel({ id: file.id });
      $.when(model.deleteModel(file.id)).done(() => {
        this.setState({
          status: null,
        });
        this.props.onDeleteSuccess(this.props.travel, this.props.carrier, file);
      });
    }
  }

  onUploadError(errors) {
    this.setState({ status: null });
    this.props.showCannotUploadFileModal(errors);
  }

  getUploadButton() {
    if (this.state.status === 'uploading') {
      return <Loading />;
    }
    return (
      <UploadButton onUpload={this.upload.bind(this)}
        onUploadError={this.onUploadError.bind(this)}
        accept={this.props.accept}
        maxSize={128000000} />
    );
  }

  render() {
    const disabled = !this.props.carrier;
    let style = 'travel__carrier--body-end travel-office--highlight';
    if (disabled) {
      style += ' travel__carrier--disabled';
    }

    return (
      <div className={style}>
        <h3 className="travel__sidebar-subtitle">
          Booking and reservations
          {this.getUploadButton()}
        </h3>
        {this.getFileList()}
      </div>
    );
  }
};
