/* eslint-disable react/sort-comp */
const React = require('react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const TextInput = require('@common/TextInput.react');
const Loader = require('@common/Loader/Loader.react');
const TypeSwitcher = require('./TypeSwitcher.react');
const KanbanSettings = require('../Kanban/Settings.react');

module.exports = class BoardHeaderEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      focusOnTitle: false,
    };
  }

  /**
   * Returns true if boards of the given type can be shown.
   * @param type
   * @return {boolean}
   */
  canShow(type) {
    return this.props.availableBoardTypes.indexOf(type) !== -1;
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  getMoreButton() {
    if (this.props.isDeletingBoard) {
      return <Loader />;
    }
    return (
      <div className="project-canvas-board__action">
        <button
          type="button"
          ref={(el) => this.moreButtonEl = el}
          onClick={this.onMenuButtonClick.bind(this)}
          className="wethod-icon-button project-canvas-spreadsheet__row-more-button">
          <div className="wethod-icon-medium-more wethod-icon-medium-more--black" />
        </button>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          <MenuItem onClick={this.props.onDeleteClick}>Delete</MenuItem>
          <MenuItem onClick={this.onCopyClick.bind(this)}>Copy link to this Board</MenuItem>
        </Menu>
      </div>
    );
  }

  onNameBlur(value) {
    this.setState({ focusOnTitle: false });
    if (value.trim() !== this.props.name) {
      this.props.editName(value);
    }
  }

  getTitleClassName() {
    let name = 'project-canvas-board__title';
    if (this.state.focusOnTitle) {
      name += ' project-canvas-board__title--focus';
    }
    return name;
  }

  onTitleFocus() {
    this.setState({ focusOnTitle: true });
  }

  onCopyClick() {
    this.textAreaRef.select();
    document.execCommand('copy');
  }

  changeBoardType(type) {
    if (this.canShow(type)) {
      this.props.changeBoardType(type);
    } else if (type === 'kanban') {
      this.props.showMissingStatusModal();
    }
  }

  getClassName() {
    let name = 'project-canvas-board__header-container';
    if (this.props.waitingForOptions) {
      name += ' project-canvas-board__header-container--disabled';
    }
    return name;
  }

  getSettings() {
    if (this.props.waitingForOptions) {
      return null;
    }
    switch (this.props.type) {
      case 'kanban':
        return (
          <KanbanSettings
            structure={this.props.structure}
            options={this.props.options.kanban}
            updateOptions={this.props.updateKanbanOptions} />
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="project-canvas-board__header">
          <div className="project-canvas-board-drag-handle__container">
            <span className="project-canvas-board-drag-handle wethod-icon-draggable wethod-icon-draggable--black" />
          </div>
          <div className={this.getTitleClassName()}>
            <TextInput
              value={this.props.name}
              onBlur={this.onNameBlur.bind(this)}
              onFocus={this.onTitleFocus.bind(this)} />
          </div>
          <div className="project-canvas-board__actions">
            <div className="project-canvas-board__actions-type-switcher">
              <TypeSwitcher current={this.props.boardType}
                handleClick={this.changeBoardType.bind(this)} />
            </div>
            {this.getMoreButton()}
          </div>
        </div>
        {this.getSettings()}
        <textarea
          className="wethod-table__search-copyable-area"
          readOnly
          ref={(ref) => this.textAreaRef = ref}
          value={this.props.boardLink} />
      </div>
    );
  }
};
