const React = require('react');
const Modal = require('@common/modals/ErrorModal.react');

const MissingEmployees = ({ employees }) => {
  const employeesList = employees.map((employee) => <li key={employee}>{employee}</li>);

  return (
    <div>
      The following emails you uploaded did <b>not match our records</b>.
      <br />
      Please, double check and try again.
      <ul className="time-off__modal-error-list">
        {employeesList}
      </ul>
      <br />
    </div>
  );
};

const WrongEmployees = ({ employees }) => {
  const employeesList = employees.map((employee) => <li key={employee}>{employee}</li>);

  return (
    <div>
      Emails from <b>archived or hidden employees are not accepted</b>.
      <br />
      Please, remove the following contacts and upload the file again.
      <ul className="time-off__modal-error-list">
        {employeesList}
      </ul>
      <br />
    </div>
  );
};

const WrongNumbers = ({ rows }) => {
  const employeesList = rows.map((employee) => <li key={employee.email}>{employee.email}</li>);

  return (
    <div>
      <b>Negative values for vacations, leaves and targets are not accepted</b>.
      <br />
      Please, double check the following employees and try again.
      <ul className="time-off__modal-error-list">
        {employeesList}
      </ul>
    </div>
  );
};

const WrongImportDataModal = ({ data, onClose }) => {
  function getNumbersError() {
    const numbersError = data.wrong_numbers && data.wrong_numbers.length
      ? data.wrong_numbers : null;

    if (numbersError) {
      return <WrongNumbers rows={numbersError} />;
    }
    return null;
  }

  function getEmployeesError() {
    const employeesError = data.wrong_employee && data.wrong_employee.length
      ? data.wrong_employee : null;

    if (employeesError) {
      return <WrongEmployees employees={employeesError} />;
    }

    return null;
  }

  function getMissingEmployees() {
    const missingEmployees = data.missing_employee && data.missing_employee.length
      ? data.missing_employee : null;

    if (missingEmployees) {
      return <MissingEmployees employees={missingEmployees} />;
    }

    return null;
  }

  function getBody() {
    return (
      <div className="time-off__modal-error-body">
        Some data in the file you uploaded are not quite right.
        <br /><br />
        {getMissingEmployees()}
        {getEmployeesError()}
        {getNumbersError()}
      </div>
    );
  }

  return (
    <Modal onCancelClick={onClose} data={getBody()} className="time-off__modal-error" />
  );
};

module.exports = WrongImportDataModal;
