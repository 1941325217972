/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const Select = require('@common/Select/Select.react');

module.exports = class Settings extends React.Component {
  getAttributesOfType(type) {
    return this.props.structure.filter((attribute) => attribute.type === type)
      .map((attribute) => ({ id: attribute.id, name: attribute.name }));
  }

  getAttribute(id) {
    return this.props.structure.filter((attribute) => attribute.id === id)
      .map((attribute) => ({ id: attribute.id, name: attribute.name }))[0];
  }

  getDateSelect() {
    const dateOption = this.props.options.date;
    if (dateOption) {
      return (
        <div className="project-canvas-board__settings-item-input">
          <Select current={this.getAttribute(dateOption)}
            items={this.getAttributesOfType('date')}
            onItemClick={this.onItemClick.bind(this)} />
        </div>
      );
    }
    return null;
  }

  getMemberSelect() {
    const memberOption = this.props.options.member;
    if (memberOption) {
      return (
        <div className="project-canvas-board__settings-item-input">
          <Select current={this.getAttribute(memberOption)}
            items={this.getAttributesOfType('member')}
            onItemClick={this.onItemClick.bind(this)} />
        </div>
      );
    }
    return null;
  }

  getRightSettings() {
    const memberSelect = this.getMemberSelect();
    const dateSelect = this.getDateSelect();

    if (!memberSelect && !dateSelect) {
      return null;
    }
    return (
      <div className="project-canvas-board__settings-item">
        <div className="project-canvas-board__settings-item-label">Show data from</div>
        {memberSelect}
        {dateSelect}
      </div>
    );
  }

  onItemClick(item) {
    const { type } = this.props.structure.filter((attribute) => attribute.id === item.id)[0];
    this.props.updateOptions({ ...this.props.options, [type]: item.id });
  }

  render() {
    return (
      <div className="project-canvas-board__settings">
        <div className="project-canvas-board__settings-left">
          <div className="project-canvas-board__settings-item">
            <div className="project-canvas-board__settings-item-label">View by</div>
            <div className="project-canvas-board__settings-item-input">
              <Select current={this.getAttribute(this.props.options.status)}
                items={this.getAttributesOfType('status')}
                onItemClick={this.onItemClick.bind(this)} />
            </div>
          </div>
        </div>
        <div className="project-canvas-board__settings-right">
          {this.getRightSettings()}
        </div>
      </div>
    );
  }
};
