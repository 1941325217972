const React = require('react');
const ModalBody = require('@common/Modal/ModalBody.react');
const TextArea = require('@common/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');

module.exports = class ApprovalStatusButtonModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { message: '' };

    this.onChange = this.onChange.bind(this);
    this.onApproveClick = this.onApproveClick.bind(this);
    this.onRejectClick = this.onRejectClick.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onApproveClick() {
    this.props.approveBudget(this.state.message);
  }

  onRejectClick() {
    this.props.rejectBudget(this.state.message);
  }

  getFeedback() {
    return this.isSaving() ? 'Saving...' : '';
  }

  isSaving() {
    return this.props.isPending('change-status');
  }

  render() {
    return (
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            Feel free to leave a message to the PM:
          </ModalBodyBlock>
          <ModalBodyBlock>
            <TextArea name="message"
              disabled={this.isSaving()}
              value={this.state.message}
              onChange={this.onChange} />
          </ModalBodyBlock>
        </ModalBody>
        <ModalFooter feedback={this.getFeedback()}>
          <ModalFooterAction onClick={this.onRejectClick} disabled={this.isSaving()}>
            No way
          </ModalFooterAction>
          <ModalFooterAction onClick={this.onApproveClick}
            disabled={this.isSaving()}>
            Yes
          </ModalFooterAction>
        </ModalFooter>
      </ModalContent>
    );
  }
};
