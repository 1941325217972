const React = require('react');
const _ = require('underscore');
const SelectItem = require('@common/inputs/Select/SelectItem.react');
const SelectItemDisabled = require('@common/inputs/Select/SelectItemDisabled.react');
const AutocompleteModel = require('../../../../../../../../models/Autocomplete');
const AutocompleteFilter = require('./CustomizableOptionsAutocompleteFilter.react');

module.exports = class JobOrderFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      availableItems: [],
      isLoading: false,
    };

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter(name, keyword) {
    this.setState({
      isLoading: true,
    });

    AutocompleteModel.getProjectsByNameOrJobOrderAutocomplete(keyword)
      .done((list) => this.setState({
        availableItems: list,
        isLoading: false,
      }));
  }

  getOptions() {
    return this.state.availableItems
      .map((project) => {
        if (project.jobOrder == null) {
          return (
            <SelectItemDisabled key={project.id}
              value={project.id}>
              <span>{_.unescape(project.name)}</span><br />
              <span className="timesheets__secondary_info">MISSING JOB ORDER</span>
            </SelectItemDisabled>
          );
        }
        return (
          <SelectItem key={project.id}
            value={project.jobOrder}>
            <span>{_.unescape(project.name)}</span><br />
            <span className="timesheets__secondary_info">{project.jobOrder}</span>
          </SelectItem>
        );
      });
  }

  render() {
    return (
      <AutocompleteFilter name={this.props.name}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.props.onChange}
        expanded={this.props.expanded}
        isLoading={this.state.isLoading}
        onFilter={this.onFilter}
        getOptions={this.getOptions.bind(this)} />
    );
  }
};
