const TableColumnList = require('@common/Table2/models/TableColumnList');
const TableColumn = require('@common/Table2/models/TableColumn');

class MobileColumnList extends TableColumnList {
  constructor() {
    const columns = [
      new TableColumn('joc-badge', '', true, false, '4px'),
      new TableColumn('name', 'Name', true, false, '34%'),
      new TableColumn('estimate', 'Value', true, false),
      new TableColumn('probability', 'Prob.', true, false, '74px'),
      new TableColumn('actions', '', true, false, '54px'),
    ];

    super(columns);
  }
}

module.exports = MobileColumnList;
