/* eslint-disable class-methods-use-this,react/sort-comp,no-case-declarations,react/jsx-no-bind */
const React = require('react');
const Loading = require('@common/widgets/Loading.react');
const Projects = require('./FavouriteProjects.react');
const Clients = require('./FavouriteClients.react');
const Searches = require('./FavouriteSearches.react');

module.exports = class Pinboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 'projects',
    };
  }

  componentDidMount() {
    this.props.getFavourites();
  }

  sortByName(itemA, itemB) {
    const a = itemA[itemA.type].name.toLowerCase();
    const b = itemB[itemB.type].name.toLowerCase();
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }

  getBody() {
    if (this.props.isWaiting || !this.props.favourites) {
      return <Loading />;
    }
    switch (this.state.selectedTab) {
      case 'clients':
        const clients = this.props.favourites.clients
          .concat(this.props.favourites.customers).sort(this.sortByName);
        return (
          <Clients clients={clients}
            deleteFavourite={this.props.deleteResourceFavourites} />
        );
      case 'searches':
        return (
          <Searches
            searches={this.props.favourites.researches}
            updateLabel={this.props.updateSearchLabel}
            deleteFavourite={this.props.deleteSearch} />
        );
      case 'projects': // fallthrough
      default:
        const projects = this.props.favourites.projects.sort(this.sortByName);
        return (
          <Projects projects={projects}
            deleteFavourite={this.props.deleteResourceFavourites} />
        );
    }
  }

  getTotalByType(type) {
    if (this.props.favourites) return this.props.favourites[type].length;
    return '';
  }

  handleTabClick(type) {
    if (!this.props.isWaiting && type !== this.state.selectedTab) {
      this.setState({ selectedTab: type });
    }
  }

  getButtonStyle(type) {
    let style = 'wethod-button';
    if (type === this.state.selectedTab) style += ' wethod-button--selected';
    if (this.props.isWaiting) style += ' disabled';
    return style;
  }

  render() {
    return (
      <div className="wethod-widget">
        <div className="wethod-widget__header">
          <span className="wethod-widget__title">Pinboard</span>
          <div className="desk-widget-pinboard__tab">
            <button type="button"
              className={this.getButtonStyle('projects')}
              onClick={() => this.handleTabClick('projects')}>
              {`Projects ${this.getTotalByType('projects')}`}
            </button>
            <button type="button"
              className={this.getButtonStyle('clients')}
              onClick={() => this.handleTabClick('clients')}>
              {`Clients ${this.getTotalByType('clients') + this.getTotalByType('customers')}`}
            </button>
            <button type="button"
              className={this.getButtonStyle('searches')}
              onClick={() => this.handleTabClick('searches')}>
              {`Searches ${this.getTotalByType('researches')}`}
            </button>
          </div>
        </div>
        <div className="wethod-widget__body desk-widget-pinboard__body">
          {this.getBody()}
        </div>
      </div>
    );
  }
};
