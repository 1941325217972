const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const BudgetFooterButton = require('../BudgetFooterButton.react');

const ProjectStatusCorrectionAction = ({
  onClick, correctionToApply,
  userHasProjectStatusEditPermissions,
}) => (
  <BudgetFooterButton label="Project status correction" onClick={onClick} noBorder disabled={!correctionToApply || !userHasProjectStatusEditPermissions}>
    <Icon icon="ps-correction" />
  </BudgetFooterButton>
);

module.exports = ProjectStatusCorrectionAction;
