const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const BodyEdit = require('../../../containers/Goals/GoalModal/GoalModalBodyEdit');

module.exports = class GoalModal extends React.Component {
  getTitle() {
    return this.props.isNew ? 'Add goal' : 'Edit goal';
  }

  getBody() {
    return <BodyEdit onCancelClick={this.props.onCancelClick} />;
  }

  render() {
    return (
      <Modal title={this.getTitle()}
        onCancelClick={this.props.onCancelClick}>{this.getBody()}
      </Modal>
    );
  }
};
