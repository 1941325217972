/* eslint-disable react/sort-comp,class-methods-use-this,jsx-a11y/anchor-is-valid,no-shadow,react/no-array-index-key,camelcase */
const React = require('react');
const Avatar = require('@common/AvatarBox/Avatar.react');
const OkrStatus = require('./OkrStatus/OkrStatus.react');

const Person = ({
  id, pic, name, surname, level, tags, is_teammate, selectedProject, quarters, job_title,
}) => {
  const getImageClass = (className) => {
    const cssClass = `${className}--big`;
    return cssClass;
  };

  /**
   * Return a name formatted as "John Travolta".
   * Pass reverse = true to get something like "Travolta John"
   * @returns {string}
   */
  const getName = (reverse) => {
    const namef = _.unescape(name);
    const surnamef = _.unescape(surname);
    if (reverse) return `${surnamef} ${namef}`;
    return `${namef} ${surnamef}`;
  };

  /**
   * Dinamically calculates the if the user's info box must be disabled or not.
   * @returns {string}
   */
  const getInfoClass = () => {
    let cssClass = 'employee__info';
    if (selectedProject && !is_teammate) {
      cssClass += ' employee__info--not-teammate';
    }
    return cssClass;
  };

  // Get no tags, one ore two tag names
  let tagString = '';
  tagString += tags[0] ? tags[0].name : '';
  tagString += tags[1] ? `, ${tags[1].name}` : '';

  const getClass = () => {
    let name = 'planning-calendar__person planning-calendar__row';
    if (id === Wethod.userInfo.attributes.employee_id) {
      name += ' planning-calendar__person--current';
    }
    return name;
  };

  const getLevel = () => {
    const name = level.short_name;

    if (job_title) {
      return `${name}, ${job_title.name}`;
    }
    return `${name}`;
  };

  return (
    <div className={getClass()}>
      <a href={`#settings/profile/${id}`} className="employee__details">
        <div className={getImageClass('employee__details-mask')} />
        <div className={getImageClass('employee__image')}>
          <Avatar name={getName(true)} pic={pic} size="medium" />
        </div>
        <div className={getInfoClass()}>
          <div className="employee__name">{getName(true)}</div>
          <div className="employee__area">{tagString}</div>
          <div className="employee__level">{getLevel()}</div>
          <OkrStatus quarters={quarters} />
        </div>
      </a>
    </div>
  );
};

module.exports = Person;
