/* eslint-disable max-len */
const React = require('react');
const Mobile = require('@common/media-queries/Mobile.react');
const Section = require('../../../../common/components/Section.react');
const Title = require('../../../containers/sections/Time/Title');
const WeeklyChargeabilityChart = require('./WeeklyChargeabilityChart.react');
const Planned = require('../../../containers/sections/Time/Planned');
const HoursDistribution = require('../../../containers/sections/Time/HoursDistribution');
const Chargeability = require('../../../containers/sections/Time/Chargeability');
const WeeklyWorkedChart = require('./WeeklyWorkedChart.react');
const Goodbye = require('./Goodbye.react');
const Worked = require('../../../containers/sections/Time/Worked');
const Paragraph = require('../../../../common/components/SectionParagraph.react');
const ParagraphDivider = require('../../../../common/components/SectionParagraphDivider.react');
const MonthlyBriefing = require('../../../containers/sections/Time/MonthlyBriefing');

module.exports = class Time extends React.Component {
  getCharts() {
    const minChargeability = Math.min(this.props.chargeability_target_perc,
      this.props.chargeable_hours_two_weeks_ago_perc, this.props.chargeable_hours_prev_week_perc,
      this.props.chargeable_hours_perc, this.props.chargeable_hours_prev_year_two_weeks_before_perc,
      this.props.chargeable_hours_prev_year_prev_week_perc,
      this.props.chargeable_hours_prev_year_perc);
    const minChargeabilityChart = minChargeability * 0.8;

    return (
      <div className="briefing-aside-chart__wrapper">
        <WeeklyChargeabilityChart
          chargeability_target_perc={this.props.chargeability_target_perc}
          min={minChargeabilityChart}
          chargeable_hours_two_weeks_ago_perc={this.props.chargeable_hours_two_weeks_ago_perc}
          chargeable_hours_prev_week_perc={this.props.chargeable_hours_prev_week_perc}
          chargeable_hours_perc={this.props.chargeable_hours_perc}
          chargeable_hours_prev_year_two_weeks_before_perc={this.props.chargeable_hours_prev_year_two_weeks_before_perc}
          chargeable_hours_prev_year_prev_week_perc={this.props.chargeable_hours_prev_year_prev_week_perc}
          chargeable_hours_prev_year_perc={this.props.chargeable_hours_prev_year_perc} />
        <WeeklyWorkedChart groups={this.props.groups} />
      </div>
    );
  }

  render() {
    return (
      <Section left={this.getCharts()}>
        <Title />
        <Worked />
        <HoursDistribution />
        <Chargeability />
        <Paragraph>
          That's all we have to say about last week.
        </Paragraph>
        <Mobile>{this.getCharts()}</Mobile>
        <ParagraphDivider />
        <Planned />
        <Goodbye />
        <MonthlyBriefing />
        {this.props.actions}
      </Section>
    );
  }
};
