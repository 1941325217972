const React = require('react');
const Icon = require('@common/Icon/Icon.react');
const BudgetFooterButton = require('../BudgetFooterButton.react');

const SaveTemplateAction = ({ onClick }) => (
  <BudgetFooterButton label="Save as template" onClick={onClick}>
    <Icon icon="save" />
  </BudgetFooterButton>
);

module.exports = SaveTemplateAction;
