const React = require('react');
const ModalBody = require('@common/Modal/ModalBody.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const DraggableList = require('./TaskSortModalDraggableList.react');
const DraggableItem = require('./TaskSortModalDraggableItem.react');

module.exports = class TaskSortModalContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { orderedIds: this.props.tasks.map((task) => task.id) };

    this.save = this.save.bind(this);
    this.onOrderChanged = this.onOrderChanged.bind(this);
  }

  onOrderChanged(orderedIds) {
    this.setState({ orderedIds });
  }

  getFeedback() { return this.isSaving() ? 'Saving...' : ''; }

  getItems() {
    return this.props.tasks
      .map((task) => (
        <DraggableItem key={task.id} id={task.id} name={task.name} />
      ));
  }

  isSaving() { return this.props.isPending('save-task-order'); }

  save() {
    const positions = this.state.orderedIds.map((id, index) => ({ id, sort: index }));
    this.props.saveTaskOrder(positions);
  }

  render() {
    return (
      <ModalContent>
        <ModalBody>
          <DraggableList onDrop={this.onOrderChanged}>
            {this.getItems()}
          </DraggableList>
        </ModalBody>
        <Footer feedback={this.getFeedback()}>
          <FooterAction onClick={this.props.closeModal} disabled={this.isSaving()}>
            Cancel
          </FooterAction>
          <FooterAction onClick={this.save} disabled={this.isSaving()}>Save</FooterAction>
        </Footer>
      </ModalContent>
    );
  }
};
