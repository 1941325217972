const React = require('react');
const Select = require('@common/material-input/SidebarSelectAddButton.react');
const TravelAutocomplete = require('../../../../../../models/TravelAutocomplete');
const FieldFactory = require('./categories/FieldFactory.react');
const ReadOnlyProjectField = require('./ReadOnlyProjectField.react');

module.exports = class ProjectSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredProjects: [],
    };
  }

  handleFilter(name, key) {
    $.when(TravelAutocomplete.getProjects(key)).done((projects) => {
      this.setState({
        filteredProjects: projects,
      });
    });
  }

  handleFocus() {
    if (!this.state.filteredProjects || this.state.filteredProjects.length === 0) {
      this.handleFilter('project', '');
    }
  }

  render() {
    return (
      <FieldFactory name="project_id"
        metadataService={this.props.metadataService}
        readOnlyComponent={(
          <ReadOnlyProjectField project={this.props.project}
            placeholder="Project name" />
)}>
        <Select
          name="project"
          value={this.props.project}
          placeholder="Project name"
          items={this.state.filteredProjects}
          onFilter={this.handleFilter.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          onChange={this.props.onChange}
          onValidate={this.props.onValidate}
          required>
          <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />
        </Select>
      </FieldFactory>
    );
  }
};
