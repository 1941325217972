const { connect } = require('react-redux');
const Component = require('@common/AdvancedSearch/filters/RadioFilter/RadioFilter.react');

const mapStateToProps = () => ({
  availableOptions: [{
    label: 'Internal',
    value: 'internal',
  }, {
    label: 'External',
    value: 'external',
  }, {
    label: 'All',
    value: 'all',
  }],
});

module.exports = connect(mapStateToProps)(Component);
