const React = require('react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const Typography = require('@common/Typography/Typography.react');

module.exports = class IntercompanyDetailsModalContent extends React.Component {
  componentDidMount() {
    if (!this.props.info) {
      this.props.getIntercompanyTaskInfo(this.props.taskId);
    }
  }

  getLinkedProject() {
    if (this.inviteAccepted()) {
      return (
        <Typography>This task is currently linked to the project
          <Typography weight="bold">{this.props.info.supplier.project.recipient}</Typography>.
        </Typography>
      );
    }
    return <Typography>Waiting to be accepted.</Typography>;
  }

  getSentBody() {
    return (
      <ModalBody>
        <ModalBodyBlock>
          Intercompany opportunity sent by
          <Typography weight="bold">{this.props.info.client.sender}</Typography>
          and currently linked to
          <Typography weight="bold">{this.props.info.client.project.name}</Typography>
          from <Typography weight="bold">{this.props.info.client.company.name}</Typography>.
        </ModalBodyBlock>
        <ModalBodyBlock>
          {this.getLinkedProject()}
        </ModalBodyBlock>
      </ModalBody>
    );
  }

  getInviteBody() {
    return (
      <ModalBody>
        <ModalBodyBlock>
          Please send an invitation to someone in
          <Typography weight="bold">{this.props.intercompany.supplier.name}</Typography>
          in order to make this intercompany task effective.
        </ModalBodyBlock>
      </ModalBody>
    );
  }

  getBody() {
    if (!this.isLoading()) {
      if (this.inviteSent()) {
        return this.getSentBody();
      }
      return this.getInviteBody();
    }
    return null;
  }

  isLoading() {
    return !this.props.info || this.props.isPending(`get-intercompany-task-info-${this.props.taskId}`);
  }

  inviteSent() {
    return this.props.info.send;
  }

  inviteAccepted() {
    return this.props.info.accepted;
  }

  render() {
    return (
      <ModalContent isLoading={this.isLoading()}>
        {this.getBody()}
      </ModalContent>
    );
  }
};
