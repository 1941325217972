const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const RequestManager = require('../../../../containers/RequestManager');
const Content = require('./DeleteTaskModalContent.react');

const DeleteTaskModal = ({ closeModal, taskId }) => (
  <Modal title="Delete task" onClose={closeModal}>
    <RequestManager>
      <Content taskId={taskId} closeModal={closeModal} />
    </RequestManager>
  </Modal>
);

module.exports = DeleteTaskModal;
