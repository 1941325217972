/* eslint-disable react/sort-comp,consistent-return,react/jsx-no-bind */
const React = require('react');
const dragula = require('react-dragula');
const TableCell = require('@common/Table2/TableCell.react');
const TableRow = require('@common/Table2/TableRow.react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const Loader = require('@common/Loader/Loader.react');
const Cell = require('./HeadCell/HeadCell.react');

module.exports = class HeadContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  componentDidMount() {
    this.drake = this.dragulaDecorator(this.rowEl);
    this.props.updateNameAttributeWidth(this.nameEl.offsetWidth);
  }

  componentDidUpdate(prevProps) {
    if (this.props.attributes.length !== prevProps.attributes.length) { // Attribute added
      this.props.updateNameAttributeWidth(this.nameEl.offsetWidth);
    }
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  getMoreButton() {
    if (this.props.waiting) {
      return (
        <div
          style={{
            position: 'relative',
            top: '10px',
            left: '4px',
          }}>
          <Loader />
        </div>
      );
    }
    return (
      <div>
        <button type="button"
          className="wethod-icon-button project-canvas__plus-button"
          ref={(el) => this.moreButtonEl = el}
          onClick={this.onMenuButtonClick.bind(this)}>
          <div className="wethod-icon wethod-icon-medium-plus wethod-icon-medium-plus--black" />
        </button>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          <MenuItem onClick={() => this.props.onAddColumnClick('text')}>Text</MenuItem>
          <MenuItem onClick={() => this.props.onAddColumnClick('numeric')}>Number</MenuItem>
          <MenuItem onClick={() => this.props.onAddColumnClick('status')}>Status</MenuItem>
          <MenuItem onClick={() => this.props.onAddColumnClick('date')}>Date</MenuItem>
          <MenuItem onClick={() => this.props.onAddColumnClick('member')}>Member</MenuItem>
          <MenuItem onClick={() => this.props.onAddColumnClick('milestone')}>Milestone</MenuItem>
        </Menu>
      </div>
    );
  }

  editAttributeName(id, name) {
    this.props.editAttributeName(this.props.boardId, id, name);
  }

  isActivityDefault(id) {
    return this.props.options ? Object.values(this.props.options).includes(id) : false;
  }

  getAttributesCells() {
    return this.props.attributes.map((attribute, index) => (
      <Cell
        key={attribute.id}
        canEdit={this.props.canEdit}
        isActivityDefault={this.isActivityDefault(attribute.id)}
        isDeleting={this.props.isDeleting(attribute.id)}
        onDeleteClick={this.props.onDeleteClick}
        updateActivityAttribute={this.props.updateActivityAttribute}
        boardId={this.props.boardId}
        editName={this.editAttributeName.bind(this)}
        dataSort={index}
        {...attribute} />
    ));
  }

  getCells() {
    const cells = [
      <TableCell
        key="name"
        rootRef={(el) => this.nameEl = el}
        className="project-canvas-spreadsheet__cell--name project-canvas-spreadsheet__cell--header">
        &nbsp;
      </TableCell>,
    ].concat(this.getAttributesCells());

    if (this.props.canEdit) {
      return cells.concat(
        <TableCell
          key="actions"
          className="project-canvas-spreadsheet__cell--actions">
          {this.getMoreButton()}
        </TableCell>,
      );
    }
    return cells;
  }

  onDrop(el, target, source, sibling) {
    const from = parseInt(el.getAttribute('data-sort'));
    const toNextSiblingSort = sibling.getAttribute('data-sort') ? parseInt(sibling.getAttribute('data-sort')) : this.props.attributes.length;
    const to = toNextSiblingSort > from ? toNextSiblingSort - 1 : toNextSiblingSort;

    this.props.sortColumns(from, to);
  }

  /**
   * Init Dragula on the element componentBackingInstance.
   * @param componentBackingInstance
   */
  dragulaDecorator(componentBackingInstance) {
    if (componentBackingInstance && this.props.canEdit) {
      const options = {
        // move only if using handle
        moves: (el, container, handle) => handle.classList.contains('project-canvas-spreadsheet__cell--header-drag-handle'),
        accepts: (el, target, source, sibling) => { // accept only draggable elements coming from the same source
          const sameBoard = parseInt(el.getAttribute('data-board-id')) === this.props.boardId;
          const afterActionsCell = sibling === null;
          const beforeNameCell = sibling && sibling.classList.contains('project-canvas-spreadsheet__cell--name');
          const draggable = !afterActionsCell && !beforeNameCell;
          return draggable && sameBoard;
        },
      };

      return dragula([componentBackingInstance], options).on('drop', this.onDrop.bind(this));
    }
  }

  render() {
    return (
      <TableRow className="project-canvas-spreadsheet__row" rootRef={(el) => this.rowEl = el}>
        {this.getCells()}
      </TableRow>
    );
  }
};
