const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const Loader = require('@common/Loader/Loader.react');

module.exports = class ItemActionsCell extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };
  }

  onCloseMenu() {
    this.setState({ showMenu: false });
  }

  onMenuButtonClick() {
    this.setState({ showMenu: true });
  }

  getMoreButton() {
    if (this.props.waiting) {
      return (
        <div
          style={{
            position: 'relative',
            top: '10px',
            left: '4px',
          }}>
          <Loader />
        </div>
      );
    } // check canEdit
    return (
      <div>
        <button
          type="button"
          ref={(el) => this.moreButtonEl = el}
          onClick={this.onMenuButtonClick.bind(this)}
          className="wethod-icon-button project-canvas-spreadsheet__row-more-button">
          <div className="wethod-icon-medium-more wethod-icon-medium-more--black" />
        </button>
        <Menu
          open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.moreButtonEl}>
          <MenuItem onClick={this.props.onDeleteClick}>Delete</MenuItem>
        </Menu>
      </div>
    );
  }

  render() {
    return (
      <TableCell className="project-canvas-spreadsheet__cell--actions">{this.getMoreButton()}</TableCell>
    );
  }
};
