const React = require('react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const Modal = require('@common/Modal/Modal.react');

const JobTitleInsertModalError = ({ closeModal }) => (
  <Modal onClose={closeModal} title="Can't insert job title">
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          You can't add a job title to a task that already has assigned days.
        </ModalBodyBlock>
        <ModalBodyBlock>
          This is because when you add a job title to a task, the task's days are the sum of days
          related to its various job titles.
        </ModalBodyBlock>
        <ModalBodyBlock>
          Please clean the existing days in this task before adding a job title.
        </ModalBodyBlock>
      </ModalBody>
      <Footer>
        <FooterAction onClick={closeModal}>
          Back
        </FooterAction>
      </Footer>
    </ModalContent>
  </Modal>
);

module.exports = JobTitleInsertModalError;
