const React = require('react');
const moment = require('moment');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const TextArea = require('@common/inputs/TextArea/OutlinedTextArea/OutlinedTextArea.react');
const DateInput = require('@common/inputs/DayPicker/OutlinedDayPicker/OutlinedDayPicker.react');
const ProjectSelect = require('./ProjectSelect.react');
const DailyHoursInput = require('../../containers/sidebar/DailyHoursInput');
// const ProjectDetails = require('./ProjectHoursDetails.react');

const AllocationRequestBody = ({
  readOnly, item, errors, updateErrors,
  onChange, internationalCalendar,
}) => {
  function handleChange(e) {
    const { name, value } = e.target;
    onChange(name, value);
  }

  function handleDateChange(e) {
    const { name, value } = e.target;
    const formattedDate = value ? moment(value).format('YYYY-MM-DD') : null;
    onChange(name, formattedDate);
  }

  function handleSelectChange(name, option) {
    onChange(name, option);
  }

  function getDaysHours() {
    const { days } = item;

    return days.map((day) => (
      <DailyHoursInput key={day.date}
        onChange={handleSelectChange}
        updateErrors={updateErrors}
        readOnly={readOnly}
        day={day} />
    ));
  }

  return (
    <div className="allocation-request-sidebar__body">
      <Row>
        <InputWrapper icon={
          <span className="wethod-icon wethod-icon-pipeline wethod-icon-pipeline--black" />
        }>
          <ProjectSelect readOnly={readOnly}
            name="project"
            id="sidebar-project"
            label="Project name"
            value={item.project}
            onChange={handleSelectChange}
            errorText={errors.project}
            updateErrors={updateErrors} />
        </InputWrapper>
      </Row>
      {/* WARNING: temporarily unused, needs to be updated */}
      {/* <ShowIf condition={item.project != null}> */}
      {/*  <ProjectDetails project={item.project} employee={item.employee} /> */}
      {/* </ShowIf> */}
      <Row>
        <InputWrapper icon={
          <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
        }>
          <InputValidator updateErrors={updateErrors} constraints={['required']}>
            <DateInput readOnly={readOnly}
              name="from"
              id="sidebar-from"
              label="From"
              value={item.from}
              disableWeekend={!internationalCalendar}
              onChange={handleDateChange}
              errorText={errors.from} />
          </InputValidator>
        </InputWrapper>
        <InputWrapper>
          <InputValidator updateErrors={updateErrors} constraints={['required']}>
            <DateInput readOnly={readOnly}
              name="to"
              id="sidebar-to"
              label="To"
              value={item.to}
              disableWeekend={!internationalCalendar}
              onChange={handleDateChange}
              errorText={errors.to} />
          </InputValidator>
        </InputWrapper>
      </Row>
      <ShowIf condition={item.from != null && item.to != null}>
        <div>{getDaysHours()}</div>
      </ShowIf>
      <Row>
        <InputWrapper icon={
          <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
        }>
          <TextArea readOnly={readOnly}
            name="notes"
            id="sidebar-notes"
            label="Notes"
            value={item.notes}
            rows={3}
            onChange={handleChange}
            errorText={errors.notes} />
        </InputWrapper>
      </Row>
    </div>
  );
};

module.exports = AllocationRequestBody;
