const React = require('react');
const NumberFormatter = require('@common/formatters/NumberFormatter.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const Delta = require('../../containers/Calculator/CellDelta');

const NumericDelta = ({ actualValue, previousValue, label, decimalPlaces = 0 }) => (
  <ShowIf condition={previousValue !== null} showElse={null}>
    <Delta className="wethod-budget-calculator__cell-delta--numeric" label={label}>
      <NumberFormatter precision={decimalPlaces} explicitSign hideZero>
        {actualValue - previousValue}
      </NumberFormatter>
    </Delta>
  </ShowIf>
);

module.exports = NumericDelta;
