const React = require('react');
const Modal = require('@common/Table2/modals/SaveSearchModal/SaveSearchModal.react');
const TranslatorService = require('../../services/TranslatorService');

const SaveSearchModal = ({ saveSearch, isSaving, onCloseClick, filters, search, section }) => {
  function onSaveClick(label) {
    const url = TranslatorService.serializeFilters({ ...filters, search });
    saveSearch(url, label, section);
  }

  return (
    <Modal onCloseClick={onCloseClick} onSaveClick={onSaveClick} isSaving={isSaving} />
  );
};

module.exports = SaveSearchModal;
