const React = require('react');
const Tooltip = require('@common/TooltipFixed/Tooltip.react');
const Label = require('./PricelistTooltipMessage.react');

const PricelistTooltip = ({ children, pricelistName }) => (
  <Tooltip label={<Label name={pricelistName} />} wrapperClassName="wethod-budget-header__pricelist_container">
    {children}
  </Tooltip>
);

module.exports = PricelistTooltip;
