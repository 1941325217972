/* eslint-disable class-methods-use-this,consistent-return,no-shadow */
const React = require('react');
const _ = require('underscore');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const RequestEmployee = require('../RequestEmployee.react');
const CategoriesList = require('../TravelCategoriesList.react');
const format = require('../../../../../../../services/FormatService').formatDecimalNumber;
const ProjectField = require('../ReadOnlyProjectField.react');
const TotalCostField = require('../ReadOnlyTotalTravelCostField.react');

module.exports = class MainTravelReadOnlyDetails extends React.Component {
  getCategoriesAmount() {
    const amountList = {};
    if (this.props.travel && this.props.travel.travel_carriers) {
      this.props.travel.travel_carriers.forEach((item) => (amountList[item.type]
        ? amountList[item.type] += 1
        : amountList[item.type] = 1));
    }
    return amountList;
  }

  // Sum all values of the specific categories
  getTotalCosts() {
    if (this.props.travel && this.props.travel.travel_carriers) {
      const sum = this.props.travel.travel_carriers
        .reduce((sum, item) => (item.value ? sum + item.value : sum), 0);
      return format(sum, false, 1);
    }
  }

  getUnescapedValue(value) {
    return _.unescape(value);
  }

  getProject(attribute) {
    return this.props.travel.project
      ? this.getUnescapedValue(this.props.travel.project[attribute]) : null;
  }

  render() {
    return (
      <div>
        <RequestEmployee {...this.props.travel} />
        <ProjectField placeholder="Project name" project={this.props.travel.project} />
        <ReadOnlyField placeholder="Job Order" value={this.getProject('job_order')}>
          <span />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Main destination" value={this.props.travel.destination}>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="Purpose of the travel" value={this.props.travel.notes}>
          <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
        </ReadOnlyField>
        <TotalCostField placeholder="Total travel costs" value={this.getTotalCosts()} />
        <CategoriesList selectCategory={this.props.changePage}
          totals={this.getCategoriesAmount()}
          missingValues={this.props.missingValues} />
      </div>
    );
  }
};
