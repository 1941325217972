const React = require('react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalContent = require('@common/Modal/ModalContent.react');

const EnableMulticurrencyModalContent = ({ isPending, saveInfo, closeModal, budgetIsEditable }) => {
  const isSaving = () => isPending('save-budget-info');

  const feedback = isSaving() ? 'Saving...' : '';

  function onSaveClick() {
    saveInfo({ currency_enabled: true });
  }

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          With multicurrency you can set currencies for final net price and external costs.
        </ModalBodyBlock>
        <ModalBodyBlock>
          This can be useful when your clients or suppliers use currencies different from yours.
        </ModalBodyBlock>
        <ModalBodyBlock>Do you want to proceed?</ModalBodyBlock>
      </ModalBody>
      <ModalFooter feedback={feedback}>
        <ModalFooterAction onClick={closeModal} disabled={!budgetIsEditable}>
          Cancel
        </ModalFooterAction>
        <ModalFooterAction onClick={onSaveClick} disabled={!budgetIsEditable}>
          OK
        </ModalFooterAction>
      </ModalFooter>
    </ModalContent>
  );
};

module.exports = EnableMulticurrencyModalContent;
