const ApiTokenModel = require('@models/ApiToken');
const PropertyMetadataModel = require('@models/PropertyMetadata');
const modals = require('./modals');
const constants = require('./constants');

const closeModal = () => ({
  type: constants.CLOSE_MODAL,
});

const showModal = (key, data) => ({
  type: 'SHOW_MODAL',
  key,
  data,
});

module.exports.closeModal = closeModal;

module.exports.showModal = showModal;

const addPersonalTokenRequest = () => ({
  type: constants.PERSONAL_TOKEN_ADD_REQUEST,
});

const addPersonalTokenFailure = (message) => ({
  type: constants.PERSONAL_TOKEN_ADD_FAILURE,
  message,
});

const addPersonalTokenSuccess = (token) => ({
  type: constants.PERSONAL_TOKEN_ADD_SUCCESS,
  token,
});

module.exports.addPersonalToken = (name, description) => (dispatch) => {
  dispatch(addPersonalTokenRequest());

  ApiTokenModel.create({ name, description })
    .done((token) => {
      dispatch(showModal(modals.PERSONAL_TOKEN_CREATED, token));
      dispatch(addPersonalTokenSuccess(token));
    }).fail((message) => dispatch(addPersonalTokenFailure(message)));
};

const deletePersonalTokenRequest = () => ({
  type: constants.PERSONAL_TOKEN_DELETE_REQUEST,
});

const deletePersonalTokenSuccess = (id) => ({
  type: constants.PERSONAL_TOKEN_DELETE_SUCCESS,
  id,
});

module.exports.deletePersonalToken = (id) => (dispatch) => {
  dispatch(deletePersonalTokenRequest());

  ApiTokenModel.delete(id)
    .done(() => {
      dispatch(closeModal());
      dispatch(deletePersonalTokenSuccess(id));
    });
};

const listPersonalTokensRequest = () => ({
  type: constants.PERSONAL_TOKEN_LIST_REQUEST,
});

const listPersonalTokensSuccess = (tokens) => ({
  type: constants.PERSONAL_TOKEN_LIST_SUCCESS,
  tokens,
});

module.exports.listPersonalTokens = () => (dispatch) => {
  dispatch(listPersonalTokensRequest());

  ApiTokenModel.list()
    .done((tokens) => {
      dispatch(listPersonalTokensSuccess(tokens));
    });
};

const getPersonalTokensMetadataRequest = () => ({
  type: constants.PERSONAL_TOKEN_PROPERTY_METADATA_REQUEST,
});

const getPersonalTokensMetadataSuccess = (metadata) => ({
  type: constants.PERSONAL_TOKEN_PROPERTY_METADATA_SUCCESS,
  metadata,
});

module.exports.getPersonalTokensMetadata = () => (dispatch) => {
  dispatch(getPersonalTokensMetadataRequest());

  PropertyMetadataModel.list(PropertyMetadataModel.API_TOKEN_ENTITY)
    .done((metadata) => {
      dispatch(getPersonalTokensMetadataSuccess(metadata));
    });
};
