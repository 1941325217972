/* eslint-disable class-methods-use-this,react/sort-comp,no-case-declarations,react/jsx-no-bind,
 react/no-access-state-in-setstate */
const React = require('react');
const SearchInput = require('@common/SearchInput.react');
const Empty = require('../Empty.react');
const Favourite = require('./FavouriteSearch.react');

module.exports = class FavouriteSearches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredSearches: this.props.searches,
      keyword: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searches.length !== this.props.searches.length) {
      this.handleFilter(this.state.keyword);
    }
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Filter the list of searches with the given keyword
   * @param keyword
   */
  handleFilter(keyword) {
    this.setState({
      filteredSearches: this.props.searches.filter((search) => this.found(keyword, search.search)
        || this.found(keyword, search.label)),
      keyword,
    });
  }

  handleSearchUpdate(updatedSearch) {
    this.setState({
      filteredSearches: this.state.filteredSearches.map((search) => (search.id === updatedSearch.id
        ? { ...updatedSearch }
        : search)),
    });
    this.props.updateLabel(updatedSearch.id, updatedSearch);
  }

  getBody() {
    if (!this.props.searches.length) {
      return (
        <Empty title={(
          <div className="desk-widget-empty__pinboard-title">
            Bookmark
            <div className="wethod-icon-bookmark-empty wethod-icon-bookmark-empty--black desk-widget-empty__pinboard-bookmark" />
            your search results.
          </div>
        )}
          className="desk-widget-empty--activities"
          description="Keep them here for a faster access."
          image={<div className="desk-widget-empty__pinboard--researches" />} />
      );
    }
    const searches = !this.state.filteredSearches.length
      ? <div className="desk-widget__body--empty-text">No search found</div>
      : this.state.filteredSearches.map((search) => (
        <Favourite key={search.id}
          updateLabel={this.handleSearchUpdate.bind(this)}
          deleteFavourite={this.props.deleteFavourite}
          search={search} />
      ));
    return (
      <div className="wethod-widget__body--content">
        <div className="wethod-widget__search desk-widget__item">
          <SearchInput filter={this.handleFilter.bind(this)}
            keyword={this.state.keyword} />
        </div>
        <div className="desk-widget__body--list">
          {searches}
        </div>
      </div>
    );
  }

  render() {
    return (this.getBody());
  }
};
