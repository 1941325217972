const React = require('react');
const Select = require('@common/SearchSelect/SearchSelect.react');
const LanguageSelect = require('@common/Select2/Select2.react');

module.exports = class Language extends React.Component {
  static found(key, value) {
    if (value) return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
    return false;
  }

  constructor(props) {
    super(props);

    this.state = {
      language: this.props.name,
      localeCode: this.props.locale_code,
      level: this.props.level,
      errors: [],
      filteredLanguages: this.getFilteredLanguages(''),
    };

    this.timer = 0;
  }

  handleChanges() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.saveOrUpdate();
    }, 500);
  }

  onNameChange(name, value) {
    this.setState({ name: value }, () => {
      this.save();
    });
  }

  onLevelChange(name, value) {
    this.setState({ level: value ? value.id : null }, () => {
      this.handleChanges();
    });
  }

  onLanguageChange(name, value) {
    if (!value || !value.name || !value.locale_code) return;

    this.setState({
      language: value.name,
      localeCode: value.locale_code,
    }, () => {
      this.handleChanges();
    });
  }

  onValidate(name, errors) {
    if (errors.length > 0) {
      this.addError(name);
    } else {
      this.removeError(name);
    }
  }

  onDeleteClick() {
    this.props.deleteLanguage({
      id: this.props.id,
      name: this.state.name,
      level: this.state.level,
      isFake: this.props.isFake,
    });
    this.props.updateFormErrors(this.props.id, 'remove');
  }

  getLevel(id) {
    for (let i = 0; i < this.props.levels.length; i++) {
      if (id === this.props.levels[i].id) {
        return this.props.levels[i];
      }
    }

    return 'Error';
  }

  getInputClassName(name) {
    let className = `profile-languages__item-${name}`;
    if (this.state.errors.indexOf(name) !== -1) {
      className = `${className} wethod-modal2-input--error`;
    }
    return className;
  }

  getLanguage() {
    return { name: this.state.language };
  }

  getFilteredLanguages(key) {
    const toRemove = this.props.languages;
    const { availableLanguages } = this.props;

    return availableLanguages.filter(
      (language) => {
        let include = true;
        toRemove.forEach((el) => {
          if (el.name === language.name && language.name !== this.props.name) {
            include = false;
          }
        });
        return include && language.name.toLowerCase().includes(key.toLowerCase());
      },
    );
  }

  addError(name) {
    // Passing a function to setState lets you avoid async state update problems.
    if (this.state.errors.indexOf(name) === -1) {
      this.setState((previousState) => ({
        ...previousState,
        errors: previousState.errors.concat(name),
      }), () => {
        this.props.updateFormErrors(this.props.id, 'add');
      });
    }
  }

  removeError(name) {
    this.setState((previousState) => ({
      ...previousState,
      errors: previousState.errors.filter((error) => error !== name),
    }), () => {
      if (!this.state.errors.length) {
        this.props.updateFormErrors(this.props.id, 'remove');
      }
    });
  }

  saveOrUpdate() {
    const { language, level } = this.state;
    const originalLanguage = this.props.name;
    const originalLevel = this.props.level;

    if (this.state.errors.length > 0) return;

    if (language !== null && level !== null) {
      if (originalLanguage === null) {
        this.props.addLanguage({ name: language, locale_code: this.state.localeCode, level });
      } else if (originalLanguage !== language || originalLevel !== level) {
        this.props.updateLanguage({
          name: language,
          locale_code: this.state.localeCode,
          level,
          id: this.props.id,
        });
      }
    }
  }

  filterLanguages(name, key) {
    const filteredLanguages = this.getFilteredLanguages(key);

    this.setState({ filteredLanguages });
  }

  render() {
    const disabledStyle = this.props.disabled ? 'profile-languages__add--disabled' : '';
    const style = `profile-languages__item ${disabledStyle}`;
    return (
      <div className={style}>
        <span className={this.getInputClassName('language')}>
          <LanguageSelect name="language"
            placeholder="Language"
            current={this.getLanguage()}
            items={this.state.filteredLanguages}
            onFilter={this.filterLanguages.bind(this)}
            onChange={this.onLanguageChange.bind(this)}
            constraints="required"
            onValidate={this.onValidate.bind(this)} />
        </span>
        <span className={this.getInputClassName('level')}>
          <Select name="level"
            current={this.getLevel(this.state.level)}
            constraints="required"
            onValidate={this.onValidate.bind(this)}
            items={this.props.levels}
            onChange={this.onLevelChange.bind(this)} />
        </span>
        <button type="button"
          onClick={this.onDeleteClick.bind(this)}
          className="profile-languages__item-delete wethod-icon-button">
          <div className="wethod-icon-medium-delete wethod-icon-medium-delete--black" />
        </button>
      </div>
    );
  }
};
