const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const Loader = require('@common/Loader/Loader.react');
const Typography = require('@common/Typography/Typography.react');
const LevelRow = require('./LevelRow.react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');

module.exports = class LevelsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      size: 0,
      showTooltip: false,
    };
  }

  handleMouseEnter() {
    this.setState({ showTooltip: true });
  }

  handleMouseLeave() {
    this.setState({ showTooltip: false });
  }

  getCategories() {
    const timeFilter = TimeFilterFactory(this.props.timeFilter);
    return timeFilter
      .getBreakpoints()
      .map((breakpoint) => {
        const label = breakpoint.getLabel();
        return <Typography key={label} weight="bold">{label}</Typography>;
      });
  }

  getLoadedLevels() {
    return this.props.levels.slice(0, this.state.page * this.state.size);
  }

  getLevels() {
    const levels = this.getLoadedLevels();

    return levels.map((level) => (
      <LevelRow key={level.id}
        level={level}
        selected={level.id === this.props.selectedLevel.id}
        onClick={this.props.selectLevel} />
    ));
  }

  getEmptyContent() {
    if (this.props.isWaiting) return <Loader />;
    return 'No levels yet';
  }

  hasMore() {
    const loaded = this.state.page * this.state.size;
    return this.props.levels.length > loaded;
  }

  loadMore(size, page) {
    this.setState({
      page,
      size,
    });
  }

  render() {
    return (
      <Table columns={4} maxHeight={this.props.maxHeight}>
        <TableHead>
          <TableRow>
            <TableCell name="level" className="chargeability__column-level">
              <Typography weight="bold">Level</Typography>
            </TableCell>
            <TableCell name="trend" className="chargeability__column-trend">
              {this.getCategories()}
            </TableCell>
            <TableCell name="target" className="chargeability__column-target">
              <Typography weight="bold">Target</Typography>
            </TableCell>
            <TableCell
              name="avg_chargeability"
              className="chargeability__column-avg"
              onMouseEnter={this.handleMouseEnter.bind(this)}
              onMouseLeave={this.handleMouseLeave.bind(this)}>
              <Typography weight="bold">Avg.</Typography>
            </TableCell>
            {
              this.state.showTooltip && (
                <TableCell className="chargeability__tooltip tooltipReact">
                  <span style={{ position: 'relative', bottom: '50%' }}>
                    Average chargeability
                  </span>
                </TableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.hasMore()}
          loadMore={this.loadMore.bind(this)}
          empty={this.getEmptyContent()}>
          {this.getLevels()}
        </TableBody>
      </Table>
    );
  }
};
