const React = require('react');
const _ = require('underscore');
const Select = require('@common/inputs/Select/ColorfulSelect/OutlinedColorfulSelect/OutlinedColorfulSelect.react');
const SelectItem = require('@common/Menu/ListMenu/MenuItemColorful.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const TooltipItem = require('../TooltipItem.react');

const StageSelect = ({
  updateErrors, value, color, onChange, error, name, availableValues, readOnly, disabled,
}) => {
  function handleChange(e) {
    const selected = availableValues.filter((status) => status.id === parseInt(e.target.value))[0];
    onChange(e, selected);
  }

  const getOptions = () => availableValues
    .sort((a, b) => a.hierarchy - b.hierarchy)
    .map((status) => {
      const label = status.is_default ? '' : status.name;
      return (
        <SelectItem key={status.id}
          value={status.id}
          color={status.color}
          label={label}>
          <TooltipItem label={status.description}>
            {_.unescape(label)}
          </TooltipItem>
        </SelectItem>
      );
    });

  return (
    <InputValidator updateErrors={updateErrors} constraints={[]}>
      <Select readOnly={readOnly}
        disabled={!readOnly && disabled}
        value={value}
        id="sidebar-stage"
        label="Stage"
        color={color}
        onChange={handleChange}
        name={name}
        errorText={error}>
        {getOptions()}
      </Select>
    </InputValidator>
  );
};

module.exports = StageSelect;
