/* eslint-disable consistent-return,react/jsx-no-bind,class-methods-use-this */
const React = require('react');
const Item = require('@common/Menu/ListMenu/MenuItemColorful.react');
const EditItem = require('@common/Menu/ListMenu/MenuItem.react');

module.exports = class StatusListMenu extends React.Component {
  handleStatusClick(id) {
    this.props.onStatusChange(this.props.statusList.find((status) => status.id === id));
  }

  handleEditClick() {
    this.props.onEditClick();
  }

  getActions() {
    if (this.props.onEditClick) {
      return (
        <EditItem onClick={this.handleEditClick.bind(this)}>
          <div className="wethod-icon-medium-edit wethod-icon-medium-edit--black project-canvas-menu-status-list__edit--icon" />
          <span>Edit</span>
        </EditItem>
      );
    }
  }

  getStatusLabel(completed) {
    if (completed) {
      return (
        <span className="project-canvas-menu-status-list__label">(Final)</span>
      );
    }
  }

  getStatusList() {
    return (
      this.props.statusList.sort((a, b) => a.sort - b.sort)
        .map((status) => (
          <Item
            key={status.id}
            color={status.color}
            onClick={() => this.handleStatusClick(status.id)}>
            <span>{status.name}</span>
            {this.getStatusLabel(status.completed)}
          </Item>
        ))
    );
  }

  render() {
    return (
      <div>
        <ul className="project-canvas-menu project-canvas-menu-status-list">
          {this.getStatusList()}
        </ul>
        {this.getActions()}
      </div>
    );
  }
};
