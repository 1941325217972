const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const Select = require('@common/Select2/Select2.react');
const LoadingItem = require('@common/Select2/LoadingItem.react');
const Escaper = require('@common/formatters/Escaper.react');
const AutocompleteModel = require('../../models/AutocompleteMasterProjectModel');

module.exports = class DelegateInvoiceModal extends React.Component {
  static isDirty(value) {
    return value != null && value.toString().trim() !== '';
  }

  constructor(props) {
    super(props);

    this.state = {
      project: null,
      filteredProjects: [],
      isLoading: false,
      errors: [],
    };
  }

  handleProjectFilter(name, key) {
    this.setState({ isLoading: true, filteredProjects: [] });

    const params = { key };
    const model = new AutocompleteModel(params);

    $.when(model.getAutoComplete(this.props.data.project.id)).done((response) => {
      this.setState({
        filteredProjects: response,
        isLoading: false,
      });
    });
  }

  handleSave() {
    this.props.onDelegate(this.props.data.project.id, this.state.project.id);
  }

  onProjectChange(name, value) {
    this.setState({ project: value });
  }

  onValidate(name, errors) {
    this.setState({ errors });
  }

  getBody() {
    return (
      <div>
        <p>
          Please, select the project you would like to delegate all the
          {' '}
          <b><Escaper>{this.props.data.project.name}</Escaper></b> invoicing process.
        </p>
        <br />
        <Select name="project"
          placeholder="Select a project"
          param="hint"
          current={this.state.project}
          empty={this.getEmpty()}
          items={this.state.filteredProjects}
          onFilter={this.handleProjectFilter.bind(this)}
          onChange={this.onProjectChange.bind(this)}
          constraints="required"
          onValidate={this.onValidate.bind(this)} />
      </div>
    );
  }

  getEmpty() {
    if (this.state.isLoading) {
      return <LoadingItem />;
    }
    return null;
  }

  getActions() {
    return (
      <div className="invoice-plan__modal-actions">
        <button type="button" onClick={this.props.onClose} className="wethod-button">Cancel</button>
        <button type="button" onClick={this.handleSave.bind(this)} className={this.getSaveStyle()}>Save</button>
      </div>
    );
  }

  getSaveStyle() {
    let style = 'wethod-button';
    if (!this.canSave()) style += ' disabled';
    return style;
  }

  getFeedback() {
    if (this.props.isWaiting) return <span className="wethod-modal2__feedback">Saving...</span>;
    return '';
  }

  canSave() {
    return this.state.errors.length === 0;
  }

  render() {
    return (
      <Modal title="Billing group" onCancelClick={this.props.onClose} className="invoice-plan__modal">
        {this.getBody()}
        {this.getActions()}
        {this.getFeedback()}
      </Modal>
    );
  }
};
