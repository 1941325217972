const React = require('react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const MenuItem = require('@common/Menu/ListMenu/MenuItem.react');
const IconButton = require('@common/Button/IconButton.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');

module.exports = class MoreOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showMenu: false };

    this.closeMenu = this.closeMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.setMenuAnchorRef = this.setMenuAnchorRef.bind(this);
  }

  setMenuAnchorRef(ref) {
    this.button = ref;
  }

  closeMenu() {
    this.setState({ showMenu: false });
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  render() {
    return (
      <div style={{ display: 'inline-block' }}>
        <IconButton icon="more"
          color="blue"
          size="medium"
          onClick={this.showMenu}
          className="company-settings__icon-button"
          inputRef={this.setMenuAnchorRef} />
        <Menu
          open={this.state.showMenu}
          onClose={this.closeMenu}
          anchorEl={this.button}>
          <ShowIf condition={this.props.archived}
            showElse={
              <MenuItem onClick={this.props.onArchive}>Archive</MenuItem>
            }>
            <MenuItem onClick={this.props.onArchive}>Unarchive</MenuItem>
          </ShowIf>
        </Menu>
      </div>
    );
  }
};
