const React = require('react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Modal = require('@common/Modal/Modal.react');

const LogModal = ({ closeModal, logs }) => {
  const formattedLogs = logs
    .map((log) => <ModalBodyBlock key={log.id}>{log.message}</ModalBodyBlock>);

  return (
    <Modal title="Logs" onClose={closeModal}>
      <ModalContent>
        <ModalBody>
          <div className="wethod-budget-modal-logs__body">
            {formattedLogs}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

module.exports = LogModal;
