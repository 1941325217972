const React = require('react');
const isEqual = require('react-fast-compare');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const allDoneImg = require('@img/empty-states/all-done.svg');
const selectSegmentImg = require('../../../../../../../img/empty-states/select-segment.svg');
const IntercompanyService = require('../../../../../../services/IntercompanyService');
const AlertAccordion = require('./alerts/AlertAccordion.react');
const DashboardHeader = require('../../containers/dashboard/DashboardHeader');
const UnarchivedProjectsAccordion = require('./unarchived-projects/UnarchivedProjectsAccordion.react');
const OutstandingProjectsAccordion = require('./outstanding-projects/OutstandingProjectsAccordion.react');
const UnapprovedBudgetsAccordion = require('./unapproved-budgets/UnapprovedBudgetsAccordion.react');
const IntercompanyActivityAccordion = require('./intercompany/IntercompanyActivityAccordion.react');
const EmptyBody = require('./DashboardEmptyBody.react');
const timelines = require('../../timelines');

module.exports = class DataFreezingDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSegment
      && !isEqual(this.props.selectedSegment, prevProps.selectedSegment)) {
      this.props.getTotals(this.props.selectedSegment.from, this.props.selectedSegment.to);
    }
  }

  getBody() {
    if (!this.props.selectedSegment) {
      return (
        <EmptyBody
          src={selectSegmentImg}
          alt="Select segment"
          title="Select a segment to start"
          description="Create a segment or select one to pause or freeze data from that period." />
      );
    }
    if (timelines.isMainTimeline(this.props.selectedSegment.timeline)) {
      return (
        <div>
          <UnarchivedProjectsAccordion counter={this.props.totals?.unarchived_projects} />
          <OutstandingProjectsAccordion counter={this.props.totals?.outstanding_projects} />
          <UnapprovedBudgetsAccordion counter={this.props.totals?.unapproved_budgets} />
          <ShowIf condition={IntercompanyService.isIntercompanyEnabled()}>
            <IntercompanyActivityAccordion counter={this.props.totals?.intercompany_activity} />
          </ShowIf>
          <AlertAccordion
            title="Alerts Overview"
            subtitle="Check on red alerts and eventually resolve them in order to guarantee quality of data."
            counter={this.props.totals?.alerts} />
        </div>
      );
    }
    return (
      <EmptyBody
        src={allDoneImg}
        alt="All done"
        title="All done!"
        description="There are no inconsistencies to resolve here." />
    );
  }

  render() {
    return (
      <div className="data-freezing-engine-mb--m">
        <DashboardHeader selectedSegment={this.props.selectedSegment} />
        {this.getBody()}
      </div>
    );
  }
};
