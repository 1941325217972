/* eslint-disable react/sort-comp */
const React = require('react');
const TextInput = require('@common/TextInput.react');

module.exports = class ItemCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = { focus: false, name: '' };
  }

  onFocus() {
    this.setState({ focus: true });
  }

  onBlur() {
    this.setState({ focus: false });
  }

  onNameChange(name, value) {
    const trimmed = value.trim();
    if (trimmed !== this.state.name) {
      this.setState({ name: trimmed });
    }
  }

  handleKeyUp(e) {
    const { name } = this.state;
    if (e.keyCode === 13 && name !== '') {
      this.props.onAddItem(name);
      this.setState({ name: '', focus: false });
    }
  }

  getInput() {
    if (this.props.waiting) {
      return <span className="project-canvas-spreadsheet__row-creator-feedback">Adding...</span>;
    }
    return (
      <TextInput
        placeholder="+ New row"
        onChange={this.onNameChange.bind(this)}
        onFocus={this.onFocus.bind(this)}
        onKeyUp={this.handleKeyUp.bind(this)}
        onBlur={this.onBlur.bind(this)} />
    );
  }

  getInstructionsClassName() {
    let name = 'project-canvas-spreadsheet__row-creator-instructions';
    const visible = !this.props.waiting && (this.state.focus || this.state.name !== '');
    if (visible) {
      name += ' project-canvas-spreadsheet__row-creator-instructions--visible';
    }
    return name;
  }

  getNameCellClassName() {
    let name = 'project-canvas-gantt__item--new-input';
    if (this.state.focus) {
      name += ' project-canvas-gantt__item--new-input--focus';
    }
    return name;
  }

  getTop() {
    // add header cell to numItems
    const numItems = this.props.numItems + 1;
    // 50px per Items
    return numItems * 50;
  }

  render() {
    return (
      <div className="project-canvas-gantt__item project-canvas-gantt__item--new" style={{ top: this.getTop() }}>
        <div className={this.getNameCellClassName()}>{this.getInput()}</div>
        <span className={this.getInstructionsClassName()}>Press ENTER to add</span>
      </div>
    );
  }
};
