const React = require('react');
const Typography = require('@common/Typography/Typography.react');
const CreateButton = require('../containers/CreateSegmentButton');

const Actions = () => (
  <div className="wethod-section-actions">
    <Typography color={Typography.COLORS.DUSTY_GRAY}>
      Start a new analysis on a period you want to pause or freeze to resolve possible {' '}
      inconsistencies before freezing data from that period.
    </Typography>
    <CreateButton />
  </div>
);

module.exports = Actions;
