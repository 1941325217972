/* eslint-disable react/no-array-index-key,consistent-return,react/sort-comp,
 class-methods-use-this */
const React = require('react');
const Modal = require('@common/modal2/Modal.react');
const SearchSelect = require('@common/SearchSelectAddButton/SearchSelect2.react');
const LoadingItem = require('@common/LoadingSmall.react');

module.exports = class MoveSupplierModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      suppliers: [],
      selectedSupplier: null,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const params = { like: '' };
    const supplierAutocompleteRequest = Wethod.request('autocomplete:finance:supplier', params);
    $.when(supplierAutocompleteRequest).done((suppliers) => {
      if (suppliers) {
        this.setState({
          suppliers,
          isLoading: false,
        });
      }
    });
  }

  getUnescapedContent(value) {
    return _.unescape(value);
  }

  handleSupplierSelect(name, value) {
    this.setState({ selectedSupplier: value });
  }

  handleMove() {
    if (this.state.selectedSupplier) {
      this.props.moveSupplier(this.props.data.supplier, this.state.selectedSupplier);
    }
  }

  getOrdersList() {
    return this.props.data.orders.map((item, index) => (
      <li key={index}>
        <span className="suppliers__orders--project">{this.getUnescapedContent(item.project.name)}</span>
        <span className="suppliers__orders--value">{item.value}</span>
      </li>
    ));
  }

  getOrders() {
    return (
      <div className="wethod-modal__spaced-paragraph">
        <span>Associated orders:</span>
        <span className="suppliers__orders--header">
          <span className="suppliers__orders--project">Project</span>
          <span className="suppliers__orders--value">Value</span>
        </span>
        <ul className="wethod-modal-list">
          {this.getOrdersList()}
        </ul>
      </div>
    );
  }

  getSuppliers() {
    if (this.state.isLoading) {
      return <div className="wethod-modal__spaced-paragraph"><LoadingItem /></div>;
    }

    let { suppliers } = this.state;
    // When supplier status is disabled, every supplier except the one
    // we are deleting is available
    if (!this.props.statusEnabled) {
      suppliers = suppliers.filter((item) => item.id !== this.props.data.supplier.id);
    } else {
      // When supplier status is enabled, only suppliers with status 'available' are suitable
      // for the switch
      suppliers = suppliers.filter((item) => {
        const available = item.status ? item.status.available : false;

        return item.id !== this.props.data.supplier.id && available;
      });
    }

    return (
      <div className="wethod-modal__spaced-paragraph">
        <span>Select new supplier for the above orders:</span>
        <SearchSelect name="suppliers"
          onChange={this.handleSupplierSelect.bind(this)}
          placeholder="Choose new supplier"
          items={suppliers} />
      </div>
    );
  }

  getDeleteButtonStyle() {
    let style = 'wethod-button wethod-button--confirm';
    if (!this.state.selectedSupplier) {
      style += ' disabled';
    }
    return style;
  }

  getFeedBack() {
    if (this.props.isDeleting) {
      return <div className="profile-contact-info__feedback">Deleting</div>;
    }
  }

  render() {
    return (
      <Modal title="Move Supplier Orders"
        onCancelClick={this.props.onClose}
        className="suppliers__modal">
        <span>
          Are you sure you want to delete <b>{this.props.data.supplier.name}</b> ?
        </span>
        <br />
        {this.getOrders()}
        {this.getSuppliers()}
        <div className="profile-contact-info__actions">
          <button type="button"
            onClick={this.props.onClose}
            className="wethod-button wethod-button--warning">
            Cancel
          </button>
          <button type="button"
            onClick={this.handleMove.bind(this)}
            className={this.getDeleteButtonStyle()}>
            Move
          </button>
        </div>
        {this.getFeedBack()}
      </Modal>
    );
  }
};
