/* eslint-disable class-methods-use-this */

const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');

module.exports = class Actions extends React.Component {
  getAddButtonClassName() {
    return 'clients__actions--add-client-button wethod-button';
  }

  getSectionTitle() {
    return this.props.type === 'companies' ? 'Client List' : 'Contacts';
  }

  render() {
    return (
      <div className="clients__actions wethod-section-actions"
        ref={(ref) => this.rootRef = ref}>
        <SectionHeader
          current_section={this.getSectionTitle()}
          preview_anchor_id="clients"
          helper_url="finance/index/#clients" />
        <button type="button"
          className={this.getAddButtonClassName()}
          onClick={this.props.addClient}>
          Add
        </button>
      </div>
    );
  }
};
