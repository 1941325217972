const React = require('react');
const Menu = require('@common/Menu/ListMenu/ListMenu.react');
const Item = require('@common/Menu/ListMenu/MenuItem.react');
const MoreOptionsMenuItem = require('../../../budget/components/ProjectSidebar/MoreOptionMenuItem.react');

module.exports = class ProjectActions extends React.Component {
  onManualEditClick() {
    if (this.props.canEdit) {
      this.props.changeMode('edit');
      this.props.hideActionsDropdown();
    }
  }

  onSaveButtonClick() {
    this.props.saveProject();
  }

  getManualEditButton() {
    if (this.props.canEdit) {
      return (
        <Item onClick={this.onManualEditClick.bind(this)}>
          Manual edit
        </Item>
      );
    }
    return null;
  }

  getAutoDistributionButton() {
    if (this.props.canEdit) {
      return (
        <Item
          onClick={this.props.autoDistribute}
          className="production-plan-project__options--border-bottom">
          Auto distribution
        </Item>
      );
    }
    return null;
  }

  getOptions() {
    return (
      <Menu
        open={this.props.showOptions}
        onClose={this.props.hideActionsDropdown}
        centered
        anchorEl={this.optionsButton}>
        {this.getManualEditButton()}
        {this.getAutoDistributionButton()}
        <MoreOptionsMenuItem href={`#pipeline/budget/${this.props.projectId}`}>Budget</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project/${this.props.projectId}/canvas`}>Canvas</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project/${this.props.projectId}/report`}>Project report</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project-status/${this.props.projectId}`}>Project status</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`#timeline/${this.props.projectId}`}>Timeline</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`#planning/people?project_id=${this.props.projectId}`}>Planning</MoreOptionsMenuItem>
        <MoreOptionsMenuItem href={`/#project/${this.props.projectId}/invoice-plan`}>Invoice plan</MoreOptionsMenuItem>
      </Menu>
    );
  }

  getEditButton() {
    return (
      <div>
        <button
          type="button"
          onClick={this.props.showActionsDropdown}
          ref={(el) => this.optionsButton = el}
          className="wethod-icon-button production-plan-project__edit-button production-plan-project__actions-button--edit">
          <div className="wethod-icon-medium-more wethod-icon-medium-more--black" />
        </button>
        { this.getOptions() }
      </div>
    );
  }

  getButton() {
    const { mode } = this.props;
    switch (mode) {
      case 'view':
        return this.getEditButton();
      case 'edit':
      case 'approve':
        if (this.props.canEdit) {
          return (
            <button
              type="button"
              onClick={this.onSaveButtonClick.bind(this)}
              className="wethod-icon-button production-plan-project__actions-button--got-it">
              <div className="wethod-icon-medium-checked wethod-icon-medium-checked--black" />
            </button>
          );
        }
        return null;
      case 'saving':
        return (
          <span className="production-plan-project__actions--feedback">Saving</span>
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <span className="production-plan-project__actions-buttons">
        {this.getButton()}
      </span>
    );
  }
};
