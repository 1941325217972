const React = require('react');
const BaseItem = require('@common/inputs/Select/SelectItem.react');

const SelectItem = ({ children, className, ...rest }) => {
  const customClassName = className || 'company-settings__select-item';

  return (
    <BaseItem className={customClassName}
      {...rest}>
      {children}
    </BaseItem>
  );
};

module.exports = SelectItem;
