const React = require('react');
const SnackbarComponent = require('@common/Snackbar/Snackbar.react');
const ForProjects = require('../containers/ForProjects');
const ForOpportunities = require('../containers/ForOpportunities');

const Snackbar = ({ show, data, onClose }) => {
  function handleClose() {
    onClose();
  }

  const id = data ? data.project.id : '';

  return (
    <SnackbarComponent name="pipeline-snackbar" open={show} onClose={handleClose} timeoutMs={7000}>
      <ForProjects>
        <span>Your project has been moved to the
          <a href={`#pipeline/opportunities?id=${id}`}> Opportunity List!</a>
        </span>
      </ForProjects>
      <ForOpportunities>
        <span><b>Hooray!</b> Your opportunity has been moved to the
          <a href={`#pipeline/projects?id=${id}`}> Project List!</a>
        </span>
      </ForOpportunities>
    </SnackbarComponent>
  );
};

module.exports = Snackbar;
