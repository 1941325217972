const React = require('react');
const { connect } = require('react-redux');
const Modal = require('@common/Modal/Modal.react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalFooter = require('@common/Modal/ModalFooter.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const ModalFooterAction = require('@common/Modal/ModalFooterAction.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Typography = require('@common/Typography/Typography.react');
const modalService = require('@common/Modal/ModalManager/services/ModalService');
const actions = require('../../actions');

class RevokeInvitationModal extends React.Component {
  static onClose() {
    modalService.closeModal();
  }

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      error: null,
    };
    this.onOk = this.onOk.bind(this);
  }

  onOk() {
    this.setState({ isLoading: true });
    this.props.deleteInvitation({ code: this.props.user.code })
      .always(() => {
        this.setState({ isLoading: false });
      })
      .done(() => {
        this.props.getItems();
        this.props.onSuccess?.();
        RevokeInvitationModal.onClose();
      })
      .fail((response) => {
        if (typeof response === 'string') {
          this.setState({ error: response });
        }
      });
  }

  render() {
    return (
      <Modal onClose={RevokeInvitationModal.onClose} title="Revoke Invitation">
        <ModalContent>
          <ModalBody>
            <ModalBodyBlock>
              Are you sure you want to revoke the invitation for
              <Typography weight={Typography.WEIGHTS.BOLD}>{ this.props.user.email }</Typography>?
            </ModalBodyBlock>
          </ModalBody>
          <ModalFooter
            error={this.state.error}
            feedback={this.state.isLoading ? 'Saving...' : null}>
            <ModalFooterAction
              onClick={RevokeInvitationModal.onClose}
              disabled={this.state.isLoading}>
              Cancel
            </ModalFooterAction>
            <ModalFooterAction
              onClick={this.onOk}
              disabled={this.state.isLoading}>
              Revoke
            </ModalFooterAction>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

module.exports = connect(null, {
  getItems: actions.getItems,
  deleteInvitation: actions.deleteInvitation,
})(RevokeInvitationModal);
