/* eslint-disable class-methods-use-this,react/sort-comp,no-case-declarations,react/jsx-no-bind,
 react/no-access-state-in-setstate */
const React = require('react');
const SearchInput = require('@common/SearchInput.react');
const Empty = require('../Empty.react');
const Favourite = require('./FavouriteProject.react');

module.exports = class FavouriteProjects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredProjects: this.props.projects,
      keyword: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projects.length !== this.props.projects.length) {
      this.handleFilter(this.state.keyword);
    }
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  /**
   * Filter the list of projects with the given keyword
   * @param keyword
   */
  handleFilter(keyword) {
    this.setState({
      filteredProjects: this.props.projects
        .filter((project) => this.found(keyword, project.project.name)
          || this.found(keyword, project.client.name)),
      keyword,
    });
  }

  getBody() {
    if (!this.props.projects.length) {
      return (
        <Empty
          title={(
            <div className="desk-widget-empty__pinboard-title">
              Bookmark
              <div className="wethod-icon-bookmark-empty wethod-icon-bookmark-empty--black desk-widget-empty__pinboard-bookmark" />
              your favourite project pages.
            </div>
          )}
          className="desk-widget-empty--activities"
          description="Favourites are saved here for a faster access."
          image={<div className="desk-widget-empty__pinboard--projects" />} />
      );
    }
    const projects = !this.state.filteredProjects.length
      ? <div className="desk-widget__body--empty-text">No project found</div>
      : this.state.filteredProjects
        .map((favourite) => (
          <Favourite key={favourite.project.id}
            deleteFavourite={this.props.deleteFavourite}
            {...favourite} />
        ));
    return (
      <div className="wethod-widget__body--content">
        <div className="wethod-widget__search desk-widget__item">
          <SearchInput filter={this.handleFilter.bind(this)}
            keyword={this.state.keyword} />
        </div>
        <div className="desk-widget__body--list">
          {projects}
        </div>
      </div>
    );
  }

  render() {
    return this.getBody();
  }
};
