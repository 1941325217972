const React = require('react');
const AvatarBox = require('@common/AvatarBox/AvatarBox.react');
const InputDecorator = require('@common/InputDecorator/BasicInputDecorator/BasicInputDecorator.react');
const Menu = require('@common/Menu/Menu.react');
const LocationList = require('./LocationList/LocationList.react');

class LocationPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    this.props.validate('locations', this.props.addedLocations);
  }

  componentWillUnmount() {
    if (this.props.resetErrors) {
      this.props.resetErrors('locations');
    }
  }

  handleLocationDelete(locationTagId) {
    this.props.deleteLocation(locationTagId);
  }

  handleLocationSelect(locationId) {
    this.props.addLocation(locationId);
  }

  onMenuClose() {
    this.setState({ open: false });
    this.props.onChange();
    this.props.validate('locations', this.props.addedLocations);
  }

  onMenuButtonClick() {
    this.setState({ open: true });
  }

  getSelectedItemsAvatars() {
    return this.props.addedLocations
      ? this.props.addedLocations.map((location) => (
        {
          ...location,
          name: location.name?.length >= 2 ? `${location.name.substring(0, 1)} ${location.name.substring(1, 2)}` : location.name,
        }))
      : [];
  }

  render() {
    return (
      <div className="project-canvas-spreadsheet__cell-button">
        <Menu
          open={this.state.open}
          anchorEl={this.moreButtonEl}
          onClose={this.onMenuClose.bind(this)}
          bottom
          centered>
          <LocationList
            locations={this.props.locations}
            addedLocations={this.props.addedLocations}
            onDelete={this.handleLocationDelete.bind(this)}
            onSelect={this.handleLocationSelect.bind(this)} />
        </Menu>
        <InputDecorator
          errorText={this.props.errorText}>
          <button type="button"
            ref={(el) => this.moreButtonEl = el}
            className="wethod-input-root wethod-select__button"
            onClick={this.onMenuButtonClick.bind(this)}>
            <AvatarBox
              className="wethod-avatar-box--inline"
              avatars={this.getSelectedItemsAvatars()}
              max={3} />
            {
              this.props.addedLocations?.length === 0 && (
                <span className="company-settings__placeholder">
                  Select location
                </span>
              )
            }
          </button>
        </InputDecorator>
      </div>
    );
  }
}

module.exports = LocationPicker;
