const React = require('react');
const Table = require('@common/Table2/Table2.react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const TableBody = require('@common/Table2/TableBody.react');
const Typography = require('@common/Typography/Typography.react');
const TimeFilterFactory = require('../../../finance/clients/detail/company/models/TimeFilter/TimeFilterFactory');
const Employee = require('./EmployeeRow.react');

module.exports = class EmployeesTable extends React.Component {
  handleSort(col, order) {
    this.props.sort(col, order);
  }

  getSort(col) {
    const currentSort = this.props.sortBy;
    if (currentSort.col === col) {
      return currentSort.order;
    }
    return null;
  }

  getCategories() {
    const timeFilter = TimeFilterFactory(this.props.timeFilter);
    return timeFilter
      .getBreakpoints()
      .map((breakpoint) => {
        const label = breakpoint.getLabel();
        return <Typography key={label} weight="bold">{label}</Typography>;
      });
  }

  getLoadedEmployees() {
    return this.props.employees.slice(0, this.props.page * this.props.size);
  }

  getEmployees() {
    const employees = this.getLoadedEmployees();

    return employees.map((employee) => (
      <Employee key={employee.id}
        employee={employee}
        role={this.props.role}
        selectedCriterion={this.props.selectedCriterion}
        timeFilter={this.props.timeFilter} />
    ));
  }

  hasMore() {
    const loaded = this.props.page * this.props.size;
    return this.props.employees.length > loaded;
  }

  render() {
    return (
      <Table columns={6} maxHeight={this.props.maxHeight}>
        <TableHead>
          <TableRow>
            <TableCell name="name" className="kudos__column-name">
              <Typography weight="bold">Team member</Typography>
            </TableCell>
            <TableCell name="projects" className="kudos__column-project kudos__column--right">
              <Typography weight="bold">Projects</Typography>
            </TableCell>
            <TableCell name="reviews" className="kudos__column-review kudos__column--right">
              <Typography weight="bold">Reviews</Typography>
            </TableCell>
            <TableCell name="trend" className="kudos__column-trend">
              {this.getCategories()}
            </TableCell>
            <TableCell name="avg"
              className="kudos__column-avg-header"
              onSort={this.handleSort.bind(this)}
              sort={this.getSort('avg')}>
              <Typography weight="bold">Avg. Kudos</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          hasMore={this.hasMore()}
          loadMore={this.props.loadMore}
          empty="No employees found">
          {this.getEmployees()}
        </TableBody>
      </Table>
    );
  }
};
