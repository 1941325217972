const React = require('react');
const PropTypes = require('prop-types');
const Checkbox = require('@common/inputs/Checkbox/Checkbox.react');
const Radio = require('@common/inputs/RadioGroup/Radio.react');

const TYPE_CHECKBOX = 'checkbox';
const TYPE_RADIO = 'radio';

const SearchAndSelectModalOption = ({
  id, onChange, label, checked, type, name, value,
  disabled,
}) => {
  switch (type) {
    case TYPE_CHECKBOX:
      return (
        <Checkbox name={name}
          disabled={disabled}
          id={id}
          value={value}
          onChange={onChange}
          label={label}
          checked={checked} />
      );
    case TYPE_RADIO:
      return (
        <Radio name={name}
          disabled={disabled}
          id={id}
          value={value}
          onClick={onChange}
          label={label}
          checked={checked} />
      );
    default:
      return null;
  }
};

SearchAndSelectModalOption.TYPE_CHECKBOX = TYPE_CHECKBOX;
SearchAndSelectModalOption.TYPE_RADIO = TYPE_RADIO;

SearchAndSelectModalOption.defaultProps = {
  disabled: false,
};

SearchAndSelectModalOption.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  checked: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([TYPE_CHECKBOX, TYPE_RADIO]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
};

module.exports = SearchAndSelectModalOption;
