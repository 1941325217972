/* eslint-disable consistent-return,react/no-array-index-key */
const React = require('react');
const ReadOnlyField = require('@common/material-input/SidebarReadOnlyField.react');
const TravelOfficeSection = require('../../../containers/sidebar/TravelOfficeSection/TravelOfficeSection');
const FileSection = require('../../../containers/sidebar/FileSection/FileSection');
const Date = require('../DateController.react');

module.exports = class CarReadOnlyDetails extends React.Component {
  getEmployeesInfo() {
    if (this.props.travel.employees) {
      return this.props.travel.employees.map((employee, index) => (
        <div key={index}>
          <div key={index}>
            <ReadOnlyField placeholder="Name" value={employee.name}>
              <span className="wethod-icon wethod-icon-pm" />
            </ReadOnlyField>
            <ReadOnlyField placeholder="Surname" value={employee.surname}>
              <span />
            </ReadOnlyField>
          </div>
        </div>
      ));
    }
  }

  render() {
    return (
      <div>
        <ReadOnlyField placeholder="From" value={this.props.travel.from}>
          <span className="wethod-icon wethod-icon-location wethod-icon-location--black" />
        </ReadOnlyField>
        <ReadOnlyField placeholder="To" value={this.props.travel.to}>
          <span />
        </ReadOnlyField>
        <Date editable={false}
          isRoundTrip
          date={this.props.travel.date}
          return_date={this.props.travel.return_date} />
        {this.getEmployeesInfo()}
        <ReadOnlyField placeholder="Additional requests" value={this.props.travel.additional_requests}>
          <span className="wethod-icon wethod-icon-description wethod-icon-description--black" />
        </ReadOnlyField>
        <TravelOfficeSection travel={this.props.travel} editMode={false} />
        <FileSection editMode={false} carrier={this.props.travel} />
      </div>
    );
  }
};
