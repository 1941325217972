/* eslint-disable react/prop-types,class-methods-use-this,jsx-a11y/anchor-is-valid */
const React = require('react');
const $ = require('jquery');

class Modal extends React.Component {
  /**
   * Places the modal vertically & horizontally center aligned.
   */
  getStyle() {
    const modalW = 350;
    const modalH = 200;
    const contextW = $(window).width();
    const contextH = $(window).height();
    const posLeft = (contextW - modalW) / 2;
    const posTop = (contextH - modalH) / 2;

    return { left: posLeft, top: posTop, display: 'block' };
  }

  render() {
    if (this.props.show) {
      return (
        <div className="modal-wrapper" style={this.getStyle()}>
          <div className="modal__title">
            <div className="modal__title-content">{this.props.title}</div>
            <a href="#" className="modal__close" onClick={this.props.handleClose} role="button" aria-label="Close">x</a>
          </div>
          <div className="modal__body">
            <div className="modal__body-content">
              {this.props.body}
            </div>
          </div>
          <div className="modal__actions">
            {this.props.actions}
          </div>
          <div className="modal__feedback">
            {this.props.feedback}
          </div>
        </div>
      );
    }
    return null;
  }
}

module.exports = Modal;
