const React = require('react');
const moment = require('moment');
const Typography = require('@common/Typography/Typography.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const OutlinedTag = require('@common/Tag/OutlinedTag.react');
const Button = require('@common/Button/RoundedButton.react');
const status = require('../../segmentsStatus');
const { getTimelineLabel } = require('../../timelines');

const DashboardHeader = ({ selectedSegment, editStatus, editPeriod, permissions }) => {
  const getFormattedDate = (date) => {
    if (!date) return '-';
    const dateObj = moment(date);
    return dateObj.format('MMM YYYY');
  };

  const getFormattedRange = () => {
    if (!selectedSegment) return '';
    const from = getFormattedDate(selectedSegment.from);
    const to = getFormattedDate(selectedSegment.to);

    return selectedSegment.from
      ? `${from} - ${to}`
      : `Up to ${to}`;
  };

  function handleEditStatus() {
    editStatus(selectedSegment);
  }

  function handleEditSegment() {
    editPeriod(selectedSegment);
  }

  const isEditVisible = () => selectedSegment
    && selectedSegment.status !== status.STATUS.FREE
    && selectedSegment.status !== status.STATUS.FROZEN;

  // Permissions for editing segment
  const canEditSegment = () => isEditVisible()
    && permissions[status.PERMISSIONS[selectedSegment?.status]];

  // Permissions for editing status. If the segment is paused, we need to check if the user has
  // permissions to freeze the segment.
  const canEditStatus = () => isEditVisible()
    && (permissions[status.PERMISSIONS[selectedSegment?.status]]
    || (selectedSegment?.status === status.STATUS.PAUSED
        && permissions[status.PERMISSIONS[status.STATUS.FROZEN]])
    );

  return (
    <div className="data-freezing-engine-mb--l data-freezing-engine__dashboard-header">
      <div className="data-freezing-engine__dashboard-header-title">
        <Typography className="data-freezing-engine-mb--s"
          component={Typography.COMPONENTS.H2}
          size={Typography.SIZES.PX18}
          weight={Typography.WEIGHTS.SEMIBOLD}>
          Data Review Dashboard
        </Typography>
        <ShowIf condition={!!selectedSegment}>
          <div>
            <Typography
              className="data-freezing-engine-mb--xs"
              component={Typography.COMPONENTS.H2}
              size={Typography.SIZES.PX18}
              weight={Typography.WEIGHTS.LIGHT}>
              {getTimelineLabel(selectedSegment?.timeline)}
            </Typography>
            <div className="data-freezing-engine-mb--s data-freezing-engine__dashboard-header-subtitle">
              <Typography
                component={Typography.COMPONENTS.H2}
                size={Typography.SIZES.PX18}
                weight={Typography.WEIGHTS.LIGHT}>
                {getFormattedRange()}
              </Typography>
              <OutlinedTag upperCase
                inline
                fontSize={Typography.SIZES.PX10}
                color={
                  DashboardHeader.statusColor[selectedSegment?.status]
                  || OutlinedTag.COLORS.DUSTY_GRAY
                }
                borderRadius={OutlinedTag.BORDER_RADIUS.SQUARED}>
                {status.LABEL[selectedSegment?.status] || ''}
              </OutlinedTag>
            </div>
          </div>
        </ShowIf>
        <Typography
          component={Typography.COMPONENTS.P}
          color={Typography.COLORS.DUSTY_GRAY}>
          Create segment from the timeline above to check on data before changing data edit status.
        </Typography>
      </div>
      <ShowIf condition={isEditVisible()}>
        <Button onClick={handleEditStatus} disabled={!canEditStatus()}>Edit Status</Button>
      </ShowIf>
      <ShowIf condition={isEditVisible()}>
        <Button onClick={handleEditSegment} disabled={!canEditSegment()}>Edit Segment</Button>
      </ShowIf>
    </div>
  );
};

DashboardHeader.statusColor = {
  [status.STATUS.FREE]: OutlinedTag.COLORS.GARDEN_GREEN,
  [status.STATUS.IN_REVIEW]: OutlinedTag.COLORS.BRIGHT_SKY_BLUE,
  [status.STATUS.PAUSED]: OutlinedTag.COLORS.SUNSET_ORANGE,
  [status.STATUS.FROZEN]: OutlinedTag.COLORS.DUSTY_GRAY,
};

module.exports = DashboardHeader;
