const React = require('react');
const YearNavigator = require('@common/YearNavigator/YearNavigator.react');
const QuarterFilter = require('@common/FilterSelect/FilterSelect.react');

const Filters = ({
  quarters,
  chooseQuartersFilter,
  year,
  currentQuarter,
  employee,
  getObjectives,
}) => {
  function onYearChange(changedYear) {
    getObjectives(changedYear, employee);
  }

  const getCurrentQuarter = () => {
    for (let i = 0; i < quarters.length; i++) {
      if (quarters[i].quarterId === currentQuarter) return quarters[i].name;
    }
    return quarters[0].name;
  };

  return (
    <div className="profile-objectives__filters">
      <QuarterFilter
        current={getCurrentQuarter()}
        title="Select a quarter"
        items={quarters}
        onFilterChosen={chooseQuartersFilter} />
      <YearNavigator
        year={year}
        changeDate={onYearChange} />
    </div>
  );
};
module.exports = Filters;
