const { connect } = require('react-redux');
const TypeSwitcher = require('@common/TabSwitcher.react');

const mapStateToProps = (state) => ({
  items: [
    {
      key: 0,
      name: 'Auto',
    }, {
      key: 1,
      name: 'Manual',
    },
  ],
  current: state.plan.status,
});

module.exports = connect(mapStateToProps, null)(TypeSwitcher);
