const React = require('react');
const FilledTag = require('@common/Tag/FilledTag.react');
const Accordion = require('../DashboardAccordion.react');
const Alerts = require('./Alerts.react');

const AlertAccordion = ({ title, subtitle, tag, counter }) => (
  <Accordion title={title}
    subtitle={subtitle}
    tag={tag}
    counter={counter}
    counterColor={FilledTag.COLORS.BRIGHT_SKY_BLUE}>
    <Alerts />
  </Accordion>
);

module.exports = AlertAccordion;
