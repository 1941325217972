const React = require('react');
const TabSwitcher = require('@common/TabSwitcher.react');

const TypeSwitcher = ({ current, size, keyword, bu, handleClick }) => {
  const getItems = () => {
    const items = [{
      key: 'mine',
      name: 'Mine',
    }];

    // Only show 'Others' selector when the user has permission to see others production plan
    if (Wethod.PlanningApp.getPermission('view_other')) {
      items.push({
        key: 'others',
        name: 'Others',
      });
    }

    items.push({
      key: 'archived',
      name: 'Archived',
    });

    return items;
  };

  function onClick(filter) {
    handleClick(size, keyword, filter, bu);
  }

  return (
    <TabSwitcher current={current} items={getItems()} handleClick={onClick} />
  );
};

module.exports = TypeSwitcher;
