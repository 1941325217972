const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const Loading = require('./Loading.react');
const Steps = require('../containers/Steps');
const Modal = require('../containers/Modal');

module.exports = class ProjectReview extends React.Component {
  componentDidMount() {
    this.props.getReview(this.props.id);
  }

  getContent() {
    if (this.props.loading) {
      return <Loading />;
    }
    if (this.props.notFoundError) {
      Wethod.trigger('url:404:show'); // Lanciare anche se idProject, idReview non sono coerenti tra loro
      return null;
    }
    return <Steps />;
  }

  render() {
    return (
      <div className="project-review wethod-section-body">
        <SectionHeader current_section="Project & Team Review" helper_url="pipeline/index/#project-team-review" />
        {this.getContent()}
        <Modal />
      </div>
    );
  }
};
