const React = require('react');
const Modal = require('@common/modals/ErrorModal.react');

const MissingColumnModal = ({ data, onClose }) => {
  function getMissingColumns() {
    if (data && data.length) {
      return data.map((column) => <li key={column}>{column}</li>);
    }
    return null;
  }

  function getBody() {
    return (
      <div>
        The following <b>columns are missing</b> from the template provided.<br />
        Please double check and upload again.
        <br />
        <ul className="time-off__modal-error-list">
          {getMissingColumns()}
        </ul>
      </div>
    );
  }

  return (
    <Modal data={getBody()} onCancelClick={onClose} />
  );
};

module.exports = MissingColumnModal;
