const React = require('react');
const constants = require('../constants');

/**
 * Debounce the given callback.
 * Passing a callback id you can group callback by it and debounce only callback belonging to same group.
 * @type {(function(*, *): void)|*}
 */
const debounce = (callback, id = 0) => {
  debounce.timers = debounce?.timers ?? {};
  clearTimeout(debounce.timers[id]);
  debounce.timers[id] = setTimeout(callback, 500);
};

/**
 * Higher-order components used to inject API requests into child component.
 *
 * Real API requests are implemented in actions.js, each action must then be injected in components
 * that use them (this lead to duplication of code).
 * Instead of injecting the same action in different components you can inject the action in
 * this HOC and then wrap components using this HOC.
 * This is especially useful when API request needs some logic in order to be called, i.e. if
 * request need budget id as attribute, you can inject it here instead of injecting budget id
 * in each component using this API.
 *
 * @param children
 * @param waitingFor
 * @param projectId
 * @param clientId
 * @param consumptionReportRequest
 * @param budgetInfo
 * @param updateDiscountRequest
 * @param budgetId
 * @param updateInfo
 * @param applyPsCorrection
 * @param approversRequest
 * @param changeStatus
 * @param getVersionRequest
 * @param updateTaskInfoRequest
 * @param updateTaskLevelAmountRequest
 * @param createTaskRequest
 * @param createAreaRequest
 * @param deleteTaskRequest
 * @param updateAreaRequest
 * @param makeTaskIntercompanyRequest
 * @param inviteIntercompanySupplierRequest
 * @param getVersionComparisonRequest
 * @param updateTaskOrderRequest
 * @param getIntercompanyTaskInfoRequest
 * @param getVersionListRequest
 * @param getProjectStatusCorrectionRequest
 * @param insertJobTitleRequest
 * @param deleteJobTitleRequest
 * @param insertProductsRequest
 * @param updatePricelistRequest
 * @param updateAreaPriceListRequest
 * @param getPriceListLevelsRequest
 * @param rest
 * @return {React.FunctionComponentElement<unknown>}
 * @constructor
 */
const RequestManager = ({
  children,
  waitingFor,
  projectId,
  clientId,
  consumptionReportRequest,
  budgetInfo,
  updateDiscountRequest,
  budgetId,
  updateInfo,
  applyPsCorrection,
  approversRequest,
  changeStatus,
  getVersionRequest,
  updateTaskInfoRequest,
  updateTaskLevelAmountRequest,
  createTaskRequest,
  createAreaRequest,
  deleteTaskRequest,
  updateAreaRequest,
  makeTaskIntercompanyRequest,
  inviteIntercompanySupplierRequest,
  getVersionComparisonRequest,
  updateTaskOrderRequest,
  getIntercompanyTaskInfoRequest,
  getVersionListRequest,
  getProjectStatusCorrectionRequest,
  insertJobTitleRequest,
  deleteJobTitleRequest,
  insertProductsRequest,
  updatePricelistRequest,
  updateAreaPriceListRequest,
  getPriceListLevelsRequest,
  ...rest
}) => {
  const isPending = (label) => waitingFor.indexOf(label) !== -1;

  const getConsumptionReport = () => consumptionReportRequest(projectId);

  const saveInfo = (info = {}, optimistic = false) => debounce(() => {
    updateInfo(projectId, budgetId, info, optimistic);
  });

  const saveInfoOptimistic = (info = {}) => saveInfo(info, true);

  const applyProjectStatusCorrection = () => {
    applyPsCorrection(budgetId);
  };

  const getAvailableApprovers = () => approversRequest(projectId);

  const submitBudget = (message, approverId) => changeStatus(projectId, budgetId,
    constants.BUDGET_STATUS_ACTION_SEND, message, approverId);
  const approveBudget = (message) => changeStatus(projectId, budgetId,
    constants.BUDGET_STATUS_ACTION_APPROVE, message);
  const rejectBudget = (message) => changeStatus(projectId, budgetId,
    constants.BUDGET_STATUS_ACTION_REJECT, message);
  const draftBudget = () => changeStatus(projectId, budgetId,
    constants.BUDGET_STATUS_ACTION_RECALL);

  const updateTaskInfo = (taskId, taskInfo) => debounce(() => updateTaskInfoRequest(taskId,
    taskInfo, projectId), taskId);

  const updateTaskLevel = (taskLevelAmountId, levelId, budgetTaskId,
    days) => debounce(
    () => updateTaskLevelAmountRequest(taskLevelAmountId, levelId, budgetTaskId,
      days, projectId), taskLevelAmountId,
  );

  const createTask = (areaId, taskInfo) => {
    createTaskRequest(projectId, areaId, taskInfo);
  };

  const createArea = (name) => {
    createAreaRequest(name, projectId);
  };

  const deleteTask = (taskId) => {
    deleteTaskRequest(projectId, budgetId, taskId);
  };

  const deleteJobTitle = (id, taskId, jobTitleId) => {
    deleteJobTitleRequest(id, taskId, jobTitleId, projectId);
  };

  const saveTaskOrder = (positions) => {
    updateTaskOrderRequest(positions, projectId, budgetId);
  };

  const updateArea = (id, changes) => debounce(() => updateAreaRequest(id, changes, projectId));

  const makeTaskIntercompany = (task, supplierId) => {
    makeTaskIntercompanyRequest(task, supplierId);
  };

  const inviteIntercompanySupplier = (areaId, taskId, supplierId) => {
    inviteIntercompanySupplierRequest(areaId, taskId, supplierId);
  };

  const getIntercompanyTaskInfo = (taskId) => getIntercompanyTaskInfoRequest(taskId);

  const getVersion = (version) => getVersionRequest(projectId, version);
  const getVersionList = () => getVersionListRequest(projectId);

  const getVersionComparison = (versions) => debounce(() => getVersionComparisonRequest(projectId,
    versions));

  const getProjectStatusCorrection = () => getProjectStatusCorrectionRequest(
    budgetId,
  );

  const insertJobTitle = (taskId, jobTitleId) => insertJobTitleRequest(
    taskId, jobTitleId, projectId,
  );

  const insertProducts = (areaId, products) => insertProductsRequest(
    areaId, products, projectId,
  );

  const updatePricelist = (pricelistId) => updatePricelistRequest(projectId, budgetId, pricelistId);

  const updateAreaPriceList = (pricelistId, areaId) => updateAreaPriceListRequest(
    pricelistId, areaId,
  );

  const getPriceListLevels = () => getPriceListLevelsRequest(projectId, clientId);

  return React.cloneElement(children, {
    ...rest,
    isPending,
    getConsumptionReport,
    saveInfo,
    saveInfoOptimistic,
    applyProjectStatusCorrection,
    getAvailableApprovers,
    submitBudget,
    approveBudget,
    rejectBudget,
    draftBudget,
    getVersion,
    updateTaskInfo,
    updateTaskLevel,
    createTask,
    createArea,
    deleteTask,
    updateArea,
    makeTaskIntercompany,
    inviteIntercompanySupplier,
    getVersionComparison,
    saveTaskOrder,
    getIntercompanyTaskInfo,
    getVersionList,
    getProjectStatusCorrection,
    insertJobTitle,
    deleteJobTitle,
    insertProducts,
    updatePricelist,
    updateAreaPriceList,
    getPriceListLevels,
  });
};

module.exports = RequestManager;
