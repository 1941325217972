const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const StatusButton = require('@common/ColoredButton.react');
const Tag = require('@common/Tag/Tag.react');
const Rating = require('@common/Rating/SmallRating.react');
const IconLink = require('@common/TooltipFixed/IconLinkTooltip.react');
const StatusList = require('./SupplierStatusList.react');

module.exports = class Supplier extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  handleSidebarOpen() {
    this.props.showSidebar(this.props.supplier);
  }

  handleStatusChange(status) {
    if (status && status.id !== 'no-status') {
      const updatedSupplier = {
        ...this.props.supplier,
        status,
      };
      this.props.updateSupplier(updatedSupplier);
    }
    this.hideMenu();
  }

  getStatusColor() {
    return this.props.supplier.status ? this.props.supplier.status.color : '';
  }

  getStatusValue() {
    return this.props.supplier.status ? this.props.supplier.status.name : '';
  }

  getTagCellStyle() {
    let style = 'suppliers__column--tag';
    if (!this.props.statusEnabled && !this.props.showRating) {
      style += ' suppliers__column--tag-large';
    } else if (!this.props.statusEnabled || !this.props.showRating) {
      style += ' suppliers__column--tag-medium';
    }

    return style;
  }

  getStatusCellStyle() {
    let style = 'suppliers__column--status';
    if (!this.canChangeStatus()) {
      style += ' suppliers__status--not-editable';
    }
    return style;
  }

  getStatusItems() {
    if (this.props.statuses && this.props.statuses.length > 0) {
      return this.props.statuses;
    }
    return [{
      id: 'no-status',
      name: 'No status available',
    }];
  }

  getStatusCell() {
    return (
      <TableCell key="status"
        name="status"
        className={this.getStatusCellStyle()}>
        <StatusButton setRef={this.setRef.bind(this)}
          value={this.getStatusValue()}
          color={this.getStatusColor()}
          onClick={this.showMenu.bind(this)}
          disabled={this.props.isWaiting} />
        <StatusList open={this.state.showMenu}
          anchorEl={this.statusButton}
          onClose={this.hideMenu.bind(this)}
          statusList={this.getStatusItems()}
          onStatusChange={this.handleStatusChange.bind(this)} />
      </TableCell>
    );
  }

  getTagList() {
    if (this.props.supplier.tags) {
      return this.props.supplier.tags.map((tag) => (
        <Tag key={tag.id}>{tag.name}</Tag>
      ));
    }

    return null;
  }

  getRating() {
    const value = this.props.supplier.rating;

    if (value) {
      return <Rating value={this.props.supplier.rating} max={5} readOnly showNumericValue />;
    }
    return null;
  }

  getCellList() {
    const cellList = []
      .concat(
        <TableCell key="name"
          name="name"
          className="suppliers__column--name">
          {this.props.supplier.name}
        </TableCell>,
      )
      .concat(
        <TableCell key="email"
          name="email"
          className="suppliers__column--email">
          {this.props.supplier.email}
        </TableCell>,
      )
      .concat(
        <TableCell key="tag"
          name="tag"
          className={this.getTagCellStyle()}>
          {this.getTagList()}
        </TableCell>,
      );

    if (this.props.showRating) {
      cellList.push(
        <TableCell key="rating"
          name="rating"
          className="suppliers__column--rating">
          {this.getRating()}
        </TableCell>,
      );
    }

    if (this.props.statusEnabled) {
      cellList.push(this.getStatusCell());
    }

    cellList.push(
      <TableCell key="actions"
        className="suppliers__column--actions">
        <IconLink label="Open details"
          className="chargeability__button"
          borderless
          href={`/#finance/suppliers/${this.props.supplier.id}`}
          target="_blank">
          <span className="wethod-icon wethod-icon-arrow-right wethod-icon-arrow-right--black" />
        </IconLink>
      </TableCell>,
    );

    return cellList;
  }

  setRef(element) {
    this.statusButton = element;
  }

  showMenu() {
    this.setState({ showMenu: true });
  }

  hideMenu() {
    this.setState({ showMenu: false });
  }

  canChangeStatus() {
    return !this.props.isWaiting && this.props.canSetStatus;
  }

  render() {
    return (
      <TableRow>
        {this.getCellList()}
      </TableRow>
    );
  }
};
