const React = require('react');
const { CSSTransition } = require('react-transition-group');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const Sidebar = require('../containers/sidebar/ProductSidebar');
const SaveManager = require('../containers/SaveManager');

const SidebarController = ({ showSidebar }) => (
  <CSSTransition in={showSidebar}
    classNames="wethod-sidebar--transition"
    timeout={400}
    mountOnEnter
    unmountOnExit>
    <FormValidator>
      <SaveManager>
        <Sidebar />
      </SaveManager>
    </FormValidator>
  </CSSTransition>
);

module.exports = SidebarController;
