const React = require('react');
const JobOrderTemplatesTypes = require('@models/JobOrderTemplate').types;
const Block = require('./PlaceholderBlock.react');

module.exports = class PlaceholderBlocksList extends React.Component {
  componentDidMount() {
    this.props.addContainer(this.listEl);
  }

  render() {
    return (
      <div data-id="template-blocks"
        ref={(el) => this.listEl = el}>
        <Block type={JobOrderTemplatesTypes.text}>Text</Block>
        <Block type={JobOrderTemplatesTypes.date} format={2}>Year (YY)</Block>
        <Block type={JobOrderTemplatesTypes.date} format={4}>Year (YYYY)</Block>
      </div>
    );
  }
};
