/* eslint-disable react/jsx-no-bind */
const React = require('react');
const Modal = require('@common/modal2/Modal.react');

const ConfirmDeleteModal = ({ data, onCancelClick, isWaiting, onConfirmClick }) => {
  const handleCancelClick = () => {
    if (!isWaiting) onCancelClick();
  };

  const handleConfirmClick = () => {
    if (!isWaiting) {
      const request = data && data.review && data.review ? data.review : null;
      onConfirmClick(request);
      onCancelClick();
    }
  };

  const getFeedback = () => {
    if (isWaiting) return <span className="wethod-modal2__feedback">Deleting...</span>;
    return '';
  };

  const getActionsStyle = () => {
    let style = 'profile-contact-info__actions';
    if (isWaiting) style += ' disabled';
    return style;
  };

  return (
    <Modal title="Delete request" onCancelClick={handleCancelClick} className="review-changelog__modal">
      <span>You are about to <b>delete this request and its results.</b><br />
        Are you sure you want to continue?
      </span>
      <div className={getActionsStyle()}>
        <button type="button" onClick={handleCancelClick} className="wethod-button">Cancel</button>
        <button type="button" onClick={handleConfirmClick} className="wethod-button wethod-button--warning">Yes, delete</button>
      </div>
      {getFeedback()}
    </Modal>
  );
};

module.exports = ConfirmDeleteModal;
