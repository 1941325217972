const React = require('react');
const Timeline = require('../../containers/timeline/Timeline');

const TimelineBody = ({ visibleMonths, timelines }) => {
  function getTimelines() {
    return timelines.map((timeline) => (
      <Timeline key={timeline.name} timeline={timeline} visibleMonths={visibleMonths} />
    ));
  }

  return (
    <div className="segment-timeline__body">
      {getTimelines()}
    </div>
  );
};

module.exports = TimelineBody;
