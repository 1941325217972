/* eslint-disable no-param-reassign */

const React = require('react');
const SearchSelect = require('@common/material-input/SidebarSearchSelectAddButton.react');
const Loading = require('@common/LoadingSmall.react');

module.exports = class IntercompanyIdSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: this.props.value,
      waiting: true,
      items: [],
    };
  }

  componentDidMount() {
    $.when(Wethod.request('get:companies:connectable')).done((companies) => this.setState({
      items: companies,
      waiting: false,
    }));
  }

  onChange(name, value) {
    value = value !== null ? value.id : null;
    return this.props.onChange(name, value);
  }

  render() {
    if (this.state.waiting) {
      return <Loading />;
    }
    return (
      <SearchSelect
        name={this.props.name}
        value={this.state.selectedItem}
        placeholder={this.props.placeholder}
        items={this.state.items}
        onChange={this.onChange.bind(this)}
        onValidate={this.props.onValidate}
        required={this.props.required}>
        {this.props.children}
      </SearchSelect>
    );
  }
};
