/* eslint-disable react/sort-comp,react/no-array-index-key,react/no-access-state-in-setstate */
const React = require('react');
const Card = require('@common/CollapsibleCard.react');
const Person = require('./Person.react');

module.exports = class People extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
    };
  }

  componentDidMount() {
    if (this.props.people.length === 0) this.setState({ collapsed: true });
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  getPeople() {
    return this.props.people.map((person, index) => <Person key={index} {...person} />);
  }

  render() {
    return (
      <Card
        title="People on this project"
        collapsed={this.state.collapsed}
        toggleCollapsed={this.toggleCollapsed.bind(this)}>
        <div>
          {this.getPeople()}
        </div>
      </Card>
    );
  }
};
