const { connect } = require('react-redux');
const Component = require('@common/TabSwitcher.react');
const actions = require('../actions');

const items = [
  {
    key: 'true',
    name: 'Available',
  }, {
    key: 'false',
    name: 'Unavailable',
  },
];

const mapStateToProps = (state) => ({
  items,
  current: state.available,
  disabled: state.waitingFor.filter((key) => key === 'get-pricelists').length > 0,
});

const mapDispatchToProps = (dispatch) => ({
  handleClick: (key) => dispatch(actions.changeAvailability(key)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
