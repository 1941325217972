const React = require('react');
const Table2 = require('@common/Table2/Table2.react');
const TableBody = require('@common/Table2/TableBody.react');
const TableHead = require('@common/Table2/TableHead.react');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const BodyRow = require('../../containers/company-holidays/CompanyHolidaysTableBodyRow');
const HeaderRow = require('./CompanyHolidaysTableHeaderRow.react');

module.exports = class CompanyHolidaysTable extends React.Component {
  static loadMore() {
    return null;
  }

  static getEmptyMessage() {
    return <span>NO COMPANY HOLIDAY SET YET</span>;
  }

  constructor(props) {
    super(props);

    this.state = { tableHeight: 375 };
  }

  getRows() {
    return this.props.items
      .map((item) => (
        <FormValidator key={item.id}>
          <BodyRow item={item} />
        </FormValidator>
      ));
  }

  hasMore() {
    return this.props.isWaiting;
  }

  render() {
    return (
      <div ref={(ref) => this.containerRef = ref} className="company-settings__table">
        <Table2 maxHeight={this.state.tableHeight}>
          <TableHead>
            <HeaderRow />
          </TableHead>
          <TableBody editable
            hasMore={this.hasMore()}
            loadMore={CompanyHolidaysTable.loadMore}
            empty={CompanyHolidaysTable.getEmptyMessage()}>
            {this.getRows()}
          </TableBody>
        </Table2>
      </div>
    );
  }
};
