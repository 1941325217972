const React = require('react');
const { CSSTransition } = require('react-transition-group');
const FormValidator = require('@common/FormValidator/FormValidator.react');
const Sidebar = require('../containers/sidebar/ItemSidebar');
const PermissionManager = require('../containers/PermissionManager');
const SaveManager = require('../containers/SaveManager');

const SidebarController = ({ showSidebar, item }) => (
  <CSSTransition in={showSidebar}
    classNames="wethod-sidebar--transition"
    timeout={400}
    mountOnEnter
    unmountOnExit>
    <FormValidator>
      <PermissionManager>
        <SaveManager item={item}>
          <Sidebar item={item} />
        </SaveManager>
      </PermissionManager>
    </FormValidator>
  </CSSTransition>
);

module.exports = SidebarController;
