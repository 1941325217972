const React = require('react');
const Modal = require('@common/Modal/Modal.react');
const Content = require('./ProjectStatusCorrectionModalContent.react');
const RequestManager = require('../../../../../containers/RequestManager');

const ProjectStatusCorrectionModal = ({ closeModal, correctionToApply }) => (
  <Modal title="Project status correction" onClose={closeModal}>
    <RequestManager>
      <Content correctionToApply={correctionToApply} closeModal={closeModal} />
    </RequestManager>
  </Modal>
);

module.exports = ProjectStatusCorrectionModal;
