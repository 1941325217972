const request = require('./Request');

const DataFreezing = {
  list() {
    const url = '/data-freezing/timelines';

    return request({
      method: 'get',
      url,
    });
  },
  getWarningsTotal(from, to) {
    let url = '/data-freezing/warnings-total?';

    url += from ? `from=${from}&` : '';
    url += to ? `to=${to}` : '';

    return request({
      method: 'get',
      url,
    });
  },
  updateSegments(segments, timelineName) {
    const url = '/data-freezing/timeline';

    return request({
      method: 'PUT',
      url,
      data: {
        segments,
        name: timelineName,
      },
    });
  },
};

module.exports = DataFreezing;
