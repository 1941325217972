const React = require('react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');
const Footer = require('@common/Modal/ModalFooter.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const Modal = require('@common/Modal/Modal.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const DailyHours = require('../containers/WeeklyHoursModalBody');
const CapacityManager = require('../containers/CapacityManager');
const FeatureService = require('../../services/FeatureService');

const WeeklyHoursModal = ({ show, closeModal }) => {
  if (!show) {
    return null;
  }
  const getHours = () => {
    if (FeatureService.isEmployeeCapacityEnabled()) {
      return (
        <CapacityManager>
          <DailyHours />
        </CapacityManager>
      );
    }
    return <DailyHours />;
  };

  return (
    <Modal title="Hours in this week" onClose={closeModal} className="timesheet-detailed__weekly-hours-modal-wrapper">
      <ModalContent>
        <ModalBody>
          <ModalBodyBlock>
            {getHours()}
          </ModalBodyBlock>
        </ModalBody>
        <Footer>
          <FooterAction onClick={closeModal}>
            Close
          </FooterAction>
        </Footer>
      </ModalContent>
    </Modal>
  );
};

module.exports = WeeklyHoursModal;
