/* eslint-disable react/prefer-stateless-function */
const React = require('react');
const Avatar = require('@common/AvatarBox/Avatar.react');
const FormatService = require('../../../../../../../services/FormatService');

module.exports = class CardMember extends React.Component {
  render() {
    const name = `${this.props.name} ${this.props.surname}`;
    return (
      <div className="project-canvas-kanban__card-members-item">
        <span className="project-canvas-kanban__card-members-item-pic project-canvas-menu-members__pic">
          <Avatar name={name} pic={this.props.pic} />
        </span>
        <span className="project-canvas-kanban__card-members-item-name">
          {FormatService.formatOneLetterPersonName(name)}
        </span>
      </div>
    );
  }
};
