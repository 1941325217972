/* eslint-disable react/sort-comp,class-methods-use-this,react/no-did-update-set-state,react/no-access-state-in-setstate,consistent-return,react/jsx-no-bind,no-case-declarations */
const React = require('react');
const Menu = require('@common/Menu/Menu.react');
const MilestoneDateMenu = require('../Spreadsheet/ItemCell/MilestoneItemCell/MilestoneMenu/MilestoneDateMenu.react');
const MilestoneListMenu = require('../Spreadsheet/ItemCell/MilestoneItemCell/MilestoneMenu/MilestoneListMenu.react');
const { formatDate } = require('../../../../../../../services/FormatService');

module.exports = class MilestoneSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      showMilestoneList: false,
      updatedMilestone: this.props.value,
    };
  }

  needsUpdate() {
    if (!this.props.value) { // Save changes if the milestone info are completed
      return Boolean(this.state.updatedMilestone && this.state.updatedMilestone.type
        && this.state.updatedMilestone.date);
    } // Save changes if some info of the milestone has actually changed
    return this.state.updatedMilestone.type !== this.props.value.type
      || !moment(this.state.updatedMilestone.date).isSame(this.props.value.date);
  }

  onCloseMenu() {
    if (this.needsUpdate()) {
      this.props.onChange(this.props.name, this.state.updatedMilestone);
      this.setState({ showMenu: false });
    } else {
      this.setState({
        showMenu: false,
        updatedMilestone: this.props.value,
      });
    }
  }

  onMenuButtonClick() {
    if (!this.props.eventTypes && !this.props.isLoading) {
      this.props.getEventTypes();
    }

    this.setState({ showMenu: true });
  }

  onMilestoneClick() {
    this.setState({ showMilestoneList: true });
  }

  handleDelete(e) {
    e.stopPropagation();

    this.props.onChange(this.props.name, null);

    this.setState({
      updatedMilestone: null,
      showMilestoneList: false,
    });
  }

  handleMilestoneChange(type) {
    this.setState(
      {
        updatedMilestone: {
          ...this.state.updatedMilestone,
          type,
        },
        showMilestoneList: false,
      },
      () => {
        if (this.state.updatedMilestone && this.state.updatedMilestone.date) {
          this.onCloseMenu();
        }
      },
    );
  }

  handleDateChange(day) {
    this.setState(
      {
        updatedMilestone: {
          ...this.state.updatedMilestone,
          date: day,
        },
        showMilestoneList: false,
      },
      () => {
        this.onCloseMenu();
      },
    );
  }

  getMenuBody() {
    if (!this.state.updatedMilestone || this.state.showMilestoneList) {
      return (
        <MilestoneListMenu isLoading={this.props.isLoading}
          eventTypes={this.props.eventTypes}
          value={this.state.updatedMilestone}
          onChange={this.handleMilestoneChange.bind(this)} />
      );
    }
    return (
      <MilestoneDateMenu isLoading={this.props.isLoading}
        value={this.state.updatedMilestone}
        onMilestoneClick={this.onMilestoneClick.bind(this)}
        onChange={this.handleDateChange.bind(this)} />
    );
  }

  getIcon() {
    return `wethod-icon wethod-icon-${this.state.updatedMilestone.type.toLowerCase()}`;
  }

  getDeleteButton() {
    if (this.state.updatedMilestone) {
      return (
        <div className="wethod-icon-button wethod-icon-button--medium project-canvas-kanban-sidebar__delete"
          onClick={this.handleDelete.bind(this)}>
          <div className="wethod-icon wethod-icon-medium-discard wethod-icon-medium-discard--black" />
        </div>
      );
    }
  }

  getFormattedDate() {
    return this.props.value ? formatDate(this.props.value.date) : null;
  }

  render() {
    return (
      <div>
        <button type="button"
          ref={(el) => this.milestoneButton = el}
          className="material-input-button project-canvas-kanban-sidebar__button-input project-canvas-kanban-sidebar__button-input--deletable"
          onClick={this.onMenuButtonClick.bind(this)}>
          <span>{this.getFormattedDate()}</span>
          {this.getDeleteButton()}
        </button>
        <Menu open={this.state.showMenu}
          onClose={this.onCloseMenu.bind(this)}
          anchorEl={this.milestoneButton}>
          {this.getMenuBody()}
        </Menu>
      </div>
    );
  }
};
