const React = require('react');
const SectionHeader = require('@common/SectionHeader/components/SectionHeader.react');
const TeamMember = require('../containers/TeamMember');
const TimeFilter = require('../containers/TimeFilter');
const PM = require('../containers/PM');

module.exports = class Kudos extends React.Component {
  componentDidMount() {
    this.props.getKudos(this.props.timeFilter);
  }

  render() {
    return (
      <div className="wethod-section-body kudos">
        <div className="wethod-section-actions">
          <SectionHeader
            current_section="Kudos"
            helper_url="reports/index/#kudos"
            big />
          <TimeFilter />
        </div>
        <h2 className="kudos__title">As team member</h2>
        <TeamMember />
        <h2 className="kudos__title">As PM</h2>
        <PM />
      </div>
    );
  }
};
