const React = require('react');
const Search = require('@common/SearchInput.react');
const LocationTag = require('@common/Tag/Tag.react');
const LocationResultElement = require('./LocationResultElement.react');

class LocationList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredLocations: this.props.locations,
    };
  }

  componentDidMount() {
    this.setState((prevState) => ({
      filteredLocations: prevState.filteredLocations.filter((location) => (
        !this.props.addedLocations.some((el) => el.id === location.id))),
    }));
  }

  componentDidUpdate(prevProps) {
    const prevLocations = prevProps.addedLocations ? prevProps.addedLocations.length : 0;
    const newLocations = this.props.addedLocations ? this.props.addedLocations.length : 0;
    if (prevLocations !== newLocations) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        filteredLocations: this.props.addedLocations && this.props.addedLocations.length > 0
          ? this.props.locations
            .filter((location) => !this.props.addedLocations.some((el) => el.id === location.id))
          : this.props.locations,
      });
    }
  }

  /**
   * Returns true if value contains key.
   *
   * @param key
   * @param value
   * @returns {boolean}
   */
  // eslint-disable-next-line react/sort-comp,class-methods-use-this
  found(key, value) {
    return value.toLowerCase().indexOf(key.toLowerCase()) !== -1;
  }

  handleLocationFilter(keyword) {
    this.setState({
      filteredLocations: this.props.locations.filter((location) => (
        this.found(keyword, location.name)
        && !this.props.addedLocations.some((el) => el.id === location.id))),
    });
  }

  handleLocationSelect(id) {
    const location = this.props.locations.find((l) => l.id === id);
    if (location) {
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        filteredLocations: this.state.filteredLocations.filter((l) => l.id !== id),
      });
      this.props.onSelect(id);
    }
  }

  handleLocationDelete(locationTagId) {
    const location = this.props.locations.find((l) => l.id === locationTagId);
    if (location) {
      this.setState((prevState) => ({
        filteredLocations: prevState.filteredLocations.concat(location),
      }));
    }
    this.props.onDelete(locationTagId);
  }

  // eslint-disable-next-line consistent-return
  getLocationResultList() {
    if (this.state.filteredLocations && this.state.filteredLocations.length > 0) {
      return (
        this.state.filteredLocations.map((location) => (
          <LocationResultElement key={location.id}
            onClick={this.handleLocationSelect.bind(this)}
            disabled={this.props.isDeleting}
            {...{
              ...location,
            }} />
        ))
      );
    }
    if (this.state.filteredLocations && this.state.filteredLocations.length === 0) {
      return <span className="project-canvas-menu-members__result--empty">No location found</span>;
    }
  }

  getLocationsTagList() {
    if (this.props.addedLocations && this.props.addedLocations.length > 0) {
      return this.props.addedLocations.map((location) => (
        <LocationTag
          key={location.id}
          id={location.id}
          onDelete={this.handleLocationDelete.bind(this, location.id)}>
          {location.name}
        </LocationTag>
      ));
    }
    return <span className="project-canvas-menu-members__result--empty">No locations added</span>;
  }

  render() {
    return (
      <div className="project-canvas-menu project-canvas-menu-members">
        <div className="project-canvas-menu-members__tag-list">
          {this.getLocationsTagList()}
        </div>
        <div className="project-canvas-menu-members__search">
          <Search placeholder="Search location" filter={this.handleLocationFilter.bind(this)} />
          <div className="members-result-list">
            {this.getLocationResultList()}
          </div>
        </div>
      </div>
    );
  }
}

module.exports = LocationList;
