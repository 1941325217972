const React = require('react');
const isEqual = require('react-fast-compare');
const IconButton = require('@common/Button/IconButton.react');
const Button = require('@common/Button/RoundedButton.react');
const NumericInput = require('@common/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const ShowIf = require('@common/ShowIf/ShowIf.react');
const JobOrderTemplatesTypes = require('@models/JobOrderTemplate').types;
const formatWithLeadingZeroes = require('../../../../../../../../services/FormatService').formatNumberWithLeadingZeroes;
const Checkbox = require('../../CheckboxRow.react');
const BlocksArea = require('./BlocksArea.react');
const JobOrderCategoriesSelect = require('./JobOrderCategoriesSelect.react');

module.exports = class Template extends React.Component {
  static getValidNumericValue(value) {
    const formattedValue = parseInt(value);
    const isValid = formattedValue && !Number.isNaN(formattedValue);

    return isValid ? formattedValue : 0;
  }

  static hasChanged(oldTemplate, newTemplate) {
    return !isEqual(oldTemplate, newTemplate);
  }

  constructor(props) {
    super(props);

    this.state = {
      job_order_categories: this.props.template?.job_order_categories ?? [],
      blocks: this.props.template?.blocks ?? [],
      auto_reset: this.props.template.auto_reset || false,
      ...this.props.template,
    };
  }

  componentDidUpdate(prevProps) {
    const oldTemplate = prevProps.template;
    const newTemplate = this.props.template;

    if (Template.hasChanged(oldTemplate, newTemplate)) {
      this.resetTemplate(newTemplate);
    }
  }

  handleDelete() {
    if (!this.props.template.id || this.props.template.id === 'new-template') { // Template is new
      this.props.deleteNewTemplate();
    } else { // Needs confirmation
      this.props.showDeleteModal({ ...this.state });
    }
  }

  handleSave() {
    this.resetError('blocks');

    const template = { ...this.state };

    this.props.onSave(template);
  }

  handleNumberDigitChange(e) {
    const { value } = e.target;
    const formattedValue = Template.getValidNumericValue(value);

    const numberBlock = {
      ...this.getNumberBlock(),
      format: formattedValue,
    };

    const updatedBlocks = this.state.blocks.map((block) => {
      if (block.sort === numberBlock.sort) {
        return {
          ...block,
          ...numberBlock,
          value: formatWithLeadingZeroes(block.value, numberBlock.format),
        };
      }
      return block;
    });
    this.handleTemplateChange('blocks', updatedBlocks);
  }

  handleYearlyResetChange(e) {
    const { checked, name } = e.target;
    this.handleTemplateChange(name, checked);
  }

  handleTemplateChange(name, value) {
    this.resetError('blocks');

    this.setState({
      [name]: value,
    });
  }

  onJocListChange(jocList) {
    // Update the list of joc (if template is new it has custom id)
    this.props.onJobOrderCategoryChange(jocList);
  }

  getNumberBlock() {
    return this.state.blocks.find((el) => el.type === JobOrderTemplatesTypes.number);
  }

  isWaitingForDelete() {
    return this.props.waitingForDelete.some((key) => key.includes(this.props.template.id));
  }

  isWaitingForSave() {
    return this.props.waitingForSave.some((key) => key.includes(this.props.template.id));
  }

  hasError(name) {
    const hasEmptyError = !this.props.errors[name];
    return !hasEmptyError;
  }

  hasYearBlock() {
    const yearBlocks = this.state.blocks.filter((el) => el.type === JobOrderTemplatesTypes.date);

    return yearBlocks && yearBlocks.length > 0;
  }

  resetError(name) {
    this.props.updateErrors(name, []);
  }

  resetTemplate(newTemplate) {
    this.setState({ ...newTemplate });
  }

  canSave() {
    return this.props.isValid;
  }

  render() {
    return (
      <div className="company-settings-jo-template">
        <div className="company-settings-jo-template__inline">
          <JobOrderCategoriesSelect
            jobOrderCategories={this.props.jobOrderCategories}
            template={this.state}
            onJobOrderCategoryChange={this.onJocListChange.bind(this)}
            handleTemplateChange={this.handleTemplateChange.bind(this)}
            onError={this.props.updateErrors} />
          <div className="company-settings-jo-template__inline">
            <IconButton icon="delete"
              label="Delete"
              color="blue"
              size="medium"
              className="company-settings__button-delete"
              onClick={this.handleDelete.bind(this)} />
            <ShowIf condition={Template.hasChanged(this.props.template, { ...this.state })}>
              <Button
                disabled={!this.canSave()}
                className="company-settings__button company-settings__button--blue"
                onClick={this.handleSave.bind(this)}>
                Save
              </Button>
            </ShowIf>
          </div>
        </div>
        <div className="company-settings-jo-template__input-digit">
          <InputValidator updateErrors={this.props.updateErrors} constraints={['required', 'min:1']}>
            <NumericInput id={`${this.props.template.id}-number-format`}
              name="number_format"
              label="Min digit padding"
              value={this.getNumberBlock().format}
              onChange={this.handleNumberDigitChange.bind(this)}
              errorText={this.props.errors.number_format} />
          </InputValidator>
        </div>
        <div className="company-settings__description company-settings-margin-bottom-s">
          Drop template components below then fill the fields with text, numbers, or simbols:
        </div>
        <BlocksArea blocks={this.state.blocks}
          templateId={this.props.template.id}
          addContainer={this.props.addContainer}
          onChange={this.handleTemplateChange.bind(this)}
          onError={this.props.updateErrors}
          ref={this.props.setRef} />
        <Checkbox name="auto_reset"
          id="jo-template-yearly-reset"
          label=""
          value={this.state.auto_reset.toString()}
          checked={this.state.auto_reset}
          border={false}
          rowClassName="company-settings-margin-top-l company-settings-jo-template__checkbox"
          onChange={this.handleYearlyResetChange.bind(this)}
          disabled={!this.hasYearBlock()}
          description="Reset the progressive number automatically when year changes." />
        <ShowIf condition={this.hasError('job_order_categories')}>
          <div className="company-settings-margin-top-l company-settings__feedback wethod-modal2__feedback wethod-modal2__feedback--warning">
            {this.props.errors.job_order_categories}
          </div>
        </ShowIf>
        <ShowIf condition={this.hasError('blocks')}>
          <div className="company-settings-margin-top-l company-settings__feedback wethod-modal2__feedback wethod-modal2__feedback--error">
            {this.props.errors.blocks}
          </div>
        </ShowIf>
        <ShowIf condition={this.isWaitingForDelete()}>
          <div className="company-settings-margin-top-l company-settings__feedback wethod-modal2__feedback">
            Deleting...
          </div>
        </ShowIf>
        <ShowIf condition={this.isWaitingForSave()}>
          <div className="company-settings-margin-top-l company-settings__feedback wethod-modal2__feedback">
            Saving...
          </div>
        </ShowIf>
      </div>
    );
  }
};
