const React = require('react');
const Mobile = require('@common/media-queries/Mobile.react');
const Title = require('../../../containers/sections/Economics/Title');
const Revenues = require('../../../containers/sections/Economics/Revenues');
const RevenuesPerEmployee = require('../../../containers/sections/Economics/RevenuesPerEmployee');
const GrossMarginPerEmployee = require('../../../containers/sections/Economics/GrossMarginPerEmployee');
const ExternalCosts = require('../../../containers/sections/Economics/ExternalCosts');
const Payroll = require('../../../containers/sections/Economics/Payroll');
const GA = require('../../../containers/sections/Economics/GA');
const Marketing = require('../../../containers/sections/Economics/Marketing');
const Capex = require('../../../containers/sections/Economics/Capex');
const RevenuesPerEmployeeChart = require('../../../containers/sections/Economics/RevenuesPerEmployeeChart');
const GrossMarginPerEmployeeChart = require('../../../containers/sections/Economics/GrossMarginPerEmployeeChart');
const ProfitLossChart = require('../../../containers/sections/Economics/ProfitLossChart/ProfitLossChart');
const Section = require('../../../../common/components/Section.react');

module.exports = () => {
  const getCharts = () => (
    <div className="briefing-aside-chart__wrapper">
      <ProfitLossChart />
      <RevenuesPerEmployeeChart />
      <GrossMarginPerEmployeeChart />
    </div>
  );

  return (
    <Section left={getCharts()}>
      <Title />
      <Revenues />
      <RevenuesPerEmployee />
      <ExternalCosts />
      <GrossMarginPerEmployee />
      <Payroll />
      <GA />
      <Marketing />
      <Capex />
      <Mobile>{getCharts()}</Mobile>
    </Section>
  );
};
