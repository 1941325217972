const { connect } = require('react-redux');
const Component = require('../../components/timeline/Timeline.react');
const actions = require('../../actions');

const mapStateToProps = (state) => ({
  selectedSegment: state.selectedSegment,
});

const mapDispatchToProps = (dispatch) => ({
  selectSegment: (segmentStatus, timelineName) => dispatch(actions
    .selectSegment(segmentStatus, timelineName)),
});

module.exports = connect(mapStateToProps, mapDispatchToProps)(Component);
