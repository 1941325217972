const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const Distribution = require('./DistributionChart.react');

const LanguageRow = ({ language, selected, onClick }) => {
  function handleRowClick() {
    onClick(language.name);
  }

  function getRowStyle() {
    let style = 'knowledge__language-row';
    if (selected) style += ' knowledge__language-row--selected';

    return style;
  }

  return (
    <TableRow className={getRowStyle()} onClick={handleRowClick}>
      <TableCell name="language" className="knowledge__column-language">{language.name}</TableCell>
      <TableCell name="distribution" className="knowledge__column-distribution">
        <Distribution data={language.distribution} name={language.name} />
      </TableCell>
    </TableRow>
  );
};

module.exports = LanguageRow;
