const React = require('react');
const TableCell = require('@common/Table2/TableCell.react');

const WhitelistEnabled = ({ enabled, name, style }) => {
  const getIcon = () => (
    enabled
      ? <div className="pipeline__budget-status wethod-icon-medium wethod-icon-medium-checked" />
      : null
  );

  return (
    <TableCell name={name}
      className="pipeline-item__whitelist"
      style={style}>
      {getIcon()}
    </TableCell>
  );
};

module.exports = WhitelistEnabled;
