const React = require('react');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const MonthInput = require('@common/inputs/MonthPicker/OutlinedMonthPicker/OutlinedMonthPicker.react');
const NumericInput = require('@common/inputs/NumericField/OutlinedNumericField/OutlinedNumericField.react');
const Row = require('@common/sidebar/SidebarInputRow.react');
const InputWrapper = require('@common/sidebar/SidebarInputWrapper.react');
const InputMetadataManager = require('@common/InputMetadataManager/InputMetadataManager.react');

const SupplyPeriodInput = ({
  updateErrors,
  start,
  onChange,
  duration,
  readOnly,
  errors,
  metadataService,
}) => {
  const durationGreaterThan = metadataService.getConstraintValue('duration', 'greater_than');
  const getDurationConstraints = () => (durationGreaterThan ? ['required', `min:${durationGreaterThan}`] : ['required']);
  const disableStartBeforeEqual = metadataService.getConstraintValue('start', 'greater_than');
  const startConstraints = disableStartBeforeEqual ? ['required', `minMonth:${disableStartBeforeEqual}`] : ['required'];

  return (
    <Row>
      <InputWrapper icon={
        <span className="wethod-icon wethod-icon-calendar wethod-icon-calendar--black" />
      }>
        <InputValidator updateErrors={updateErrors} constraints={startConstraints}>
          <InputMetadataManager canEdit={!readOnly}
            name="start"
            metadataService={metadataService}>
            <MonthInput
              label="Start"
              id="sidebar-supply-start"
              value={start}
              disableBeforeEqual={disableStartBeforeEqual}
              errorText={errors.start}
              onChange={onChange} />
          </InputMetadataManager>
        </InputValidator>
      </InputWrapper>
      <InputWrapper>
        <InputValidator updateErrors={updateErrors} constraints={getDurationConstraints()}>
          <InputMetadataManager canEdit={!readOnly}
            name="duration"
            metadataService={metadataService}>
            <NumericInput
              id="sidebar-supply-duration"
              label="Months"
              value={duration}
              errorText={errors.duration}
              onChange={onChange} />
          </InputMetadataManager>
        </InputValidator>
      </InputWrapper>
    </Row>
  );
};

module.exports = SupplyPeriodInput;
