const React = require('react');
const ModalContent = require('@common/Modal/ModalContent.react');
const ModalBody = require('@common/Modal/ModalBody.react');
const FooterAction = require('@common/Modal/ModalFooterAction.react');
const Footer = require('@common/Modal/ModalFooter.react');
const ModalBodyBlock = require('@common/Modal/ModalBodyBlock.react');

const ProjectStatusCorrectionModalContent = ({
  correctionToApply, closeModal, applyProjectStatusCorrection, isPending,
}) => {
  const isSaving = () => isPending('apply-ps-correction');

  const feedback = isSaving() ? 'Applying...' : '';

  const getFormattedDayWord = (amount) => {
    if (Math.abs(amount) === 1) {
      return 'day';
    }
    return 'days';
  };

  const getFormattedAmountSentence = () => {
    const days = `${Math.abs(correctionToApply.correction)} ${getFormattedDayWord(correctionToApply.correction)}`;
    if (correctionToApply.correction > 0) {
      return `added ${days}`;
    }
    return `removed ${days}`;
  };

  return (
    <ModalContent>
      <ModalBody>
        <ModalBodyBlock>
          Last approved budget version {getFormattedAmountSentence()}.
        </ModalBodyBlock>
        <ModalBodyBlock>
          Would you like to update accordingly all the Project Status done so far?
        </ModalBodyBlock>
      </ModalBody>
      <Footer feedback={feedback}>
        <FooterAction onClick={closeModal} disabled={isSaving()}>Cancel</FooterAction>
        <FooterAction onClick={applyProjectStatusCorrection} disabled={isSaving()}>OK</FooterAction>
      </Footer>
    </ModalContent>
  );
};

module.exports = ProjectStatusCorrectionModalContent;
