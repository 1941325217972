const React = require('react');
const TableRow = require('@common/Table2/TableRow.react');
const TableCell = require('@common/Table2/TableCell.react');
const TableHead = require('@common/Table2/TableHead.react');
const T = require('@common/Typography/Typography.react');

const TokenListHeader = () => (
  <TableHead>
    <TableRow className="wethod-personal-api-token-row">
      <TableCell className="wethod-personal-api-token-cell wethod-personal-api-token-cell--name">
        <T uppercase>Name</T>
      </TableCell>
      <TableCell className="wethod-personal-api-token-cell wethod-personal-api-token-cell--last-use">
        <T uppercase>Last used</T>
      </TableCell>
      <TableCell className="wethod-personal-api-token-cell wethod-personal-api-token-cell--description">
        <T uppercase>Description</T>
      </TableCell>
      <TableCell className="wethod-personal-api-token-cell wethod-personal-api-token-cell--action" />
    </TableRow>
  </TableHead>
);

module.exports = TokenListHeader;
