const React = require('react');
const _ = require('underscore');
const $ = require('jquery');
const InputValidator = require('@common/InputValidator/InputValidator.react');
const Autocomplete = require('@common/inputs/Autocomplete/OutlinedAutocomplete/OutlinedAutocomplete.react');
const Item = require('./SupplierSelectItem.react');
const SuppliersAutocompleteModel = require('../../../../../../../models/SuppliersAutocomplete');

module.exports = class SupplierSelect extends React.Component {
  static isAvailable(supplier) {
    return !Wethod.FinanceApp.supplierStatusEnabled
      || (supplier.status !== null && supplier.status.available);
  }

  static getInputValue(item) {
    return _.unescape(item.name);
  }

  constructor(props) {
    super(props);

    this.state = {
      filteredSuppliers: [],
      isLoading: false,
    };

    this.availableConstraint = {
      name: 'available',
      validator: (value, parameter, attributes) => attributes !== null
        && attributes.available === true,
      message: () => 'This supplier is set as "not available for contract"',
    };
  }

  handleFilter(name, key) {
    this.setState({
      isLoading: true,
      filteredSuppliers: [],
    });

    $.when(SuppliersAutocompleteModel.get(key)).done((suppliers) => {
      if (suppliers) {
        this.setState({
          filteredSuppliers: suppliers,
          isLoading: false,
        });
      }
    });
  }

  handleChange(e, item) {
    const {
      name,
    } = e.target;
    let supplier = null;

    if (item) {
      supplier = {
        id: item.value,
        name: item.name,
        available: item.available,
      };
    }

    this.props.onChange(name, supplier);
  }

  getOptions() {
    return this.state.filteredSuppliers.map((supplier) => {
      const name = _.unescape(supplier.name);
      return (
        <Item key={supplier.id}
          available={SupplierSelect.isAvailable(supplier)}
          value={supplier.id}
          name={name} />
      );
    });
  }

  getSelectedSupplier() {
    // Augment value with default available=true to avoid wrong validation on mount.
    // Ask smenon@wethod.com for any doubt
    return (this.props.value && this.props.value.id) ? { available: true, ...this.props.value }
      : null;
  }

  getSupplier(id) {
    const suppliers = this.state.filteredSuppliers;
    if (suppliers.length) {
      return this.state.filteredSuppliers.filter((supplier) => supplier.id === id)[0];
    }
    return null;
  }

  render() {
    return (
      <InputValidator updateErrors={this.props.updateErrors}
        constraints={['required', this.availableConstraint]}>
        <Autocomplete readOnly={this.props.readOnly}
          onChange={this.handleChange.bind(this)}
          value={this.getSelectedSupplier()}
          getInputValue={SupplierSelect.getInputValue}
          name={this.props.name}
          label="Supplier"
          id="sidebar-supplier"
          onFilter={this.handleFilter.bind(this)}
          errorText={this.props.error}
          loading={this.state.isLoading}>
          {this.getOptions()}
        </Autocomplete>
      </InputValidator>
    );
  }
};
